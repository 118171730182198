import { notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../../../constants/svg";
import {
  setCategoryListByModule,
  setCreateUpdateTitleModuleID,
} from "../../../../store/slices/globalSlice";
import {
  all_category_type,
  roles as salonRoles,
  salooon_event_type,
  search_module_type,
  segment_type,
} from "../../../../utils/constants";
import {
  CreateEvent,
  GetSingleEvent,
  UpdateEvent,
} from "../../../../services/events/events";
import { UpdateFileUploadStorageSizeAvailability } from "../../../../services/global/global";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import {
  setDisableActionBtn,
  setEventAddEditFormVisibility,
  setRenderTable,
  setReRenderEventDetailPage,
  setSelectedData,
} from "../../../../store/slices/eventsSlice";
import { salonUploadToS3, toastMessage } from "../../../../utils/helper";
import FirstStep from "./StepsForm/FirstStep";
import FourthStep from "./StepsForm/FourthStep";
import SecondStep from "./StepsForm/SecondStep";
import ThirdStep from "./StepsForm/ThirdStep";
import ZeroStep from "../../Resource/ResourceModal/StepsForm/ZeroStep";
import { toNumber } from "lodash";

const EventModalForms = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  );
  const [firstStepDisabled, setFirstStepDisabled] = useState(true);
  const [secondStepDisabled, setSecondStepDisabled] = useState(true);
  const [thirdStepDisabled, setThirdStepDisabled] = useState(true);
  const [fourthStepDisabled, setFourthStepDisabled] = useState(true);
  const [isFetchingEditDetail, setIsFetchingEditDetail] = useState(false);
  const [segment, setSegment] = useState(null);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const {
    disableActionBtn,
    selectedData,
    eventAddEditFormVisibility,
    renderTable,
    reRenderEventDetailPage,
  } = useSelector((state) => state.events);
  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );

  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      eventType: "",
      eventName: "",
      eventLink: "",
      educatorOrganizer: "",
      category: "",
      description: "",
      eventLocation: "",
      eventGoogleMap: "",
      eventLat: "",
      eventLng: "",
      startDate: "",
      startTime: "",
      timeZone: "",
      endDate: "",
      endTime: "",
      price: "",
      uploadFromLocal: "",
      uploadFromGallery: "",
      uploadFromFolder: "",
      previewImage: "",
    },
  });

  const watchingFirstStepForm = watch("eventType");
  const watchingEventLinkOrLocation = watch(["eventLink", "eventLocation"]);
  const watchingSecondStepForm = watch([
    "eventName",
    "educatorOrganizer",
    "category",
  ]);
  const watchingThirdStepForm = watch([
    "startDate",
    "startTime",
    "timeZone",
    "endDate",
    "endTime",
  ]);
  const watchingFourthStepForm = watch([
    "uploadFromLocal",
    "uploadFromGallery",
    "uploadFromFolder",
    "previewImage",
  ]);

  useEffect(() => {
    if (watchingFirstStepForm) {
      setFirstStepDisabled(false);
    } else {
      setFirstStepDisabled(true);
    }
  }, [watchingFirstStepForm]);

  useEffect(() => {
    const isValid = watchingEventLinkOrLocation.some((elem) => elem != "");
    setSecondStepDisabled(!isValid);
  }, [watchingEventLinkOrLocation]);

  useEffect(() => {
    const isValid = watchingSecondStepForm.every((elem) => elem != "");
    setSecondStepDisabled(!isValid);
  }, [watchingSecondStepForm]);

  useEffect(() => {
    const isValid = watchingThirdStepForm.every((elem) => elem != "");
    setThirdStepDisabled(!isValid);
  }, [watchingThirdStepForm]);

  useEffect(() => {
    const isValid = watchingFourthStepForm.some((elem) => elem != "");
    setFourthStepDisabled(!isValid);
  }, [watchingFourthStepForm]);

  const onSubmit = async (inputFields) => {
    try {
      const payload = {
        name: inputFields.eventName,
        link: inputFields.eventLink,
        start_date: inputFields.startDate,
        start_time: inputFields.startTime,
        end_date: inputFields.endDate,
        end_time: inputFields.endTime,
        timezone: inputFields.timeZone,
        address: inputFields.eventLocation,
        lat: inputFields.eventLat, // latitude
        long: inputFields.eventLng, // longitude
        location: inputFields.eventGoogleMap, // google map link
        instructor: inputFields.educatorOrganizer,
        type: salooon_event_type[inputFields.eventType],
        price: toNumber(inputFields.price || 0),
        description: inputFields.description,
        salon_id: currentSalonId,
        category_id: inputFields.category,
      };
      if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
        let salonData =
          selectedSalons.length > 0
            ? selectedSalons
            : salonSegments.map((item) => {
                return item.id;
              });
        payload.salons = salonData;
        payload.products = selectedProductLines;
        payload.positions = selectedPositions;
        payload.segment = segment;
        payload.softwares = softwares;
        payload.technologies = technologies;
      }

      dispatch(setDisableActionBtn(true));

      let salonUploadToS3Response;

      if (inputFields.uploadFromLocal) {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/events`;
        salonUploadToS3Response = await salonUploadToS3(
          inputFields.uploadFromLocal,
          folder,
        );
        if (salonUploadToS3Response.status) {
          payload.image = salonUploadToS3Response.data.location;
        } else {
          throw new Error(salonUploadToS3Response.message);
        }
      } else if (
        inputFields.uploadFromGallery ||
        inputFields.uploadFromFolder ||
        inputFields.previewImage
      ) {
        payload.image = inputFields.uploadFromGallery
          ? inputFields.uploadFromGallery
          : inputFields.uploadFromFolder
            ? inputFields.uploadFromFolder
            : inputFields.previewImage
              ? inputFields.previewImage
              : "";
      }

      let response;
      if (eventAddEditFormVisibility.formType == "create") {
        response = await CreateEvent(payload);
      } else if (eventAddEditFormVisibility.formType == "update") {
        response = await UpdateEvent(selectedData.id, payload);
      }

      if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
        if (salonUploadToS3Response) {
          await UpdateFileUploadStorageSizeAvailability(
            salonUploadToS3Response.data.size,
          );
        }
      }

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          message,
          placement: "bottomRight",
          // duration: 0,
          className: "deletedNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      dispatch(setSelectedData(null));

      dispatch(
        setEventAddEditFormVisibility({
          show: false,
          formType: null,
        }),
      );

      dispatch(setRenderTable(renderTable + 1));
      dispatch(setReRenderEventDetailPage(reRenderEventDetailPage + 1));

      // let notiMsg;

      // if (eventAddEditFormVisibility.formType == "create") {
      //   notiMsg = "Event Posted Successfully!";
      // } else if (eventAddEditFormVisibility.formType == "update") {
      //   notiMsg = "Event Updated Successfully!";
      // }

      toastMessage("success", message);
    } catch (e) {
      toastMessage("warning", e.message);
    } finally {
      dispatch(setDisableActionBtn(false));
    }
  };

  // Navigation of buttons
  const nextStep = () => {
    if (eventAddEditFormVisibility.formType == "update" && step == 0) {
      setStep(2);
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (
      eventAddEditFormVisibility.formType == "update" &&
      step == 2 &&
      GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin
    ) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  const getSingleEvent = async () => {
    try {
      setIsFetchingEditDetail(true);
      const response = await GetSingleEvent(selectedData.id);
      const { data, message, status } = response.data;
      setValue(
        "eventType",
        Object.entries(salooon_event_type).find(
          (elem) => elem[1] == data.type,
        )[0],
      );
      setSegment(data?.segment || null);
      if (data?.segment == segment_type.CUSTOM) {
        let salons = [];
        let products = [];
        data?.products?.map((item) => {
          products.push(item.product);
        });
        data?.salons?.map((item) => {
          salons.push(item.id);
        });
        setSelectedSalons(salons);
        setSelectedProductLines(products);
      }
      setValue("eventName", data.name);
      setValue("educatorOrganizer", data.instructor);
      setValue("category", data.category_id);
      setValue("description", data.description);
      setValue("eventLocation", data.address);
      setValue("eventLink", data.link);
      setValue("startDate", moment(data.start_date).format("YYYY-MM-DD"));
      setValue("startTime", data.start_time);
      setValue("endDate", moment(data.end_date).format("YYYY-MM-DD"));
      setValue("endTime", data.end_time);
      setValue("timeZone", data.timezone);
      setValue("price", data.price);
      setValue("previewImage", data.image);
      setValue("eventLat", data.lat);
      setValue("eventLng", data.long);
      setValue("eventGoogleMap", data.location);
      setValue("eventLink", data.link);
      setTechnologies(data.technologies.map(({ name }) => name) ?? []);
      setSoftwares(data.software.map(({ name }) => name) ?? []);

      // setValue("", data.);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      setIsFetchingEditDetail(false);
    }
  };

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.event));

    if (eventAddEditFormVisibility.formType == "update") {
      getSingleEvent();
    }
  }, [eventAddEditFormVisibility]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.events));
  }, []);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={step == 0 ? "modal-body" : "modal-body tabs-modal"}>
          <div className={step == 0 ? "" : "d-none"}>
            <ZeroStep
              setZeroStepDisabled={setZeroStepDisabled}
              customClass="noBorders"
              selectedSalons={selectedSalons}
              setSelectedSalons={setSelectedSalons}
              setSelectedProductLines={setSelectedProductLines}
              selectedProductLines={selectedProductLines}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              showPositions={false}
              segment={segment}
              setSegment={setSegment}
              softwares={softwares}
              setSoftwares={setSoftwares}
              technologies={technologies}
              setTechnologies={setTechnologies}
            />
          </div>
          {/* {eventAddEditFormVisibility.formType != "update" && ( */}
          <div className={step == 1 ? "" : "d-none"}>
            <FirstStep
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          {/* )} */}

          <div className={step == 2 ? "" : "d-none"}>
            <SecondStep
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          <div className={step == 3 ? "" : "d-none"}>
            <ThirdStep
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          <div className={step == 4 ? "" : "d-none"}>
            <FourthStep
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              submitted={disableActionBtn}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={nextStep}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={firstStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={firstStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            ) : null}
            {step == 2 && (
              <>
                <div
                // className={`
                // ${eventAddEditFormVisibility.formType == "update" && GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin
                //     ? "invisible"
                //     : ""
                //   }`}
                >
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={secondStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {step == 3 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={thirdStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {step == 4 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {!disableActionBtn && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={fourthStepDisabled}
                    >
                      {eventAddEditFormVisibility.formType == "update"
                        ? "Update Event"
                        : "Post Event"}
                    </Button>
                  )}
                  {disableActionBtn && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={true}
                    >
                      <div className="load black" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default EventModalForms;
