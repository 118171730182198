import { Image, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { maxBounds } from "../../constants/contants";
import {
  DeleteIcon,
  GroupCoverUpload,
  ModalCloseIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import {
  createGroupChat,
  updateGroupChat,
} from "../../services/chats/frontend-chats";
import { UpdateFileUploadStorageSizeAvailability } from "../../services/global/global";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  CreateGroup,
  GetGroupByID,
  UpdateGroup,
} from "../../services/team/groups";
import {
  setCreateUpdateGroupForm,
  setDisableActionBtn,
  setRenderTable,
  setSelectedData,
} from "../../store/slices/groupSlice";
import { constant, status as GroupStatus } from "../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import CustomMembersSelect from "../Common/CustomMembersSelect";
import CustomUploader from "../Common/CustomUploader";

const CreateNewGroup = (props) => {
  const dispatch = useDispatch();
  const { currentSalonId } = useSelector((state) => state.global);
  const { me } = useSelector((state) => state.auth);
  const { disableActionBtn, renderTable, selectedData, createUpdateGroupForm } =
    useSelector((state) => state.group);

  const [disable, setDisable] = useState(true);
  const [image, setImage] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      file: "",
      preview: "",
      users: "",
    },
  });

  const watchingInputs = watch(["name", "users"]);

  useEffect(() => {
    const isValid = watchingInputs.every((elem) => elem != "");
    setDisable(!isValid);
  }, [watchingInputs]);

  const onSubmitCreateNewGroup = async (inputFields) => {
    try {
      dispatch(setDisableActionBtn(true));

      const payload = {
        name: inputFields.name,
        users: inputFields.users,
        description: "",
        user_id: GetAuthUserLocalStorage().id,
        salon_id: currentSalonId,
        status: GroupStatus.active,
      };

      let uploadGroupImage;
      if (inputFields.preview) {
        payload.image = inputFields.preview;
      } else if (inputFields.file) {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/groups`;
        uploadGroupImage = await salonUploadToS3(inputFields.file, folder);
        if (uploadGroupImage.status) {
          payload.image = uploadGroupImage.data.location;
        } else {
          throw new Error(uploadGroupImage.message);
        }
      }

      if (
        (payload.image == undefined || payload.image == "") &&
        (image == null || image == "")
      ) {
        payload.image = constant.FALLBACK_IMAGE_URL;
      }

      let response;
      if (createUpdateGroupForm.formType == "create") {
        response = await CreateGroup(payload);
        const created_group_id = response?.data?.data?.id;
        payload.group_id = created_group_id;
        await createGroupChat(payload, me?.data);
      } else if (createUpdateGroupForm.formType == "update") {
        response = await UpdateGroup(selectedData.id, payload);
        const group_id = selectedData.id;
        payload.group_id = group_id;
        await updateGroupChat(payload);
      }

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      if (uploadGroupImage) {
        await UpdateFileUploadStorageSizeAvailability(
          uploadGroupImage.data.size,
        );
      }

      dispatch(setRenderTable(renderTable + 1));

      setSelectedData(null);

      dispatch(
        setCreateUpdateGroupForm({
          show: false,
          formType: null,
        }),
      );

      toastMessage("success", message);
    } catch (e) {
      toastMessage("warning", e.message);
    } finally {
      dispatch(setDisableActionBtn(false));
    }
  };

  const getSingleGroup = async () => {
    const response = await GetGroupByID(selectedData.id);
    const { data, status, message } = response.data;
    setValue("name", data.name);
    setValue(
      "users",
      data.users.map((elem) => elem.id),
    );
    if (data.image) {
      setValue("preview", data.image);
    }
  };

  useEffect(() => {
    if (createUpdateGroupForm.formType == "update") {
      getSingleGroup();
    }
  }, [createUpdateGroupForm]);

  const handleChangeMember = (value) => {
    setValue("users", value);
  };

  useEffect(() => {
    setImage(getValues("preview"));
  }, [getValues("preview"), image]);

  const handleDelete = () => {
    setImage(null);
    setValue("preview", "");
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmitCreateNewGroup)}>
        <div className="modal-body group-modal-body">
          <Row>
            <Col lg={7}>
              <FloatingLabel
                controlId="DocName"
                label="Group Name"
                className="mb-20"
              >
                <Form.Control
                  type="text"
                  placeholder="Group Name"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.name ? "hasError" : ""}
                  maxLength={maxBounds.NAME}
                  {...register("name", {
                    required: "Group name is required",
                  })}
                />
              </FloatingLabel>
            </Col>
            <Col lg={5}>
              <div className="mb-20">
                <Controller
                  render={({ field }) => (
                    <CustomMembersSelect
                      className={
                        errors.users
                          ? "hasError custSelectAddMember"
                          : "custSelectAddMember"
                      }
                      {...field}
                      handleChangeMember={handleChangeMember}
                      selectedValue={field.value == "" ? [] : field.value}
                    />
                  )}
                  name="users"
                  control={control}
                  defaultValue={[]}
                  rules={{
                    required: "Member is required",
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <p className="small-font uploadHeader">Upload Group Photo</p>
              <div
                className={`border-0 bg-0 height-dragger ${
                  errors.file ? "hasError" : ""
                }`}
              >
                <Controller
                  control={control}
                  defaultValue={""}
                  name="file"
                  render={({ field: { onChange, value } }) => (
                    <CustomUploader
                      {...props}
                      accept={".jpg,.png,.jpeg"}
                      fileList={value == "" ? [] : [value]}
                      beforeUpload={() => {
                        return false;
                      }}
                      listType="picture"
                      onRemove={(e) => {
                        onChange("");
                        setValue("preview", "");
                      }}
                      onChange={(e) => {
                        const fileList = e.fileList;
                        onChange(
                          fileList.length
                            ? fileList.length > 1
                              ? fileList[1]
                              : fileList[0]
                            : [],
                        );
                        setValue("preview", "");
                      }}
                    >
                      <GroupCoverUpload />
                    </CustomUploader>
                  )}
                />
              </div>
            </Col>
          </Row>
          {image && (
            <Row>
              <Col md={12}>
                <div className="img-upload-preview">
                  {/* <strong>Preview</strong> */}
                  {/* <br /> */}
                  <Image
                    className="ant-img-custom-css upload-img"
                    preview={false}
                    src={image}
                  />
                  <Button
                    type="button"
                    className={"delete-img"}
                    onClick={() => handleDelete()}
                  >
                    <ModalCloseIcon />
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          {!disableActionBtn ? (
            <Button type="submit" className={"w-100"} disabled={disable}>
              Save Group
            </Button>
          ) : (
            <Button type="submit" className={"w-100"} disabled={true}>
              <div className="load black" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateNewGroup;
