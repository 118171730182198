import React, { createContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [goalTitle, setGoalTitle] = useState("Step 1");

  return (
    <MyContext.Provider value={{ goalTitle, setGoalTitle }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
