import { Switch, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { NewSparkleIcon, ToastCrossIcon } from "../../constants/svg";
import { useDispatch, useSelector } from "react-redux";
import { GetShoutouts, UpdateShoutouts } from "../../services/team/shoutouts";
import {
  setIsShoutoutsLoading,
  setShoutouts,
  updateShoutouts,
} from "../../store/slices/rewardSlice";
import { status, tableHeight } from "../../utils/constants";
import moment from "moment";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { tableLoader } from "../../utils/helper";

const ShoutoutPage = () => {
  const dispatch = useDispatch();
  const { currentSalonId } = useSelector((state) => state.global);
  const { isShoutoutsLoading, shoutouts } = useSelector(
    (state) => state?.reward,
  );

  const [page, setPage] = useState({ currentPage: 1, perPage: 10 });

  const handleSwitchChange = async (record, checked) => {
    try {
      dispatch(
        updateShoutouts({ index: record?.index, checkedValue: checked }),
      );
      const data = {
        status: checked ? status?.active : status?.inactive,
        shoutout_id: record?.key,
        salon_id: currentSalonId,
        type: record?.type,
      };
      await UpdateShoutouts(data);
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const columns = [
    {
      title: "Message Preview",
      dataIndex: "message",
      key: "message",
      width: "60%",
      render: (text) => (
        <>
          <div className="newmessageBox">
            <h5>{text.heading}</h5>
            <h6>{text.subheading}</h6>
            <p>{text.paragraph}</p>
          </div>
        </>
      ),
    },
    {
      title: "Send On",
      dataIndex: "sendOn",
      key: "sendOn",
      width: "30%",
      render: (text) => (
        <>
          <h5>{text.heading}</h5>
          <h6>{text.subheading}</h6>
          <p>{text.paragraph}</p>
        </>
      ),
    },
    // {
    //   title: "On Click",
    //   dataIndex: "onClick",
    //   key: "onClick",
    //   render: (text) => (
    //     <>
    //       <h5>{text.heading}</h5>
    //       <h6>{text.subheading}</h6>
    //       <p>{text.paragraph}</p>
    //     </>
    //   ),
    //   width: "234px",
    // },
    {
      title: "Off/On",
      dataIndex: "offOn",
      key: "offOn",
      width: "10%",
      render: (checked, record) => (
        <>
          <div className="d-flex align-items-start">
            <Switch
              defaultChecked={checked}
              onChange={(newChecked) => handleSwitchChange(record, newChecked)}
            />
          </div>
        </>
      ),
    },
  ];

  const fetchShoutouts = async () => {
    try {
      if (shoutouts?.data?.length == 0) {
        dispatch(setIsShoutoutsLoading(true));
      }
      const params = {
        is_paginated: 1,
        page: page?.currentPage,
        "per-page": page?.perPage,
      };

      const res = await GetShoutouts(params);
      const data = res?.data?.data?.data?.map((item, index) => {
        return {
          key: item?.id,
          index,
          message: {
            heading: item?.name || "-",
            subheading: item?.title || "-",
            paragraph: item?.description || "-",
          },
          sendOn: {
            heading: (item?.send_on?.split("\\n")[0] || "-").replace(/\\/g, ""),
            subheading: (item?.send_on?.split("\\n")[1] || "-").replace(
              /\\/g,
              "",
            ),
            paragraph: moment(item?.time, "HH:mm:ss").format("hh:mm A"),
          },
          onClick: {
            heading: item?.action?.split("\n")[0] || "-",
            subheading: item?.action?.split("\n")[1] || "-",
            // paragraph: item?.description || "-",
          },
          offOn: item?.salonShoutouts[0]?.status == status?.active,
          type: item?.type,
        };
      });
      dispatch(setShoutouts({ data, meta: res?.data?.data?.meta }));
      dispatch(setIsShoutoutsLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsShoutoutsLoading(false));
    }
  };

  useEffect(() => {
    fetchShoutouts();
  }, [page.currentPage, page?.perPage]);

  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">Shoutouts</h2>
      </div>
      <div className="teamTabSubHead memberHeader">
        <p>
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> When enabled, Shoutouts are
          automatically sent as push notifications to all members.
        </p>
      </div>

      <div className="teamBody content">
        <Table
          loading={tableLoader(isShoutoutsLoading)}
          dataSource={shoutouts?.data}
          columns={columns}
          pagination={false}
          scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
          responsive={true}
          // pagination={{
          //   onChange: (currPage) => setPage({ ...page, currentPage: currPage }),
          //   pageSize: page?.perPage,
          //   current: page?.currentPage,
          //   total: shoutouts?.meta?.total || 0,
          //   pageSizeOptions: [10, 20, 50, 100, 'All'],
          //   showSizeChanger: false,
          // }}
          className="shoutoutTable"
        />
      </div>
    </>
  );
};

export default ShoutoutPage;
