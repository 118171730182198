import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetSearch = (params) => api.get(apiUrl.search, { params });
export const GetSearchHistory = (params) =>
  api.get(apiUrl.user_search_histories, { params });
export const AddSearchHistory = (data) =>
  api.post(apiUrl.user_search_histories, data);
export const DeleteSingleSearchHistory = (id) =>
  api.delete(`${apiUrl.user_search_histories}/${id}`);
export const ClearAllSearchHistory = (user_id) =>
  api.delete(`${apiUrl.clear_search_histories}/${user_id}`);
