import { notification, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../../../constants/svg";
import { GetFlashcards } from "../../../../services/flashCards/flashCards";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import { setCreateUpdateModuleTitle } from "../../../../store/slices/globalSlice";
import { GetQuiz } from "../../../../services/quiz/quiz";
import { GetSalonResources } from "../../../../services/resources/resources";
import { GetServey } from "../../../../services/survey/survey";
import {
  alert_category_array,
  alert_link_type_array,
  link_category_type,
  link_types,
  recipient_types,
  roles as salonRoles,
} from "../../../../utils/constants";
import { GetDocuments } from "../../../../services/team/document";
import CustomInput from "../../../FormFields/CustomInput";
import CustomInputWithEmoji from "../../../FormFields/CustomInputWithEmoji";
import CustomSelect from "../../../FormFields/CustomSelect";
import CustomTreeSelect from "../../../FormFields/CustomTreeSelect";
import { GetEvents } from "../../../../services/events/events";

const ExternalLinkFields = ({ control }) => {
  return (
    <Col xs={8}>
      <Controller
        control={control}
        name="link"
        rules={{
          required: {
            value: "Required",
          },
        }}
        render={({ field }) => (
          <CustomInput
            {...field}
            label="https://"
            type="link"
            placeholder="https://"
            value={field?.value}
            onChange={(e) => {
              field?.onChange(e);
            }}
          />
        )}
      />
    </Col>
  );
};

const Step2 = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useFormContext();
  const [switchCheckValue, setSwitchCheckValue] = useState(false);
  const [link_type, selectedCategory, users_id, category] = watch([
    "link_type",
    "selectedCategory",
    "users_id",
    "category",
  ]);
  const { formData } = useSelector((state) => state?.alerts);
  const { currentSalonId } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();
  const [content, setContent] = useState([]);
  const [laadingInternalLinkCategory, setLoadingInternalLinkCategory] =
    useState(false);

  const handleEmoji = (name, value) => {
    setValue(name, value);
  };
  const handleTreeSelectValue = (parentNode, value) => {
    setValue("users_id", value);
    setValue("selectedCategory", parentNode);
    setValue(
      "recipient_type",
      parentNode == "everyone"
        ? recipient_types?.everyone
        : parentNode == "group"
          ? recipient_types?.group
          : recipient_types?.member,
    );
  };

  const handleSwitch = (value) => {
    setSwitchCheckValue(value);
    // setValue("checked", value)
    if (value) {
      setValue("link_type", link_types?.internal);
    }
  };

  const InternalLinkFields = () => {
    return (
      <>
        <Col lg={4}>
          <Controller
            rules={{
              required: {
                value: "Required",
              },
            }}
            control={control}
            name="category"
            defaultValue={""}
            render={({ field }) => (
              <div
                className={`${laadingInternalLinkCategory ? "disable" : ""}`}
              >
                <CustomSelect
                  {...field}
                  placeholder="Select Category"
                  // label="Select Category"
                  value={field?.value}
                  options={alert_category_array}
                  onChange={(e) => field?.onChange(e)}
                />
              </div>
            )}
          />
        </Col>

        <Col lg={4}>
          <Controller
            rules={{
              required: {
                value: "Required",
              },
            }}
            control={control}
            name="content"
            defaultValue={""}
            render={({ field }) => (
              <div
                className={`${laadingInternalLinkCategory ? "disable" : ""}`}
              >
                <CustomSelect
                  {...field}
                  placeholder="Select Content"
                  // label="Select Content"
                  value={
                    laadingInternalLinkCategory
                      ? "Select Content"
                      : field?.value
                  }
                  options={content}
                  onChange={(e) => field?.onChange(e)}
                />
              </div>
            )}
          />
          <i className="internal-link-content-dropdown-loading-text">
            {laadingInternalLinkCategory && "Please wait..."}
          </i>
        </Col>
      </>
    );
  };

  // const ExternalLinkFields = () => {
  //     return (
  //         <Col xs={8}>
  //             <Controller
  //                 control={control}
  //                 name="link"
  //                 rules={{
  //                     required: {
  //                         value: "Required"
  //                     }
  //                 }}
  //                 render={({ field }) => (
  //                     <CustomInput
  //                         {...field}
  //                         label="https://"
  //                         placeholder="https://"
  //                         value={field?.value}
  //                         onChange={(e) => {
  //                             field?.onChange(e)
  //                         }}
  //                     />
  //                 )}
  //             />
  //         </Col>
  //     )
  // }

  const returnText = () => {
    if (link_type == 1) {
      return (
        <p className="gray mt-19">
          Internal Link: Choose any content in your workspace, such as a
          resource, survey, event or quiz.
        </p>
      );
    } else if (link_type == 2) {
      return (
        <p className="gray  mt-19">
          External link: Choose any content in your workspace, such as a
          resource, survey, event or quiz.
        </p>
      );
    }
  };

  const getAllResources = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetSalonResources(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllDocuments = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetDocuments(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllFlashcards = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetFlashcards(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllSurveys = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetServey(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllQuizzes = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetQuiz(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };
  const getAllEvents = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetEvents(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const fetchContent = async () => {
    setLoadingInternalLinkCategory(true);
    setValue("content", "Select Content");
    if (category == link_category_type?.resources) {
      await getAllResources();
    } else if (category == link_category_type?.documents) {
      await getAllDocuments();
    } else if (category == link_category_type?.flashcard) {
      await getAllFlashcards();
    } else if (category == link_category_type?.surveys) {
      await getAllSurveys();
    } else if (category == link_category_type?.quizzes) {
      await getAllQuizzes();
    } else if (category == link_category_type?.events) {
      await getAllEvents();
    }
    setLoadingInternalLinkCategory(false);
  };

  useEffect(() => {
    if (link_type == link_types?.no_link) {
      setSwitchCheckValue(false);
    } else if (
      link_type == link_types?.internal ||
      link_type == link_types?.external
    ) {
      setSwitchCheckValue(true);
    }
  }, [link_type]);

  /*    useEffect(() => {
            setSwitchCheckValue(formData?.checked)
        }, [formData]) */

  useEffect(() => {
    fetchContent();
  }, [category, currentSalonId]);

  return (
    <Row>
      <Col
        lg={
          GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin
            ? 12
            : 8
        }
        className="mb-20"
      >
        <Controller
          control={control}
          name="title"
          rules={{
            required: {
              value: "Required",
            },
            minLength: {
              value: 5,
            },
            maxLength: {
              value: 50,
            },
          }}
          render={({ field }) => (
            <CustomInputWithEmoji
              {...field}
              name="title"
              label="Alert Title"
              placeholder="Alert Title"
              value={field?.value}
              onChange={(e) => {
                dispatch(setCreateUpdateModuleTitle(e));
                field?.onChange(e);
              }}
              handleEmoji={handleEmoji}
              minLength={5}
              maxLength={50}
            />
          )}
        />
      </Col>
      {GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin && (
        <Col lg={4} className={""}>
          <div className=" form-floating">
            <CustomTreeSelect
              isedit={props?.isedit}
              label="Recipients"
              placeholder="Recipients"
              defaultSelectedData={users_id}
              handleChange={handleTreeSelectValue}
              selectedCategory={selectedCategory || null}
            />
          </div>
        </Col>
      )}

      <Col lg={12}>
        <Controller
          control={control}
          name="message"
          rules={{
            required: {
              value: "Required",
            },
            minLength: {
              value: 5,
            },
            maxLength: {
              value: 150,
            },
          }}
          render={({ field }) => (
            <CustomInputWithEmoji
              {...field}
              name="message"
              label="Alert Message"
              placeholder="Alert Message"
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
              handleEmoji={handleEmoji}
              minLength={5}
              maxLength={150}
            />
          )}
        />
      </Col>

      <div className="notificationLinkBox custom-mt-2">
        <div className="header d-flex">
          <h3 className={"me-3"}>Add Link (Optional)</h3>{" "}
          <Switch
            checked={switchCheckValue}
            onChange={handleSwitch}
            size={"medium"}
          />
        </div>
        <p className={"gray"}>
          {" "}
          Send members to a website or link to your content in Salon Symphony.
        </p>
      </div>

      {switchCheckValue && (
        <>
          <Col lg={4}>
            <Controller
              rules={{
                required: {
                  value: "Required",
                },
              }}
              control={control}
              name="link_type"
              render={({ field }) => (
                <div
                  className={`${laadingInternalLinkCategory ? "disable" : ""}`}
                >
                  <CustomSelect
                    {...field}
                    placeholder="Select Link Type"
                    // label="Internal Link"
                    value={field?.value}
                    options={alert_link_type_array}
                    onChange={(e) => field?.onChange(e)}
                  />
                </div>
              )}
            />
          </Col>
          {link_type == link_types?.internal || !link_type ? (
            <InternalLinkFields />
          ) : link_type == link_types?.external ? (
            <ExternalLinkFields control={control} />
          ) : (
            <></>
          )}

          {link_type == link_types?.internal ||
          (!link_type && switchCheckValue) ? (
            <p className="gray mt-19">
              Internal Link: Choose any content in your workspace, such as a
              resource, survey, event or quiz.
            </p>
          ) : link_type == link_types?.external ? (
            <p className="gray mt-19">
              External link: Link to any website URL, like a blog article or
              social media page.
            </p>
          ) : (
            ""
          )}
        </>
      )}
      {returnText()}
    </Row>
  );
};

export default Step2;
