import { notification } from "antd";
import {
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import moment from "moment";
import {
  GetStorages,
  GetSingleStorage,
  GetStoragePaymentLink,
} from "./storages";
import {
  setIsStoragesLoading,
  setStorages,
  setIsSingleStorageLoading,
  setSingleStorage,
  setSingleStoragePaymentLinkLoading,
  setSingleStoragePaymentLink,
} from "../../store/slices/storageSlice";

export const getStorages = async (params, dispatch) => {
  try {
    dispatch(setIsStoragesLoading(true));
    const res = await GetStorages(params);
    dispatch(setStorages({ data: res.data.data }));
    dispatch(setIsStoragesLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });

    dispatch(setIsStoragesLoading(false));
  }
};

export const getSingleStorage = async (id, dispatch) => {
  try {
    dispatch(setIsSingleStorageLoading(true));
    const res = await GetSingleStorage(id);
    dispatch(setSingleStorage(res.data.data));
    dispatch(setIsSingleStorageLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });

    dispatch(setIsSingleStorageLoading(false));
  }
};

export const getStoragePaymentLink = async (id, dispatch) => {
  try {
    dispatch(setSingleStoragePaymentLinkLoading(true));
    const res = await GetStoragePaymentLink(id);
    dispatch(setSingleStoragePaymentLink(res.data.data));
    dispatch(setSingleStoragePaymentLinkLoading(false));
    return res.data.data;
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });

    dispatch(setSingleStoragePaymentLinkLoading(false));
  }
};
