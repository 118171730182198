import { Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowTriangleDown, CheckMarkIcon } from "../../../constants/svg";
import { setFilters } from "../../../store/slices/organizationSlice";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";

const OrganizationHeader = (props) => {
  const dispatch = useDispatch();

  const { allSalons, currentSalonId } = useSelector((state) => state.global);
  const { packages } = useSelector((state) => state.subscription);
  const { filters } = useSelector((state) => state.organization);

  const { activeOrganizations, inActiveOragnizations } = useSelector(
    (state) => state.organization,
  );

  const [modalShow, setModalShow] = useState(false);

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };

  return (
    <div className="globalHeader">
      <div className="globalHeader--left">
        <h2 className="sec-heading">Organizations</h2>
        <div className="customTablist">
          <div className="customTabItem">
            <div
              className={props.activeTab == "tab1" ? "tab active" : "tab"}
              onClick={() => handleTabClick("tab1")}
            >
              Active ({activeOrganizations?.meta?.total})
            </div>
          </div>
          <div className="customTabItem">
            <div
              className={props.activeTab == "tab2" ? "tab active" : "tab"}
              // onClick={() => handleTabClick("tab2")}
            >
              {/* Inactive ({inActiveOragnizations?.meta?.total}) */}
              Inactive ({0})
            </div>
          </div>
        </div>
      </div>
      <div className="globalHeader--right">
        <Select
          value={currentSalonId || undefined}
          defaultValue="All Organizations"
          suffixIcon={<ArrowTriangleDown />}
          menuItemSelectedIcon={<CheckMarkIcon />}
          onChange={(e) => {
            dispatch(setCurrentSalonID(e));
          }}
          popupClassName="main-select pageDropdown header-dropdown me-3"
          className="custom-select-box border"
          options={allSalons.map((elem) => {
            return { value: elem.id, label: elem.name };
          })}
          getPopupContainer={() => document.getElementById("area")}
        />
        <Select
          defaultValue="Plan Type"
          suffixIcon={<ArrowTriangleDown />}
          menuItemSelectedIcon={<CheckMarkIcon />}
          // open={true}
          popupClassName="main-select pageDropdown header-dropdown me-3"
          className="custom-select-box border"
          onChange={(v) => {
            dispatch(setFilters({ ...filters, billing_plan_id: v }));
          }}
          options={packages.map((elem) => {
            return { value: elem.id, label: elem.name };
          })}
        ></Select>
      </div>
    </div>
  );
};

export default OrganizationHeader;
