import React from "react";
import Tickets from "./Tickets";

const TicketBody = (props) => {
  return (
    <>
      <div className="content ticket">
        <div
          className={
            props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
          }
        >
          <Tickets />
        </div>
      </div>
    </>
  );
};

export default TicketBody;
