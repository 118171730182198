import { ConfigProvider, notification, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Assets } from "../../../constants/images";
import {
  NoDataIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import {
  roles as salonRoles,
  SHARE_URL,
  tableHeight,
} from "../../../utils/constants";
import { NavLink } from "react-router-dom";
import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import { DeleteQuiz, GetQuiz } from "../../../services/quiz/quiz";
import {
  setActionButtonDisable,
  setIsPaginate,
  setLoading,
  setPage,
  setPerPage,
  setQuiz,
  setQuizAddEditForm,
  setQuizTableData,
  setReRenderTable,
  setSelectedData,
  setSorting,
} from "../../../store/slices/quizSlice";
import { getColumns } from "./TableColumn";
import { tableLoader, toastMessage } from "../../../utils/helper";
import LazyLoadTable from "../../Table/LazyLoadTable";

const QuizBody = () => {
  const dispatch = useDispatch();

  const {
    loading,
    quiz,
    page,
    perPage,
    isPaginate,
    quizTableData,
    reRenderTable,
    actionButtonDisable,
    sorting,
    filters,
  } = useSelector((state) => state.quiz);
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const [quizIDForDelete, setQuizIDForDelete] = useState(null);
  const [quizShareData, setQuizShareData] = useState(null);

  const [showGenericModal, setShowGenericModal] = useState(false);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const onInitCompGetQuizList = async () => {
    if (!salon?.id && !isSuperAdmin) return;

    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];

    try {
      dispatch(setLoading(true));
      const params = {
        page,
        is_paginated: 0,
        // "per-page": perPage,
        "order-column": "created_at",
        order: sorting.sortBy,
        salon_id: currentSalonId,
        softwares,
        technologies,
        products,
      };

      if (filters.category_id) {
        params.category_id = filters.category_id;
      }

      const resp = await GetQuiz(params);
      const { data, status, message } = resp.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      const quizRecords = data; // isPaginate ? data.data : data;

      const quizDataTableModfied = quizRecords.map((elem) => {
        return {
          key: elem.uniid,
          id: elem.id,
          uniid: elem.uniid,
          quizName: elem.name,
          name: elem.name,
          description: elem.description,
          created_at: elem.created_at,
          image: elem.image,
          lock: elem?.user?.roles?.[0]?.id == salonRoles.super_admin,
          category: elem.category.name,
          lowScore: {
            count: elem.lowScoreUser.length,
            attendents: elem.lowScoreUser.map((elem) => elem?.user?.name),
          },
          highScore: {
            count: elem.hightScoreUser.length,
            attendents: elem.hightScoreUser.map((elem) => elem?.user?.name),
          },
          user: elem?.user || null,
        };
      });
      dispatch(setQuizTableData(quizDataTableModfied));
      dispatch(setQuiz(data));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onDeleteQuiz = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const resp = await DeleteQuiz(quizIDForDelete.id);
      const { data, message, status } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setQuizIDForDelete(null);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(setIsPaginate(0));
      dispatch(setPage(0));
      dispatch(setPerPage(0));
    } else {
      dispatch(setPage(page));
      dispatch(setPerPage(perPage));
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    let column = "";

    switch (sorter.field) {
      case "quizName":
        column = "name";
        break;

      case "category":
        column = "category_id";
        break;

      default:
        column = sorter.field;
        break;
    }

    dispatch(
      setSorting({
        column,
        sortBy: sorter.order,
      }),
    );
  };

  const handleOpenQuizEditForm = (record) => {
    dispatch(setSelectedData(record));
    dispatch(
      setQuizAddEditForm({
        show: true,
        formType: "update",
      }),
    );
  };

  useEffect(() => {
    onInitCompGetQuizList();
  }, [
    page,
    perPage,
    reRenderTable,
    sorting,
    filters,
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  return (
    <>
      <div className="content">
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Quizzes you've created will appear here.</h5>
              <h6>Click "Create Quiz" to get started.</h6>
            </div>
          )}
        >
          <Table
            /* locale={{
              emptyText:(   <Empty
                  image={Assets.EmptyWhiteBg}
                  description={''}
              />),
            }} */
            store={{ store: "quiz", param: "quizTableData" }}
            columns={getColumns(
              handleOpenQuizEditForm,
              setShowModal,
              setQuizIDForDelete,
              setQuizShareData,
            )}
            dataSource={quizTableData}
            bordered={false}
            loading={tableLoader(loading)}
            pagination={false}
            // pagination={{
            //   onChange: handlePaginationChange,
            //   // pageSize: !isPaginate ? quizTableData.length : perPage,
            //   showSizeChanger: false,
            //   current: !isPaginate ? 1 : page,
            //   total: !isPaginate ? quizTableData.length : quiz?.meta?.total,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            // }}
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="flashCardTable imageTable"
            rowKey={(record) => record.key}
          />
        </ConfigProvider>
      </div>

      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Quiz"}
        shareLink={SHARE_URL.QUIZZES + quizShareData?.id}
        data={{
          image: quizShareData?.image,
          name: quizShareData?.name,
          description: quizShareData?.description,
          start_date: quizShareData
            ? moment(quizShareData.created_at).format("MMM DD, yyyy")
            : "",
          start_time: quizShareData
            ? moment(quizShareData.created_at).format("hh:mm a")
            : "",
        }}
        modalSubtitle={
          <>
            Click “Copy Link” below, then
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              &nbsp;Open Chat&nbsp;
            </NavLink>
            to easily share this quiz with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Matrix Hair Coloring Quiz",
          para1:
            "This is event dummy message will replace with the. This is event dummy message will replace with the. This is event dummy message will replace with the...",
          para2: "8 Questions • Oct 04, 2023",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        disable={actionButtonDisable}
        handleDelete={onDeleteQuiz}
        show={showModal.deleteModal}
        onHide={() => {
          setShowModal({ ...showModal, deleteModal: false });
          setQuizIDForDelete(null);
        }}
        heading="Delete Quiz?"
        para={
          <>
            Are you sure you want to delete <b>{quizIDForDelete?.name}?</b>
            <br />
            Important: This quiz cannot be recovered.
          </>
        }
        notificationMessage="Quiz"
      />
    </>
  );
};

export default QuizBody;
