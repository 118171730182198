import React from "react";
import { notification } from "antd";
import { Button, Form } from "react-bootstrap";
import {
  CopyIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";

const CopylinkBarTwo = ({ link, closeAction }) => {
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      notification.info({
        message: (
          <span>
            <strong>Link </strong> copied to clipboard!
          </span>
        ),
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      closeAction(false);
    });
  };
  return (
    <div className="copy-link-container-two">
      <Form.Control value={link} readOnly />
      <Button
        className="mb-0"
        size={"lg"}
        type={"submit"}
        onClick={copyLinkToClipboard}
      >
        <CopyIcon />
        Copy Link
      </Button>
    </div>
  );
};

export default CopylinkBarTwo;
