import { notification } from "antd";
import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { maxBounds, Patterns } from "../../constants/contants";
import {
  Eye,
  EyeHide,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { Login } from "../../services/auth/auth";
import { updateOnlineStatusOfLoginUser } from "../../services/chats/frontend-chats";
import {
  SetAuthUserLocalStorage,
  SetTokenLocalStorage,
  SetUserRoleLocalStorage,
} from "../../services/localStorage/localStorage";
import { setVerifyEmail } from "../../store/slices/authSlice";
import { constant, online_status, roles } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";

function SignIn(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [disable, setDisable] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      setDisable(true);

      const apiData = {
        email: data?.email,
        password: data?.password,
        role_id: roles?.admin?.toString(),
        device_type: constant?.DEVICE_TYPE,
        device_token: constant?.DEVICE_TOKEN,
      };

      const res = await Login(apiData);

      if (!res?.data?.data?.user.is_verified) {
        dispatch(setVerifyEmail(res?.data?.data?.user.email));
        toastMessage("error", "You need to verify your email first!");
        navigate("/verify-email");
        localStorage.setItem(
          "authentication-data",
          JSON.stringify(res?.data?.data),
        );
        return;
      }

      await updateOnlineStatusOfLoginUser(
        res?.data?.data?.user?.id,
        online_status?.online,
      );
      const resData = res?.data?.data;
      SetAuthUserLocalStorage(resData?.user);
      SetTokenLocalStorage(resData?.access_token?.token);
      SetUserRoleLocalStorage(resData?.user?.roles[0]);
      setDisable(false);

      notification.info({
        message: res.data.message,
        placement: "bottomRight",
        // duration: 0,
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      navigate("/dashboard");
    } catch (e) {
      setDisable(false);
      notification.error({
        message: e.message,
        placement: "bottomRight",
        // duration: 0,
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  return (
    <>
      <div className="authBox signInBox">
        <div className="authHeaderText">
          <h1>Welcome Back!</h1>
          <p>
            Don’t have a Salon Symphony account?
            <NavLink
              to={"/setup"}
              className={"uLine blue-accent d-inline ms-1"}
            >
              Sign Up
            </NavLink>
          </p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          {/* Email Label */}
          <FloatingLabel
            controlId="floatingInput"
            label="Email Address"
            className="mb-20"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className={errors.email ? "hasError" : ""}
              autoComplete="true"
              {...register("email", {
                required: {
                  value: true,
                  // message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                },
                pattern: {
                  value: Patterns.Email,
                  // message: VALIDATIONS_TEXT.EMAIL_PATTERN,
                },
                maxLength: {
                  value: maxBounds.EMAIL,
                  // message: VALIDATIONS_TEXT.EMAIL_MAX,
                },
              })}
            />
          </FloatingLabel>

          {/* Password Label */}
          <FloatingLabel
            controlId="floatingPassword"
            label="Password"
            className="mb-20"
            autoComplete="true"
          >
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              minLength={6}
              maxLength={16}
              className={errors.password ? "hasError" : ""}
              {...register("password", {
                required: {
                  value: true,
                  // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                },
                pattern: {
                  value: Patterns.Password,
                  // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                },
                minLength: {
                  value: 6,
                  // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                },
                maxLength: {
                  value: 16,
                  // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                },
              })}
            />
            <div className="password-toggle-icon" onClick={toggleShowPassword}>
              {showPassword ? <Eye /> : <EyeHide />}
            </div>
          </FloatingLabel>
          <Button
            disabled={disable}
            className="mb-0 w-100"
            size={"lg"}
            type={"submit"}
          >
            {disable ? <div className="load black" /> : "Sign In"}
          </Button>
        </Form>
        <div className="authBoxText">
          <NavLink to={"/forgot-password"} className={"blue-accent uLine"}>
            Forgot Password?
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default SignIn;
