import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    tags: [],
    positions: [],
    locations: [],
    categories: [],
    galleries: [],
    selectPreUploadedFile: [],
    sendChatSelectedUsers: [],
    selectedCategory: null,
    addRemoveMemberInSendChatPopup: false,
    salon: null,
    allSalons: [],
    salonSegments: [],
    currentSalonId: null,
    statUserHover: null,
    openChatModal: false,
    createUpdateModuleTitle: null,
    moduleCreateUpdateID: null,
    navigateTo: null,
    blockerModal: false,
  },
  reducers: {
    setAddRemoveMemberInSendChatPopup: (state, action) => {
      state.addRemoveMemberInSendChatPopup = action.payload;
    },
    setStatUserHover: (state, action) => {
      state.statUserHover = action.payload;
    },
    setShowHideChatModel: (state, action) => {
      state.openChatModal = action.payload;
    },
    setSendChatSelectedUsers: (state, action) => {
      state.sendChatSelectedUsers = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setSelectPreUploadedFile: (state, action) => {
      state.selectPreUploadedFile = action.payload;
    },
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCategoryListByModule: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setGalleries: (state, action) => {
      state.galleries = action.payload;
    },
    setSingleSalon: (state, action) => {
      state.salon = action.payload;
    },
    setAllSalons: (state, action) => {
      state.allSalons = action.payload;
    },
    setSalonSegments: (state, action) => {
      state.salonSegments = action.payload;
    },
    setCurrentSalonID: (state, action) => {
      state.currentSalonId = action.payload;
    },
    setCreateUpdateModuleTitle: (state, action) => {
      state.createUpdateModuleTitle = action.payload;
    },
    setCreateUpdateTitleModuleID: (state, action) => {
      state.moduleCreateUpdateID = action.payload;
    },
    setNavigateTo: (state, action) => {
      state.navigateTo = action.payload;
    },
    setBlockerModal: (state, action) => {
      state.blockerModal = action.payload;
    },
  },
});

export const {
  setTags,
  setSelectPreUploadedFile,
  setPositions,
  setLocations,
  setCategories,
  setCategoryListByModule,
  setGalleries,
  setSingleSalon,
  setSendChatSelectedUsers,
  setShowHideChatModel,
  setStatUserHover,
  setAddRemoveMemberInSendChatPopup,
  setAllSalons,
  setSalonSegments,
  setCurrentSalonID,
  setCreateUpdateModuleTitle,
  setCreateUpdateTitleModuleID,
  setNavigateTo,
  setBlockerModal,
} = globalSlice.actions;

export default globalSlice.reducer;
