import { notification } from "antd";
import { ToastCrossIcon, ToastGreenCheckIcon } from "../../constants/svg";
import {
  AddSearchHistory,
  ClearAllSearchHistory,
  DeleteSingleSearchHistory,
  GetSearch,
  GetSearchHistory,
} from "./search";
import {
  setAlerts,
  setClearAllSearchHistory,
  setDocuments,
  setEvents,
  setFlashcards,
  setGoals,
  setMembers,
  setQuizzes,
  setRemoveSearchHistory,
  setRequests,
  setResources,
  setSurveys,
  setTasks,
} from "../../store/slices/searchSlice";

export const getAlertsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setAlerts({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getDocumentsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setDocuments({
        data: res?.data?.data?.data,
        meta: res?.data?.data?.meta,
      }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getEventsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setEvents({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getFlashcardsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setFlashcards({
        data: res?.data?.data?.data,
        meta: res?.data?.data?.meta,
      }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getGoalsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setGoals({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getMembersSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setMembers({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getQuizzesSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setQuizzes({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getRequestsSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setRequests({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getResourcesSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setResources({
        data: res?.data?.data?.data,
        meta: res?.data?.data?.meta,
      }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getSurveysSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setSurveys({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getTasksSearch = async (params, dispatch) => {
  try {
    const res = await GetSearch(params);
    dispatch(
      setTasks({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getSearchHistory = async (
  params,
  dispatch,
  setLoading,
  setData,
) => {
  try {
    dispatch(setLoading(true));
    const res = await GetSearchHistory(params);
    dispatch(setData({ data: res?.data?.data, meta: null }));
    dispatch(setLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const deleteSingleSearchHistory = async (id, index, dispatch) => {
  try {
    const res = await DeleteSingleSearchHistory(id);
    dispatch(setRemoveSearchHistory(index));
    notification.info({
      message: <span>{res.data.message}</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const clearAllSearchHistory = async (user_id, dispatch) => {
  try {
    const res = await ClearAllSearchHistory(user_id);
    dispatch(setClearAllSearchHistory());

    notification.info({
      message: <span>{res.data.message}</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const addSearchHistory = async (keyword) => {
  try {
    const res = await AddSearchHistory(keyword);
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};
