import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    selectedUser: null,
    isUsersLoading: true,

    users: {
      meta: null,
      data: [],
    },
  },
  reducers: {
    setIsUsersLoading: (state, action) => {
      state.isUsersLoading = action.payload;
    },
    setUsers: (state, action) => {
      state.users.data = action.payload.data;
      state.users.meta = action.payload.meta;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
});

export const { setIsUsersLoading, setUsers, setSelectedUser } =
  usersSlice.actions;

export default usersSlice.reducer;
