import { ConfigProvider, Dropdown, Empty, Select, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import {
  DeleteSingleGallery,
  GetGalleries,
} from "../../services/galleries/galleries";
import {
  gallery_type,
  gallery_type_array,
  SHARE_URL,
  status,
  tableHeight,
} from "../../utils/constants";
import {
  getUloadTypeStringLiteral,
  hasAccess,
  tableLoader,
  toastMessage,
} from "../../utils/helper";
import UploadModal from "./../../components/Modals/Uploads/UploadModal";
import ShareUploadMediaModal from "../../components/Modals/ShareUploadMediaModal";
import { Assets } from "../../constants/images";
import {
  ArrowTriangleDown,
  AudioIconFilled,
  BinocularsLight,
  BlackSolidPDFIcon,
  CheckMarkIcon,
  MoreIcon,
  NewAudioIcon,
  NewFolderIcon,
  NewImageIcon,
  NewVideoIcon,
  NoDataIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
  PdfIconFilled,
  PurpleFolderIcon,
  RecycleBin,
  RenameIcon,
  ShareIcon,
  UploadNewIcon,
  VideoIconFilled,
} from "../../constants/svg";
import {
  setFilters,
  setGalleryAddEditForm,
  setGallerys,
  setIsGallerysLoading,
  setUploadType,
} from "../../store/slices/gallerysSlice";
import LazyLoadTable from "../../components/Table/LazyLoadTable";

const GalleryPage = () => {
  const {
    isGallerysLoading,
    gallerys,
    uploadType,
    renderList,
    filters,
    galleryAddEditForm,
  } = useSelector((state) => state?.gallerys);
  const { currentSalonId } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const [showGenericModalTwo, setShowGenericModalTwo] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [selectedData, setSelectedData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isPaginate, setIsPaginate] = useState(0);
  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/settings/uploads/details/${record?.key}`}
              className={"black"}
            >
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "2",
        className: `${record.type == gallery_type.folder ? "" : "d-none"}`,
        label: (
          <>
            <div
              onClick={() => {
                setSelectedData(record);
                dispatch(setUploadType(gallery_type.folder));
                dispatch(
                  setGalleryAddEditForm({
                    show: true,
                    formType: "update",
                  }),
                );
              }}
            >
              <RenameIcon />
              Rename
            </div>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                setSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              setSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "alertTitle",
      key: "alertTitle",
      width: 340,
      sorter: (a, b) => a.alertTitle.title.localeCompare(b.alertTitle.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink
          to={`/settings/uploads/details/${record?.key}`}
          className={"black gallery-link"}
        >
          <Space>
            {record.type == gallery_type.image && (
              <div className={"image-container"}>
                <img
                  src={record.alertTitle.image}
                  className="eventDetailImage svg-size"
                  style={{
                    backgroundSize: "cover",
                  }}
                />
              </div>
            )}

            {record.type == gallery_type.pdf && (
              <span className="customSvgSize">
                <PdfIconFilled />
              </span>
            )}

            {record.type == gallery_type.video && (
              <span className="customSvgSize">
                <VideoIconFilled />
              </span>
            )}

            {record.type == gallery_type.audio && (
              <span className="customSvgSize">
                <AudioIconFilled />
              </span>
            )}

            {record.type == gallery_type.folder && (
              <span className="customSvgSize">
                <PurpleFolderIcon />
              </span>
            )}

            <div className="truncateUploadMediaName">
              {record.alertTitle.title}
            </div>
          </Space>
        </NavLink>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.typeAsTxt.localeCompare(b.typeAsTxt),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.typeAsTxt}</p>,
      align: "left",
      width: 100,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      sorter: (a, b) => a.size.localeCompare(b.size),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.size}</p>,
      align: "left",
      width: 100,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => {
        return (
          <div className="">
            <div className="seenBy">
              <span>{record.created}</span>
            </div>
          </div>
        );
      },

      // align: "center",
      width: 100,
    },
    {
      title: "More",
      align: "center",
      key: "action",
      width: 100,
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const url = SHARE_URL.GALLERY + selectedData?.key;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      event.target != dropdownButtonRef.current // Add this condition to check if the button was clicked
    ) {
      setIsOpen(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDisable(true);
      const res = await DeleteSingleGallery(selectedData?.key);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);
      getGalleries();
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  const getGalleries = async () => {
    try {
      dispatch(setIsGallerysLoading(true));
      const params = {
        is_paginated: isPaginate,
        status: status.active,
        salon_id: currentSalonId,
        page,
        // "per-page": perPage,
        "without-folder-data": true,
      };
      if (filters.type) {
        params.type = filters.type;
      }
      const res = await GetGalleries(params);
      let data = res?.data?.data?.map((item) => {
        let folderSize = 0;

        if (item?.type == 40 && item.resources.length >= 1) {
          // Use forEach instead of for loop for better readability
          item.resources.forEach((resource) => {
            const numericSize = parseFloat(resource.size); // Extract numeric value
            folderSize += isNaN(numericSize) ? 0 : numericSize;
          });
        }

        return {
          id: item?.id,
          key: item?.id,
          name: item?.name || "-",
          alertTitle: {
            title: item?.name || "-",
            image: item?.link || Assets.GeneralPlaceholder,
          },
          type: item?.type,
          typeAsTxt: getUloadTypeStringLiteral(item?.type),
          size: folderSize
            ? folderSize + " KB"
            : item?.size
              ? item?.size.split(/[.\s]/)[0] + " KB"
              : "-",
          created: item?.created_at
            ? moment(item?.created_at).format("MMM DD, yyyy")
            : "-",
          user: item?.user || null,
        };
      });
      dispatch(setGallerys({ meta: res.data.data?.meta, data }));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsGallerysLoading(false));
    }
  };

  const handleItemClick = (uploadType) => {
    dispatch(setUploadType(uploadType));
    dispatch(
      setGalleryAddEditForm({
        show: true,
        formType: "create",
      }),
    );
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getGalleries();
  }, [renderList, filters, currentSalonId, page, perPage]);

  const getUploadModalTitle = () => {
    let uploadModalTitle;
    const isAddEdit = galleryAddEditForm.formType;

    switch (uploadType) {
      case gallery_type.image:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Image" : "Update Image";
        break;

      case gallery_type.video:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Video" : "Update Video";
        break;

      case gallery_type.audio:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Audio" : "Update Audio";
        break;

      case gallery_type.folder:
        uploadModalTitle =
          isAddEdit == "create" ? "Create New Folder" : "Rename Folder";
        break;

      case gallery_type.pdf:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New PDF" : "Update PDF";
        break;
    }
    return uploadModalTitle;
  };
  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      setIsPaginate(0);
      setPage(0);
      setPerPage(0);
    } else {
      setPage(page);
      setPerPage(perPage);
    }
  };

  return (
    <>
      <div className="">
        <div className="gallery-header">
          <div className="globalHeader">
            <div className="globalHeader--left">
              <h2 className="sec-heading">Media Uploads</h2>
            </div>
            <div className="globalHeader--right position-realtive">
              <Select
                defaultValue="All Media"
                suffixIcon={<ArrowTriangleDown />}
                menuItemSelectedIcon={<CheckMarkIcon />}
                // open={true}
                onChange={(e) => dispatch(setFilters({ ...filters, type: e }))}
                popupClassName="main-select pageDropdown header-dropdown me-3"
                className="custom-select-box border"
                options={gallery_type_array}
              ></Select>
              <button
                className="btn-gradient"
                onClick={toggleDropdown}
                ref={dropdownButtonRef}
              >
                <span>
                  <UploadNewIcon />
                </span>
                Upload New
              </button>

              {isOpen && (
                <div className="custom-dropdown" ref={dropdownRef}>
                  <div
                    className="sub-dropdown cursor-pointer"
                    onClick={() => handleItemClick(gallery_type?.image)}
                  >
                    <NewImageIcon />
                    <span>New Image</span>
                  </div>
                  <div
                    className="sub-dropdown cursor-pointer"
                    onClick={() => handleItemClick(gallery_type?.video)}
                  >
                    <NewVideoIcon />
                    <span>New Video</span>
                  </div>
                  <div
                    className="sub-dropdown cursor-pointer"
                    onClick={() => handleItemClick(gallery_type?.audio)}
                  >
                    <NewAudioIcon />
                    <span>New Audio</span>
                  </div>
                  <div
                    className="sub-dropdown cursor-pointer"
                    onClick={() => handleItemClick(gallery_type?.pdf)}
                  >
                    <BlackSolidPDFIcon />
                    <span>New PDF</span>
                  </div>
                  <div
                    className="sub-dropdown cursor-pointer"
                    onClick={() => handleItemClick(gallery_type?.folder)}
                  >
                    <NewFolderIcon />
                    <span>New Folder</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <p>Upload and manage your image, PDF, video and audio files here.</p>
        </div>
        <div className="content">
          <ConfigProvider
            renderEmpty={() => (
              <div className="noSearchData">
                <NoDataIcon />
                <h5>Media files you've uploaded will appear here.</h5>
                <h6>Click "Upload New" to get started.</h6>
              </div>
            )}
          >
            <Table
              // locale={{
              //   emptyText: (
              //     <Empty image={Assets.EmptyWhiteBg} description={""} />
              //   ),
              // }}
              pagination={false}
              // pagination={{
              //   onChange: handlePaginationChange,
              //   pageSize: !isPaginate ? gallerys?.data.length : perPage,
              //   current: !isPaginate ? 1 : page,
              //   showSizeChanger: false,
              //   total: gallerys?.meta?.total,
              //   pageSizeOptions: [10, 20, 50, 100, "All"],
              // }}
              loading={tableLoader(isGallerysLoading)}
              store={{ store: "gallerys", param: "gallerys" }}
              columns={columns}
              dataSource={gallerys?.data}
              bordered={false}
              scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
              responsive={true}
              size={"large"}
              showSorterTooltip={false}
              className="flashCardTable imageTable" // customHeightTable class has been remove to remove scroll
              rowKey={(record) => record.key}
            />
          </ConfigProvider>
        </div>
        {/* Share Modal */}
        <ShareUploadMediaModal
          shareLink={url}
          data={{
            type: selectedData?.type,
            key: selectedData?.key,
            name: selectedData?.alertTitle?.title,
            description: null,
            image: selectedData?.alertTitle?.image,
            start_date: selectedData?.created
              ? moment(selectedData?.created).format("MMM DD, yyyy")
              : "-",
            start_time: selectedData?.created
              ? moment(selectedData?.created).format("hh:mm a")
              : "-",
          }}
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Media"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this media with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Introduction to L’Oreal Color",
            para1: "Improve your Business knowledge with this flashcard set.",
            para2: "10 Cards • Feb 12, 2023",
          }}
        />

        {/* Delete Modal */}
        <DeleteModal
          disable={disable}
          handleDelete={handleDelete}
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Media"
          para={
            <>
              Are you sure you want to delete
              <b> {selectedData?.name || "this media"} ?</b>
              <br />
              Important: This cannot be undone.
            </>
          }
          notificationMessage="Image"
        />
        <GenericModal
          show={galleryAddEditForm.show}
          onHide={() => {
            dispatch(
              setGalleryAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={() => {
            dispatch(
              setGalleryAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          modalTitle={getUploadModalTitle()}
          desc={true}
          modalDesc={
            "Use unique file names to help you find this resource in the future."
          }
          pro="Pro Tip:"
          size={"lg"}
          bodyComponent={
            <UploadModal
              selectedData={selectedData}
              setModalShow={(e) => {
                dispatch(
                  setGalleryAddEditForm({
                    show: e,
                    formType: null,
                  }),
                );
              }}
            />
          }
        />
      </div>
    </>
  );
};

export default GalleryPage;
