import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Space, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import { link_types } from "../utils/constants";
import {
  BinocularsLight,
  EditIcon,
  MoreIcon,
  RecycleBin,
} from "../constants/svg";
import { assignOnBoardingURL } from "../utils/helper";

const getNewHireCheckListTableColumns = (
  setShowModal,
  handleOpenEditForm,
  setNewHiringCheckListIDForDelete,
  handleSetSelectedData,
) => {
  return [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink
          to={`/team/onboarding/details/${record.id}`}
          className={"black truncateTaskName truncateUploadTitle"}
        >
          {record.name}
        </NavLink>
      ),
      width: "150px",
    },
    {
      title: "Task Link",
      dataIndex: "link",
      key: "link",
      align: "left",
      width: "150px",
      sorter: false,
      render: (text, record) => {
        if (link_types.external == record.link_type && record.link) {
          return (
            <a
              href={record.link}
              target="_blank"
              style={{ color: "#7b68ee" }}
              className="globalBlueLinkColor truncateTaskLink"
              rel="noreferrer"
            >
              {record.link || "-"}
            </a>
          );
        } else if (link_types.internal == record.link_type) {
          return (
            <NavLink
              className={"truncateTaskLink"}
              style={{ color: "#7b68ee" }}
              to={assignOnBoardingURL(record.link_category, record.link_id)}
            >
              {record?.meta?.link_data?.name
                ? record?.meta?.link_data?.name
                : record?.meta?.link_data?.title
                  ? record?.meta?.link_data?.title
                  : "-"}
            </NavLink>
          );
        }

        return "-";
      },
    },
    {
      title: "Done",
      dataIndex: "done",
      className: "center-column",
      key: "done",
      render: (text, record) =>
        renderAttendeesColumn({
          count: record?.completedUsers?.length,
          attendents: record?.completedUsers?.map((elem) => elem?.user?.name),
        }),
      align: "center",
      width: "150px",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      width: "100px",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: uuidv4(),
                  label: (
                    <>
                      <NavLink
                        to={`/team/onboarding/details/${record.id}`}
                        className={"black"}
                      >
                        <BinocularsLight />
                        View
                      </NavLink>
                    </>
                  ),
                },
                {
                  key: uuidv4(),
                  label: (
                    <>
                      <div
                        onClick={() => {
                          handleOpenEditForm(record);
                        }}
                      >
                        <EditIcon />
                        Edit
                      </div>
                    </>
                  ),
                },
                {
                  key: uuidv4(),
                  label: (
                    <>
                      <div
                        onClick={() => {
                          setShowModal((prevState) => ({
                            ...prevState,
                            shareModal: true,
                          }));
                          handleSetSelectedData(record);
                        }}
                      >
                        <EditIcon />
                        Share
                      </div>
                    </>
                  ),
                },
                {
                  key: uuidv4(),
                  label: (
                    <div
                      className={"deleteLabel"}
                      onClick={() => {
                        setNewHiringCheckListIDForDelete(record.id);
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }));
                      }}
                    >
                      <RecycleBin />
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];
};

function renderAttendeesColumn(attendees) {
  if (!attendees) {
    return null;
  }
  const attendeeNames = attendees.attendents || [];
  const attendeeTooltipContent = (
    <div className="attendeeNames">
      {attendeeNames.slice(0, 7).map((name, index) => (
        <React.Fragment key={index}>
          <span>{name}</span>
          {index < attendeeNames.slice(0, 7).length - 1}
        </React.Fragment>
      ))}
      {attendeeNames.length > 7 && (
        <>
          <strong>+{attendeeNames.length - 7} more</strong>
        </>
      )}
    </div>
  );

  return (
    <div className="">
      {attendees.count != 0 ? (
        <Tooltip
          title={attendeeTooltipContent}
          placement="bottom"
          overlayClassName="custom-tooltip"
          color="purple"
          // open={true}
        >
          <div className="seenBy dottedRoundedBox">
            <span>{attendees.count}</span>
          </div>
        </Tooltip>
      ) : (
        <div className="seenBy dottedRoundedBox">
          <span>{attendees.count}</span>
        </div>
      )}
    </div>
  );
}

export default getNewHireCheckListTableColumns;
