import { createSlice } from "@reduxjs/toolkit";

export const positionSlice = createSlice({
  name: "positions",
  initialState: {
    positions: {
      meta: null,
      data: [],
    },
  },
  reducers: {
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
  },
});

export const { setPositions } = positionSlice.actions;

export default positionSlice.reducer;
