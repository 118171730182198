import React from "react";
import { ConfigProvider } from "antd";
import EmptyTableView from "../../EmptyTables/EmptyTableView";
import { tableLoader } from "../../../utils/helper";
import { Assets } from "../../../constants/images";
import ActivityTable from "./ActivityTable";

const Activities = (props) => {
  return (
    <>
      <div
        className={
          props?.data?.length == 0 && !props?.isLoading
            ? "task__container_empty"
            : ""
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <EmptyTableView
              image={Assets.notificationIcon}
              boldText="Welcome to Salon Symphony!"
              infoText="Activity will appear here as soon as you add your team!"
              action={() => {
                window.open(
                  "https://support.mysalonsymphony.com/hc/en-us/articles/18673510182939-Inviting-Users",
                  "_blank",
                );
              }}
              style={{ paddingTop: "16px" }}
            />
          )}
        >
          <ActivityTable
            getData={props?.getData}
            loading={tableLoader(props?.isLoading)}
            page={props?.page}
            store={props?.store}
            readType={props?.readType}
            activeTab={props.activeTab}
            filterKeys={props.filterKeys}
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default Activities;
