import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { PlansBullets } from "../../constants/svg";
import { Button, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoragePaymentLink,
  getStorages,
} from "../../services/storages/frontend-storages";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { GetSalonByID } from "../../services/global/global";
import { setSingleSalon } from "../../store/slices/globalSlice";

const StoragePage = () => {
  const storageState = useSelector((state) => state.storage);

  const { currentSalonId } = useSelector((state) => state.global);
  const currentSalon = useSelector((state) => state.global.salon);

  const { currentSubscriptionDetail, callCheckSubscriptionAPI } = useSelector(
    (state) => state.subscription,
  );
  const authUser = GetAuthUserLocalStorage();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [pmLinkLoading, setPMLinkLoading] = useState({
    id: "",
  });
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const getAvailableStoragePercentage = () => {
    const total = currentSubscriptionDetail?.meta?.total_storage || 0;
    const used = currentSubscriptionDetail?.salon?.storage_available_gbs || 0;
    let freeStoragePercentage = ((total - used) / total) * 100;
    let usedPercentage = 100 - freeStoragePercentage;
    setProgress(usedPercentage);
  };

  const fetchStorages = async () => {
    const params = {
      page,
      "per-page": perPage,
      is_paginated: 1,
      salon_id: currentSalonId,
    };

    await getStorages(params, dispatch);
  };

  const getSalonByID = async () => {
    try {
      const res = await GetSalonByID(authUser?.salon?.id);
      const { data } = res.data;
      dispatch(setSingleSalon(data));
    } catch (e) {}
  };

  const redirectToPaymentLink = async (id) => {
    setPMLinkLoading({
      id,
    });
    let data = await getStoragePaymentLink(id, dispatch);
    if (data?.payment_link) window.open(data.payment_link, "_blank");
    setPMLinkLoading({
      id: "",
    });
  };

  useEffect(() => {
    getSalonByID();
    fetchStorages();
  }, [currentSalonId]);

  useEffect(() => {
    getAvailableStoragePercentage();
  }, [currentSubscriptionDetail, callCheckSubscriptionAPI]);

  return (
    <>
      <section className={"billingpagewrapper"} style={{ overflowY: "auto" }}>
        <div className={"billingheader"}>
          <Row>
            <Col lg={9} md={9}>
              <h2 className="sec-heading cloudHeading">Cloud Storage</h2>
              <p className={"mt-26 billing-paragraph"}>
                When you've used up your free storage, you can upgrade or{" "}
                <NavLink to="/settings/uploads">delete files » </NavLink>
              </p>
            </Col>

            <Col lg={3} md={3} className={"text-center progressheader mt-26  "}>
              <p className="billing-paragraph currentPlanPara">
                <strong>
                  Current Plan: {currentSubscriptionDetail?.meta?.total_storage}{" "}
                  GB
                </strong>{" "}
              </p>
              <Progress
                percent={progress}
                showInfo={false}
                strokeColor={{ from: "#7B68EE", to: "#E806A0" }}
                size={"small"}
              />
              <p className={"text-center billing-paragraph"}>
                {currentSubscriptionDetail?.salon?.storage_available_gbs
                  ? currentSubscriptionDetail?.salon?.storage_available_gbs.toFixed(
                      2,
                    )
                  : 0}{" "}
                GB of {currentSubscriptionDetail?.meta?.total_storage} GB used
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={4} className={"pe-0"}></Col>
          </Row>
        </div>

        <div className={"planbilling-area"}>
          <Row>
            {storageState?.storages?.data?.data
              ?.slice()
              ?.sort((a, b) => a.storage - b.storage)
              .map((item) => {
                const isBought = !!currentSalon?.storages.find(
                  (x) => x.storage === item.storage,
                );

                return (
                  <>
                    <Col lg={4} md={4}>
                      <div className={"planbilling"}>
                        <div className={"planA1"}>
                          <h3 className={"billingheadings"}>
                            {item.storage} {item.storageUnitName}
                          </h3>
                          <div className={"billingcontent"}>
                            Additional Cloud Storage
                          </div>
                          <div className={"billingprices"}>
                            {`${item.currencyUnit}${item.price}/month`}
                            {/* {`${item.currencyUnit}${item.price}`} */}
                          </div>

                          <div className={""}>
                            {pmLinkLoading?.id == item.id ? (
                              <div className="load black" />
                            ) : !isBought ? (
                              <NavLink
                                to=""
                                className={
                                  pmLinkLoading.id != item.id &&
                                  "btnplan btn-gradient"
                                }
                                onClick={() => redirectToPaymentLink(item.id)}
                              >
                                Buy Now
                              </NavLink>
                            ) : (
                              <Button
                                className={
                                  "btnplan btn-gradient btnplan__disabled"
                                }
                              >
                                Current Plan
                              </Button>
                            )}
                          </div>
                        </div>
                        {item?.description && (
                          <div className={"planB1"}>
                            <ul>
                              {item?.description?.split(",").map((point) => {
                                return (
                                  <>
                                    <li>
                                      <PlansBullets></PlansBullets>
                                      {point}
                                    </li>
                                  </>
                                );
                              })}
                              {/* <li>
                            <PlansBullets></PlansBullets>Save $30 when billed
                            annually
                          </li>
                          <li>
                            <PlansBullets></PlansBullets>Access 24/7 Support
                          </li> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default StoragePage;
