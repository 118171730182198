import { Avatar } from "antd";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Assets } from "../../constants/images";
import {
  setAddRemoveMemberInSendChatPopup,
  setSendChatSelectedUsers,
  setShowHideChatModel,
  setStatUserHover,
} from "../../store/slices/globalSlice";
import AvatarImage from "./AvatarImage";

const ProfileHover = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);
  return (
    <>
      <div className="profile-hover">
        <div className="avatar-overlay-content">
          <div className="avatar-icon">
            <AvatarImage
              name={user?.name}
              src={user?.image}
              placeholder={Assets.ProfilePlaceholder}
              size={48}
            />
          </div>
          <h4 className="userName">{user?.name || "-"}</h4>
          {user?.position && (
            <p className="userTitle m-0">{user?.position || "-"}</p>
          )}
          <div className="avatar-buttons">
            <Button
              type="button"
              className={"view-profile border-grey-log-btn"}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setStatUserHover(null));
                navigate(`/team/members/${user?.id}`);
              }}
            >
              View Profile
            </Button>
            {user?.id != me?.data?.id ? (
              <Button
                type="button"
                className={"messageBtn"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setAddRemoveMemberInSendChatPopup(false));
                  dispatch(
                    setSendChatSelectedUsers([
                      {
                        label: user?.name,
                        image: user?.image,
                        value: user?.id,
                      },
                    ]),
                  );
                  dispatch(setStatUserHover(null));
                  dispatch(setShowHideChatModel(true));
                }}
              >
                Message
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHover;
