import api from "../index";
import { apiUrl } from "../../utils/constants";
import { GetAuthUserLocalStorage } from "../localStorage/localStorage";

export const CheckFileUploadStorageAvailable = (params) =>
  api.get(
    `${apiUrl.salon_check_storage_available}/${
      GetAuthUserLocalStorage()?.salon?.id
    }`,
    { params },
  );

export const UpdateFileUploadStorageSizeAvailability = (size) =>
  api.post(
    `${apiUrl.salon_check_storage_available}/${
      GetAuthUserLocalStorage()?.salon.id
    }?size=${size}`,
  );

export const GetCategoryTags = (params) =>
  api.get(`${apiUrl.categoryTags}`, { params });

export const GetSalonByID = (id) => api.get(`${apiUrl.salon}/${id}`);

export const DeleteSalonByID = (id) => api.delete(`${apiUrl.salon}/${id}`);

export const GetAllSalons = (params) => api.get(`${apiUrl.salon}`, { params });

export const GetSalonSegments = (params) =>
  api.get(`${apiUrl.salonSegments}`, { params });

export const UpdateSalon = (id, payload) =>
  api.put(`${apiUrl.salon}/${id}`, payload);

export const CheckTitle = (params) =>
  api.get(`${apiUrl.checkTitle}`, { params });
