import {
  ceil,
  compact,
  filter,
  flatMap,
  groupBy,
  includes,
  keys,
  map,
  minBy,
  reject,
  size,
  uniqBy,
  without,
} from "lodash";
import { task_repeat_forever, task_type } from "./constants";
import moment from "moment";

export const checkIsDefaultGroupAvatar = (imageSrc) => {
  return includes(imageSrc, "group-icon.png");
};

export const distinctUpcomingTasks = (tasks) => {
  const groupedData = groupBy(tasks, "parentId");
  const parentIds = without(keys(groupedData), "null", null, "");
  const duplicatedUpcomingData = map(parentIds, (parentId) => {
    const childrenTasks = groupedData[parentId];
    if (size(childrenTasks) <= 1) return [];
    const earlyTask = minBy(childrenTasks, "dueDate");
    return without(map(childrenTasks, "key"), earlyTask?.key);
  });
  const needRemovedIds = compact(flatMap(duplicatedUpcomingData));
  return reject(tasks, ({ key }) => includes(needRemovedIds, key));
};

export const nextOccurenceRepeatDate = (date, repeatType) => {
  const inputDate = moment(date);
  const today = moment();
  const gapDays = today.diff(inputDate, "days");

  if (gapDays > 0) {
    if (repeatType === task_type.monthly) {
      const isLastDayOfMonth = inputDate.date() > 28;
      if (isLastDayOfMonth) {
        return today.clone().endOf("month").format("YYYY-MM-DD");
      }
      return inputDate.clone().add(1, "month").format("YYYY-MM-DD");
    }
    if (repeatType === task_type.weekly || repeatType === task_type.bi_weekly) {
      const noRepeatedDays = repeatType === task_type.weekly ? 7 : 14;
      const noAddDays = ceil(gapDays / noRepeatedDays) * noRepeatedDays;
      return inputDate.clone().add(noAddDays, "days").format("YYYY-MM-DD");
    }
  }

  return date;
};

export const nextOccurenceRepeatTask = (task) => {
  const { type, dueDate } = task;
  return {
    ...task,
    dueDate: nextOccurenceRepeatDate(dueDate, type),
  };
};

export const isYesterday = (date) => {
  const inputDate = moment(date);
  const today = moment();
  const diffInDays = today.diff(inputDate, "days");
  return diffInDays === 1;
};

export const getShowUpcomingTasks = (tasks, userId) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const allTasksAfterFilter = filter(
    tasks,
    (task) =>
      moment(task.dueDate).isSameOrAfter(currentDate) ||
      task.repeat === task_repeat_forever,
  );
  const futureTasks = distinctUpcomingTasks(allTasksAfterFilter);
  const reupTasks = filter(
    tasks,
    (task) => isYesterday(task.dueDate) && !includes(task.doneUserIds, userId),
  );
  const rawUpcomingTasks = uniqBy([...futureTasks, ...reupTasks], "key");

  return map(rawUpcomingTasks, (task) =>
    task.repeat === task_repeat_forever ? nextOccurenceRepeatTask(task) : task,
  );
};
