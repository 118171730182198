import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ModalCloseIcon } from "../../../constants/svg";
import { UploadGallery } from "../../../services/galleries/galleries";
import { UpdateFileUploadStorageSizeAvailability } from "../../../services/global/global";
import { setFolderId } from "../../../store/slices/gallerysSlice";
import { gallery_type, roles as salonRoles } from "../../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../../utils/helper";
import { maxBounds } from "../../../constants/contants";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import FileUploader from "../../Common/FileUploader";

const FolderResourceUploadModal = ({ setModalShow, getSingleGallery }) => {
  const { folderId, uploadType, renderList } = useSelector(
    (state) => state?.gallerys,
  );
  const { currentSalonId } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      file: "",
      name: "",
    },
  });

  const [disable, setDisable] = useState(false);

  const getUploadAccept = () => {
    let acceptType;

    switch (uploadType) {
      case gallery_type.image:
        acceptType = ".png,.jpg,.jpeg";
        break;

      case gallery_type.video:
        acceptType = "video/*";
        break;

      case gallery_type.audio:
        acceptType = "audio/*";
        break;

      case gallery_type.pdf:
        acceptType = ".pdf";
        break;
    }
    return acceptType;
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: getUploadAccept(),
    beforeUpload: (file) => {
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  const onSubmit = async (data) => {
    try {
      const apiData = {
        name: data?.name,
        type: uploadType,
        salon_id: currentSalonId,
        folder_id: folderId,
      };

      setDisable(true);

      let salonS3UploadResponse;

      salonS3UploadResponse = await salonUploadToS3(
        data.file,
        "uploads/gallery",
        false,
        false,
      );
      if (salonS3UploadResponse.status) {
        apiData.link = salonS3UploadResponse?.data?.location;
        apiData.size = salonS3UploadResponse?.data?.sizeD;
      }

      const res = await UploadGallery(apiData);

      if (
        GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin &&
        salonS3UploadResponse
      ) {
        await UpdateFileUploadStorageSizeAvailability(
          salonS3UploadResponse.data.size,
        );
      }
      getSingleGallery();
      setDisable(false);
      setModalShow(false);
      dispatch(setFolderId(null));
      toastMessage("success", res.data.message);
    } catch (e) {
      toastMessage("warning", e.message);
      setDisable(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="DocName"
                label="File Name"
                className="mb-20"
              >
                <Form.Control
                  type="text"
                  placeholder="File Name"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.name ? "hasError" : ""}
                  maxLength={maxBounds.NAME}
                  {...register("name", {
                    required: {
                      value: true,
                    },
                    maxLength: {
                      value: maxBounds.NAME,
                    },
                  })}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <p className="small-font mb-4">Upload File (Max: 10MB)</p>
              <div className="addLinkBox">
                <Controller
                  control={control}
                  name="file"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FileUploader
                      {...uploadProps}
                      fileList={value == "" ? [] : [value]}
                      onChange={(e) => {
                        const fileList = e.fileList;
                        onChange(
                          !fileList.length
                            ? ""
                            : fileList.length > 1
                              ? fileList[1]
                              : fileList[0],
                        );
                      }}
                      className={`border-0 bg-0 ${value == "" ? "hasError" : ""}`}
                      listType="picture"
                      limitMegabyteSize={10}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {!disable && (
            <Button type="submit" className={" w-100"} disabled={!isValid}>
              Upload Media
            </Button>
          )}

          {disable && (
            <Button type="submit" className={" w-100"} disabled={true}>
              <div className="load black" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default FolderResourceUploadModal;
