import { Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GetTicketList } from "../../../services/tickets/tickets";
import { setPagination, setTickets } from "../../../store/slices/ticketSlice";
import { tableLoader, toastMessage } from "../../../utils/helper";
import { BinocularsLight } from "../../../constants/svg";
import { constant, tableHeight } from "../../../utils/constants";
import LazyLoadTable from "../../Table/LazyLoadTable";

const Tickets = () => {
  const dispatch = useDispatch();
  const { pagination, tickets, filters } = useSelector(
    (state) => state.tickets,
  );

  const [loadingTickets, setLoadingTickets] = useState(false);

  const getTickets = async () => {
    try {
      const payload = {
        "per-page": pagination.perPage,
        page: pagination.page,
        is_paginated: 1,
      };
      if (filters.status && filters.status != "all") {
        payload.status = filters.status;
      }
      setLoadingTickets(true);
      const response = await GetTicketList(payload);
      const { status, message, data } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }

      const dataM = data.results.map((elem) => ({
        status: elem.status,
        subject: elem.subject ? elem.subject : "-",
        requester_id: elem.requester_id,
        created_at: moment(elem.created_at).format("MMM DD, yyyy"),
        type: elem.type ? elem.type : "-",
        priority: elem.priority ? elem.priority : "-",
        url: constant.ZENDESK_TICKET_URL + elem.id,
      }));

      dispatch(
        setTickets({
          data: dataM,
          total: data.count,
        }),
      );
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoadingTickets(false);
    }
  };

  useEffect(() => {
    getTickets();
  }, [JSON.stringify(pagination), JSON.stringify(filters)]);

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <a
              href={record?.url}
              target="_blank"
              className={"black"}
              rel="noreferrer"
            >
              <BinocularsLight />
              View
            </a>
          </>
        ),
      },
    ];
  };
  const columns = [
    {
      width: "10%",
      title: "Ticket Status",
      dataIndex: "ticket_status",
      key: "ticket_status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <span className="open_staus" style={{ textTransform: "capitalize" }}>
          {record.status}
        </span>
      ),
    },
    // {
    //     width: "30%",
    //     title: "Subject",
    //     dataIndex: "subject",
    //     key: "subject",
    //     sorter: (a, b) => a.subject.localeCompare(b.subject),
    //     render: (text, record) => <p>{record.subject}</p>,
    // },
    {
      width: "20%",
      title: "Requester",
      dataIndex: "requester",
      key: "requester",
      sorter: false,
      render: (text, record) => <p>{record.requester_id}</p>,
    },
    {
      width: "20%",
      title: "Requested",
      dataIndex: "requested",
      key: "requested",
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => <p>{record.created_at}</p>,
    },
    {
      width: "10%",
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.type}</p>,
    },
    {
      width: "10%",
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      sorter: (a, b) => a.priority.localeCompare(b.priority),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.priority}</p>,
    },
    /* {
            title: "More",
            key: "action",
            align: "center",
            render: (record) => (
                <Space size="middle">
                    <Dropdown
                        menu={{
                            items: actionMenu(record),
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayClassName={"defaultDropdown"}
                    >
                        <div className="icon cursor-pointer">
                            <MoreIcon />
                        </div>
                    </Dropdown>
                </Space>
            )
        } */
  ];

  return (
    <>
      <LazyLoadTable
        columns={columns}
        dataSource={tickets.data}
        loading={tableLoader(loadingTickets)}
        bordered={false}
        scroll={{ x: "max-content", y: tableHeight?.height }}
        responsive={true}
        size={"large"}
        showSorterTooltip={false}
        className="flashCardTable tickets-datatable"
        rowKey={(record) => record.id}
        store={{ store: "tickets", param: "tickets" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              window.open(record.url, "_blank");
            },
          };
        }}
        pagination={{
          onChange: (currPage) =>
            dispatch(setPagination({ ...pagination, page: currPage })),
          pageSize: pagination.perPage,
          current: pagination.page,
          total: tickets.total,
          pageSizeOptions: [10, 20, 50, 100, "All"],
          showSizeChanger: false,
        }}
        virtual
      />
    </>
  );
};

export default Tickets;
