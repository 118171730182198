import { createSlice } from "@reduxjs/toolkit";

export const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    isLocationsLoading: true,
    isReloadLocation: 1,

    locations: {
      meta: null,
      data: [],
    },

    singleLocation: {
      isSingleLocationLoading: true,
      data: null,
    },

    formData: {
      addedMembers: [],
    },
  },
  reducers: {
    setIsLocationsLoading: (state, action) => {
      state.isLocationsLoading = action.payload;
    },
    setLocations: (state, action) => {
      state.locations.data = action.payload.data;
      state.locations.meta = action.payload.meta;
    },

    setIsSingleLocationLoading: (state, action) => {
      state.singleLocation.isSingleLocationLoading = action.payload;
    },
    setSingleLocation: (state, action) => {
      state.singleLocation.data = action.payload;
    },

    setAddMember: (state, action) => {
      state.formData.addedMembers = action.payload;
    },

    setRemoveMember: (state, action) => {
      let data = [...state.formData.addedMembers];
      const index = data.findIndex((obj) => obj.id == action.payload);
      data.splice(index, 1);
      state.formData.addedMembers = data;
    },
    setReloadLocation: (state, action) => {
      state.isReloadLocation = action.payload;
    },
  },
});

export const {
  setIsLocationsLoading,
  setLocations,
  setReloadLocation,

  setIsSingleLocationLoading,
  setSingleLocation,

  setAddMember,
  setRemoveMember,
} = locationsSlice.actions;

export default locationsSlice.reducer;
