import { Dropdown, Image, notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import {
  BinocularsLight,
  EditIcon,
  MoreIcon,
  NoDataIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../../constants/svg";
import {
  deleteGoal,
  getAllGoals,
  getGoals,
  getGoalsMore,
} from "../../../services/goals/frontend-goals";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setActiveGoals,
  setAllGoalsLoading,
  setEmptyAllGoals,
  setIsActiveGoalsLoading,
} from "../../../store/slices/goalsSlice";
import {
  goal_type,
  SHARE_URL,
  target_types,
  user_goal_status,
} from "../../../utils/constants";
import DeleteModal from "../../Modals/DeleteModal";
import GenericModal from "../../Modals/GenericModal";
import ShareModal from "../../Modals/ShareModal";
import GoalModalForms from "./Modals/GoalModalForms";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import InfiniteScroll from "react-infinite-scroll-component";
import AvatarImage from "../../Common/AvatarImage";

const ActiveCards = (props) => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { isActiveGoalsLoading, activeGoals } = useSelector(
    (state) => state.goals,
  );
  const { currentSalonId } = useSelector((state) => state.global);
  const url = window.location.href;
  const [disable, setDisable] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const methods = useForm();
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState("Create New Goal");
  const [stepStatus, setStepStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [goals, setGoals] = useState({ data: null, meta: null });

  const onClose = () => {
    setOpen(false);
  };

  const handleSetSelectedData = (record, index) => {
    setSelectedData({
      index,
      type: record?.type,
      key: record?.id,
      name: record?.name,
      description: record?.description,
      image: null,
      start_date: record?.start_date,
      start_time: moment(record?.start_date).format("hh:mm a"),
    });
  };

  const actionMenu = (record, index) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink to={`/goals/details/${record?.id}`} className={"black"}>
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                // setOpen(true);
                setShowGenericModal(true);
                handleSetSelectedData(record, index);
              }}
            >
              <EditIcon />
              Edit
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record, index);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              handleSetSelectedData(record, index);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const handleDelete = async () => {
    await deleteGoal(
      selectedData,
      setDisable,
      showModal,
      setShowModal,
      dispatch,
    );
    await getAllGoals(currentSalonId, dispatch);
  };

  const fetchGoals = async () => {
    const params = {
      is_paginated: 1,
      salon_id: currentSalonId,
      type: props?.selectedGoalType,
      viewed_status: user_goal_status?.active,
      page: props?.page?.currentPage,
      "per-page": props?.page?.perPage,
      "order-column": "end_date",
      order: "asc",
    };
    await getGoals(params, dispatch, setIsActiveGoalsLoading, setActiveGoals);
  };

  useEffect(() => {
    dispatch(setAllGoalsLoading());
    dispatch(setEmptyAllGoals());
  }, []);

  useEffect(() => {
    fetchGoals();
  }, [
    props?.selectedGoalType,
    props?.page?.currentPage,
    props?.page?.perPage,
    currentSalonId,
  ]);

  const fetchMoreData = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        let next_page = goals.meta.current_page + 1;
        const params = {
          is_paginated: 1,
          salon_id: currentSalonId,
          type: props?.selectedGoalType,
          viewed_status: user_goal_status?.active,
          page: next_page,
          "per-page": props?.page?.perPage,
          "order-column": "end_date",
          order: "asc",
        };

        const response = await getGoalsMore(params);
        const newData = response.data;

        const hasPostsData = response.data?.length > 0;
        if (hasPostsData) {
          const updatedData = [...goals.data, ...newData];
          if (
            response?.meta?.total &&
            response?.meta?.total <= updatedData?.length
          ) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setGoals({ meta: response?.meta, data: updatedData });
          setLoading(false);
        }
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setLoading(false);
      }
    }, 1500);
  };

  useEffect(() => {
    setGoals(activeGoals);
    if (activeGoals?.data?.length == activeGoals?.meta?.total) {
      setHasMore(false);
    }
  }, [activeGoals]);

  return (
    <>
      <div>
        {isActiveGoalsLoading ? (
          <LoadingScreen child={true} />
        ) : goals?.data?.length <= 0 ? (
          <div className="noSearchData">
            <NoDataIcon />
            <h5>Goals you've created will appear here.</h5>
            <h6>Click "Create Goal" to get started.</h6>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={goals?.data?.length ? goals?.data?.length : 0}
            next={fetchMoreData}
            scrollableTarget="infinite-scroll-component "
            hasMore={hasMore} // You can conditionally set this based on your data
            loader={
              loading ? (
                <div className="loader-chat">
                  <Image
                    width={100}
                    height={100}
                    src={Assets.loadingGif}
                    preview={false}
                  />
                </div>
              ) : null
            }
            height={"calc(100vh - 192px)"}
          >
            <Row>
              {goals?.data?.map((item, index) => (
                <Col lg={4} xxl={3} key={index}>
                  <div className={`goalsCard ${props?.className}`}>
                    <div className="goalsHeader">
                      <p className="years">{item?.typeName}</p>
                      <Dropdown
                        menu={{
                          items: actionMenu(item, index),
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayClassName={"defaultDropdown"}
                      >
                        <div className="icon cursor-pointer">
                          <MoreIcon />
                        </div>
                      </Dropdown>
                    </div>
                    <NavLink to={`/goals/details/${item?.id}`}>
                      <div className="goalsBody">
                        <AvatarImage
                          name={item?.assigned?.name}
                          src={item?.assigned?.image}
                          placeholder={Assets.ProfilePlaceholder}
                          size={50}
                        />
                        <h5 className="heading">{item?.name || "-"}</h5>
                        <h6 className="price">
                          {item?.target_type == target_types.dollar && "$"}
                          {item?.target_value}
                          {item?.target_type == target_types.percentage && "%"}
                        </h6>
                      </div>
                      <div className="goalsFooter">
                        <p className="targetDate m-0 text-center">
                          Target Date:{" "}
                          {item?.end_date
                            ? moment(item?.end_date).format("MMM DD, yyyy")
                            : "-"}
                        </p>
                      </div>
                    </NavLink>
                  </div>
                </Col>
              ))}
              {/* <Col xs={12} className="text-center mb-4"> */}
              {/*  { */}
              {/*    props?.page?.currentPage < activeGoals?.meta?.last_page ? */}
              {/*      isActiveGoalsLoading ? <div className="load black" /> */}
              {/*        : */}
              {/*        <Button onClick={() => props.setPage({ ...props?.page, currentPage: props?.page?.currentPage + 1 })}>Load More</Button> */}
              {/*      : "" */}
              {/*  } */}
              {/* </Col> */}
            </Row>
          </InfiniteScroll>
        )}
      </div>

      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Goal"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this goal with members.
          </>
        }
        data={selectedData}
        shareLink={SHARE_URL.GOALS + selectedData?.key}
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Goal?"
        handleDelete={handleDelete}
        disable={disable}
        para={
          <>
            Are you sure you want to delete{" "}
            <b>
              {selectedData?.type == goal_type.custom
                ? "custom goal"
                : "recurring goal"}
              ?
            </b>
            <br />
            Important: This goal cannot be recovered.
          </>
        }
        noti
        notificationMessage="Goal"
      />
      {/* Edit Drawer */}
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={selectedCard}
        mt10="custom-mt-10"
        size={"lg"}
        goal={true}
        bodyComponent={
          <FormProvider {...methods}>
            <GoalModalForms
              setModalShow={setShowGenericModal}
              setStepStatus={setStepStatus}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              methods={methods}
              isedit={true}
              selectedData={selectedData}
            />
          </FormProvider>
        }
      />
    </>
  );
};

export default ActiveCards;
