import { notification } from "antd";
import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Assets } from "../../constants/images";
import {
  ModalCloseIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";

const NewMessageModal = (props) => {
  const [isTyped, setIsTyped] = useState(true);
  const handleInputChange = (event) => {
    const message = event.target.value;
    if (message.length > 0) {
      setIsTyped(false);
    }
  };
  const handleClose = () => {
    notification.success({
      message: "Message Sent",
      description: "Direct Message sent!",
      placement: "bottomRight",
      // duration: 0,
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
    props.setShowGenericModal(false);
  };
  const handleTextareaBlur = (event) => {
    if (event.target.value == "") {
      setIsTyped(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal userProfileModal newMessageModal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" text-center w-100"
          >
            New Message
          </Modal.Title>

          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        {props.desc == true ? (
          <div className="text-left location-desc-padding custom-height-20">
            <b>{props.pro}</b> {props.modalDesc}
          </div>
        ) : (
          <div
            className="text-center pb-4"
            style={{ display: props.modalDesc ? "block" : "none" }}
          >
            {props.modalDesc}
          </div>
        )}

        {/* All body Compponents goes here... */}
        <div className="user-selection ">
          <FloatingLabel
            controlId="messageTextArea"
            label="Type your message here..."
            className="mb-20"
          >
            <Form.Control
              as="textarea"
              placeholder="Type your message here..."
              style={{ height: "150px" }}
              onChange={handleInputChange}
              onBlur={handleTextareaBlur}
            />
          </FloatingLabel>
          <div className="userFooter d-flex justify-content-between align-items-center">
            <div className="iconsImageBox">
              <img src={Assets.iconsImage} />
            </div>
            <Button disabled={isTyped} onClick={handleClose}>
              Direct Message
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewMessageModal;
