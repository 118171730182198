import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetSalonResources = (params) =>
  api.get(apiUrl.salon_resources, { params });
export const GetSalonResourceById = (id) =>
  api.get(apiUrl.salon_resources + `/${id}`);
export const DeleteSalonResource = (id) =>
  api.delete(apiUrl.salon_resources + `/${id}`);
export const CreateResource = (payload) =>
  api.post(apiUrl.salon_resources, payload);
export const UpdateResource = (id, payload) =>
  api.put(apiUrl.salon_resources + "/" + id, payload);
