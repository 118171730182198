import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createGoal,
  getAllGoals,
  presetDataForEditGoals,
  updateGoal,
} from "../../../../services/goals/frontend-goals";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import { setCreateUpdateTitleModuleID } from "../../../../store/slices/globalSlice";
import {
  setGoalsFormData,
  setResetGoalsFormData,
} from "../../../../store/slices/goalsSlice";
import { goal_type, search_module_type } from "../../../../utils/constants";
import FirstStep from "./StepsForm/FirstStep";
import SecondStep from "./StepsForm/SecondStep";
import ThirdStep from "./StepsForm/ThirdStep";

const GoalModalForms = (props) => {
  const [step, setStep] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState("");
  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useFormContext();
  const [
    type,
    name,
    users,
    description,
    target_type,
    target_date,
    target_value,
    duration,
    start_date,
    end_date,
    selectedCategory,
  ] = watch([
    "type",
    "name",
    "users",
    "description",
    "target_type",
    "target_date",
    "target_value",
    "duration",
    "start_date",
    "end_date",
    "selectedCategory",
  ]);
  props.setStepStatus(step);
  const dispatch = useDispatch();
  const authUser = GetAuthUserLocalStorage();
  const { formData } = useSelector((state) => state?.goals);
  const { currentSalonId } = useSelector((state) => state.global);
  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const navigate = useNavigate();
  const [reRender, setReRender] = useState(0);

  const goalTitle = (option) => {
    if (option == goal_type?.weekly) {
      props.setSelectedCard(
        `${props?.isedit ? "Edit Weekly Goal" : "New Weekly Goal"}`,
      );
    } else if (option == goal_type?.monthly) {
      props.setSelectedCard(
        `${props?.isedit ? "Edit Monthly Goal" : "New Monthly Goal"}`,
      );
    } else if (option == goal_type?.annual) {
      props.setSelectedCard(
        `${props?.isedit ? "Edit Annual Goal" : "New Annual Goal"}`,
      );
    } else if (option == goal_type?.custom) {
      props.setSelectedCard(
        `${props?.isedit ? "Edit Custom Goal" : "New Custom Goal"}`,
      );
    } else {
      props.setSelectedCard(`${props?.isedit ? "Edit Goal" : "Create Goal"}`);
    }
    return null;
  };

  const handleSelect = (value) => {
    setSelected(value);
    setValue("type", value);
  };

  const returnUsers = (category) => {
    if (category == "everyone") {
      return users;
    } else if (category == "group") {
      let groups = salonTeams?.data?.groups?.filter((item) =>
        users?.includes(item?.id?.toString()),
      );
      let all_users = [];
      for (let i = 0; i < groups?.length; i++) {
        for (let j = 0; j < groups[i]?.users?.length; j++) {
          all_users.push(groups[i].users[j]?.id?.toString());
        }
      }
      return all_users;
    } else if (category == "member") {
      return users;
    }
  };

  const handleDisable = () => {
    if (props?.isedit) {
      if (!name || !description) {
        return true;
      }
    } else {
      if (!name || !description || users?.length <= 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const nextStep = () => {
    saveData();
    setStep(step + 1);
    goalTitle(selected);
  };

  const prevStep = () => {
    saveData();
    setStep(step - 1);
  };

  const saveData = () => {
    dispatch(
      setGoalsFormData({
        type,
        name,
        users,
        description,
        target_type,
        target_date,
        target_value,
        duration,
        start_date,
        end_date,
        selectedCategory,
      }),
    );
  };

  const resetForm = () => {
    dispatch(setResetGoalsFormData());
    setSelected("");
    setStep(1);
    setReRender(0);
    reset({
      type: "",
      name: "",
      description: "",
      target_type: null,
      target_date: null,
      target_value: "",
      duration: null,
      start_date: null,
      end_date: null,
      users: [],
      selectedCategory: "",
    });
  };

  const onCreateGoal = async () => {
    let apiData = {
      name,
      target_type,
      target_value,
      type,
      description,
      status: 10,
      users: returnUsers(selectedCategory),
      salon_id: currentSalonId,
      user_id: authUser?.id,

      ...(type == goal_type?.custom && {
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      }),

      ...(type == goal_type?.weekly && {
        day: target_date,
        duration,
      }),

      ...(type == goal_type?.monthly && {
        day: target_date,
        duration,
      }),
    };

    await createGoal(
      apiData,
      setDisabled,
      props?.setModalShow,
      resetForm,
      dispatch,
    );
  };

  const onUpdateGoal = async () => {
    let apiData = {
      name,
      target_type,
      target_value,
      type,
      description,
      status: 10,
      salon_id: currentSalonId,
      user_id: authUser?.id,

      ...(type == goal_type?.custom && {
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      }),

      ...(type == goal_type?.weekly ||
        (type == goal_type?.monthly && {
          day: target_date,
          duration,
        })),
    };
    const selectedData = {
      key: props?.selectedData?.key || props?.id,
    };

    await updateGoal(
      apiData,
      selectedData,
      setDisabled,
      props?.setModalShow,
      resetForm,
      dispatch,
    );
    navigate("/goals");
  };

  const fetchAndPresetSingleGoalData = async () => {
    const selectedData = {
      key: props?.selectedData?.key || props?.id,
    };
    await presetDataForEditGoals(selectedData, setSelected, dispatch);
  };

  const handleDisableSubmit = () => {
    if (props?.isedit) {
      if (disabled || !isValid || isSubmitting) {
        return true;
      } else {
        return false;
      }
    } else {
      if (disabled || !isValid || isSubmitting || users?.length <= 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onSubmit = async (data) => {
    if (props?.isedit) {
      await onUpdateGoal();
      await getAllGoals(currentSalonId, dispatch);
    } else {
      if (getValues("users")) {
        await onCreateGoal();
        await getAllGoals(currentSalonId, dispatch);
      }
    }
  };

  useEffect(() => {
    if (step == 1) {
      props?.setSelectedCard(
        `${props?.isedit ? "Edit Goal" : "Create New Goal"}`,
      );
    }
  }, [step]);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  useEffect(() => {
    if (props?.isedit) {
      fetchAndPresetSingleGoalData();
    } else {
      resetForm();
    }
  }, [props?.isedit, props?.show]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.goals));
  }, []);

  return (
    <>
      <Form className="goal-modal-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body tabs-modal">
          {step == 1 && (
            <FirstStep handleSelect={handleSelect} selected={selected} />
          )}
          {step == 2 && <SecondStep isedit={props?.isedit} />}
          {step == 3 && (
            <ThirdStep reRender={reRender} setReRender={setReRender} />
          )}
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 1 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={!selected}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={handleDisable()}
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {step == 3 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={handleDisableSubmit()}
                    type="submit"
                    className={"custom-width-332"}
                  >
                    {disabled ? (
                      <div className="load black" />
                    ) : (
                      `${props?.isedit ? "Edit Goal" : "Assign Goal"}`
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default GoalModalForms;
