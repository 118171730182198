import { Checkbox, Radio } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Assets } from "../../constants/images";
import { ArrowTriangleDown } from "../../constants/svg";
import { recipentsData } from "../../constants/treeSelectData";

const InputField = ({
  image = Assets.RoundedStaffIcon,
  label = "",
  title = "Recipents",
}) => {
  return (
    <>
      <div
        className={`${"labelHeadingBox d-flex justify-content-space align-items-center"} ${
          label ? "added" : null
        }`}
      >
        <div className="labelTitle">
          <p className={`title ${label && "highlighted"}`}>{title}</p>
          {label && <p className=" label">{label}</p>}
        </div>
        <div className="labelImage">
          <img src={image} />
        </div>
      </div>
    </>
  );
};

const TestPage = () => {
  const listopenRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dropdownData, setDropdownData] = useState(recipentsData);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleParentClick = (el, parentId) => {
    setAccordionOpen((prev) => !prev);

    setActiveItem(parentId);
    setDropdownData((current) =>
      current.map((el, id) => {
        if (id == parentId) {
          if (el.childern.length == 0) {
            return { ...el, isSelected: true };
          } else {
            return { ...el, isSelected: false };
          }
        }
        return { ...el };
      }),
    );
  };
  const handleChecked = (e, parentId, ChildId) => {
    setDropdownData((current) =>
      current.map((el, id) => {
        if (id == parentId) {
          let { childern } = el;
          let updatedChildern = childern.map((el, id) => {
            if (id == ChildId) {
              return { ...el, isSelected: true };
            }
            return { ...el };
          });
          return { ...el, isSelected: true, childern: updatedChildern };
        } else {
          let { childern } = el;
          let updatedChildern = childern.map((el, id) => {
            return { ...el, isSelected: false };
          });
          return { ...el, isSelected: false, childern: updatedChildern };
        }
      }),
    );
  };
  useEffect(() => {
    let availableImages = dropdownData.find((el) => {
      return el.isSelected;
    });
    setSelectedImage(availableImages);
  }, [dropdownData]);

  // fucntion to open mennu
  const handleOpenMenu = () => {
    if (listopenRef.current) {
      listopenRef.current.classList.toggle("open");
    }
  };

  // fucntion to close the menu when cliked outside
  const handleCloseMenu = () => {
    if (listopenRef.current) {
      listopenRef.current.classList.remove("open");
    }
  };
  return (
    <>
      {/* overlayDiv to close the DropdownMenu */}
      <div className="treeSelectOverlay" onClick={handleCloseMenu}></div>
      <div className="subChildSelectWrapper">
        <div
          className="subChildLabelHolder"
          ref={listopenRef}
          onClick={handleOpenMenu}
        >
          {selectedImage ? (
            <InputField
              image={selectedImage.image}
              label={selectedImage.value}
            />
          ) : (
            <InputField />
          )}
        </div>
        <div className="subChildItemsWrapper" ref={listopenRef}>
          <div className="acordionChild">
            {dropdownData.map((d, i) => {
              return (
                <>
                  <div className="subChildBox">
                    <div
                      className="accordianTitle"
                      onClick={() => {
                        handleParentClick(d, i);
                      }}
                      key={i}
                    >
                      {d.childern.length > 0 ? (
                        d.value
                      ) : (
                        <Radio checked={d.isSelected}> {d.value}</Radio>
                      )}
                      {d.childern.length > 0 ? <ArrowTriangleDown /> : null}
                    </div>

                    <ul
                      className={`${"accordianChildList"} ${
                        activeItem == i && accordionOpen ? "show" : ""
                      }`}
                    >
                      {d.childern.length > 0 &&
                        d.childern.map((childData, childKey) => {
                          return (
                            <li key={childKey}>
                              <Checkbox
                                // checked={childData.isSelected}
                                onChange={(e) => {
                                  handleChecked(e, i, childKey);
                                }}
                              >
                                {childData.value}
                              </Checkbox>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestPage;
