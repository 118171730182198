import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastCrossIcon, NewSparkleIcon } from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { GetSalonResources } from "../../../services/resources/resources";
import {
  setAudioResources,
  setIsAudioResourcesLoading,
  setIsPdfResourcesLoading,
  setIsResourcesLoading,
  setIsVideoResourcesLoading,
  setPdfResources,
  setResources,
  setVideoResources,
} from "../../../store/slices/resourcesSlice";
import { salon_resources_type, status } from "../../../utils/constants";
import { toastMessage } from "../../../utils/helper";
import AllResources from "./AllResources";
import AudioResources from "./AudioResources";
import PdfResources from "./PdfResources";
import VideoResources from "./VideoResources";

const ResourceBody = (props) => {
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const {
    renderTable,
    allResourcePagination,
    videoResourcePagination,
    audioResourcePagination,
    pdfResourcePagination,
  } = useSelector((state) => state.resources);

  const [deleteData, setDeleteData] = useState(false);

  const authUser = GetAuthUserLocalStorage();

  const getAllResources = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];
    try {
      dispatch(setIsResourcesLoading(true));
      let params = {
        salon_id: currentSalonId,
        user_id: authUser?.id,
        status: status?.active,
        is_paginated: 1,
        category_id: props.seletedCategory,
        page: allResourcePagination.page,
        "per-page": allResourcePagination.perPage,
        softwares,
        technologies,
        products,
      };
      // dispatch(setIsResourcesLoading(true))
      const res = await GetSalonResources(params);
      dispatch(
        setResources({
          data: res?.data?.data?.data,
          meta: res?.data?.data?.meta,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsResourcesLoading(false));
    }
  };

  const getPdfResources = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    try {
      let params = {
        salon_id: currentSalonId,
        user_id: authUser?.id,
        status: status?.active,
        is_paginated: 1,
        type: salon_resources_type?.pdf,
        category_id: props.seletedCategory,
        page: pdfResourcePagination.page,
        "per-page": pdfResourcePagination.perPage,
        softwares,
        technologies,
      };
      dispatch(setIsPdfResourcesLoading(true));
      const res = await GetSalonResources(params);
      dispatch(
        setPdfResources({
          data: res?.data?.data?.data,
          meta: res?.data?.data?.meta,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsPdfResourcesLoading(false));
    }
  };

  const getAudioResources = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    try {
      let params = {
        salon_id: currentSalonId,
        user_id: authUser?.id,
        status: status?.active,
        is_paginated: 1,
        type: salon_resources_type?.audio,
        category_id: props.seletedCategory,
        page: audioResourcePagination.page,
        "per-page": audioResourcePagination.perPage,
        softwares,
        technologies,
      };
      dispatch(setIsAudioResourcesLoading(true));
      const res = await GetSalonResources(params);
      dispatch(
        setAudioResources({
          data: res?.data?.data?.data,
          meta: res?.data?.data?.meta,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsAudioResourcesLoading(false));
    }
  };

  const getVideoResources = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    try {
      let params = {
        salon_id: currentSalonId,
        user_id: authUser?.id,
        status: status?.active,
        is_paginated: 1,
        type: salon_resources_type?.video,
        category_id: props.seletedCategory,
        page: videoResourcePagination.page,
        "per-page": videoResourcePagination.perPage,
        softwares,
        technologies,
      };
      dispatch(setIsVideoResourcesLoading(true));
      const res = await GetSalonResources(params);
      dispatch(
        setVideoResources({
          data: res?.data?.data?.data,
          meta: res?.data?.data?.meta,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsVideoResourcesLoading(false));
    }
  };

  useEffect(() => {
    getAllResources();
    setDeleteData(false);
  }, [
    props.seletedCategory,
    deleteData,
    renderTable,
    JSON.stringify(allResourcePagination),
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  useEffect(() => {
    getPdfResources();
    setDeleteData(false);
  }, [
    props.seletedCategory,
    deleteData,
    renderTable,
    JSON.stringify(pdfResourcePagination),
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  useEffect(() => {
    getAudioResources();
    setDeleteData(false);
  }, [
    props.seletedCategory,
    deleteData,
    renderTable,
    JSON.stringify(audioResourcePagination),
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  useEffect(() => {
    getVideoResources();
    setDeleteData(false);
  }, [
    props.seletedCategory,
    deleteData,
    renderTable,
    JSON.stringify(videoResourcePagination),
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  return (
    <>
      <div className="content">
        {props.activeTab == "tab1" && (
          <AllResources
            deleteData={deleteData}
            setDeletedData={setDeleteData}
            seletedCategory={props.seletedCategory}
          />
        )}

        {props.activeTab == "tab2" && (
          <PdfResources
            deleteData={deleteData}
            setDeletedData={setDeleteData}
            seletedCategory={props.seletedCategory}
          />
        )}

        {props.activeTab == "tab3" && (
          <VideoResources
            deleteData={deleteData}
            setDeletedData={setDeleteData}
            seletedCategory={props.seletedCategory}
          />
        )}

        {props.activeTab == "tab4" && (
          <AudioResources
            deleteData={deleteData}
            setDeletedData={setDeleteData}
            seletedCategory={props.seletedCategory}
          />
        )}
      </div>
    </>
  );
};

export default ResourceBody;
