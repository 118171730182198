import { Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowTriangleDown,
  AudioIcon,
  BigAudioIconFilled,
  CheckMarkIcon,
  CreateIcon,
  PdfIcon,
  PdfIconFilled,
  VideoIcon,
  VideoIconFilled,
} from "../../../constants/svg";
import {
  setAllResourcePagination,
  setAudioResourcePagination,
  setPdfResourcePagination,
  setResourceAddEditForm,
  setVideoResourcePagination,
} from "../../../store/slices/resourcesSlice";
import GenericModal from "../../Modals/GenericModal";
import ResourcesModalSteps from "./ResourceModal/ResourcesModalSteps";
import { roles as salonRoles } from "../../../utils/constants";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";

const ResourceHeader = (props) => {
  const { resources, resources_pdf, resources_video, resources_audio } =
    useSelector((state) => state?.resources);
  const { categories, allSalons, currentSalonId } = useSelector(
    (state) => state.global,
  );
  const { resourceAddEditForm } = useSelector((state) => state.resources);
  const dispatch = useDispatch();
  const [showGenericModal, setShowGenericModal] = useState(false);

  const handleTabClick = (tabName) => {
    dispatch(
      setAllResourcePagination({
        perPage: 12,
        page: 1,
      }),
    );
    dispatch(
      setAudioResourcePagination({
        perPage: 12,
        page: 1,
      }),
    );
    dispatch(
      setPdfResourcePagination({
        perPage: 12,
        page: 1,
      }),
    );
    dispatch(
      setVideoResourcePagination({
        perPage: 12,
        page: 1,
      }),
    );
    props.setActiveTab(tabName);
  };

  return (
    <>
      <div className="globalHeader resourceHeader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Resources</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                All ({resources?.meta?.total || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                <div className="icon icon-active">
                  <PdfIconFilled />
                </div>
                <div className="icon">
                  <PdfIcon />
                </div>
                PDF ({resources_pdf?.meta?.total || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab3" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab3")}
              >
                <div className="icon icon-active">
                  <VideoIconFilled />
                </div>
                <div className="icon">
                  <VideoIcon />
                </div>
                Video ({resources_video?.meta?.total || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab4" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab4")}
              >
                <div className="icon icon-active">
                  <BigAudioIconFilled />
                </div>
                <div className="icon">
                  <AudioIcon />
                </div>
                Audio ({resources_audio?.meta?.total || 0})
              </div>
            </div>
          </div>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              value={currentSalonId || undefined}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}

          <Select
            defaultValue="All Categories"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={categories}
            onChange={(e) =>
              props.setSelectedCategory(e == "All Categories" ? "" : e)
            }
            getPopupContainer={() => document.getElementById("area")}
          />
          <button
            className="btn-gradient"
            onClick={() => {
              dispatch(
                setResourceAddEditForm({
                  show: true,
                  formType: "create",
                }),
              );
            }}
          >
            <span>
              <CreateIcon />
            </span>
            Add Resource
          </button>
        </div>
        {resourceAddEditForm.show && (
          <GenericModal
            show={resourceAddEditForm.show}
            onHide={() => {
              dispatch(
                setResourceAddEditForm({
                  show: false,
                  formType: null,
                }),
              );
            }}
            setModalShow={(e) => {
              dispatch(
                setResourceAddEditForm({
                  show: e,
                  formType: null,
                }),
              );
            }}
            modalTitle={
              resourceAddEditForm.formType == "create"
                ? "Create New Resource"
                : resourceAddEditForm.formType == "update"
                  ? "Edit Resource"
                  : ""
            }
            customModalHeader={"customNewResourceModalHeader"}
            resource-modal="resource-modal"
            size={"lg"}
            bodyComponent={
              <ResourcesModalSteps
                customModalBody={"customResourceModalBody"}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default ResourceHeader;
