import { notification } from "antd";
import moment from "moment";
import { ToastCrossIcon } from "../../constants/svg";
import {
  alert_type,
  link_types,
  recipient_types,
  roles as salonRoles,
  segment_type,
} from "../../utils/constants";
import {
  setAlertsFormData,
  setIsSingleAlertLoading,
  setSingleAlert,
} from "../../store/slices/aletsSlice";
import {
  convertToLocal,
  convertUTCToLocalTime,
  toastMessage,
  convertTimezone,
  getStringTime,
  getTimefromString,
} from "../../utils/helper";
import {
  CreateAlert,
  DeleteSingleAlert,
  GetAlerts,
  GetSingleAlert,
  UpdateAlert,
} from "./alerts";

export const getAlerts = async (params, dispatch, setLoading, setData) => {
  try {
    dispatch(setLoading(true));
    const res = await GetAlerts(params);
    const data = res?.data?.data?.data?.map((item) => {
      let datetime;
      if (item?.type == alert_type.scheduled) {
        datetime = convertToLocal(item?.date, item?.time, null, "MMM DD, yyyy");
      }
      let ls = item?.last_sent;
      const [user] = item.userRecipients;
      let timezone = "EST";
      if (item.timezone) timezone = item.timezone;
      if (user && user?.timezone) timezone = user?.timezone;
      if (item?.type == alert_type.immediate && item?.time) {
        ls = convertTimezone(
          item?.last_sent.split("T")[0] + "T" + item?.time,
          timezone || "EST",
        );
      } else {
        ls = getTimefromString(item?.time);
      }
      return {
        group: false,
        key: item?.id,
        alertTitle: {
          title: item?.name || "-",
          lock: item?.user?.roles[0]?.id == salonRoles.super_admin,
        },
        time: item?.time,
        date: item?.date,
        sendType: {
          main: item?.typeName,
          sub:
            item?.type == alert_type.scheduled
              ? datetime?.localDate
              : item?.repeatTypeName,
        },
        ls,
        lastSent: {
          main: item?.last_sent
            ? moment(item?.last_sent).format("MMM DD, yyyy")
            : "-",
          sub: item?.last_sent
            ? moment(item?.last_sent).format("hh:mm a")
            : "-",
        },
        seenBy: item?.userAlertsView?.length,
        attendeeNames: item?.userAlertsView?.map((item) => item?.user?.name),
        salon: item?.salon?.name,
        type: item?.type,
        user: item?.user,
      };
    });
    dispatch(setData({ data, meta: res?.data?.data?.meta }));
    dispatch(setLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });

    dispatch(setLoading(false));
  }
};

export const deleteAlert = async (
  selectedData,
  setDisable,
  showModal,
  setShowModal,
) => {
  try {
    setDisable(true);
    const res = await DeleteSingleAlert(selectedData?.key);
    setShowModal({ ...showModal, deleteModal: false });
    if (selectedData.type != alert_type.immediate) {
      toastMessage("delete", res?.data?.message);
    }
    setDisable(false);
  } catch (e) {
    toastMessage("error", e.message);
    setDisable(false);
  }
};

export const getSingleAlert = async (id, dispatch) => {
  if (typeof id != "undefined") {
    try {
      dispatch(setIsSingleAlertLoading(true));
      const res = await GetSingleAlert(id);
      dispatch(setSingleAlert(res.data.data));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setIsSingleAlertLoading(false));
    }
  }
};

export const createAlert = async (
  apiData,
  setDisable,
  setModalShow,
  resetForm,
  fetchAlerts,
) => {
  try {
    setDisable(true);
    const res = await CreateAlert(apiData);
    setDisable(false);
    setModalShow(false);
    resetForm();
    fetchAlerts();

    let createAlertNot;

    switch (+apiData.type) {
      case alert_type.immediate:
        createAlertNot = "sent";
        break;

      case alert_type.scheduled:
        createAlertNot = "scheduled";
        break;

      case alert_type.recurring:
        createAlertNot = "scheduled";
        break;
    }

    toastMessage(
      "success",
      <div>
        <strong>Alert</strong> <span>{createAlertNot}!</span>
      </div>,
    );
  } catch (e) {
    setDisable(false);
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const updateAlert = async (
  apiData,
  selectedData,
  setDisable,
  setModalShow,
  resetForm,
  fetchAlerts,
) => {
  try {
    setDisable(true);
    const res = await UpdateAlert(selectedData?.key, apiData);
    setDisable(false);
    setModalShow(false);
    resetForm();
    fetchAlerts();

    let createAlertNot;

    switch (+apiData.type) {
      case alert_type.scheduled:
        createAlertNot = "updated";
        break;

      case alert_type.recurring:
        createAlertNot = "updated";
        break;
    }
    if (createAlertNot) {
      toastMessage(
        "success",
        <div>
          <strong>Alert</strong> <spa>{createAlertNot}!</spa>
        </div>,
      );
    }
  } catch (e) {
    setDisable(false);
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const presetDataForEditAlert = async (
  selectedData,
  setSelectedAlertType,
  dispatch,
  setSegment,
  setSelectedSalons,
  setSelectedProductLines,
  setSelectedPositions,
  setSoftwares,
  setTechnologies,
) => {
  if (typeof selectedData?.key != "undefined") {
    try {
      const res = await GetSingleAlert(selectedData?.key);
      const data = res?.data?.data;
      let datetime;
      setSegment(data?.segment || null);
      if (data?.segment == segment_type.CUSTOM) {
        let salons = [];
        let products = [];
        data?.products?.map((item) => {
          products.push(item.product);
        });
        data?.salons?.map((item) => {
          salons.push(item.id);
        });
        setSelectedSalons(salons);
        setSelectedProductLines(products);
      }
      setTechnologies?.(data.technologies.map(({ name }) => name) ?? []);
      setSoftwares?.(data.software.map(({ name }) => name) ?? []);

      switch (data?.type) {
        case alert_type.recurring:
          datetime = data?.date
            ? convertToLocal(
                data?.date,
                data?.time,
                null,
                "YYYY-MM-DD",
                data?.repeat_type,
              )
            : null;
          break;
        case alert_type.scheduled:
          datetime = convertToLocal(data?.date, data?.time, null, "YYYY-MM-DD");
          break;
        default:
          datetime = null;
      }
      dispatch(
        setAlertsFormData({
          // date: data?.date ? dayjs(data?.date, 'YYYY-MM-DD') : null,
          date: data?.date ? datetime?.localDate : null,
          // time: moment(data?.time, 'HH:mm:ss').format('hh:mm A'),
          time: datetime?.localTime || convertUTCToLocalTime(data?.time),
          repeat_type: data?.repeat_type,
          day: data?.day || datetime?.day,
          title: data?.name,
          message: data?.message,
          category: data?.link_category,
          content: data?.link_id,
          link: data?.link,
          link_type: data?.link_type,
          checked: !!(
            data?.link_type != null && data?.link_type != link_types.no_link
          ),
          type: data?.type,
          selectedCategory:
            data?.recipient_type == recipient_types?.everyone
              ? "everyone"
              : data?.recipient_type == recipient_types?.group
                ? "group"
                : "member",
        }),
      );
      setSelectedAlertType(data?.type);
      dispatch(setIsSingleAlertLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsSingleAlertLoading(false));
    }
  }
};
