import { Dropdown, Select, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ImageUploaderModal from "../../components/Modals/ImageGalleryUpload/ImageUploaderModal";
import QuizModal from "../../components/pages/Quizzes/QuizModal/QuizModal";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  EditIcon,
  HorizontalDots,
  ImgSvg,
  ModalCloseIcon,
  PlusICons,
  RecycleBin,
  SurveyIconFilled,
  TagFilledIcon,
} from "../../constants/svg";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  CreateQuizQuestions,
  DeleteQuiz,
  GetQuizByID,
  GetQuizQuestions,
} from "../../services/quiz/quiz";
import {
  setQuizAddEditForm,
  setReRenderQuizBuilder,
  setSelectedData,
} from "../../store/slices/quizSlice";
import { roles as salonRoles, salonQuestionType } from "../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import { UpdateFileUploadStorageSizeAvailability } from "../../services/global/global";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { Assets } from "../../constants/images";
import { setBlockerModal } from "../../store/slices/globalSlice";
import { FiMinusCircle } from "react-icons/fi";
import { GoPlusCircle } from "react-icons/go";
import { Image, Shimmer } from "react-shimmer";

const menuOption = [
  { value: salonQuestionType.BOOL, label: "True/False" },
  { value: salonQuestionType.MULTIPLE, label: "Multiple Choice" },
  { value: salonQuestionType.PICTURE, label: "Picture Choice" },
];

const props = {
  name: "file",
  multiple: false,
  accept: ".png,.jpg,.jpeg",
  beforeUpload: (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
    }
    return isImage;
  },
  showUploadList: {
    showDownloadIcon: false,
    showRemoveIcon: true,
    removeIcon: <ModalCloseIcon />,
  },
};

const QuizBuilder = () => {
  const defaultTrueFalseQuestion = {
    type: salonQuestionType.BOOL,
    question: "",
    answers: [
      { answer: "", is_correct: false },
      { answer: "", is_correct: false },
    ],
  };

  const defaultMultipleChoiceQueston = {
    type: salonQuestionType.MULTIPLE,
    question: "",
    answers: [
      { answer: "", is_correct: false },
      { answer: "", is_correct: false },
      { answer: "", is_correct: false },
    ],
  };

  const defaultImageQuestion = {
    type: salonQuestionType.PICTURE,
    question: "",
    answers: [
      { answer: "", image: null, is_correct: false },
      { answer: "", image: null, is_correct: false },
      { answer: "", image: null, is_correct: false },
    ],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [actionBtnDisable, setActionBtnDisable] = useState(false);
  const [quizBuilderQuestions, setQuizBuilderQuestions] = useState([]);
  const [disableQuizDeleteBtn, setDisableQuizDeleteBtn] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const { reRenderQuizBuilder, quizAddEditForm, selectedData } = useSelector(
    (state) => state.quiz,
  );
  const handleAddOptionField = (shouldAdd) => {
    const updatedQuestionBuilder = quizBuilderQuestions?.map(
      (current, index) => {
        if (index == count - 1) {
          if (shouldAdd) {
            return {
              ...current,
              answers: [
                ...current.answers,
                {
                  answer: "",
                  is_correct: false,
                },
              ],
            };
          } else {
            return {
              ...current,
              answers: current.answers?.slice(0, -1),
            };
          }
        }
        return current;
      },
    );
    setQuizBuilderQuestions(updatedQuestionBuilder);
  };
  useEffect(() => {
    dispatch(setBlockerModal(true));
    return () => dispatch(setBlockerModal(false));
  }, []);

  const actionMenu = [
    {
      key: uuidv4(),
      label: (
        <>
          <div
            onClick={() => {
              dispatch(
                setQuizAddEditForm({
                  show: true,
                  formType: "update",
                }),
              );
            }}
          >
            <EditIcon />
            Edit Details
          </div>
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div
          className={"deleteLabel"}
          onClick={() => {
            setShowModal({ ...showModal, deleteModal: true });
          }}
        >
          <RecycleBin />
          Delete Quiz
        </div>
      ),
    },
  ];

  const handleValidate = () => {
    let isValidate = true;

    for (let elem of quizBuilderQuestions) {
      if (elem.question == "") {
        isValidate = false;
        break;
      }

      if (elem.type == salonQuestionType.BOOL) {
        const isAnyOptionIsCorrect = elem.answers.some(
          (elem) => elem.is_correct == true,
        );

        if (!isAnyOptionIsCorrect) {
          isValidate = false;
          break;
        }

        for (let row of elem.answers) {
          if (row.answer == "") {
            isValidate = false;
            break;
          }
        }
      } else if (elem.type == salonQuestionType.MULTIPLE) {
        const isAnyOptionIsCorrect = elem.answers.some(
          (elem) => elem.is_correct == true,
        );

        if (!isAnyOptionIsCorrect) {
          isValidate = false;
          break;
        }

        for (let i = 0; i < elem.answers.length; i++) {
          let row = elem.answers[i];
          if (i == 2 && row.answer?.length > 0) {
            continue;
          }
          if (row.answer == "") {
            isValidate = false;
            break;
          }
        }
      } else if (elem.type == salonQuestionType.PICTURE) {
        const isAnyOptionIsCorrect = elem.answers.some(
          (elem) => elem.is_correct == true,
        );

        if (!isAnyOptionIsCorrect) {
          isValidate = false;
          break;
        }

        for (let row of elem.answers) {
          if (row.answer == "") {
            isValidate = false;
            break;
          }
          if (!row.image) {
            isValidate = false;
            break;
          }
        }
      }
    }
    if (quizBuilderQuestions?.length == 0) {
      isValidate = false;
    }

    return isValidate;
  };

  const handleClick = () => {
    if (handleValidate()) {
      setCount(count + 1);
      setQuizBuilderQuestions([
        ...quizBuilderQuestions,
        defaultTrueFalseQuestion,
      ]);
    }
  };

  const onDeleteQuiz = async () => {
    try {
      setDisableQuizDeleteBtn(true);
      const resp = await DeleteQuiz(id);
      const { data, message, status } = resp.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("success", "Quiz Deleted Successfully");
      navigate("/quizzes");
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableQuizDeleteBtn(false);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      quiz_id: parseInt(id),
      user_id: GetAuthUserLocalStorage().id,
      questions: quizBuilderQuestions,
    };

    try {
      setActionBtnDisable(true);
      const response = await CreateQuizQuestions(payload);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setBlockerModal(false));
      setTimeout(() => {
        navigate("/quizzes");
      }, 0);
      dispatch(setReRenderQuizBuilder(reRenderQuizBuilder + 1));
      toastMessage("success", "Quiz published");
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setActionBtnDisable(false);
    }
  };

  const deleteQuestion = (i) => {
    setCount(count - 1);
    setQuizBuilderQuestions(
      quizBuilderQuestions.filter((elem, ind) => ind != i),
    );
  };

  const changeQuestionType = (i, v) => {
    const newChangeQuestionType = quizBuilderQuestions.map((elem, index) => {
      if (index == i) {
        let newElem;
        switch (v) {
          case salonQuestionType.MULTIPLE:
            newElem = {
              ...defaultMultipleChoiceQueston,
              question: elem.question,
            };
            break;

          case salonQuestionType.PICTURE:
            newElem = { ...defaultImageQuestion, question: elem.question };
            break;

          case salonQuestionType.BOOL:
            newElem = { ...defaultTrueFalseQuestion, question: elem.question };
            break;
        }
        return newElem;
      } else {
        return elem;
      }
    });
    setQuizBuilderQuestions(newChangeQuestionType);
  };

  const onChangeMultipleChoiceQuestion = (i, type, data) => {
    const newChangeQuestionType = quizBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        } else if (type == "checkbox") {
          newElem.answers[0].is_correct = false;
          newElem.answers[1].is_correct = false;
          if (newElem.answers?.length > 2) {
            newElem.answers[2].is_correct = false;
          }
          newElem.answers[data.checkboxIndex].is_correct = data.value;
        }
        return newElem;
      } else {
        return elem;
      }
    });
    setQuizBuilderQuestions(newChangeQuestionType);
  };

  const onChangeMultipleImageQuestion = async (i, type, data) => {
    let image = null;
    if (type == "image" && data.value) {
      image = await uploadQuestionImageOnS3(data.value);
    }

    const newChangeQuestionType = [...quizBuilderQuestions];
    newChangeQuestionType.forEach((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        } else if (type == "checkbox") {
          newElem.answers[0].is_correct = false;
          newElem.answers[1].is_correct = false;
          newElem.answers[2].is_correct = false;
          newElem.answers[data.checkboxIndex].is_correct = data.value;
        } else if (type == "image") {
          newElem.answers[data.imgIndex].image = image;
        }
        newChangeQuestionType[index] = newElem;
      }
    });
    setQuizBuilderQuestions(newChangeQuestionType);
  };

  const onChangeTrueFalseQuestion = async (i, type, data) => {
    const newChangeQuestionType = [...quizBuilderQuestions];
    newChangeQuestionType.forEach(async (elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        } else if (type == "checkbox") {
          newElem.answers[0].is_correct = false;
          newElem.answers[1].is_correct = false;
          newElem.answers[data.checkboxIndex].is_correct = data.value;
        }
        newChangeQuestionType[index] = newElem;
      }
    });
    setQuizBuilderQuestions(newChangeQuestionType);
  };

  const uploadQuestionImageOnS3 = async (file) => {
    try {
      let folder = `${
        GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
      }/quizBuilder`;
      let salonS3UploadResponse = await salonUploadToS3(file, folder);
      if (salonS3UploadResponse.status) {
        if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
          await UpdateFileUploadStorageSizeAvailability(
            salonS3UploadResponse.data.size,
          );
        }
        return salonS3UploadResponse.data.location;
      } else {
        throw new Error(salonS3UploadResponse.message);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
    }
  };

  const onInitGetQuizQuestion = async () => {
    try {
      setLoading(true);
      const response = await GetQuizQuestions(id);
      const { data, message, status } = response.data;

      if (!status) {
        toastMessage("error", message);
        return;
      }

      if (data.length) {
        const quizQuesion = data.map((elem, ind) => {
          if (elem.type == salonQuestionType.MULTIPLE) {
            const defaultArray = [
              {
                answer: elem.questionAnswers[0].answer,
                is_correct: !!elem.questionAnswers[0].is_correct,
              },
              {
                answer: elem.questionAnswers[1].answer,
                is_correct: !!elem.questionAnswers[1].is_correct,
              },
            ];
            return {
              type: salonQuestionType.MULTIPLE,
              question: elem.question,
              answers:
                elem.questionAnswers?.length > 2
                  ? [
                      ...defaultArray,
                      {
                        answer: elem.questionAnswers[2].answer,
                        is_correct: !!elem.questionAnswers[2].is_correct,
                      },
                    ]
                  : defaultArray,
            };
          } else if (elem.type == salonQuestionType.PICTURE) {
            return {
              type: salonQuestionType.PICTURE,
              question: elem.question,
              answers: [
                {
                  answer: elem.questionAnswers[0].answer,
                  image: elem.questionAnswers[0].image,
                  is_correct: !!elem.questionAnswers[0].is_correct,
                },
                {
                  answer: elem.questionAnswers[1].answer,
                  image: elem.questionAnswers[1].image,
                  is_correct: !!elem.questionAnswers[1].is_correct,
                },
                {
                  answer: elem.questionAnswers[2].answer,
                  image: elem.questionAnswers[2].image,
                  is_correct: !!elem.questionAnswers[2].is_correct,
                },
              ],
            };
          } else if (elem.type == salonQuestionType.BOOL) {
            return {
              type: salonQuestionType.BOOL,
              question: elem.question,
              answers: [
                {
                  answer: elem.questionAnswers[0].answer,
                  is_correct: !!elem.questionAnswers[0].is_correct,
                },
                {
                  answer: elem.questionAnswers[1].answer,
                  is_correct: !!elem.questionAnswers[1].is_correct,
                },
              ],
            };
          }
        });
        setCount(quizQuesion.length);
        setQuizBuilderQuestions(quizQuesion);
      } else {
        setCount(1);
        setQuizBuilderQuestions([defaultTrueFalseQuestion]);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoading(false);
    }
  };

  const getSingleQuizByID = async () => {
    const response = await GetQuizByID(id);
    const { data, message, status } = response.data;
    if (!status) {
      toastMessage("error", message);
      return;
    }
    dispatch(setSelectedData(data));
  };

  useEffect(() => {
    onInitGetQuizQuestion();
    getSingleQuizByID();
  }, [reRenderQuizBuilder]);

  if (loading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <div className="innerContainer">
      <Row>
        <Col lg={3} style={{ paddingTop: "0px" }}>
          <div className="sidebar_Bg">
            <div className="surveysbuilder_image">
              <Image
                src={selectedData?.image || Assets.GeneralPlaceholder}
                fallback={<Shimmer width={800} height={600} />}
                alt="QuizBuilder"
                className="img-fluid"
              />
            </div>
            <ul>
              <li> {selectedData?.category?.name || "-"}</li>
              <li>
                <Space size="middle">
                  <Dropdown
                    menu={{
                      items: actionMenu,
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                    overlayClassName={"defaultDropdown"}
                  >
                    <div className="icon cursor-pointer">
                      <HorizontalDots />
                    </div>
                  </Dropdown>
                </Space>
              </li>
            </ul>
            <div className="surveysbuilder_content">
              <h3>{selectedData?.name}</h3>
              <p>{selectedData?.description || "-"}</p>
            </div>

            {!handleValidate() && (
              <Button
                className="publish_survey"
                type="submit"
                disabled={!handleValidate()}
              >
                Publish Quiz
              </Button>
            )}

            {handleValidate() && (
              <Button
                className="publish_survey"
                type="submit"
                disabled={actionBtnDisable}
                onClick={handleSubmit}
              >
                {!actionBtnDisable && "Publish Quiz"}
                {actionBtnDisable && <div className="load black" />}
              </Button>
            )}

            {selectedData?.tags?.length > 0 && (
              <div className="tag-div">
                <TagFilledIcon />
                <p>{selectedData?.tags?.map((item) => item.name).join(", ")}</p>
              </div>
            )}
          </div>
        </Col>
        <Col lg={9}>
          <div className="headingbar_bg">
            <ul className="bar_count">
              <li>&nbsp;</li>
            </ul>
            <h2 className="sec-heading">Quiz Builder</h2>
            <p>
              Add questions one by one, then click Publish Quiz when finished.
            </p>
            <p className="question_count">
              Total Questions <span>({count})</span>
            </p>
          </div>
          <div className="add_question_box">
            {quizBuilderQuestions.map((elem, index) => {
              if (elem.type == salonQuestionType.BOOL) {
                return (
                  <TrueFalseQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    quizBuilderQuestion={elem}
                    onChangeTrueFalseQuestion={onChangeTrueFalseQuestion}
                  />
                );
              }

              if (elem.type == salonQuestionType.PICTURE) {
                return (
                  <PictureChoiceQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    quizBuilderQuestion={elem}
                    onChangeMultipleImageQuestion={
                      onChangeMultipleImageQuestion
                    }
                  />
                );
              }

              if (elem.type == salonQuestionType.MULTIPLE) {
                return (
                  <MultipleChoiceQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    count={count}
                    changeQuestionType={changeQuestionType}
                    quizBuilderQuestion={elem}
                    onChangeMultipleChoiceQuestion={
                      onChangeMultipleChoiceQuestion
                    }
                    handleAddOptionField={handleAddOptionField}
                  />
                );
              }
            })}
            <div
              className={`add_question ${!handleValidate() ? "disabled" : ""}`}
              onClick={() => handleClick()}
            >
              <PlusICons />
              Add Question
            </div>
          </div>
        </Col>
      </Row>

      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        disable={disableQuizDeleteBtn}
        heading="Delete Quiz?"
        handleDelete={onDeleteQuiz}
        para={
          <>
            Are you sure you want to delete this Quiz?
            <br />
            <b>Important:</b> This question cannot be recovered.
          </>
        }
        notificationMessage="Quiz"
      />

      <GenericModal
        show={quizAddEditForm.show}
        onHide={() => {
          dispatch(
            setQuizAddEditForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setQuizAddEditForm({
              show: e,
              formType: null,
            }),
          );
        }}
        modalTitle={
          quizAddEditForm.formType == "create" ? "Create New Quiz" : "Edit Quiz"
        }
        size={"lg"}
        bodyComponent={<QuizModal />}
      />

      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={"Image Uploader"}
        size={"lg"}
        bodyComponent={
          <ImageUploaderModal setShowGenericModal={setShowGenericModal} />
        }
      />
    </div>
  );
};

const MultipleChoiceQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  quizBuilderQuestion,
  onChangeMultipleChoiceQuestion,
  handleAddOptionField,
  count,
}) => {
  const { question, type, answers } = quizBuilderQuestion;
  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              minLength={100}
              maxLength={200}
              type="text"
              title={""}
              placeholder="Overall, what do you consider to be..."
              value={question}
              onChange={(e) => {
                onChangeMultipleChoiceQuestion(
                  index,
                  "question",
                  e.target.value,
                );
              }}
            />
          </div>
        </div>
        <div className="extra_para">
          Select the correct answer next to the option field below.
        </div>
        <div className="question_option">
          <div className="d-block w-100 question_item">
            {/* <span>Correct</span> */}
            <div className="question_option">
              <Form.Check
                name={`multiple_choice_checkbox[${index}]`}
                onChange={(e) => {
                  onChangeMultipleChoiceQuestion(index, "checkbox", {
                    value: e.target.checked,
                    checkboxIndex: 0,
                  });
                }}
                defaultChecked={false}
                defaultValue={false}
                value={true}
                checked={answers[0].is_correct}
                className="d-flex"
                type="radio"
                label={
                  <div className="d-block w-100 question_item">
                    {answers[0].is_correct && <span>Correct</span>}
                    <div className="inputWrapper">
                      <Form.Control
                        minLength={100}
                        maxLength={200}
                        type="text"
                        title={""}
                        placeholder="Option A here..."
                        value={answers[0].answer}
                        onChange={(e) => {
                          onChangeMultipleChoiceQuestion(index, "answer", {
                            value: e.target.value,
                            answerIndex: 0,
                          });
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div className="d-block w-100 question_item">
            {/* <span>Correct</span> */}
            <div className="question_option">
              <Form.Check
                defaultChecked={false}
                defaultValue={false}
                value={true}
                name={`multiple_choice_checkbox[${index}]`}
                onChange={(e) => {
                  onChangeMultipleChoiceQuestion(index, "checkbox", {
                    value: e.target.checked,
                    checkboxIndex: 1,
                  });
                }}
                checked={answers[1].is_correct}
                className="d-flex"
                type="radio"
                label={
                  <div className="d-block w-100 question_item">
                    {answers[1].is_correct && <span>Correct</span>}
                    <div className="inputWrapper">
                      <Form.Control
                        type="text"
                        minLength={100}
                        maxLength={200}
                        title={""}
                        value={answers[1].answer}
                        placeholder="Option B here..."
                        onChange={(e) => {
                          onChangeMultipleChoiceQuestion(index, "answer", {
                            value: e.target.value,
                            answerIndex: 1,
                          });
                        }}
                      />
                      {answers?.length < 3 && index + 1 == count && (
                        <GoPlusCircle
                          className="add_remove_icon"
                          onClick={() => handleAddOptionField(true)}
                        />
                      )}
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          {answers?.length > 2 && (
            <div className="d-block w-100 question_item">
              {/* <span>Correct</span> */}
              <div className="question_option">
                <Form.Check
                  name={`multiple_choice_checkbox[${index}]`}
                  defaultChecked={false}
                  defaultValue={false}
                  value={true}
                  onChange={(e) => {
                    onChangeMultipleChoiceQuestion(index, "checkbox", {
                      value: e.target.checked,
                      checkboxIndex: 2,
                    });
                  }}
                  checked={answers[2].is_correct}
                  className="d-flex"
                  type="radio"
                  label={
                    <div className="d-block w-100 question_item">
                      {answers[2].is_correct && <span>Correct</span>}
                      <div className="inputWrapper">
                        <Form.Control
                          type="text"
                          placeholder="Option C here..."
                          minLength={100}
                          maxLength={200}
                          title={""}
                          value={answers[2].answer}
                          onChange={(e) => {
                            onChangeMultipleChoiceQuestion(index, "answer", {
                              value: e.target.value,
                              answerIndex: 2,
                            });
                          }}
                        />
                        {index + 1 == count && (
                          <FiMinusCircle
                            className="add_remove_icon"
                            onClick={() => handleAddOptionField(false)}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const PictureChoiceQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  onChangeMultipleImageQuestion,
  quizBuilderQuestion,
}) => {
  const { type, question, answers } = quizBuilderQuestion;

  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              value={question}
              minLength={100}
              maxLength={200}
              title={""}
              onChange={(e) => {
                onChangeMultipleImageQuestion(
                  index,
                  "question",
                  e.target.value,
                );
              }}
              placeholder="Which of the following are you most..."
            />
          </div>
        </div>
        <div className="extra_para">
          Select the correct answer next to the option field below.
        </div>

        <div className="question_option">
          <Row>
            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[0].image == null ? [] : [answers[0].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 0,
                      });
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 0,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[0]?.image ? (
                      <img src={answers[0].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
              </div>
              <div className="d-block w-100 question_item">
                <div className="question_option">
                  <Form.Check
                    defaultChecked={false}
                    defaultValue={false}
                    value={true}
                    name={`picture_choice_checkbox[${index}]`}
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "checkbox", {
                        value: e.target.checked,
                        checkboxIndex: 0,
                      });
                    }}
                    checked={answers[0].is_correct}
                    className="d-flex"
                    type="radio"
                    label={
                      <div className="d-block w-100 question_item">
                        {/* {answers[0].is_correct && <span>Correct</span>} */}
                        <div className="inputWrapper">
                          <Form.Control
                            type="text"
                            placeholder="Picture title A..."
                            minLength={100}
                            maxLength={200}
                            title={""}
                            value={answers[0].answer}
                            onChange={(e) => {
                              onChangeMultipleImageQuestion(index, "answer", {
                                value: e.target.value,
                                answerIndex: 0,
                              });
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>

            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[1].image == null ? [] : [answers[1].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 1,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 1,
                      });
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[1]?.image ? (
                      <img src={answers[1].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
              </div>
              <div className="d-block w-100 question_item">
                <div className="question_option">
                  <Form.Check
                    defaultChecked={false}
                    defaultValue={false}
                    value={true}
                    name={`picture_choice_checkbox[${index}]`}
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "checkbox", {
                        value: e.target.checked,
                        checkboxIndex: 1,
                      });
                    }}
                    checked={answers[1].is_correct}
                    className="d-flex"
                    type="radio"
                    label={
                      <div className="d-block w-100 question_item">
                        {/* {answers[1].is_correct && <span>Correct</span>} */}
                        <div className="inputWrapper">
                          <Form.Control
                            type="text"
                            placeholder="Picture title B..."
                            minLength={100}
                            maxLength={200}
                            title={""}
                            value={answers[1].answer}
                            onChange={(e) => {
                              onChangeMultipleImageQuestion(index, "answer", {
                                value: e.target.value,
                                answerIndex: 1,
                              });
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[2].image == null ? [] : [answers[2].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 2,
                      });
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 2,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[2]?.image ? (
                      <img src={answers[2].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
              </div>

              <div className="d-block w-100 question_item">
                <div className="question_option">
                  <Form.Check
                    name={`picture_choice_checkbox[${index}]`}
                    defaultChecked={false}
                    defaultValue={false}
                    value={true}
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "checkbox", {
                        value: e.target.checked,
                        checkboxIndex: 2,
                      });
                    }}
                    checked={answers[2].is_correct}
                    className="d-flex"
                    type="radio"
                    label={
                      <div className="d-block w-100 question_item">
                        {/* {answers[2].is_correct && <span>Correct</span>} */}
                        <div className="inputWrapper">
                          <Form.Control
                            type="text"
                            value={answers[2].answer}
                            minLength={100}
                            maxLength={200}
                            title={""}
                            placeholder="Picture title C..."
                            onChange={(e) => {
                              onChangeMultipleImageQuestion(index, "answer", {
                                value: e.target.value,
                                answerIndex: 2,
                              });
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const TrueFalseQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  onChangeTrueFalseQuestion,
  quizBuilderQuestion,
}) => {
  const { type, question, answers } = quizBuilderQuestion;

  return (
    <div className={`headingbar_bg bg_extra_space active`}>
      <div className="custom-pr">
        <div
          className="three-dots deleteRed"
          onClick={(e) => {
            deleteQuestion(index);
          }}
        >
          Delete
        </div>
      </div>
      <div className="serial_number">
        <SurveyIconFilled />
        {index + 1}
      </div>
      <div className="question_box">
        <div className="surver_builder_select">
          <Select
            value={type}
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box"
            options={menuOption}
            onChange={(e) => {
              changeQuestionType(index, e);
            }}
          />
        </div>
        <div className="surver_builder_input">
          <Form.Control
            type="text"
            placeholder="Enter your question here..."
            minLength={100}
            maxLength={200}
            title={""}
            onChange={(e) => {
              onChangeTrueFalseQuestion(index, "question", e.target.value);
            }}
            value={question}
          />
        </div>
      </div>
      <div className="extra_para">
        Select the correct answer next to the option field below.
      </div>
      <div className="question_option">
        <Form.Check
          defaultChecked={false}
          defaultValue={false}
          value={true}
          name={`true_false_checkbox[${index}]`}
          onChange={(e) => {
            onChangeTrueFalseQuestion(index, "checkbox", {
              value: e.target.checked,
              checkboxIndex: 0,
            });
          }}
          checked={answers[0].is_correct}
          className="d-flex"
          type="radio"
          label={
            <div className="d-block w-100 question_item">
              {answers[0].is_correct && <span>Correct</span>}
              <div className="inputWrapper">
                <Form.Control
                  type="text"
                  placeholder="True"
                  minLength={100}
                  maxLength={200}
                  title={""}
                  onChange={(e) => {
                    onChangeTrueFalseQuestion(index, "answer", {
                      value: e.target.value,
                      answerIndex: 0,
                    });
                  }}
                  value={answers[0].answer}
                />
              </div>
            </div>
          }
        />

        <Form.Check
          name={`true_false_checkbox[${index}]`}
          defaultChecked={false}
          defaultValue={false}
          value={true}
          onChange={(e) => {
            onChangeTrueFalseQuestion(index, "checkbox", {
              value: e.target.checked,
              checkboxIndex: 1,
            });
          }}
          checked={answers[1].is_correct}
          className="d-flex"
          type="radio"
          label={
            <div className="d-block w-100 question_item">
              {answers[1].is_correct && <span>Correct</span>}
              <div className="inputWrapper">
                <Form.Control
                  minLength={100}
                  maxLength={200}
                  type="text"
                  title={""}
                  onChange={(e) => {
                    onChangeTrueFalseQuestion(index, "answer", {
                      value: e.target.value,
                      answerIndex: 1,
                    });
                  }}
                  value={answers[1].answer}
                  placeholder="False"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default QuizBuilder;
