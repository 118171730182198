import { createSlice } from "@reduxjs/toolkit";

export const salonTeamsSlice = createSlice({
  name: "salon_teams",
  initialState: {
    isSalonTeamsLoading: true,

    salonTeams: {
      meta: null,
      data: {
        everyone: [],
        groups: [],
        members: [],
      },
    },
  },
  reducers: {
    setIsSalonTeamsLoading: (state, action) => {
      state.isSalonTeamsLoading = action.payload;
    },
    setSalonTeams: (state, action) => {
      state.salonTeams.data.everyone = action.payload.data.everyone;
      state.salonTeams.data.groups = action.payload.data.groups;
      state.salonTeams.data.members = action.payload.data.members;

      state.salonTeams.meta = action.payload.meta;
    },
  },
});

export const { setIsSalonTeamsLoading, setSalonTeams } =
  salonTeamsSlice.actions;

export default salonTeamsSlice.reducer;
