import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { ArrowTriangleDown } from "../../../../../../constants/svg";
import {
  primaryProductList,
  SOFTWARE,
  TECHNOLOGY,
} from "../../../../../../utils/constants";
import { useSelector } from "react-redux";

const NewSegment = ({
  showPositions,
  getSalonSegments,
  setSelectedSalons,
  selectedSalons,
  selectedProductLines,
  selectedPositions,
  setSelectedProductLines,
  setSelectedPositions,
  zeroStepData,
  softwares,
  setSoftwares,
  technologies,
  setTechnologies,
}) => {
  const [size, setSize] = useState("middle");
  const [salons, setSalons] = useState([]);
  const { positions, salonSegments } = useSelector((state) => state.global);
  useEffect(() => {
    let salonData = [];
    salonSegments.map((item) => {
      salonData.push({ label: item.name, value: item.id });
    });
    setSalons(salonData);
  }, [salonSegments]);
  useEffect(() => {
    getSalonSegments();
  }, []);
  const primaryProduct = primaryProductList.map((product) => ({
    value: product,
    label: product,
  }));

  const handleSalonChange = (value) => {
    setSelectedSalons(value);
  };
  const handleProductLineChange = (value) => {
    setSelectedProductLines(value);
  };
  const handlePositionChange = (value) => {
    setSelectedPositions(value);
  };
  return (
    <div className="min_box new_segment">
      <div className="header_box">
        Filter recipients who match the following criteria:
      </div>
      <div className="all_select">
        <div className="select_box_new">
          <div className="select_label_new">AND</div>
          <div className="select_option_new">
            <Select
              showSearch
              value={selectedProductLines}
              mode="multiple"
              size={size}
              placeholder="Select Product Line"
              onChange={handleProductLineChange}
              style={{ width: "100%" }}
              options={primaryProduct}
              suffixIcon={<ArrowTriangleDown />}
              popupClassName="new_select_class"
            />
          </div>
        </div>
        {showPositions ? (
          <div className="select_box_new">
            <div className="select_label_new">AND</div>
            <div className="select_option_new">
              <Select
                showSearch
                value={selectedPositions}
                mode="multiple"
                size={size}
                placeholder="Select Job Title"
                onChange={handlePositionChange}
                style={{ width: "100%" }}
                options={positions}
                suffixIcon={<ArrowTriangleDown />}
                popupClassName="new_select_class"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </div>
          </div>
        ) : null}
        <div className="select_box_new">
          <div className="select_label_new">AND</div>
          <div className="select_option_new">
            <Select
              showSearch
              value={selectedSalons}
              mode="multiple"
              size={size}
              placeholder="Select Organizations"
              onChange={handleSalonChange}
              style={{ width: "100%" }}
              options={salons}
              suffixIcon={<ArrowTriangleDown />}
              popupClassName="new_select_class"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </div>
        </div>
        <div className="select_box_new">
          <div className="select_label_new">AND</div>
          <div className="select_option_new">
            <Select
              showSearch
              value={softwares}
              mode="multiple"
              size={size}
              placeholder="Select Booking Software"
              onChange={setSoftwares}
              style={{ width: "100%" }}
              options={SOFTWARE.map((el) => ({ label: el, value: el }))}
              suffixIcon={<ArrowTriangleDown />}
              popupClassName="new_select_class"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </div>
        </div>
        <div className="select_box_new">
          <div className="select_label_new">AND</div>
          <div className="select_option_new">
            <Select
              showSearch
              value={technologies}
              mode="multiple"
              size={size}
              placeholder="Select Other Technology"
              onChange={setTechnologies}
              style={{ width: "100%" }}
              options={TECHNOLOGY.map((el) => ({ label: el, value: el }))}
              suffixIcon={<ArrowTriangleDown />}
              popupClassName="new_select_class"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSegment;
