import React, { useState } from "react";
import PushAlertHeader from "../../components/pages/PushAlertPage/PushAlertHeader";
import PushAlertBody from "../../components/pages/PushAlertPage/PushAlertBody";

const PushAlertPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState({ currentPage: 1, perPage: 10 });

  const handleResetPage = () => {
    setPage({ currentPage: 1, perPage: 10 });
  };

  return (
    <>
      <div className="innerContainer">
        <PushAlertHeader
          setSelectedCategory={setSelectedCategory}
          handleResetPage={handleResetPage}
        />
        <PushAlertBody
          selectedCategory={selectedCategory}
          page={page}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default PushAlertPage;
