import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  callGetMyProfileAPI: 1,
  verifyEmail: null,
  formData: {
    id: null,
    name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
    salon_name: "",
    date_founded: null,
    position: null,
    product_line: null,
    booking_software: null,
    locations: [],
    dob: "",
    image: "",
  },
  positions: {
    data: [],
    meta: null,
    isPositionLoading: true,
  },
  me: {
    isMeLoading: true,
    data: null,
  },
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAll: () => {
      return initialState;
    },
    setAuthData: (state, action) => {
      state.formData = action.payload;
    },
    setPositions: (state, action) => {
      state.positions.data = action.payload.data;
      state.positions.meta = action.payload.meta;
    },
    setIsPositionsLoading: (state, action) => {
      state.positions.isPositionLoading = action.payload;
    },

    setIsMeLoading: (state, action) => {
      state.me.isMeLoading = action.payload;
    },
    setMeData: (state, action) => {
      state.me.data = action.payload;
    },

    setResetAuthFormData: (state, action) => {
      state.formData.name = "";
      state.formData.email = "";
      state.formData.phone = "";
      state.formData.password = "";
      state.formData.password_confirmation = "";
      state.formData.salon_name = "";
      state.formData.date_founded = null;
      state.formData.position = null;
      state.formData.product_line = null;
      state.formData.booking_software = null;
      state.formData.locations = [];
      state.formData.dob = "";
      state.formData.image =
        "https://i.ibb.co/7k0m1Qq/Events-Management-festival-image.jpg";
    },
    setCallGetMyProfileAPI: (state, action) => {
      state.callGetMyProfileAPI = action.payload;
    },
    setVerifyEmail: (state, action) => {
      state.verifyEmail = action.payload;
    },
  },
});

export const {
  setAuthData,
  setPositions,
  setIsPositionsLoading,
  setCallGetMyProfileAPI,
  setIsMeLoading,
  setMeData,
  setResetAuthFormData,
  setVerifyEmail,
  resetAll,
} = authSlice.actions;

export default authSlice.reducer;
