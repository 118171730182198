import { createSlice } from "@reduxjs/toolkit";

export const billingAndPlanSlice = createSlice({
  name: "billingAndPlan",
  initialState: {
    packages: [],
    isCreatingPaymentLink: false,
    isBillingPageLoading: false,
    isCancellingSubscription: false,
  },
  reducers: {
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setIsBillingPageLoading: (state, action) => {
      state.isBillingPageLoading = action.payload;
    },
    setIsCreatingPaymentLink: (state, action) => {
      state.isCreatingPaymentLink = action.payload;
    },
    setIsCancellingSubscription: (state, action) => {
      state.isCancellingSubscription = action.payload;
    },
  },
});

export const {
  setPackages,
  setIsBillingPageLoading,
  setIsCreatingPaymentLink,
  setIsCancellingSubscription,
} = billingAndPlanSlice.actions;

export default billingAndPlanSlice.reducer;
