import { setIsUsersLoading, setUsers } from "../../store/slices/usersSlice";
import { toastMessage } from "../../utils/helper";
import { GetUsers, InviteUser, UpdateUser } from "./users";

export const getUsersWithoutPagination = async (params, dispatch) => {
  try {
    dispatch(setIsUsersLoading(true));
    const res = await GetUsers(params);
    dispatch(setUsers({ data: res?.data?.data, meta: null }));
    dispatch(setIsUsersLoading(false));
  } catch (e) {
    toastMessage("error", e.message);
    dispatch(setIsUsersLoading(false));
  }
};

export const addUser = async (data, setDisable, onSuccessCreateMember) => {
  try {
    setDisable(true);
    const response = await InviteUser(data);
    const { data: uData, message, status } = response.data;
    onSuccessCreateMember(message);
    /* await addUserToFirebase({
            // email: data.email,
            id: (uData?.id).toString(),
            image: (data.image) ? data.image : '',
            name: data.name,
            online: online_status.offline,
            position: null,
            salon_id: authUser?.salon_id
        }); */
  } catch (e) {
    setDisable(false);
    toastMessage("error", e.message);
  }
};

export const updateUser = async (
  id,
  data,
  setDisable,
  onSuccessUpdateMember,
) => {
  try {
    setDisable(true);
    const res = await UpdateUser(id, data);
    onSuccessUpdateMember(res.data.message);
  } catch (e) {
    setDisable(false);
    toastMessage("error", e.message);
  }
};
