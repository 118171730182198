import { Avatar, List } from "antd";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import UserListModal from "../../components/pages/Chat/Modal/UserListModal";
import UserProfileModal from "../../components/pages/Chat/Modal/UserProfileModal";
import { ToolTipTriangle } from "../../constants/svg";
import { useNavigate } from "react-router-dom";

const SendMessageModal = ({ users, onItemClick }) => {
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(null);

  const renderStatus = (isOnline) => {
    const statusColor = isOnline ? "green" : "grey";
    return (
      <span
        style={{ color: statusColor }}
        className={`status ${statusColor}`}
      ></span>
    );
  };

  const renderLastMessage = (lastMessage, timeSpan) => {
    if (lastMessage) {
      const { content, sendingTime } = lastMessage;
      return (
        <div className="messageHolder">
          <p className="message-preview m-0">{content}</p>
          <span className="time-span">{timeSpan}</span>
        </div>
      );
    }
    return null;
  };

  const renderDate = (date) => {
    const today = new Date();
    const messageDate = new Date(date);

    if (isSameDate(today, messageDate)) {
      return "Today";
    } else if (isYesterday(today, messageDate)) {
      return "Yesterday";
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return messageDate.toLocaleDateString([], options);
    }
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() == date2.getDate()
    );
  };

  const isYesterday = (date1, date2) => {
    const yesterday = new Date(date1);
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDate(yesterday, date2);
  };

  const handleSend = () => {};

  const handleOpenModal = (user) => {
    setActiveUser(user);
  };

  const handleCloseModal = () => {
    setActiveUser(null);
  };

  const renderItem = (user) => {
    const { id, avatar, username, isOnline, chatHistory, sendingTime } = user;
    const lastMessage =
      chatHistory.length > 0 ? chatHistory[chatHistory.length - 1] : null;
    const timeSpan = sendingTime;

    return (
      <List.Item onClick={() => onItemClick(user)}>
        <div className="innerContainer pe-0 w-100">
          <List.Item.Meta
            avatar={
              <div className="chatAvatatWithStatus">
                <Avatar src={avatar} size={50} />
                {renderStatus(isOnline)}
                {/* Avatar overlay */}
                <div className="avatar-overlay m-4">
                  <div className="triangleToolTip">
                    <ToolTipTriangle />
                  </div>
                  <div className="avatar-overlay-content">
                    <div className="avatar-icon">
                      <Avatar src={avatar} size={48} />
                    </div>
                    <h4 className="userName">{username}</h4>
                    <p className="userTitle m-0">Stylist, Manhattan</p>
                    <div className="avatar-buttons">
                      <Button
                        type="primary"
                        className={"view-profile border-grey-log-btn"}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/team/members/${id}`);
                        }}
                      >
                        View Profile
                      </Button>
                      {/* Open Modal on click on this */}
                      <Button
                        type="primary"
                        className={"messageBtn"}
                        onClick={() => handleOpenModal(user)}
                      >
                        Message
                      </Button>
                      <UserProfileModal
                        show={activeUser == user}
                        onHide={handleCloseModal}
                        setModalShow={handleCloseModal}
                        modalTitle={"Direct Message"}
                        size={"lg"}
                        className="userListModal"
                        bodyComponent={
                          <UserListModal
                            users={users}
                            handleSend={handleSend}
                            username={username}
                            userAvatar={avatar}
                            onCloseModal={handleCloseModal}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            title={username}
            className="chatCard"
            description={renderLastMessage(lastMessage, timeSpan)}
          />
        </div>
      </List.Item>
    );
  };
  return (
    <>
      {users?.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={users}
          renderItem={renderItem}
        />
      )}
    </>
  );
};

export default SendMessageModal;
