import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import {
  recurring_at_this_time_array,
  recurring_every_other_array,
  recurring_every_other_Bi_Weekly_array,
  recurring_every_other_monthly_array,
  recurring_repeat_alert_array,
  repeat_alert_type,
} from "../../../../utils/constants";
import CustomDatePicker from "../../../FormFields/CustomDatePicker";
import CustomSelect from "../../../FormFields/CustomSelect";

const RecurringAlertForm = (props) => {
  const { control, watch, setValue } = useFormContext();
  const [repeat_type, date] = watch(["repeat_type", "date"]);
  const [time, setime] = React.useState(props?.selectedData?.ls);
  return (
    <Row>
      <Col xs={4}>
        <Controller
          rules={{
            required: {
              value: "Required",
            },
          }}
          control={control}
          name="repeat_type"
          render={({ field }) => (
            <CustomSelect
              {...field}
              placeholder={"Repeat Alert"}
              label={"Repeat Alert"}
              value={props?.selectedData?.sendType?.sub || field?.value}
              options={recurring_repeat_alert_array}
              onChange={(e) => field?.onChange(e)}
            />
          )}
        />
      </Col>

      {repeat_type == repeat_alert_type?.annually && (
        <Col xs={4}>
          <Controller
            rules={{
              required: {
                value: "Required",
              },
            }}
            control={control}
            name="date"
            render={({ field }) => {
              return (
                <CustomDatePicker
                  {...field}
                  defaultValue={null}
                  label="Send Every"
                  placeholder="Send Every"
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  onChange={(date, dateString) => {
                    field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  value={field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
                />
              );
            }}
          />
        </Col>
      )}

      {repeat_type != repeat_alert_type?.annually && (
        <Col xs={4}>
          <Controller
            rules={{
              required: {
                value: "Required",
              },
            }}
            control={control}
            name="day"
            render={({ field }) => (
              <CustomSelect
                {...field}
                placeholder={"Send Every"}
                label={"Send Every"}
                value={field?.value}
                options={
                  repeat_type == repeat_alert_type?.monthly
                    ? recurring_every_other_monthly_array
                    : repeat_type == repeat_alert_type?.bi_weekly
                      ? recurring_every_other_Bi_Weekly_array
                      : recurring_every_other_array
                }
                onChange={(e) => field?.onChange(e)}
              />
            )}
          />
        </Col>
      )}

      <Col xs={4}>
        <Controller
          rules={{
            required: {
              value: "Required",
            },
          }}
          control={control}
          name="time"
          render={({ field }) => (
            <CustomSelect
              {...field}
              placeholder={"At This Time"}
              label={"At This Time"}
              value={time}
              options={recurring_at_this_time_array}
              onChange={(e) => {
                field?.onChange(e);
                setime(e);
              }}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default RecurringAlertForm;
