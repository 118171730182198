import { Input, Select, Space, Tag } from "antd";
import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Assets } from "../../../../constants/images";
import {
  ArrowDown,
  ClipIcon,
  PlusIconLight,
  SendButtonTriangle,
  WhiteRoundedCross,
} from "../../../../constants/svg";
import { FaTimes } from "react-icons/fa";
import { salonUploadToS3, toastMessage } from "../../../../utils/helper";
import {
  addUserToFirebase,
  getFireBaseUserByID,
  isUserAlreadyChatWithEachOther,
  sendMessage,
} from "../../../../services/chats/frontend-chats";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import { GetMembers } from "../../../../services/team/member";
import { message_type, online_status } from "../../../../utils/constants";
import AvatarImage from "../../../Common/AvatarImage";
const { TextArea } = Input;

const { Option } = Select;

const UserListModal = ({ onCloseModal }) => {
  const dispatch = useDispatch();
  const { sendChatSelectedUsers, addRemoveMemberInSendChatPopup } = useSelector(
    (state) => state.global,
  );
  const [members, setMembers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isDisableSendMsgBtn, setIsDisableSendMsgBtn] = useState(false);
  const [isTyped, setIsTyped] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatAttachment, setChatAttachment] = useState(null);

  const handleUserSelection = (value) => {
    const clickedMember = members.find((member) => member.value == value);
    const isFind = selectedUsers.find(
      (elem) => elem.value == clickedMember.value,
    );
    if (isFind) {
      setSelectedUsers(selectedUsers.filter((member) => member.value != value));
    } else {
      setSelectedUsers([...selectedUsers, clickedMember]);
    }
  };

  const handleUserDeselection = (value) => {
    setSelectedUsers(selectedUsers.filter((member) => member.value != value));
  };

  const handleSendMessage = async () => {
    if (!message && !chatAttachment) return;

    const newMessageWithAttachment = {
      created_at: serverTimestamp(),
      id: uuidv4(),
      is_read: false,
      sender_id: GetAuthUserLocalStorage().id,
      image_url: "",
      video_url: "",
      media_url: "",
      text: "",
    };

    const newMessageWithTxt = {
      created_at: serverTimestamp(),
      id: uuidv4(),
      is_read: false,
      sender_id: GetAuthUserLocalStorage().id,
      image_url: "",
      video_url: "",
      media_url: "",
      text: "",
    };

    try {
      setIsDisableSendMsgBtn(true);
      let uploadedChatAttachment;
      let folder = `${
        GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
      }/chat_attachments`;
      if (chatAttachment) {
        uploadedChatAttachment = await salonUploadToS3(chatAttachment, folder);
        if (uploadedChatAttachment.status) {
          const fileName = chatAttachment.name;
          const fileExtension = fileName.split(".").pop().toLowerCase();
          if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
            newMessageWithAttachment.image_url =
              uploadedChatAttachment.data.location;
            newMessageWithAttachment.message_type = message_type.image;
          } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
            newMessageWithAttachment.audio_url =
              uploadedChatAttachment.data.location;
            newMessageWithAttachment.message_type = message_type.audio;
          } else if (["mp4", "avi", "mov"].includes(fileExtension)) {
            newMessageWithAttachment.video_url =
              uploadedChatAttachment.data.location;
            newMessageWithAttachment.message_type = message_type.video;
          } else if (["pdf"].includes(fileExtension)) {
            newMessageWithAttachment.file_url =
              uploadedChatAttachment.data.location;
            newMessageWithAttachment.message_type = message_type.pdf;
          } else {
          }
          await sendMessageAfterSettingMsgPayload(newMessageWithAttachment);
        } else {
          throw new Error(uploadedChatAttachment.message);
        }
      }

      if (message) {
        newMessageWithTxt.text = message;
        newMessageWithTxt.message_type = message_type.text;
        await sendMessageAfterSettingMsgPayload(newMessageWithTxt);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setIsDisableSendMsgBtn(false);
    }
  };

  const sendMessageAfterSettingMsgPayload = async (msg) => {
    for await (const selectedUser of selectedUsers) {
      const isUserInFireBase = await getFireBaseUserByID(selectedUser.value);
      if (!isUserInFireBase) {
        await addUserToFirebase({
          id: selectedUser.value.toString(),
          email: selectedUser?.email,
          image: selectedUser?.image,
          name: selectedUser?.label,
          online: online_status.offline,
          position: null,
        });
      }

      const threadId = await isUserAlreadyChatWithEachOther(
        GetAuthUserLocalStorage().id,
        selectedUser.value,
        dispatch,
      );
      const toUser = {
        chat_id: threadId,
      };
      await sendMessage(toUser, msg);
    }
    toastMessage("success", "Direct Message sent!");
    setSelectedUsers([]);
    setMessage("");
    onCloseModal(false);
  };

  const handleChange = (id) => {};

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const openSelect = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleInputChange = (event) => {
    const message = event.target.value;
    setMessage(message);
    setIsTyped(message.length > 0);
  };

  const handleTextareaBlur = (event) => {
    if (event.target.value == "") {
      setIsTyped(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      handleSendMessage();
    }
  };
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setChatAttachment(selectedFile || null);
  };

  const getMember = async () => {
    const response = await GetMembers({
      is_paginated: 0,
      "order-column": "id",
      order: "desc",
    });
    const { data, status, messag } = response.data;
    const users = data.users;
    const mUsers = users.map(({ id, image, name, email }) => {
      return {
        value: id,
        label: name,
        email,
        image,
      };
    });
    setMembers(mUsers);
  };

  useEffect(() => {
    getMember();
  }, []);

  useEffect(() => {
    setSelectedUsers(sendChatSelectedUsers);
  }, [sendChatSelectedUsers]);

  return (
    <>
      <div className="modal-body">
        <div className="user-selection ">
          <div className="selected-users">
            <div className="d-flex align-items-center flex-wrap">
              {selectedUsers.map((user) => (
                <Tag
                  key={uuidv4()}
                  className="selected-user"
                  style={{
                    margin: "4px 4px",
                  }}
                  closable={addRemoveMemberInSendChatPopup != false}
                  onClose={() => handleUserDeselection(user.value)}
                  closeIcon={<WhiteRoundedCross />}
                >
                  <AvatarImage
                    name={user.label}
                    src={user.image}
                    placeholder={Assets.ProfilePlaceholder}
                    size={28}
                  />
                  <h5 className="title">{user.label}</h5>
                </Tag>
              ))}

              {addRemoveMemberInSendChatPopup == false ? (
                ""
              ) : (
                <div
                  className={
                    isDropdownOpen ? "transform addMember" : "addMember"
                  }
                  onClick={openSelect}
                >
                  <PlusIconLight />
                </div>
              )}
            </div>
          </div>
          <div
            className={
              isDropdownOpen
                ? "userDropdown open mt-3 mb-20"
                : "userDropdown mt-3 mb-20"
            }
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select users"
              value={selectedUsers}
              onChange={handleChange}
              onSelect={handleUserSelection}
              onDeselect={handleUserDeselection}
              onBlur={handleDropdownClose}
              onDropdownVisibleChange={handleDropdownOpen}
              menuItemSelectedIcon={false}
              closeIcon={<WhiteRoundedCross />}
              removeIcon={<WhiteRoundedCross />}
              suffixIcon={<ArrowDown />}
              showSearch={false}
              className="chatSendMessageModal"
              tagRender={(option) => {
                return (
                  <Tag
                    key={uuidv4()}
                    className="selected-user"
                    style={{
                      margin: "4px 4px",
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    closable={true}
                    onClose={() => handleUserDeselection(option.value)}
                    closeIcon={<WhiteRoundedCross />}
                  >
                    <AvatarImage
                      name={option.label}
                      src={
                        selectedUsers.find((e) => e.value == option.value)
                          ?.image
                      }
                      placeholder={Assets.ProfilePlaceholder}
                      size={28}
                    />
                    <h5 className="title">{option.label}</h5>
                  </Tag>
                );
              }}
              optionRender={(option) => {
                return (
                  <Option key={uuidv4()} value={option.value}>
                    <AvatarImage src={option.image} name={option.label} />
                    {option.label}
                  </Option>
                );
              }}
            >
              {members.map((member) => (
                <Option
                  key={uuidv4()}
                  value={member.value}
                  label={member.label}
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <Space>
                      <AvatarImage
                        name={member.label}
                        src={member.image}
                        placeholder={Assets.ProfilePlaceholder}
                        size={28}
                      />
                      <h6 className="m-0 title fs-12">{member.label}</h6>
                    </Space>
                    {selectedUsers.find((e) => e.value == member.value) && (
                      <span className="roundedIcon check"></span>
                    )}
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          {/* <FloatingLabel
            controlId="messageTextArea"
            label="Type your message here..."
            className="mb-20"
          >
            <Form.Control
              as="textarea"
              placeholder="Type your message here..."
              style={{ height: "150px" }}
              onChange={handleInputChange}
              onBlur={handleTextareaBlur}
            />
          </FloatingLabel> */}

          <div className={chatAttachment ? "file-box d-block" : "d-none"}>
            <div className="d-flex align-items-center justify-content-between">
              <span className="file-name">{chatAttachment?.name}</span>
              <span
                onClick={() => {
                  setChatAttachment(null);
                  fileInputRef.current.value = null;
                }}
              >
                <FaTimes />
              </span>
            </div>
          </div>

          <div className="chat-input d-flex align-items-center w-100">
            <div className="upload-box-wrapper d-flex align-items-center">
              {/* <div className="emoji-picker-wrapper"> */}
              {/* <Button
                type="link"
                icon={<SmileIcon />}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="chatInput-icon"
              /> */}

              {/* paperClip */}
              {/* <div className="chatInput">
                  <div
                    className="chatInput-icon chatInput-iconModal"
                    onClick={handleIconClick}
                  >
                    <ClipIcon />
                  </div> */}
              {/* Hidden file input */}
              {/* <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  /> */}
              {/* Other chat input components */}
              {/* </div>
              </div> */}
            </div>
            <div className="messageBox position-relative">
              <TextArea
                value={message}
                onChange={handleInputChange}
                rows={5}
                onKeyPress={handleKeyPress}
                placeholder="Type your message here..."
              />
              <Button
                onClick={handleSendMessage}
                type="primary"
                disabled={isDisableSendMsgBtn}
                className="chatSend modalChatSend"
              >
                {!isDisableSendMsgBtn && <SendButtonTriangle />}
                {isDisableSendMsgBtn && <div className="load black" />}
              </Button>
            </div>
          </div>
          {/* <div className="userFooter d-flex justify-content-between align-items-center">
            <div className="iconsImageBox">
              <img src={Assets.iconsImage} />
            </div>
            <Button disabled={isTyped} onClick={handleClose}>
              Send
            </Button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserListModal;
