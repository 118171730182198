import { Empty, List, Popover } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Assets } from "../../../constants/images";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import AvatarImage from "../../Common/AvatarImage";
import ChatProfileHover from "../../Common/ChatProfileHover";
import ChatStatusIcon from "../../Common/ChatStatusIcon";
import EveryOneImageSvg from "../../../assets/images/everyone.svg";

import { isNumber } from "lodash";

const UserList = ({ users, onItemClick }) => {
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(null);
  const [showPlusSign, setShowPlusSign] = useState(true);
  const { selectedUser } = useSelector((state) => state.users);
  const { positions } = useSelector((state) => state.global);

  const handleSend = () => {};

  // const renderLastMessage = (lastMessage, timeSpan) => {
  //   if (lastMessage) {
  //     const { content, sendingTime } = lastMessage;
  //     return (
  //       <span
  //         style={{ color: "green" }}
  //         className={`status green`}
  //       ></span>
  //     );
  //   }
  //   else {
  //     return (
  //       <span
  //         style={{ color: `${selectedUser?.online ? "green" : "grey"}` }}
  //         className={`status ${selectedUser?.online ? "green" : "grey"}`}
  //       ></span>
  //     )
  //   }
  // }

  const renderLastMessage = (lastMessage, timeSpan, unreadCount = 0) => {
    const finalTimeSpan = !isNumber(timeSpan) ? timeSpan?.seconds : timeSpan;
    const date = new Date(finalTimeSpan * 1000);
    const now = new Date();
    const duration = moment.duration(now - date);
    const formattedDuration =
      duration._milliseconds == 0
        ? "a few seconds"
        : moment.duration(duration).humanize();

    function timeSince(date) {
      let seconds = Math.floor((new Date() - date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + "y";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + "mo";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + "d";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + "h";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + "m";
      }
      return Math.floor(seconds) + "s";
    }

    return (
      <div className="messageHolder">
        <p className="message-preview m-0">
          {lastMessage || "Start a conversation…"}
        </p>
        {lastMessage != "" ? (
          <span className="time-span">
            {formattedDuration == "a few seconds"
              ? "Just Now"
              : timeSince(date) + " ago"}{" "}
          </span>
        ) : null}
        {lastMessage != "" && unreadCount > 0 ? (
          <span
            className="unread-messages-circle"
            style={{ color: "red" }}
          ></span>
        ) : null}
      </div>
    );
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() == date2.getDate()
    );
  };

  const handleOpenModal = (user) => {
    setShowPlusSign(false);
    setActiveUser(user);
  };

  const handleCloseModal = () => {
    setActiveUser(null);
  };

  const renderItem = (user) => {
    let unreadCount = 0;
    let authUser = GetAuthUserLocalStorage();
    const userData = user?.read_statuses?.filter(
      (item) => item.id == authUser?.id,
    );
    if (userData.length > 0 && !userData[0].is_read) {
      unreadCount++;
    }
    const { id, avatar, username, isOnline, chatHistory, sendingTime } = user;
    let userPosition = [];
    if (!user?.is_group_chat) {
      userPosition = positions.filter((item) => {
        return item.value == user?.receiver_profile[0]?.position;
      });
    }
    return (
      <List.Item onClick={() => onItemClick(user)}>
        <div className="innerContainer pe-0 w-100">
          <List.Item.Meta
            avatar={
              <div className="chatAvatatWithStatus">
                <Popover
                  placement="rightTop"
                  content={
                    <ChatProfileHover
                      user={user}
                      userPosition={userPosition}
                      onItemClick={onItemClick}
                      handleCloseModal={handleCloseModal}
                      activeUser={activeUser}
                      users={users}
                      handleSend={handleSend}
                      username={username}
                      avatar={avatar}
                      showPlusSign={showPlusSign}
                    />
                  }
                  arrow={true}
                  key={user.id}
                >
                  <AvatarImage
                    name={
                      user?.is_group_chat
                        ? user?.name
                        : user?.receiver_profile[0]?.name
                    }
                    src={
                      user?.is_group_chat
                        ? EveryOneImageSvg
                        : user?.receiver_profile[0]?.image
                    }
                    placeholder={Assets.ProfilePlaceholder}
                    size={50}
                    isSVG={user?.is_group_chat}
                  />
                  <ChatStatusIcon user={user} />
                </Popover>
              </div>
            }
            title={
              user?.is_group_chat ? user?.name : user?.receiver_profile[0]?.name
            }
            className="chatCard"
            description={renderLastMessage(
              user?.last_message,
              user?.updated_at,
              unreadCount,
            )}
          />
        </div>
      </List.Item>
    );
  };

  return (
    <>
      {users.length == 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No messages yet."
        />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={users}
          renderItem={renderItem}
        />
      )}
    </>
  );
};

export default UserList;
