import React, { useState } from "react";
import { Input } from "antd";
import { IoSearch } from "react-icons/io5";

const ExpandableSearch = ({ setSearchText }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  return (
    <div className="pe-2">
      <Input.Search
        className="expandableSearchInput"
        prefix={<IoSearch />}
        onFocus={handleExpand}
        onBlur={handleCollapse}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ width: expanded ? "180px" : "40px", transition: "width 0.3s" }}
      />
    </div>
  );
};

export default ExpandableSearch;
