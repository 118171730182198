import { notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Assets } from "../../constants/images";
import {
  GreyTagIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { GetCategoryTags } from "../../services/global/global";

const TagSlider = ({
  title,
  handleChange = "",
  value = "",
  categoryId = "",
}) => {
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState([]);
  const [cateogoryTags, setCategoryTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleClick = (id) => {
    let selectedTagsArr;
    if (selectedTags.includes(id)) {
      selectedTagsArr = selectedTags.filter((i) => i != id);
    } else if (selectedTags.length == 2) {
      selectedTagsArr = selectedTags;
    } else {
      selectedTagsArr = [...selectedTags, id];
    }

    if (typeof handleChange == "function") {
      handleChange(selectedTagsArr.length ? selectedTagsArr : "");
    }
    setSelectedTags(selectedTagsArr);
  };

  useEffect(() => {
    if (value != "" && value?.length) {
      setSelectedTags(value);
    }
  }, [value]);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      setSelectedTags([]);
    };
  }, []);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust the scroll speed as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleArrowClick = (direction) => {
    const container = containerRef.current;
    const scrollWidth = container.scrollWidth;
    const tagWidth = container.querySelector(".tag-box").offsetWidth;
    const numVisibleTags = Math.floor(container.clientWidth / tagWidth);
    const maxScrollLeft = scrollWidth - container.clientWidth;
    let newScrollLeft;

    if (direction == "left") {
      newScrollLeft = Math.max(0, scrollLeft - numVisibleTags * tagWidth);
    } else if (direction == "right") {
      newScrollLeft = Math.min(
        maxScrollLeft,
        scrollLeft + numVisibleTags * tagWidth,
      );
    }

    container.scrollTo({
      left: newScrollLeft,
      behavior: "smooth",
    });

    setScrollLeft(newScrollLeft);
  };

  const onInitGetCategoryTag = async () => {
    try {
      const params = {
        is_paginated: 0,
      };
      if (categoryId) {
        params.category_id = categoryId;
      }
      setLoadingTags(true);
      setCategoryTags([]);

      if (value != "" && value?.length) {
        setSelectedTags(value);
      } else {
        setSelectedTags([]);
      }

      const response = await GetCategoryTags(params);
      const { data, status, message } = response.data;
      setCategoryTags(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      setLoadingTags(false);
    }
  };

  useEffect(() => {
    if (categoryId) {
      onInitGetCategoryTag();
    }
  }, [categoryId]);

  return (
    <>
      <div className="small-tags">
        <div className="pro-div">
          <p>
            <b>Required:</b>{" "}
            {title == "flashcard"
              ? "Add one or two tags to help members find and filter this set more quickly."
              : title == "quizzes"
                ? "Add one or two tags to help members find and filter quizzes more quickly."
                : "Add one or two tags to help members find and filter resources more quickly."}
          </p>
          {cateogoryTags.length > 7 && (
            <div className="arrows" style={{ marginBottom: "1rem" }}>
              <img
                src={Assets.LeftArrow}
                alt="Left Arrow"
                className="img-fluid"
                style={{ width: "16px" }}
                onClick={() => handleArrowClick("left")}
              />
              <img
                src={Assets.RightArrow}
                alt="Right Arrow"
                className="img-fluid"
                style={{ width: "16px", marginLeft: "10px" }}
                onClick={() => handleArrowClick("right")}
              />
            </div>
          )}
        </div>
        <div
          className="main-tag-box"
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
        >
          {loadingTags && <p>Loading new tags...</p>}
          {!cateogoryTags.length && !loadingTags && (
            <p>No tags are available</p>
          )}
          {cateogoryTags.map((tag, index) => {
            let tagSelectionAvailability;
            if (selectedTags.includes(tag.id)) {
              tagSelectionAvailability = "active";
            } else if (selectedTags.length == 2) {
              tagSelectionAvailability = "inactive";
            }
            return (
              <div
                className={`tag-box ${tagSelectionAvailability}`}
                key={tag.id}
                onClick={() => handleClick(tag.id)}
              >
                <p>
                  <span>
                    <GreyTagIcon />
                  </span>
                  {tag.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TagSlider;
