import { createSlice } from "@reduxjs/toolkit";

export const newHireCheckListSlice = createSlice({
  name: "newHireCheckList",
  initialState: {
    loading: true,
    newHireCheckList: null,
    page: 1,
    perPage: 10,
    isPaginate: 1,
    reRenderTable: 1,
    actionButtonDisable: false,
    newHireCheckListDetail: null,
    reRenderNewHireDetailPage: 1,
    selectedData: null,
    pageLoading: false,
    newHireCheckListAddEditForm: {
      show: false,
      formType: null,
    },
    sorting: {
      column: "id",
      sortBy: "desc",
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNewHireCheckList: (state, action) => {
      state.newHireCheckList = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setIsPaginate: (state, action) => {
      state.isPaginate = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setNewHireCheckListDetail: (state, action) => {
      state.newHireCheckListDetail = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setNewHireCheckListAddEditForm: (state, action) => {
      state.newHireCheckListAddEditForm = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setReRenderNewHireDetailPage: (state, action) => {
      state.reRenderNewHireDetailPage = action.payload;
    },
  },
});

export const {
  setLoading,
  setNewHireCheckList,
  setPage,
  setPerPage,
  setIsPaginate,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setPageLoading,
  setNewHireCheckListDetail,
  setNewHireCheckListAddEditForm,
  setSelectedData,
  setReRenderNewHireDetailPage,
} = newHireCheckListSlice.actions;

export default newHireCheckListSlice.reducer;
