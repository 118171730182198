import React from "react";
import { Col, Row } from "react-bootstrap";
import { GoalIcon } from "../../../../../constants/svg";
import RadioModalCards from "../../../../Cards/RadioModalCards";
import { goal_type } from "../../../../../utils/constants";

const FirstStep = ({ handleSelect, selected }) => {
  return (
    <Row>
      <Col lg={6}>
        <div className="margin-bottom-26">
          <RadioModalCards
            title="Weekly Goal"
            para="Repeats the same day each week, like retail per client ticket."
            icon={<GoalIcon />}
            active={selected == goal_type?.weekly}
            handleClick={() => handleSelect(goal_type?.weekly)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-26">
          <RadioModalCards
            title="Monthly Goal"
            para="Repeats the same day each month, like pre-book percentage."
            icon={<GoalIcon />}
            active={selected == goal_type?.monthly}
            handleClick={() => handleSelect(goal_type?.monthly)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-39">
          <RadioModalCards
            title="Annual Goal"
            para="Ends on Dec 31 of the current year, like annual service sales."
            icon={<GoalIcon />}
            active={selected == goal_type?.annual}
            handleClick={() => handleSelect(goal_type?.annual)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-39">
          <RadioModalCards
            title="Custom Goal"
            para="Create a custom one-time goal with a single start and end date."
            icon={<GoalIcon />}
            active={selected == goal_type?.custom}
            handleClick={() => handleSelect(goal_type?.custom)}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FirstStep;
