import React, { useState } from "react";
import TicketHeader from "../../components/pages/TicketPage/TicketHeader";
import TicketBody from "../../components/pages/TicketPage/TicketBody";

const TicketPage = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <>
      <div className="innerContainer">
        <TicketHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        <TicketBody activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </>
  );
};

export default TicketPage;
