import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./slices/dashboardSlice";
import requestsSlice from "./slices/requestsSlice";
import activitiesSlice from "./slices/activitiesSlice";
import authSlice from "./slices/authSlice";
import aletsSlice from "./slices/aletsSlice";
import eventsSlice from "./slices/eventsSlice";
import categoriesSlice from "./slices/categoriesSlice";
import positionSlice from "./slices/positionSlice";
import surveySlice from "./slices/surveySlice";
import quizSlice from "./slices/quizSlice";

import candidateReferrelSlice from "./slices/candidateReferrelSlice";
import documentSlice from "./slices/documentSlice";
import groupSlice from "./slices/groupSlice";
import memeberSlice from "./slices/memberSlice";
import newHireCheckListSlice from "./slices/newHireChecklistSlice";
import rewardSlice from "./slices/rewardSlice";
import shoutoutSlice from "./slices/shoutoutSlice";
import globalSlice from "./slices/globalSlice";
import flashcardSlice from "./slices/flashcardSlice";

import goalsSlice from "./slices/goalsSlice";
import tasksSlice from "./slices/tasksSlice";
import locationsSlice from "./slices/locationsSlice";
import gallerysSlice from "./slices/gallerysSlice";
import salonTeamsSlice from "./slices/salonTeamsSlice";
import resourcesSlice from "./slices/resourcesSlice";
import usersSlice from "./slices/usersSlice";
import searchSlice from "./slices/searchSlice";
import chatsSlice from "./slices/chatsSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import billingAndPlanSlice from "./slices/billingAndPlanSlice";
import storageSlice from "./slices/storageSlice";
import organizationSlice from "./slices/organizationSlice";
import ticketSlice from "./slices/ticketSlice";

export default configureStore({
  reducer: {
    dashboard: dashboardSlice,
    requests: requestsSlice,
    activities: activitiesSlice,
    auth: authSlice,
    alerts: aletsSlice,
    events: eventsSlice,
    categories: categoriesSlice,
    survey: surveySlice,
    quiz: quizSlice,
    candidateReferrel: candidateReferrelSlice,
    document: documentSlice,
    group: groupSlice,
    member: memeberSlice,
    newHireCheckList: newHireCheckListSlice,
    reward: rewardSlice,
    shoutout: shoutoutSlice,
    global: globalSlice,
    flashCard: flashcardSlice,
    goals: goalsSlice,
    tasks: tasksSlice,
    locations: locationsSlice,
    gallerys: gallerysSlice,
    salon_teams: salonTeamsSlice,
    resources: resourcesSlice,
    users: usersSlice,
    search: searchSlice,
    chats: chatsSlice,
    position: positionSlice,
    subscription: subscriptionSlice,
    billingAndPlan: billingAndPlanSlice,
    storage: storageSlice,
    organization: organizationSlice,
    tickets: ticketSlice,
  },
});
