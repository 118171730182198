import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Assets } from "../../constants/images";
import { BlackCrossIcon } from "../../constants/svg";

const DeleteMemberModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-delete-member"
      >
        <Modal.Header>
          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <BlackCrossIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <img src={Assets.DeleteModalMember} alt="" width="30%" />
          <h4>
            <span>Remove</span> {props?.member?.name}?
          </h4>
          <p>
            <b>Important:</b> Removing this{" "}
            {props?.isLocation ? "location" : "member"} will delete them from
            your organization and their seat will not be counted towards your
            next billing cycle. However some of their history and activity may
            still appear in your dashboard.
          </p>
          <div className="btn-div">
            <Button
              className="border-grey-sm-btn me-2"
              onClick={props.onHide}
              data-bs-dismiss="modal"
            >
              Cancel
            </Button>
            <Button
              disable={props?.disable}
              className="solid-red-sm-btn"
              onClick={props?.handleDelete}
            >
              {props.disable ? <div className="load black" /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteMemberModal;
