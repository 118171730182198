import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const CustomTextArea = (props) => {
  return (
    <FloatingLabel className="custom-textarea" label={props?.label}>
      <Form.Control
        {...props}
        as="textarea"
        style={{ height: "200px" }}
        placeholder={props?.placeholder}
        className={props?.className}
        value={props?.value}
        onChange={props?.onChange}
        minLength={props?.minLength}
        maxLength={props?.maxLength}
      />
    </FloatingLabel>
  );
};

export default CustomTextArea;
