import { Dropdown, notification, Select, Space } from "antd";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import moment from "moment";
import { PatternFormat } from "react-number-format";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { maxBounds, Patterns } from "../../constants/contants";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowTriangleDown,
  CircleTickPurple,
  ModalCloseIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { getLocationsWithoutPagination } from "../../services/locations/frontend-locations";
import { addUser, updateUser } from "../../services/users/frontend-users";
import { GetSingleUser } from "../../services/users/users";
import { setCallSalonByIDAPI } from "../../store/slices/dashboardSlice";
import {
  setRenderMemberDetaiPage,
  setReRenderTable,
} from "../../store/slices/memberSlice";
import { roles, search_module_type } from "../../utils/constants";
import CustomDatePicker from "../FormFields/CustomDatePicker";
import CustomSelect from "../FormFields/CustomSelect";
import { showNumber, toastMessage } from "../../utils/helper";
import { setCreateUpdateTitleModuleID } from "../../store/slices/globalSlice";

const items = [
  {
    value: roles?.admin,
    label: (
      <div className="dropdownBox">
        <div className="head">
          <h6>Admin</h6>
          <div className="icon">
            <CircleTickPurple />
          </div>
        </div>
        <p className="gray">
          Can access the admin panel to manage billing, users and more.
        </p>
      </div>
    ),
  },
  {
    value: roles?.member,
    label: (
      <div className="dropdownBox">
        <div className="head">
          <h6>Member</h6>
          <div className="icon">
            <CircleTickPurple />
          </div>
        </div>
        <p className="gray">
          Can interact and engage with content from the mobile app.
        </p>
      </div>
    ),
  },
];

const InviteStaffModal = (props) => {
  const handleClickEmptyHref = (e) => {
    e.preventDefault();
  };
  const [selectedValue, setSelectedValue] = useState(items[1].value);
  const [isOwner, setIsOwner] = useState(false);
  const [uniqueId, setUniqueId] = useState(uuidv4());
  const selectedItem = items.find((item) => item.value == selectedValue);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid, isSubmitting, isSubmitted },
  } = useForm({ mode: "onChange" });
  const { locations, currentSalonId } = useSelector((state) => state?.global);
  const { callSalonByIDAPI } = useSelector((state) => state.dashboard);
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const {
    reRenderTable,
    memberAddEditForm,
    selectedData,
    renderMemberDetaiPage,
  } = useSelector((state) => state?.member);

  const resetForm = () => {
    reset({
      name: "",
      phone: "",
      email: "",
      location_id: null,
      hire_date: null,
      salon_id: "",
      role_id: "",
      isOwner: "",
    });
  };

  const onSuccessCreateMember = (msg) => {
    dispatch(setReRenderTable(reRenderTable + 1));
    dispatch(setRenderMemberDetaiPage(renderMemberDetaiPage + 1));
    setDisable(false);
    resetForm();
    // props?.setModalShow();
    toastMessage("success", msg);
  };
  const onSuccessUpdateMember = (msg) => {
    dispatch(setCallSalonByIDAPI(callSalonByIDAPI + 1));
    dispatch(setReRenderTable(reRenderTable + 1));
    dispatch(setRenderMemberDetaiPage(renderMemberDetaiPage + 1));
    setDisable(false);
    resetForm();
    props?.setModalShow();
    toastMessage("success", msg);
  };

  const onCreateMember = async (data) => {
    const apiData = {
      name: data?.name,
      phone: data?.phone,
      // email: data?.email,
      salon_location_id: data?.location_id,
      hire_date: moment(data?.hire_date).format("YYYY-MM-DD"),
      salon_id: currentSalonId,
      user_id: authUser?.id,
      role_id: selectedValue,
    };
    await addUser(apiData, setDisable, onSuccessCreateMember);
    resetForm();
  };

  const onUpdateMember = async (data) => {
    const apiData = {
      name: data?.name,
      phone: data?.phone,
      // email: data?.email,
      location_id: data?.location_id,
      hire_date: moment(data?.hire_date).format("YYYY-MM-DD"),
      salon_id: currentSalonId,
      role_id: selectedValue == roles?.admin ? roles?.admin : roles?.member,
    };
    await updateUser(
      selectedData?.id,
      apiData,
      setDisable,
      onSuccessUpdateMember,
    );
  };

  // const handleDisableSubmit = () => {
  //   if (disable || !isValid || isSubmitting) {
  //     return true
  //   }
  //   else {
  //     return false
  //   }
  // }

  useEffect(() => {
    if (isSubmitted) {
      setUniqueId(uuidv4());
    }
  }, [isSubmitted]);

  const onSubmit = async (data) => {
    const fullName = data.name.split(" ").filter((elem) => elem != "");
    if (fullName.length <= 1) {
      toastMessage("warning", "Please provide Full Name.");
      return;
    }
    data.phone = data?.phone ? "+1" + data.phone : "";
    if (memberAddEditForm?.formType == "update") {
      await onUpdateMember(data);
    } else {
      await onCreateMember(data);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const fetchLocations = async () => {
    const params = {
      is_paginated: 0,
      salon_id: currentSalonId,
    };
    await getLocationsWithoutPagination(params, dispatch);
  };

  const fetchAndPresetMemberData = async () => {
    try {
      const res = await GetSingleUser(selectedData?.id);
      const data = res.data.data;
      reset({
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
        location_id: data?.location_id ? parseInt(data?.location_id) : null,
        hire_date: data?.hire_date
          ? dayjs(data?.hire_date, "YYYY-MM-DD")
          : null,
        salon_id: data?.salon?.id,
        role_id: data?.roles[0]?.id,
        isOwner: data?.isOwner,
      });
      setSelectedValue(data?.roles[0]?.id);
      setIsOwner(data.isOwner);
    } catch (e) {
      notification.error({
        message: e.message,
        placement: "bottomRight",
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };
  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    if (memberAddEditForm?.formType == "update") {
      fetchAndPresetMemberData();
    }
  }, [memberAddEditForm]);

  useEffect(() => {
    fetchLocations();
  }, [currentSalonId]);

  useEffect(() => {
    if (props.show) {
      dispatch(setCreateUpdateTitleModuleID(search_module_type.members));
    }
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal invite-staff"
        onHide={props.onHide}
      >
        <div className="closeBtn" onClick={() => props.setModalShow(false)}>
          <ModalCloseIcon />
        </div>
        <Form key={uniqueId} onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body tabs-modal sub-invite">
            <Row>
              <Col lg={12}>
                <h3>
                  {memberAddEditForm.formType == "create"
                    ? "Send Invitation"
                    : "Edit Member"}
                </h3>
                <p>
                  Send staff an SMS invite to join your team on Salon Symphony.
                </p>
              </Col>

              <Col lg={12}>
                <FloatingLabel
                  controlId="fullName"
                  label="Full Name"
                  className="mb-20 position-relative"
                >
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    autoComplete="off"
                    role={"presentation"}
                    className={errors.fullName ? "hasError" : ""}
                    maxLength={maxBounds.NAME}
                    autoFocus
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Full name is required",
                      },
                      pattern: {
                        value: Patterns.Username,
                        // message: VALIDATIONS_TEXT.NAME_PATTERN,
                      },
                      maxLength: {
                        value: maxBounds.NAME,
                        // message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                    })}
                  />
                  <div
                    className="form-control-dropdown"
                    style={{
                      height: "55px",
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      backgroundColor: "white",
                      padding: "0px 0px 0px 16px",
                    }}
                  >
                    {isOwner ? (
                      <Dropdown
                        overlayClassName="singleDropdown"
                        className="singLock"
                        placement="bottom"
                        open={false}
                      >
                        <Space>Owner</Space>
                      </Dropdown>
                    ) : (
                      <Select
                        defaultValue={""}
                        onChange={handleSelectChange}
                        value={selectedValue}
                        className="withoutDropdown"
                        suffixIcon={<ArrowTriangleDown />}
                        popupClassName={"customDropdownMenu admindropdown"}
                        options={items}
                        placement="bottomRight"
                      />
                    )}
                  </div>
                </FloatingLabel>
              </Col>

              <Col lg={12}>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="phone"
                  rules={{
                    required: {
                      value: true,
                      message: "Phone is required",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <FloatingLabel
                        controlId="floatingPhone"
                        label="Phone Number"
                        className="mb-20"
                      >
                        <PatternFormat
                          value={showNumber(field.value)}
                          onValueChange={(values) => {
                            field.onChange(values.value);
                          }}
                          allowEmptyFormatting
                          placeholder="Phone Number"
                          format="+1 ###-###-####"
                          className={
                            errors.phoneNumber
                              ? "hasError form-control"
                              : "form-control"
                          }
                        />
                      </FloatingLabel>
                    );
                  }}
                />
              </Col>

              <Col lg={12} className="mb-20">
                <Controller
                  control={control}
                  name="location_id"
                  rules={{
                    required: {
                      value: true,
                      message: "Location is required",
                    },
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      className={errors.location_id ? "hasError" : ""}
                      placeholder={"Location"}
                      label="Location"
                      value={field?.value}
                      options={locations}
                      onChange={(e) => field?.onChange(e)}
                      // isDropdownOpen={true}
                    />
                  )}
                />
              </Col>

              <Col lg={12}>
                <Controller
                  control={control}
                  name="hire_date"
                  rules={{
                    required: {
                      value: true,
                      message: "Hire Date is required",
                    },
                  }}
                  render={({ field }) => (
                    <CustomDatePicker
                      {...field}
                      placeholder="Hire Date"
                      label="Hire Date"
                      className={errors.hire_date ? "hasError" : ""}
                      onChange={(date, dateString) => {
                        field?.onChange(
                          moment(dateString).format("YYYY-MM-DD"),
                        );
                      }}
                      value={
                        field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                      }
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                    />
                  )}
                />
              </Col>

              <Col lg={12} className="pt-4">
                <Button disabled={!isValid} type="submit" className={"w-100"}>
                  {disable ? (
                    <div className="load black" />
                  ) : memberAddEditForm.formType == "create" ? (
                    "Send Invite"
                  ) : (
                    "Edit Member"
                  )}
                </Button>
                <a
                  href={
                    "https://support.mysalonsymphony.com/hc/en-us/articles/19418823578267-User-Roles"
                  }
                  target="_blank"
                  className="learnmore nav-link"
                  rel="noreferrer"
                >
                  Learn more about user roles
                </a>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default InviteStaffModal;
