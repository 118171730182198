import { Empty, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import GenericModal from "../../components/Modals/GenericModal";
import CreateNewPoint from "../../components/Modals/Rewards/CreateNewPoint";
import CreateNewReward from "../../components/Modals/Rewards/CreateNewReward";
import UpdatePoint from "../../components/Modals/Rewards/UpdatePoint";
import { EditColorIcon, NewSparkleIcon, PlusSign } from "../../constants/svg";
import {
  GetSalonAchievements,
  UpdateSalonAchievement,
} from "../../services/team/rewards";
import { setAchievements, setRender } from "../../store/slices/rewardSlice";
import { status as salonStatus, tableHeight } from "../../utils/constants";
import { tableLoader, toastMessage } from "../../utils/helper";
import { Assets } from "../../constants/images";

const Rewards = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
    updatePointModal: null,
  });

  const [selectedPoint, setSelectedPoint] = useState(null);

  const [showGenericModal, setShowGenericModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const { achievements, render } = useSelector((state) => state.reward);
  const { currentSalonId } = useSelector((state) => state.global);

  const [activeTab, setActiveTab] = useState("tab1");

  const handleSwitchChange = async (id, type, isChecked, record) => {
    try {
      const payload = {
        //  name: record.achievement,
        //  description: record.description,
        // ...( record.badge && {badge: record.badge}),
        // ...( record.points  && {points: record.points}),
      };
      if (type == "badge") {
        payload.badge_status = isChecked
          ? salonStatus.active
          : salonStatus.inactive;
      } else if (type == "points") {
        payload.point_status = isChecked
          ? salonStatus.active
          : salonStatus.inactive;
      }

      const response = await UpdateSalonAchievement(id, payload);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setRender(render + 1));
      toastMessage("success", "Status changed successfully!");
    } catch (err) {
      toastMessage("error", err.message);
    }
  };

  const badgeColumn = [
    {
      title: "Achievement",
      dataIndex: "achievement",
      key: "achievement",
      render: (text, record) => (
        <NavLink
          to="/"
          onClick={(e) => {
            e.preventDefault();
          }}
          className={"black"}
        >
          <span>{record.achievement}</span>
        </NavLink>
      ),
    },
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      width: "150px",
      // sorter: (a, b) => a.sendType.main.localeCompare(b.sendType.main),
      render: (text, record) => (
        <>
          <div className="badgeDiv">
            <img src={record.badge} className="img-fluid" />
          </div>
        </>
      ),
      align: "left",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <span style={{ fontSize: "12px" }}>{record.description}</span>
      ),
    },
    {
      title: "Off/On",
      key: "action",

      render: (text, record) => (
        <>
          <div className="d-flex align-items-start">
            <Switch
              checked={record.badgeStatus == salonStatus.active}
              key={record.id}
              onChange={(checked) =>
                handleSwitchChange(record.id, "badge", checked, record)
              }
            />
          </div>
        </>
      ),
    },
  ];

  const pointColumns = [
    {
      title: "Achievement",
      dataIndex: "achievement",
      key: "achievement",
      render: (text, record) => (
        <NavLink
          to="/"
          onClick={(e) => {
            e.preventDefault();
          }}
          className={"black"}
        >
          <span>{record.achievement}</span>
        </NavLink>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      width: "150px",
      render: (text, record) => (
        <>
          <div className="point-div">
            {record.points}
            <span style={{ marginLeft: "16px" }}>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setSelectedPoint({ id: record.id, points: record.points });
                  setShowModal((prevState) => ({
                    ...prevState,
                    updatePointModal: true,
                  }));
                }}
              >
                <EditColorIcon />
              </span>
            </span>
          </div>
        </>
      ),
      align: "left",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <span style={{ fontSize: "12px" }}>{record.description}</span>
      ),
    },
    {
      title: "Off/On",
      key: "action",

      render: (text, record) => (
        <>
          <div className="d-flex align-items-start">
            <Switch
              checked={record.pointStatus == salonStatus.active}
              key={record.id}
              onChange={(checked) =>
                handleSwitchChange(record.id, "points", checked, record)
              }
            />
          </div>
        </>
      ),
    },
  ];

  const getSalonAchievements = async () => {
    try {
      if (achievements?.length == 0) {
        setLoadingTable(true);
      }
      const response = await GetSalonAchievements({
        is_paginated: 0,
        salon_id: currentSalonId,
      });
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      const dataModified = data.map((elem) => {
        return {
          id: elem.id,
          achievement: elem.name,
          description: elem.description,
          badge: elem.badge,
          badgeStatus: elem.badge_status,
          points: elem.points,
          pointStatus: elem.point_status,
          status: elem.status,
        };
      });
      dispatch(setAchievements(dataModified));
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    getSalonAchievements();
  }, [render, currentSalonId]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="teamTabMainHead">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Rewards</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                Manage Badges
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                Manage Points
              </div>
            </div>
          </div>
        </div>
        {activeTab == "tab1" ? (
          <button
            className="btn-gradient"
            onClick={() => setShowGenericModal(true)}
          >
            <span>
              <PlusSign />
            </span>
            Give Badges
          </button>
        ) : (
          <button
            className="btn-gradient"
            onClick={() =>
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }))
            }
          >
            <span>
              <PlusSign />
            </span>
            Give Points
          </button>
        )}
      </div>
      <div className="teamTabSubHead teamHeading">
        {activeTab == "tab1" ? (
          <p>
            <NewSparkleIcon />
            <b className="gap-6">Pro Tip:</b> When enabled, badges are
            automatically granted to members for the achievements below. &nbsp;
            <a
              href={
                "https://support.mysalonsymphony.com/hc/en-us/articles/19399499066267-Reward-Settings"
              }
              target="_blank"
              className={"uLine globalBlueLinkColor"}
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        ) : (
          <p>
            <NewSparkleIcon />
            <b className="gap-6">Pro Tip:</b> When enabled, points are
            automatically granted to members for the achievements below. &nbsp;
            <a
              href={
                "https://support.mysalonsymphony.com/hc/en-us/articles/19399499066267-Reward-Settings"
              }
              target="_blank"
              className={"uLine globalBlueLinkColor"}
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        )}
      </div>
      <div className="teamBody content">
        {activeTab == "tab1" ? (
          <Table
            locale={{
              emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
            }}
            columns={badgeColumn}
            dataSource={achievements}
            bordered={false}
            pagination={false}
            scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="main-reward-table"
            loading={tableLoader(loadingTable)}
          />
        ) : (
          <Table
            locale={{
              emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
            }}
            columns={pointColumns}
            dataSource={achievements}
            bordered={false}
            pagination={false}
            scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="main-point-table"
            loading={tableLoader(loadingTable)}
          />
        )}

        <GenericModal
          show={showGenericModal}
          onHide={() => setShowGenericModal(false)}
          setModalShow={setShowGenericModal}
          modalTitle={"Grant Reward Badge"}
          size={"lg"}
          bodyComponent={
            <CreateNewReward setShowGenericModal={setShowGenericModal} />
          }
          customClassName={"rewardModal"}
        />
        <CreateNewPoint
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        />
        <UpdatePoint
          show={showModal.updatePointModal}
          selectedPoint={selectedPoint}
          onHide={() => {
            setSelectedPoint(null);
            setShowModal({ ...showModal, updatePointModal: false });
          }}
        />
      </div>
    </>
  );
};

export default Rewards;
