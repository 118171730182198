import { Dropdown, Image, notification, Popover, Space, Table } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Assets } from "../../constants/images";
import {
  ArrowDown,
  BinocularsLight,
  MoreIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";
import { GetActivities } from "../../services/activities/activities";
import { updateActivity } from "../../services/activities/frontend-activities";
import { GetStatistics } from "../../services/dashboard/dashboard";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  DeleteSingleRequest,
  GetRequests,
} from "../../services/requests/requests";
import {
  setActivities,
  setEducationRequest,
  setIsActivitiesLoading,
  setIsEducationRequestLoading,
  setIsScheduleRequestLoading,
  setIsStatisticsLoading,
  setIsTimeOffRequestLoading,
  setScheduleRequest,
  setStatistics,
  setTimeOffRequest,
} from "../../store/slices/dashboardSlice";
import {
  activity_type,
  constant,
  roles as salonRoles,
  user_requests,
  user_requests_status,
} from "../../utils/constants";
import ShareModal from "../../components/Modals/ShareModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import UserLimitModal from "../../components/Modals/UserLimitModal";

import { setCurrentSubscriptionDetail } from "../../store/slices/subscriptionSlice";
import { GetCurrentSubscription } from "../../services/subscription/subscription";
import {
  getActivityTypeURL,
  tableLoader,
  toastMessage,
} from "../../utils/helper";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetTicketList } from "../../services/tickets/tickets";
import { setTickets } from "../../store/slices/ticketSlice";
import ProfileHover from "../../components/Common/ProfileHover";
import { v4 as uuidv4 } from "uuid";
import AvatarImage from "../../components/Common/AvatarImage";
import StorageLimitModal from "../../components/Modals/StorageLimitModal";

function DashboardPage() {
  const { positions } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(0);
  const { statistics } = useSelector((state) => state?.dashboard);
  const { currentSubscriptionDetail } = useSelector(
    (state) => state?.subscription,
  );
  const totalStorage = currentSubscriptionDetail?.meta?.total_storage || 0;
  const usedStorage =
    currentSubscriptionDetail?.salon?.storage_available_gbs || 0;
  const storageReachedOut = usedStorage > 0 && usedStorage >= totalStorage;

  const { statUserHover, currentSalonId } = useSelector(
    (state) => state?.global,
  );
  const [show, setShow] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [selectedData, setSelectedData] = useState(null);
  const url = window.location.href;
  const [disable, setDisable] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { isActivitiesLoading, activities } = useSelector(
    (state) => state?.dashboard,
  );
  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.tickets);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const authUser = GetAuthUserLocalStorage();
  const { me } = useSelector((state) => state.auth);
  const [showStorageLimitModal, setShowStorageLimitModal] = useState(false);
  const [isLoadedStatics, setIsLoadedStatics] = useState(false);
  const [isLoadedSubscription, setIsLoadedSubscription] = useState(false);
  const [isExistModal, setIsExistModal] = useState(false);
  const isLoadedUserLimitData = isLoadedSubscription && isLoadedStatics;

  const handleSetSelectedData = (record) => {
    setSelectedData({
      key: record?.key,
      name: record?.alertTitle?.title,
      description: record?.comment,
      image: null,
      start_date: record?.created_at
        ? moment(record?.created_at).format("MMM DD, yyyy")
        : "-",
      start_time: record?.created_at
        ? moment(record?.created_at).format("hh:mm a")
        : "-",
    });
  };
  const handleDelete = async () => {
    try {
      setDisable(true);
      const res = await DeleteSingleRequest(selectedData?.key);
      setShowModal({ ...showModal, deleteModal: false });
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
      getTimeOffRequests();
      getEducationRequests();
      getScheduleRequests();
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };
  const handleMouseEnter = (item) => {
    setHoveredItem(item.id);
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };
  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/requests/details/${record?.key}`}
              className={"black"}
            >
              <BinocularsLight />
              Details
            </NavLink>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              handleSetSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "alertTitle",
      sorter: false,
      key: "alertTitle",
      render: (text, record) => (
        <NavLink to={`/requests/details/${record?.key}`} className={"black"}>
          <Space size={12}>
            <AvatarImage
              name={record?.alertTitle?.title}
              src={record?.alertTitle?.image}
              placeholder={Assets.ProfilePlaceholder}
              size={38}
            />
            <p className="">
              <span className={"request-username"}>
                {record?.alertTitle?.title}
              </span>
              <span className="d-block text-capitalize">
                {record?.alertTitle?.sub === "a day ago"
                  ? "Yesterday"
                  : record?.alertTitle?.sub}
              </span>
            </p>
          </Space>
        </NavLink>
      ),
      width: "32%",
    },
    {
      title: "Reason",
      dataIndex: "sendType",
      key: "sendType",
      sorter: false,
      render: (text, record) => (
        <p className="custom-fw-400">
          {record?.sendType?.main}
          <span className="d-block">{record?.sendType?.sub}</span>
        </p>
      ),
      align: "left",
      width: "20%",
    },
    {
      title: "Leave",
      dataIndex: "firstSent",
      key: "firstSent",
      sorter: false,
      render: (text, record) => (
        <p className="custom-fw-400">
          {record?.firstSent?.main}
          <span className="d-block">{record?.firstSent?.sub}</span>
        </p>
      ),
      align: "left",
      width: "20%",
    },
    {
      title: "Return",
      dataIndex: "lastSent",
      key: "lastSent",
      sorter: false,
      render: (text, record) => (
        <p className="custom-fw-400">
          {record?.lastSent?.main}
          <span className="d-block">{record?.lastSent?.sub}</span>
        </p>
      ),
      align: "left",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div
              className="icon cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      width: "8%",
    },
  ];
  const ticketColumns = [
    {
      title: "Ticket Status",
      dataIndex: "status",
      sorter: false,
      key: "status",
      render: (text, record) => (
        <span className="open_staus" style={{ textTransform: "capitalize" }}>
          {record.status}
        </span>
      ),
      width: "20%",
    },
    // {
    //   title: "Subject",
    //   dataIndex: "subject",
    //   key: "subject",
    //   sorter: false,
    //   render: (text, record) =>(
    //       <a href={record.url} target="_blank"><p>{ShortenText(record.subject,30)}</p></a>
    //   ),
    //   align: "left",
    //   width: "30%",
    // },
    {
      title: "Requester",
      dataIndex: "requester_id",
      key: "requester_id",
      sorter: false,
      render: (text, record) => <p>{record.requester_id}</p>,
      align: "left",
      width: "20%",
    },
    {
      title: "Requested",
      dataIndex: "created_at",
      key: "created_at",
      sorter: false,
      render: (text, record) => <p>{record.created_at}</p>,
      align: "left",
      width: "20%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
      render: (text, record) => <p>{record.type}</p>,
      align: "left",
      width: "10%",
    },
  ];
  const handleItemClick = (item) => {
    setActiveItem((prevItem) => (prevItem == item ? null : item));
  };
  const navigateFunc = () => {
    if (GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin) {
      navigate("/tickets");
    } else {
      navigate("/requests");
    }
  };
  const navigateActivity = () => {
    navigate("/activity");
  };
  const handleActivityClick = async (data) => {
    const apiData = {
      name: data?.name,
      is_read: activity_type.read,
      salon_id: currentSalonId,
    };
    await updateActivity(data?.id, apiData);
  };
  const getStatistics = async () => {
    let auth = GetAuthUserLocalStorage();
    if (auth) {
      try {
        const params = {
          salon_id: auth?.salon_id,
        };
        dispatch(setIsStatisticsLoading(true));
        const res = await GetStatistics(params);
        const data = res.data.data;
        dispatch(
          setStatistics({
            totalEventsCount: data?.eventCount,
            totalMembersCount: data?.membersCount,
            totalGoalsCount: data?.goalCount,
            totalTasksCount: data?.taskCount,
            accountCount: data?.accountCount,
            openTickets: data?.openTickets,
            requestsCount: data?.requestsCount,
            invitesCount: data?.invitesCount,
          }),
        );
        dispatch(setIsStatisticsLoading(false));
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        dispatch(setIsStatisticsLoading(false));
      }
    }
  };
  const waitGetStatistics = async () => {
    await getStatistics();
    setIsLoadedStatics(true);
  };
  const getTimeOffRequests = async () => {
    try {
      const params = {
        is_paginated: 1,
        page: 1,
        "per-page": 4,
        type: user_requests.timeoff,
        salon_id: currentSalonId,
        status: user_requests_status?.pending,
      };
      dispatch(setIsTimeOffRequestLoading(true));
      const res = await GetRequests(params);
      let data = res.data.data.data?.map((item) => {
        return {
          key: item?.id,
          alertTitle: {
            title: item?.user?.name || "-",
            sub: moment.utc(item?.created_at).local().fromNow() || "-",
            image: item?.user?.image || Assets.ProfilePlaceholder,
          },
          sendType: {
            main: item?.subTypeName || "-",
            sub: "Reason",
          },
          firstSent: {
            main: item?.date ? moment(item?.date).format("MMM DD, yyyy") : "-",
            sub: "Leave",
          },
          lastSent: {
            main: item?.return_date
              ? moment(item?.return_date).format("MMM DD, yyyy")
              : "-",
            sub: "Return",
          },
          created_at: item?.created_at,
        };
      });
      dispatch(setTimeOffRequest({ meta: res.data.data?.meta, data }));
      dispatch(setIsTimeOffRequestLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsTimeOffRequestLoading(false));
    }
  };
  const getEducationRequests = async () => {
    try {
      const params = {
        is_paginated: 1,
        page: 1,
        "per-page": 4,
        type: user_requests.education,
        salon_id: currentSalonId,
        status: user_requests_status?.pending,
      };
      dispatch(setIsEducationRequestLoading(true));
      const res = await GetRequests(params);
      let data = res.data.data.data?.map((item) => {
        return {
          key: item?.id,
          alertTitle: {
            title: item?.user?.name || "-",
            sub: moment.utc(item?.created_at).local().fromNow() || "-",
            image: item?.user?.image || Assets.ProfilePlaceholder,
          },
          sendType: {
            main: item?.typeName || "-",
            sub: "Reason",
          },
          firstSent: {
            main: item?.type == 40 ? "$" + item?.value : item?.hour || "-",
            sub: "Total",
          },
          lastSent: {
            main: item?.date
              ? moment(item?.created_at).format("MMM DD, yyyy")
              : "-",
            sub: "Date",
          },
          created_at: item?.created_at,
        };
      });
      dispatch(setEducationRequest({ meta: res.data.data?.meta, data }));
      dispatch(setIsEducationRequestLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsEducationRequestLoading(false));
    }
  };
  const getScheduleRequests = async () => {
    try {
      const params = {
        is_paginated: 1,
        page: 1,
        "per-page": 4,
        type: user_requests.schedule,
        salon_id: currentSalonId,
        status: user_requests_status?.pending,
      };
      dispatch(setIsScheduleRequestLoading(true));
      const res = await GetRequests(params);
      let data = res.data.data.data?.map((item) => {
        return {
          key: item?.id,
          alertTitle: {
            title: item?.user?.name || "-",
            sub: moment.utc(item?.created_at).local().fromNow() || "-",
            image: item?.user?.image || Assets.ProfilePlaceholder,
          },
          sendType: {
            main: item?.subTypeName || "-",
            sub: "Reason",
          },
          firstSent: {
            main:
              item?.dayName && item?.preAvailable
                ? item?.dayName + ", " + item?.preAvailable
                : "-",
            sub: "Current",
          },
          lastSent: {
            main:
              item?.dayName && item?.available
                ? item?.dayName + ", " + item?.available
                : "-",
            sub: "Desired",
          },
          created_at: item?.created_at,
        };
      });
      dispatch(setScheduleRequest({ meta: res.data.data?.meta, data }));
      dispatch(setIsScheduleRequestLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsScheduleRequestLoading(false));
    }
  };
  const getActivities = async () => {
    try {
      const params = {
        is_paginated: 1,
        page: 1,
        "per-page": 10,
        salon_id: currentSalonId,
        is_read: activity_type.unread,
      };
      dispatch(setIsActivitiesLoading(true));
      const res = await GetActivities(params);
      const data = res.data.data?.data;
      dispatch(setActivities({ meta: res.data.data?.meta, data }));
      dispatch(setIsActivitiesLoading(false));
      if (res.data.data?.meta.total == data?.length) {
        setHasMore(false);
      }
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsActivitiesLoading(false));
    }
  };
  const getCurrentSubscription = async () => {
    try {
      const response = await GetCurrentSubscription(
        GetAuthUserLocalStorage()?.salon?.id,
      );
      const { data, message, status } = response.data;
      if (!data) throw new Error();
      dispatch(setCurrentSubscriptionDetail(data));
    } catch (err) {}
  };
  const waitGetCurrentSubscription = async () => {
    await getCurrentSubscription();
    setIsLoadedSubscription(true);
  };
  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };
  const getTickets = async () => {
    try {
      const payload = {
        is_paginated: 1,
        status: "new",
        "order-column": "created_at",
        order: "desc",
        "per-page": 4,
        page: 1,
      };
      setLoadingTickets(true);
      const response = await GetTicketList(payload);
      const { status, message, data } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      const dataM = data.results.map((elem) => ({
        status: elem.status,
        subject: elem.subject ? elem.subject : "-",
        requester_id: elem.requester_id,
        created_at: moment(elem.created_at).format("MMM DD, yyyy"),
        type: elem.type ? elem.type : "-",
        priority: elem.priority ? elem.priority : "-",
        url: constant.ZENDESK_TICKET_URL + elem?.id,
      }));
      dispatch(
        setTickets({
          data: dataM,
          total: data.count,
        }),
      );
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoadingTickets(false);
    }
  };

  useEffect(() => {
    if (isLoadedUserLimitData && !isExistModal) {
      const checkToShowStorageModal = () => {
        if (storageReachedOut) {
          setShowStorageLimitModal(storageReachedOut);
        }
      };

      if (
        currentSubscriptionDetail?.billing_plan?.total_members &&
        statistics?.invitesCount
      ) {
        const total = statistics?.invitesCount + statistics?.totalMembersCount;
        const isShowUpgrade =
          total >= currentSubscriptionDetail?.billing_plan?.total_members;
        setUpgradeModal(isShowUpgrade);
        if (!isShowUpgrade) {
          checkToShowStorageModal();
        }
      } else {
        checkToShowStorageModal();
      }
    }
  }, [
    statistics,
    currentSubscriptionDetail,
    isLoadedUserLimitData,
    storageReachedOut,
    isExistModal,
  ]);
  useEffect(() => {
    if (upgradeModal || showStorageLimitModal) {
      setIsExistModal(true);
    }
  }, [upgradeModal, showStorageLimitModal]);

  useEffect(() => {
    if (GetAuthUserLocalStorage().roles[0].id != salonRoles.super_admin) {
      waitGetCurrentSubscription();
    }
  }, []);
  useEffect(() => {
    waitGetStatistics();
    if (GetAuthUserLocalStorage().roles[0].id != salonRoles.super_admin) {
      getTimeOffRequests();
      getScheduleRequests();
      getEducationRequests();
    } else {
      getTickets();
    }
    getActivities();
  }, [currentSalonId]);
  const fetchMoreData = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        let next_page = activities.meta.current_page + 1;
        const params = {
          is_paginated: 1,
          page: next_page,
          "per-page": 10,
          salon_id: currentSalonId,
          is_read: activity_type.unread,
        };
        const response = await GetActivities(params);
        const newData = response.data.data?.data;
        const hasPostsData = response.data.data?.data?.length > 0;
        if (hasPostsData) {
          const updatedData = [...activities.data, ...newData];
          if (
            response.data.data?.meta?.total &&
            response.data.data?.meta?.total == updatedData?.length
          ) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          dispatch(
            setActivities({
              meta: response.data.data?.meta,
              data: updatedData,
            }),
          );
          setLoading(false);
        }
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setLoading(false);
      }
    }, 1500);
  };

  const getDayText = (createdAt) => {
    const value = moment.utc(createdAt).local().fromNow();
    return value === "a day ago" ? "Yesterday" : value;
  };

  return (
    <>
      <div className="innerContainer dashboard_bg_grey pt-4 pb-4">
        <Row>
          <Col lg={8}>
            {GetAuthUserLocalStorage().roles[0].id ==
              salonRoles.super_admin && (
              <>
                <div className="dashboard_state">
                  <NavLink
                    to={"/organization"}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>{statistics?.accountCount}</h4>
                        <p>Total Accounts</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to={"/organization"}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>
                          {statistics?.totalMembersCount +
                            statistics.invitesCount}
                        </h4>
                        <p>Total Users</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to={"/tickets"}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>{statistics?.openTickets}</h4>
                        <p>Open Tickets</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to="#"
                    state={{ activeTab: "tab2" }}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>{"0"}</h4>
                        <p>Lost Accounts</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <DashboardSupportTicket
                  navigateFunc={navigateFunc}
                  activeItem={activeItem}
                  handleItemClick={handleItemClick}
                  tableData={tickets}
                  loadingTickets={loadingTickets}
                  columns={
                    GetAuthUserLocalStorage()?.roles[0]?.id ==
                    salonRoles.super_admin
                      ? ticketColumns
                      : columns
                  }
                />
              </>
            )}
            {GetAuthUserLocalStorage().roles[0].id !=
              salonRoles.super_admin && (
              <>
                {" "}
                <div className="dashboard_state">
                  <NavLink
                    to={"/team/members"}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>
                          {statistics?.totalMembersCount +
                            statistics.invitesCount}
                        </h4>
                        <p>Total Members</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to={"/events"}
                    className="dashboard-link state_items"
                  >
                    <div className="">
                      <div>
                        <h4>{statistics?.totalEventsCount}</h4>
                        <p>Upcoming Events</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={"/tasks"} className="dashboard-link state_items">
                    <div className="">
                      <div>
                        <h4>{statistics?.totalTasksCount}</h4>
                        <p>Task Reminders</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/goals"
                    state={{ activeTab: "tab2" }}
                    className="dashboard-link state_items achieved-goals"
                  >
                    <div className="">
                      <div>
                        <h4>{statistics?.totalGoalsCount}</h4>
                        <p>Achieved Goals</p>
                        <span>Manage</span>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <DashboardRequests
                  navigateFunc={navigateFunc}
                  activeItem={activeItem}
                  handleItemClick={handleItemClick}
                  columns={columns}
                  stats={
                    statistics?.requestsCount ? statistics?.requestsCount : null
                  }
                />
              </>
            )}
          </Col>
          <Col lg={4} onMouseLeave={handleMouseLeave}>
            <div className="dashboard_activity">
              <div className="header_dashboard">
                <div className="heading_class">
                  <h2>Activity</h2>
                </div>
                <div className="items_class">
                  <ul>
                    <li onClick={navigateActivity} className="cursor-pointer">
                      See All →
                    </li>
                  </ul>
                </div>
              </div>
              <div className="activity_listing">
                <div className="listing_item active">
                  <InfiniteScroll
                    dataLength={activities?.data?.length}
                    next={fetchMoreData}
                    scrollableTarget="infinite-scroll-component "
                    hasMore={hasMore} // You can conditionally set this based on your data
                    loader={
                      loading ? (
                        <div className="loader-chat">
                          <Image
                            width={100}
                            height={100}
                            src={Assets.loadingGif}
                            preview={false}
                          />
                        </div>
                      ) : null
                    }
                    height={"calc(100vh - 219px)"}
                  >
                    {isActivitiesLoading ? (
                      // <div className="load black" />
                      <LoadingScreen child={true} />
                    ) : activities?.data?.length <= 0 ? (
                      // <span>Check back soon!</span>
                      <div className="activity_items">
                        <div
                          style={{ position: "relative" }}
                          className="img_box"
                          key={uuidv4()}
                        >
                          <AvatarImage
                            src={Assets.notificationIcon}
                            placeholder={Assets.ProfilePlaceholder}
                          />
                        </div>
                        <NavLink to={"/activity"}>
                          <div className="txt_box">
                            <span className="title">
                              Welcome to Salon Symphony!
                            </span>
                            <ul className="activity__list__Description">
                              <li>
                                Activity will appear here as soon as you add
                                your team!
                              </li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    ) : (
                      activities?.data?.map((item, index) => {
                        if (item?.name) {
                          return (
                            <div className="activity_items" key={index}>
                              <Popover
                                placement="rightTop"
                                content={
                                  <ProfileHover
                                    user={{
                                      key: item?.id,
                                      id: item?.user?.id,
                                      name: item?.user?.name,
                                      image: item?.user?.image,
                                      position: returnPosition(
                                        item?.user?.position_id,
                                      ),
                                    }}
                                  />
                                }
                                arrow={true}
                                key={item?.id}
                              >
                                <div
                                  style={{ position: "relative" }}
                                  className="img_box"
                                  // onMouseEnter={(e) => {
                                  //   dispatch(setStatUserHover({
                                  //     key: item?.id,
                                  //     id: item?.user?.id,
                                  //     name: item?.user?.name,
                                  //     image: item?.user?.image
                                  //   }))
                                  // }}
                                  // onMouseLeave={() => {
                                  //   dispatch(setStatUserHover(null))
                                  // }}
                                >
                                  <AvatarImage
                                    name={item?.user?.name}
                                    src={item?.user?.image}
                                    placeholder={Assets.ProfilePlaceholder}
                                    size={42}
                                  />
                                  {/* <ProfileHoverChat */}
                                  {/*    user={{ */}
                                  {/*      key: item?.id, */}
                                  {/*      id: item?.user?.id, */}
                                  {/*      name: item?.user?.name, */}
                                  {/*      image: item?.user?.image, */}
                                  {/*      position: returnPosition(item?.user?.position_id) */}
                                  {/*    }} */}
                                  {/*    show={(statUserHover && (statUserHover.key == item?.id)) ? true : false} */}
                                  {/* /> */}
                                </div>
                              </Popover>
                              <NavLink
                                // onClick={() => handleActivityClick(item)}
                                key={index}
                                to={
                                  item?.model && item?.model_id
                                    ? getActivityTypeURL(
                                        item?.model,
                                        item?.model_id,
                                      )
                                    : "/activity"
                                }
                              >
                                <div className="txt_box">
                                  <span className="title">
                                    {item?.name || "-"}
                                  </span>
                                  {/* <div */}
                                  {/*  className={ */}
                                  {/*    item?.is_read == activity_type.unread ? "unread-circle" : "" */}
                                  {/*  } */}
                                  {/* ></div> */}
                                  <ul className="activity__list__Description">
                                    <li> {item?.description || "-"}</li>
                                    <li className="text-capitalize">
                                      {getDayText(item?.created_at)},{" "}
                                      {item?.created_at
                                        ? moment
                                            .utc(item?.created_at)
                                            .local()
                                            .format("hh:mm a")
                                            .toUpperCase()
                                        : "-"}
                                    </li>
                                  </ul>
                                </div>
                              </NavLink>
                            </div>
                          );
                        }
                      })
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ShareModal
        shareLink={url}
        data={selectedData}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Request"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this event with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />
      <UserLimitModal
        show={upgradeModal}
        suggestions={upgradeModal}
        onHide={() => setUpgradeModal(false)}
        setModalShow={() => setUpgradeModal(true)}
      />
      {/* Delete Modal */}
      <DeleteModal
        disable={disable}
        handleDelete={handleDelete}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Request"
        para={
          <>
            Are you sure you want to delete{" "}
            <b>{selectedData?.name || "this"} request?</b>
            <br />
            Important: This cannot be recovered.
          </>
        }
        notificationMessage="Request"
      />
      <StorageLimitModal
        show={showStorageLimitModal}
        suggestions={false}
        onHide={() => setShowStorageLimitModal(false)}
        setModalShow={() => setShowStorageLimitModal(true)}
      />
    </>
  );
}
const DashboardRequests = ({
  navigateFunc,
  activeItem,
  handleItemClick,
  columns,
  stats = null,
}) => {
  const {
    isEducationRequestLoading,
    isTimeOffRequestLoading,
    isScheduleRequestLoading,
    educationRequest,
    timeOffRequest,
    scheduleRequest,
  } = useSelector((state) => state?.dashboard);
  return (
    <div className="dashboard_request">
      <div className="header_dashboard">
        <div className="heading_class">
          <h2>Requests</h2>
        </div>
        <div className="items_class">
          <ul>
            <li onClick={navigateFunc} className="cursor-pointer">
              See All →
            </li>
          </ul>
        </div>
      </div>
      <div className="dashboard_listing">
        <div
          className={`listing_item ${activeItem == 0 ? "active" : ""}`}
          onClick={() => handleItemClick(0)}
        >
          <h4>
            <ArrowDown /> Time Off (
            {stats?.timeOffRequests ? stats?.timeOffRequests : 0})
          </h4>
          {timeOffRequest?.data?.length > 0 && (
            <Table
              loading={tableLoader(isTimeOffRequestLoading)}
              columns={columns}
              showHeader={false}
              dataSource={timeOffRequest?.data}
              bordered={false}
              pagination={false}
              scroll={{ x: "max-content" }}
              responsive={true}
              size={"large"}
              showSorterTooltip={false}
              className={"nobg_table"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                  }, // click row
                };
              }}
            />
          )}
          {timeOffRequest?.data?.length == 0 && (
            <div className={"nobg_table ant-table-wrapper"}>
              <p className="fallBackText">Pending requests will appear here.</p>
            </div>
          )}
        </div>
        <div
          className={`listing_item ${activeItem == 1 ? "active" : ""}`}
          onClick={() => handleItemClick(1)}
        >
          <h4>
            <ArrowDown /> Schedule (
            {stats?.scheduleRequests ? stats?.scheduleRequests : 0})
          </h4>
          {scheduleRequest?.data?.length ? (
            <Table
              loading={tableLoader(isScheduleRequestLoading)}
              columns={columns}
              dataSource={scheduleRequest?.data}
              showHeader={false}
              bordered={false}
              pagination={false}
              scroll={{ x: "max-content" }}
              responsive={true}
              size={"large"}
              showSorterTooltip={false}
              className={"nobg_table"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                  }, // click row
                };
              }}
            />
          ) : (
            <div className={"nobg_table ant-table-wrapper"}>
              <p className="fallBackText">Pending requests will appear here.</p>
            </div>
          )}
        </div>
        <div
          className={`listing_item ${activeItem == 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
        >
          <h4>
            <ArrowDown /> Education (
            {stats?.educationRequests ? stats?.educationRequests : 0})
          </h4>
          {educationRequest?.data?.length ? (
            <Table
              loading={tableLoader(isEducationRequestLoading)}
              columns={columns}
              dataSource={educationRequest?.data}
              showHeader={false}
              bordered={false}
              pagination={false}
              scroll={{ x: "max-content" }}
              responsive={true}
              size={"large"}
              showSorterTooltip={false}
              className={"nobg_table"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                  }, // click row
                };
              }}
            />
          ) : (
            <div className={"nobg_table ant-table-wrapper"}>
              <p className="fallBackText">Pending requests will appear here.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const DashboardSupportTicket = ({
  navigateFunc,
  activeItem,
  handleItemClick,
  columns,
  tableData,
  loadingTickets,
}) => {
  return (
    <div className="dashboard_request">
      <div className="header_dashboard">
        <div className="heading_class">
          <h2>Support Tickets</h2>
        </div>
        <div className="items_class">
          <ul>
            <li onClick={navigateFunc} className="cursor-pointer">
              See All →
            </li>
          </ul>
        </div>
      </div>
      <div className="dashboard_listing">
        <div className={`listing_item ${activeItem == 0 ? "active" : ""}`}>
          <h4 onClick={() => handleItemClick(0)}>
            <ArrowDown /> Total Tickets ({tableData?.total || 0})
          </h4>
          {loadingTickets ? (
            <LoadingScreen child={true} />
          ) : (
            <Table
              columns={columns}
              dataSource={tableData.data}
              bordered={false}
              pagination={false}
              scroll={{ x: "max-content" }}
              responsive={true}
              size={"large"}
              showSorterTooltip={false}
              className={"nobg_table flashCardTable row-pointer"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    window.open(record.url, "_blank");
                  },
                };
              }}
            />
          )}
        </div>
        {/* <div
                  className={`listing_item ${activeItem == 1 ? "active" : ""}`}
                  onClick={() => handleItemClick(1)}
                >
                  <h4>
                    <ArrowDown /> Schedule (0)
                  </h4>
                  {activeItem == 1 &&
                    <p className="fallBackText">You're all caught up!</p>
                  }
                  <Table
                    columns={columns}
                    dataSource={datathree}
                    bordered={false}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                    responsive={true}
                    size={"large"}
                    showSorterTooltip={false}
                    className={"nobg_table"}
                  />
                </div>
                <div
                  className={`listing_item ${activeItem == 2 ? "active" : ""}`}
                  onClick={() => handleItemClick(2)}
                >
                  <h4>
                    <ArrowDown /> Education (4)
                  </h4>
                  <Table
                    columns={columns}
                    dataSource={datatwo}
                    bordered={false}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                    responsive={true}
                    size={"large"}
                    showSorterTooltip={false}
                    className={"nobg_table"}
                  />
                </div> */}
      </div>
    </div>
  );
};
export default DashboardPage;
