import React, { useEffect, useState } from "react";
import { Assets } from "../../../constants/images";
import {
  Dropdown,
  Empty,
  notification,
  Select,
  Space,
  Table,
  ConfigProvider,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  BinocularsLight,
  CheckMarkIcon,
  MoreIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
  UpDpwnArrow,
  NoDataIcon,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  DeleteSingleRequest,
  GetRequests,
  UpdateRequest,
} from "../../../services/requests/requests";
import {
  setIsTimeOffRequestLoading,
  setPagination,
  setTimeOffRequest,
} from "../../../store/slices/requestsSlice";
import {
  SHARE_URL,
  tableHeight,
  user_requests,
  user_requests_status,
} from "../../../utils/constants";

import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import StatusModal from "../../Modals/StatusModal";
import { tableLoader, toastMessage } from "../../../utils/helper";
import LazyLoadTable from "../../Table/LazyLoadTable";
import AvatarImage from "../../Common/AvatarImage";
import { GetStatistics } from "../../../services/dashboard/dashboard";
import { setStatistics } from "../../../store/slices/dashboardSlice";

const menuOption = [
  {
    name: "Pending",
    value: "Pending",
  },
  {
    name: "Approve",
    value: "Approved",
  },
  {
    name: "Decline",
    value: "Declined",
  },
];

const TimeOff = (props) => {
  const dispatch = useDispatch();
  const { currentSalonId } = useSelector((state) => state.global);
  const { isTimeOffRequestLoading, timeOffRequest, filters, pagination } =
    useSelector((state) => state?.requests);

  const [selectedValue, setSelectedValue] = useState(menuOption[0].name);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [statusModal, setStatusModal] = useState({
    showModal: false,
    option: "",
  });
  const value = statusModal.option;
  const formattedValue = value.charAt(0).toLowerCase() + value.slice(1);

  const [selectedData, setSelectedData] = useState(null);
  const url = window.location.host;
  const [disable, setDisable] = useState(false);
  const authUser = GetAuthUserLocalStorage();

  const handleChange = (value, record) => {
    if (value == "Approve" || value == "Decline") {
      setStatusModal({ showModal: true, option: value });
    } else {
      setSelectedValue(value);
    }
    setSelectedData(record);
  };

  const onApprove = async (msg) => {
    try {
      const data = {
        status: user_requests_status?.accepted,
        salon_id: currentSalonId,
        approved_by: authUser?.id,
        type: selectedData.type,
        sub_type: selectedData.sub_type,
        note: msg,
      };
      setDisable(true);
      const res = await UpdateRequest(selectedData?.key, data);
      setDisable(false);
      setStatusModal({ ...statusModal, showModal: false });
      toastMessage("success", res.data.message);
    } catch (e) {
      setDisable(false);
      toastMessage("warning", e.message);
    }
  };

  const onDecline = async (msg) => {
    try {
      const data = {
        status: user_requests_status?.declined,
        salon_id: currentSalonId,
        approved_by: authUser?.id,
        type: selectedData.type,
        sub_type: selectedData.sub_type,
        note: msg,
      };
      setDisable(true);
      const res = await UpdateRequest(selectedData?.key, data);
      setDisable(false);
      setStatusModal({ ...statusModal, showModal: false });
      toastMessage("success", res.data.message);
    } catch (e) {
      setDisable(false);
      toastMessage("warning", e.message);
    }
  };

  const handleStatusUpdate = async (msg = null) => {
    if (statusModal.option == "Approve") {
      await onApprove(msg);
      await getTimeOffRequests();
    } else if (statusModal.option == "Decline") {
      await onDecline(msg);
      await getTimeOffRequests();
    }
  };

  const handleSetSelectedData = (record) => {
    setSelectedData({
      key: record?.key,
      name: record?.employee?.name,
      description: record?.comment,
      image: record?.employee?.avatar,
      start_date: record?.created_at
        ? moment(record?.created_at).format("MMM DD, yyyy")
        : "-",
      start_time: record?.created_at
        ? moment(record?.created_at).format("hh:mm a")
        : "-",
    });
  };

  const handleDelete = async () => {
    try {
      setDisable(true);

      const res = await DeleteSingleRequest(selectedData?.key);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res.data.message);
      setDisable(false);
      getTimeOffRequests();
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };
  const getStatistics = async () => {
    let auth = GetAuthUserLocalStorage();
    const params = {
      salon_id: auth?.salon_id,
    };
    const res = await GetStatistics(params);
    const data = res.data.data;
    dispatch(
      setStatistics({
        totalEventsCount: data?.eventCount,
        totalMembersCount: data?.membersCount,
        totalGoalsCount: data?.goalCount,
        totalTasksCount: data?.taskCount,
        accountCount: data?.accountCount,
        openTickets: data?.openTickets,
        requestsCount: data?.requestsCount,
        invitesCount: data?.invitesCount,
      }),
    );
  };

  const getTimeOffRequests = async () => {
    getStatistics();

    try {
      const params = {
        is_paginated: 0,
        type: user_requests.timeoff,
        salon_id: currentSalonId,
        // "per-page": pagination.timeOff.perPage,
        // page: pagination.timeOff.currentPage,
        "order-column": "created_at",
      };
      if (filters.location) {
        params.location_id = filters.location;
      }
      if (filters.status) {
        params.status = filters.status;
      }
      dispatch(setIsTimeOffRequestLoading(true));
      const res = await GetRequests(params);
      let data = res.data.data?.map((item) => {
        return {
          key: item?.id,
          employee: {
            name: item?.user?.name || "-",
            avatar: item?.user?.image || Assets.ProfilePlaceholder,
            // avatar: <AvatarImage name={item?.user?.name} src={item?.user?.image} placeholder={Assets.ProfilePlaceholder} />,
          },
          reason: item?.subTypeName || "-",
          leave_date: item?.date
            ? moment(item?.date).format("MMM DD, yyyy")
            : "-",
          return_date: item?.return_date
            ? moment(item?.return_date).format("MMM DD, yyyy")
            : "-",
          status:
            item?.status == user_requests_status?.accepted
              ? "Approve"
              : item?.status == user_requests_status?.pending
                ? "Pending"
                : "Decline",
          created_at: item?.created_at,
          comment: item?.comments,
          type: item?.type,
          sub_type: item?.sub_type,
        };
      });
      dispatch(setTimeOffRequest({ meta: res.data.data?.meta, data }));
      dispatch(setIsTimeOffRequestLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsTimeOffRequestLoading(false));
    }
  };

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/requests/details/${record?.key}`}
              className={"black"}
            >
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              handleSetSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      width: "25%",
      sorter: (a, b) => a?.employee?.name?.localeCompare(b.employee.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <>
          <NavLink to={`/requests/details/${record?.key}`} className={"black"}>
            <Space>
              <AvatarImage
                name={record?.employee?.name}
                src={record?.employee?.avatar}
                placeholder={Assets.ProfilePlaceholder}
                size={42}
              />
              <span style={{ marginLeft: 8 }}>{record?.employee?.name}</span>
            </Space>
          </NavLink>
        </>
      ),
    },
    {
      title: "Request",
      dataIndex: "reason",
      key: "reason",
      width: "15%",
      sorter: (a, b) => a?.reason.localeCompare(b?.reason),
      sortDirections: ["descend", "ascend", "descend"],
      render: (reason) => <p>{reason}</p>,
    },
    {
      title: "Leave Date",
      dataIndex: "leave_date",
      key: "leave_date",
      width: "15%",
      sorter: (a, b) => a?.leave_date?.localeCompare(b?.leave_date),
      sortDirections: ["descend", "ascend", "descend"],
      render: (leave_date) => <p>{leave_date}</p>,
    },
    {
      title: "Return Date",
      dataIndex: "return_date",
      key: "return_date",
      width: "15%",
      sorter: (a, b) => {
        const dateA = moment(a.return_date);
        const dateB = moment(b.return_date);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (return_date) => <p>{return_date}</p>,
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "status",
      width: "20%",
      // align: "center",
      // className: "ss",
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (record) => (
        <Select
          value={record?.status}
          suffixIcon={<UpDpwnArrow />}
          menuItemSelectedIcon={<CheckMarkIcon />}
          onChange={(value) => handleChange(value, record)}
          // open={true}
          popupClassName="statusDropdown main-select me-3"
          className="custom-select-box statusDropdown req-detail"
          options={menuOption.map((item) => ({
            label: (
              <>
                <div className="d-flex align-items-center">
                  <span className={`statusBall ${item.name}`}></span>{" "}
                  {item.value}
                </div>
              </>
            ),
            value: item.name,
            disabled: !!(
              (record?.status == "Approve" && item.value != "Approved") ||
              (record?.status == "Decline" && item.value != "Declined")
            ),
          }))}
        ></Select>
      ),
    },
    {
      title: "More",
      key: "action",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(
        setPagination({
          ...pagination,
          timeOff: {
            currentPage: 1,
            perPage: 10,
            isPaginate: 1,
          },
        }),
      );
    } else {
      dispatch(
        setPagination({
          ...pagination,
          timeOff: {
            currentPage: page,
            perPage,
            isPaginate: 1,
          },
        }),
      );
    }
  };

  useEffect(() => {
    getTimeOffRequests();
  }, [pagination.timeOff, filters, currentSalonId]);

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <div className="noSearchData">
            <NoDataIcon />
            <h5>Requests sent by staff will appear here.</h5>
            <h6>Staff can submit requests from the app.</h6>
          </div>
        )}
      >
        <Table
          // locale={{
          //   emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
          // }}
          store={{ store: "requests", param: "timeOffRequest" }}
          loading={tableLoader(isTimeOffRequestLoading)}
          columns={columns}
          dataSource={timeOffRequest?.data}
          bordered={false}
          // pagination={{
          //   onChange: handlePaginationChange,
          //   pageSize: pagination.timeOff.perPage,
          //   current: pagination.timeOff.currentPage,
          //   total: timeOffRequest?.meta?.total || 0,
          //   pageSizeOptions: [10, 20, 50, 100, "All"],
          //   showSizeChanger: false,
          // }}
          pagination={false}
          scroll={{ x: "max-content", y: tableHeight?.height }}
          responsive={true}
          size={"large"}
          showSorterTooltip={false}
          className="requestTable"
        />
      </ConfigProvider>

      {/* Share Modal */}
      <ShareModal
        shareLink={`${SHARE_URL.REQUESTS}${selectedData?.key}`}
        data={selectedData}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Request"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this request with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        disable={disable}
        handleDelete={handleDelete}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Request?"
        para={
          <>
            Are you sure you want to delete this <b>Member's Request?</b> <br />
            Important: This request cannot be recovered.
          </>
        }
        notificationMessage="Request"
      />
      {/* Status Modal */}
      <StatusModal
        show={statusModal.showModal}
        size={"lg"}
        onHide={() => setStatusModal({ ...statusModal, showModal: false })}
        modalTitle={
          statusModal.option == "Approve"
            ? "Approve Request?"
            : "Decline Request?"
        }
        modalSubtitle={
          <>
            {statusModal.option == "Approve" ? "Approve" : "Decline"} the{" "}
            <b>{selectedData?.reason || "-"}</b> time requested by{" "}
            <b>{selectedData?.employee?.name || "-"}</b>?
          </>
        }
        modalButtonClass={
          statusModal.option == "Approve"
            ? "solid-approve-green-btn"
            : "solid-red-sm-btn"
        }
        modalButtonText={
          statusModal.option == "Approve" ? "Approve" : "Decline"
        }
        onStatusUpdate={handleStatusUpdate}
        disable={disable}
      />
    </>
  );
};

export default TimeOff;
