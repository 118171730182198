import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToastCrossIcon } from "../../../../constants/svg";
import { UpdateFileUploadStorageSizeAvailability } from "../../../../services/global/global";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import {
  CreateResource,
  GetSalonResourceById,
  UpdateResource,
} from "../../../../services/resources/resources";
import {
  setCategoryListByModule,
  setCreateUpdateTitleModuleID,
} from "../../../../store/slices/globalSlice";
import {
  setDisableActionBtn,
  setFirstStepTabNo,
  setRenderTable,
  setReRenderResourceDetailPage,
  setResourceAddEditForm,
  setSelectedData,
} from "../../../../store/slices/resourcesSlice";
import {
  all_category_type,
  roles as salonRoles,
  salon_resources_type,
  search_module_type,
  segment_type,
} from "../../../../utils/constants";
import {
  salonUploadToS3,
  toastMessage,
  validURL,
} from "../../../../utils/helper";
import FirstStep from "./StepsForm/FirstStep";
import SecondStep from "./StepsForm/SecondStep";
import ThirdStep from "./StepsForm/ThirdStep";
import ZeroStep from "./StepsForm/ZeroStep";

const ResourcesModalSteps = (props) => {
  const dispatch = useDispatch();
  const {
    renderTable,
    selectedData,
    disableActionBtn,
    resourceAddEditForm,
    reRenderResourceDetailPage,
  } = useSelector((state) => state.resources);
  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );
  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  ); // initial the step where you want to start

  const [firstStepDisabled, setFirstStepDisabled] = useState(true);
  const [secondStepDisabled, setSecondStepDisabled] = useState(true);
  const [thirdStepDisabled, setThirdStepDisabled] = useState(true);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [segment, setSegment] = useState(null);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);

  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      uploadPDF: "",
      uploadVideos: "",
      uploadAudio: "",
      resourceTitle: "",
      category: "",
      tags: "",
      description: "",
      uploadFromLocal: "",
      uploadFromGallery: "",
      uploadFromFolder: "",
      previewThirdStepAttachment: "",
      previewFirstStepAttachment: "",
      switchValue: "",
    },
  });

  const watchingFirstFormInputs = watch([
    "uploadPDF",
    "uploadVideos",
    "uploadAudio",
    "previewFirstStepAttachment",
  ]);

  const watchingSecondFormInputs = watch(["resourceTitle", "category", "tags"]);
  const watchingThirdFormInputs = watch([
    "uploadFromLocal",
    "uploadFromGallery",
    "uploadFromFolder",
    "previewThirdStepAttachment",
  ]);

  const onSubmit = async (inputFields) => {
    try {
      dispatch(setDisableActionBtn(true));

      const payload = {
        name: inputFields.resourceTitle,
        type:
          inputFields.uploadPDF != ""
            ? salon_resources_type.pdf
            : inputFields.uploadVideos != ""
              ? salon_resources_type.video
              : inputFields.uploadAudio != ""
                ? salon_resources_type.audio
                : selectedData
                  ? selectedData.type
                  : "",
        description: inputFields.description,
        category_id: inputFields.category,
        salon_id: currentSalonId,
        status: 10,
        link_type: inputFields.switchValue ? 20 : 10, // Need Switch Value Here
        tags: inputFields.tags,
        softwares,
        technologies,
      };
      let salonUploadFirstFormAttachmentS3Response;

      const firstFormAttachment = inputFields.uploadPDF
        ? inputFields.uploadPDF
        : inputFields.uploadVideos
          ? inputFields.uploadVideos
          : inputFields.uploadAudio
            ? inputFields.uploadAudio
            : inputFields.previewFirstStepAttachment
              ? inputFields.previewFirstStepAttachment
              : "";

      if (typeof firstFormAttachment.originFileObj == "undefined") {
        payload.link = firstFormAttachment;
      } else {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/resources`;
        salonUploadFirstFormAttachmentS3Response = await salonUploadToS3(
          firstFormAttachment,
          folder,
        );
        if (salonUploadFirstFormAttachmentS3Response.status) {
          payload.link = salonUploadFirstFormAttachmentS3Response.data.location;
          payload.size = salonUploadFirstFormAttachmentS3Response.data.sizeD;
        } else {
          throw new Error(salonUploadFirstFormAttachmentS3Response.message);
        }
      }

      let salonThirdFormUploadAttachmentResponseS3Response;

      if (inputFields.uploadFromLocal) {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/resources`;
        salonThirdFormUploadAttachmentResponseS3Response =
          await salonUploadToS3(inputFields.uploadFromLocal, folder);
        if (salonThirdFormUploadAttachmentResponseS3Response.status) {
          payload.image =
            salonThirdFormUploadAttachmentResponseS3Response.data.location;
        } else {
          throw new Error(
            salonThirdFormUploadAttachmentResponseS3Response.message,
          );
        }
      } else {
        payload.image = inputFields.uploadFromGallery
          ? inputFields.uploadFromGallery
          : inputFields.uploadFromFolder
            ? inputFields.uploadFromFolder
            : inputFields.previewImage
              ? inputFields.previewImage
              : inputFields.previewThirdStepAttachment
                ? inputFields.previewThirdStepAttachment
                : "";
      }

      let response;
      if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
        let salonData =
          selectedSalons.length > 0
            ? selectedSalons
            : salonSegments.map((item) => {
                return item.id;
              });
        payload.salons = salonData;
        payload.products = selectedProductLines;
        payload.positions = selectedPositions;
        payload.segment = segment;
      }
      if (resourceAddEditForm.formType == "create") {
        response = await CreateResource(payload);
      } else if (resourceAddEditForm.formType == "update") {
        response = await UpdateResource(selectedData.id, payload);
      }

      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
        if (salonThirdFormUploadAttachmentResponseS3Response) {
          await UpdateFileUploadStorageSizeAvailability(
            salonThirdFormUploadAttachmentResponseS3Response.data.size,
          );
        }

        if (salonUploadFirstFormAttachmentS3Response) {
          await UpdateFileUploadStorageSizeAvailability(
            salonUploadFirstFormAttachmentS3Response.data.size,
          );
        }
      }

      dispatch(setSelectedData(null));

      dispatch(
        setResourceAddEditForm({
          show: false,
          formType: null,
        }),
      );

      dispatch(setRenderTable(renderTable + 1));
      dispatch(setReRenderResourceDetailPage(reRenderResourceDetailPage + 1));

      // let notiMsg;

      // if (resourceAddEditForm.formType == "create") {
      //   notiMsg = "Resource Created Successfully!";
      // } else if (resourceAddEditForm.formType == "update") {
      //   notiMsg = "Resource Updated Successfully!";
      // }
      toastMessage("success", message);
    } catch (e) {
      toastMessage("warning", e.message);
    } finally {
      dispatch(setDisableActionBtn(false));
    }
  };

  // Navigation of buttons
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    const isValid = watchingFirstFormInputs.some((elem) => elem != "");
    if (isValid) {
      let isURLValidation = true;
      for (let elem of watchingFirstFormInputs) {
        if (
          elem != "" &&
          typeof elem.originFileObj == "undefined" &&
          !validURL(elem)
        ) {
          isURLValidation = false;
          break;
        }
      }
      setFirstStepDisabled(!isURLValidation);
    } else {
      setFirstStepDisabled(!isValid);
    }
  }, [watchingFirstFormInputs]);

  useEffect(() => {
    const isValid = watchingSecondFormInputs.every((elem) => elem != "");
    setSecondStepDisabled(!isValid);
  }, [watchingSecondFormInputs]);

  useEffect(() => {
    const isValid = watchingThirdFormInputs.some((elem) => elem != "");
    setThirdStepDisabled(!isValid);
  }, [watchingThirdFormInputs]);

  const getSalonResourceById = async () => {
    const response = await GetSalonResourceById(selectedData.id);
    const { data, status, message } = response.data;
    setValue("resourceTitle", data.name);
    setValue("category", data.category_id);
    if (data?.segment == segment_type.CUSTOM) {
      let salons = [];
      let products = [];
      data?.salons?.map((item) => {
        salons.push(item.id);
      });
      data?.products?.map((item) => {
        products.push(item.product);
      });
      setSelectedSalons(salons);
      setSelectedProductLines(products);
    }
    setTechnologies(data.technologies.map(({ name }) => name) ?? []);
    setSoftwares(data.software.map(({ name }) => name) ?? []);
    setSegment(data?.segment || null);
    setValue(
      "tags",
      data.tags.map((elem) => elem.id),
    );
    setValue("description", data.description);
    setValue("previewFirstStepAttachment", data.link);
    setValue("previewThirdStepAttachment", data.image);
    setValue("switchValue", data.link_type == 20);
    if (data.type_name == "PDF") {
      dispatch(setFirstStepTabNo(1));
    } else if (data.type_name == "VIDEO") {
      dispatch(setFirstStepTabNo(2));
    } else if (data.type_name == "AUDIO") {
      dispatch(setFirstStepTabNo(3));
    }
  };

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.RFQ));

    if (resourceAddEditForm.formType == "update") {
      getSalonResourceById();
    }
  }, [resourceAddEditForm]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.resources));
  }, []);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={
            step == 0
              ? "modal-body"
              : `modal-body tabs-modal ${props.customModalBody}`
          }
        >
          <div className={step == 0 ? "" : "d-none"}>
            <ZeroStep
              setZeroStepDisabled={setZeroStepDisabled}
              customClass="noBorders"
              selectedSalons={selectedSalons}
              setSelectedSalons={setSelectedSalons}
              setSelectedProductLines={setSelectedProductLines}
              selectedProductLines={selectedProductLines}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              showPositions={false}
              segment={segment}
              setSegment={setSegment}
              softwares={softwares}
              setSoftwares={setSoftwares}
              technologies={technologies}
              setTechnologies={setTechnologies}
            />
          </div>
          <div className={step == 1 ? "" : "d-none"}>
            <FirstStep
              register={register}
              watch={watch}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              resetField={resetField}
              customClass="noBorders"
            />
          </div>
          <div className={step == 2 ? "" : "d-none"}>
            <SecondStep
              register={register}
              watch={watch}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              title="Resource tags"
            />
          </div>
          <div className={step == 3 ? "" : "d-none"}>
            <ThirdStep
              register={register}
              watch={watch}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              submitted={disableActionBtn}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={nextStep}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={firstStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={firstStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            ) : null}
            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={secondStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {step == 3 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {!disableActionBtn && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={thirdStepDisabled}
                    >
                      {resourceAddEditForm.formType == "update"
                        ? "Update Resource"
                        : "Publish Resource"}
                    </Button>
                  )}
                  {disableActionBtn && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={true}
                    >
                      <div className="load black" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default ResourcesModalSteps;
