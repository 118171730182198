import { Popover } from "antd";
import ResponsesToolTip from "./ResponsesToolTip";
import { useState } from "react";

const QuestionResponseUsersListPopup = ({
  answeredUserList,
  answerResponsePercentage,
  questionOptionID,
  detailPageType,
  questionType,
}) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [open, setOpen] = useState([]);

  const onHide = () => {
    setOpen(null);
  };

  const handleOpenChange = (surveyQuestionOptionsId, bool) => {
    if (!answeredUserList.length) return;
    if (bool) {
      setOpen(surveyQuestionOptionsId);
    } else {
      setOpen(null);
    }
  };

  return (
    <div className="position-relative survey-details">
      <p
        className="ms-2 customToolTip d-flex"
        onClick={() => {
          setOpenToolTip(true);
          setIsApply(true);
        }}
      >
        {detailPageType == "survey" ? (
          <span className={`ms-1 customToolTip show-percent text-dark`}>
            {answerResponsePercentage.toFixed(0)}%
          </span>
        ) : (
          ""
        )}
        <Popover
          onOpenChange={(bool) => {
            handleOpenChange(questionOptionID, bool);
          }}
          open={open == questionOptionID}
          placement="rightTop"
          overlayClassName="custom-popover-class"
          content={
            <ResponsesToolTip
              hide={onHide}
              toolTipData={answeredUserList.map((elem) => {
                return {
                  name: elem?.user?.name,
                  avatar: elem?.user?.image,
                };
              })}
            />
          }
          trigger="click"
        >
          <div className="percent-views blue-accent cursor-pointer">
            <span className="text-black">
              {detailPageType != "survey" ? answeredUserList.length : ""}
            </span>
            View
          </div>
        </Popover>
      </p>
    </div>
  );
};

export default QuestionResponseUsersListPopup;
