import api from "../index";
import { apiUrl } from "../../utils/constants";

export const Register = (data) => api.post(apiUrl.register, data);
export const Login = (data) => api.post(apiUrl.login, data);
export const ForgetPassword = (data) => api.post(apiUrl.forgot_password, data);
export const ResendOtp = (data) => api.post(apiUrl.resend_otp, data);
export const VerifyOtp = (data) => api.post(apiUrl.verify_otp, data);
export const ResetPasswords = (data) => api.post(apiUrl.reset_password, data);
export const ChangePassword = (data) => api.post(apiUrl.change_password, data);
export const GetMyProfile = (data) => api.get(apiUrl.me, data);
export const Logout = (data) => api.post(apiUrl.logout_user);
export const CheckRegistrationValidation = (data) =>
  api.post(apiUrl.checkUserValidation, data);
