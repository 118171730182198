import React from "react";
import CustomUploader from "./CustomUploader";
import FileUploadField from "./FileUploadField";

const FileUploader = ({ limitMegabyteSize, ...restProps }) => {
  return (
    <CustomUploader {...restProps}>
      <FileUploadField limitMegabyteSize={limitMegabyteSize} />
    </CustomUploader>
  );
};

export default FileUploader;
