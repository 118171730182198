import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  AddIcon,
  BinocularsLight,
  EditIcon,
  MoreIconGrey,
  NewSparkleIcon,
  RecycleBin,
} from "../../constants/svg";
import { Avatar, Dropdown } from "antd";
import { Assets } from "../../constants/images";

import GenericModal from "../../components/Modals/GenericModal";
import AddNewLocationModal from "../../components/Modals/AddNewLocationModal";
import DeleteMemberModal from "../../components/Modals/DeleteMemberModal";
import { useDispatch, useSelector } from "react-redux";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  deleteLocation,
  getLocationsWithoutPagination,
} from "../../services/locations/frontend-locations";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import AvatarImage from "../../components/Common/AvatarImage";

const LocationPage = () => {
  const dispatch = useDispatch();
  const authUser = GetAuthUserLocalStorage();

  const { currentSalonId } = useSelector((state) => state.global);
  const { isLocationsLoading, locations, isReloadLocation } = useSelector(
    (state) => state?.locations,
  );
  const [selectedData, setSelectedData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [isUpdateLocation, setIsUpdateLocation] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/settings/locations/${record?.slug ?? record?.id}`}
              className={"black"}
            >
              <BinocularsLight />
              View Location
            </NavLink>
          </>
        ),
      },
      {
        key: "2",
        label: (
          <>
            <NavLink
              to={"/"}
              className={"black item"}
              onClick={(e) => {
                e.preventDefault();
                setSelectedData(record);
                setShowGenericModal(true);
                setIsUpdateLocation(true);
              }}
            >
              <EditIcon />
              Edit Location
            </NavLink>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              setSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const handleDelete = async () => {
    await deleteLocation(selectedData?.id, setDisable, showModal, setShowModal);
    fetchLocations();
  };

  const fetchLocations = async () => {
    const params = {
      is_paginated: 0,
      salon_id: currentSalonId,
    };
    await getLocationsWithoutPagination(params, dispatch);
  };

  useEffect(() => {
    fetchLocations();
  }, [isReloadLocation, currentSalonId]);

  return (
    <>
      <section className={"billingpagewrapper"}>
        <div className="teamTabMainHead">
          <h2 className="sec-heading">Locations</h2>
          <button
            className="btn-gradient"
            onClick={() => {
              setIsUpdateLocation(false);
              setSelectedData(null);
              setShowGenericModal(true);
            }}
          >
            <span>
              <AddIcon />
            </span>
            Add Location
          </button>
        </div>
        <div className={"billingheader"}>
          <Row>
            <Col lg={12} md={12} className={"pe-0"}>
              <p className="border-line">
                You can add locations and assign members for quick referencing
                &nbsp;
                <a
                  href={
                    "https://support.mysalonsymphony.com/hc/en-us/articles/19757789390619"
                  }
                  target="_blank"
                  className={"uLine"}
                  rel="noreferrer"
                >
                  Learn more.
                </a>
              </p>
            </Col>
          </Row>

          <div className="teamBody profileBodyWrapper">
            {isLocationsLoading ? (
              <LoadingScreen child={true} />
            ) : (
              <Row>
                {locations?.data?.length <= 0 ? (
                  <span>Check back soon!</span>
                ) : (
                  locations?.data?.map((item, index) => (
                    <Col lg={4} key={index}>
                      <div className="main-group-card">
                        <div className="groupCardDropdown">
                          <Dropdown
                            menu={{
                              items: actionMenu(item),
                            }}
                            placement="bottomRight"
                            trigger={["click"]}
                            overlayClassName={
                              "defaultDropdown balckedDropdown newdrop"
                            }
                          >
                            <div className="icon cursor-pointer">
                              <MoreIconGrey />
                            </div>
                          </Dropdown>
                        </div>
                        <NavLink
                          to={`/settings/locations/${item?.slug ?? item?.id}`}
                        >
                          <div className="groupCard">
                            <div className="roundIcon">
                              {/* <UserSettingIcon /> */}
                              <img
                                src={item?.image || Assets.GeneralPlaceholder}
                                onError={(e) =>
                                  (e.target.src = Assets.GeneralPlaceholder)
                                }
                                alt=""
                              />
                              {/* <LocationPurpleIcon /> */}
                            </div>
                            <h6 className="title black">{item?.name || "-"}</h6>
                            <p className="subTitle">{item?.meta?.user_count}</p>
                            {/* <Avatar.Group>
                                  {
                                    item?.users?.slice(0, 7)?.map((user, i) => (
                                      <Avatar key={i} size={25} src={user?.image || Assets.ProfilePlaceholder} />
                                    ))
                                  }
                                </Avatar.Group> */}
                            <Avatar.Group>
                              {item?.users?.slice(0, 5)?.map((user, i) => (
                                <AvatarImage
                                  name={user?.name}
                                  src={user?.image}
                                  placeholder={Assets.placeHolderIcon}
                                  size={25}
                                  key={i}
                                />
                              ))}
                              {item?.users?.length > 5 && (
                                <Avatar
                                  size={25}
                                  style={{
                                    backgroundColor: "#f0f0f0",
                                    color: "#000",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  +{item?.users?.length - 5}
                                </Avatar>
                              )}
                            </Avatar.Group>
                          </div>
                        </NavLink>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
            )}
          </div>
        </div>
      </section>
      {/* Delete Modal */}
      <DeleteMemberModal
        disable={disable}
        handleDelete={handleDelete}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        member={{ name: "location" }}
        isLocation={true}
      />
      <GenericModal
        show={showGenericModal}
        onHide={() => {
          setIsUpdateLocation(false);
          setShowGenericModal(false);
        }}
        setModalShow={setShowGenericModal}
        modalTitle={isUpdateLocation ? "Update Location" : "Add New Location"}
        desc={true}
        modalDesc={
          isUpdateLocation
            ? ""
            : "Pro Tip: After creating your location, you can assign members to its group."
        }
        size={"lg"}
        bodyComponent={
          <AddNewLocationModal
            setModalShow={setShowGenericModal}
            selectedData={selectedData}
            isUpdateLocation={isUpdateLocation}
          />
        }
      />
    </>
  );
};

export default LocationPage;
