import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Assets } from "../../../constants/images";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { GetBadges, GiveBadge } from "../../../services/team/rewards";
import {
  setBadges,
  setIsBadgesLoading,
} from "../../../store/slices/rewardSlice";
import CustomInput from "../../FormFields/CustomInput";
import CustomTreeSelect from "../../FormFields/CustomTreeSelect";

const CreateNewReward = ({ setShowGenericModal }) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });

  const [assignees, selectedCategory, badge] = watch([
    "assignees",
    "selectedCategory",
    "badge",
  ]);
  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const { isBadgesLoading, badges } = useSelector((state) => state?.reward);
  const { currentSalonId } = useSelector((state) => state.global);

  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);

  const returnUsers = (category) => {
    if (category == "everyone") {
      return assignees;
    } else if (category == "group") {
      let groups = salonTeams?.data?.groups?.filter((item) =>
        assignees?.includes(item?.id?.toString()),
      );
      let users = [];
      for (let i = 0; i < groups?.length; i++) {
        for (let j = 0; j < groups[i]?.users?.length; j++) {
          users.push(groups[i].users[j]?.id?.toString());
        }
      }

      return users;
    } else if (category == "member") {
      return assignees;
    }
  };

  const handleTreeSelectValue = (parentNode, value) => {
    setValue("assignees", value);
    setValue("selectedCategory", parentNode);
  };

  const getBadges = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: 10,
        type: "user-badges",
      };
      dispatch(setIsBadgesLoading(true));
      const res = await GetBadges(params);
      dispatch(setBadges({ data: res?.data?.data, meta: null }));
      dispatch(setIsBadgesLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      setIsBadgesLoading(false);
    }
  };

  const handleDisableSubmit = () => {
    if (disable || !isValid || isSubmitting || assignees?.length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const resetForm = () => {
    reset({
      name: "",
      badge: null,
      date: null,
      assignees: [],
    });
  };

  const onSubmit = async (data) => {
    if (getValues("assignees")) {
      try {
        const apiData = {
          name: data?.name,
          badge: data?.badge,
          date: moment().format("YYYY-MM-DD"),
          user_id: authUser?.id,
          salon_id: currentSalonId,
          assignees: returnUsers(selectedCategory),
        };
        setDisable(true);
        const res = await GiveBadge(apiData);
        setShowGenericModal(false);
        notification.info({
          message: <span>{res.data.message}</span>,
          placement: "bottomRight",
          className: "createdNotification",
          icon: <ToastGreenCheckIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        resetForm();
        setDisable(false);
      } catch (e) {
        notification.error({
          message: <span>{e.message}</span>,
          placement: "bottomRight",
          className: "createdNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        setDisable(false);
      }
    }
  };

  useEffect(() => {
    getBadges();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body tabs-modal">
          <Row>
            <p className="badge-p">
              1. Give this acheivement a title and select the recipient.
            </p>
            <Col lg={8}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: "Required",
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Achievement"
                    placeholder="Highest Retail Sales"
                    value={field?.value}
                    onChange={(e) => field?.onChange(e)}
                  />
                )}
              />
            </Col>

            <Col lg={4}>
              <CustomTreeSelect
                isedit={false}
                label="Recipients"
                placeholder="Recipients"
                defaultSelectedData={[]}
                handleChange={handleTreeSelectValue}
                selectedCategory={getValues("selectedCategory") || null}
              />
            </Col>
          </Row>

          <div className="main-badges">
            <p className="badge-p">
              2. Choose a badge that represents this achievement.
            </p>
            <div className="badge-div">
              {isBadgesLoading ? (
                <div className="load black" />
              ) : (
                badges?.data?.map((item, index) => (
                  <label htmlFor={item?.id?.toString()} key={index}>
                    <input
                      id={item?.id?.toString()}
                      className="d-none"
                      type="radio"
                      value={item?.value}
                      {...register("badge", {
                        required: {
                          value: "Required",
                        },
                      })}
                    />
                    <div
                      className={`single-badge ${item?.value == badge ? "selected" : ""}`}
                    >
                      <div className="badgeDiv">
                        <img
                          className="w-100 h-100"
                          style={{ objectFit: "cover" }}
                          src={item?.value || Assets?.GeneralPlaceholder}
                          onError={(e) =>
                            (e.target.src = Assets?.GeneralPlaceholder)
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </label>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Row className="w-100">
            <Col lg={12}>
              <Button
                disabled={handleDisableSubmit()}
                type="submit"
                className="w-100"
              >
                {disable ? <div className="load black" /> : "Give Badge"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CreateNewReward;
