import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Assets } from "../../constants/images";
import UserProfileModal from "../pages/Chat/Modal/UserProfileModal";
import UserListModal from "../pages/Chat/Modal/UserListModal";
import AvatarImage from "./AvatarImage";
import { truncate } from "lodash";
import EveryOneImage from "../../assets/images/everyone.svg";

const ChatProfileHover = ({
  user,
  userPosition,
  onItemClick,
  handleCloseModal,
  activeUser,
  users,
  handleSend,
  username,
  avatar,
  showPlusSign,
}) => {
  const navigate = useNavigate();

  const name = user?.is_group_chat
    ? user?.name
    : user?.receiver_profile[0]?.name;
  const src = user?.is_group_chat
    ? EveryOneImage
    : user?.receiver_profile[0]?.image;
  const userName = truncate(
    user?.is_group_chat ? user?.name : user?.receiver_profile[0]?.name,
    { length: 18 },
  );
  const userTitle =
    !user?.is_group_chat && userPosition.length > 0
      ? userPosition[0]?.label
      : "";
  return (
    <>
      <div className="profile-hover">
        <div className="avatar-overlay-content">
          <div className="avatar-icon">
            <AvatarImage
              name={name}
              src={src}
              placeholder={Assets.ProfilePlaceholder}
              size={48}
            />
          </div>
          <h4 className="userName">{userName}</h4>
          <p className="userTitle m-0">{userTitle}</p>
          <div className="avatar-buttons">
            <Button
              type="primary"
              className={"view-profile border-grey-log-btn"}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  user?.is_group_chat
                    ? `/team/groups/${user?.group_id}`
                    : `/team/members/${user?.receiver_profile[0]?.id}`,
                );
              }}
              disabled={
                user.is_user_group || user?.receiver_profile?.[0]?.is_deleted
              }
            >
              View Profile
            </Button>
            {/* Open Modal on click on this */}
            <Button
              type="primary"
              className={"messageBtn"}
              onClick={() => {
                onItemClick(user);
              }}
            >
              Message
            </Button>
            <UserProfileModal
              show={activeUser == user}
              onHide={handleCloseModal}
              setModalShow={handleCloseModal}
              modalTitle={"Direct Message"}
              size={"lg"}
              className="userListModal"
              bodyComponent={
                <UserListModal
                  users={users}
                  handleSend={handleSend}
                  username={username}
                  userAvatar={avatar}
                  onCloseModal={handleCloseModal}
                  showPlusSign={showPlusSign}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatProfileHover;
