import { ConfigProvider, Empty, notification, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Assets } from "../../../constants/images";
import { NoDataIcon, ToastCrossIcon } from "../../../constants/svg";
import {
  roles as salonRoles,
  SHARE_URL,
  tableHeight,
} from "../../../utils/constants";
import { NavLink } from "react-router-dom";
import { DeleteSurvey, GetServey } from "../../../services/survey/survey";
import {
  setActionButtonDisable,
  setIsPaginate,
  setLoading,
  setPage,
  setPerPage,
  setReRenderTable,
  setSelectedData,
  setSorting,
  setSurvey,
  setSurveyAddEditForm,
  setSurveyTableData,
} from "../../../store/slices/surveySlice";
import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import { getColumns } from "./TableColumn";
import { tableLoader, toastMessage } from "../../../utils/helper";
import LazyLoadTable from "../../Table/LazyLoadTable";

const SurveyBody = () => {
  const dispatch = useDispatch();
  const {
    loading,
    survey,
    page,
    perPage,
    isPaginate,
    surveyTableData,
    reRenderTable,
    actionButtonDisable,
    surveyAddEditForm,
    sorting,
    filters,
    selectedData,
  } = useSelector((state) => state.survey);
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const [surveyIDForDelete, setSurveyIDForDelete] = useState(null);
  const [surveyShareData, setSurveyShareData] = useState(null);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const handleOpenEditForm = (record) => {
    dispatch(setSelectedData(record));
    dispatch(
      setSurveyAddEditForm({
        show: true,
        formType: "update",
      }),
    );
  };

  const onInitCompGetSurveyList = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];
    try {
      dispatch(setLoading(true));
      const params = {
        // page,
        is_paginated: 0,
        // "per-page": perPage,
        // "order-column": "created_at",
        // order: sorting.sortBy,
        salon_id: currentSalonId,
        technologies,
        softwares,
        products,
      };
      if (filters.category_id) {
        params.category_id = filters.category_id;
      }
      const resp = await GetServey(params);
      const { data, status, message } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      // const surveyRecords = isPaginate ? data.data : data;
      const surveyRecords = data;

      const surveyDataTableModfied = surveyRecords.map((elem) => {
        return {
          key: elem.uniid,
          id: elem.id,
          uniid: elem.uniid,
          title: elem.name,
          name: elem.name,
          image: elem.image,
          description: elem.description,
          created_at: elem.created_at,
          lock: elem?.user?.roles?.[0]?.id == salonRoles.super_admin,
          category: elem.category.name,
          notStarted: {
            count: elem.notStartedUsers.length,
            attendents: elem.notStartedUsers.map((elem) => elem?.user?.name),
          },
          submitted: {
            count: elem.submittedUsers.length,
            attendents: elem.submittedUsers.map((elem) => elem?.user?.name),
          },
          user: elem?.user || null,
        };
      });

      dispatch(setSurveyTableData(surveyDataTableModfied));
      dispatch(setSurvey(data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onDeleteSurvey = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const resp = await DeleteSurvey(surveyIDForDelete);
      const { data, message, status } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setSurveyIDForDelete(null);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handleSetSelectedData = (record) => {
    dispatch(setSelectedData(record));
  };

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(setIsPaginate(0));
      dispatch(setPage(0));
      dispatch(setPerPage(0));
    } else {
      dispatch(setPage(page));
      dispatch(setPerPage(perPage));
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    let column = "";

    switch (sorter.field) {
      case "title":
        column = "name";
        break;

      case "category":
        column = "category_id";
        break;

      default:
        column = sorter.field;
        break;
    }

    dispatch(
      setSorting({
        column,
        sortBy: sorter.order,
      }),
    );
  };

  useEffect(() => {
    onInitCompGetSurveyList();
  }, [
    page,
    perPage,
    reRenderTable,
    sorting,
    filters,
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  return (
    <>
      <div className="content">
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Surveys you've created will appear here.</h5>
              <h6>Click "Create Survey" to get started.</h6>
            </div>
          )}
        >
          <Table
            // locale={{
            //   emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
            // }}
            columns={getColumns(
              setShowModal,
              setSurveyIDForDelete,
              handleOpenEditForm,
              setSurveyShareData,
              handleSetSelectedData,
            )}
            dataSource={surveyTableData}
            bordered={false}
            loading={tableLoader(loading)}
            // pagination={{
            //   onChange: handlePaginationChange,
            //   pageSize: !isPaginate ? surveyTableData.length : perPage,
            //   showSizeChanger: false,
            //   current: !isPaginate ? 1 : page,
            //   total: !isPaginate ? surveyTableData.length : survey?.meta?.total,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            // }}
            pagination={false}
            store={{ store: "survey", param: "surveyTableData" }}
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="flashCardTable imageTable"
            rowKey={(record) => record.key}
          />
        </ConfigProvider>
      </div>

      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Survey"}
        shareLink={SHARE_URL.SURVEYS + surveyShareData?.id}
        data={{
          image: surveyShareData?.image,
          name: surveyShareData?.name,
          description: surveyShareData?.description,
          start_date: surveyShareData
            ? moment(surveyShareData.created_at).format("MMM DD, yyyy")
            : "",
          start_time: surveyShareData
            ? moment(surveyShareData.created_at).format("hh:mm a")
            : "",
        }}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this survey with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Class Followup Survey",
          para1:
            "This is event dummy message will replace with the. This is event dummy message will replace with the. This is event dummy message will replace with the...",
          para2: "8 Questions • Oct 04, 2023",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        handleDelete={onDeleteSurvey}
        show={showModal.deleteModal}
        disable={actionButtonDisable}
        onHide={() => {
          setShowModal({ ...showModal, deleteModal: false });
          setSurveyIDForDelete(null);
        }}
        heading="Delete Survey?"
        para={
          <>
            Are you sure you want to delete{" "}
            <b> {selectedData?.title || "this survey"} ?</b>
            <br />
            Important: This survey cannot be recovered.
          </>
        }
        notificationMessage="Survey"
      />
    </>
  );
};

export default SurveyBody;
