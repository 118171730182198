import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetRequests = (params) =>
  api.get(apiUrl.user_requests, { params });
export const GetSingleRequest = (id) =>
  api.get(`${apiUrl.user_requests}/${id}`);
export const DeleteSingleRequest = (id) =>
  api.delete(`${apiUrl.user_requests}/${id}`);
export const UpdateRequest = (id, data) =>
  api.put(`${apiUrl.user_requests}/${id}`, data);
