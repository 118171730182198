import React, { useRef, useState } from "react";
import {
  Eye,
  EyeHide,
  SquareLeftArrow,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Patterns } from "../../constants/contants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import { ResetPasswords } from "../../services/auth/auth";

function ResetPassword(props) {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const location = useLocation();

  const passwordWatch = useRef({});
  passwordWatch.current = watch("password", "");

  const onSubmit = async (data) => {
    try {
      setDisable(true);
      const apiData = {
        email: location?.state?.data?.email,
        password: data?.password,
        password_confirmation: data?.password_confirmation,
        otp_code: parseInt(location?.state?.data?.otp_code),
      };
      const res = await ResetPasswords(apiData);
      setDisable(false);
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });

      setTimeout(() => {
        navigate("/");
      }, 1200);
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };

  const goBackFunc = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="authBox">
        <div className="goBackBtn" onClick={goBackFunc}>
          <SquareLeftArrow />
        </div>
        <div className="authHeaderText">
          <h1>New Password</h1>
          <p>Set a memorable password different from your last.</p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="password"
              label="Create Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={passwordShow ? "text" : "password"}
                placeholder="xxxxxx"
                aria-describedby="passToggle"
                className={errors.password ? "hasError" : ""}
                minLength={6}
                maxLength={16}
                autoComplete="off"
                role={"presentation"}
                {...register("password", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                {passwordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="confirm"
              label="Confirm Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={cPasswordShow ? "text" : "password"}
                placeholder="••••••••"
                aria-describedby="passToggle"
                minLength={6}
                maxLength={16}
                className={errors.password_confirmation ? "hasError" : ""}
                autoComplete="off"
                role={"presentation"}
                {...register("password_confirmation", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  validate: (value) =>
                    value == passwordWatch.current ||
                    "The passwords do not match",
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setCPasswordShow(!cPasswordShow)}
              >
                {cPasswordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Button
            disabled={disable}
            className="mb-0 w-100"
            size={"lg"}
            type={"submit"}
          >
            {disable ? <div className="load black" /> : "Reset Password"}
          </Button>
        </Form>
        <div className="authBoxText">
          <NavLink to={"/forgot-password"} className={"blue-accent uLine"}>
            Forget Password?
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
