import React from "react";
import { CrossThin } from "../../constants/svg";
import { Assets } from "../../constants/images";
import AvatarImage from "./AvatarImage";

const ResponsesToolTip = ({ hide, toolTipData, greenBG, redBG }) => {
  // const handleClose = () => {
  //   setIsApply(false)
  //   setIsApplyCorrect(false)
  //   closeToolTip({ open: false, id: null });
  //   setIsOpen(false)
  // };

  return (
    <>
      <div className="customToolTipBox">
        {/* <div className="triangleToolTip">
          <ToolTipTriangle />
        </div> */}
        <div
          className={
            greenBG
              ? "header d-flex align-items-center justify-content-between greenheader"
              : redBG
                ? "header d-flex align-items-center justify-content-between redheader"
                : "header blueheader d-flex align-items-center justify-content-between"
          }
        >
          <h5 className="white m-0 p-0">Responses</h5>
          <div className="icon cursor-pointer" onClick={hide}>
            <CrossThin />
          </div>
        </div>
        <div className="body">
          <ul>
            {toolTipData.map((data, index) => {
              return (
                <li key={index}>
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                    }}
                  >
                    <AvatarImage
                      name={data?.name || "App User"}
                      src={data?.avatar}
                      placeholder={Assets.ProfilePlaceholder}
                      size={36}
                    />
                    {/* <img
                      src={
                        data?.avatar ? data?.avatar : Assets.ProfilePlaceholder
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "100px",
                        objectFit: "cover",
                      }}
                    /> */}
                  </div>
                  <p className="m-0 black">
                    {data?.name ? data?.name : "App User"}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ResponsesToolTip;
