import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  BlackLockIcon,
  BulbIcon,
  DropWhiteIcon,
  GreyLockIcon,
} from "../../../../../constants/svg";
import {
  goal_type,
  repeat_goal_monthly_array,
  repeat_goal_weekly_array,
  target_date_monthly_array,
  target_date_weekly_array,
  target_type_array,
  target_types,
} from "../../../../../utils/constants";
import CustomDatePicker from "../../../../FormFields/CustomDatePicker";
import CustomInput from "../../../../FormFields/CustomInput";
import CustomSelect from "../../../../FormFields/CustomSelect";
import { toastMessage } from "../../../../../utils/helper";

const ReturnTargetValueFields = ({
  target_type,
  control,
  getValues,
  setValue,
}) => {
  if (target_type == target_types?.dollar || !target_type) {
    return (
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            left: "16px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="black"
        >
          $
        </span>
        <Controller
          control={control}
          name="target_value"
          rules={{
            required: {
              value: "Required",
            },
          }}
          render={({ field }) => (
            <Form.Control
              {...field}
              className="no-box-shadow custom-focus-number"
              type="number"
              placeholder="0.00"
              autoComplete="off"
              style={{ paddingLeft: "28px" }}
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
            />
          )}
        />
      </div>
    );
  } else if (target_type == target_types?.percentage) {
    return (
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            right: "16px",
            left: "auto",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="black"
        >
          %
        </span>
        <Controller
          control={control}
          name="target_value"
          rules={{
            required: {
              value: "Required",
            },
          }}
          render={({ field }) => (
            <Form.Control
              {...field}
              className="no-box-shadow goal-page-black-placeholder"
              type="number"
              autoComplete="off"
              style={{ paddingLeft: "28px" }}
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
            />
          )}
        />
      </div>
    );
  } else if (target_type == target_types?.number) {
    return (
      <div style={{ position: "relative" }}>
        {/* <span
            style={{
              position: "absolute",
              left: "16px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="black"
          >
            0
          </span> */}
        <Controller
          control={control}
          name="target_value"
          rules={{
            required: {
              value: "Required",
            },
          }}
          render={({ field }) => (
            <Form.Control
              {...field}
              className="no-box-shadow"
              type="number"
              placeholder="100"
              autoComplete="off"
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
            />
          )}
        />
      </div>
    );
  }
};

const ReturnFormFileds = ({
  type,
  control,
  target_date,
  duration,
  handleMouseEnterTwo,
  handleMouseEnter,
  handleMouseLeave,
  isHoveredTwo,
  isHovered,
  handleMouseLeaveTwo,
  getValues,
  setValue,
}) => {
  if (type == goal_type?.weekly) {
    return (
      <>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Target Date</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                What day should this goal be achieved by each week?
              </i>
            </p>
            <Controller
              control={control}
              name="target_date"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              defaultValue={1}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Target Date"}
                  // label={"Target Date"}
                  value={field?.value}
                  options={target_date_weekly_array(target_date)}
                  onChange={(e) => field?.onChange(e)}
                  className="noLabelSelect myCustomSelect goal-page-black-placeholder"
                  popupClassName="dateSelectMenu dollarSelectMenu"
                />
              )}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Repeat Goal</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                How long should this goal repeat into the future?
              </i>
            </p>
            <Controller
              control={control}
              name="duration"
              defaultValue={1}
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"For 1 Months"}
                  // label={"For 1 Months"}
                  value={field?.value}
                  options={repeat_goal_weekly_array(duration)}
                  onChange={(e) => field?.onChange(e)}
                  className="noLabelSelect myCustomSelect goal-page-black-placeholder"
                  popupClassName="resourceSelectMenu dollarSelectMenu for1month"
                />
              )}
            />
          </div>
        </Col>
      </>
    );
  } else if (type == goal_type?.monthly) {
    return (
      <>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Target Date</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                What day should this goal be achieved by each month?
              </i>
            </p>
            <Controller
              control={control}
              name="target_date"
              defaultValue={1}
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Target Date"}
                  // label={"Target Date"}
                  value={field?.value}
                  options={target_date_monthly_array(target_date)}
                  onChange={(e) => field?.onChange(e)}
                  className="noLabelSelect myCustomSelect goal-page-black-placeholder"
                  popupClassName="dateSelectMenu dollarSelectMenu"
                />
              )}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Repeat Goal</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                How long should this goal repeat into the future?
              </i>
            </p>
            <Controller
              control={control}
              name="duration"
              defaultValue={1}
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"For 3 Months"}
                  // label={"For 1 Months"}
                  value={field?.value}
                  options={repeat_goal_monthly_array(duration)}
                  onChange={(e) => field?.onChange(e)}
                  className="noLabelSelect myCustomSelect goal-page-black-placeholder"
                  popupClassName="resourceSelectMenu dollarSelectMenu for1month"
                />
              )}
            />
          </div>
        </Col>
      </>
    );
  } else if (type == goal_type?.annual) {
    return (
      <>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Target Date</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                Annual goals automatically end Dec 31 of the current year.
              </i>
            </p>
            <div style={{ position: "relative" }}>
              <span
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  position: "absolute",
                  right: "22px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className={`black ${isHovered ? "hovered" : ""}`}
              >
                <GreyLockIcon />
              </span>
              <Controller
                control={control}
                name="target_date"
                rules={{
                  required: {
                    value: false,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label={`December 31, ${moment().format("YYYY")}`}
                    placeholder={`December 31, ${moment().format("YYYY")}`}
                    value={field?.value}
                    onChange={(e) => field?.onChange(e)}
                    className="disabled-field goal-page-black-placeholder"
                    disabled={true}
                  />
                )}
              />
              {/* <div className="lockinput-field"> */}
              {/*  <BlackLockIcon /> */}
              {/*  <h5>Goal dates locked..</h5> */}
              {/*  <p>Annual goals will automatically start on today’s date and end on the last day of the year.</p> */}
              {/*  <span className="drop-icon"><DropWhiteIcon /></span> */}
              {/* </div> */}
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Repeat Goals</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                As a best practice, annual goals do not repeat.
              </i>
            </p>
            <div style={{ position: "relative" }}>
              <span
                onMouseEnter={handleMouseEnterTwo}
                onMouseLeave={handleMouseLeaveTwo}
                style={{
                  position: "absolute",
                  right: "22px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className={`black ${isHoveredTwo ? "hovered" : ""}`}
              >
                <GreyLockIcon />
              </span>
              <Controller
                control={control}
                name="duration"
                rules={{
                  required: {
                    value: false,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Never"
                    placeholder="Never"
                    value={field?.value}
                    onChange={(e) => field?.onChange(e)}
                    className="disabled-field goal-page-black-placeholder"
                    disabled={true}
                  />
                )}
              />
              {/* <div className="lockinput-field"> */}
              {/*  <BlackLockIcon /> */}
              {/*  <h5>Goal repeat locked.</h5> */}
              {/*  <p>As a best practice, annual goals should be set once each year to effectively measure progress.</p> */}
              {/*  <span className="drop-icon"><DropWhiteIcon /></span> */}
              {/* </div> */}
            </div>
          </div>
        </Col>
      </>
    );
  } else if (type == goal_type?.custom) {
    return (
      <>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Goal Starts</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                On what date should this goal begin?
              </i>
            </p>
            <Controller
              rules={{
                required: {
                  value: "Required",
                },
              }}
              control={control}
              name="start_date"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  placeholder="Select Date"
                  className="goal-page-black-placeholder"
                  onChange={(date, dateString) => {
                    const startDate = new Date(dateString);
                    const endDate = new Date(getValues("end_date"));
                    if (
                      getValues("end_date") &&
                      startDate.getTime() > endDate.getTime()
                    ) {
                      toastMessage(
                        "error",
                        "The start date should not precede the end date.",
                      );
                      return;
                    }
                    onChange(moment(dateString).format("YYYY-MM-DD"));
                    if (!getValues("end_date")) {
                      setValue(
                        "end_date",
                        moment(dateString).format("YYYY-MM-DD"),
                      );
                    }
                  }}
                  value={value ? dayjs(value, "YYYY-MM-DD") : ""}
                />
              )}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="goalModalWrap second-row">
            <h5 className="head black custom-mb-2">Goal Ends</h5>
            <p>
              <i className="newgray font-size-12 font-style-normal">
                On what date should this goal be achieved by?
              </i>
            </p>
            <Controller
              rules={{
                required: {
                  value: "Required",
                },
              }}
              control={control}
              name="end_date"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  placeholder="Select Date"
                  className="goal-page-black-placeholder"
                  onChange={(date, dateString) => {
                    const endDate = new Date(dateString);
                    const startDate = new Date(getValues("start_date"));
                    if (
                      getValues("start_date") &&
                      endDate.getTime() < startDate.getTime()
                    ) {
                      toastMessage(
                        "error",
                        "The end date should not precede the start date",
                      );
                      return;
                    }
                    if (!getValues("start_date")) {
                      setValue(
                        "start_date",
                        moment(dateString).format("YYYY-MM-DD"),
                      );
                    }
                    onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  value={value ? dayjs(value, "YYYY-MM-DD") : ""}
                />
              )}
            />
          </div>
        </Col>
      </>
    );
  }
};

const ThirdStep = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTwo, setIsHoveredTwo] = useState(false);
  const {
    register,
    watch,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const [target_type, type, target_date, duration] = watch([
    "target_type",
    "type",
    "target_date",
    "duration",
  ]);

  const { formData } = useSelector((state) => state?.goals);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterTwo = () => {
    setIsHoveredTwo(true);
  };
  const handleMouseLeaveTwo = () => {
    setIsHoveredTwo(false);
  };

  useEffect(() => {
    setValue("target_type", target_types?.dollar);
    if (type != goal_type?.annual) {
      setValue("target_date", 1);
    }
    if (type == goal_type?.weekly) {
      setValue("duration", 20);
    } else if (type == goal_type?.monthly) {
      setValue("duration", 30);
    }
    if (props?.reRender <= 0) {
      props?.setReRender(props?.reRender + 1);
    }
  }, [props?.reRender]);

  return (
    <Row>
      <Col lg={6}>
        <div className="goalModalWrap">
          <h5 className="head black custom-mb-2">Target Type</h5>
          <p>
            <i className="newgray font-size-12 font-style-normal">
              Is this goal a dollar amount, percentage or number?
            </i>
          </p>
          <Controller
            control={control}
            name="target_type"
            rules={{
              required: {
                value: "Required",
              },
            }}
            render={({ field }) => (
              <CustomSelect
                {...field}
                placeholder={"Dollar Amount"}
                // label={"Dollar Amount"}
                value={field?.value}
                options={target_type_array(target_type)}
                onChange={(e) => field?.onChange(e)}
                className="noLabelSelect myCustomSelect goal-page-black-placeholder"
                popupClassName="resourceSelectMenu dollarSelectMenu"
              />
            )}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="goalModalWrap">
          <h5 className="head black custom-mb-2">Target Value</h5>
          <p>
            <i className="newgray font-size-12 font-style-normal">
              What is the value of the target that must be met?
            </i>
          </p>
          <ReturnTargetValueFields
            target_type={target_type}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        </div>
      </Col>
      <ReturnFormFileds
        type={type}
        control={control}
        target_date={target_date}
        duration={duration}
        handleMouseEnterTwo={handleMouseEnterTwo}
        isHoveredTwo={isHoveredTwo}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isHovered={isHovered}
        handleMouseLeaveTwo={handleMouseLeaveTwo}
        getValues={getValues}
        setValue={setValue}
      />
      {/* <div className="para-div">
        <span>
          <BulbIcon />
        </span>
        <p><b>Example:</b> Assume you want your stylist Jenna to maintain a $13.50 RPCT every week for the next 6 months, and Jenna’s workweek ends on Saturday.
          The target type would be a Dollar Amount, the target value would be $13.50, the target date would be Every Saturday, and the goal would repeat For 6 months.</p>
      </div> */}
    </Row>
  );
};
export default ThirdStep;
