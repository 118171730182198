import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Assets } from "../../../../../constants/images";
import { setCreateUpdateModuleTitle } from "../../../../../store/slices/globalSlice";
import CustomTextArea from "../../../../FormFields/CustomTextArea";
import CustomTreeSelect from "../../../../FormFields/CustomTreeSelect";

const SecondStep = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const { formData } = useSelector((state) => state?.goals);

  const showPickerShowHide = useRef(false);
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);

  const handleTreeSelectValue = (parentNode, value) => {
    setValue("users", value);
    setValue("selectedCategory", parentNode);
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPicker]);

  function handleEmojiClick(emojiData) {
    setValue("name", getValues("name") + emojiData.emoji);
  }

  return (
    <>
      <Row>
        <Col lg={8}>
          <FloatingLabel label={"Goal Name"}>
            <Form.Control
              placeholder="ex. New Guest Retention"
              minLength={1}
              maxLength={50}
              className="custom-grey-color"
              autoComplete="off"
              {...register("name", {
                onChange: (e) => {
                  dispatch(setCreateUpdateModuleTitle(e.target.value));
                },
                required: {
                  value: "Goal name is required",
                },
                minLength: {
                  value: 1,
                },
                maxLength: {
                  value: 50,
                },
              })}
            />
            <div ref={emojiPickerRef}>
              <div
                onClick={() => {
                  setShowPicker(!showPicker);
                  showPickerShowHide.current = !showPicker;
                }}
                className={"smileyBox"}
              >
                <img src={Assets.Smile} className={"smileImage"} />
              </div>
              {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
            </div>
          </FloatingLabel>
        </Col>

        <Col lg={4}>
          <CustomTreeSelect
            isedit={props?.isedit}
            label="Assign Staff"
            placeholder="Assign Staff"
            defaultSelectedData={formData?.users || []}
            handleChange={handleTreeSelectValue}
            selectedCategory={getValues("selectedCategory") || null}
          />
        </Col>
      </Row>

      <Row>
        <p className="custom-fs-11">
          <span className="custom-fw-bold">Pro Tip:</span> You can save time by
          assigning a goal to a group of members, like all Stylists.{" "}
        </p>

        <Col lg={12}>
          <Controller
            control={control}
            name="description"
            rules={{
              required: {
                value: false,
              },
              minLength: {
                value: 0,
              },
              maxLength: {
                value: 475,
              },
            }}
            render={({ field }) => (
              <CustomTextArea
                {...field}
                label="How will this goal best be achieved?"
                placeholder="Work with your mentor to…"
                value={field?.value}
                onChange={(e) => field?.onChange(e)}
                minLength={0}
                maxLength={475}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default SecondStep;
