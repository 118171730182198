import { Switch } from "antd";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAllSchedule } from "../../services/team/member";
import { setSchedules } from "../../store/slices/memberSlice";
import {
  memberScheduleStatus,
  memberScheduleStatus as salonStatus,
} from "../../utils/constants";
import { toastMessage } from "../../utils/helper";

const ScheduleDetail = ({ onCloseSchedule }) => {
  const dispatch = useDispatch();

  const [disableActionBtn, setDisableActionBtn] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors, isValid },
  } = useForm();

  const { schedules, selectedData } = useSelector((state) => state.member);

  const handleSwitchChange = (isChecked, day) => {
    const scheduleAvailabilityNew = schedules.map((elem) => {
      return { ...elem };
    });
    scheduleAvailabilityNew.forEach((elem, ind) => {
      if (elem.day == day) {
        scheduleAvailabilityNew[ind].availability = isChecked
          ? salonStatus.active
          : salonStatus.inactive;
      }
    });
    dispatch(setSchedules(scheduleAvailabilityNew));
  };

  const onSubmit = async () => {
    try {
      setDisableActionBtn(true);
      const response = await UpdateAllSchedule(selectedData.id, schedules);
      const { data, status, message } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      onCloseSchedule();
      toastMessage("success", "Schedule Updated Successfully");
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setDisableActionBtn(false);
    }
  };
  const monday = schedules.find((elem) => elem.day == 1);
  const tuesday = schedules.find((elem) => elem.day == 2);
  const wednessday = schedules.find((elem) => elem.day == 3);
  const thursday = schedules.find((elem) => elem.day == 4);
  const friday = schedules.find((elem) => elem.day == 5);
  const saturday = schedules.find((elem) => elem.day == 6);
  const sunday = schedules.find((elem) => elem.day == 7);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="editSelectDate">
        <div className="editSelectDate">
          <div className="dateWrapper" style={{ padding: "24px" }}>
            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Monday</h4>
                    <h6
                      className={`${monday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {monday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        monday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 1)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Tuesday</h4>
                    <h6
                      className={`${tuesday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {tuesday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        tuesday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 2)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Wednessday</h4>
                    <h6
                      className={`${wednessday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {wednessday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        wednessday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 3)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Thursday</h4>
                    <h6
                      className={`${thursday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {thursday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        thursday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 4)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Friday</h4>
                    <h6
                      className={`${friday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {friday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        friday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 5)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Saturday</h4>
                    <h6
                      className={`${saturday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {saturday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        saturday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 6)}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key={uuidv4()} className="custom-mb-20">
              <Col>
                <div className="scheduleBox">
                  <div className="dayBox">
                    <h4>Sunday</h4>
                    <h6
                      className={`${sunday.availability == memberScheduleStatus.active ? "" : "off"}`}
                    >
                      {sunday.availability == memberScheduleStatus.active
                        ? "ON"
                        : "OFF"}
                    </h6>
                  </div>
                  <div className="switchBox">
                    <Switch
                      defaultChecked={
                        sunday.availability == memberScheduleStatus.active
                      }
                      onChange={(isChecked) => handleSwitchChange(isChecked, 7)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Col lg={12}>
          <div className="datebtndiv" style={{ padding: "0 24px" }}>
            {!disableActionBtn && (
              <Button
                type="submit"
                className={"w-100 datebutton"}
                disabled={!!Object.keys(errors).length}
              >
                Save Changes
              </Button>
            )}

            {disableActionBtn && (
              <Button
                type="submit"
                className={"w-100 datebutton"}
                disabled={true}
              >
                <div className="load black" />
              </Button>
            )}
          </div>
        </Col>
      </div>
    </Form>
  );
};

export default ScheduleDetail;
