import React, { useEffect, useState } from "react";

import { Image, message } from "antd";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { maxBounds } from "../../constants/contants";
import { DeleteIcon, ModalCloseIcon } from "../../constants/svg";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  CreateLocation,
  UpdateLocation,
} from "../../services/locations/locations";
import { setReloadLocation } from "../../store/slices/locationsSlice";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import CustomInput from "../FormFields/CustomInput";
import { roles as salonRoles } from "../../utils/constants";
import FileUploader from "../Common/FileUploader";

const AddNewLocationModal = (props) => {
  let dispatch = useDispatch();
  const { isReloadLocation } = useSelector((state) => state.locations);
  const { currentSalonId } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { name: "", file: "" },
  });
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  // const [file, name] = watch(["file", "name"])
  const watchingForm = watch(["name"]);

  const DraggerProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5185415ba171ea3a00704eed",
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status != "uploading") {
      }
      if (status == "done") {
        setValue("file", info.file?.originFileObj);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status == "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: (
        <span onClick={() => setValue("file", null)}>
          <ModalCloseIcon />
        </span>
      ),
    },
  };

  const onSubmit = async (data) => {
    try {
      let payload = {
        name: data?.name,
        salon_id: currentSalonId,
      };
      setLoading(true);
      setDisable(true);

      let salonS3UploadResponse;
      if (data?.file) {
        if (data.uploadFileFromLocal != "") {
          let updateStorage =
            GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin;
          let folder = `${
            GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
          }/location`;
          salonS3UploadResponse = await salonUploadToS3(
            data?.file[0],
            folder,
            updateStorage,
          );
          if (salonS3UploadResponse.status) {
            payload.image = salonS3UploadResponse?.data?.location;
          } else {
            throw new Error(salonS3UploadResponse?.message);
          }
        }
      }
      let res;
      if (props?.isUpdateLocation) {
        res = await UpdateLocation(props?.selectedData?.id, payload);
        if (props?.fetchtSingleLocation) {
          props.fetchtSingleLocation();
        }
      } else {
        res = await CreateLocation(payload);
      }
      props.setModalShow(false);
      dispatch(setReloadLocation(isReloadLocation + 1));
      setDisable(false);
      setLoading(false);
      toastMessage("success", res?.data?.message);
    } catch (e) {
      setDisable(false);
      setLoading(false);
      toastMessage("warning", e.message);
    }
  };
  const handleDelete = () => {
    setImage(null);
  };
  useEffect(() => {
    const isValid = watchingForm.every((elem) => elem != "");
    if (isValid && loading == false) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [watchingForm]);

  useEffect(() => {
    setValue("name", props?.selectedData?.name);
    setImage(props?.selectedData?.image);
  }, [props?.selectedData]);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Row>
          <Col lg={12} className="mb-20">
            <Controller
              control={control}
              name="name"
              rules={{
                required: {
                  value: true,
                },
                // pattern: {
                //   value: Patterns.Name,
                // },
                maxLength: {
                  value: maxBounds.NAME,
                },
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  label="Location Name"
                  placeholder="Location Name"
                  value={field?.value}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
            {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
          </Col>

          <Col lg={12}>
            <p className="small-font mb-3">Upload Attachment</p>
            <Controller
              control={control}
              name="file"
              defaultValue={false}
              // rules={{
              //   required: true,
              // }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div className="uploaderHolder">
                  <FileUploader
                    {...props}
                    fileList={value == "" ? [] : value}
                    onChange={(e) => {
                      const fileList = e.fileList;
                      onChange(fileList.length ? fileList : "");
                      setImage(null);
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    className="border-0 bg-0"
                    listType="picture"
                    limitMegabyteSize={4}
                  />
                </div>
              )}
            />
            {/* {errors.uploadFile && (
              <Form.Text>{errors.uploadFile.message}</Form.Text>
            )} */}
          </Col>
        </Row>
        {image && (
          <Row>
            <Col md={12}>
              <div className="img-upload-preview">
                <Image
                  className="ant-img-custom-css upload-img"
                  preview={false}
                  src={image}
                />
                <Button
                  type="button"
                  className={"delete-img"}
                  onClick={() => handleDelete()}
                >
                  <ModalCloseIcon />
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>

      <div className="modal-footer">
        <Button disabled={disable} type="submit" className="w-100">
          {loading ? (
            <div className="load black" />
          ) : props?.isUpdateLocation ? (
            "Update Location"
          ) : (
            "Save Location"
          )}
        </Button>
      </div>
    </Form>
  );
};

export default AddNewLocationModal;
