import { createSlice } from "@reduxjs/toolkit";

export const documentSlice = createSlice({
  name: "document",
  initialState: {
    loading: false,
    documents: [],
    page: 1,
    perPage: 10,
    isPaginate: 1,
    reRenderTable: 1,
    selectedData: null,
    actionButtonDisable: false,
    documentDetail: null,
    pageLoading: false,
    reRenderDocDetailPage: 1,
    docAddEditForm: {
      show: false,
      formType: null,
    },
    sorting: {
      column: "id",
      sortBy: "desc",
    },
  },
  reducers: {
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setDocAddEditForm: (state, action) => {
      state.docAddEditForm = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setIsPaginate: (state, action) => {
      state.isPaginate = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setDocumentDetail: (state, action) => {
      state.documentDetail = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setReRenderDocDetailPage: (state, action) => {
      state.reRenderDocDetailPage = action.payload;
    },
  },
});

export const {
  setLoading,
  setDocuments,
  setPage,
  setPerPage,
  setIsPaginate,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setDocumentDetail,
  setPageLoading,
  setSelectedData,
  setDocAddEditForm,
  setReRenderDocDetailPage,
} = documentSlice.actions;

export default documentSlice.reducer;
