import {
  ConfigProvider,
  Dropdown,
  Empty,
  notification,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Assets } from "../../../constants/images";
import {
  BinocularsLight,
  EditIcon,
  LockIcon,
  MoreIcon,
  NewSparkleSmallIcon,
  NoDataIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import {
  DeleteSingleFlashcard,
  GetFlashcards,
} from "../../../services/flashCards/flashCards";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setFlashCardAddEditForm,
  setFlashcards,
  setFlashCardSelectedData,
  setIsFlashcardsLoading,
} from "../../../store/slices/flashcardSlice";
import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import { hasAccess, tableLoader, toastMessage } from "../../../utils/helper";
import {
  roles as salonRoles,
  SHARE_URL,
  tableHeight,
} from "../../../utils/constants";
import { FaStar } from "react-icons/fa";
import LazyLoadTable from "../../Table/LazyLoadTable";

function renderAttendeesColumn(attendees) {
  if (!attendees) {
    return null;
  }
  const users = attendees.users || [];
  const attendeeTooltipContent = (
    <div className="attendeeNames">
      {users.slice(0, 7).map((name, index) => (
        <React.Fragment key={index}>
          <span>{name}</span>
          {index < users.slice(0, 7).length - 1}
        </React.Fragment>
      ))}
      {users.length > 7 && (
        <>
          <strong>+{users.length - 7} more</strong>
        </>
      )}
    </div>
  );

  return (
    <div className="">
      {attendees.count != 0 ? (
        <Tooltip
          title={attendeeTooltipContent}
          placement="bottom"
          overlayClassName="custom-tooltip"
          color="purple"
          // open={true}
        >
          <div className="seenBy dottedRoundedBox">
            <span>{attendees.count}</span>
          </div>
        </Tooltip>
      ) : (
        <div className="seenBy dottedRoundedBox">
          <span>{attendees.count}</span>
        </div>
      )}
    </div>
  );
}

const FlashCardBody = (props) => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const {
    status,
    user,
    isFlashcardsLoading,
    isReloadFlashCardTable,
    flashCardAddEditForm,
    flashcards,
  } = useSelector((state) => state.flashCard);
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const url = window.location.href + "/" + selectedData?.key;
  const [disable, setDisable] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const onClose = () => {
    setOpen(false);
  };

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <NavLink
            onClick={() => handleSetSelectedData(record)}
            to={`/flashcards/details/${record?.key}`}
            className={"black"}
          >
            <BinocularsLight />
            View
          </NavLink>
        ),
      },
      hasAccess(record?.user) && {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                dispatch(
                  setFlashCardAddEditForm({
                    show: true,
                    formType: "edit",
                  }),
                );
              }}
            >
              <EditIcon />
              Edit
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              handleSetSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const items = [
    {
      key: uuidv4(),
      label: (
        <>
          <div className="adminRoleBox lockBox">
            <h6>
              <span>
                <NewSparkleSmallIcon />
              </span>{" "}
              Posted by Salon Symphony
            </h6>
          </div>
        </>
      ),
    },
  ];

  const columns = [
    {
      key: "setName",
      title: "Set Name",
      dataIndex: "taskName",
      sorter: (a, b) => a.taskName.title.localeCompare(b.taskName.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <>
          <NavLink
            to={`/flashcards/details/${record?.key}`}
            className={"black imageTableWrapper"}
          >
            <Space size={10}>
              <img
                src={record.taskName.image || Assets.GeneralPlaceholder}
                alt={record.taskName.title}
                className="eventDetailImage"
                onError={(e) => (e.target.src = Assets.GeneralPlaceholder)}
              />
              {record.taskName.lock && (
                <Dropdown
                  menu={{
                    items,
                  }}
                  overlayClassName="singleDropdown"
                  className="singLock imageMargin"
                  placement="bottom"
                  open={false}
                >
                  <Space>
                    {/* <LockIcon /> */}
                    <FaStar
                      color="#A8A8A8"
                      style={{ ":hover": { color: "#c4c4c4" } }}
                    />
                  </Space>
                </Dropdown>
              )}
              <span>{record.taskName.title}</span>
            </Space>
          </NavLink>
        </>
      ),
      width: "45%",
    },
    {
      key: "started",
      title: <span className="taskHead bg-purple">STARTED</span>,
      dataIndex: "started",
      render: (text, record) => renderAttendeesColumn(record.started),
      align: "center",
      width: "15%",
    },
    {
      key: "notStarted",
      title: <span className="taskHead bg-red">NOT STARTED</span>,
      dataIndex: "notStarted",
      render: (text, record) => renderAttendeesColumn(record.notStarted),
      align: "center",
      width: "15%",
    },
    {
      key: "completed",
      title: <span className="taskHead bg-green">COMPLETED</span>,
      dataIndex: "completed",
      render: (text, record) => renderAttendeesColumn(record.completed),
      align: "center",
      width: "15%",
    },

    {
      key: "more",
      title: "More",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      width: "10%",
    },
  ];

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust the scroll speed as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleSetSelectedData = (record) => {
    dispatch(setFlashCardSelectedData(record));
    setSelectedData({
      key: record?.key,
      name: record?.taskName?.title,
      description: record?.description,
      image: record?.taskName?.image,
      start_date: moment(record?.start_date).format("MMM DD, yyyy"),
      start_time: moment(record?.start_date).format("hh:mm a"),
    });
  };

  const getFlashcards = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];
    try {
      const params = {
        is_paginated: 0,
        status: status?.active,
        salon_id: currentSalonId,
        user_id: authUser?.id,
        category_id: props?.selectedCategory,
        "order-column": "created_at",
        softwares,
        technologies,
        products,
      };
      dispatch(setIsFlashcardsLoading(true));
      const res = await GetFlashcards(params);
      const data = res?.data?.data?.map((item) => {
        return {
          key: item?.id,
          taskName: {
            title: item?.name || "-",
            image: item?.image || Assets.GeneralPlaceholder,
            lock: item?.user?.roles?.[0]?.id == salonRoles.super_admin,
          },
          icon: true,
          started: {
            count: item?.startedUsers?.length,
            users: item?.startedUsers?.map((item) => item?.user?.name),
          },
          notStarted: {
            count: item?.notStartedUsers?.length,
            users: item?.notStartedUsers?.map((item) => item?.user?.name),
          },
          completed: {
            count: item?.completedUsers?.length,
            users: item?.completedUsers?.map((item) => item?.user?.name),
          },
          description: item?.description || "-",
          start_data: item?.created_at || "-",
          start_time: item?.created_at || "-",
          user: item?.user || null,
        };
      });
      dispatch(setFlashcards({ data, meta: res?.data?.data?.meta }));
      dispatch(setIsFlashcardsLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsFlashcardsLoading(false));
    }
  };

  const handleDelete = async () => {
    try {
      setDisable(true);

      const res = await DeleteSingleFlashcard(selectedData?.key);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);
      getFlashcards();
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    dispatch(setIsFlashcardsLoading(false));
    getFlashcards();
  }, [
    props?.selectedCategory,
    props?.page?.currentPage,
    // props?.page?.perPage,
    isReloadFlashCardTable,
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  return (
    <>
      <div className="content">
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Flashcards you've created will appear here.</h5>
              <h6>Click "Create Set" to get started.</h6>
            </div>
          )}
        >
          <Table
            // locale={{
            //   emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
            // }}
            columns={columns}
            store={{ store: "flashCard", param: "flashcards" }}
            loading={tableLoader(isFlashcardsLoading)}
            dataSource={flashcards?.data}
            bordered={false}
            pagination={false}
            // pagination={{
            //   onChange: (currPage) =>
            //     props?.setPage({ ...props?.page, currentPage: currPage }),
            //   pageSize: props?.page?.perPage,
            //   current: props?.page?.currentPage,
            //   total: flashcards?.meta?.total || 0,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            //   showSizeChanger: false,
            // }}
            // virtual
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className={"flashCardTable imageTable"}
            rowKey={(record) => record.key}
          />
        </ConfigProvider>
      </div>
      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        data={selectedData}
        shareLink={SHARE_URL.FLASHCARDS + selectedData?.key}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Flashcard Set"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this flashcard with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Introduction to L’Oreal Color",
          para1: "Improve your Business knowledge with this flashcard set.",
          para2: "10 Cards • Feb 12, 2023",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        handleDelete={handleDelete}
        disable={disable}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Flashcard"
        para={
          <>
            Are you sure you want to delete
            <b> {selectedData?.name || " this flahcard"} ?</b>
            <br />
            Important: This cannot be undone.
          </>
        }
        notificationMessage="Flashcard set"
      />
    </>
  );
};

export default FlashCardBody;
