import { createSlice } from "@reduxjs/toolkit";

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    isCategoriesLoading: true,

    categories: {
      meta: null,
      data: [],
    },
  },
  reducers: {
    setIsCategoriesLoading: (state, action) => {
      state.isCategoriesLoading = action.payload;
    },
    setCategories: (state, action) => {
      state.categories.data = action.payload.data;
      state.categories.meta = action.payload.meta;
    },
  },
});

export const { setIsCategoriesLoading, setCategories } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
