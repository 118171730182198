import React from "react";
import { NavLink } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Assets } from "../../constants/images";
import { formatString, getActivityTypeURL } from "../../utils/helper";
import AvatarImage from "./AvatarImage";

function NotificationCard(props) {
  return (
    <>
      <NavLink
        style={{ textDecoration: "none" }}
        to={
          props?.data?.model && props?.data?.model_id
            ? getActivityTypeURL(props?.data?.model, props?.data?.model_id)
            : "/activity"
        }
        className={"notificationAnchor"}
      >
        <div
          className={
            props?.clearedData ? "notificationCard cleared" : "notificationCard"
          }
        >
          <div className="notificationAvatar">
            <AvatarImage
              name={props.data.alertTitle.title}
              src={props?.data?.alertTitle?.image}
              placeholder={Assets.ProfilePlaceholder}
              size={38}
            />
          </div>
          <div className="notificationContent">
            <div className="notificationText text-dark">
              {props?.data?.sendType?.main || "-"}
            </div>
            <div className="notificationTime">
              <span>{formatString(props?.data?.lastSent?.main) || "-"}</span>
            </div>
          </div>
          {props?.clearedData && (
            <div className={"clearedIcon"}>
              <IoCheckmarkCircle />
            </div>
          )}
        </div>
      </NavLink>
    </>
  );
}

export default NotificationCard;
