import { Dropdown, Space, Tooltip } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  BinocularsLight,
  EditIcon,
  LockIcon,
  MoreIcon,
  NewSparkleSmallIcon,
  RecycleBin,
  ShareIcon,
} from "../../../constants/svg";
import { hasAccess } from "../../../utils/helper";
import { FaStar } from "react-icons/fa";

export const getColumns = (
  handleOpenQuizEditForm,
  setShowModal,
  setQuizIDForDelete,
  setQuizShareData,
) => {
  return [
    {
      title: "Quiz Name",
      dataIndex: "quizName",
      key: "quizName",
      sorter: (a, b) => a.quizName.localeCompare(b.quizName),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <>
          <NavLink
            to={`/quizzes/details/${record.id}`}
            className={"black imageTableWrapper"}
          >
            <Space size={10}>
              <img
                src={record.image}
                alt={record.quizName}
                className="eventDetailImage"
              />
              {record.lock && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <>
                            <div className="adminRoleBox lockBox">
                              <h6>
                                <span>
                                  <NewSparkleSmallIcon />
                                </span>{" "}
                                Posted by Salon Symphony
                              </h6>
                            </div>
                          </>
                        ),
                      },
                    ],
                  }}
                  overlayClassName="singleDropdown"
                  className="singLock imageMargin"
                  placement="bottom"
                  open={false}
                >
                  <Space>
                    {/* <LockIcon /> */}
                    <FaStar
                      color="#A8A8A8"
                      style={{ ":hover": { color: "#c4c4c4" } }}
                    />
                  </Space>
                </Dropdown>
              )}
              <span>{record.quizName}</span>
            </Space>
          </NavLink>
        </>
      ),
      width: "45%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.category}</p>,
      align: "left",
      width: "15%",
    },
    {
      sorter: false,
      title: <span className="taskHead bg-red">LOW SCORE</span>,
      dataIndex: "lowScore",
      key: "lowScore",
      render: (text, record) => renderAttendeesColumn(record.lowScore),
      align: "center",
      width: "15%",
    },
    {
      sorter: false,
      title: <span className="taskHead bg-green">HIGH SCORE</span>,
      dataIndex: "highScore",
      key: "highScore",
      render: (text, record) => renderAttendeesColumn(record.highScore),
      align: "center",
      width: "15%",
    },

    {
      sorter: false,
      title: "More",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: uuidv4(),
                  label: (
                    <>
                      <NavLink
                        to={`/quizzes/details/${record.id}`}
                        className={"black"}
                      >
                        <BinocularsLight />
                        View
                      </NavLink>
                    </>
                  ),
                },
                hasAccess(record?.user) && {
                  key: uuidv4(),
                  label: (
                    <>
                      <div
                        onClick={() => {
                          handleOpenQuizEditForm(record);
                        }}
                      >
                        <EditIcon />
                        Edit
                      </div>
                    </>
                  ),
                },
                {
                  key: uuidv4(),
                  label: (
                    <>
                      <div
                        onClick={() => {
                          setQuizShareData(record);
                          setShowModal((prevState) => ({
                            ...prevState,
                            shareModal: true,
                          }));
                        }}
                      >
                        <ShareIcon />
                        Share
                      </div>
                    </>
                  ),
                },
                hasAccess(record?.user) && {
                  key: uuidv4(),
                  label: (
                    <div
                      className={"deleteLabel"}
                      onClick={() => {
                        setQuizIDForDelete(record);
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }));
                      }}
                    >
                      <RecycleBin />
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
            // open={true}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      width: "10%",
    },
  ];
};

function renderAttendeesColumn(attendees) {
  if (!attendees) {
    return null;
  }
  const attendeeNames = attendees.attendents || [];
  const attendeeTooltipContent = (
    <div className="attendeeNames">
      {attendeeNames.slice(0, 7).map((name, index) => (
        <React.Fragment key={index}>
          <span>{name}</span>
          {index < attendeeNames.slice(0, 7).length - 1}
        </React.Fragment>
      ))}
      {attendeeNames.length > 7 && (
        <>
          <strong>+{attendeeNames.length - 7} more</strong>
        </>
      )}
    </div>
  );

  return (
    <div className="">
      {attendees.count != 0 ? (
        <Tooltip
          title={attendeeTooltipContent}
          placement="bottom"
          overlayClassName="custom-tooltip"
          color="purple"
          // open={true}
        >
          <div className="seenBy dottedRoundedBox">
            <span>{attendees.count}</span>
          </div>
        </Tooltip>
      ) : (
        <div className="seenBy dottedRoundedBox">
          <span>{attendees.count}</span>
        </div>
      )}
    </div>
  );
}
