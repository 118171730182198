import { Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowTriangleDown,
  BookIcon,
  BookIconFilled,
  CalenderIcon,
  CalenderIconFilled,
  CheckMarkIcon,
  NewTreeIconFilled,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { GetLocations } from "../../../services/locations/locations";
import {
  setIsLocationsLoading,
  setLocations,
} from "../../../store/slices/locationsSlice";
import { user_requests_status_array } from "../../../utils/constants";
import { setFilters, setPagination } from "../../../store/slices/requestsSlice";
import { GetStatistics } from "../../../services/dashboard/dashboard";
import { setIsStatisticsLoading } from "../../../store/slices/dashboardSlice";

const RequestHeader = (props) => {
  const [showGenericModal, setShowGenericModal] = useState(false);
  const { locations } = useSelector((state) => state);
  const [allRequests, setAllRequests] = useState();
  const dispatch = useDispatch();
  const { currentSalonId } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();
  const { educationRequest, timeOffRequest, scheduleRequest, filters } =
    useSelector((state) => state?.requests);
  const { statistics } = useSelector((state) => state?.dashboard);

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };
  useEffect(() => {
    console.log("statistics", statistics?.requestsCount);
    setAllRequests(statistics);
  }, [statistics]);

  const resetPagination = () => {
    dispatch(
      setPagination({
        timeOff: {
          currentPage: 1,
          perPage: 10,
          isPaginate: 1,
        },
        schedule: {
          currentPage: 1,
          perPage: 10,
          isPaginate: 1,
        },
        educatioon: {
          currentPage: 1,
          perPage: 10,
          isPaginate: 1,
        },
      }),
    );
  };

  const handleLocationChange = (value) => {
    resetPagination();
    dispatch(
      setFilters({
        ...filters,
        location: value,
      }),
    );
  };

  const handleStatusChange = (value) => {
    resetPagination();
    dispatch(
      setFilters({
        ...filters,
        status: value,
      }),
    );
  };

  const getLocations = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetLocations(params);
      const data = res?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setLocations({ data, meta: null }));
      dispatch(setIsLocationsLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsLocationsLoading(false));
    }
  };

  const fetchStatistics = async () => {
    let auth = await GetAuthUserLocalStorage();
    if (auth) {
      const params = {
        salon_id: auth?.salon_id,
      };
      dispatch(setIsStatisticsLoading(true));
      const res = await GetStatistics(params);
      const data = res.data.data;
      setAllRequests(data);
    }
    dispatch(setIsStatisticsLoading(false));
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    getLocations();
  }, [currentSalonId]);

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Requests</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                <div className="icon icon-active">
                  <NewTreeIconFilled />
                </div>
                <div className="icon">
                  <NewTreeIconFilled />
                </div>
                Time Off ({allRequests?.requestsCount?.timeOffRequests || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                <div className="icon icon-active">
                  <CalenderIconFilled />
                </div>
                <div className="icon">
                  <CalenderIcon />
                </div>
                Schedule ({allRequests?.requestsCount?.scheduleRequests || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab3" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab3")}
              >
                <div className="icon icon-active">
                  <BookIconFilled />
                </div>
                <div className="icon">
                  <BookIcon />
                </div>
                Education ({allRequests?.requestsCount?.educationRequests || 0})
              </div>
            </div>
          </div>
        </div>
        <div className="globalHeader--right">
          <Select
            defaultValue="All Locations"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={locations?.locations?.data}
            onChange={(e) => handleLocationChange(e)}
          ></Select>
          <Select
            defaultValue="All Pending"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            onChange={(e) => handleStatusChange(e)}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={user_requests_status_array}
          ></Select>
        </div>
      </div>
    </>
  );
};

export default RequestHeader;
