import React, { useState } from "react";
import { Image, Modal, Row, Col } from "react-bootstrap";
import LeavePageModal from "./LeavePageModal";
import { ModalCloseIcon } from "../../constants/svg";
import CopylinkBar from "../Common/CopylinkBar";
import { Avatar } from "antd";
import { Assets } from "../../constants/images";

const ShareDocModal = (props) => {
  const { title, link, size, description, docType } = props.data;
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal shareModal mainShareMemberBody"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" text-left w-100"
          >
            {props.modalTitle}
            <p>{props.modalSubtitle}</p>
          </Modal.Title>

          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        {/* All body Compponents goes here... */}
        <div className="modal-body share-member-body">
          <Row>
            <Col>
              <div className="member-info">
                <div className="">
                  <img src={Assets.PdfImage} alt="" />
                </div>
                <div className="info">
                  <h5>{title}</h5>
                  <p>{description}</p>
                  <p>
                    {size} • {docType}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <CopylinkBar closeAction={props.onHide} link={link} />
        </div>
      </Modal>
    </>
  );
};

export default ShareDocModal;
