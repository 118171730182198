import React from "react";
import { Col, Row } from "react-bootstrap";
import { OneTimeIcon, WeeklyTaskIcon } from "../../../../../constants/svg";
import { task_type } from "../../../../../utils/constants";
import RadioModalCards from "../../../../Cards/RadioModalCards";

const FirstStep = ({ handleSelect, selected }) => {
  return (
    <Row>
      <Col lg={6}>
        <div className="margin-bottom-26">
          <RadioModalCards
            title="One-Time Task"
            para="Any task with a single due date,"
            subpara="such as study flashcards"
            icon={<OneTimeIcon />}
            active={selected == task_type?.one_time}
            handleClick={() => handleSelect(task_type?.one_time)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-26">
          <RadioModalCards
            title="Weekly Task"
            para="Due the same day each week,"
            subpara="such as weekly cleaning"
            icon={<WeeklyTaskIcon />}
            active={selected == task_type?.weekly}
            handleClick={() => handleSelect(task_type?.weekly)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-39">
          <RadioModalCards
            title="Bi-Weekly Task"
            para="Due the same day every two weeks,"
            subpara="such as submit timecard"
            icon={<WeeklyTaskIcon />}
            active={selected == task_type?.bi_weekly}
            selected={selected}
            handleClick={() => handleSelect(task_type?.bi_weekly)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <div className="margin-bottom-39">
          <RadioModalCards
            title="Monthly Task"
            para="Due the same day each month,"
            subpara="such as monthly inventory"
            icon={<WeeklyTaskIcon />}
            active={selected == task_type?.monthly}
            handleClick={() => handleSelect(task_type?.monthly)}
          />
        </div>
      </Col>
    </Row>
  );
};

export default FirstStep;
