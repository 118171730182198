import { Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
} from "../../../constants/svg";
import {
  setIsAlertsLoading,
  setResetAlertsFormData,
} from "../../../store/slices/aletsSlice";
import CreatePushAlertModal from "./CreatePushAlertModal";
import { FormProvider, useForm } from "react-hook-form";

const PushAlertHeader = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const methods = useForm();

  const menuOption = [
    {
      name: null,
      value: "All Alerts",
    },
    {
      name: 10,
      value: "Immediate",
    },
    {
      name: 20,
      value: "Scheduled",
    },
    {
      name: 30,
      value: "Recurring",
    },
  ];

  const handleChange = (value) => {
    props?.handleResetPage();
    const selected = menuOption.filter((item) => item.value == value);
    props?.setSelectedCategory(selected[0].name);
    dispatch(setIsAlertsLoading(true));
  };

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Alerts</h2>
        </div>
        <div className="globalHeader--right">
          <Select
            defaultValue="All Alerts"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={menuOption}
            onChange={(e) => handleChange(e)}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() => {
              dispatch(setResetAlertsFormData());
              setModalShow(true);
            }}
          >
            <span>
              <CreateIcon />
            </span>
            Create Alert
          </button>
        </div>
      </div>
      <FormProvider {...methods}>
        <CreatePushAlertModal
          isedit={false}
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          methods={methods}
          customModalHeader={"customPushAlertModalHeader"}
          customModalTitle={"customPushAlertModalTitle"}
        />
      </FormProvider>
    </>
  );
};

export default PushAlertHeader;
