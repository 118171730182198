import { notification } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../components/FormFields/CustomDatePicker";
import CustomInput from "../../components/FormFields/CustomInput";
import CustomSelect from "../../components/FormFields/CustomSelect";
import { ToastCrossIcon } from "../../constants/svg";
import {
  setAuthData,
  setIsPositionsLoading,
} from "../../store/slices/authSlice";
import { setPositions } from "../../store/slices/globalSlice";
import {
  currentBookingList,
  primaryProductList,
  salonJobType,
  status as salonStatus,
  event_timezone,
} from "../../utils/constants";
import { GetPositions } from "./../../services/positions/positions";
import CustomDropdown from "../../components/Common/CustomDropdown";

function BusinessDetail(props) {
  const currentBooking = currentBookingList.map((product) => ({
    value: product.replaceAll(" People", ""),
    label: product,
  }));

  const { positions } = useSelector((state) => state.global);

  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState("");

  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state?.auth);
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    let convertedDate = moment(selectedDate).format("MM DD, YYYY");
    if (selectedDate) {
      setSelectedDate(convertedDate);
    }
  }, [selectedDate]);

  const goBackFunc = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    dispatch(setAuthData({ ...formData, ...data }));
    navigate("/add-locations");
  };

  function disabledDate(current) {
    const currentDate = moment();
    return current && current > currentDate;
  }

  const getPositions = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: salonStatus.active,
        type: salonJobType.admin,
        order: "asc",
        "order-column": "id",
      };
      const response = await GetPositions(params);
      const { data, message, status } = response.data;

      const positionOptions = data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setPositions(positionOptions));
      dispatch(setIsPositionsLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsPositionsLoading(false));
    }
  };

  useEffect(() => {
    getPositions();
    if (formData.name == "") {
      navigate("/setup");
    }
  }, []);

  useEffect(() => {
    reset({
      salon_name: formData?.salon_name,
      date_founded: formData?.date_founded,
      position: formData?.position,
      product_line: formData?.product_line,
      booking_software: formData?.booking_software,
      timezone: formData?.timezone,
    });
  }, [formData]);

  return (
    <>
      <div className="authBox accountSetup">
        <div className="authHeaderText">
          <h1>Business Details</h1>
          <p>Beautiful! Now let’s get down to your business.</p>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          className="BusniessDetailForm"
        >
          <div className="mb-20 BusniessDetailFormInput">
            <Controller
              control={control}
              name="salon_name"
              rules={{
                required: "Bussiness name is required",
              }}
              render={({ field }) => (
                <CustomInput
                  {...field}
                  className={errors.salon_name ? "hasError" : ""}
                  label="Business Name"
                  placeholder=""
                  value={field?.value}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </div>

          <div className="mb-20">
            <Controller
              control={control}
              name="date_founded"
              defaultValue={""}
              rules={{
                required: "Date founded is required",
              }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  className={errors.date_founded ? "hasError" : ""}
                  placeholder="Date Founded"
                  onChange={(date, dateString) => {
                    field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  label={"Date Founded"}
                  value={field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
                  disabledDate={disabledDate}
                />
              )}
            />
          </div>

          <div className="mb-20">
            <Controller
              control={control}
              name="position"
              defaultValue={""}
              rules={{
                required: "Job title is required",
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  className={
                    errors.position
                      ? "hasError businessDetailDropdown"
                      : " businessDetailDropdown"
                  }
                  placeholder="Job Title"
                  label="Job Title"
                  value={field?.value}
                  options={positions}
                  onChange={(e) => field?.onChange(e)}
                  popupClassName="position-absolute"
                  //   isDropdownOpen={true}
                />
              )}
            />
          </div>

          <div className="mb-20">
            <Controller
              control={control}
              name="dob"
              defaultValue={""}
              rules={{
                required: "Date of Birth is required",
              }}
              render={({ field }) => (
                <div className="mb-20">
                  <CustomDatePicker
                    {...field}
                    className={`form-floating ${errors.dob ? "hasError" : ""}`}
                    placeholder="Date of Birth"
                    label="Date of Birth"
                    onChange={(date, dateString) => {
                      field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                    }}
                    value={
                      field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                    }
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current >= moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </div>
              )}
            />
          </div>

          <div className="mb-20">
            <Controller
              rules={{
                required: "Time zone is required",
              }}
              control={control}
              name="timezone"
              render={({ field: { onChange, value } }) => (
                <FloatingLabel
                  controlId="startTime"
                  label={value == "" ? "Time Zone" : ""}
                  className="mb-20"
                >
                  <CustomDropdown
                    value={value}
                    defaultValue={value}
                    className={errors.timezone ? "hasError" : ""}
                    handleSelectAndDatePickerValues={onChange}
                    placeholder={"Time Zone"}
                    menuOption={event_timezone} // Send the data array for menu
                    show={null} // Show Method of modal if there is any
                  />
                </FloatingLabel>
              )}
            />
          </div>

          <div className="mb-20">
            {/* <Controller
              control={control}
              name="booking_software"
              defaultValue={""}
              rules={{
                required: "Current Booking Software is required",
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  className={
                    errors.booking_software
                      ? "hasError businessDetailDropdown"
                      : "businessDetailDropdown"
                  }
                  placeholder={"How many people will use Salon Symphony?"}
                  label="How many people will use Salon Symphony?"
                  value={field?.value}
                  options={currentBooking}
                  onChange={(e) => field?.onChange(e)}
                  popupClassName={"position-absolute"}
                  //   isDropdownOpen={true}
                />
              )}
            /> */}
          </div>

          <Row>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                className={"w-100 btn-submit btn-gray"}
                onClick={goBackFunc}
              >
                Back
              </Button>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                type={"submit"}
                className={"w-100 btn-submit"}
                disabled={!isValid}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default BusinessDetail;
