import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../constants/svg";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../services/auth/auth";
import {
  EmptyLocalStorage,
  GetAuthUserLocalStorage,
} from "../../services/localStorage/localStorage";
import { online_status } from "../../utils/constants";
import { updateOnlineStatusOfLoginUser } from "../../services/chats/frontend-chats";
import { toastMessage } from "../../utils/helper";
import { useDispatch } from "react-redux";

const LogoutModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const authUser = GetAuthUserLocalStorage();

  const handleLogout = async () => {
    try {
      setDisable(true);
      const res = await Logout();
      await updateOnlineStatusOfLoginUser(authUser?.id, online_status?.offline);
      // dispatch(resetChatData())
      // dispatch(resetAll())
      EmptyLocalStorage();
      toastMessage("success", "You have been logged out!");
      setTimeout(() => {
        setDisable(false);
        // navigate("/");
        window.location.href = "/";
      }, 1200);
    } catch (e) {
      setDisable(false);
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="logout-modal"
      >
        <Modal.Body>
          <h3>{props.heading}</h3>
          <p>{props.para}</p>

          <div className="btn-div">
            <Button className="border-grey-log-btn me-2" onClick={props.onHide}>
              {props.cancelText ? props.cancelText : "Cancel"}
            </Button>
            <Button
              disabled={disable}
              className="solid-pink-sm-btn"
              onClick={handleLogout}
            >
              {disable ? <div className="load black" /> : "Log Out"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutModal;
