export default function ErrorPage() {
  const handleRedirect = () => {
    // You can replace this URL with your main domain or home page URL
    window.location.href = window.location.origin;
  };
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p>
        Sorry, the page you are looking for might be unavailable or does not
        exist.
      </p>
      <p>
        Return to <button onClick={handleRedirect}>Home</button>
      </p>
    </div>
  );
}
