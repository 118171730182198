import { createSlice } from "@reduxjs/toolkit";

export const shoutoutSlice = createSlice({
  name: "shoutout",
  initialState: {},
  reducers: {},
});

export const {} = shoutoutSlice.actions;

export default shoutoutSlice.reducer;
