import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Assets } from "../../constants/images";

const CustomInputWithEmoji = (props) => {
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const inputRef = useRef(null);
  function handleEmojiClick(emojiData) {
    const cursorPosition = inputRef.current?.selectionStart;
    const currentValue = props?.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      emojiData.emoji +
      currentValue.slice(cursorPosition);
    // let value = props?.value + emojiData.emoji;
    props?.handleEmoji(props?.name, newValue);
    // Set the cursor position after the emoji
    inputRef.current.selectionStart = cursorPosition + emojiData.emoji.length;
    inputRef.current.selectionEnd = cursorPosition + emojiData.emoji.length;
    inputRef.current.focus();
  }

  // Define a function to handle clicks outside of the EmojiPicker
  function handleCheckEmojiClick(event) {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  }

  useEffect(() => {
    // Add the click event listener to the window
    window.addEventListener("click", handleCheckEmojiClick);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleCheckEmojiClick);
    };
  }, [showPicker]);

  return (
    <FloatingLabel label={props?.label}>
      <Form.Control
        {...props}
        placeholder={props?.placeholder}
        value={props?.value}
        onChange={props?.onChange}
        minLength={props?.minLength}
        maxLength={props?.maxLength}
        ref={inputRef}
      />
      <div ref={emojiPickerRef}>
        <div onClick={() => setShowPicker(!showPicker)} className={"smileyBox"}>
          <img src={Assets.Smile} className={"smileImage"} alt="Smile" />
        </div>
        {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
      </div>
    </FloatingLabel>
  );
};

export default CustomInputWithEmoji;
