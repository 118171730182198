import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { OnlineGlobe, UserIcons } from "../../../../../constants/svg";
import RadioModalCards from "../../../../Cards/RadioModalCards";

const FirstStep = ({ control, errors }) => {
  return (
    <Row>
      <Col lg={6}>
        <Controller
          control={control}
          name="eventType"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioModalCards
              title="In-Person Event"
              para="Any event with a physical address, like an in-salon workshop."
              icon={<UserIcons />}
              active={value == "onsite"}
              handleClick={() => onChange("onsite")}
            />
          )}
        />
      </Col>
      <Col lg={6}>
        <Controller
          control={control}
          name="eventType"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioModalCards
              title="Online Event"
              para="Any event with an external link, like a Zoom conference."
              icon={<OnlineGlobe />}
              active={value == "online"}
              handleClick={() => onChange("online")}
            />
          )}
        />
      </Col>
      <Col lg={12}>
        {errors.eventType && <Form.Text>{errors.eventType.message}</Form.Text>}
      </Col>
    </Row>
  );
};

export default FirstStep;
