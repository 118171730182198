import { Switch, message } from "antd";
import React, { useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { DropVideo } from "../../../../../../constants/svg";
import CustomUploader from "../../../../../Common/CustomUploader";

const VideoUpload = ({
  handleChange,
  fileList,
  resetField,
  handleSwitch,
  switchDefaultValue,
  linkDefaultValue,
}) => {
  const [switchValue, setSwitchValue] = useState(false);
  const [resourceLink, setResourceLink] = useState("");

  const handleSwitchChange = (checked) => {
    resetField("uploadVideos");
    setResourceLink("");
    setSwitchValue(checked);
    handleSwitch(checked);
  };

  const UploadProps = {
    name: "file",
    accept: "video/*",
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    // showUploadList: {
    //   showDownloadIcon: false,
    //   showRemoveIcon: true,
    //   removeIcon: (
    //     <ModalCloseIcon />
    //   ),
    // },
  };

  const handleInputLink = (event) => {
    setResourceLink(event.target.value);
    handleChange(event.target.value);
  };

  return (
    <>
      <div className="uploadHeader mb-15 d-flex justify-content-between align-items-center">
        <div className="title">Upload an MP4 or link to a YouTube video.</div>
        <p className="m-0">
          Link to resource instead
          <Switch
            checked={switchDefaultValue || switchValue}
            onChange={handleSwitchChange}
            className="custom-ml-05"
          />
        </p>
      </div>
      {!switchValue && !switchDefaultValue ? (
        <div className="addLink-Box">
          <CustomUploader
            {...UploadProps}
            beforeUpload={() => {
              /* update state here */
              return false;
            }}
            className="three-type-upload"
            fileList={fileList == "" ? [] : [fileList]}
            onChange={(e) => {
              const fileList = e.fileList;
              handleChange(
                !fileList.length
                  ? ""
                  : fileList.length > 1
                    ? fileList[1]
                    : fileList[0],
              );
            }}
          >
            <DropVideo />
          </CustomUploader>
        </div>
      ) : (
        <div className="uploaderBox floatedLabel">
          <FormGroup className="form-label-group">
            <Form.Label htmlFor="resourceLink">Resource Link</Form.Label>
            <Form.Control
              className="custom-grey-color"
              type="link"
              id="resourceLink"
              placeholder="https://"
              value={linkDefaultValue || resourceLink}
              onChange={handleInputLink}
              required
            />
          </FormGroup>
        </div>
      )}
    </>
  );
};

export default VideoUpload;
