import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetServey = async (params) => {
  return await api.get(apiUrl.survey, { params });
};

export const DeleteSurvey = async (id) => {
  return await api.delete(apiUrl.survey + "/" + id);
};

export const GetSurveyByID = async (id) => {
  return await api.get(apiUrl.survey + "/" + id);
};

export const CreateSurvey = async (payload) => {
  return await api.post(apiUrl.survey, payload);
};

export const UpdateSurvey = async (id, payload) => {
  return await api.put(apiUrl.survey + "/" + id, payload);
};

export const CreateSurveyQuestions = async (payload) => {
  return await api.post(apiUrl.surveyQuestion, payload);
};

export const GetSurveyQuestions = async (id) => {
  return await api.get(apiUrl.surveyQuestion + "/" + id);
};
