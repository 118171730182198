import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetEvents = (params) => {
  params = { ...params, platform: "web" };
  return api.get(apiUrl.events, { params });
};
export const GetSingleEvent = (id) => {
  let params = { platform: "web" };
  return api.get(`${apiUrl.events}/${id}`, { params });
};
export const DeleteSingleEvent = (id) => api.delete(`${apiUrl.events}/${id}`);
export const CreateEvent = (payload) => api.post(`${apiUrl.events}`, payload);
export const UpdateEvent = (id, payload) =>
  api.put(`${apiUrl.events}/${id}`, payload);
