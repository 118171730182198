const FileUploadField = ({ limitMegabyteSize }) => {
  return (
    <div className="uploadBox upload-dashed-border-image dragEffect">
      <p className="notifymessage">
        Drop your cover photo here or <span>Browse</span>
      </p>
      <p className="fileFormat">{`PNG, JPG, or GIF Up to ${limitMegabyteSize}MB.`}</p>
    </div>
  );
};

export default FileUploadField;
