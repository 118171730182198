import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { tableHeight, roles } from "../../utils/constants";
import { useSelector } from "react-redux";
import { loader } from "../../utils/helper";
let throttle = {};

const LazyLoadTable = ({
  state_property,
  columns,
  store,
  dataSource,
  isUpdate = false,
  ...props
}) => {
  const _states = useSelector((state) => state[store?.store]);
  throttle[store?.param] = throttle?.[store?.param] || 0;
  const { onChange } = props.pagination;

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (isUpdate) {
      setLoading(false);
      setPage(props.pagination?.current || 1);
      setHasMore(_states[store?.param]?.meta?.last_page > page);
      setTotal(props.pagination?.total);
      setData(dataSource);
    } else {
      if (_states[store?.param] !== undefined) {
        setLoading(false);
        const _data =
          _states[store?.param]?.data ||
          _states[store?.param].results ||
          _states[store?.param];
        if (_data.length == 0) return;
        const mergeData = [...data, ..._data];

        const key = _data[0] && _data[0].key ? "key" : "id";
        const uniqueObjects = mergeData.filter(
          (obj, index, self) =>
            index == self.findIndex((o) => o[key] == obj[key]),
        );
        uniqueObjects.sort(function (a, b) {
          let keyA = a[key];
          let keyB = b[key];
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setPage(_states[store?.param]?.meta?.current_page || 1);
        setData((prevData) => [...uniqueObjects]);
        setHasMore(_states[store?.param]?.meta?.last_page > page);
        setTotal(_states[store?.param]?.meta?.total);
      }
    }
  }, [_states[store?.param], dataSource]);

  const fetchData = async (_page) => {
    if (!hasMore) return;
    if (_page <= throttle[store?.param]) return;
    throttle[store?.param] = _page;
    if (page != 1) setLoading(true);
    onChange(_page, 10);
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      parseInt(scrollHeight - scrollTop) == clientHeight &&
      data?.length < total
    ) {
      fetchData(page + 1);
    }
  };
  useEffect(() => {
    fetchData(page);
  }, []);

  return (
    <>
      <div
        onScroll={handleScroll}
        style={{ overflow: "auto", height: tableHeight.height }}
      >
        <Table
          className="lazyLoadTable"
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          responsive={true}
          size={"large"}
          sticky={true}
          showSorterTooltip={false}
        />
      </div>
      <div className="table-loader">{loader(loading)}</div>
    </>
  );
};
export default LazyLoadTable;
