import { createSlice } from "@reduxjs/toolkit";

export const memeberSlice = createSlice({
  name: "members",
  initialState: {
    loading: false,
    member: null,
    memberTableData: [],
    reRenderTable: 1,
    actionButtonDisable: false,
    memberDetail: null,
    pageLoading: false,
    selectedData: null,
    selectedOrganization: null,
    renderMemberDetaiPage: 1,
    schedules: [],
    memberAddEditForm: {
      show: false,
      formType: null,
      isSalonCreate: 0,
    },
    sorting: {
      column: "id",
      sortBy: "desc",
    },
    formData: {
      addedMembers: [],
    },
  },
  reducers: {
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setMemberAddEditForm: (state, action) => {
      state.memberAddEditForm = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMember: (state, action) => {
      state.member = action.payload;
    },
    setMemberTableData: (state, action) => {
      state.memberTableData = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setMemberDetail: (state, action) => {
      state.memberDetail = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setAddMember: (state, action) => {
      state.formData.addedMembers = action.payload;
    },

    setRemoveMember: (state, action) => {
      let data = [...state.formData.addedMembers];
      const index = data.findIndex((obj) => obj.id == action.payload);
      data.splice(index, 1);
      state.formData.addedMembers = data;
    },
    setSchedules: (state, action) => {
      state.schedules = action.payload;
    },
    setRenderMemberDetaiPage: (state, action) => {
      state.renderMemberDetaiPage = action.payload;
    },
  },
});

export const {
  setLoading,
  setMember,
  setMemberTableData,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setMemberDetail,
  setPageLoading,
  setMemberAddEditForm,
  setSelectedData,
  setSelectedOrganization,
  setSchedules,
  setRenderMemberDetaiPage,
} = memeberSlice.actions;

export default memeberSlice.reducer;
