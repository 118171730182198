import { createSlice } from "@reduxjs/toolkit";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    currentSubscriptionDetail: null,
    canceledSubscriptionID: null,
    checkingSubscription: true,
    isLoadingPackagesList: false,
    isCreatingPaymentLink: false,
    callCheckSubscriptionAPI: 1,
    subscriptionStatus: null,
    packages: [],
  },
  reducers: {
    setCallCheckSubscriptionAPI: (state, action) => {
      state.callCheckSubscriptionAPI = action.payload;
    },
    setCurrentSubscriptionDetail: (state, action) => {
      state.currentSubscriptionDetail = action.payload;
    },
    setCheckingSubscription: (state, action) => {
      state.checkingSubscription = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setIsLoadingPackagesList: (state, action) => {
      state.isLoadingPackagesList = action.payload;
    },
    setIsCreatingPaymentLink: (state, action) => {
      state.isCreatingPaymentLink = action.payload;
    },
    setSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload;
    },
    setCanceledSubscriptionID: (state, action) => {
      state.canceledSubscriptionID = action.payload;
    },
  },
});

export const {
  setCurrentSubscriptionDetail,
  setCheckingSubscription,
  setPackages,
  setIsLoadingPackagesList,
  setIsCreatingPaymentLink,
  setCallCheckSubscriptionAPI,
  setSubscriptionStatus,
  setCanceledSubscriptionID,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
