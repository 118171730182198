import { Progress, Popover } from "antd";
import ResponsesToolTip from "./ResponsesToolTip";
import { useEffect, useState } from "react";
import { Assets } from "../../constants/images";
import QuestionResponseUsersListPopup from "./QuestionResponseUsersListPopup";

const ShortQuestionView = ({ data, index, assignedUsers, detailPageType }) => {
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.classList.remove("overlay-class");
    });
  });

  let answers = [];

  if (detailPageType == "survey") {
    answers = data.surveyQuestionAnswers;
  } else if (detailPageType == "quiz") {
    answers = data.questionAnswers;
  }

  return (
    <>
      <div className="survey-border-wrapper">
        <div className="survey-answer-box">
          <div className="question-box">
            <h3>
              {index + 1}. {data.question}
              {/* <span> ({answers.length} Responses)</span> */}
            </h3>
          </div>
          <div className="answer-box">
            {answers.map((elem) => {
              let answeredUsers = [];

              if (detailPageType == "survey") {
                answeredUsers = elem.userSurveyAnswers;
              } else if (detailPageType == "quiz") {
                answeredUsers = elem.userQuizAnswer;
              }

              return (
                <div className="each-response-div">
                  <h5 className="purple-color">
                    {elem?.user?.name} <span className="grey-color">said:</span>
                  </h5>
                  <div className="text-survey-div">
                    <p>“{elem?.answer}”</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortQuestionView;
