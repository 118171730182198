import { Avatar, notification, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import CreateNewHireModal from "../../components/Modals/Teams/CreateNewHIreModal";
import { Assets } from "../../constants/images";
import {
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import {
  DeleteNewHireCheckList,
  GetSingleNewHireCheckList,
} from "../../services/team/newHireChecklist";
import {
  setActionButtonDisable,
  setNewHireCheckListAddEditForm,
  setPageLoading,
  setSelectedData,
} from "../../store/slices/newHireChecklistSlice";
import { link_types, SHARE_URL } from "../../utils/constants";
import { assignOnBoardingURL, toastMessage } from "../../utils/helper";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ProfileHoverChat from "../../components/Common/ProfileHoverChat";
import { setStatUserHover } from "../../store/slices/globalSlice";

const HireDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    actionButtonDisable,
    selectedData,
    reRenderNewHireDetailPage,
    pageLoading,
    newHireCheckListAddEditForm,
  } = useSelector((state) => state.newHireCheckList);
  const { positions, statUserHover } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const onDeleteNewHiringCheckList = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const resp = await DeleteNewHireCheckList(id);
      const { data, message, status } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      navigate("/team/onboarding");
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const onInitGetNewHiringCheckDetail = async () => {
    try {
      dispatch(setPageLoading(true));
      const response = await GetSingleNewHireCheckList(id);
      const { data, message, status } = response.data;
      dispatch(setSelectedData(data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setPageLoading(false));
    }
  };
  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };
  useEffect(() => {
    onInitGetNewHiringCheckDetail();
  }, [reRenderNewHireDetailPage]);

  if (pageLoading || !selectedData) {
    return <LoadingScreen child={true} />;
  }

  return (
    <Row>
      <Col lg={8} className={"pe-0"}>
        <div className="leftEventDetailWrapper">
          <div className="eventDetailHeader innerContainer d-flex justify-content-between">
            <div className="backToEvent">
              <NavLink to="/team/onboarding">
                <PurpleBackIcon />
                <span className="uLineHover">Back to New Hire Checklist</span>
              </NavLink>
            </div>
            <div className="shareButton">
              <Space size={"large"}>
                <div
                  className="icon-text-wrapper cursor-pointer blackLine"
                  onClick={() =>
                    setShowModal((prevState) => ({
                      ...prevState,
                      shareModal: true,
                    }))
                  }
                >
                  <ShareIcon />
                  <p className="uLine ">Share</p>
                </div>
                <div
                  className="icon-text-wrapper cursor-pointer blackLine"
                  onClick={() => {
                    dispatch(
                      setNewHireCheckListAddEditForm({
                        show: true,
                        formType: "update",
                      }),
                    );
                  }}
                >
                  <EditIcon />
                  <p className="uLine ">Edit</p>
                </div>
                <div className="icon-text-wrapper deleteLabel cursor-pointer">
                  <RecycleBin />
                  <p
                    className="uLine redLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        deleteModal: true,
                      }))
                    }
                  >
                    Delete
                  </p>
                </div>
              </Space>
            </div>
          </div>
          <div className="eventDetailBody">
            <div className="innerContainer">
              <h3 className="sec-heading">{selectedData?.name || "-"}</h3>
              <div className="eventDetailInformation">
                <div className="eventDetailItem">
                  <h5 className="head">Description</h5>
                  <p>
                    {selectedData.description ? selectedData.description : "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Task Link</h5>
                  <p className="purple-link truncate">
                    {link_types.external == selectedData.link_type &&
                    selectedData.link &&
                    selectedData.link ? (
                      selectedData.link
                    ) : link_types.internal == selectedData.link_type ? (
                      <NavLink
                        to={assignOnBoardingURL(
                          selectedData.link_category,
                          selectedData.link_id,
                        )}
                      >
                        {selectedData?.meta?.link_data?.name
                          ? selectedData?.meta?.link_data?.name
                          : selectedData?.meta?.link_data?.title
                            ? selectedData?.meta?.link_data?.title
                            : "-"}
                      </NavLink>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>

                <div className="eventDetailItem">
                  <h5 className="head">Created By</h5>
                  <p>{selectedData.user.name}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Created On</h5>
                  <p>
                    {selectedData &&
                      moment(selectedData.created_at).format("MMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={4} className={"p-0"}>
        <div className="rightEventDetailWrapper">
          <div className="eventDetailHeader d-flex justify-content-between">
            <h4 className="sec-heading m-0 p-0">Task Status</h4>
          </div>
          <div className="eventDetailBody">
            <div className="response-card">
              <div className="response-header">
                <h6>Complete ({selectedData.completedUsers.length})</h6>
              </div>
              <div className="response-body">
                {selectedData.completedUsers.map((elem) => (
                  <NavLink
                    to={`/team/members/${elem?.user?.slug ?? elem?.user?.id}`}
                  >
                    <div
                      style={{ position: "relative" }}
                      className="avatar-item"
                    >
                      <div
                        className="statUserHoverContainer customHover"
                        onMouseEnter={(e) => {
                          dispatch(
                            setStatUserHover({
                              id: elem?.user?.id,
                              name: elem?.user?.name,
                              image: elem?.user?.image,
                            }),
                          );
                        }}
                        onMouseLeave={() => {
                          dispatch(setStatUserHover(null));
                        }}
                      >
                        <ProfileHoverChat
                          user={{
                            id: elem?.user?.id,
                            name: elem?.user?.name,
                            image: elem?.user?.image,
                            position: returnPosition(elem?.user?.position_id),
                          }}
                          show={
                            !!(
                              statUserHover &&
                              statUserHover.id == elem?.user?.id
                            )
                          }
                        />
                        <Avatar
                          size={38}
                          icon={
                            <img
                              src={elem?.user?.image || Assets.EventImg1}
                              alt={"avatar img"}
                            />
                          }
                        />
                      </div>
                      <p>{elem?.user?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="response-card">
              <div className="response-header">
                <h6>Incomplete ({selectedData.incompleteUsers.length})</h6>
              </div>
              <div className="response-body">
                {selectedData.incompleteUsers.map((elem) => (
                  <NavLink
                    to={`/team/members/${elem?.user?.slug ?? elem?.user?.id}`}
                  >
                    <div
                      style={{ position: "relative" }}
                      className="avatar-item"
                    >
                      <div
                        className="statUserHoverContainer customHover"
                        onMouseEnter={(e) => {
                          dispatch(
                            setStatUserHover({
                              id: elem?.user?.id,
                              name: elem?.user?.name,
                              image: elem?.user?.image,
                            }),
                          );
                        }}
                        onMouseLeave={() => {
                          dispatch(setStatUserHover(null));
                        }}
                      >
                        <ProfileHoverChat
                          user={{
                            id: elem?.user?.id,
                            name: elem?.user?.name,
                            image: elem?.user?.image,
                            position: returnPosition(elem?.user?.position_id),
                          }}
                          show={
                            !!(
                              statUserHover &&
                              statUserHover.id == elem?.user?.id
                            )
                          }
                        />
                        <Avatar
                          size={38}
                          icon={
                            <img
                              src={elem?.user?.image || Assets.EventImg1}
                              alt={"avatar img"}
                            />
                          }
                        />
                      </div>
                      <p>{elem?.user?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Share Modal */}
        <ShareModal
          show={showModal.shareModal}
          onHide={() => {
            setShowModal((prevState) => ({ ...prevState, shareModal: false }));
          }}
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Onboarding Task"}
          shareLink={SHARE_URL.ONBOARDING + selectedData?.id}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this goal with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Advanced Balayage Master Class",
            para1:
              "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
            para2: "Sep 12, 2023 @ 9:00 AM EST",
          }}
          data={{
            image: selectedData?.image,
            name: selectedData?.name,
            description: selectedData?.description,
            start_date: selectedData
              ? moment(selectedData.created_at).format("MMM DD, yyyy")
              : "",
            start_time: selectedData
              ? moment(selectedData.created_at).format("hh:mm a")
              : "",
          }}
        />

        {/* Delete Modal */}
        <DeleteModal
          handleDelete={onDeleteNewHiringCheckList}
          disable={actionButtonDisable}
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Onboarding Task?"
          para={
            <>
              Are you sure you want to delete
              <b> {selectedData?.name || "this checklist"}</b>
              <br />
              Important: This resource cannot be recovered.
            </>
          }
          notificationMessage="Onboarding task"
        />

        <GenericModal
          show={newHireCheckListAddEditForm.show}
          onHide={() => {
            dispatch(
              setNewHireCheckListAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setNewHireCheckListAddEditForm({
                show: e,
                formType: null,
              }),
            );
          }}
          modalTitle={
            newHireCheckListAddEditForm.formType == "update"
              ? "Edit Onboarding Task"
              : "Add Onboarding Task"
          }
          size={"lg"}
          bodyComponent={<CreateNewHireModal />}
        />
      </Col>
    </Row>
  );
};

export default HireDetailPage;
