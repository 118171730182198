import React from "react";
import { alert_type } from "../../../../utils/constants";
import {
  ImmediateIcon,
  RecurringIcon,
  ScheduledIcon,
} from "../../../../constants/svg";
import { Col, Row } from "react-bootstrap";

const Step1 = (props) => {
  return (
    <div className="main-push-alert mb-20 mt-3">
      <Row>
        <Col xs={4}>
          <div
            className={`main-alert-box ${props?.selectedAlertType == alert_type?.immediate ? "selected" : ""}`}
            onClick={() => props?.handleAlertTypeChange(alert_type?.immediate)}
          >
            <div className="icon-div">
              <ImmediateIcon />
            </div>
            <h3>Immediate</h3>
            <p>Send a one-time alert immediately.</p>
          </div>
        </Col>

        <Col xs={4}>
          <div
            className={`main-alert-box ${props?.selectedAlertType == alert_type?.scheduled ? "selected" : ""}`}
            onClick={() => props?.handleAlertTypeChange(alert_type?.scheduled)}
          >
            <div className="icon-div">
              <ScheduledIcon />
            </div>
            <h3>Scheduled</h3>
            <p>Send a one-time alert on a future date.</p>
          </div>
        </Col>

        <Col xs={4}>
          <div
            className={`main-alert-box ${props?.selectedAlertType == alert_type?.recurring ? "selected" : ""}`}
            onClick={() => props?.handleAlertTypeChange(alert_type?.recurring)}
          >
            <div className="icon-div">
              <RecurringIcon />
            </div>
            <h3>Recurring</h3>
            <p>Repeat an alert weekly, monthly or annually.</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
