import { Drawer, Dropdown, notification, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SendMessageModal from "../../components/Common/SendMessageModal";
import DeleteMemberModal from "../../components/Modals/DeleteMemberModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import { Assets } from "../../constants/images";
import {
  ArrowTriangleDown,
  CircleTickPurple,
  DrawerCloseIcon,
  EditProfileIcon,
  EditScheduleIcon,
  MessageIcon,
  PurpleBackIcon,
  RecycleBin,
  ToastCrossIcon,
  YellowStarIcon,
} from "../../constants/svg";
import {
  DeleteMember,
  GetMemberDetailByID,
  GetUserSchedules,
  UpdateMemberRole,
} from "../../services/team/member";
import { setCallSalonByIDAPI } from "../../store/slices/dashboardSlice";
import {
  setAddRemoveMemberInSendChatPopup,
  setSendChatSelectedUsers,
  setShowHideChatModel,
} from "../../store/slices/globalSlice";
import {
  setActionButtonDisable,
  setMemberAddEditForm,
  setPageLoading,
  setRenderMemberDetaiPage,
  setReRenderTable,
  setSchedules,
  setSelectedData,
} from "../../store/slices/memberSlice";
import {
  roles as salonRoles,
  roles,
  status as salonStatus,
} from "../../utils/constants";
import { formatPhoneNumber, toastMessage } from "../../utils/helper";
import ScheduleDetail from "./ScheduleDetail";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { deleteFirebaseUser } from "../../services/chats/frontend-chats";
import AvatarImage from "../../components/Common/AvatarImage";

const roleOption = [
  {
    key: uuidv4(),
    value: roles.admin,
    label: (
      <div className="dropdownBox">
        <div className="head memb">
          <h6>Admin</h6>
          <div className="icon">
            <CircleTickPurple />
          </div>
        </div>
        <p className="gray">
          Can access the admin panel to manage billing, users and more.
        </p>
      </div>
    ),
  },
  {
    key: uuidv4(),
    value: roles.member,
    label: (
      <div className="dropdownBox">
        <div className="head memb">
          <h6>Member</h6>
          <div className="icon">
            <CircleTickPurple />
          </div>
        </div>
        <p className="gray">
          Can interact and engage with content from the mobile app.
        </p>
      </div>
    ),
  },
];

const MemberDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
  });

  const {
    selectedData,
    pageLoading,
    actionButtonDisable,
    reRenderTable,
    memberAddEditForm,
    renderMemberDetaiPage,
  } = useSelector((state) => state.member);
  const { callSalonByIDAPI } = useSelector((state) => state.dashboard);
  const { me } = useSelector((state) => state.auth);

  const [openChat, setOpenChat] = useState(false);
  const [openConfirmUpdateMemberModal, setOpenConfirmUpdateMemberModal] =
    useState(false);
  const [roleIdForUpdate, setRoleIdForUpdate] = useState(null);
  const [
    confirmMemberRoleUpdateMemberDisable,
    setConfirmMemberRoleUpdateMemberDisable,
  ] = useState(false);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);

  const showDefaultDrawer = () => {
    setOpen(true);
  };
  const showDefaultDrawerSchedule = () => {
    setOpenSchedule(true);
  };

  const onCloseSchedule = () => {
    setOpenSchedule(false);
  };
  const [selectedOption, setSelectedOption] = useState({
    category: null,
  });

  useEffect(() => {
    reset({});
  }, []);

  useEffect(() => {}, [openChat]);

  const handleDelete = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const response = await DeleteMember(selectedData.id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      await deleteFirebaseUser(selectedData.id);
      dispatch(setCallSalonByIDAPI(callSalonByIDAPI + 1));
      setShowModal({ ...showModal, deleteModal: false });
      dispatch(setReRenderTable(reRenderTable + 1));
      navigate("/team/members");
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const onInitGetMemberDetailByID = async () => {
    try {
      dispatch(setPageLoading(true));
      const response = await GetMemberDetailByID(id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setSelectedData(data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const onInitGetUserSchedules = async () => {
    try {
      const response = await GetUserSchedules({
        is_paginated: 0,
        user_id: selectedData.id,
        "order-column": "day",
        order: "asc",
      });

      const { data, message, status } = response.data;

      if (!status) {
        toastMessage("error", message);
        return;
      }

      const scheduleModified = data.map((elem) => {
        return {
          availability: elem.availability,
          day: elem.day,
        };
      });

      const monday = scheduleModified.find((elem) => elem.day == 1);
      if (!monday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 1,
        });
      }

      const tuesday = scheduleModified.find((elem) => elem.day == 2);
      if (!tuesday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 2,
        });
      }

      const wednessday = scheduleModified.find((elem) => elem.day == 3);
      if (!wednessday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 3,
        });
      }

      const thursday = scheduleModified.find((elem) => elem.day == 4);
      if (!thursday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 4,
        });
      }

      const friday = scheduleModified.find((elem) => elem.day == 5);
      if (!friday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 5,
        });
      }

      const saturday = scheduleModified.find((elem) => elem.day == 6);
      if (!saturday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 6,
        });
      }

      const sunday = scheduleModified.find((elem) => elem.day == 7);
      if (!sunday) {
        scheduleModified.push({
          availability: salonStatus.inactive,
          day: 7,
        });
      }

      dispatch(setSchedules(scheduleModified));
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  const handleConfirmMemberUpdateRole = (roleId) => {
    setRoleIdForUpdate(roleId);
    setOpenConfirmUpdateMemberModal(true);
  };

  const handleUpdateUserRole = async () => {
    try {
      setConfirmMemberRoleUpdateMemberDisable(true);
      const response = await UpdateMemberRole(selectedData.id, roleIdForUpdate);
      const { message, data, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setRenderMemberDetaiPage(renderMemberDetaiPage + 1));
      setRoleIdForUpdate(null);
      setOpenConfirmUpdateMemberModal(false);
      toastMessage("success", "Role Updated Successfully!");
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setConfirmMemberRoleUpdateMemberDisable(false);
    }
  };

  useEffect(() => {
    if (selectedData) {
      onInitGetUserSchedules();
    }
  }, [selectedData]);

  useEffect(() => {
    onInitGetMemberDetailByID();
  }, [renderMemberDetaiPage]);

  if (pageLoading || !selectedData) {
    return <LoadingScreen child={true} />;
  }
  return (
    <>
      <Row>
        <Col lg={12} className={"pe-0"}>
          <div className="leftMemberDetailWrapper">
            <div className="memberDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToMembers">
                {GetAuthUserLocalStorage()?.roles?.[0]?.id !=
                  salonRoles.super_admin && (
                  <NavLink to="/team/members">
                    <PurpleBackIcon />
                    <span className="uLineHover">Back to Members</span>
                  </NavLink>
                )}
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  {selectedData.id != me?.data?.id ? (
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={() => {
                        dispatch(setAddRemoveMemberInSendChatPopup(false));
                        dispatch(
                          setSendChatSelectedUsers([
                            {
                              value: selectedData.id,
                              label: selectedData.name,
                              email: selectedData.email,
                              image: selectedData.image,
                            },
                          ]),
                        );
                        dispatch(setShowHideChatModel(true));
                      }}
                    >
                      <MessageIcon />
                      <p className="uLine">Send Chat</p>
                    </div>
                  ) : null}
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      dispatch(
                        setMemberAddEditForm({
                          show: true,
                          formType: "update",
                          isSalonCreate: 0,
                        }),
                      );
                    }}
                  >
                    <EditProfileIcon />
                    <p className="uLine">Edit Profile</p>
                  </div>
                  {GetAuthUserLocalStorage().roles?.[0].id !=
                    salonRoles.super_admin && (
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={showDefaultDrawerSchedule}
                    >
                      <EditScheduleIcon />
                      <p className="uLine">Edit Schedule</p>
                    </div>
                  )}
                  <div
                    className="icon-text-wrapper deleteLabel cursor-pointer"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        deleteModal: true,
                      }))
                    }
                  >
                    <RecycleBin />
                    <p className="uLine redLine">Delete</p>
                  </div>
                </Space>
              </div>
            </div>
          </div>

          <div className="main-member-body">
            <div className="member-info">
              <div>
                <AvatarImage
                  name={selectedData.name}
                  src={selectedData.image}
                  placeholder={Assets.ProfilePlaceholder}
                  size={70}
                />
              </div>
              <div className="member-name">
                <h3>{selectedData.name}</h3>
                <p>
                  Member since{" "}
                  {moment(selectedData.created_at)
                    .local()
                    .format("MMM DD, YYYY")}
                </p>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="">
                <div className="main-member-detail">
                  <div className="left-detail">
                    <ul className="main-first-list">
                      <li>Position</li>
                      <li>Location </li>
                      <li>Hire Date</li>
                      <li>Phone</li>
                      <li>Email </li>
                      <li>Role </li>
                    </ul>
                    <ul className="main-second-list">
                      <li>{selectedData.position?.name || <span>-</span>}</li>
                      <li>{selectedData?.location?.name || <span>-</span>}</li>
                      <li>
                        {selectedData.hire_date ? (
                          moment(selectedData.hire_date).format("MMM DD, YYYY")
                        ) : (
                          <span>-</span>
                        )}
                      </li>
                      <li>
                        {selectedData?.phone ? (
                          formatPhoneNumber(selectedData?.phone)
                        ) : (
                          <span>-</span>
                        )}
                      </li>
                      <li>{selectedData?.email || <span>-</span>}</li>
                      <li className="memberDropdownBox">
                        {" "}
                        <div className="form-control-dropdown">
                          {selectedData?.isOwner ? (
                            <Dropdown
                              // menu={{
                              //   items,
                              // }}
                              overlayClassName="singleDropdown"
                              className="singLock"
                              placement="bottom"
                              open={false}
                            >
                              <Space>Owner</Space>
                            </Dropdown>
                          ) : (
                            <Select
                              className="withoutDropdown memberDropdown"
                              suffixIcon={<ArrowTriangleDown />}
                              value={
                                selectedData?.roles?.[0]?.id ?? roles.member
                              }
                              onChange={(e) => {
                                handleConfirmMemberUpdateRole(e);
                              }}
                              popupClassName={
                                "customDropdownMenu admindropdown"
                              }
                              options={roleOption}
                              placement="bottomLeft"
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-detail">
                    {/* <ul className='main-first-list'>
                                    <li><YellowStarIcon /> Total Reward Points</li>
                                    <li><YellowStarIcon /> Approved Class Hours</li>
                                    <li><YellowStarIcon /> Candidates Referred</li>
                                    <li><YellowStarIcon /> Total Reimbursements</li>

                                </ul>
                                <ul className='main-second-list'>
                                    <li>2,000</li>
                                    <li>66</li>
                                    <li>1</li>
                                    <li>1</li>

                                </ul> */}
                    <div className="sub-div">
                      <h5>
                        <span>
                          <YellowStarIcon />
                        </span>{" "}
                        Total Reward Points
                      </h5>
                      <p>{selectedData.totalPoints}</p>
                    </div>
                    <div className="sub-div">
                      <h5>
                        <span>
                          <YellowStarIcon />
                        </span>{" "}
                        Approved Class Hours
                      </h5>
                      <p>{selectedData?.meta?.total_hours ?? "-"}</p>
                    </div>
                    <div className="sub-div">
                      <h5>
                        <span>
                          <YellowStarIcon />
                        </span>{" "}
                        Candidates Referred
                      </h5>
                      <p>{selectedData?.meta?.total_referrals ?? "-"}</p>
                    </div>
                    <div className="sub-div">
                      <h5>
                        <span>
                          <YellowStarIcon />
                        </span>{" "}
                        Total Reimbursements
                      </h5>
                      <p>${selectedData?.meta?.total_amount ?? "0"}</p>
                    </div>
                  </div>
                </div>
                <div className="main-bio">
                  <h2>Member Bio</h2>
                  <p>{selectedData?.bio || "-"}</p>
                </div>
                <div className="main-badges">
                  <h2>Badges</h2>
                  <div className="badges-div">
                    {selectedData?.badges && selectedData?.badges.length > 0 ? (
                      selectedData.badges.map((item, key) => (
                        <div className="badgeDiv" key={key}>
                          <img src={item.badge} alt="" className="img-fluid" />
                        </div>
                      ))
                    ) : (
                      <span>Check back soon!</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Delete Modal */}

      <DeleteMemberModal
        show={showModal.deleteModal}
        handleDelete={handleDelete}
        disable={actionButtonDisable}
        onHide={() => {
          setShowModal({ ...showModal, deleteModal: false });
        }}
        text="Member"
        member={selectedData}
        pageType="detail"
      />
      {/*       
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => {
          setmemberForDelete(null);
          setShowModal({ ...showModal, deleteModal: false })
        }}
        heading="Delete Member?"
        para={
          <>
            Are you sure you want to delete <b>Abram Calzoni</b> from your
            organizaton?
            <br />
            Important: This person cannot be recovered, however some of their
            history and activity may still appear in your workspace.
          </>
        }
        notificationMessage="Member"
      /> */}
      <Drawer
        title="Edit Schedule"
        placement="right"
        className="custom-drawer edittitle editsch"
        onClose={onCloseSchedule}
        open={openSchedule}
        width={471}
        closable={false}
        extra={
          <Space>
            <div className="cursor-pointer" onClick={onCloseSchedule}>
              <DrawerCloseIcon />
            </div>
          </Space>
        }
      >
        <ScheduleDetail onCloseSchedule={onCloseSchedule} />
      </Drawer>

      <SendMessageModal />

      <DeleteModal
        deleteText="Update"
        show={openConfirmUpdateMemberModal}
        onHide={() => setOpenConfirmUpdateMemberModal(false)}
        heading="Update Role?"
        handleDelete={handleUpdateUserRole}
        disable={confirmMemberRoleUpdateMemberDisable}
        para={
          <>
            Changing the role of this user will change their permissions.
            <br />
            Are you sure you want to update their role?
          </>
        }
        notificationMessage="Member"
      />
    </>
  );
};

export default MemberDetail;
// comment
