import {
  Button,
  Dropdown,
  Input,
  List,
  Modal,
  notification,
  Popover,
  Space,
} from "antd";
import Picker from "emoji-picker-react";
import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { BsStopCircle } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Assets } from "../../../constants/images";
import moment from "moment";
import {
  AcrobatIcon,
  BinocularsLight,
  ClipIcon,
  MoreIcon,
  RecycleBin,
  SendButtonTriangle,
  SmileIcon,
  ToastCrossIcon,
} from "../../../constants/svg";
import {
  deleteThread,
  getChannelMessages,
  sendMessage,
} from "../../../services/chats/frontend-chats";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { message_type, roles as salonRoles } from "../../../utils/constants";
import {
  blobToFile,
  renderContentWithLinks,
  salonUploadToS3,
  toastMessage,
} from "../../../utils/helper";
import NewChat from "./NewChat";
import DeleteModal from "../../Modals/DeleteModal";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { AiFillFile } from "react-icons/ai";
import AvatarImage from "../../Common/AvatarImage";
import ChatStatusIcon from "../../Common/ChatStatusIcon";
import { find, head, isEmpty, toString } from "lodash";
import ChatProfileHover from "../../Common/ChatProfileHover";
import { setSelectedUser } from "../../../store/slices/chatsSlice";
import EveryOneImage from "../../../assets/images/everyone.svg";

const actionMenu = (user, setConfirmDeleteChatPopup, channelMessages) => {
  return [
    {
      key: uuidv4(),
      label: (
        <>
          {Object.keys(user).length > 0 && (
            <NavLink
              to={`${user?.is_group_chat ? `/team/groups/${user?.group_id}` : `/team/members/${user?.receiver_profile[0]?.id}`}`}
              onClick={(e) => {
                if (
                  user.is_user_group ||
                  user?.receiver_profile?.[0]?.is_deleted
                ) {
                  e.preventDefault();
                }
              }}
              className={"black"}
              disabled={
                user.is_user_group || user?.receiver_profile?.[0]?.is_deleted
              }
            >
              <BinocularsLight />
              View Profile
            </NavLink>
          )}
        </>
      ),
    },
    /*    {
          key: uuidv4(),
          className: user?.is_group_chat ? "d-none" : "d-block",
          label: (
            <>
              {
                user?.is_group_chat ?
                  <></>
                    : null
                 /!* <NavLink to="/team/groups" className={"black"}>
                    <div className="d-flex align-items-center w-100">
                      <PlusIconLight />
                      Add to group
                    </div>
                  </NavLink>*!/
              }
            </>
          ),
        }, */
    {
      key: uuidv4(),
      label: (
        <div
          className={"deleteLabel"}
          onClick={() => {
            setConfirmDeleteChatPopup(true);
          }}
        >
          <RecycleBin />
          Delete Thread
        </div>
      ),
    },
  ];
};

const ChatWindow = (props) => {
  const [message, setMessage] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const messagesEndRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pickerRef = useRef();
  const { me } = useSelector((state) => state.auth);
  const inputRef = useRef(null);
  const { channelMessages, selectedUser, userListing } = useSelector(
    (state) => state?.chats,
  );

  const findChatUser = (userId) => {
    const users = userListing?.data;
    return find(
      users,
      ({ receiver_profile, is_group_chat }) =>
        !is_group_chat && head(receiver_profile)?.id === `${userId}`,
    );
  };
  const handleItemClick = (user) => {
    dispatch(setSelectedUser(user));
  };

  const handleClickEmptyHref = (e) => {
    if (!selectedUser?.is_group_chat) {
      navigate(
        `/team/members/${
          selectedUser?.receiver_profile[0]?.slug ??
          selectedUser?.receiver_profile[0]?.id
        }`,
      );
    }
  };
  const [onDelete, setOnDelete] = useState(1);
  const [file, setFile] = useState([]);
  const fileRef = useRef();
  const [confirmDeleteChatPopup, setConfirmDeleteChatPopup] = useState(false);
  const mediaRecorder = useRef(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [channelSubscription, setChannelSubscription] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disableSendChatBtn, setDisableSendChatBtn] = useState(false);

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [srcPreview, setSrcPreview] = useState("");

  let lastTimestamp = null;
  let lastDateTimestamp = null;
  let newTimestamp = null;
  let showTimestamp = true;
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickImage = (src) => {
    setIsOpenPreview(true);
    setSrcPreview(src);
  };

  const handleCancelPreview = () => {
    setIsOpenPreview(false);
  };

  const renderMessageContent = (item) => {
    // Implement logic to handle different types of message content, such as emojis, voice messages, and files
    if (item?.message_type == message_type?.text) {
      return <p className={"m-0"}>{renderContentWithLinks(item?.text)}</p>;
    } else if (item?.message_type == message_type?.image) {
      const imageSrc = item?.image_url || Assets.GeneralPlaceholder;
      return (
        <img
          style={{
            width: "180px",
            height: "180px",
            borderRadius: "8px",
            objectFit: "cover",
            objectPosition: "top",
            border: "1px solid #eee",
            cursor: "pointer",
          }}
          className={"mx-3"}
          src={imageSrc}
          onError={(e) => (e.target.src = Assets.GeneralPlaceholder)}
          alt=""
          onClick={() => handleClickImage(imageSrc)}
        />
      );
    } else if (item?.message_type == message_type?.video) {
      return (
        <video
          className={"chat-video mx-3"}
          style={{ width: "auto", height: "200px" }}
          controls
        >
          <source src={item?.video_url} />
        </video>
      );
    } else if (item?.message_type == message_type?.audio) {
      return (
        <audio
          className={"mx-3"}
          style={{ width: "300px" }}
          src={item?.audio_url}
          controls
        ></audio>
      );
    } else if (item?.message_type == message_type?.media) {
      return (
        <a
          href={item?.media_url}
          className={"file-content mx-3"}
          style={{ width: "300px" }}
          target="_blank"
          download
          rel="noreferrer"
        >
          <span className="file-content-icon">
            <AcrobatIcon />
          </span>
          <span className="file-content-name">{item?.name || "File"}</span>
        </a>
      );
    }
  };

  const returnUserDataInGroupChat = (item) => {
    const sender_id = item?.sender_id;
    if (sender_id == authUser?.id) {
      return { name: authUser?.name, image: authUser?.image, isDeleted: false };
    }

    const profile = selectedUser.receiver_profile
      .filter((el) => !!el)
      .find(({ id }) => id == sender_id);

    return {
      name: profile?.name ?? "DELETED USER",
      image: profile?.image,
      isDeleted: !!profile,
    };
  };

  const renderMessageItem = (item, index) => {
    if (item?.created_at) {
      const date = item?.created_at.toDate();
      const currentDate = moment();
      const yesterdayDate = moment().subtract(1, "days");
      let formattedDate, newdate;
      if (moment(date).isSame(currentDate, "day")) {
        formattedDate = "Today, " + moment(date).format("hh:mm A");
        newdate = "Today";
      } else if (moment(date).isSame(yesterdayDate, "day")) {
        formattedDate = "Yesterday, " + moment(date).format("hh:mm A");
        newdate = "Yesterday";
      } else {
        formattedDate = moment(date).format("MMMM DD, YYYY, hh:mm A");
        newdate = moment(date).format("MMMM DD, YYYY");
      }
      newTimestamp = newdate;
      if (newdate != lastDateTimestamp) {
        lastTimestamp = formattedDate;
        lastDateTimestamp = newdate;
        showTimestamp = true;
      } else {
        showTimestamp = false;
      }
    }
    const isSentByAdmin = item.sender_id == authUser?.id;
    const messageClassName = isSentByAdmin
      ? "admin-message"
      : "customer-message";
    const userData = returnUserDataInGroupChat(item);
    const foundUserOnlineData = find(selectedUser?.receiver_profile, {
      id: toString(item.sender_id),
    });
    const { online: isOnline } = foundUserOnlineData || {};
    const isLastItem = index === channelMessages?.data?.length - 1;

    const senderId = item?.sender_id;
    const sender = {
      ...userData,
      receiver_profile: [{ ...userData, id: senderId }],
    };
    const senderFullInfo = findChatUser(senderId);
    const onItemClick = () => handleItemClick(senderFullInfo);

    return (
      <>
        {item?.message_type == message_type?.default ? (
          <span className="message-item-timestamp">{item.text}</span>
        ) : selectedUser?.is_group_chat ? (
          <>
            {showTimestamp ? (
              <span className="message-item-timestamp">{lastTimestamp}</span>
            ) : null}
            <List.Item className={messageClassName}>
              {!isSentByAdmin && (
                <div className="chatAvatatWithStatus smallSize">
                  <Popover
                    placement="rightTop"
                    content={
                      <ChatProfileHover
                        user={sender}
                        userPosition={[]}
                        onItemClick={onItemClick}
                        users={[]}
                        username={userData?.name}
                        avatar={userData?.image}
                      />
                    }
                    arrow={true}
                    key={item.id}
                  >
                    <div>
                      <AvatarImage
                        src={userData?.image}
                        name={userData?.name}
                        placeholder={Assets.ProfilePlaceholder}
                        size={40}
                      />
                      <ChatStatusIcon isOnline={isOnline} />
                    </div>
                  </Popover>
                </div>
              )}
              <div className="messageTimeWrapper d-flex">
                <div className="user-name mx-3">{userData?.name}</div>
                {item?.message_type == message_type?.text ? (
                  <div className="message">
                    <div className="message-content">
                      {renderMessageContent(item)}
                    </div>
                  </div>
                ) : (
                  renderMessageContent(item)
                )}

                {/*              {isSentByAdmin && <SeenIcon />}
              {!isSentByAdmin && (
                <div className="message-time">
                  {moment.unix(item?.created_at).format("hh:mm A")}
                </div>
              )} */}
              </div>
              {isSentByAdmin && (
                <AvatarImage
                  src={userData?.image}
                  name={userData?.name}
                  placeholder={Assets.ProfilePlaceholder}
                  size={40}
                />
              )}
            </List.Item>
          </>
        ) : (
          <>
            {showTimestamp ? (
              <span className="message-item-timestamp">{lastTimestamp}</span>
            ) : null}
            <List.Item className={messageClassName}>
              {!isSentByAdmin && (
                <div className="chatAvatatWithStatus smallSize">
                  <AvatarImage
                    name={selectedUser?.receiver_profile[0]?.name}
                    src={selectedUser?.receiver_profile[0].image}
                    placeholder={Assets.ProfilePlaceholder}
                    size={40}
                  />
                  <ChatStatusIcon user={selectedUser} />
                </div>
              )}
              <div className="messageTimeWrapper d-flex">
                <div className="user-name mx-3">{userData?.name}</div>
                {item?.message_type == message_type?.text ? (
                  <div className="message">
                    <div className="message-content">
                      {renderMessageContent(item)}
                    </div>
                  </div>
                ) : (
                  renderMessageContent(item)
                )}

                {/* {isSentByAdmin && <SeenIcon />} */}
                {/* {!isSentByAdmin && (
                <div className="message-time">
                  {moment.unix(item?.created_at).format("hh:mm A")}
                </div>
              )} */}
              </div>
              {isSentByAdmin && (
                <AvatarImage
                  name={authUser?.name}
                  src={authUser.image}
                  placeholder={Assets.ProfilePlaceholder}
                  size={40}
                />
              )}
            </List.Item>
          </>
        )}
        {isLastItem && <div ref={messagesEndRef} />}
      </>
    );
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
  };

  const fetchChannelMessages = async (unsubscribe) => {
    let subscription = await getChannelMessages(
      selectedUser,
      channelMessages?.subscription,
      dispatch,
      me?.data?.id,
    );
  };
  const resetMessage = () => {
    document.getElementById("file-upload-icon").value = "";
    setFile([]);
    setMessage("");
    // fileRef.current = null;
  };

  const uploadFile = async (file) => {
    const file_type = file?.type?.split("/")[0];
    let folder = `${
      GetAuthUserLocalStorage()?.salon?.directory_name || "chat"
    }/`;
    let salonS3UploadResponse = await salonUploadToS3(
      file,
      folder,
      GetAuthUserLocalStorage().roles[0].id != salonRoles.super_admin,
    );
    if (salonS3UploadResponse.status) {
      const url = salonS3UploadResponse?.data?.location;
      return { url, file_type, name: file?.name };
    } else {
      throw new Error(salonS3UploadResponse?.message);
    }
  };

  const handleSendMessage = async () => {
    try {
      setDisableSendChatBtn(true);
      const newMessageWithTxt = {
        created_at: serverTimestamp(),
        id: uuidv4(),
        is_read: false,
        sender_id: authUser?.id,
        image_url: "",
        video_url: "",
        media_url: "",
        text: "",
      };

      const newMessageWjithAttachment = {
        created_at: serverTimestamp(),
        id: uuidv4(),
        is_read: false,
        sender_id: authUser?.id,
        image_url: "",
        video_url: "",
        media_url: "",
        text: "",
      };

      if (message) {
        newMessageWithTxt.text = message;
        newMessageWithTxt.message_type = message_type?.text;
        await sendMessage(selectedUser, newMessageWithTxt);
      }

      if (file?.length) {
        for (let i = 0; i < file.length; i++) {
          const data = await uploadFile(file[i]);
          newMessageWjithAttachment.name = data?.name;
          if (data?.file_type == "image") {
            newMessageWjithAttachment.message_type = message_type?.image;
            newMessageWjithAttachment.image_url = data?.url;
          } else if (data?.file_type == "audio") {
            newMessageWjithAttachment.message_type = message_type?.audio;
            newMessageWjithAttachment.audio_url = data?.url;
          } else if (data?.file_type == "video") {
            newMessageWjithAttachment.message_type = message_type?.video;
            newMessageWjithAttachment.video_url = data?.url;
          } else {
            newMessageWjithAttachment.message_type = message_type?.media;
            newMessageWjithAttachment.media_url = data?.url;
          }
          await sendMessage(selectedUser, newMessageWjithAttachment);
        }
      }
      setDisableSendChatBtn(false);
      resetMessage();
    } catch (e) {
      setDisableSendChatBtn(false);
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const handleDeleteThread = async () => {
    setDisable(true);
    await deleteThread(
      selectedUser,
      channelMessages?.data,
      dispatch,
      onDelete,
      setOnDelete,
    );
    setDisable(false);
    setConfirmDeleteChatPopup(false);
  };

  const startRecording = async () => {
    setIsRecording(true);
    const streamData = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const media = new MediaRecorder(streamData, { type: "audio/webm" });
    // set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    // invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data == "undefined") return;
      if (event.data.size == 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    // stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      // creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
      // creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      const file = blobToFile(audioBlob);
      setFile([file]);
      setAudioChunks([]);
      setIsRecording(false);
    };
  };

  const startTimer = () => {
    setIsRunning(true);
    setTimer(0);
  };

  const stopTimer = () => {
    setIsRunning(false);
    setTimer(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning]);

  useEffect(() => {
    if (isScrolledToBottom) {
      scrollToBottom();
    }
  }, [channelMessages?.data, isScrolledToBottom]);

  useEffect(() => {
    let unsubscribe;
    if (selectedUser && Object.keys(selectedUser)?.length > 0) {
      fetchChannelMessages(unsubscribe);
    }
  }, [selectedUser]);
  useEffect(() => {
    inputRef?.current?.focus();
  }, [channelMessages?.isLoading]);
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    // Trigger a click event on the hidden file input element
    // fileInputRef.current.click();
    document.getElementById("file-upload-icon").click();
  };

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    const totalFiles = [...file, ...newFiles];
    if (totalFiles.length > 10) {
      toastMessage("warning", "Maximum 10 files are allowed!");
      return;
    }
    setFile(totalFiles);
  };

  const handleRemoveFile = (index) => {
    let temp = [...file];
    temp.splice(index, 1);
    setFile(temp);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Click occurred outside of the picker, so close it
        setShowEmojiPicker(false);
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <>
      <div className="chat-window">
        <div className="chat-header">
          <div className="titleBox d-flex align-items-center w-100">
            {props?.newChat ? (
              <NewChat setNewChat={props?.setNewChat} />
            ) : (
              Object.keys(selectedUser)?.length > 0 && (
                <>
                  <AvatarImage
                    name={
                      selectedUser?.is_group_chat
                        ? selectedUser?.name
                        : selectedUser?.receiver_profile[0]?.name
                    }
                    src={
                      selectedUser?.is_group_chat
                        ? EveryOneImage
                        : selectedUser?.receiver_profile[0]?.image
                    }
                    placeholder={Assets.ProfilePlaceholder}
                    size={40}
                  />

                  <h3 className="ms-3 m-0" style={{ fontSize: "22px" }}>
                    {selectedUser?.is_group_chat
                      ? selectedUser?.name
                      : selectedUser?.receiver_profile[0]?.name}
                  </h3>

                  {/* Three Dots */}
                  <Space
                    style={{ position: "absolute", right: "40px" }}
                    size="middle"
                  >
                    <Dropdown
                      menu={{
                        items: actionMenu(
                          selectedUser,
                          setConfirmDeleteChatPopup,
                          channelMessages,
                        ),
                      }}
                      placement="bottomRight"
                      trigger={["click"]}
                      overlayClassName={"defaultDropdown chatDropdown"}
                      // open={true}
                    >
                      <div className="icon cursor-pointer">
                        <MoreIcon />
                      </div>
                    </Dropdown>
                  </Space>
                </>
              )
            )}
          </div>
          {/* Three Dots */}
          <Space size="middle">
            <Dropdown
              menu={{
                items: actionMenu(selectedUser, handleDeleteThread),
              }}
              placement="bottomRight"
              trigger={["click"]}
              overlayClassName={"defaultDropdown chatDropdown"}
              // open={true}
            >
              <div className="icon cursor-pointer">{/* <MoreIcon /> */}</div>
            </Dropdown>
          </Space>
        </div>
        <div className="chatHolder">
          <div
            className={`chat-messages ${file?.length > 0 ? "with-file" : ""}`}
          >
            {channelMessages?.isLoading ? (
              Object.keys(selectedUser)?.length > 0 && (
                <LoadingScreen child={true} />
              )
            ) : (
              // <div
              //   className={`mt-3 load black ${Object.keys(selectedUser)?.length > 0 ? "d-block" : "d-none"
              //     }`}
              // />
              <>
                <List
                  dataSource={channelMessages?.data}
                  renderItem={(item, index) => renderMessageItem(item, index)}
                  onScroll={(e) => {
                    const { scrollTop, scrollHeight, clientHeight } = e.target;
                    setIsScrolledToBottom(
                      scrollTop + clientHeight == scrollHeight,
                    );
                  }}
                  className="chat-winddow__messages-list"
                />
              </>
            )}
          </div>

          <div
            className={`attachment-wrapper ${file?.length > 0 ? "d-block" : "d-none"}`}
          >
            {file?.length > 0 &&
              file.map((item, index) => (
                <div key={index} className={"file-box mt-2"}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="file-name">{item?.name}</span>
                    {/* <span className="media-upload-loader">
                      <Spin/>
                    </span> */}
                    {!disableSendChatBtn && (
                      <span
                        onClick={() => {
                          handleRemoveFile(index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FaTimes />
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {Object.keys(selectedUser).length > 0 && (
            <div
              className="chat-window-input d-flex align-items-center w-100"
              style={{
                overflow: "initial",
              }}
            >
              <div className="upload-box-wrapper d-flex align-items-center">
                <div className="emoji-picker-wrapper wrapper-main-chat-emoji-picker">
                  <Button
                    type="link"
                    icon={<SmileIcon />}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="chatInput-icon"
                  />

                  {showEmojiPicker && (
                    <div className="picker" ref={pickerRef}>
                      <Picker onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>

                <div className="emoji-picker-wrapper">
                  {/* <div */}
                  {/*  className="chatInput-icon" */}
                  {/*  onClick={() => { */}
                  {/*    startRecording(); */}
                  {/*    startTimer(); */}
                  {/*  }} */}
                  {/* > */}
                  {/*  /!* <Image src={Assets.micIcon} alt={"chat-input-icon"} /> *!/ */}
                  {/*  <BlackMicroPhone /> */}
                  {/* </div> */}
                  {/* paperClip */}

                  <div className={"chatInput " + Object.keys(selectedUser)}>
                    <div
                      className="chatInput-icon chatInput-iconModal"
                      onClick={handleIconClick}
                    >
                      <ClipIcon />
                    </div>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      id={"file-upload-icon"}
                      // ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                    {/* Other chat input components */}
                  </div>
                </div>
              </div>

              <div className="messageBox position-relative">
                <Input
                  id={"message-field"}
                  ref={inputRef}
                  value={message}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message here..."
                  // disabled={file ? true : false}
                />
                {isRecording && (
                  <span
                    className="mic-icon"
                    onClick={() => {
                      stopRecording();
                      stopTimer();
                    }}
                  >
                    <span className="me-2 text-grey">{formatTime(timer)}</span>
                    <BsStopCircle className="stop-icon" />
                  </span>
                )}
                <Button
                  onClick={handleSendMessage}
                  type="primary"
                  disabled={disableSendChatBtn}
                  className={isEmpty(message) ? "chatSend" : "chatSend isInput"}
                >
                  {disableSendChatBtn && <div className="load black" />}
                  {!disableSendChatBtn && <SendButtonTriangle />}
                </Button>
              </div>
            </div>
          )}
          {/* <p className="typingPara blue-accent">{selectedUser?.name} is typing ...</p> */}
        </div>
      </div>

      <DeleteModal
        show={confirmDeleteChatPopup}
        onHide={() => setConfirmDeleteChatPopup(false)}
        heading="Delete Chat?"
        handleDelete={handleDeleteThread}
        disable={disable}
        para={<>Are you sure you want to delete chat</>}
        notificationMessage="Event"
      />

      <Modal
        className="chatPreviewModal"
        open={isOpenPreview}
        onCancel={handleCancelPreview}
        footer={null}
        centered
      >
        <img src={srcPreview} alt="" className="chatPreviewImage" />
      </Modal>
    </>
  );
};

export default ChatWindow;
