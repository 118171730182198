import { createSlice } from "@reduxjs/toolkit";

export const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    pagination: {
      perPage: 10,
      page: 1,
    },
    filters: {
      status: null,
    },
    tickets: {
      data: [],
      total: 0,
    },
  },
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export const { setPagination, setFilters, setTickets } = ticketSlice.actions;

export default ticketSlice.reducer;
