import { Progress } from "antd";
import QuestionResponseUsersListPopup from "./QuestionResponseUsersListPopup";

const TrueFalseView = ({ data, index, assignedUsers, detailPageType }) => {
  let totalResponse = 0;
  if (detailPageType == "survey") {
    totalResponse = data.surveyQuestionOptions.reduce((prevVal, currVal) => {
      return prevVal + currVal.userSurveyAnswers.length;
    }, 0);
  } else if (detailPageType == "quiz") {
    totalResponse = data.questionAnswers.reduce((prevVal, currVal) => {
      return prevVal + currVal.userQuizAnswer.length;
    }, 0);
  }

  let answers = [];

  if (detailPageType == "survey") {
    answers = data.surveyQuestionOptions;
  } else if (detailPageType == "quiz") {
    answers = data.questionAnswers;
  }

  const getTrueFalseBarColor = (isCorrect) => {
    let c;
    if (isCorrect == undefined || isCorrect == null) {
      c = "#7B68EE";
    } else if (isCorrect) {
      c = "#04b027";
    } else {
      c = "#ff0054";
    }
    return c;
  };

  return (
    <>
      <div className="survey-border-wrapper">
        <div className="survey-answer-box">
          <div className="question-box">
            <h3>
              {index + 1}. {data.question}
            </h3>
          </div>
          <div className="answer-box">
            {answers.map((elem) => {
              let answeredUsers = [];

              if (detailPageType == "survey") {
                answeredUsers = elem.userSurveyAnswers;
              } else if (detailPageType == "quiz") {
                answeredUsers = elem.userQuizAnswer;
              }

              const answerResponsePercentage =
                (answeredUsers.length / assignedUsers.length) * 100;

              return (
                <div className="each-response-div">
                  <h5>
                    {elem.answer}
                    {elem?.is_correct == 1 && detailPageType == "quiz" ? (
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#a8a8a8",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        (Correct)
                      </span>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="progress-div">
                    <Progress
                      strokeLinecap="butt"
                      percent={answerResponsePercentage}
                      strokeColor={getTrueFalseBarColor(elem?.is_correct)}
                    />
                    {/* <p>{answeredUsers.length}</p> */}
                    <QuestionResponseUsersListPopup
                      detailPageType={detailPageType}
                      questionOptionID={elem.id}
                      questionType="true/false"
                      answeredUserList={answeredUsers}
                      answerResponsePercentage={answerResponsePercentage}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrueFalseView;
