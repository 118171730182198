import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/FormFields/CustomDatePicker";
import CustomSelect from "../../../components/FormFields/CustomSelect";
import { maxBounds, Patterns } from "../../../constants/contants";
import {
  GetAuthUserLocalStorage,
  SetAuthUserLocalStorage,
} from "../../../services/localStorage/localStorage";
import { UpdateUser } from "../../../services/users/users";
import { showNumber, toastMessage } from "../../../utils/helper";
import {
  setCallGetMyProfileAPI,
  setIsPositionsLoading,
} from "../../../store/slices/authSlice";
import { PatternFormat } from "react-number-format";
import {
  roles as salonRoles,
  salonJobType,
  status as salonStatus,
  primaryProductList,
  SOFTWARE,
  TECHNOLOGY,
} from "../../../utils/constants";
import { GetPositions } from "../../../services/positions/positions";
import { setPositions } from "../../../store/slices/globalSlice";
import { notification, Select } from "antd";
import { ToastCrossIcon, ArrowTriangleDown } from "../../../constants/svg";
import { updateFirebaseUser } from "../../../services/chats/frontend-chats";

const EditProfileBio = (props) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const { salon } = useSelector((state) => state.global);
  const [initiatedSegments, setInitiatedSegments] = useState(false);
  const [bookingSoftware, setBookingSoftware] = useState([]);
  const [product, setProduct] = useState([]);
  const [technology, setTechnology] = useState([]);

  const { me, callGetMyProfileAPI } = useSelector((state) => state?.auth);
  const { positions } = useSelector((state) => state?.global);
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const authUser = GetAuthUserLocalStorage();

  const primaryProduct = primaryProductList.map((product) => ({
    value: product,
    label: product,
  }));

  useEffect(() => {
    if (!initiatedSegments && salon) {
      setProduct(salon.products?.map(({ product }) => product));
      setBookingSoftware(salon.softwares?.map(({ name }) => name));
      setTechnology(salon.technologies?.map(({ name }) => name));
      setInitiatedSegments(true);
    }
  }, [initiatedSegments, salon]);

  const getPositions = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: salonStatus.active,
        type: salonJobType.admin,
        order: "asc",
        "order-column": "id",
      };
      const response = await GetPositions(params);
      const { data, message, status } = response.data;

      const positionOptions = data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setPositions(positionOptions));
      dispatch(setIsPositionsLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsPositionsLoading(false));
    }
  };
  useEffect(() => {
    getPositions();
  }, []);
  const handleDisableSubmit = () => !!(disable || !isValid || isSubmitting);

  const onSubmit = async (data) => {
    const apiData = {
      name: data?.name,
      email: data?.email,
      company_name: data?.company_name,
      position_id: data?.position_id,
      phone:
        data?.phone && data?.phone.slice(0, 2) == "+1"
          ? data.phone
          : "+1" + data.phone,
      date_founded: moment(data?.date_founded).format("YYYY-MM-DD"),
      dob: moment(data?.date_of_birth).format("YYYY-MM-DD"),
      product_line: product,
      technology,
      software: bookingSoftware,
    };
    try {
      let salonS3UploadResponse = "";
      setDisable(true);
      /*     if (props?.file?.file) {
             let folder = `${GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
               }/`;
             salonS3UploadResponse = await salonUploadToS3(
               props?.file?.file,
               folder
             );
             if (salonS3UploadResponse.status) {
               apiData.image = salonS3UploadResponse?.data?.location;
             } else {
               throw new Error(salonS3UploadResponse?.message);
             }
           } */
      const res = await UpdateUser(authUser?.id, apiData);
      SetAuthUserLocalStorage(res?.data?.data);
      await updateFirebaseUser(res?.data?.data);
      dispatch(setCallGetMyProfileAPI(callGetMyProfileAPI + 1));
      setDisable(false);
      toastMessage("success", res.data.message);
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  const disabledDate = (current) => current && current >= moment();

  useEffect(() => {
    reset({
      name: me?.data?.name,
      email: me?.data?.email,
      phone: me?.data?.phone,
      company_name: me?.data?.salon?.name,
      date_founded: me?.data?.salon?.date_founded
        ? dayjs(me?.data?.salon?.date_founded, "YYYY-MM-DD")
        : "",
      bio: me?.data?.bio,
      position_id: me?.data?.position_id,
      product_line: me?.data?.salon?.product_line,
      date_of_birth: me?.data?.dob
        ? dayjs(moment(me?.data?.dob).toISOString(), "YYYY-MM-DD")
        : "",
    });
  }, [me?.data]);

  useEffect(() => {
    if (positions.length) {
      setValue("position_id", me?.data?.position_id);
    }
  }, [positions, me?.data?.position_id]);

  const renderSegments = () => {
    if (
      !GetAuthUserLocalStorage().roles.find(({ id }) => id === salonRoles.owner)
    )
      return null;
    return (
      <>
        <Col lg={6}>
          <div className="all_select bio">
            <div className="select_box_new mb-20">
              <div className="select_option_new">
                <Controller
                  control={control}
                  name="product_line"
                  defaultValue={product}
                  render={({ field }) => (
                    <Select
                      {...field}
                      showSearch
                      value={product}
                      mode="multiple"
                      size="middle"
                      placeholder="Product Line(s)"
                      style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "58px",
                      }}
                      options={primaryProduct}
                      popupClassName="new_select_class"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={<ArrowTriangleDown />}
                      onChange={(e) => {
                        field?.onChange(e);
                        setProduct(e);
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="all_select bio">
            <div className="select_box_new mb-20">
              <div className="select_option_new">
                <Controller
                  control={control}
                  name="software"
                  defaultValue={bookingSoftware}
                  render={({ field }) => (
                    <Select
                      {...field}
                      showSearch
                      value={bookingSoftware}
                      mode="multiple"
                      size="middle"
                      placeholder="Booking Software"
                      style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "58px",
                      }}
                      options={SOFTWARE.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                      popupClassName="new_select_class"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={<ArrowTriangleDown />}
                      onChange={(e) => {
                        field?.onChange(e);
                        setBookingSoftware(e);
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="all_select bio">
            <div className="select_box_new mb-20">
              <div className="select_option_new">
                <Controller
                  control={control}
                  name="technology"
                  defaultValue={technology}
                  render={({ field }) => (
                    <Select
                      {...field}
                      showSearch
                      value={technology}
                      mode="multiple"
                      size="middle"
                      placeholder="Other Technology"
                      style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "58px",
                      }}
                      options={TECHNOLOGY.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                      popupClassName="new_select_class"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={<ArrowTriangleDown />}
                      onChange={(e) => {
                        field?.onChange(e);
                        setTechnology(e);
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
        </Col>
      </>
    );
  };

  console.log(
    GetAuthUserLocalStorage().roles.find(({ id }) => id === salonRoles.owner),
  );

  return (
    <div className="profileEditBox">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <FloatingLabel
              controlId="name"
              label="Full Name"
              className="mb-20 position-relative"
            >
              <Form.Control
                type="text"
                placeholder="Full Name"
                autoComplete="off"
                role={"presentation"}
                className={errors.name ? "hasError" : ""}
                maxLength={maxBounds.NAME}
                {...register("name", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.NAME_REQUIRED,
                  },
                  // pattern: {
                  //   value: Patterns.Name,
                  //   // message: VALIDATIONS_TEXT.FIELD_REQUIRE,
                  // },
                  maxLength: {
                    value: maxBounds.NAME,
                    // message: VALIDATIONS_TEXT.NAME_MAX,
                  },
                })}
              />
            </FloatingLabel>
          </Col>
          {GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin ? (
            <Col lg={6}>
              <Controller
                control={control}
                name="position_id"
                rules={{
                  required: "Job title is required",
                }}
                render={({ field }) => {
                  return (
                    <CustomSelect
                      {...field}
                      className={
                        errors.position
                          ? "hasError customfontsize"
                          : "customfontsize"
                      }
                      placeholder="Job Title"
                      label="Job Title"
                      value={field?.value}
                      options={positions}
                      onChange={(e) => field?.onChange(e)}
                    />
                  );
                }}
              />
            </Col>
          ) : null}
          <Col lg={6}>
            <FloatingLabel
              controlId="email"
              label="Email Address"
              className="mb-20"
            >
              <Form.Control
                readOnly
                type="email"
                placeholder="name@example.com"
                className={errors.email ? "hasError" : ""}
                {...register("email", {
                  required: {
                    value: false,
                    // message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Email,
                    // message: VALIDATIONS_TEXT.EMAIL_PATTERN,
                  },
                  maxLength: {
                    value: maxBounds.EMAIL,
                    // message: VALIDATIONS_TEXT.EMAIL_MAX,
                  },
                })}
              />
            </FloatingLabel>
          </Col>
          <Col lg={6}>
            <Controller
              control={control}
              defaultValue={""}
              name="phone"
              rules={{
                required: {
                  value: true,
                  message: "Phone is required",
                },
              }}
              render={({ field }) => {
                return (
                  <FloatingLabel
                    controlId="floatingPhone"
                    label="Phone Number"
                    className="mb-20"
                  >
                    <PatternFormat
                      value={showNumber(field.value)}
                      onValueChange={(values) => {
                        field.onChange(values.value);
                      }}
                      allowEmptyFormatting
                      placeholder="Phone Number"
                      format="+1 ###-###-####"
                      className={
                        errors.phoneNumber
                          ? "hasError form-control"
                          : "form-control"
                      }
                    />
                  </FloatingLabel>
                );
              }}
            />
          </Col>
          {GetAuthUserLocalStorage()?.roles[0]?.id !=
            salonRoles.super_admin && (
            <Col lg={6}>
              <FloatingLabel
                controlId="companyName"
                label="Company Name"
                className="mb-20 position-relative"
              >
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.eventName ? "hasError" : ""}
                  maxLength={maxBounds.POINTSTITLE}
                  {...register("company_name", {
                    required: {
                      value: true,
                      // message: VALIDATIONS_TEXT.NAME_REQUIRED,
                    },
                    // pattern: {
                    //   value: Patterns.Name,
                    //   // message: VALIDATIONS_TEXT.FIELD_REQUIRE,
                    // },
                    maxLength: {
                      value: maxBounds.POINTSTITLE,
                      // message: VALIDATIONS_TEXT.NAME_MAX,
                    },
                  })}
                />
                {/* {errors.companyName && (
              <Form.Text>{errors.companyName.message}</Form.Text>
            )} */}
              </FloatingLabel>
            </Col>
          )}
          {GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin ? (
            <Col lg={6}>
              <Controller
                control={control}
                name="date_founded"
                defaultValue={""}
                rules={{
                  required: "Date founded is required",
                }}
                render={({ field }) => {
                  return (
                    <CustomDatePicker
                      {...field}
                      className={
                        errors.date_founded
                          ? "hasError customZindex customfontsize"
                          : "customZindex customfontsize"
                      }
                      label="Date Founded"
                      placeholder="Date Founded"
                      onChange={(date, dateString) => {
                        field?.onChange(
                          moment(dateString).format("YYYY-MM-DD"),
                        );
                      }}
                      value={
                        field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                      }
                      disabledDate={disabledDate}
                    />
                  );
                }}
              />
            </Col>
          ) : null}

          <Col lg={6}>
            <Controller
              control={control}
              name="date_of_birth"
              defaultValue={""}
              rules={{
                required: "Date of birth is required",
              }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  className={
                    errors.date_of_birth
                      ? "hasError customZindex customfontsize"
                      : "customZindex customfontsize"
                  }
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  onChange={(date, dateString) => {
                    dayjs(field?.value, "YYYY-MM-DD");
                    field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  value={field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
                  disabledDate={disabledDate}
                />
              )}
            />
          </Col>

          {renderSegments()}

          <Col lg={12}>
            <Button
              disabled={handleDisableSubmit() || !isDirty}
              type={"submit"}
              className={"w-100 btn-submit"}
            >
              {disable ? <div className="load black" /> : "Save Changes"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditProfileBio;
