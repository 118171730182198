import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUser: null,

  allUsers: {
    isLoading: true,
    data: [],
  },

  userListing: {
    isLoading: true,
    data: [],
  },

  channelMessages: {
    isLoading: true,
    data: null,
    subscription: null,
  },
};
export const chatsSlice = createSlice({
  name: "chats",
  initialState,

  reducers: {
    resetChatData: () => {
      return initialState;
    },
    setIsAllUsersLoading: (state, action) => {
      state.allUsers.isLoading = action.payload;
    },
    setAllUsers: (state, action) => {
      state.allUsers.data = action.payload;
    },

    setIsChatUsersLoading: (state, action) => {
      state.userListing.isLoading = action.payload;
    },
    setChatUsers: (state, action) => {
      state.userListing.data = action.payload;
    },

    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },

    setIsChannelMessagesLoading: (state, action) => {
      state.channelMessages.isLoading = action.payload;
    },
    setChannelMessages: (state, action) => {
      state.channelMessages.data = action.payload;
    },
  },
});

export const {
  setIsAllUsersLoading,
  setAllUsers,
  setIsChatUsersLoading,
  setChatUsers,
  setSelectedUser,
  setIsChannelMessagesLoading,
  setChannelMessages,
  resetChatData,
} = chatsSlice.actions;

export default chatsSlice.reducer;
