import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../Contexts/ContextProvider";
import { ArrowTriangleDown } from "../../constants/svg";
import DatepickerComponent from "./Datepicker";

const CustomDropdown = (props) => {
  const { alertScheduledTime, setAlertScheduledTime } = useStateContext();

  const [selectedOption, setSelectedOption] = useState({
    category: props.defaultValue,
  });
  const [showchanged, setshowChanged] = useState(false);

  // Select Change fucntion
  const handleSelectChange = (id, value) => {
    setAlertScheduledTime(value);
    if (props?.handleSelectAndDatePickerValues) {
      props?.handleSelectAndDatePickerValues(value);
    }

    if (props.setData) {
      props.setData(props.name, value);
    }
    if (props.setIsCategory) {
      props.setIsCategory(true);
    }
    if (!value) {
      setSelectedOption({
        ...selectedOption,
        [id]: false,
      });
    } else {
      setSelectedOption({
        ...selectedOption,
        [id]: value,
      });
    }
  };
  // To Reset the Select on close of popup window
  useEffect(() => {
    if (props.show) {
      setSelectedOption({
        ...selectedOption,
        category: false,
      });
    }
  }, [props.show]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(props, "fetchgeneratedoption")) {
      setshowChanged(true);
    } else {
      setshowChanged(false);
    }
  }, [props]);

  useEffect(() => {
    if (showchanged) {
      props?.fetchgeneratedoption(selectedOption);
    }
  }, [selectedOption]);

  return (
    <>
      {props.menuOption.length == 0 ? (
        <DatepickerComponent pickerName={"On This Day"} />
      ) : (
        <div className="customLabelGroup form-floating mb-20 customDropdown">
          <div
            className={
              selectedOption.category || props.defaultValue
                ? "form-control hasValue"
                : "form-control"
            }
          >
            {selectedOption.category || props.defaultValue ? (
              <div
                className={"customLabel"}
                style={{
                  display: "block",
                  color: selectedOption.isOpen ? "#7B68EE" : "#A8A8A8",
                }}
              >
                {props.placeholder}
              </div>
            ) : null}

            <Select
              {...props}
              style={{ fontSize: "12px" }}
              // value={selectedOption.category}
              value={props?.value}
              onChange={(e) => {
                handleSelectChange("category", e);
                if (props.setIsCategory) {
                  props.setIsCategory(true);
                }
              }}
              className={props.customClass}
              placeholder={props.placeholder}
              suffixIcon={<ArrowTriangleDown />}
              options={props.menuOption}
              defaultValue={props.defaultValue && props.defaultValue}
              // open={true}
              popupClassName={`${props.popupClassName} hoverClass`}
              onDropdownVisibleChange={(open) => {
                setSelectedOption((prevState) => ({
                  ...prevState,
                  isOpen: open,
                }));
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomDropdown;
// comment
