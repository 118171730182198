import React from "react";
import ActiveCards from "./ActiveCards";
import AchievedCards from "./AchievedCards";
import MissedCards from "./MissedCards";

function GoalsBody(props) {
  return (
    <div className="content">
      <div
        className={
          props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <ActiveCards
          className={"activeCard"}
          page={props?.activeGoalsPage}
          setPage={props?.setActiveGoalsPage}
          selectedGoalType={props?.selectedGoalType}
        />
      </div>
      <div
        className={
          props.activeTab == "tab2" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <AchievedCards
          className={"achievedCard"}
          page={props?.achievedGoalsPage}
          setPage={props?.setAchievedGoalsPage}
          selectedGoalType={props?.selectedGoalType}
        />
      </div>
      <div
        className={
          props.activeTab == "tab3" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <MissedCards
          className={"missedCard"}
          page={props?.missedGoalsPage}
          setPage={props?.setMissedGoalsPage}
          selectedGoalType={props?.selectedGoalType}
        />
      </div>
    </div>
  );
}

export default GoalsBody;
