import api from "../index";
import { apiUrl } from "../../utils/constants";

export const CreateGoal = (data) => api.post(apiUrl.goals, data);
export const UpdateGoal = (id, data) => api.put(`${apiUrl.goals}/${id}`, data);
export const GetGoals = (params) => api.get(apiUrl.goals, { params });
export const GetSingleGoal = (id) => api.get(`${apiUrl.goals}/${id}`);
export const DeleteSingleGoal = (id) => api.delete(`${apiUrl.goals}/${id}`);
export const UpdateGoalStatus = (id, payload) =>
  api.put(`${apiUrl.userGoals}/${id}`, payload);
