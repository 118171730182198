import { notification } from "antd";
import dayjs from "dayjs";
import { ToastCrossIcon } from "../../constants/svg";
import {
  setAchievedGoals,
  setActiveGoals,
  setAllGoalsLoading,
  setEmptyAllGoals,
  setGoalsFormData,
  setIsAchievedGoalsLoading,
  setIsActiveGoalsLoading,
  setIsMissedGoalsLoading,
  setIsSingleGoalLoading,
  setMissedGoals,
  setSingleGoal,
} from "../../store/slices/goalsSlice";
import { user_goal_status } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";
import {
  CreateGoal,
  DeleteSingleGoal,
  GetGoals,
  GetSingleGoal,
  UpdateGoal,
} from "./goals";

export const getGoals = async (params, dispatch, setLoading, setData) => {
  try {
    const res = await GetGoals(params);
    dispatch(
      setData({ data: res?.data?.data?.data, meta: res?.data?.data?.meta }),
    );
    dispatch(setLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setLoading(false));
  }
};

export const getGoalsMore = async (params) => {
  try {
    const res = await GetGoals(params);
    return { data: res?.data?.data?.data, meta: res?.data?.data?.meta };
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getAllGoals = async (currentSalonId, dispatch) => {
  const activeGoalsParams = {
    is_paginated: 1,
    salon_id: currentSalonId,
    viewed_status: user_goal_status?.active,
    page: 1,
    "per-page": 10,
    "order-column": "end_date",
    order: "asc",
  };

  const achievedGoalsParams = {
    is_paginated: 1,
    salon_id: currentSalonId,
    viewed_status: user_goal_status?.achieved,
    page: 1,
    "per-page": 10,
    "order-column": "end_date",
    order: "asc",
  };

  const missedGoalsParams = {
    is_paginated: 1,
    salon_id: currentSalonId,
    viewed_status: user_goal_status?.missed,
    page: 1,
    "per-page": 10,
    "order-column": "end_date",
    order: "asc",
  };

  getGoals(
    activeGoalsParams,
    dispatch,
    setIsActiveGoalsLoading,
    setActiveGoals,
  );
  getGoals(
    achievedGoalsParams,
    dispatch,
    setIsAchievedGoalsLoading,
    setAchievedGoals,
  );
  getGoals(
    missedGoalsParams,
    dispatch,
    setIsMissedGoalsLoading,
    setMissedGoals,
  );
};

export const deleteGoal = async (
  selectedData,
  setDisable,
  showModal,
  setShowModal,
  dispatch,
) => {
  try {
    setDisable(true);
    const res = await DeleteSingleGoal(selectedData?.key);
    setShowModal({ ...showModal, deleteModal: false });
    toastMessage("delete", res.data.message);
    setDisable(false);
    getAllGoals(selectedData?.salon_id, dispatch);
    dispatch(setAllGoalsLoading());
    dispatch(setEmptyAllGoals());
  } catch (e) {
    toastMessage("error", e.message);
    setDisable(false);
  }
};

export const getSingleGoal = async (id, dispatch) => {
  try {
    dispatch(setIsSingleGoalLoading(true));
    const res = await GetSingleGoal(id);
    const data = res?.data?.data;
    dispatch(setSingleGoal(data));
    dispatch(setIsSingleGoalLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsSingleGoalLoading(false));
  }
};

export const presetDataForEditGoals = async (
  selectedData,
  setSelected,
  dispatch,
) => {
  try {
    const res = await GetSingleGoal(selectedData?.key);
    const data = res?.data?.data;

    dispatch(
      setGoalsFormData({
        type: data?.type,
        name: data?.name,
        description: data?.description,
        target_type: data?.target_type,
        target_date: data?.day,
        target_value: data?.target_value,
        duration: data?.duration,
        start_date: dayjs(data?.start_date, "YYYY-MM-DD"),
        end_date: dayjs(data?.end_date, "YYYY-MM-DD"),
        selectedCategory: "member",
      }),
    );
    setSelected(data?.type);
    dispatch(setIsSingleGoalLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsSingleGoalLoading(false));
  }
};

export const createGoal = async (
  apiData,
  setDisabled,
  setModalShow,
  resetForm,
  dispatch,
) => {
  try {
    setDisabled(true);
    const res = await CreateGoal(apiData);
    toastMessage("success", "Goal added and assigned!");
    setModalShow(false);
    resetForm();
    dispatch(setAllGoalsLoading());
    dispatch(setEmptyAllGoals());
    setDisabled(false);
  } catch (e) {
    toastMessage("warning", e.message);
    setDisabled(false);
  }
};

export const updateGoal = async (
  apiData,
  selectedData,
  setDisabled,
  setModalShow,
  resetForm,
  dispatch,
) => {
  try {
    setDisabled(true);
    const res = await UpdateGoal(selectedData?.key, apiData);
    toastMessage("success", "Goal updated successfully!");
    setModalShow(false);
    resetForm();
    dispatch(setAllGoalsLoading());
    dispatch(setEmptyAllGoals());
    setDisabled(false);
  } catch (e) {
    toastMessage("warning", e.message);
    setDisabled(false);
  }
};
