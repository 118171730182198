import { createSlice } from "@reduxjs/toolkit";

export const candidateReferrelSlice = createSlice({
  name: "candidateReferrel",
  initialState: {
    loading: false,
    candidateReferral: [],
    page: 1,
    perPage: 10,
    isPaginate: 1,
    reRenderTable: 1,
    actionButtonDisable: false,
    candidateReferralDetail: null,
    pageLoading: false,
    filters: {
      is_approved: 0,
    },
    sorting: {
      column: "id",
      sortBy: "desc",
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCandidateReferral: (state, action) => {
      state.candidateReferral = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setIsPaginate: (state, action) => {
      state.isPaginate = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setCandidateReferralDetail: (state, action) => {
      state.candidateReferralDetail = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  setCandidateReferral,
  setPage,
  setPerPage,
  setIsPaginate,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setCandidateReferralDetail,
  setPageLoading,
  setFilters,
} = candidateReferrelSlice.actions;

export default candidateReferrelSlice.reducer;
