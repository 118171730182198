import { Progress } from "antd";
import { useEffect } from "react";
import { BlackStarIcon } from "../../constants/svg";
import QuestionResponseUsersListPopup from "./QuestionResponseUsersListPopup";
import { calPercentage } from "../../utils/helper";

const StarRatingQuestionView = ({
  data,
  index,
  assignedUsers,
  detailPageType,
}) => {
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.classList.remove("overlay-class");
    });
  });

  let totalResponse = 0;
  if (detailPageType == "survey") {
    totalResponse = data.surveyQuestionAnswers.length;
  } else if (detailPageType == "quiz") {
    totalResponse = data.surveyQuestionAnswers.length;
  }

  let answers = [];

  if (detailPageType == "survey") {
    answers = data.surveyQuestionAnswers;
  } else if (detailPageType == "quiz") {
    answers = data.surveyQuestionAnswers;
  }

  const oneStarRating = answers.filter((elem) => elem.answer == "1.0");
  const twoStarRating = answers.filter((elem) => elem.answer == "2.0");
  const threeStarRating = answers.filter((elem) => elem.answer == "3.0");
  const fourStarRating = answers.filter((elem) => elem.answer == "4.0");
  const fiveStarRating = answers.filter((elem) => elem.answer == "5.0");

  const oneStarRatingPercentage = calPercentage(
    oneStarRating.length,
    assignedUsers.length,
  );
  const twoStarRatingPercentage = calPercentage(
    twoStarRating.length,
    assignedUsers.length,
  );
  const threeStarRatingPercentage = calPercentage(
    threeStarRating.length,
    assignedUsers.length,
  );
  const fourStarRatingPercentage = calPercentage(
    fourStarRating.length,
    assignedUsers.length,
  );
  const fiveStarRatingPercentage = calPercentage(
    fiveStarRating.length,
    assignedUsers.length,
  );

  return (
    <>
      <div className="survey-border-wrapper">
        <div className="survey-answer-box">
          <div className="question-box">
            <h3>
              {index + 1}. {data.question}
              {/* <span> ({totalResponse} Responses)</span> */}
            </h3>
          </div>
          <div className="answer-box">
            <div className="each-response-div">
              <h5>
                <BlackStarIcon />{" "}
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={oneStarRatingPercentage}
                  strokeColor="#7B68EE"
                />
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID={"starRatingOne"}
                  answeredUserList={oneStarRating}
                  questionType="star"
                  answerResponsePercentage={oneStarRatingPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                <BlackStarIcon /> <BlackStarIcon />
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={twoStarRatingPercentage}
                  strokeColor="#7B68EE"
                />

                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID={"starRatingTwo"}
                  answeredUserList={twoStarRating}
                  questionType="star"
                  answerResponsePercentage={twoStarRatingPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                <BlackStarIcon /> <BlackStarIcon /> <BlackStarIcon />
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={threeStarRatingPercentage}
                  strokeColor="#7B68EE"
                />

                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID={"starRatingThree"}
                  answeredUserList={threeStarRating}
                  questionType="star"
                  answerResponsePercentage={threeStarRatingPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                <BlackStarIcon /> <BlackStarIcon /> <BlackStarIcon />{" "}
                <BlackStarIcon />
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={fourStarRatingPercentage}
                  strokeColor="#7B68EE"
                />

                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID={"starRatingFour"}
                  answeredUserList={fourStarRating}
                  questionType="star"
                  answerResponsePercentage={fourStarRatingPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                <BlackStarIcon /> <BlackStarIcon /> <BlackStarIcon />{" "}
                <BlackStarIcon /> <BlackStarIcon />
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={fiveStarRatingPercentage}
                  strokeColor="#7B68EE"
                />

                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID={"starRatingFive"}
                  answeredUserList={fiveStarRating}
                  questionType="star"
                  answerResponsePercentage={fiveStarRatingPercentage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StarRatingQuestionView;
