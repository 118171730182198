import React from "react";
import { Dropdown, Space, Tooltip } from "antd";
import moment from "moment";
import {
  AcrobatIcon,
  BinocularsLight,
  MoreIcon,
  RecycleBin,
  RenameIcon,
  ShareIcon,
} from "../constants/svg";
import { NavLink } from "react-router-dom";
import { roundStringToNearestInteger } from "../utils/helper";

const getdDocumentTableColumns = (
  setShowModal,
  setDocIDForDelete,
  handleOpenEditDocForm,
  setSharePopupData,
) => {
  return [
    {
      title: "Doc Name",
      dataIndex: "title",
      key: "title",
      width: 450,
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink to={`/team/docs/details/${record.id}`} className={"black"}>
          <div className={"gallery-link"}>
            <Space>
              <AcrobatIcon />
              <div className={"truncateDocName document-content"}>
                {record.title}
              </div>
            </Space>
          </div>
        </NavLink>
      ),
    },
    {
      title: "File Size",
      dataIndex: "size",
      key: "size",
      width: 150,
      sorter: (a, b) => a.size.localeCompare(b.size),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p> {roundStringToNearestInteger(record.size) + " KB"}</p>
      ),
      align: "left",
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p>{moment(record.created_at).format("MMM DD, YYYY")}</p>
      ),
      align: "left",
    },
    {
      title: "Viewed",
      className: "center-column",
      dataIndex: "opened",
      key: "opened",
      width: 150,
      sorter: (a, b) => a.viewedBy - b.viewedBy,
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) =>
        renderAttendeesColumn({
          opened: record?.views?.length,
          attendents: record.views.map((elem) => elem?.user?.name),
        }),
      align: "center",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <NavLink
                        to={`/team/docs/details/${record.id}`}
                        className={"black"}
                      >
                        <BinocularsLight />
                        View
                      </NavLink>
                    </>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <>
                      <div
                        onClick={() => {
                          handleOpenEditDocForm(record);
                        }}
                      >
                        <RenameIcon />
                        Rename
                      </div>
                    </>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <>
                      <div
                        onClick={() => {
                          setSharePopupData(record);
                          setShowModal((prevState) => ({
                            ...prevState,
                            shareModal: true,
                          }));
                        }}
                      >
                        <ShareIcon />
                        Share
                      </div>
                    </>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <div
                      className={"deleteLabel"}
                      onClick={() => {
                        setDocIDForDelete(record);
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }));
                      }}
                    >
                      <RecycleBin />
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];
};

function renderAttendeesColumn(attendees) {
  if (!attendees) {
    return null;
  }
  const attendeeNames = attendees.attendents || [];
  const attendeeTooltipContent = (
    <div className="attendeeNames">
      {attendeeNames.slice(0, 7).map((name, index) => (
        <React.Fragment key={index}>
          <span>{name}</span>
          {index < attendeeNames.slice(0, 7).length - 1}
        </React.Fragment>
      ))}
      {attendeeNames.length > 7 && (
        <>
          <strong>+{attendeeNames.length - 7} more</strong>
        </>
      )}
    </div>
  );

  return (
    <div className="">
      {attendees.opened != 0 ? (
        <Tooltip
          title={attendeeTooltipContent}
          placement="bottom"
          overlayClassName="custom-tooltip"
          color="purple"
          // open={true}
        >
          <div className="seenBy dottedRoundedBox">
            <span>{attendees.opened}</span>
          </div>
        </Tooltip>
      ) : (
        <div className="seenBy dottedRoundedBox">
          <span>{attendees.opened}</span>
        </div>
      )}
    </div>
  );
}

export default getdDocumentTableColumns;
