import React, { useState } from "react";
import GoalsHeader from "../../components/pages/GoalsPage/GoalsHeader";
import GoalsBody from "../../components/pages/GoalsPage/GoalsBody";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGoalTab } from "../../store/slices/goalsSlice";

function GoalsPage() {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.goals.activeTab);
  const [activeTab, setActiveTab] = useState(selectedTab || "tab1");
  dispatch(setSelectedGoalTab(activeTab));
  const [selectedGoalType, setSelectedGoalType] = useState(null);

  const [activeGoalsPage, setActiveGoalsPage] = useState({
    currentPage: 1,
    perPage: 12,
  });
  const [achievedGoalsPage, setAchievedGoalsPage] = useState({
    currentPage: 1,
    perPage: 12,
  });
  const [missedGoalsPage, setMissedGoalsPage] = useState({
    currentPage: 1,
    perPage: 12,
  });

  const handleResetPage = () => {
    setActiveGoalsPage({ currentPage: 1, perPage: 12 });
    setAchievedGoalsPage({ currentPage: 1, perPage: 12 });
    setMissedGoalsPage({ currentPage: 1, perPage: 12 });
  };

  return (
    <>
      <div className="innerContainer goalPage">
        <GoalsHeader
          setSelectedGoalType={setSelectedGoalType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleResetPage={handleResetPage}
        />

        <GoalsBody
          selectedGoalType={selectedGoalType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeGoalsPage={activeGoalsPage}
          setActiveGoalsPage={setActiveGoalsPage}
          achievedGoalsPage={achievedGoalsPage}
          setAchievedGoalsPage={setAchievedGoalsPage}
          missedGoalsPage={missedGoalsPage}
          setMissedGoalsPage={setMissedGoalsPage}
        />
      </div>
    </>
  );
}

export default GoalsPage;
