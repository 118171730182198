import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../../../constants/svg";
import { UpdateFileUploadStorageSizeAvailability } from "../../../../services/global/global";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import {
  CreateQuiz,
  GetQuizByID,
  UpdateQuiz,
} from "../../../../services/quiz/quiz";
import {
  setCategoryListByModule,
  setCreateUpdateTitleModuleID,
} from "../../../../store/slices/globalSlice";
import {
  setActionButtonDisable,
  setQuizAddEditForm,
  setReRenderQuizBuilder,
  setReRenderQuizDetailPage,
  setReRenderTable,
  setSelectedData,
} from "../../../../store/slices/quizSlice";
import {
  all_category_type,
  roles as salonRoles,
  search_module_type,
  segment_type,
  status as recordStatus,
} from "../../../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../../../utils/helper";
import FirstStep from "./StepForm/FirstStep";
import SecondStep from "./StepForm/SecondStep";
import ZeroStep from "../../Resource/ResourceModal/StepsForm/ZeroStep";

const QuizModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    quizAddEditForm,
    selectedData,
    reRenderTable,
    actionButtonDisable,
    reRenderQuizDetailPage,
    reRenderQuizBuilder,
  } = useSelector((state) => state.quiz);
  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );

  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  );
  const [firstStepDisabled, setFirstStepDisabled] = useState(true);
  const [secondStepDisabled, setSecondStepDisabled] = useState(false);
  const [segment, setSegment] = useState(null);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      quizName: "",
      category: "",
      tags: "",
      description: "",
      localUpload: "",
      uploadFromGallery: "",
      uploadFromFolder: "",
      preview: "",
    },
  });

  const watchingStep1Form = watch(["quizName", "category", "tags"]);
  const watchingStep2Form = watch([
    "localUpload",
    "uploadFromGallery",
    "uploadFromFolder",
    "preview",
  ]);

  useEffect(() => {
    const isPass = watchingStep1Form.every((elem) => elem != "");
    setFirstStepDisabled(!isPass);
  }, [watchingStep1Form]);

  useEffect(() => {
    const isPass = watchingStep2Form.some((elem) => elem != "");
    setSecondStepDisabled(!isPass);
  }, [watchingStep2Form]);

  const onSubmit = async (inputFields) => {
    try {
      const payload = {
        name: inputFields.quizName,
        status: recordStatus.active,
        category_id: inputFields.category,
        description: inputFields.description,
        tags: inputFields.tags,
        salon_id: currentSalonId,
        user_id: GetAuthUserLocalStorage().id,
      };
      if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
        let salonData =
          selectedSalons.length > 0
            ? selectedSalons
            : salonSegments.map((item) => {
                return item.id;
              });
        payload.salons = salonData;
        payload.products = selectedProductLines;
        payload.positions = selectedPositions;
        payload.segment = segment;
        payload.softwares = softwares;
        payload.technologies = technologies;
      }

      dispatch(setActionButtonDisable(true));

      let salonS3UploadResponse;

      if (inputFields.localUpload != "") {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/quiz`;
        salonS3UploadResponse = await salonUploadToS3(
          inputFields.localUpload,
          folder,
        );
        if (salonS3UploadResponse.status) {
          payload.image = salonS3UploadResponse.data.location;
        } else {
          throw new Error(salonS3UploadResponse.message);
        }
      } else if (
        inputFields.uploadFromGallery ||
        inputFields.uploadFromFolder ||
        inputFields.preview
      ) {
        payload.image = inputFields.uploadFromGallery
          ? inputFields.uploadFromGallery
          : inputFields.uploadFromFolder
            ? inputFields.uploadFromFolder
            : inputFields.preview
              ? inputFields.preview
              : "";
      }

      let response;
      if (quizAddEditForm.formType == "create") {
        response = await CreateQuiz(payload);
      } else if (quizAddEditForm.formType == "update") {
        response = await UpdateQuiz(selectedData.id, payload);
      }
      const { data, message, status } = response.data;

      if (!status) {
        toastMessage("error", message);
        return;
      }

      if (
        salonS3UploadResponse &&
        GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin
      ) {
        await UpdateFileUploadStorageSizeAvailability(
          salonS3UploadResponse.data.size,
        );
      }

      dispatch(setSelectedData(null));

      dispatch(
        setQuizAddEditForm({
          show: false,
          formType: null,
        }),
      );

      dispatch(setReRenderQuizBuilder(reRenderQuizBuilder + 1));
      dispatch(setReRenderQuizDetailPage(reRenderQuizDetailPage + 1));
      dispatch(setReRenderTable(reRenderTable + 1));

      let notiMsg;

      if (quizAddEditForm.formType == "create") {
        notiMsg = "Quiz Created Successfully!";
      } else if (quizAddEditForm.formType == "update") {
        notiMsg = "Quiz Updated Successfully!";
      }

      navigate("/quiz/quizbuilder/" + data.id);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  // Navigation of buttons
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const getQuizByID = async () => {
    try {
      const response = await GetQuizByID(selectedData.id);
      const { data, status, message } = response.data;
      setValue("quizName", data.name);
      setValue("category", data.category_id);
      setValue(
        "tags",
        data.tags.map((elem) => elem.id),
      );
      setSegment(data?.segment || null);
      if (data?.segment == segment_type.CUSTOM) {
        let salons = [];
        let products = [];
        data?.products?.map((item) => {
          products.push(item.product);
        });
        data?.salons?.map((item) => {
          salons.push(item.id);
        });
        setSelectedSalons(salons);
        setSelectedProductLines(products);
      }
      setValue("description", data.description);
      setValue("preview", data.image);
      setTechnologies(data.technologies.map(({ name }) => name) ?? []);
      setSoftwares(data.software.map(({ name }) => name) ?? []);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.RFQ));

    if (quizAddEditForm.formType == "update") {
      getQuizByID();
    }
  }, [quizAddEditForm]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.quizzes));
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`${step == 0 ? "modal-body" : "modal-body tabs-modal"} ${props.customModalBody}`}
        >
          <div className={step == 0 ? "" : "d-none"}>
            <ZeroStep
              setZeroStepDisabled={setZeroStepDisabled}
              customClass="noBorders"
              selectedSalons={selectedSalons}
              setSelectedSalons={setSelectedSalons}
              setSelectedProductLines={setSelectedProductLines}
              selectedProductLines={selectedProductLines}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              showPositions={false}
              segment={segment}
              setSegment={setSegment}
              softwares={softwares}
              setSoftwares={setSoftwares}
              technologies={technologies}
              setTechnologies={setTechnologies}
            />
          </div>
          <div className={step == 1 ? "" : "d-none"}>
            <FirstStep
              register={register}
              control={control}
              getValues={getValues}
              watch={watch}
              errors={errors}
              title="quizzes"
              setValue={setValue}
            />
          </div>
          <div className={step == 2 ? "" : "d-none"}>
            <SecondStep
              getValues={getValues}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              submitted={actionButtonDisable}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={nextStep}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={firstStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={firstStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            ) : null}

            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {!actionButtonDisable && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={secondStepDisabled}
                    >
                      Continue
                    </Button>
                  )}
                  {actionButtonDisable && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={true}
                      onClick={nextStep}
                    >
                      <div className="load black" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default QuizModal;
