import { Dropdown, Space, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import { BinocularsLight, MoreIcon, RecycleBin } from "../../../constants/svg";
import {
  setShowHideOrganizationDeleteModal,
  setSingleOraganizationDetail,
} from "../../../store/slices/organizationSlice";
import Active from "./Active";
import Lost from "./Lost";

const OrganizationBody = (props) => {
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Organization",
      dataIndex: "alertTitle",
      key: "alertTitle",
      // width: "25%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink
          to={`/organization/organization-detail/${record.id}`}
          className={"black"}
        >
          <Space size={19}>
            <img
              src={record.image || Assets.GeneralPlaceholder}
              alt={record.name}
              className="activityAvatar "
            />
            <span>{record.name}</span>
          </Space>
        </NavLink>
      ),
    },
    {
      title: "Member",
      dataIndex: "members",
      key: "members",
      className: "center-column",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: (a, b) => a.total_members > b.total_members,
      render: (text, record) => {
        const members = record.users || [];
        const attendeeTooltipContent = (
          <div className="attendeeNames">
            {members.slice(0, 7).map((elem, index) => (
              <React.Fragment key={index}>
                <span>{elem.name}</span>
                {index < members.slice(0, 7).length - 1}
              </React.Fragment>
            ))}
            {members.length > 7 && (
              <>
                <strong>+{members.length - 7} more</strong>
              </>
            )}
          </div>
        );

        return (
          <div className="">
            {members.length ? (
              <Tooltip
                title={attendeeTooltipContent}
                placement="bottom"
                overlayClassName="custom-tooltip"
                color="purple"
              >
                <div className="seenBy dottedRoundedBox">
                  <span>{members.length}</span>
                </div>
              </Tooltip>
            ) : (
              <div className="seenBy dottedRoundedBox">
                <span>{members.length}</span>
              </div>
            )}
          </div>
        );
      },

      align: "center",
      // width: "15%",
    },
    {
      title: "Account Owner",
      dataIndex: "account_owner",
      key: "account_owner",
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p>{record?.user?.name ? record?.user?.name : "-"}</p>
      ),
      align: "left",
    },
    {
      title: "Product Line",
      dataIndex: "product_line",
      key: "product_line",
      sorter: (a, b) => a.product_line.localeCompare(b.product_line),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p>{record.product_line ? record.product_line : "-"}</p>
      ),
      align: "left",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p>{moment(record.created_at).format("MMM DD, yyyy")}</p>
      ),
      align: "left",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <NavLink
                        to={`/organization/organization-detail/${record.id}`}
                        className={"black"}
                      >
                        <BinocularsLight />
                        View
                      </NavLink>
                    </>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <div
                      className={"deleteLabel"}
                      onClick={() => {
                        dispatch(setSingleOraganizationDetail(record));
                        dispatch(setShowHideOrganizationDeleteModal(true));
                      }}
                    >
                      <RecycleBin />
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      // width: "10%",
    },
  ];

  return (
    <div className="content">
      <div
        className={
          props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Active tableColumn={columns} />
      </div>

      <div
        className={
          props.activeTab == "tab2" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Lost tableColumn={columns} />
      </div>
    </div>
  );
};

export default OrganizationBody;
