import React from "react";
import SparklingStar from "../../assets/images/SparklingStars.svg";
import EmptyTaskIcon from "../../assets/images/tasklist/empty_task_list.svg";

function defaultAction() {}

function EmptyTableView({
  image = EmptyTaskIcon,
  boldText = "No Data",
  infoText = "There is some info",
  action = defaultAction,
  style = null,
  includeIcon,
}) {
  return (
    <div className="emptyTask__main">
      <img src={image} style={{ width: "60px", borderRadius: "100px" }} />
      <p style={style}>{boldText}</p>

      <p className="mb-0">
        {" "}
        {/* <img src={SparklingStar} /> */}
        <span style={{ fontWeight: 400 }}> {infoText}</span>
        <span onClick={action} style={{ color: "#7B68EE", cursor: "pointer" }}>
          {" "}
          Learn more →
        </span>
      </p>
    </div>
  );
}

export default EmptyTableView;
