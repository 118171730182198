import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CreatePaymentLink } from "../../services/subscription/subscription";
import { setIsCreatingPaymentLink } from "../../store/slices/subscriptionSlice";
import { subscriptionStatus as salonSubscriptionStatus } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";
import "swiper/css";
import "swiper/css/navigation";
import "../../assets/scss/components/_packagesPricingModal.scss";

const PackageListingModal = (props) => {
  // const selectCSS = () => {
  //   if (props.show) {
  //     require("../../assets/scss/components/_packagesPricingModal.scss")
  //   }
  // }

  const dispatch = useDispatch();

  const [swiper, setSwiper] = useState(null);

  const { subscriptionStatus, canceledSubscriptionID } = useSelector(
    (state) => state.subscription,
  );
  const { packages, isCreatingPaymentLink } = useSelector(
    (state) => state.subscription,
  );

  const [packageListing, setPackageListing] = useState([]);

  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [initialSlide, setInitialSlide] = useState();

  const handleSubscribePackage = async (packageId) => {
    if (selectedPackageId == packageId) return;
    try {
      setSelectedPackageId(packageId);
      dispatch(setIsCreatingPaymentLink(true));
      const response = await CreatePaymentLink(packageId);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      window.location.href = data.payment_link;
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setSelectedPackageId(null);
      dispatch(setIsCreatingPaymentLink(false));
    }
  };

  const getSubscribeBtnText = (loopingPackageId) => {
    if (isCreatingPaymentLink && loopingPackageId == selectedPackageId) {
      return <div className="load black" />;
    } else {
      return "Start Trial";
    }
  };

  useEffect(() => {
    if (canceledSubscriptionID) {
      const find = packages.find((elem) => elem.id == canceledSubscriptionID);
      const filtered = packages.filter(
        (elem) => elem.id != canceledSubscriptionID,
      );
      const final = [find, ...filtered];
      const suggestedSlideIndex = final.findIndex(
        (elem) => elem?.suggested == true,
      );
      setInitialSlide(0);
      setPackageListing(final);
    } else {
      const suggestedSlideIndex = packages.findIndex(
        (elem) => elem?.suggested == true,
      );
      setInitialSlide(suggestedSlideIndex);
      setPackageListing(packages);
    }
  }, [packages]);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-pricing-modal"
        contentClassName="pricing-modal-content"
        keyboard={false}
      >
        <Modal.Header>
          <div className="">
            <h2>
              {subscriptionStatus == salonSubscriptionStatus.canceled &&
                "Reactivate Subscription"}
            </h2>
            <h2>
              {(subscriptionStatus == salonSubscriptionStatus.paid ||
                subscriptionStatus == salonSubscriptionStatus.none) &&
                "Pricing Plans"}
            </h2>
            {subscriptionStatus == salonSubscriptionStatus.canceled && (
              <p>
                We were unable to process your last payment, please reactivate
                your plan below.
              </p>
            )}
            {(subscriptionStatus == salonSubscriptionStatus.paid ||
              subscriptionStatus == salonSubscriptionStatus.none) && (
              <p>Special Offer: Subscribe to Business for just $99/month.</p>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="custom-pl-20 custom-pr-20">
            {packageListing.map((elem, ind) => {
              return (
                <Col lg="6" md="6" key={ind}>
                  <div
                    className={`main-priceListBox ${
                      canceledSubscriptionID &&
                      elem.id == canceledSubscriptionID
                        ? "main-priceListBox-hovered"
                        : ""
                    } ${
                      canceledSubscriptionID &&
                      elem.id != canceledSubscriptionID
                        ? "main-priceListBox-disabled"
                        : ""
                    }`}
                  >
                    <div className="seatsMainBox">
                      <h3>{elem?.name}</h3>
                      {/* {elem?.suggested && (
                        <h4 className="suggestedTag">Suggested</h4>
                      )} */}
                    </div>
                    <div className="priceMonthBox">
                      <h2>${elem.price}/month</h2>
                    </div>
                    <div className="listDiv">
                      <ul>
                        <li>
                          {elem?.total_members < 999 ? "-" : "Unlimited"}{" "}
                          Employees
                        </li>
                        <li>{elem?.storage?.name} of Cloud Storage</li>
                        <li>Online Support Center</li>
                        <li>Cancel Anytime</li>
                      </ul>
                    </div>
                    <div className="btnDiv">
                      <Button
                        type="button"
                        onClick={() => handleSubscribePackage(elem.id)}
                      >
                        {getSubscribeBtnText(elem.id)}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            })}
            <Col lg="6" md="6">
              <div className={`main-priceListBox`}>
                <div className="seatsMainBox">
                  <h3>Franchise</h3>
                </div>
                <div className="priceMonthBox">
                  <h2>Custom Price</h2>
                </div>
                <div className="listDiv">
                  <ul>
                    <li>White Label Branding</li>
                    <li>Unlimited Cloud Storage</li>
                    <li>Dedicated Support Team</li>
                    <li>Annual Contract</li>
                  </ul>
                </div>
                <div className="btnDiv">
                  <a
                    href="https://salonsymphonysoftware.com/pricing/franchise/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button type="button">Contact Us</Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackageListingModal;
