import { Switch } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { SmileIcon } from "../../../../../constants/svg";
import { Controller } from "react-hook-form";
import CustomSelect from "../../../../FormFields/CustomSelect";
import { setCreateUpdateModuleTitle } from "../../../../../store/slices/globalSlice";
import { maxBounds } from "../../../../../constants/contants";
import { Assets } from "../../../../../constants/images";

function FirstStep({
  register,
  errors,
  control,
  setValue,
  setError,
  getValues,
}) {
  const dispatch = useDispatch();
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [showAlterPicker, setShowAlterPicker] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [text, setText] = useState("");
  const emojiPickerRefTwo = useRef(null);
  const [switchCheckValue, setSwitchCheckValue] = useState(false);
  const showPickerShowHide = useRef(false);
  const { categories } = useSelector((state) => state.global);

  const [selectSwitchState, setSelectSwitchState] = useState(0);

  const handleSwitch = (checked) => {
    if (checked == true) {
      setSelectSwitchState(0);
    }

    setSwitchCheckValue(!switchCheckValue);
  };

  function handleEmojiClick(emojiData) {
    setText(text + emojiData.emoji);
    setValue("surveyName", getValues("surveyName") + emojiData.emoji);
  }

  function handleAlertMessage(emojiData) {
    setAlertMessage(alertMessage + emojiData.emoji);
  }

  const [selectedOption, setSelectedOption] = useState({
    category: null,
  });

  const handleCheckEmojiClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCheckEmojiClick);
    return () => {
      window.removeEventListener("click", handleCheckEmojiClick);
    };
  }, [showPicker]);

  return (
    <Row>
      <Col lg={8}>
        <FloatingLabel
          controlId="organizationLabel"
          label="Survey Name"
          className="mb-20 custom-textarea"
        >
          <Form.Control
            type="text"
            autoComplete="off"
            placeholder="ex. Employee Development Audit"
            role={"presentation"}
            minLength={1}
            maxLength={100}
            className={errors.surveyName ? "hasError" : ""}
            {...register("surveyName", {
              onChange: (e) => {
                dispatch(setCreateUpdateModuleTitle(e.target.value));
              },
              required: "Survey name is required",
              minLength: {
                value: 1,
              },
              maxLength: {
                value: 100,
              },
            })}
          />
          <div ref={emojiPickerRef}>
            <div
              onClick={() => {
                setShowPicker(!showPicker);
              }}
              className={"smileyBox"}
            >
              <img src={Assets.Smile} className={"smileImage"} />
            </div>
            {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
          </div>
        </FloatingLabel>
      </Col>
      <Col lg={4}>
        <Controller
          control={control}
          name="category"
          defaultValue={""}
          rules={{
            required: "Category is required",
          }}
          render={({ field: { onChange, value } }) => (
            <FloatingLabel
              controlId="category"
              label={value == "" ? "Category" : ""}
              className="mb-20"
            >
              <CustomSelect
                value={value}
                label="Category"
                options={categories}
                className={value == "" ? "hasError" : ""}
                onChange={onChange}
                placeholder="Category"
                listItemHeight={37}
              />
            </FloatingLabel>
          )}
        />
      </Col>
      <Col lg={12}>
        <FloatingLabel
          controlId="description"
          label="Description (Optional)"
          className="mb-20 custom-textarea"
        >
          <Form.Control
            as="textarea"
            placeholder="Your voice matters! Please submit this feedback survey at your earliest convenience."
            minLength={0}
            maxLength={900}
            style={{ height: "200px" }}
            className={`form-control ${errors.description ? "hasError" : ""}`}
            {...register("description", {
              required: false,
              minLength: {
                value: 0,
              },
              maxLength: {
                value: 900,
              },
            })}
          />
        </FloatingLabel>
        {/* <div className="header d-flex justify-content-end align-items-center mt_24"> */}
        {/*  <p className={"me-3 mb-0"}>Make all responses Anonymous</p> */}
        {/*  <Controller */}
        {/*    control={control} */}
        {/*    name="anonymousResponse" */}
        {/*    defaultValue={false} */}
        {/*    render={({ field: { onChange, onBlur, value } }) => ( */}
        {/*      <Switch */}
        {/*        defaultChecked={value} */}
        {/*        checked={value} */}
        {/*        onChange={onChange} */}
        {/*        size={"medium"} */}
        {/*      /> */}
        {/*    )} */}
        {/*  /> */}

        {/* </div> */}
      </Col>
    </Row>
  );
}

export default FirstStep;
