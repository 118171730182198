import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { IoAddCircleSharp } from "react-icons/io5";
import {
  LocationCrossIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../store/slices/authSlice";
import CustomInput from "../../components/FormFields/CustomInput";
function AddLocation(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    watch,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitting },
    setError,
  } = useForm();
  const { formData } = useSelector((state) => state.auth);
  const [locationsArray, setLocationsArray] = useState([
    { title: "", disabled: false },
  ]);

  const goBackFunc = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    dispatch(
      setAuthData({
        ...formData,
        locations: locationsArray.map((elem) => elem.title),
      }),
    );
    navigate("/add-bio");
  };

  const handleChange = (e, index) => {
    setLocationsArray((prevLocations) => {
      const temp = [...prevLocations];
      temp[index] = { ...temp[index], title: e.target.value };
      return temp;
    });
  };

  const handleAdd = () => {
    let bool = false;
    for (let i = 0; i < locationsArray?.length; i++) {
      if (locationsArray[i]?.title == "") {
        bool = true;
        break;
      }
    }

    if (!bool) {
      setLocationsArray([...locationsArray, { title: "", disabled: false }]);
    }
  };

  const handleDisableNext = () => {
    if (locationsArray[0]?.title == "") {
      return true;
    } else {
      return false;
    }
  };

  const handleRemove = (index) => {
    let temp = [...locationsArray];
    temp.splice(index, 1);
    setLocationsArray(temp);
  };

  const handleDisble = () => {
    let bool = false;
    for (let i = 0; i < locationsArray?.length; i++) {
      if (locationsArray[i]?.title == "") {
        bool = true;
        break;
      }
    }
    return bool;
  };

  useEffect(() => {
    if (formData.name == "") {
      navigate("/setup");
    }
  }, []);

  useEffect(() => {
    if (formData?.locations?.length > 0) {
      setLocationsArray(
        formData?.locations.map((elem) => ({ title: elem, disabled: false })),
      );
    }
  }, [formData]);

  return (
    <>
      <div className="authBox addLocation">
        <div className="authHeaderText">
          <h1>Add Locations</h1>
          <p>Next, add your location(s) using city names or boroughs.</p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <div className="formheight">
            {locationsArray?.map((item, index) => (
              <div style={{ position: "relative" }} className="mb-20">
                <FloatingLabel
                  className="floating black"
                  label={
                    index == 0 ? "Primary Location" : `Location #${index + 1}`
                  }
                >
                  <Form.Control
                    placeholder={
                      index == 0
                        ? "ex. Upper East Side"
                        : index == 1
                          ? "ex. Upper West Side"
                          : index == 2
                            ? "ex. Morningside Heights"
                            : `Location #${index + 1}`
                    }
                    type="text"
                    value={item?.title}
                    onChange={(e) => handleChange(e, index)}
                    disabled={item?.disabled}
                    minLength={1}
                    maxLength={50}
                  />
                </FloatingLabel>
                <span
                  className={`clear-icon ${index == 0 ? "d-none" : "d-flex"}`}
                  onClick={() => handleRemove(index)}
                >
                  <LocationCrossIcon />
                </span>
              </div>
            ))}
            <div
              className={`addLocBtn mb-20 ${handleDisble() ? "opacity" : ""}`}
            >
              <IoAddCircleSharp />
              <p
                onClick={() => handleAdd()}
                className={`m-0 uLine cursor-pointer`}
              >
                Add Location
              </p>
            </div>
          </div>
          <Row className="locationFooter">
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                className="w-100 btn-submit btn-gray"
                onClick={goBackFunc}
              >
                Back
              </Button>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                disabled={handleDisableNext()}
                type="submit"
                className="w-100 btn-submit"
              >
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AddLocation;
