import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SquareLeftArrow,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { ResendOtp, VerifyOtp } from "../../services/auth/auth";
import { formatTime } from "../../utils/helper";

const ResetCode = () => {
  const navigate = useNavigate();
  const [sendingOtpCode, setSendingOtpCode] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [counter, setCounter] = useState(300);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const location = useLocation();

  const onSubmit = async (data) => {
    setDisable(true);
    if (otpCode.length == 0) {
      setError(true);
      setDisable(false);
      setErrorMessage("Please Enter OTP code");
    } else if (otpCode.length > 0 && otpCode.length < 4) {
      setError(true);
      setDisable(false);
      setErrorMessage("OTP code is incomplete");
    } else {
      try {
        let apiData = {
          email: location?.state?.email,
          otp_code: otpCode,
        };
        const res = await VerifyOtp(apiData);
        setDisable(false);
        notification.info({
          message: <span>{res.data.message}</span>,
          placement: "bottomRight",
          className: "createdNotification",
          icon: <ToastGreenCheckIcon />,
          closeIcon: <ToastCrossIcon />,
        });

        setTimeout(() => {
          navigate("/reset-password", { state: { data: apiData } });
        }, 1200);
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setDisable(false);
      }
    }
  };

  const handleOtpChange = (otp) => {
    setOtpCode(otp);
  };

  const resendCode = async () => {
    if (sendingOtpCode) return;
    try {
      if (!location.state?.email) throw new Error("Email is required!");

      setSendingOtpCode(true);
      const response = await ResendOtp({
        email: location.state?.email,
      });

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      setCounter(30);

      notification.success({
        message,
        placement: "bottomRight",
        // duration: 0,
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        message: e.message,
        placement: "bottomRight",
        // duration: 0,
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      setSendingOtpCode(false);
    }
  };

  const goBackFunc = () => {
    navigate(-1);
  };

  useEffect(() => {
    let timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="authBox resetCode">
      <div className="goBackBtn" onClick={goBackFunc}>
        <SquareLeftArrow />
      </div>
      <div className="authHeaderText">
        <h1>Reset Code</h1>
        <p>To set a new password, enter the code sent to your inbox.</p>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <Form.Group className="OtpInput-container" controlId="signInEmail">
          <OtpInput
            value={otpCode}
            onChange={(otp) => handleOtpChange(otp)}
            numInputs={4}
            isInputNum={true}
            placeholder={"----"}
            className={"OtpInput"}
            hasErrored={true}
            focusStyle="focus"
            autoFocus={true}
          />
          {/* {error && <Form.Text>{errorMessage}</Form.Text>} */}
        </Form.Group>
        <Button
          type={"submit"}
          className={"w-100 btn-submit m-t-50"}
          disabled={!!(disable || otpCode?.length < 4)}
        >
          {!disable ? <>Submit </> : <div className="load black" />}
        </Button>

        <div className="mainCounterDiv">
          {counter > 0 ? (
            <span className="d-inline-block mt-30">
              Didn't get a code? Request a new one in{" "}
              <b className="timer blue-accent">{formatTime(counter)}</b>
            </span>
          ) : (
            <p className={"text-center blue-accent cursor-pointers"}>
              Request a new
              <span className={"uLine"} onClick={() => resendCode()}>
                &nbsp;passcode now.
              </span>
            </p>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ResetCode;
