import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ActivityHeader = (props) => {
  const { newActivitiesPage, clearedActivitiesPage } = useSelector(
    (state) => state?.activities,
  );

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };
  const [totalNewActivities, setTotalNewActivities] = useState(0);
  const [totalClearedActivities, setTotalClearedActivities] = useState(0);

  useEffect(() => {
    if (newActivitiesPage?.meta?.per_page == 10) {
      setTotalNewActivities(newActivitiesPage?.meta?.total);
    }
    if (clearedActivitiesPage?.meta?.per_page == 10) {
      setTotalClearedActivities(clearedActivitiesPage?.meta?.total);
    }
  }, [newActivitiesPage, clearedActivitiesPage]);
  return (
    <>
      <div className="globalHeader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Activity</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                New ({totalNewActivities})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                Cleared ({totalClearedActivities})
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityHeader;
