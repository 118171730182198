import React, { useState, useEffect } from "react";
import { all_category_type } from "../../utils/constants";
import ResourceBody from "../../components/pages/Resource/ResourceBody";
import ResourceHeader from "../../components/pages/Resource/ResourceHeader";
import { setCategoryListByModule } from "../../store/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { setActiveResourcesTab } from "../../store/slices/resourcesSlice";

const ResourcesPage = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.resources.activeTab);
  const [activeTab, setActiveTab] = useState(selectedTab || "tab1");
  const [selectedCategory, setSelectedCategory] = useState("");
  dispatch(setActiveResourcesTab(activeTab));

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.RFQ));
  }, []);

  return (
    <>
      <div className="innerContainer">
        <ResourceHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <ResourceBody
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          seletedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
    </>
  );
};

export default ResourcesPage;
