import { Select } from "antd";
import React, { useState } from "react";
import { ArrowTriangleDown, CheckMarkIcon } from "../../../constants/svg";
import { setFilters } from "../../../store/slices/ticketSlice";
import { useDispatch, useSelector } from "react-redux";

const menuOption = [
  {
    value: "all",
    label: "Any Status",
  },
  {
    value: "new",
    label: "All Open",
  },
  {
    value: "closed",
    label: "All Closed",
  },
];

const TicketHeader = (props) => {
  const { filters } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();

  const handleChangeStatus = (e) => {
    dispatch(
      setFilters({
        ...filters,
        status: e,
      }),
    );
  };

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Tickets</h2>
        </div>
        <div className="globalHeader--right">
          <Select
            placeholder="Select Status"
            value={filters.status ? filters.status : undefined}
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            onChange={handleChangeStatus}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={menuOption}
          ></Select>
        </div>
      </div>
    </>
  );
};

export default TicketHeader;
