import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getMessaging, getToken } from "@firebase/messaging";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const database = getDatabase(app);

// if (process.env.REACT_APP_LAUNCH === "local") {
//   console.log("connect emulators");
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(db, "localhost", 8081);
//   // connectFunctionsEmulator(functions, "localhost", 5001);
//   // connectDatabaseEmulator(database, "localhost", 9000);
// }

const messaging = getMessaging(app);
export const fetchToken = () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_vapidKey })
    .then((token) => token ?? console.log("No Token Found!!"))
    .catch((error) => console.log("Error Occured: ", error));
};

export { auth, db };
