import React from "react";

export const Assets = {
  BadgeOne: require("../assets/images/new-badges-icons/badge-third-places.png"),
  BadgeTwo: require("../assets/images/new-badges-icons/badge_clap.png"),
  BadgeThree: require("../assets/images/new-badges-icons/badge_confetti.png"),
  BadgeFour: require("../assets/images/new-badges-icons/badge_crown.png"),
  BadgeFive: require("../assets/images/new-badges-icons/badge_eco_friendly.png"),
  BadgeSix: require("../assets/images/new-badges-icons/badge_fire.png"),
  BadgeSeven: require("../assets/images/new-badges-icons/badge_first_place.png"),
  BadgeEight: require("../assets/images/new-badges-icons/badge_gem.png"),
  BadgeNine: require("../assets/images/new-badges-icons/badge_gift.png"),
  BadgeTen: require("../assets/images/new-badges-icons/badge_key.png"),
  BadgeEleven: require("../assets/images/new-badges-icons/badge_kiss.png"),
  BadgeTwelve: require("../assets/images/new-badges-icons/badge_lightbulb.png"),
  BadgeThirteen: require("../assets/images/new-badges-icons/badge_lightening.png"),
  BadgeFourteen: require("../assets/images/new-badges-icons/badge_magic_hat.png"),
  BadgeFifteen: require("../assets/images/new-badges-icons/badge_money_bag.png"),
  BadgeSixteen: require("../assets/images/new-badges-icons/badge_rocket.png"),
  EmojiOne: require("../assets/images/surveys/emojione.png"),
  EmojiTwo: require("../assets/images/surveys/emojitwo.png"),
  EmojiThree: require("../assets/images/surveys/emojithree.png"),
  EmojiFour: require("../assets/images/surveys/emojifour.png"),
  EmojiFive: require("../assets/images/surveys/emojifive.png"),
  QuizAvatarOne: require("../assets/images/quizzes/avatarone.png"),
  QuizAvatarTwo: require("../assets/images/quizzes/avatartwo.png"),
  WhiteMainLogo: require("../assets/images/white-icon.png"),
  AuthBackgroundImage: require("../assets/images/admin-landing-page-bg.jpg"),
  SidebarTransparentImage: require("../assets/images/sidebar-image-light.png"),
  DashboardImage: require("../assets/images/dashboardImage.png"),
  AccountSetupImage: require("../assets/images/account-setup-img.png"),
  noDataFolder: require("../assets/images/no-data.png"),
  notificationIcon: require("../assets/images/notification-icon.png"),
  placeHolderIcon: require("../assets/images/placeholdericon.png"),
  roundedCameraIcon: require("../assets/images/roundedCameraIcon.png"),
  ProfileAvatar: require("../assets/images/request/avatar.png"),
  EventImg1: require("../assets/images/events/event1.png"),
  EventImg2: require("../assets/images/events/event2.png"),
  EventImg3: require("../assets/images/events/event3.png"),
  GalleryImg1: require("../assets/images/events/galleryImg-1.png"),
  GalleryImg2: require("../assets/images/events/galleryImg-2.png"),
  GalleryImg3: require("../assets/images/events/galleryImg-3.png"),
  GalleryImg4: require("../assets/images/events/galleryImg-4.png"),
  GalleryImg5: require("../assets/images/events/galleryImg-5.png"),
  GoalImg1: require("../assets/images/GoalsProfile.png"),
  shareModal: require("../assets/images/shareModalImage.png"),
  shareTaskImage: require("../assets/images/share-task-image.png"),
  eventShareModal: require("../assets/images/events/share-event.png"),
  resourceimage01: require("../assets/images/resources/image01.jpg"),
  resourceimage02: require("../assets/images/resources/image02.png"),
  resourceimage03: require("../assets/images/resources/image03.png"),
  resourceimage04: require("../assets/images/resources/image04.png"),
  MemberAvatar: require("../assets/images/members/avatar.png"),
  FirstBadge: require("../assets/images/members/onebadge.png"),
  SecondBadge: require("../assets/images/members/twobadge.png"),
  ThirdBadge: require("../assets/images/members/threebadge.png"),
  FourthBadge: require("../assets/images/members/fourthbadge.png"),
  FiveBadge: require("../assets/images/members/fivebadge.png"),
  FifthBadge: require("../assets/images/members/sixbadge.png"),
  SixthBadge: require("../assets/images/members/sevenbdage.png"),
  EightBadge: require("../assets/images/members/eightbadge.png"),
  SparkleImge: require("../assets/images/sparkle-img.png"),
  ModalBanner: require("../assets/images/banner.png"),
  surveyimage1: require("../assets/images/surveys/survey1 (1).png"),
  surveyimage2: require("../assets/images/surveys/survey1 (3).png"),
  surveyimage3: require("../assets/images/surveys/survey1 (4).png"),
  surveyimage4: require("../assets/images/surveys/survey1 (5).png"),
  surveyimage5: require("../assets/images/surveys/survey1 (6).png"),
  surveyimage6: require("../assets/images/surveys/survey1.png"),
  surveyimage7: require("../assets/images/surveys/survey2.jpg"),
  profileImageBig: require("../assets/images/profile/profile-rec.png"),
  everyOneImage: require("../assets/images/everyoneImage.png"),
  NeweveryOneImage: require("../assets/images/everyone.png"),
  NeweveryOneImageSvg: require("../assets/images/everyone.svg"),
  DefaultGroup: require("../assets/images/group.png"),
  ProfileAvatarOne: require("../assets/images/avatars/1.png"),
  ProfileAvatarTwo: require("../assets/images/avatars/2.png"),
  ProfileAvatarThree: require("../assets/images/avatars/3.png"),
  ProfileAvatarFour: require("../assets/images/avatars/4.png"),
  DeleteModalMember: require("../assets/images/deletemembermodal.png"),
  DeleteModalGroup: require("../assets/images/deletegroupmodal.png"),
  PdfImage: require("../assets/images/pdf.png"),
  MarketIcon: require("../assets/images/locationdefualtThumb.png"),
  GalleryOne: require("../assets/images/gallery/gallery1.png"),
  GalleryTwo: require("../assets/images/gallery/gallery2.png"),
  GalleryThree: require("../assets/images/gallery/gallery3.png"),
  ActivityAvatarOne: require("../assets/images/activity/avatarone.png"),
  ActivityAvatarTwo: require("../assets/images/activity/avatartwo.png"),
  ResAvatarOne: require("../assets/images/responses/res-one.png"),
  ResAvatarTwo: require("../assets/images/responses/res-two.png"),
  ResAvatarThree: require("../assets/images/responses/res-three.png"),
  ResAvatarFour: require("../assets/images/responses/res-four.png"),
  ResAvatarFive: require("../assets/images/responses/res-five.png"),
  ResAvatarSix: require("../assets/images/responses/res-six.png"),
  AdminProfile: require("../assets/images/avatars/profile.png"),
  micIcon: require("../assets/images/micIcon.png"),
  pinIcon: require("../assets/images/pinIcon.png"),
  iconsImage: require("../assets/images/iconsImage.png"),
  RoundedStaffIcon: require("../assets/images/rounded-staff-icon.png"),
  MessageAvatar: require("../assets/images/message/messageavatar.png"),
  SideArrows: require("../assets/images/aa.png"),
  LeftArrow: require("../assets/images/leftarrow.png"),
  RightArrow: require("../assets/images/rightarrow.png"),
  GroupChatAvatar: require("../assets/images/groupavatar.png"),
  loadingGif: require("../assets/images/SS-circle-preloader.gif"),
  GroupOneAvatar: require("../assets/images/group-icons/admin_group.png"),
  GroupTwoAvatar: require("../assets/images/group-icons/barbers_group.png"),
  GroupThreeAvatar: require("../assets/images/group-icons/managers_group_icon.png"),
  GroupFourAvatar: require("../assets/images/group-icons/stylist_group.png"),
  ProfilePlaceholder: require("../assets/images/avatars/profile-placeholder.png"),
  GeneralPlaceholder: require("../assets/images/avatars/general-placeholder.png"),
  EventsAvatar: require("../assets/images/eventsAvatar.png"),
  RocketModalImg: require("../assets/images/rocket.png"),
  MobileLandingPage: require("../assets/images/mobile-landing-img.png"),
  SSWhiteLogo: require("../assets/images/ss-white-logo.png"),
  Smile: require("../assets/images/smile.png"),
  Warning: require("../assets/images/warning.png"),
  EmptyWhiteBg: require("../assets/images/Empty-background.png"),
  UploadClouldImg: require("../assets/images/upload-cloud.png"),
};
