import axios from "axios";
import { constant } from "../utils/constants";
import {
  EmptyLocalStorage,
  GetTokenLocalStorage,
} from "./localStorage/localStorage";

axios.defaults.baseURL = constant.BASE_URL;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(function (config) {
  const token = GetTokenLocalStorage();
  config.headers.Authorization = token ? `bearer ${token}` : "";
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status == 401) {
      setTimeout(() => {
        EmptyLocalStorage();
        window.location.href = "/";
      }, 1500);
    }
    // return Promise.reject(error.response.data);
    return Promise.reject(error?.response?.data || { message: "Server Error" });
  },
);

export default api;
