import React, { useEffect, useRef, useState } from "react";
import { Checkbox, notification } from "antd";
import {
  DropdownIcon,
  NewEveryOneIcon,
  NewGroupIcon,
  NewMemberIcon,
  NewRecipientIcon,
  RoundedStaffIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSalonTeamsLoading,
  setSalonTeams,
} from "../../store/slices/salonTeamsSlice";
import { GetSalonTeams } from "../../services/salonTeams/salonTeams";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";

const CustomTreeSelect = (props) => {
  const [tempSelectedCategory, setTempSelectedCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [show, setShow] = useState(false);
  const mainWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const authUser = GetAuthUserLocalStorage();
  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const [open, setOpen] = useState(false);

  const handleRadioChange = (parentNode, value) => {
    if (parentNode == "everyone") {
      setSelectedCategory(parentNode);
      setSelectedData(
        salonTeams?.data?.everyone?.map((item) => item?.id?.toString()),
      );
      props?.handleChange(
        parentNode,
        salonTeams?.data?.everyone?.map((item) => item?.id?.toString()),
      );
    } else {
      setSelectedCategory(parentNode);
      setSelectedData([]);
    }
  };

  const handleCheckboxChange = (parentNode, value) => {
    if (selectedData?.includes(value)) {
      let tempData = [...selectedData];
      const index = tempData.indexOf(value);
      tempData.splice(index, 1);
      setSelectedData(tempData);
      props?.handleChange(parentNode, tempData);
    } else {
      setSelectedData([...selectedData, value.toString()]);
      props?.handleChange(parentNode, [...selectedData, value.toString()]);
    }
  };

  const getSalonTeams = async () => {
    try {
      let everyone = [];
      let groups = [];
      let members = [];

      const params = {
        invited: 0,
      };
      dispatch(setIsSalonTeamsLoading(true));
      const res = await GetSalonTeams(authUser?.salon?.id, params);
      res?.data?.data?.map((item) => {
        if (item?.key == "Every One") {
          item?.data?.map((dt) => {
            everyone.push(dt);
          });
        } else if (item?.key == "Groups") {
          item?.data?.map((dt) => {
            groups.push(dt);
          });
        } else {
          item?.data?.map((dt) => {
            members.push(dt);
          });
        }
      });

      dispatch(
        setSalonTeams({ data: { everyone, groups, members }, meta: null }),
      );
      dispatch(setIsSalonTeamsLoading(true));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const returnIcon = () => {
    if (!tempSelectedCategory) {
      return <RoundedStaffIcon />;
    } else if (tempSelectedCategory == "everyone") {
      return <NewEveryOneIcon />;
    } else if (tempSelectedCategory == "group") {
      return <NewGroupIcon />;
    } else if (tempSelectedCategory == "member") {
      return <NewMemberIcon />;
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        mainWrapperRef.current &&
        !mainWrapperRef.current.contains(e.target)
      ) {
        setShow(false);
        setOpen(false);
      }
    };

    getSalonTeams();

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (props?.defaultSelectedData?.length > 0) {
      setSelectedData(props?.defaultSelectedData);
    }
    setSelectedCategory(props?.selectedCategory);
    setTempSelectedCategory(props?.selectedCategory);
  }, [props?.defaultSelectedData]);

  return (
    <div
      onClick={() => !props?.isedit && setShow(true)}
      ref={mainWrapperRef}
      className={`custom-tree-select customLabelGroup datePickerWrapper form-floating mb-20 hasValue `}
    >
      <FloatingLabel label={props?.label}>
        <Form.Control
          readOnly
          className="text-capitalize"
          placeholder={props?.placeholder}
          value={
            tempSelectedCategory == "everyone"
              ? "Everyone"
              : tempSelectedCategory == "group"
                ? "Groups"
                : tempSelectedCategory == "member"
                  ? "Members"
                  : ""
          }
        />
      </FloatingLabel>
      <div className="icon">
        {!props?.isedit && (
          <span
            className={` ${tempSelectedCategory == "everyone" ? "d-none" : tempSelectedCategory && selectedData?.length > 0 ? "counter d-block" : "d-none"}`}
          >
            {selectedData?.length}
          </span>
        )}
        {returnIcon()}
      </div>

      <div className={`main-wrapper ${show ? "d-block" : "d-none"}`}>
        <div>
          <label
            style={{ marginBottom: "13px" }}
            htmlFor="everyone"
            className="d-flex align-items-center justify-content-between"
          >
            <span> Everyone</span>
            <Form.Check
              type="radio"
              checked={selectedCategory == "everyone"}
              name="parent"
              id="everyone"
              value="everyone"
              onChange={(value) => {
                handleRadioChange("everyone", value);
                setTempSelectedCategory("everyone");
                setShow(false);
              }}
            />
          </label>

          <div>
            <label
              htmlFor="group"
              style={{ marginBottom: "13px" }}
              className="d-flex align-items-center justify-content-between"
            >
              <span>Groups</span>
              <input
                className="hide-radio"
                type="radio"
                checked={selectedCategory == "group"}
                onChange={(value) => {
                  handleRadioChange("group", value);
                  setTempSelectedCategory(null);
                }}
                onClick={() => setOpen(!open)}
                id="group"
                name="parent"
                value="group"
              />
              <DropdownIcon />
            </label>

            <div
              className={`dropdown-listing ${selectedCategory == "group" && open ? "d-block" : "d-none"}`}
            >
              {salonTeams?.data?.groups?.map((item, index) => (
                <Checkbox
                  reverse
                  type="checkbox"
                  checked={selectedData?.includes(item?.id.toString())}
                  name={`group-children-${item?.id.toString()}`}
                  value={item?.id.toString()}
                  className="mb-3 ps-3"
                  onChange={(e) => {
                    handleRadioChange("group");
                    handleCheckboxChange("group", e.target.value, index);
                    setTempSelectedCategory("group");
                  }}
                >
                  {item?.name}
                </Checkbox>
                // <Form.Check
                //     reverse
                //     type="checkbox"
                //     checked={selectedData?.includes(item?.id.toString())}
                //     name={`group-children-${item?.id.toString()}`}
                //     value={item?.id.toString()}
                //     onChange={(e) => {
                //         handleRadioChange("group")
                //         handleCheckboxChange("group", e.target.value, index)
                //         setTempSelectedCategory("group")
                //     }}
                //     label={item?.name}
                // />
              ))}
            </div>
          </div>

          <div>
            <label
              htmlFor="member"
              style={{ marginBottom: "13px" }}
              className="d-flex align-items-center justify-content-between"
            >
              <span>Members</span>
              <input
                className="hide-radio"
                type="radio"
                checked={selectedCategory == "member"}
                onChange={(value) => {
                  handleRadioChange("member", value);
                  setTempSelectedCategory(null);
                }}
                onClick={() => setOpen(!open)}
                name="parent"
                id="member"
                value="member"
              />
              <DropdownIcon />
            </label>

            <div
              className={`dropdown-listing  ${selectedCategory == "member" && open ? "d-block" : "d-none"} `}
            >
              {salonTeams?.data?.members?.map((item, index) => (
                <Checkbox
                  className="mb-3 ps-3"
                  reverse
                  checked={selectedData?.includes(item?.id.toString())}
                  name={`member-children-${item?.id.toString()}`}
                  value={item?.id.toString()}
                  onChange={(e) => {
                    handleRadioChange("member");
                    handleCheckboxChange("member", e.target.value, index);
                    setTempSelectedCategory("member");
                  }}
                >
                  {item?.name}
                </Checkbox>
                // <label
                //     className='mb-3 d-flex align-items-center justify-content-between'
                //     htmlFor={item?.id?.toString()}
                // >
                //     <span>{item?.name}</span>
                //     <Form.Check
                //         type="checkbox"
                //         checked={selectedData?.includes(item?.id.toString())}
                //         name={`member-children-${item?.id.toString()}`}
                //         value={item?.id.toString()}
                //         onChange={(e) => {
                //             handleRadioChange("member")
                //             handleCheckboxChange("member", e.target.value, index)
                //             setTempSelectedCategory("member")
                //         }}

                //     />
                // </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTreeSelect;
