import { createSlice } from "@reduxjs/toolkit";

export const gallerysSlice = createSlice({
  name: "gallerys",
  initialState: {
    isGallerysLoading: true,
    uploadType: null,
    renderList: 1,
    folderId: null,
    galleryAddEditForm: {
      show: false,
      formType: null,
    },
    gallerys: {
      meta: null,
      data: [],
    },
    filters: {
      type: null,
    },
    singleGallery: {
      isSingleGalleryLoading: true,
      data: null,
    },
    galleryData: [],
  },
  reducers: {
    setIsGallerysLoading: (state, action) => {
      state.isGallerysLoading = action.payload;
    },
    setGallerys: (state, action) => {
      state.gallerys.data = action.payload.data;
      state.gallerys.meta = action.payload.meta;
    },

    setIsSingleGalleryLoading: (state, action) => {
      state.singleGallery.isSingleGalleryLoading = action.payload;
    },
    setSingleGallery: (state, action) => {
      state.singleGallery.data = action.payload;
    },
    setUploadType: (state, action) => {
      state.uploadType = action.payload;
    },
    setRenderList: (state, action) => {
      state.renderList = action.payload;
    },
    setFolderId: (state, action) => {
      state.folderId = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setGalleryAddEditForm: (state, action) => {
      state.galleryAddEditForm = action.payload;
    },
    setGalleryData: (state, action) => {
      state.galleryData = action.payload;
    },
  },
});

export const {
  setIsGallerysLoading,
  setGallerys,
  setUploadType,
  setIsSingleGalleryLoading,
  setSingleGallery,
  setRenderList,
  setFolderId,
  setFilters,
  setGalleryAddEditForm,
  setGalleryData,
} = gallerysSlice.actions;

export default gallerysSlice.reducer;
