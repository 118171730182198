import { Image, message, Tabs } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ModalCloseIcon } from "../../../../../constants/svg";
import { setFileUploadTabNo } from "../../../../../store/slices/eventsSlice";
import { setSelectPreUploadedFile } from "../../../../../store/slices/globalSlice";
import FolderTab from "../../../../Common/FolderTab";
import GalleryTab from "../../../../Common/GalleryTab";
import React, { useEffect, useState } from "react";
import FileUploader from "../../../../Common/FileUploader";

const FourthStep = ({
  register,
  control,
  setValue,
  errors,
  getValues,
  submitted = false,
}) => {
  const dispatch = useDispatch();
  const { fileUploadTabNo, selectedData } = useSelector(
    (state) => state.events,
  );
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(getValues("previewImage"));
  }, [getValues("previewImage"), image]);
  // Dragger image options
  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    onChange(info) {},
    onDrop(e) {},
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  const handleTabsChange = (n) => {
    setValue("uploadFromLocal", "");
    setValue("uploadFromGallery", "");
    setValue("uploadFromFolder", "");
    dispatch(setSelectPreUploadedFile(""));
    dispatch(setFileUploadTabNo(n));
  };

  // Table Component data

  const TabsContent = [
    {
      key: 1,
      label: `Gallery`,
      children: (
        <>
          <Controller
            control={control}
            name="uploadFromGallery"
            rules={{
              required:
                fileUploadTabNo == 2 &&
                getValues("uploadFromFolder") == "" &&
                getValues("uploadFromGallery") == "" &&
                getValues("localUpload") == ""
                  ? "Please select file from gallery"
                  : false,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <GalleryTab
                incSearch={true}
                handleChange={(e) => {
                  setValue("previewImage", "");
                  onChange(e);
                }}
                submitted={submitted}
              />
            )}
          />
          {/* {errors.uploadFromGallery && (
                        <Form.Text>{errors.uploadFromGallery.message}</Form.Text>
                    )} */}
        </>
      ),
    },
    {
      key: 2,
      label: `Folders`,
      children: (
        <>
          <Controller
            control={control}
            name="uploadFromFolder"
            rules={{
              required:
                fileUploadTabNo == 3 &&
                getValues("uploadFromFolder") == "" &&
                getValues("uploadFromGallery") == "" &&
                getValues("localUpload") == ""
                  ? "Please select file from folder"
                  : false,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FolderTab
                handleChange={(e) => {
                  setValue("previewImage", "");
                  onChange(e);
                }}
                submitted={submitted}
              />
            )}
          />
          {/* {errors.uploadFromFolder && (
                        <Form.Text>{errors.uploadFromFolder.message}</Form.Text>
                    )} */}
        </>
      ),
    },
    {
      key: 3,
      label: `Upload`,
      children: (
        <div style={{ marginTop: "16px" }}>
          <Controller
            control={control}
            name="uploadFromLocal"
            rules={{
              required:
                fileUploadTabNo == 1 &&
                getValues("uploadFromFolder") == "" &&
                getValues("uploadFromGallery") == "" &&
                getValues("localUpload") == ""
                  ? "Please Select file from local drive"
                  : false,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FileUploader
                {...props}
                fileList={value == "" ? [] : [value]}
                onChange={(e) => {
                  const fileList = e.fileList;
                  setValue("previewImage", "");
                  onChange(
                    fileList.length
                      ? fileList.length > 1
                        ? fileList[1]
                        : fileList[0]
                      : "",
                  );
                }}
                beforeUpload={() => {
                  /* update state here */
                  return false;
                }}
                className="custom-upload border-0 bg-0"
                listType="picture"
                limitMegabyteSize={4}
              />
            )}
          />
          {/* {errors.uploadFromLocal && (
                        <Form.Text>{errors.uploadFromLocal.message}</Form.Text>
                    )} */}
        </div>
      ),
    },
  ];

  const handleDelete = () => {
    setImage(null);
    setValue("uploadFileFromLocal", "");
    setValue("previewImage", "");
  };
  return (
    <>
      <p className="proTipText mb-20">
        <span>Pro Tip:</span> Upload a cover photo or choose from the gallery.
        For best results, use an image with little or no text.
      </p>
      {/* */}

      <Tabs
        defaultActiveKey="1"
        items={TabsContent}
        onChange={handleTabsChange}
      />
      {image && (
        <Row>
          <Col md={12}>
            <div className="img-upload-preview">
              {/* <strong>Preview</strong> */}
              {/* <br /> */}
              <Image
                className="ant-img-custom-css upload-img"
                preview={false}
                src={image}
              />
              {!submitted && (
                <Button
                  type="button"
                  className={"delete-img"}
                  onClick={() => handleDelete()}
                >
                  <ModalCloseIcon />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FourthStep;
