import { createSlice } from "@reduxjs/toolkit";

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    renderTable: 1,
    isUpcomingEventsLoading: true,
    isPastEventsLoading: true,
    disableActionBtn: false,
    showGenericModal: false,
    selectedData: null,
    isPageLoading: false,
    reRenderEventDetailPage: 1,
    upcomingEvents: {
      meta: null,
      data: [],
    },
    pastEvents: {
      meta: null,
      data: [],
    },

    singleEvent: {
      isSingleEventLoading: true,
      data: null,
    },
    fileUploadTabNo: 1,
    eventAddEditFormVisibility: {
      show: false,
      formType: null,
    },
    activeTab: "",
  },
  reducers: {
    setReRenderEventDetailPage: (state, action) => {
      state.reRenderEventDetailPage = action.payload;
    },
    setIsUpcomingEventsLoading: (state, action) => {
      state.isUpcomingEventsLoading = action.payload;
    },
    setIsPastEventsLoading: (state, action) => {
      state.isPastEventsLoading = action.payload;
    },
    setIsPageLoading: (state, action) => {
      state.isPageLoading = action.payload;
    },
    setUpcomingEvents: (state, action) => {
      state.upcomingEvents.data = action.payload.data;
      state.upcomingEvents.meta = action.payload.meta;
    },
    setPastEvents: (state, action) => {
      state.pastEvents.data = action.payload.data;
      state.pastEvents.meta = action.payload.meta;
    },

    setIsSingleEventLoading: (state, action) => {
      state.singleEvent.isSingleEventLoading = action.payload;
    },
    setSingleEvent: (state, action) => {
      state.singleEvent.data = action.payload;
    },
    setFileUploadTabNo: (state, action) => {
      state.fileUploadTabNo = action.payload;
    },
    setDisableActionBtn: (state, action) => {
      state.disableActionBtn = action.payload;
    },
    setShowGenericModal: (state, action) => {
      state.showGenericModal = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setEventAddEditFormVisibility: (state, action) => {
      state.eventAddEditFormVisibility = action.payload;
    },
    setRenderTable: (state, action) => {
      state.renderTable = action.payload;
    },
    setActiveEventTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setIsUpcomingEventsLoading,
  setIsPastEventsLoading,
  setUpcomingEvents,
  setPastEvents,
  setIsSingleEventLoading,
  setSingleEvent,
  setFileUploadTabNo,
  setDisableActionBtn,
  setShowGenericModal,
  setSelectedData,
  setEventAddEditFormVisibility,
  setRenderTable,
  setIsPageLoading,
  setReRenderEventDetailPage,
  setActiveEventTab,
} = eventsSlice.actions;

export default eventsSlice.reducer;
