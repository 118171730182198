import React, { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useBlocker, useLocation, useNavigate } from "react-router-dom";
import LeavePageModal from "../components/Modals/LeavePageModal";
import { useSelector } from "react-redux";
function BlockerLayout(props) {
  const { blockerModal } = useSelector((state) => state.global);
  const [leavePageModal, setLeavePageModal] = useState(false);
  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation, historyAction }) => {
      let locationChanged = currentLocation.pathname != nextLocation.pathname;
      let previousLocation = currentLocation.pathname;
      let newLocation = nextLocation.pathname;

      let toBlock = new RegExp(
        "flashcard/flash-card-builder|surveysbuilder|quiz/quizbuilder",
        "gmi",
      ).test(previousLocation);

      if (blockerModal && locationChanged && !leavePageModal) {
        setLeavePageModal(true);
      }

      return blockerModal && locationChanged && !leavePageModal;
    },
    [blockerModal],
  );
  let blocker = useBlocker(shouldBlock);

  useEffect(() => {}, [leavePageModal, blocker]);
  return (
    <>
      <Outlet></Outlet>
      {blocker && blocker.state != "unblocked" && (
        <LeavePageModal
          show={leavePageModal}
          onHide={() => setLeavePageModal(false)}
          setLeavePageModal={setLeavePageModal}
          // setModalShow={checkAndNavigate}
          // navigateNext={checkAndNavigate}
          blocker={blocker}
          resetForm={props?.resetForm}
        />
      )}
    </>
  );
}

export default BlockerLayout;
