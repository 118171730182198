import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetBadges = (params) => api.get(apiUrl.constants, { params });
export const GiveBadge = (data) => api.post(apiUrl.user_activity_badges, data);
export const GivePoint = (data) => api.post(apiUrl.user_activity_points, data);
export const GetSalonAchievements = (params) =>
  api.get(apiUrl.salonAchievements, { params });
export const UpdateSalonAchievement = (id, payload) =>
  api.put(apiUrl.salonAchievements + "/" + id, payload);
