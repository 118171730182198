import { ConfigProvider, Dropdown, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  BinocularsLight,
  EditIcon,
  MoreIcon,
  NewSparkleSmallIcon,
  NoDataIcon,
  RecycleBin,
} from "../../../constants/svg";
import {
  deleteAlert,
  getAlerts,
} from "../../../services/alerts/frontend-alerts";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setAlerts,
  setIsAlertsLoading,
} from "../../../store/slices/aletsSlice";
import { alert_type, tableHeight } from "../../../utils/constants";
import DeleteModal from "../../Modals/DeleteModal";
import CreatePushAlertModal from "./CreatePushAlertModal";
import { hasAccess, tableLoader } from "../../../utils/helper";
import { FaStar } from "react-icons/fa";
import LazyLoadTable from "../../Table/LazyLoadTable";

const PushAlertBody = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { alerts, isAlertsLoading } = useSelector((state) => state.alerts);
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const [selectedData, setSelectedData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const methods = useForm();
  const authUser = GetAuthUserLocalStorage();
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const actionMenu = (records) => {
    const showEdit =
      records.type == alert_type.recurring ||
      records.type == alert_type.scheduled;
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink to={`/alerts/details/${records?.key}`} className={"black"}>
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      showEdit &&
        hasAccess(records?.user) && {
          key: "2",
          label: (
            <>
              <div
                onClick={() => {
                  setOpen(true);
                  setModalShow(true);
                  setSelectedData(records);
                }}
                className="d-flex align-items-center w-100"
              >
                <EditIcon />
                Edit
              </div>
            </>
          ),
        },
      hasAccess(records?.user) && {
        key: "3",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              setSelectedData(records);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="adminRoleBox lockBox">
            <h6>
              <span>
                <NewSparkleSmallIcon />
              </span>{" "}
              Posted by Salon Symphony
            </h6>
          </div>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "Alert Title",
      dataIndex: "alertTitle",
      key: "alertTitle",
      sorter: (a, b) => a.alertTitle.title.localeCompare(b.alertTitle.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <>
          {record.alertTitle.lock && (
            <Dropdown
              menu={{
                items,
              }}
              overlayClassName="singleDropdown"
              className="singLock"
              placement="bottom"
              open={false}
            >
              <Space>
                {/* <LockIcon /> */}
                <FaStar
                  color="#A8A8A8"
                  style={{ ":hover": { color: "#c4c4c4" } }}
                />
              </Space>
            </Dropdown>
          )}
          <Link
            to={{ pathname: `/alerts/details/${record?.key}` }}
            className={"black"}
          >
            <Space>
              <span>{record.alertTitle.title}</span>
            </Space>
          </Link>
        </>
      ),
      width: "45%",
    },
    {
      title: "Send Type",
      dataIndex: "sendType",
      key: "sendType",
      sorter: (a, b) => a.sendType.main.localeCompare(b.sendType.main),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p>
          {record.sendType.main}
          <span className="d-block">{record.sendType.sub}</span>
        </p>
      ),
      align: "left",
      width: "15%",
    },
    {
      title: "Last Sent",
      dataIndex: "lastSent",
      key: "lastSent",
      sorter: (a, b) => {
        if (a.lastSent.main == "--") {
          return -1;
        } else if (b.lastSent.main == "--") {
          return 1;
        } else {
          const dateA = moment(a.lastSent.main, "MMM DD, YYYY");
          const dateB = moment(b.lastSent.main, "MMM DD, YYYY");
          return dateA?.isBefore(dateB) ? -1 : dateA?.isAfter(dateB) ? 1 : 0;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      // defaultSortOrder: "descend",
      render: (text, record) => {
        return (
          <p>
            {record.lastSent.main}
            <span className="d-block">{record.ls}</span>
          </p>
        );
      },
      align: "left",
      width: "15%",
    },
    {
      title: "Seen By",
      dataIndex: "seenBy",
      key: "seenBy",
      className: "center-column",
      sorter: (a, b) => a.seenBy > b.seenBy,
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => {
        const attendeeNames = record.attendeeNames || [];
        const attendeeTooltipContent = (
          <div className="attendeeNames">
            {attendeeNames.slice(0, 7).map((name, index) => (
              <React.Fragment key={index}>
                <span>{name}</span>
                {index < attendeeNames.slice(0, 7).length - 1}
              </React.Fragment>
            ))}
            {attendeeNames.length > 7 && (
              <>
                <strong>+{attendeeNames.length - 7} more</strong>
              </>
            )}
          </div>
        );

        return (
          <div className="">
            {record.seenBy != 0 ? (
              <Tooltip
                title={attendeeTooltipContent}
                placement="bottom"
                overlayClassName="custom-tooltip"
                color="purple"
              >
                <div className="seenBy dottedRoundedBox">
                  <span>{record.seenBy}</span>
                </div>
              </Tooltip>
            ) : (
              <div className="seenBy dottedRoundedBox">
                <span>{record.seenBy}</span>
              </div>
            )}
          </div>
        );
      },

      align: "center",
      width: "15%",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      width: "10%",
    },
  ];

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await deleteAlert(selectedData, setDisable, showModal, setShowModal);
    fetchAlerts();
  };

  const fetchAlerts = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];
    const params = {
      // page: props?.page?.currentPage,
      // "per-page": props?.page?.perPage,
      // is_paginated: 1,
      // type: props?.selectedCategory,
      technologies,
      softwares,
      salon_id: currentSalonId,
      products,
    };

    await getAlerts(params, dispatch, setIsAlertsLoading, setAlerts);
  };

  useEffect(() => {
    fetchAlerts();
  }, [props.selectedCategory, currentSalonId, salon, isSuperAdmin]); // props?.page?.currentPage, props?.page?.perPage,

  return (
    <>
      <div className="content">
        <div
          className={alerts?.data?.length == 0 ? "task__container_empty" : ""}
        >
          <ConfigProvider
            renderEmpty={() => (
              <div className="noSearchData">
                <NoDataIcon />
                <h5>Alerts you've created will appear here.</h5>
                <h6>Click "Create Alert" to get started.</h6>
              </div>
            )}
          >
            <Table
              /* locale={{
                emptyText:(   <Empty
                    image={Assets.EmptyWhiteBg}
                    description={''}
                />),
              }} */
              loading={tableLoader(isAlertsLoading)}
              columns={columns}
              dataSource={alerts?.data}
              bordered={false}
              store={{ store: "alerts", param: "alerts" }}
              pagination={false}
              // pagination={{
              //   onChange: (currPage) =>
              //     props?.setPage({ ...props?.page, currentPage: currPage }),
              //   pageSize: props?.page?.perPage,
              //   current: props?.page?.currentPage,
              //   total: alerts?.meta?.total || 0,
              //   pageSizeOptions: [10, 20, 50, 100, "All"],
              //   showSizeChanger: false,
              // }}
              scroll={{ x: "max-content", y: tableHeight.height }}
              responsive={true}
              size={"large"}
              className="alertTable"
              showSorterTooltip={false}
            />
          </ConfigProvider>
        </div>

        {/* Delete Modal */}
        <DeleteModal
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Alert?"
          disable={disable}
          handleDelete={handleDelete}
          para={
            <>
              Are you sure you want to delete this
              <b>
                {selectedData?.type == alert_type?.immediate
                  ? " immediate "
                  : selectedData?.type == alert_type?.recurring
                    ? " recurring "
                    : " scheduled "}{" "}
                alert?
              </b>
              <br />
              Important: This alert cannot be recovered.
            </>
          }
          notificationMessage="Alert"
        />
      </div>

      {/* Edit Drawer */}

      <FormProvider {...methods}>
        {modalShow && (
          <CreatePushAlertModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            setModalShow={setModalShow}
            methods={methods}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isedit={true}
          />
        )}
      </FormProvider>
    </>
  );
};

export default PushAlertBody;
