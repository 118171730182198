import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetUsers = (params) => api.get(apiUrl.users, { params });
export const UpdateUser = (id, data) => api.put(`${apiUrl.users}/${id}`, data);
export const AddUser = (data) => api.post(apiUrl.users, data);
export const GetSingleUser = (id) => api.get(`${apiUrl.users}/${id}`);
export const InviteUser = (data) => api.post(apiUrl.inviteUser, data);
export const UpdateInvitedUser = (id, data) =>
  api.put(`${apiUrl.inviteUser}/${id}`, data);
export const ResendInvitation = (id) =>
  api.get(`${apiUrl.resendInvitation}/${id}`);
export const CancelInvitation = (id) =>
  api.delete(`${apiUrl.inviteUser}/${id}`);
