import React from "react";
import {
  BillingHelp,
  Community,
  EarnRewards,
  FeatureTour,
  HelpGuides,
  HelpVideos,
  MakeRequest,
  ModalCloseIcon,
  Partnerships,
  ReportBug,
  WhiteTextLogo,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { ZENDESK_FORM } from "../../../constants/zendesk";
import { useSelector } from "react-redux";

const Helppopup = (props) => {
  const user = GetAuthUserLocalStorage();
  const { me } = useSelector((state) => state?.auth);
  const handleClose = () => {
    return props.setClickedprop(false);
  };

  const redirectToZendesk = (supportType) => {
    const baseUrl = ZENDESK_FORM.BASE_URL;
    const reportBugFormId = ZENDESK_FORM.FORM_IDS[supportType];

    const emailKey = ZENDESK_FORM.FIELD_KEYS.EMAIL;
    const fullNameKey = ZENDESK_FORM.FIELD_KEYS.FULL_NAME;
    const compnayNameKey = ZENDESK_FORM.FIELD_KEYS.COMPANY_NAME;

    const salonName = user.salon.name;
    const fullName = user.name;
    const email = user.email;

    const navigateTo = `${baseUrl}?ticket_form_id=${reportBugFormId}&${emailKey}=${email}&${fullNameKey}=${fullName}&${compnayNameKey}=${salonName}`;
    window.open(navigateTo, "_blank");
  };
  let firstName = me?.data?.name.split(" ");
  return (
    <>
      <div className="main_box">
        <div className="main_logo">
          <WhiteTextLogo />
          <span onClick={handleClose}>
            <ModalCloseIcon />
          </span>
        </div>
        <div className="main_txt">
          <h2>Hi there, {firstName ? firstName[0] : "-"}!</h2>
          <p>Let’s make the beauty industry more productive, together!</p>
        </div>
        <div className="help_listing">
          <h4>Help Center</h4>
          <p>
            Get started with the tools below or{" "}
            <a
              href="https://support.mysalonsymphony.com/hc/en-us/requests/new"
              target="_blank"
              rel="noreferrer"
            >
              Contact Support.
            </a>
          </p>
          <div className="help_listing_box">
            <a
              href="https://support.mysalonsymphony.com/hc/en-us"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <HelpGuides />
              <h5>Help Guides</h5>
            </a>

            <a
              href="https://www.youtube.com/playlist?list=PLlCLr9OhjpCUwx_fVCRKU2yPJWWA02LWP"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <HelpVideos />
              <h5>Help Videos</h5>
            </a>

            <a
              href="https://www.youtube.com/playlist?list=PLlCLr9OhjpCX1Y4Gd-FPFIeuIhioJoVye"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <FeatureTour />
              <h5>Feature Tour</h5>
            </a>

            <a
              href="https://salonsymphonysoftware.com/partnership/"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <Partnerships />
              <h5>Partnerships</h5>
            </a>

            <a
              href="https://salonsymphonysoftware.com/rewards/"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <EarnRewards />
              <h5>Earn Rewards</h5>
            </a>

            <a
              href="https://www.facebook.com/salonsymphonyapp"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <Community />
              <h5>Community</h5>
            </a>

            {/* <div */}
            {/*  onClick={() => redirectToZendesk(supportTypes.REPORT_BUG)} */}
            {/*  style={{ cursor: "pointer" }} */}
            {/* > */}
            <a
              href="https://support.mysalonsymphony.com/hc/en-us/requests/new?ticket_form_id=18644284601755"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <ReportBug />
              <h5>Report Bug </h5>
            </a>
            {/* </div> */}

            <a
              href="https://support.mysalonsymphony.com/hc/en-us/requests/new?ticket_form_id=19010179821595"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <MakeRequest />
              <h5>Send Feedback</h5>
            </a>

            <a
              href="https://support.mysalonsymphony.com/hc/en-us/requests/new?ticket_form_id=16817626638235"
              className="help_listing_items"
              target="_blank"
              rel="noreferrer"
            >
              <BillingHelp />
              <h5>Billing Help</h5>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Helppopup;
