import { Select } from "antd";
import React, { useState } from "react";
import { ArrowTriangleDown } from "../../constants/svg";

const CustomSelect = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);

  return (
    <div className="customLabelGroup form-floating customDropdown">
      <div className={props?.value ? "form-control hasValue" : "form-control"}>
        {props?.value && (
          <div
            className={`customLabel ${isDropdownOpen ? "addedColor" : ""}`}
            style={{ display: "block" }}
          >
            {props?.label}
          </div>
        )}
        <Select
          {...props}
          style={{
            fontSize: "12px",
          }}
          suffixIcon={<ArrowTriangleDown />}
          className={`${props.className}`}
          placeholder={props?.placeholder}
          value={props?.value}
          disabled={props?.disabled}
          options={props?.options}
          onChange={props?.onChange}
          open={isDropdownOpen}
          popupMatchSelectWidth={true}
          onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
          popupClassName={`bgColorHover ${props?.popupClassName}`}
        />
      </div>
    </div>
  );
};

export default CustomSelect;
