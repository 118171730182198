import { Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Assets } from "../../constants/images";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import StatusModal from "../../components/Modals/StatusModal";
import GoalModalForms from "../../components/pages/GoalsPage/Modals/GoalModalForms";
import {
  CheckMarkIcon,
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  UpDpwnArrow,
} from "../../constants/svg";
import { deleteGoal, getSingleGoal } from "../../services/goals/frontend-goals";
import { UpdateGoalStatus } from "../../services/goals/goals";
import {
  goal_type,
  SHARE_URL,
  target_types,
  user_goal_status,
} from "../../utils/constants";
import { renderContentWithLinks, toastMessage } from "../../utils/helper";
import { setRenderGoalDetailPage } from "../../store/slices/goalsSlice";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import AvatarImage from "../../components/Common/AvatarImage";

const menuOption = [
  {
    value: user_goal_status.active,
    label: "Active",
  },
  {
    value: user_goal_status.achieved,
    label: "Achieved",
  },
  {
    value: user_goal_status.missed,
    label: "Missed",
  },
];

const GoalsDetailPage = () => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [statusModal, setStatusModal] = useState({
    showModal: false,
    option: null,
  });
  const [open, setOpen] = useState(false);
  const [statusModalActBtnDisable, setStatusModalActBtnDisable] =
    useState(false);
  const value = statusModal.option;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleGoal, formData, renderGoalDetailPage } = useSelector(
    (state) => state?.goals,
  );
  const url = window.location.href;
  const [disable, setDisable] = useState(false);
  const methods = useForm();
  const [selectedCard, setSelectedCard] = useState("Create New Goal");
  const [stepStatus, setStepStatus] = useState();
  const [showGenericModal, setShowGenericModal] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    if (value == user_goal_status.active) {
      handleStatusUpdate("", value);
    } else {
      setStatusModal({ showModal: true, option: value });
    }
  };

  const handleStatusUpdate = async (msg = "", st = null) => {
    try {
      const { goal_id, user_id, id } = singleGoal.data.userGoals[0];
      const payload = {
        user_id,
        goal_id,
        comment: msg,
      };
      if (statusModal.option) {
        payload.status = statusModal.option;
      } else if (st) {
        payload.status = st;
      }
      setStatusModalActBtnDisable(true);
      const { status, message, data } = await UpdateGoalStatus(id, payload);

      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setRenderGoalDetailPage(renderGoalDetailPage + 1));
      setStatusModal({ showModal: false, option: null });
      toastMessage("success", "Status Updated Successfully!");
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setStatusModalActBtnDisable(false);
    }
  };

  const fetchtSingleGoal = async () => {
    await getSingleGoal(id, dispatch);
  };

  const handleDelete = async () => {
    const selectedData = {
      key: id,
    };
    await deleteGoal(
      selectedData,
      setDisable,
      showModal,
      setShowModal,
      dispatch,
    );
    setTimeout(() => {
      navigate("/goals");
    }, 1200);
  };

  useEffect(() => {
    fetchtSingleGoal();
  }, [renderGoalDetailPage]);

  const getStatusNameByKey = (status) => {
    let name;
    switch (status) {
      case user_goal_status.achieved:
        name = "Achieved";
        break;
      case user_goal_status.active:
        name = "Active";
        break;
      case user_goal_status.missed:
        name = "Missed";
        break;
    }
    return name;
  };

  if (singleGoal?.isSingleGoalLoading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/goals">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Goals</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        shareModal: true,
                      }))
                    }
                  >
                    <ShareIcon />
                    <p className="uLine ">Share</p>
                  </div>
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      setOpen(true);
                      setShowGenericModal(true);
                    }}
                  >
                    <EditIcon />
                    <p className="uLine ">Edit</p>
                  </div>
                  <div
                    className="icon-text-wrapper deleteLabel cursor-pointer"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        deleteModal: true,
                      }))
                    }
                  >
                    <RecycleBin />
                    <p className="uLine redLine">Delete</p>
                  </div>
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="sec-heading">
                    {singleGoal?.data?.name || "-"}
                  </h3>
                  {/* Staus Dropdown   */}
                  <Select
                    value={singleGoal?.data?.userGoals[0].status}
                    suffixIcon={<UpDpwnArrow />}
                    menuItemSelectedIcon={<CheckMarkIcon />}
                    // onDropdownVisibleChange={handleChange}
                    onChange={handleChange}
                    // open={true}
                    popupClassName="statusDropdown statusDropdown1 main-select me-3"
                    className={`custom-select-box statusDropdown1 ${singleGoal?.data?.userGoals[0].status}`}
                    options={menuOption.map((item) => ({
                      label: (
                        <>
                          <div className="d-flex align-items-center">
                            <span
                              className={`statusBall ${item.label.toLowerCase()}`}
                            ></span>
                            {item.label}
                          </div>
                        </>
                      ),
                      value: item.value,
                    }))}
                  ></Select>
                </div>

                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Assignee</h5>
                    <div className="recipt-div">
                      <div className="imgdiv">
                        <AvatarImage
                          name={singleGoal?.data?.assigned?.name}
                          src={singleGoal?.data?.assigned?.image}
                          placeholder={Assets.ProfilePlaceholder}
                          size={26}
                        />
                        {/* <img
                          src={
                            singleGoal?.data?.assigned?.image ||
                            Assets.ProfilePlaceholder
                          }
                          className="avatar"
                          alt="Assignee"
                          onError={(e) =>
                            (e.target.src = Assets.ProfilePlaceholder)
                          }
                        /> */}
                      </div>
                      <p>{singleGoal?.data?.assigned?.name || "-"}</p>
                    </div>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Description</h5>
                    <p>
                      {singleGoal?.data?.description
                        ? renderContentWithLinks(singleGoal?.data?.description)
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Goal Type</h5>
                    <p>{singleGoal?.data?.typeName || "-"}</p>
                  </div>

                  <div className="eventDetailItem">
                    <h5 className="head">Target Type</h5>
                    <p>{singleGoal?.data?.targetTypeName || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Target Value</h5>
                    <p>
                      {singleGoal?.data?.target_type == target_types.dollar &&
                        "$"}
                      {singleGoal?.data?.target_value || "-"}
                      {singleGoal?.data?.target_type ==
                        target_types.percentage && "%"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Target Date</h5>
                    <p>
                      {moment(singleGoal?.data?.end_date).format(
                        "MMM DD, yyyy",
                      ) || "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Repeat Goal</h5>
                    <p>{singleGoal?.data?.durationName || "-"}</p>
                  </div>
                  {/* <div className="eventDetailItem">
                    <h5 className="head">Assigned By</h5>
                    <p>{singleGoal?.data?.user?.name || "-"}</p>
                  </div> */}
                  <div className="eventDetailItem">
                    <h5 className="head">Created By</h5>
                    <p>{singleGoal?.data?.user?.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {moment(singleGoal?.data?.created_at).format(
                        "MMM DD, yyyy",
                      ) || "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={4} className={"p-0"}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Goal History</h4>
            </div>
            <div className="goalDetailBody">
              <div className="response-card">
                {singleGoal?.isSingleGoalLoading ? (
                  <div className="load black" />
                ) : singleGoal?.data?.userGoalslength <= 0 ? (
                  <span>Check back soon!</span>
                ) : (
                  singleGoal?.data?.userGoals?.map((item, index) => (
                    <div key={index} className="response-header">
                      <p>
                        {moment(item?.created_at).format("MMM DD, yyyy") || "-"}
                      </p>
                      <p>{item?.typeName || "-"}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* All modals goes here */}

      {/* Share Modal */}
      <ShareModal
        shareLink={SHARE_URL.GOALS + id}
        data={{
          name: singleGoal?.data?.name,
          description: singleGoal?.data?.description,
          start_date: singleGoal?.data?.start_date,
          start_time: moment(singleGoal?.data?.start_date).format("hh:mm a"),
        }}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Goal"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this goal with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.shareModal,
          title: "$2K Weekly Service Sales",
          para1:
            "This is event dummy message will replace with the. This is event dummy message will replace with the. This is event dummy message will replace with the...",
          para2: " Jamie Moccia • Weekly Goal",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Goal?"
        handleDelete={handleDelete}
        disable={disable}
        para={
          <>
            Are you sure you want to delete{" "}
            <b>
              {singleGoal?.data?.type == goal_type.custom
                ? "custom goal"
                : "recurring goal"}
              ?
            </b>
            <br />
            Important: This goal cannot be recovered.
          </>
        }
        notificationMessage="Goal"
      />
      {/* Status Modal */}
      <StatusModal
        show={statusModal.showModal}
        size={"lg"}
        disable={statusModalActBtnDisable}
        onHide={() => setStatusModal({ ...statusModal, showModal: false })}
        modalTitle={"Change Status"}
        modalSubtitle={
          <>
            Set the status of this goal as{" "}
            <b className="text-capitalize">
              {getStatusNameByKey(statusModal.option)}
            </b>
          </>
        }
        modalButtonClass={
          statusModal.option == user_goal_status.achieved
            ? "solid-approve-green-btn"
            : "solid-red-sm-btn"
        }
        modalButtonText={
          statusModal.option == user_goal_status.achieved
            ? "Achieved"
            : "Missed"
        }
        onStatusUpdate={handleStatusUpdate}
      />
      {/* Edit Drawer */}
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={selectedCard}
        mt10="custom-mt-10"
        size={"lg"}
        goal={true}
        bodyComponent={
          <FormProvider {...methods}>
            <GoalModalForms
              show={showGenericModal}
              setModalShow={setShowGenericModal}
              setStepStatus={setStepStatus}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              methods={methods}
              isedit={true}
              id={id}
            />
          </FormProvider>
        }
      />
    </>
  );
};

export default GoalsDetailPage;
