import { createSlice } from "@reduxjs/toolkit";

export const flashCardSlice = createSlice({
  name: "flashCard",
  initialState: {
    uploadFileTabNo: 1,
    isFlashcardsLoading: true,
    isSingleFlashcardLoading: true,
    isReloadFlashCardTable: 1,
    flashCardSelectedData: null,
    reRenderFlashCardBuilder: 1,
    flashcards: {
      meta: null,
      data: [],
    },

    singleFlashcard: {
      isSingleFlashcardLoading: true,
      data: null,
    },

    flashCardAddEditForm: {
      show: false,
      formType: null,
    },
  },
  reducers: {
    setUploadFileTabNo: (state, action) => {
      state.uploadFileTabNo = action.payload;
    },
    setIsFlashcardsLoading: (state, action) => {
      state.isFlashcardsLoading = action.payload;
    },
    setFlashcards: (state, action) => {
      state.flashcards.data = action.payload.data;
      state.flashcards.meta = action.payload.meta;
    },

    setIsSingleFlashcardLoading: (state, action) => {
      state.singleFlashcard.isSingleFlashcardLoading = action.payload;
    },
    setSingleFlashcard: (state, action) => {
      state.singleFlashcard.data = action.payload;
    },
    setFlashCardAddEditForm: (state, action) => {
      state.flashCardAddEditForm = action.payload;
    },
    setReloadFlashCardTable: (state, action) => {
      state.isReloadFlashCardTable = action.payload;
    },
    setFlashCardSelectedData: (state, action) => {
      state.flashCardSelectedData = action.payload;
    },
    setReRenderFlashCardBuilder: (state, action) => {
      state.reRenderFlashCardBuilder = action.payload;
    },
  },
});

export const {
  setUploadFileTabNo,
  setIsFlashcardsLoading,
  setFlashcards,
  setIsSingleFlashcardLoading,
  setSingleFlashcard,
  setFlashCardAddEditForm,
  setReloadFlashCardTable,
  setFlashCardSelectedData,
  setReRenderFlashCardBuilder,
} = flashCardSlice.actions;

export default flashCardSlice.reducer;
