import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import LeavePageModal from "./LeavePageModal";
import { ModalCloseIcon } from "../../constants/svg";

const GenericModal = (props) => {
  // Sate for the leave modal, which render on click of close button
  const [leavePagemodalShow, setLeavePageModalShow] = useState(false);
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`resource-modal generic-modal ${props.customClassName || ""}`}
        style={leavePagemodalShow ? { opacity: "0.75" } : {}}
        onHide={() => {
          setLeavePageModalShow(true);
        }}
      >
        <Modal.Header
          style={{
            paddingBottom: props.goal == true ? "25px" : "30px",
            background: "#f5f6f8",
          }}
          className={`${props.customModalHeader}`}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center w-100 pd-left-40"
          >
            {props.modalTitle}
          </Modal.Title>

          <div
            className="closeBtn"
            data-bs-dismiss="modal"
            onClick={() => {
              setLeavePageModalShow(true);
              // props.setModalShow(false);
            }}
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        {props.desc == true ? (
          <div className="text-left location-desc-padding custom-height-20 custom-fs-15">
            <b>{props.pro}</b> {props.modalDesc}
          </div>
        ) : (
          <div
            className="text-center pb-4 font-size-13"
            style={{ display: props.modalDesc ? "block" : "none" }}
          >
            {props.modalDesc}
          </div>
        )}

        <div className={props.mt10}>
          {/* All body Compponents goes here... */}
          {props.bodyComponent}
        </div>
        <LeavePageModal
          show={leavePagemodalShow}
          onHide={() => setLeavePageModalShow(false)}
          setModalShow={props.setModalShow}
          resetForm={props?.resetForm}
        />
      </Modal>
    </>
  );
};

export default GenericModal;
