import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Tabs } from "antd";
import NewSegment from "./StepsDetail/NewSegment";
import { GetSalonSegments } from "../../../../../services/global/global";
import { toastMessage } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setSalonSegments } from "../../../../../store/slices/globalSlice";
import { segment_type } from "../../../../../utils/constants";

function ZeroStep({
  segment,
  setSegment,
  setZeroStepDisabled,
  setSelectedSalons,
  selectedSalons,
  showPositions,
  setSelectedPositions,
  selectedPositions,
  setSelectedProductLines,
  selectedProductLines,
  softwares,
  setSoftwares,
  technologies,
  setTechnologies,
}) {
  // Tab State
  const [activeKey, setActiveKey] = useState(segment);
  const dispatch = useDispatch();
  const { salonSegments } = useSelector((state) => state.global);
  const getSalonSegments = async () => {
    try {
      const res = await GetSalonSegments({
        positions: selectedPositions,
        products: selectedProductLines,
      });
      const { data, status, message } = res.data;
      dispatch(setSalonSegments(data));
      // setSelectedSalons([])
    } catch (e) {
      toastMessage("error", e.message);
    }
  };
  useEffect(() => {
    getSalonSegments();
  }, [selectedPositions, selectedProductLines]);
  useEffect(() => {
    if (salonSegments.length > 0) {
      let salonsData = [];
      salonSegments.map((item) => {
        if (selectedSalons.includes(item.id)) {
          salonsData.push(item.id);
        }
      });
      setSelectedSalons(salonsData);
    }
  }, [salonSegments]);
  useEffect(() => {
    if (
      selectedSalons.length > 0 ||
      selectedPositions.length > 0 ||
      selectedProductLines.length > 0 ||
      softwares.length > 0 ||
      technologies.length > 0 ||
      segment == segment_type.ALL
    ) {
      setZeroStepDisabled(false);
    } else {
      if (activeKey == segment_type.CUSTOM) {
        setZeroStepDisabled(true);
      }
    }
    if (segment) {
      setActiveKey(segment);
    }
  }, [
    selectedSalons,
    segment,
    selectedSalons,
    selectedPositions,
    selectedProductLines,
    softwares,
    technologies,
  ]);

  // Tab Functions
  const handleTabClick = (key) => {
    if (key == segment_type.ALL) {
      setZeroStepDisabled(false);
    } else {
      setZeroStepDisabled(true);
    }
    setActiveKey(key);
    setSegment(key);
  };
  const hanldeOnChange = (key) => {};
  // Tabs Data Array

  // Tabs Data Array
  const ResuoreZeroStep = [
    {
      key: segment_type.ALL,
      label: (
        <div className="main-alert-box-new">
          {/* <div className="icon-div">
              <ModalImageIcon />
            </div> */}
          <h3>Send to all accounts</h3>
          <p>
            All organizations, product lines
            {showPositions ? ", job titles" : ""}
          </p>
        </div>
      ),
      children: null,
    },
    {
      key: segment_type.CUSTOM,
      label: (
        <div className="main-alert-box-new">
          {/* <div className="icon-div">
              <ModalVideoIcon />
            </div> */}
          <h3>Create new segment</h3>
          <p>
            Select organizations, product lines
            {showPositions ? ", job titles" : ""}
          </p>
        </div>
      ),
      children: (
        <NewSegment
          setZeroStepDisabled={setZeroStepDisabled}
          setSelectedPositions={setSelectedPositions}
          setSelectedProductLines={setSelectedProductLines}
          selectedPositions={selectedPositions}
          selectedProductLines={selectedProductLines}
          setSelectedSalons={setSelectedSalons}
          selectedSalons={selectedSalons}
          getSalonSegments={getSalonSegments}
          showPositions={showPositions}
          softwares={softwares}
          setSoftwares={setSoftwares}
          technologies={technologies}
          setTechnologies={setTechnologies}
        />
      ),
    },
  ];
  return (
    <Row>
      <Tabs
        activeKey={activeKey}
        onTabClick={handleTabClick}
        items={ResuoreZeroStep}
        onChange={hanldeOnChange}
        className="alerts-tab main-push-alert main-push-alert-new zero-step"
        parentClass="main-alert-box-new"
      />
    </Row>
  );
}

export default ZeroStep;
