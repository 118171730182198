import React from "react";
import TimeOff from "./TimeOff";
import Schedule from "./Schedule";
import Education from "./Education";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../../../store/slices/requestsSlice";

const RequestBody = (props) => {
  const dispatch = useDispatch();
  dispatch(setSelectedTab(props?.activeTab));

  return (
    <>
      <div className="content">
        <div
          className={
            props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
          }
        >
          <TimeOff />
        </div>

        <div
          className={
            props.activeTab == "tab2" ? "tableWrapper active" : "tableWrapper"
          }
        >
          <Schedule />
        </div>
        <div
          className={
            props.activeTab == "tab3" ? "tableWrapper active" : "tableWrapper"
          }
        >
          <Education />
        </div>
      </div>
    </>
  );
};

export default RequestBody;
