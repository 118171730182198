import { Progress } from "antd";
import { useEffect } from "react";
import QuestionResponseUsersListPopup from "./QuestionResponseUsersListPopup";

const CheckBoxQuestionView = ({
  data,
  index,
  assignedUsers,
  detailPageType,
}) => {
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.classList.remove("overlay-class");
    });
  });

  let totalResponse = 0;
  if (detailPageType == "survey") {
    totalResponse = data.surveyQuestionOptions.reduce((prevVal, currVal) => {
      return prevVal + currVal.userSurveyAnswers.length;
    }, 0);
  } else if (detailPageType == "quiz") {
    totalResponse = data.questionAnswers.reduce((prevVal, currVal) => {
      return prevVal + currVal.userQuizAnswer.length;
    }, 0);
  }

  let answers = [];

  if (detailPageType == "survey") {
    answers = data.surveyQuestionOptions;
  } else if (detailPageType == "quiz") {
    answers = data.questionAnswers;
  }

  return (
    <>
      <div className="survey-border-wrapper">
        <div className="survey-answer-box">
          <div className="question-box">
            <h3>
              {index + 1}. {data.question}
              {/* <span> ({totalResponse} Responses)</span> */}
            </h3>
          </div>
          <div className="answer-box">
            {answers.map((elem) => {
              let answeredUsers = [];

              if (detailPageType == "survey") {
                answeredUsers = elem.userSurveyAnswers;
              } else if (detailPageType == "quiz") {
                answeredUsers = elem.userQuizAnswer;
              }

              const answerResponsePercentage =
                (answeredUsers.length / assignedUsers.length) * 100 || 0;

              return (
                <div className="each-response-div">
                  <h5>{elem.answer}</h5>
                  <div className="progress-div">
                    <Progress
                      strokeLinecap="butt"
                      percent={answerResponsePercentage}
                      strokeColor="#7B68EE"
                    />
                    {/* <p>{answeredUsers.length}</p> */}
                    <QuestionResponseUsersListPopup
                      detailPageType={detailPageType}
                      questionOptionID={elem.id}
                      answeredUserList={answeredUsers}
                      questionType="checkbox"
                      answerResponsePercentage={answerResponsePercentage}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckBoxQuestionView;
