import React, { useState } from "react";
import RequestHeader from "../../components/pages/RequestPage/RequestHeader";
import RequestBody from "../../components/pages/RequestPage/RequestBody";
import { useSelector } from "react-redux";

const RequestPage = () => {
  const selectedTab = useSelector((state) => state.requests.selectedTab);
  const [activeTab, setActiveTab] = useState(selectedTab || "tab1");

  return (
    <>
      <div className="innerContainer">
        <RequestHeader activeTab={activeTab} setActiveTab={setActiveTab} />

        <RequestBody activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </>
  );
};

export default RequestPage;
