import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AccountCloudIcon,
  AccountGalleryIcon,
  AccountHelpDocIcon,
  AccountLocationsIcon,
  AccountLogoutIcon,
  ArrowUpIcon,
  AWSIcon,
  BellIcon,
  BillingIcon,
  BinocularsLight,
  CircleMessageIcon,
  DoubleTick,
  FirebaseIcon,
  HorizontalDots,
  ModalCloseIcon,
  NewIoSettingsSharp,
  ProfileIcon,
  RecycleBin,
  SideCloseIcon,
  StripIcon,
  ToastCrossIcon,
  UnreadCircle,
  WhiteSparkles,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import SearchBar from "./SearchBar";
import { Drawer, Dropdown, Image, notification, Tabs } from "antd";
import NotificationCard from "./NotificationCard";
import TourBox from "./TourBox";
import { ListGroup } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Logout } from "../../services/auth/auth";
import MessageDrawer from "./MessageDrawer";
import {
  activity_type,
  roles as salonRoles,
  online_status,
} from "../../utils/constants";
import {
  clearAllActivities,
  deleteAllActivities,
  getClearedActivities,
  getClearedActivitiesMore,
  getNewActivities,
  getNewActivitiesMore,
} from "../../services/activities/frontend-activities";
import {
  GetAuthUserLocalStorage,
  SetAuthUserLocalStorage,
  EmptyLocalStorage,
} from "../../services/localStorage/localStorage";
import { useDispatch, useSelector } from "react-redux";
import {
  setClearedActivities,
  setIsClearedActivitiesLoading,
  setIsNewActivitiesLoading,
  setNewActivities,
} from "../../store/slices/activitiesSlice";
import { setCurrentSubscriptionDetail } from "../../store/slices/subscriptionSlice";
import { GetCurrentSubscription } from "../../services/subscription/subscription";
import {
  getUnreadCount,
  updateOnlineStatusOfLoginUser,
} from "../../services/chats/frontend-chats";
import { UpdateUser } from "../../services/users/users";
import InfiniteScroll from "react-infinite-scroll-component";
import AvatarImage from "./AvatarImage";
import { toastMessage } from "../../utils/helper";

function TopBar(props) {
  const [open, setOpen] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [openAccountDrawer, setOpenAccountDrawer] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [newHasMore, setNewHasMore] = useState(true);
  const [clearHasMore, setClearHasMore] = useState(true);
  const { ref5, activeStep, setActiveStep } = props;
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = GetAuthUserLocalStorage();
  const { me } = useSelector((state) => state?.auth);

  const { currentSalonId } = useSelector((state) => state.global);
  const { currentSubscriptionDetail, callCheckSubscriptionAPI } = useSelector(
    (state) => state.subscription,
  );
  const dispatch = useDispatch();
  const {
    isNewActivitiesLoading,
    isClearedActivitiesLoading,
    newActivities,
    clearedActivities,
  } = useSelector((state) => state?.activities);

  const onClose = () => {
    setOpen(false);
  };

  const closeAccountInfoDrawer = () => {
    setOpenAccountDrawer(false);
  };

  const closeMessageDrawer = () => {
    setOpenMessage(false);
  };

  // useEffect(() => {
  //   getCurrentSubscription();
  // }, [callCheckSubscriptionAPI]);

  const getCurrentSubscription = async () => {
    try {
      const response = await GetCurrentSubscription(
        GetAuthUserLocalStorage()?.salon?.id,
      );
      const { data, message, status } = response.data;
      if (!data) throw new Error();
      dispatch(setCurrentSubscriptionDetail(data));
    } catch (err) {}
  };

  const onDeleteAllActivities = async () => {
    await deleteAllActivities(authUser?.salon?.id);
    await fetchNewActivities();
    await fetchClearedActivities();
  };

  const onClearAllActivities = async () => {
    await clearAllActivities(authUser?.salon?.id);
    await fetchNewActivities();
    await fetchClearedActivities();
  };

  const fetchMoreClearActivitiesData = async () => {
    setClearLoading(true);
    setTimeout(async () => {
      try {
        let next_page = clearedActivities.meta.current_page + 1;
        const params = {
          is_paginated: 1,
          page: next_page,
          "per-page": 10,
          salon_id: currentSalonId,
          is_read: activity_type.read,
        };

        const response = await getClearedActivitiesMore(params);
        const newData = response.data;

        const hasPostsData = response.data?.length > 0;
        if (hasPostsData) {
          const updatedData = [...clearedActivities.data, ...newData];
          if (
            response?.meta?.total &&
            response?.meta?.total <= updatedData?.length
          ) {
            setClearHasMore(false);
          } else {
            setClearHasMore(true);
          }
          dispatch(
            setClearedActivities({ meta: response?.meta, data: updatedData }),
          );
          setClearLoading(false);
        }
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setClearLoading(false);
      }
    }, 1500);
  };

  const fetchMoreNewActivitiesData = async () => {
    setNewLoading(true);
    setTimeout(async () => {
      try {
        let next_page = newActivities.meta.current_page + 1;
        const params = {
          is_paginated: 1,
          page: next_page,
          "per-page": 10,
          salon_id: currentSalonId,
          is_read: activity_type?.unread,
        };

        const response = await getNewActivitiesMore(params);
        const newData = response.data;

        const hasPostsData = response.data?.length > 0;
        if (hasPostsData) {
          const updatedData = [...newActivities.data, ...newData];
          if (
            response?.meta?.total &&
            response?.meta?.total <= updatedData?.length
          ) {
            setNewHasMore(false);
          } else {
            setNewHasMore(true);
          }
          dispatch(
            setNewActivities({ meta: response?.meta, data: updatedData }),
          );
          setNewLoading(false);
        }
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setNewLoading(false);
      }
    }, 1500);
  };

  // Dropdown Item
  const items = [
    {
      key: uuidv4(),
      label: (
        <>
          <NavLink to={"/activity"}>
            <BinocularsLight />
            View All
          </NavLink>
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div onClick={() => onClearAllActivities()}>
          <DoubleTick />
          clear activity
        </div>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div className={"deleteLabel"} onClick={() => onDeleteAllActivities()}>
          <RecycleBin />
          Delete All
        </div>
      ),
    },
  ];

  const tabsContent = [
    {
      key: "1",
      label: `New`,
      children: (
        <>
          <InfiniteScroll
            dataLength={newActivities?.data?.length}
            next={fetchMoreNewActivitiesData}
            scrollableTarget="infinite-scroll-component "
            hasMore={newHasMore} // You can conditionally set this based on your data
            loader={
              newLoading ? (
                <div className="loader-chat">
                  <Image
                    width={100}
                    height={100}
                    src={Assets.loadingGif}
                    preview={false}
                  />
                </div>
              ) : null
            }
            height={"calc(100vh - 219px)"}
          >
            {isNewActivitiesLoading ? (
              <div className="load black" />
            ) : newActivities?.data?.length <= 0 ? (
              <span>Check back soon!</span>
            ) : (
              newActivities?.data?.map((data, index) => {
                return (
                  <NotificationCard
                    key={index}
                    data={data}
                    clearedData={false}
                    notificationImg={Assets.notificationIcon}
                  />
                );
              })
            )}
          </InfiniteScroll>
        </>
      ),
    },
    {
      key: "2",
      label: `Cleared`,
      children: (
        <>
          <InfiniteScroll
            dataLength={clearedActivities?.data?.length}
            next={fetchMoreClearActivitiesData}
            scrollableTarget="infinite-scroll-component "
            hasMore={clearHasMore} // You can conditionally set this based on your data
            loader={
              clearLoading ? (
                <div className="loader-chat">
                  <Image
                    width={100}
                    height={100}
                    src={Assets.loadingGif}
                    preview={false}
                  />
                </div>
              ) : null
            }
            height={"calc(100vh - 219px)"}
          >
            {isClearedActivitiesLoading ? (
              <div className="load black" />
            ) : clearedActivities?.data?.length <= 0 ? (
              <span>Check back soon!</span>
            ) : (
              clearedActivities?.data?.map((data, index) => {
                return (
                  <NotificationCard
                    key={index}
                    data={data}
                    clearedData={true}
                    notificationImg={Assets.notificationIcon}
                  />
                );
              })
            )}
          </InfiniteScroll>
        </>
      ),
    },
  ];

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const fetchNewActivities = async () => {
    const params = {
      is_paginated: 1,
      page: 1,
      "per-page": 20,
      salon_id: currentSalonId,
      is_read: activity_type?.unread,
    };

    await getNewActivities(
      params,
      dispatch,
      setIsNewActivitiesLoading,
      setNewActivities,
    );

    if (newActivities?.data?.length == newActivities?.meta?.total) {
      setNewHasMore(false);
    }
  };

  const fetchClearedActivities = async () => {
    const params = {
      is_paginated: 1,
      page: 1,
      "per-page": 20,
      salon_id: currentSalonId,
      is_read: activity_type?.read,
    };

    await getClearedActivities(
      params,
      dispatch,
      setIsClearedActivitiesLoading,
      setClearedActivities,
    );

    if (clearedActivities?.data?.length == clearedActivities?.meta?.total) {
      setClearHasMore(false);
    }
  };

  const fetchUnreadCount = () => {
    getUnreadCount(setMessageCount);
  };

  const handleSkipTour = async () => {
    let apiData = {
      show_tab_tour: 1,
    };
    setActiveStep(null);
    document.body.classList.remove("stepFocused");
    let res = await UpdateUser(authUser?.id, apiData);
    SetAuthUserLocalStorage(res?.data?.data);
  };

  useEffect(() => {
    fetchNewActivities();
    fetchClearedActivities();
    fetchUnreadCount();
  }, [currentSalonId]);
  const handleProfileNav = () => {
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      navigate("settings/profile");
    } else {
      setOpenAccountDrawer((prev) => !prev);
    }
  };
  // Logout
  const handleLogout = async () => {
    try {
      const res = await Logout();
      await updateOnlineStatusOfLoginUser(authUser?.id, online_status?.offline);
      EmptyLocalStorage();
      toastMessage("success", "You have been logged out!");
      window.location.href = "/";
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };
  return (
    <>
      <div className="innerContainer w-100">
        <div className="topBarSearchBox">
          <div className="searchBoxes">
            <div className="searchBar">
              <SearchBar />
            </div>
          </div>
          <div className="topBarAccountBox">
            <div className="iconbox">
              <NavLink to="/inbox" className="iconRoundedBox">
                <div>
                  {messageCount > 0 ? (
                    <span className="chatDot">
                      <UnreadCircle />
                    </span>
                  ) : null}
                  <CircleMessageIcon />
                </div>
              </NavLink>
              <div className={`${activeStep == 4 && "StepActive"}`}>
                <div
                  className={`iconRoundedBox`}
                  ref={ref5}
                  onClick={() => {
                    navigate(
                      GetAuthUserLocalStorage()?.roles[0]?.id ==
                        salonRoles.super_admin
                        ? "/settings/profile"
                        : "/settings/billing",
                    );
                  }}
                >
                  <div>
                    {/* <IoSettingsSharp /> */}
                    <NewIoSettingsSharp />
                  </div>
                </div>
                <TourBox
                  heading={"Manage Settings"}
                  para={
                    "View and manage your billing, storage, locations, media, and more."
                  }
                  backFunc={() => {
                    setActiveStep(3);
                  }}
                  nextFunc={handleSkipTour}
                  nextBtnText={"Done"}
                  stepNumber={"5"}
                  skipFunc={handleSkipTour}
                  skipText={"Don't show again"}
                />
              </div>

              <div
                className="iconRoundedBox"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {newActivities?.data?.length > 0 && (
                  <span>
                    <UnreadCircle />
                  </span>
                )}
                <BellIcon />
              </div>
              {GetAuthUserLocalStorage().roles[0].id ==
                salonRoles.super_admin && (
                <>
                  <div className="iconRoundedBox">
                    <NavLink to="https://firebase.google.com/" target="_blank">
                      <FirebaseIcon />
                    </NavLink>
                  </div>
                  <div className="iconRoundedBox">
                    <NavLink to="https://aws.amazon.com/" target="_blank">
                      <AWSIcon />
                    </NavLink>
                  </div>
                  <div className="iconRoundedBox">
                    <NavLink to="https://stripe.com/" target="_blank">
                      <StripIcon />
                    </NavLink>
                  </div>
                </>
              )}
            </div>

            <div className="inner__box" onClick={handleProfileNav}>
              {/* <div className="account__owner__titles">
                <h3>June Juliano</h3>
                <p>Acapello Salons</p>
              </div> */}
              <div className="myProfile__im__topbar">
                <AvatarImage
                  name={me?.data?.name}
                  src={me?.data?.image}
                  placeholder={Assets.ProfilePlaceholder}
                  size={38}
                />
              </div>
              <div>
                <ArrowUpIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        width={337}
        open={openAccountDrawer}
        onClose={closeAccountInfoDrawer}
        className="account__info__drawer"
        closable={false}
      >
        <div className="close__icon" onClick={closeAccountInfoDrawer}>
          <SideCloseIcon />
        </div>
        <div className="account__info__header">
          <div className="avatar__info">
            <AvatarImage
              name={me?.data?.name}
              src={me?.data?.image}
              placeholder={Assets.ProfilePlaceholder}
              size={100}
            />
          </div>
          <div className="avatar_info_text">
            <h3>{me?.data?.name || "-"}</h3>
            <p>{me?.data?.salon?.name || "-"}</p>
          </div>
        </div>
        <div className="list__container">
          <ListGroup variant="flush" className="my__list__group">
            <Link to="/settings/billing" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <BillingIcon />
                </span>
                Billing
              </ListGroup.Item>
            </Link>
            <Link to="/settings/profile" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <ProfileIcon />
                </span>
                Profile
              </ListGroup.Item>
            </Link>
            <Link to="/settings/storage" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <AccountCloudIcon />
                </span>
                Storage
              </ListGroup.Item>
            </Link>
            <Link to="/settings/locations" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <AccountLocationsIcon />
                </span>
                Locations
              </ListGroup.Item>
            </Link>
            <Link to="/settings/uploads" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <AccountGalleryIcon />
                </span>
                Uploads
              </ListGroup.Item>
            </Link>
            {/* <Link to="/settings/referral" onClick={closeAccountInfoDrawer}>
              <ListGroup.Item>
                <span>
                  <AccountReferralsIcon />
                </span>
                Referrals
              </ListGroup.Item>
            </Link> */}
            <a
              href={"https://support.mysalonsymphony.com/hc/en-us"}
              target="_blank"
              rel="noreferrer"
            >
              <ListGroup.Item>
                <span>
                  <AccountHelpDocIcon />
                </span>
                Help Docs
              </ListGroup.Item>
            </a>
            <ListGroup.Item className="border-0" onClick={() => handleLogout()}>
              <span>
                <AccountLogoutIcon />
              </span>
              logout
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="account__bottom__footer">
          <div
            className="inner cursor-pointer"
            onClick={() => {
              setOpenAccountDrawer((prev) => !prev);
              navigate("/settings/billing");
            }}
          >
            <div className="upgrade__btn">
              <WhiteSparkles />
              <p className="ms-2">Upgrade Plan</p>
            </div>
            <p>
              <span className="bold">
                {currentSubscriptionDetail?.meta?.members || 0}
              </span>{" "}
              /{" "}
              {currentSubscriptionDetail?.meta?.total_members >= 999
                ? "Unlimited"
                : currentSubscriptionDetail?.meta?.total_members || 0}{" "}
              members on current plan
            </p>
          </div>
        </div>
      </Drawer>
      <Drawer
        open={open}
        onClose={onClose}
        size={"small"}
        placement={"right"}
        closable={false}
        title={"Activity"}
        scroll
        extra={
          <ul className={"drawerExtras"}>
            <li className="cursor-pointer" key={uuidv4()}>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
                trigger={["click"]}
                overlayClassName={"defaultDropdown activity-drop-down"}
              >
                <div className="icon">
                  <HorizontalDots />
                </div>
              </Dropdown>
            </li>
            <li className="cursor-pointer" onClick={onClose} key={uuidv4()}>
              <ModalCloseIcon />
            </li>
          </ul>
        }
      >
        <div className="drawerTabs">
          <Tabs defaultActiveKey="1" items={tabsContent} />
        </div>
      </Drawer>
      <MessageDrawer
        setOpenMessage={setOpenMessage}
        openMessage={openMessage}
      />
    </>
  );
}

export default TopBar;
