import { Progress } from "antd";
import { useEffect } from "react";
import QuestionResponseUsersListPopup from "./QuestionResponseUsersListPopup";
import { calPercentage } from "../../utils/helper";

const EmojiQuestionView = ({ data, index, assignedUsers, detailPageType }) => {
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.classList.remove("overlay-class");
    });
  });

  let totalResponse = 0;
  if (detailPageType == "survey") {
    totalResponse = data.surveyQuestionAnswers.length;
  } else if (detailPageType == "quiz") {
    totalResponse = data.surveyQuestionAnswers.length;
  }

  let answers = [];

  if (detailPageType == "survey") {
    answers = data.surveyQuestionAnswers;
  } else if (detailPageType == "quiz") {
    answers = data.surveyQuestionAnswers;
  }

  const dissatisfied = answers.filter((elem) => elem.answer == "10");
  const unImpressed = answers.filter((elem) => elem.answer == "20");
  const undecided = answers.filter((elem) => elem.answer == "30");
  const impressed = answers.filter((elem) => elem.answer == "40");
  const superSatisfied = answers.filter((elem) => elem.answer == "50");

  const dissatisfiedPercentage = calPercentage(
    dissatisfied.length,
    assignedUsers.length,
  );
  const unImpressedPercentage = calPercentage(
    unImpressed.length,
    assignedUsers.length,
  );
  const undecidedPercentage = calPercentage(
    undecided.length,
    assignedUsers.length,
  );
  const impressedPercentage = calPercentage(
    impressed.length,
    assignedUsers.length,
  );
  const superSatisfiedPercentage = calPercentage(
    superSatisfied.length,
    assignedUsers.length,
  );
  return (
    <>
      <div className="survey-border-wrapper">
        <div className="survey-answer-box">
          <div className="question-box">
            <h3>
              {index + 1}. {data.question}
              {/* <span> ({totalResponse} Responses)</span> */}
            </h3>
          </div>
          <div className="answer-box">
            <div className="each-response-div">
              <h5>
                {
                  <img
                    src="https://salon-symphony.s3.us-east-1.amazonaws.com/constants/dissatisfied.png"
                    alt={"avatar img"}
                    width="20px"
                    height="20px"
                  />
                }
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={dissatisfiedPercentage}
                  strokeColor="#7B68EE"
                />
                {/* <p>{dissatisfied.length}</p> */}
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID="emoji-question-1"
                  answeredUserList={dissatisfied}
                  questionType="emoji"
                  answerResponsePercentage={dissatisfiedPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                {
                  <img
                    src="https://salon-symphony.s3.us-east-1.amazonaws.com/constants/unimpressed.png"
                    alt={"avatar img"}
                    width="20px"
                    height="20px"
                  />
                }
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={unImpressedPercentage}
                  strokeColor="#7B68EE"
                />
                {/* <p>{unImpressed.length}</p> */}
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID="emoji-question-2"
                  answeredUserList={unImpressed}
                  questionType="emoji"
                  answerResponsePercentage={unImpressedPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                {
                  <img
                    src="https://salon-symphony.s3.us-east-1.amazonaws.com/constants/Undecided.png"
                    alt={"avatar img"}
                    width="20px"
                    height="20px"
                  />
                }
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={undecidedPercentage}
                  strokeColor="#7B68EE"
                />
                {/* <p>{undecided.length}</p> */}
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID="emoji-rating-3"
                  answeredUserList={undecided}
                  questionType="emoji"
                  answerResponsePercentage={undecidedPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                {
                  <img
                    src="https://salon-symphony.s3.us-east-1.amazonaws.com/constants/Impressed.png"
                    alt={"avatar img"}
                    width="20px"
                    height="20px"
                  />
                }
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={impressedPercentage}
                  strokeColor="#7B68EE"
                />
                {/* <p>{impressed.length}</p> */}
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID="emoji-rating-4"
                  answeredUserList={impressed}
                  questionType="emoji"
                  answerResponsePercentage={impressedPercentage}
                />
              </div>
            </div>

            <div className="each-response-div">
              <h5>
                {
                  <img
                    src="https://salon-symphony.s3.us-east-1.amazonaws.com/constants/super-satisfied.png"
                    alt={"avatar img"}
                    width="20px"
                    height="20px"
                  />
                }
              </h5>
              <div className="progress-div">
                <Progress
                  strokeLinecap="butt"
                  percent={superSatisfiedPercentage}
                  strokeColor="#7B68EE"
                />
                {/* <p>{superSatisfied.length}</p> */}
                <QuestionResponseUsersListPopup
                  detailPageType={detailPageType}
                  questionOptionID="emoji-rating-5"
                  answeredUserList={superSatisfied}
                  questionType="emoji"
                  answerResponsePercentage={superSatisfiedPercentage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmojiQuestionView;
