import { ConfigProvider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import { NoDataIcon } from "../../../constants/svg";
import { setActiveOragnizationPagination } from "../../../store/slices/organizationSlice";
import ShareModal from "../../Modals/ShareModal";
import { tableLoader } from "../../../utils/helper";
import { tableHeight } from "../../../utils/constants";

const Active = ({ tableColumn }) => {
  const dispatch = useDispatch();
  const { activeOraganizationPagination, activeOrganizations, tableLoading } =
    useSelector((state) => state.organization);

  const [showModal, setShowModal] = useState({
    shareModal: false,
    editModal: false,
    deleteModal: false,
  });

  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    if (!window) return;
    const handleResize = () => {
      const { innerHeight: height } = window;
      setWindowHeight(height);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className={
          activeOrganizations.data.length == 0 ? "task__container_empty" : ""
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Organizaton you've created will appear here.</h5>
            </div>
          )}
        >
          <Table
            columns={tableColumn}
            loading={tableLoader(tableLoading)}
            dataSource={activeOrganizations?.data}
            bordered={false}
            pagination={false}
            virtual
            scroll={{
              x: "max-content",
              y: windowHeight - tableHeight.teamHeightTableMinus,
            }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className={"flashCardTable imageTable"}
            rowKey={(record) => record.uniid}
          />
        </ConfigProvider>
        {/* Share Modal */}
        <ShareModal
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Flashcard Set"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/chat"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this organization with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Introduction to L’Oreal Color",
            para1: "Improve your Business knowledge with this flashcard set.",
            para2: "10 Cards • Feb 12, 2023",
          }}
        />
      </div>
    </>
  );
};

export default Active;
