import React, { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import LogoutModal from "../components/Modals/LogoutModal";
import { roles as salonRoles } from "../utils/constants";
import {
  AccountGalleryIcon,
  AccountLogoutIcon,
  BillingIcon,
  BillingIconFilled,
  GalleryIconFilled,
  GroupsIcon,
  GroupsIconFilled,
  LocationIcon,
  LocationIconFilled,
  ProfileIcon,
  ProfileIconFilled,
  StorageIcon,
  StorageIconFilled,
} from "../constants/svg";
import { GetAuthUserLocalStorage } from "../services/localStorage/localStorage";

const SettingLayout = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  return (
    <>
      <div className="teamLayoutWrapper h-100 d-flex">
        <div className="innerSideBarWrapper h-100">
          <div className="innerSideBar h-100">
            <ul className={"sidebarList innerSideBarList h-100"}>
              {GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
                <li className={"sidebarItem"}>
                  <NavLink to="/settings/billing">
                    <div className="icon activeIcon">
                      <BillingIconFilled />
                    </div>
                    <div className="icon nonActiveIcon">
                      <BillingIcon />
                    </div>
                    <p className="sidebarText blue-accent">Billing</p>
                  </NavLink>
                </li>
              ) : null}
              <li className={"sidebarItem"}>
                <NavLink to="/settings/profile">
                  <div className="icon activeIcon">
                    <ProfileIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <ProfileIcon />
                  </div>
                  <p className="sidebarText blue-accent">Profile</p>
                </NavLink>
              </li>
              {GetAuthUserLocalStorage()?.roles[0]?.id !=
                salonRoles.super_admin && (
                <li className={"sidebarItem"}>
                  <NavLink to="/settings/locations">
                    <div className="icon activeIcon">
                      <LocationIconFilled />
                    </div>
                    <div className="icon nonActiveIcon">
                      <LocationIcon />
                    </div>
                    <p className="sidebarText blue-accent">Locations</p>
                  </NavLink>
                </li>
              )}
              <li className={"sidebarItem"}>
                <NavLink to="/settings/uploads">
                  <div className="icon activeIcon">
                    <GalleryIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <AccountGalleryIcon />
                  </div>
                  <p className="sidebarText blue-accent">Uploads</p>
                </NavLink>
              </li>
              {GetAuthUserLocalStorage()?.roles[0]?.id ==
                salonRoles.super_admin && (
                <li className={"sidebarItem"}>
                  <NavLink to="/team/groups">
                    <div className="icon activeIcon">
                      <GroupsIconFilled />
                    </div>
                    <div className="icon nonActiveIcon">
                      <GroupsIcon />
                    </div>
                    <p className="sidebarText blue-accent">Groups</p>
                  </NavLink>
                </li>
              )}
              {GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
                <li className={"sidebarItem"}>
                  <NavLink to="/settings/storage">
                    <div className="icon activeIcon">
                      <StorageIconFilled />
                    </div>
                    <div className="icon nonActiveIcon">
                      <StorageIcon />
                    </div>
                    <p className="sidebarText blue-accent">Storage</p>
                  </NavLink>
                </li>
              ) : null}
              {/* <li className={"sidebarItem"}>
                <NavLink to="/settings/referrals">
                  <div className="icon activeIcon">
                    <ReferralIconFilled />
                  </div>
                  <div className="icon nonActiveIcon">
                    <ReferralIcon />
                  </div>
                  <p className="sidebarText blue-accent">Referrals</p>
                </NavLink>
              </li> */}
              <li className={"sidebarItem"}>
                <NavLink
                  to={""}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal((prevState) => ({
                      ...prevState,
                      deleteModal: true,
                    }));
                  }}
                >
                  <div className="icon activeIcon">
                    <AccountLogoutIcon />
                  </div>
                  <div className="icon nonActiveIcon">
                    <AccountLogoutIcon />
                  </div>
                  <p className="sidebarText blue-accent">Logout</p>
                </NavLink>
              </li>
            </ul>
          </div>
          <div
            className={
              location.pathname == "/settings/profile"
                ? "innerBody p-0 customHeightProfile"
                : "innerBody"
            }
          >
            <Outlet />
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <LogoutModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Logout of Account?"
        para={<>Are you sure you want to log out of Salon Symphony?</>}
      />
    </>
  );
};

export default SettingLayout;
