import dayjs from "dayjs";
import moment from "moment";
import { PatternFormat } from "react-number-format";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { maxBounds, Patterns } from "../../constants/contants";
import { useDispatch, useSelector } from "react-redux";
import { Eye, EyeHide, ToastCrossIcon } from "../../constants/svg";
import {
  constant,
  currentBookingList,
  online_status,
  primaryProductList,
  roles,
  salonJobType,
  status as salonStatus,
} from "../../utils/constants";
import CustomDatePicker from "../FormFields/CustomDatePicker";
import { showNumber, toastMessage } from "../../utils/helper";
import CustomInput from "../FormFields/CustomInput";
import CustomSelect from "../FormFields/CustomSelect";
import { Register } from "../../services/auth/auth";
import { notification } from "antd";
import {
  addUserToFirebase,
  updateOrganizationFirebase,
} from "../../services/chats/frontend-chats";
import { setMemberAddEditForm } from "../../store/slices/memberSlice";
import { UpdateSalon } from "../../services/global/global";
import {
  setReRenderList,
  setReRenderOrganizationDetailPage,
} from "../../store/slices/organizationSlice";
import { setIsPositionsLoading } from "../../store/slices/authSlice";
import { GetPositions } from "../../services/positions/positions";
import { setPositions } from "../../store/slices/globalSlice";

const AddSalonModal = (props) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const { positions } = useSelector((state) => state.global);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [disable, setDisable] = useState(false);
  const { memberAddEditForm, selectedOrganization } = useSelector(
    (state) => state?.member,
  );
  const { reRenderOrganizationDetailPage, reRenderList } = useSelector(
    (state) => state.organization,
  );
  const [locationsArray, setLocationsArray] = useState([
    { title: "", disabled: false },
  ]);
  const primaryProduct = primaryProductList.map((product) => ({
    value: product,
    label: product,
  }));
  const currentBooking = currentBookingList.map((product) => ({
    value: product.replaceAll(" People", ""),
    label: product,
  }));
  const dispatch = useDispatch();
  const resetForm = () => {
    reset({
      name: "",
      phone: "",
      email: "",
      password: "",
      booking_software: null,
      date_founded: "",
      dob: "",
      locations: "",
      position: null,
      product_line: null,
      salon_name: "",
    });
  };

  const handleAdd = () => {
    let bool = false;
    for (let i = 0; i < locationsArray?.length; i++) {
      if (locationsArray[i]?.title == "") {
        bool = true;
        break;
      }
    }

    if (!bool) {
      setLocationsArray([...locationsArray, { title: "", disabled: false }]);
    }
  };

  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (selectedOrganization) {
      setValue("email", selectedOrganization?.user?.email || "");
      setValue("name", selectedOrganization?.user?.name || "");
      setValue("phone", selectedOrganization?.user?.phone || "");
      setValue("salon_name", selectedOrganization?.name || "");
      setValue(
        "date_founded",
        selectedOrganization?.date_founded ||
          moment(new Date()).format("YYYY-MM-DD"),
      );
      setValue("locations", selectedOrganization?.locations[0]?.name || "");
      setValue("position", selectedOrganization?.user?.position_id || null);
      setValue("product_line", selectedOrganization?.product_line || null);
      setValue(
        "booking_software",
        selectedOrganization?.booking_software || null,
      );
      setValue("dob", selectedOrganization?.user?.dob || "");
    }
  }, [selectedOrganization]);
  const onSubmit = async (formData) => {
    try {
      setDisable(true);
      const fullName = formData.name.split(" ").filter((elem) => elem != "");
      if (fullName.length <= 1) {
        toastMessage("warning", "Please provide Full Name.");
        return;
      }
      if (formData?.phone) {
        formData.phone =
          formData?.phone.substring(0, 2) == "+1"
            ? formData?.phone
            : "+1" + formData.phone;
      } else {
        formData.phone = "";
      }

      const payload = {
        phone: formData?.phone,
        name: formData?.name,
        position_id: formData?.position,
        dob: formData.dob,
        image: "",
        role_id: roles?.admin,
        device_type: constant?.DEVICE_TYPE,
        device_token: constant?.DEVICE_TOKEN,
        email: formData?.email,
        salon_name: formData?.salon_name,
        booking_software: formData?.booking_software,
        product_line: formData?.product_line,
        date_founded: moment(new Date(formData?.date_founded)).format(
          "YYYY-MM-DD",
        ),
        is_super_admin: 1,
      };
      if (formData?.password && formData?.password.length > 0) {
        payload.password = formData?.password;
        payload.password_confirmation = formData?.password;
      }
      if (memberAddEditForm.formType == "create") {
        payload.locations = [formData?.locations];
      } else {
        payload.admin_user_id = selectedOrganization?.user?.id;
      }
      let response;
      if (memberAddEditForm.formType == "create") {
        response = await Register(payload);
      } else {
        response = await UpdateSalon(selectedOrganization.id, payload);
      }

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      if (memberAddEditForm.formType == "create") {
        await addUserToFirebase({
          email: formData.email,
          id: data?.user?.id.toString(),
          image: "",
          name: formData?.salon_name,
          online: online_status.offline,
          position: null,
        });
        toastMessage("success", message);
      } else {
        if (data?.user) {
          let firebasePayload = {
            name: data?.name,
            image: data?.user?.image,
            online: false,
            id: data?.user?.id.toString(),
            email: data?.user?.email,
            position_id: data?.user?.position_id,
          };
          await updateOrganizationFirebase(firebasePayload);
        }
        toastMessage("success", message);
        dispatch(
          setReRenderOrganizationDetailPage(reRenderOrganizationDetailPage + 1),
        );
      }
      resetForm();
      dispatch(
        setMemberAddEditForm({
          show: false,
          formType: null,
        }),
      );
      dispatch(setReRenderList(reRenderList + 1));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisable(false);
    }
  };
  function disabledDate(current) {
    const currentDate = moment();
    return current && current > currentDate;
  }
  const getPositions = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: salonStatus.active,
        type: salonJobType.admin,
        order: "asc",
        "order-column": "id",
      };
      const response = await GetPositions(params);
      const { data, message, status } = response.data;

      const positionOptions = data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setPositions(positionOptions));
      dispatch(setIsPositionsLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsPositionsLoading(false));
    }
  };
  useEffect(() => {
    getPositions();
  }, []);
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal invite-staff"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body tabs-modal sub-invite">
            <Row>
              <Col lg={12}>
                <h3>
                  {memberAddEditForm.formType == "create"
                    ? "Add Organization"
                    : "Edit Organization"}
                </h3>
                <p>Add salon to join your team on Salon Symphony.</p>
              </Col>
              <Col lg={12}>
                <FloatingLabel
                  controlId="fullName"
                  label="Full Name"
                  className="mb-20 position-relative"
                >
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    autoComplete="off"
                    role={"presentation"}
                    className={errors.fullName ? "hasError" : ""}
                    maxLength={maxBounds.NAME}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Full name is required",
                      },
                      pattern: {
                        value: Patterns.Name,
                        // message: VALIDATIONS_TEXT.NAME_PATTERN,
                      },
                      maxLength: {
                        value: maxBounds.NAME,
                        // message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                    })}
                  />
                </FloatingLabel>
              </Col>

              <Col lg={12}>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="phone"
                  rules={{
                    required: {
                      value: true,
                      message: "Phone is required",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <FloatingLabel
                        controlId="floatingPhone"
                        label="Phone Number"
                        className="mb-20"
                      >
                        <PatternFormat
                          value={showNumber(field.value)}
                          onValueChange={(values) => {
                            field.onChange(values.value);
                          }}
                          allowEmptyFormatting
                          placeholder="Phone Number"
                          format="+1 ###-###-####"
                          className={
                            errors.phoneNumber
                              ? "hasError form-control"
                              : "form-control"
                          }
                        />
                      </FloatingLabel>
                    );
                  }}
                />
              </Col>
              <Col lg={12}>
                <FloatingLabel
                  controlId="floatingEmail"
                  label="Email Address"
                  className="mb-20"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    className={errors.email ? "hasError" : ""}
                    {...register("email", {
                      required: {
                        value: true,
                        // message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: Patterns.Email,
                        // message: VALIDATIONS_TEXT.EMAIL_PATTERN,
                      },
                      maxLength: {
                        value: maxBounds.EMAIL,
                        // message: VALIDATIONS_TEXT.EMAIL_MAX,
                      },
                    })}
                  />
                </FloatingLabel>
              </Col>
              <Col lg={12}>
                <Form.Group className="themeFormGroup">
                  <FloatingLabel
                    controlId="password"
                    label={
                      memberAddEditForm.formType == "create"
                        ? "Create Password"
                        : "Update Password"
                    }
                    className="mb-20 position-relative"
                  >
                    <Form.Control
                      type={passwordShow ? "text" : "password"}
                      placeholder="xxxxxx"
                      aria-describedby="passToggle"
                      className={errors.password ? "hasError" : ""}
                      minLength={6}
                      maxLength={16}
                      autoComplete="off"
                      role={"presentation"}
                      {...register("password", {
                        required: {
                          value: memberAddEditForm.formType == "create",
                          // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                        },
                        pattern: {
                          value: Patterns.Password,
                          // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                        },
                        minLength: {
                          value: 6,
                          // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                        },
                        maxLength: {
                          value: 16,
                          // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                        },
                      })}
                    />
                    <div
                      className="password-toggle-icon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      {passwordShow ? <Eye /> : <EyeHide />}
                    </div>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="salon_name"
                  rules={{
                    required: "Bussiness name is required",
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      className={errors.salon_name ? "hasError" : ""}
                      label="Business Name"
                      placeholder="Business Name"
                      value={field?.value}
                      onChange={(e) => field?.onChange(e)}
                    />
                  )}
                />
              </Col>
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="date_founded"
                  defaultValue={""}
                  rules={{
                    required: "Date founded is required",
                  }}
                  render={({ field }) => (
                    <CustomDatePicker
                      {...field}
                      className={errors.date_founded ? "hasError" : ""}
                      placeholder="Date Founded"
                      label="Date Founded"
                      onChange={(date, dateString) => {
                        field?.onChange(
                          moment(dateString).format("YYYY-MM-DD"),
                        );
                      }}
                      value={
                        field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                      }
                      disabledDate={disabledDate}
                    />
                  )}
                />
              </Col>
              {memberAddEditForm.formType == "create" && (
                <Col lg={12} className={"mb-20"}>
                  <Controller
                    control={control}
                    name="locations"
                    rules={{
                      required: "Location is required",
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        className={errors.locations ? "hasError" : ""}
                        label="Primary Location"
                        placeholder="Primary Location"
                        value={field?.value}
                        onChange={(e) => field?.onChange(e)}
                      />
                    )}
                  />
                </Col>
              )}
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="position"
                  defaultValue={null}
                  rules={{
                    required: "Job title is required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      className={
                        errors.position
                          ? "hasError businessDetailDropdown"
                          : " businessDetailDropdown"
                      }
                      placeholder="Job Title"
                      label="Job Title"
                      value={field?.value}
                      options={positions}
                      onChange={(e) => field?.onChange(e)}
                    />
                  )}
                />
              </Col>
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="product_line"
                  defaultValue={null}
                  rules={{
                    required: "Primary product line is required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      className={
                        errors.product_line
                          ? "hasError businessDetailDropdown"
                          : "businessDetailDropdown"
                      }
                      placeholder={"Primary Product Line"}
                      label="Primary Product Line"
                      value={field?.value}
                      options={primaryProduct}
                      onChange={(e) => field?.onChange(e)}
                    />
                  )}
                />
              </Col>
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="booking_software"
                  defaultValue={null}
                  rules={{
                    required: "Current Booking Software is required",
                  }}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      className={
                        errors.booking_software
                          ? "hasError businessDetailDropdown"
                          : "businessDetailDropdown"
                      }
                      placeholder={"How many people will use Salon Symphony?"}
                      label="How many people will use Salon Symphony?"
                      value={field?.value}
                      options={currentBooking}
                      onChange={(e) => field?.onChange(e)}
                    />
                  )}
                />
              </Col>
              <Col lg={12} className={"mb-20"}>
                <Controller
                  control={control}
                  name="dob"
                  defaultValue={""}
                  rules={{
                    required: "Date of Birth is required",
                  }}
                  render={({ field }) => (
                    <CustomDatePicker
                      {...field}
                      className={errors.dob ? "hasError" : ""}
                      placeholder="Date of Birth"
                      onChange={(date, dateString) => {
                        field?.onChange(
                          moment(dateString).format("YYYY-MM-DD"),
                        );
                      }}
                      label="Date of Birth"
                      value={
                        field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                      }
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current >= moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                    />
                  )}
                />
              </Col>

              <Col lg={12} className="pt-4">
                <Button disabled={!isValid} type="submit" className={"w-100"}>
                  {disable ? (
                    <div className="load black" />
                  ) : memberAddEditForm.formType == "create" ? (
                    "Create Account"
                  ) : (
                    "Update Account"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddSalonModal;
