import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetQuiz = async (params) => {
  return await api.get(apiUrl.quiz, { params });
};

export const DeleteQuiz = async (id) => {
  return await api.delete(apiUrl.quiz + "/" + id);
};

export const GetQuizByID = async (id) => {
  return await api.get(apiUrl.quiz + "/" + id);
};

export const CreateQuiz = async (payload) => {
  return await api.post(apiUrl.quiz, payload);
};

export const UpdateQuiz = async (id, payload) => {
  return await api.put(apiUrl.quiz + "/" + id, payload);
};

export const CreateQuizQuestions = async (payload) => {
  return await api.post(apiUrl.quizQuestion, payload);
};

export const GetQuizQuestions = async (id) => {
  return await api.get(apiUrl.quizQuestion + "/" + id);
};
