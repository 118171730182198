import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetLocations = (params) =>
  api.get(apiUrl.salon_locations, { params });
export const GetSingleLocation = (id) =>
  api.get(`${apiUrl.salon_locations}/${id}`);
export const DeleteSingleLocation = (id) =>
  api.delete(`${apiUrl.salon_locations}/${id}`);
export const AddUserToLocation = (location_id, data) =>
  api.post(`${apiUrl.location_add_users}/${location_id}`, data);
export const UpdateLocation = (id, data) =>
  api.put(`${apiUrl.salon_locations}/${id}`, data);
export const RemoveUserFromLocation = (location_id, user_id) =>
  api.delete(`${apiUrl.location_remove_user}/${location_id}/${user_id}`);
export const CreateLocation = (data) =>
  api.post(`${apiUrl.salon_locations}`, data);
