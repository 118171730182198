import { Checkbox, ConfigProvider, List, Popover, Space } from "antd";
import moment from "moment";
import VirtualList from "rc-virtual-list";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import AddIcon from "../../../assets/images/AddICon.svg";
import DeleteCustomGroupModal from "../../../components/Modals/DeleteCustomGroupModal";
import ShareModal from "../../../components/Modals/ShareModal";
import { Assets } from "../../../constants/images";
import {
  Camera,
  PenBoxIcon,
  PurpleBackIcon,
  RecycleBin,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  addMemberToLocation,
  deleteLocation,
  getSingleLocation,
  removeUserFromLocation,
  updateLocation,
} from "../../../services/locations/frontend-locations";
import { getUsersWithoutPagination } from "../../../services/users/frontend-users";
import { salonUploadToS3, toastMessage } from "../../../utils/helper";
import AddNewLocationModal from "../../../components/Modals/AddNewLocationModal";
import GenericModal from "../../../components/Modals/GenericModal";
import AvatarImage from "../../../components/Common/AvatarImage";

const LocationDetail = () => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [showPopOver, setShowPopOver] = useState(false);
  const [file, setFile] = useState({ file: null, file_url: null });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const { isSingleLocationLoading, singleLocation, formData } = useSelector(
    (state) => state?.locations,
  );
  const { isUsersLoading, users } = useSelector((state) => state?.users);
  const { currentSalonId } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();
  const [tempAddedMembers, setTempAddedMembers] = useState([]);
  const [onAction, setOnAction] = useState(false);

  useEffect(() => {
    if (file?.file) {
      HandleImageChange();
    }
  }, [file]);

  const HandleImageChange = async () => {
    try {
      let apiData = {
        image: null,
        salon_id: currentSalonId,
        name: singleLocation?.data?.name,
      };
      let salonS3UploadResponse = "";
      setDisable(true);
      if (file?.file) {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/`;
        salonS3UploadResponse = await salonUploadToS3(file?.file, folder);
        if (salonS3UploadResponse.status) {
          apiData.image = salonS3UploadResponse?.data?.location;
        } else {
          throw new Error(salonS3UploadResponse?.message);
        }
      }
      const res = await updateLocation(singleLocation?.data?.id, apiData);
      setDisable(false);
      toastMessage("success", res?.data?.message);
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  const handleDelete = async () => {
    await deleteLocation(id, setDisable, showModal, setShowModal);
  };

  const isEqualObject = (userId) => {
    return tempAddedMembers.some((obj) => obj?.id == userId);
  };

  const handleChange = (e, item) => {
    let data = [...tempAddedMembers];
    let isAlreadyAdded = tempAddedMembers?.some(
      (member) => member?.id == item?.id,
    );

    if (isAlreadyAdded) {
      const index = data.findIndex((obj) => obj.id == item?.id);
      data.splice(index, 1);
      setTempAddedMembers(data);
    } else {
      data.push(item);
      setTempAddedMembers(data);
    }
  };

  const addMember = async () => {
    await addMemberToLocation(
      singleLocation?.data?.id,
      setDisable,
      tempAddedMembers,
      setShowPopOver,
      onAction,
      setOnAction,
    );
  };

  const removeMember = async (user_id) => {
    await removeUserFromLocation(
      id,
      user_id,
      setDisable,
      onAction,
      setOnAction,
    );
  };

  const content = (
    <div className="add_new_popup_list member-add">
      <List>
        <VirtualList data={users?.data} height={300} itemKey="id">
          {(item) => (
            <List.Item
              key={item.id}
              actions={[
                <Checkbox
                  onChange={(e) => handleChange(e, item)}
                  checked={isEqualObject(item?.id)}
                ></Checkbox>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <AvatarImage
                    name={item?.name}
                    src={item?.image}
                    placeholder={Assets.ProfilePlaceholder}
                  />
                }
                title={<p>{item?.name || "-"}</p>}
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
      <div className=" list__bottom__tooltip">
        <Button
          disabled={disable}
          className="add__member__button"
          onClick={addMember}
        >
          {disable ? <div className="load black" /> : "Done"}
        </Button>
      </div>
    </div>
  );

  const fetchtSingleLocation = async () => {
    await getSingleLocation(id, setTempAddedMembers, dispatch);
  };

  const fetchUsers = async () => {
    const params = {
      is_paginated: 0,
      salon_id: currentSalonId,
    };
    await getUsersWithoutPagination(params, dispatch);
  };

  useEffect(() => {
    fetchUsers();
    fetchtSingleLocation();
  }, [onAction, currentSalonId]);

  return (
    <>
      <Row>
        <Col lg={12} className={""}>
          <div
            className="leftEventDetailWrapper"
            style={{ marginRight: "0px" }}
          >
            <div className="group__details__header">
              <div className="d-flex justify-content-between">
                <div className="backToEvent">
                  <NavLink
                    to="/settings/locations"
                    style={{ fontSize: "14px" }}
                  >
                    <PurpleBackIcon />
                    <span className="uLineHover">Back to Locations</span>
                  </NavLink>
                </div>
                <div className="shareButton">
                  <Space size={"large"}>
                    {/* <div
                                            className="icon-text-wrapper"
                                        >
                                            <MessageIcon />
                                            <p className="uLine text__capitalize">Chat group</p>
                                        </div> */}
                    <div
                      className="icon-text-wrapper"
                      onClick={() => {
                        setShowGenericModal(true);
                      }}
                    >
                      <PenBoxIcon />
                      <p className="uLine text__capitalize">Edit Location</p>
                    </div>
                    <div
                      className="icon-text-wrapper deleteLabel"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      <RecycleBin />
                      <p className="uLine redLine">Delete Location</p>
                    </div>
                  </Space>
                </div>
              </div>
              <div className="upload__image__section">
                <div>
                  <label className="profile-img-wrapper" htmlFor="profile">
                    <input
                      className="d-none"
                      onChange={(e) => {
                        setFile({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        });
                      }}
                      type="file"
                      id="profile"
                      name="profile"
                      accept="image/*"
                    />
                    <div className="myProfile__img">
                      <img
                        src={
                          file.file_url
                            ? file.file_url
                            : singleLocation?.data?.image ||
                              Assets.GeneralPlaceholder
                        }
                        alt=""
                        onError={(e) =>
                          (e.target.src = Assets.GeneralPlaceholder)
                        }
                      />
                      <div className="cameraIcon">
                        <Camera />
                      </div>
                    </div>
                  </label>
                </div>
                <div className="group-detail-header">
                  <h1 className="sec-heading">
                    {singleLocation?.data?.name || "-"}
                  </h1>
                  <p className="light-gray">
                    {singleLocation?.data?.created_at
                      ? moment(singleLocation?.data?.created_at).format(
                          "MMM DD, yyyy",
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <div className="detail-body">
                  <h4>Assigned Members ({formData?.addedMembers?.length})</h4>
                  <div className="add__member__action__header">
                    {
                      <Popover
                        content={content}
                        overlayStyle={{
                          width: "346px",
                          position: "relative",
                        }}
                        trigger="click"
                        placement="bottomLeft"
                        open={showPopOver}
                        onOpenChange={() => setShowPopOver(!showPopOver)}
                      >
                        <img src={AddIcon} style={{ cursor: "pointer" }} />
                      </Popover>
                    }

                    <p className="add__members__title">Add members</p>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <div>
                        <ConfigProvider renderEmpty={() => <p></p>}>
                          <List
                            className="addLocationList"
                            itemLayout="horizontal"
                            dataSource={formData?.addedMembers}
                            renderItem={(member) => (
                              <List.Item
                                style={{
                                  borderBottom: "none",
                                  padding: "10px 0px",
                                }}
                              >
                                <div className="member__render__list__inner">
                                  <AvatarImage
                                    name={member?.name}
                                    src={member?.image}
                                    placeholder={Assets.ProfilePlaceholder}
                                    size={36}
                                  />
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ gap: "5px" }}
                                  >
                                    <h3 className="mb-0">
                                      {member?.name || "-"}
                                    </h3>
                                    <span
                                      className="members__remove__from__list"
                                      onClick={() => removeMember(member?.id)}
                                    >
                                      (Remove)
                                    </span>
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          />
                        </ConfigProvider>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Onboarding Task"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this goal with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />
      {/* Delete Modal */}
      {/* Delete Modal */}
      <DeleteCustomGroupModal
        disable={disable}
        handleDelete={handleDelete}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        text="Group"
      />
      <GenericModal
        show={showGenericModal}
        onHide={() => {
          setShowGenericModal(false);
        }}
        setModalShow={setShowGenericModal}
        modalTitle={"Update Location"}
        desc={true}
        modalDesc={""}
        size={"lg"}
        bodyComponent={
          <AddNewLocationModal
            setModalShow={setShowGenericModal}
            selectedData={singleLocation?.data}
            isUpdateLocation={true}
            fetchtSingleLocation={fetchtSingleLocation}
          />
        }
      />
    </>
  );
};

export default LocationDetail;
