import { ConfigProvider, Image, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  AddIcon,
  NewSparkleIcon,
  NoDataIcon,
  ToastCrossIcon,
} from "../../constants/svg";

import getdDocumentTableColumns from "../../TableColumns/document";
import DeleteDocModal from "../../components/Modals/DeleteDocModal";
import CreateNewDocModal from "../../components/Modals/Documents/CreateNewDocModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareDocModal from "../../components/Modals/ShareDocModal";
import { Assets } from "../../constants/images";
import { DeleteDocument, GetDocuments } from "../../services/team/document";
import {
  setActionButtonDisable,
  setDocAddEditForm,
  setDocuments,
  setIsPaginate,
  setLoading,
  setPage,
  setPerPage,
  setReRenderTable,
  setSelectedData,
  setSorting,
} from "../../store/slices/documentSlice";
import { tableLoader, toastMessage } from "../../utils/helper";
import { SHARE_URL, tableHeight } from "../../utils/constants";
import LazyLoadTable from "../../components/Table/LazyLoadTable";

const DocumentPage = () => {
  const dispatch = useDispatch();
  const {
    loading,
    page,
    perPage,
    isPaginate,
    reRenderTable,
    docAddEditForm,
    documents,
    actionButtonDisable,
    sorting,
  } = useSelector((state) => state.document);
  const { currentSalonId } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const [dataLoad, setdataLoad] = useState(false);

  const [sharePopupData, setSharePopupData] = useState(null);
  const [docIDForDelete, setDocIDForDelete] = useState("");

  const handleOpenEditDocForm = (record) => {
    dispatch(setSelectedData(record));
    dispatch(
      setDocAddEditForm({
        show: true,
        formType: "update",
      }),
    );
  };

  const onDeleteDocument = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const response = await DeleteDocument(docIDForDelete.id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setDocIDForDelete("");
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(setIsPaginate(0));
      dispatch(setPage(0));
      dispatch(setPerPage(0));
    } else {
      dispatch(setPage(page));
      dispatch(setPerPage(perPage));
    }
  };

  const onInitGetDocumentList = async () => {
    try {
      if (documents?.data?.length == 0) {
        dispatch(setLoading(true));
      }
      const resp = await GetDocuments({
        page,
        is_paginated: isPaginate,
        "per-page": perPage,
        "order-column": sorting.column,
        order: sorting.sortBy,
        salon_id: currentSalonId,
      });
      const { data, status, message } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      const newData = data.data.map((elem) => {
        return {
          ...elem,
          viewedBy: elem.views.length,
        };
      });

      setdataLoad(newData?.length == 0);

      dispatch(
        setDocuments({
          data: newData,
          meta: data.meta,
        }),
      );
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    let column = "";

    switch (sorter.field) {
      case "title":
        column = "name";
        break;

      case "category":
        column = "category_id";
        break;

      default:
        column = sorter.field;
        break;
    }

    dispatch(
      setSorting({
        column,
        sortBy: sorter.order,
      }),
    );
  };

  const getDocType = (link) => {
    if (link) {
      let arr = link.split(".");
      let last = arr[arr.length - 1];
      let type = last.toUpperCase();
      return type;
    }
    return "-";
  };

  useEffect(() => {
    onInitGetDocumentList();
  }, [page, perPage, reRenderTable, sorting, currentSalonId]);

  // To handle the fast tab change
  useEffect(() => {
    dispatch(setDocuments([]));
    dispatch(setLoading(true));
  }, []);

  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">Documents</h2>
        <button
          className="btn-gradient"
          onClick={() => {
            dispatch(
              setDocAddEditForm({
                show: true,
                formType: "create",
              }),
            );
          }}
        >
          <span>
            <AddIcon />
          </span>
          New Doc
        </button>
      </div>
      <div className="teamTabSubHead teamHeading">
        <p>
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> Upload your contracts and policies
          here. For training materials, like product guides, see{" "}
          <NavLink to={"/resources"} className={"uLine globalBlueLinkColor"}>
            Resources.
          </NavLink>
        </p>
      </div>
      <div className="teamBody">
        <ConfigProvider
          renderEmpty={() => (
            <>
              {dataLoad ? (
                <div className="noSearchData">
                  <NoDataIcon />
                  <h5>Docs you've added will appear here.</h5>
                  <h6>Click "New Doc" to get started.</h6>
                </div>
              ) : (
                <>
                  <div className="loader-table mt-5 pt-5">
                    <Image
                      width={100}
                      height={100}
                      src={Assets.loadingGif}
                      preview={false}
                    />
                  </div>
                </>
              )}
            </>
          )}
        >
          <LazyLoadTable
            /*            locale={{
                          emptyText:(   <Empty
                              image={Assets.EmptyWhiteBg}
                              description={''}
                          />),
                        }} */
            dataSource={documents?.data}
            columns={getdDocumentTableColumns(
              setShowModal,
              setDocIDForDelete,
              handleOpenEditDocForm,
              setSharePopupData,
            )}
            pagination={{
              onChange: handlePaginationChange,
              pageSize: !isPaginate ? documents.length : perPage,
              showSizeChanger: false,
              current: !isPaginate ? 1 : page,
              total: !isPaginate ? documents.length : documents?.meta?.total,
              pageSizeOptions: [10, 20, 50, 100, "All"],
            }}
            store={{ store: "document", param: "documents" }}
            bordered={false}
            loading={tableLoader(loading)}
            scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            rowKey={(record) => record.id}
          />
        </ConfigProvider>
        {/* Share Modal */}

        <ShareDocModal
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Document"}
          data={{
            title: sharePopupData?.title,
            link: SHARE_URL.DOCS + sharePopupData?.id,
            docType: getDocType(sharePopupData?.link),
            size: sharePopupData?.size,
            description: sharePopupData?.description,
          }}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to={""}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className={"uLine"}
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this event with admins.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Advanced Balayage Master Class",
            para1:
              "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
            para2: "Sep 12, 2023 @ 9:00 AM EST",
          }}
        />

        {/* Delete Modal */}
        <DeleteDocModal
          handleDelete={onDeleteDocument}
          disable={actionButtonDisable}
          show={showModal.deleteModal}
          onHide={() => {
            setDocIDForDelete("");
            setShowModal({ ...showModal, deleteModal: false });
          }}
          heading="Delete Doc?"
          para={
            <>
              Are you sure you want to delete
              <b> {docIDForDelete?.title || "-"} ?</b>
              <br />
              Important: This document cannot be recovered.
            </>
          }
        />
        <GenericModal
          show={docAddEditForm.show}
          onHide={() => {
            dispatch(
              setDocAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setDocAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          modalTitle={
            docAddEditForm.formType == "create"
              ? "Upload Document"
              : "Update Doc"
          }
          customModalHeader={"customUploadDocument"}
          size={"lg"}
          bodyComponent={
            <CreateNewDocModal customModalBody={"customNewDocBody"} />
          }
        />
      </div>
    </>
  );
};

export default DocumentPage;
