import { notification, Popover, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import EventModalForms from "../../components/pages/Events/Modals/EventModalForms";
import { Assets } from "../../constants/images";
import {
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import {
  DeleteSingleEvent,
  GetSingleEvent,
} from "../../services/events/events";
import {
  setEventAddEditFormVisibility,
  setIsPageLoading,
  setSelectedData,
} from "../../store/slices/eventsSlice";
import {
  event_status,
  salooon_event_type,
  SHARE_URL,
} from "../../utils/constants";
import ProfileHoverChat from "../../components/Common/ProfileHoverChat";
import { setStatUserHover } from "../../store/slices/globalSlice";
import {
  hasAccess,
  renderContentWithLinks,
  toastMessage,
} from "../../utils/helper";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ProfileHover from "../../components/Common/ProfileHover";
import AvatarImage from "../../components/Common/AvatarImage";

const EventDetailPage = () => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = SHARE_URL.EVENTS + id;
  const {
    selectedData,
    eventAddEditFormVisibility,
    reRenderEventDetailPage,
    isPageLoading,
  } = useSelector((state) => state?.events);

  const { statUserHover } = useSelector((state) => state.global);

  const [disable, setDisable] = useState(false);

  const [show, setShow] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item.user?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  const handleDelete = async () => {
    try {
      setDisable(true);

      const res = await DeleteSingleEvent(id);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);

      setTimeout(() => {
        navigate("/events");
      }, 1200);
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  const getSingleEvent = async () => {
    dispatch(setIsPageLoading(true));
    try {
      const res = await GetSingleEvent(id);
      dispatch(setSelectedData(res.data.data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setIsPageLoading(false));
    }
  };

  useEffect(() => {
    getSingleEvent();
  }, [reRenderEventDetailPage]);

  const handleClickEmptyHref = (e) => {
    e.preventDefault();
  };

  const getEventText = (eventType) => {
    let r = "-";
    switch (eventType) {
      case salooon_event_type.online:
        r = "Online";
        break;

      case salooon_event_type.onsite:
        r = "In Person";
        break;
    }
    return r;
  };

  if (isPageLoading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <Row>
      <Col lg={8} className={"pe-0"}>
        <div className="leftEventDetailWrapper">
          <div className="eventDetailHeader innerContainer d-flex justify-content-between">
            <div className="backToEvent">
              <NavLink to="/events">
                <PurpleBackIcon />
                <span className="uLineHover">Back to Events</span>
              </NavLink>
            </div>
            <div className="shareButton">
              <Space size={"large"}>
                <div
                  className="icon-text-wrapper cursor-pointer blackLine"
                  onClick={() =>
                    setShowModal((prevState) => ({
                      ...prevState,
                      shareModal: true,
                    }))
                  }
                >
                  <ShareIcon />
                  <p className="uLine ">Share</p>
                </div>
                {hasAccess(selectedData?.user) && (
                  <>
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={() =>
                        dispatch(
                          setEventAddEditFormVisibility({
                            show: true,
                            formType: "update",
                          }),
                        )
                      }
                    >
                      <EditIcon />
                      <p className="uLine ">Edit</p>
                    </div>
                    <div
                      className="icon-text-wrapper deleteLabel cursor-pointer"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      <RecycleBin />
                      <p className="uLine redLine ">Delete</p>
                    </div>
                  </>
                )}
              </Space>
            </div>
          </div>
          <div className="eventDetailBody">
            <div className="innerContainer">
              <h3 className="sec-heading">{selectedData?.name}</h3>
              <div className="eventDetailInformation">
                <div className="eventDetailItem">
                  <h5 className="head">Category</h5>
                  <p>
                    {getEventText(selectedData?.type) +
                      ", " +
                      selectedData?.category?.name || "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Description</h5>
                  <p>
                    {selectedData?.description
                      ? renderContentWithLinks(selectedData?.description)
                      : "-"}
                  </p>
                </div>

                {selectedData?.type == salooon_event_type.online && (
                  <div className="eventDetailItem">
                    <h5 className="head">Event Link</h5>
                    {selectedData?.link ? (
                      <a
                        href={selectedData?.link}
                        target="_blank"
                        className={"uLine"}
                        rel="noreferrer"
                      >
                        {selectedData?.link}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                )}

                {selectedData?.type == salooon_event_type.onsite && (
                  <div className="eventDetailItem">
                    <h5 className="head">Location</h5>
                    <p>
                      {selectedData?.address ? (
                        <a
                          href={
                            "https://maps.google.com?q=" + selectedData?.address
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {selectedData?.address}
                        </a>
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                )}

                <div className="eventDetailItem">
                  <h5 className="head">Start Date</h5>
                  <p>
                    {selectedData?.start_date
                      ? moment(selectedData?.start_date).format(
                          "MMM DD, yyyy",
                        ) +
                        " @ " +
                        selectedData?.start_time
                      : "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">End Date</h5>
                  <p>
                    {selectedData?.end_date
                      ? moment(selectedData?.end_date).format("MMM DD, yyyy") +
                        " @ " +
                        selectedData?.end_time
                      : "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Timezone</h5>
                  <p>{selectedData?.timezone || "-"}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Organizer</h5>
                  <p>{selectedData?.instructor || "-"}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Price</h5>
                  <p>
                    {selectedData && selectedData.price
                      ? "$" + selectedData.price
                      : "-"}
                  </p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Created By</h5>
                  <p>{selectedData?.user?.name}</p>
                </div>
                <div className="eventDetailItem">
                  <h5 className="head">Created On</h5>
                  <p>
                    {selectedData?.created_at
                      ? moment(selectedData?.created_at).format("MMM DD, yyyy")
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col lg={4} className={"p-0"} onMouseLeave={handleMouseLeave}>
        <div className="rightEventDetailWrapper">
          <div className="eventDetailHeader d-flex justify-content-between">
            <h4 className="sec-heading m-0 p-0">Event Responses</h4>
          </div>
          <div className="eventDetailBody">
            <div className="response-card">
              <div className="response-header">
                <h6>Going ({selectedData?.meta?.going})</h6>
              </div>
              <div className="response-body">
                {selectedData?.eventReservations?.map((item, index) => {
                  if (
                    item?.status_id == event_status.going &&
                    item?.user?.name
                  ) {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className="avatar-item"
                      >
                        <Popover
                          placement="rightTop"
                          content={
                            <ProfileHover
                              user={{
                                id: item?.user?.id,
                                name: item?.user?.name,
                                image: item?.user?.image,
                                position: item?.user?.position?.name,
                              }}
                            />
                          }
                          arrow={true}
                          key={index}
                        >
                          <div className="statUserHoverContainer customHover">
                            <AvatarImage
                              name={item?.user?.name}
                              src={item?.user?.image}
                              placeholder={Assets.ProfilePlaceholder}
                              size={38}
                            />
                          </div>
                        </Popover>
                        <NavLink
                          key={index}
                          to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                        >
                          <p>{item?.user?.name}</p>
                        </NavLink>
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div className="response-card">
              <div className="response-header">
                <h6>Interested ({selectedData?.meta?.interested})</h6>
              </div>
              <div className="response-body">
                {selectedData?.eventReservations?.map((item, index) => {
                  if (
                    item?.status_id == event_status.interested &&
                    item?.user?.name
                  ) {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className="avatar-item"
                      >
                        <Popover
                          placement="rightTop"
                          content={
                            <ProfileHover
                              user={{
                                id: item?.user?.id,
                                name: item?.user?.name,
                                image: item?.user?.image,
                                position: item?.user?.position?.name,
                              }}
                            />
                          }
                          arrow={true}
                          key={index}
                        >
                          <div className="statUserHoverContainer customHover">
                            <AvatarImage
                              name={item?.user?.name}
                              src={item?.user?.image}
                              placeholder={Assets.ProfilePlaceholder}
                              size={38}
                            />
                          </div>
                        </Popover>
                        <NavLink
                          key={index}
                          to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                        >
                          <p>{item?.user?.name}</p>
                        </NavLink>
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div className="response-card">
              <div className="response-header">
                <h6>Can’t Go ({selectedData?.meta?.not_going})</h6>
              </div>
              <div className="response-body">
                {selectedData?.eventReservations?.map((item, index) => {
                  if (
                    item?.status_id == event_status.not_going &&
                    item?.user?.name
                  ) {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className="avatar-item"
                      >
                        <Popover
                          placement="rightTop"
                          content={
                            <ProfileHover
                              user={{
                                id: item?.user?.id,
                                name: item?.user?.name,
                                image: item?.user?.image,
                                position: item?.user?.position?.name,
                              }}
                            />
                          }
                          arrow={true}
                          key={index}
                        >
                          <div className="statUserHoverContainer customHover">
                            <AvatarImage
                              name={item?.user?.name}
                              src={item?.user?.image}
                              placeholder={Assets.ProfilePlaceholder}
                              size={38}
                            />
                          </div>
                        </Popover>
                        <NavLink
                          key={index}
                          to={`/team/members/${item?.user?.slug ?? item?.user?.id}`}
                        >
                          <p>{item?.user?.name}</p>
                        </NavLink>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        {/* Share Modal */}
        <ShareModal
          data={selectedData}
          shareLink={url}
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Event"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this event with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Advanced Balayage Master Class",
            para1:
              "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
            para2: "Sep 12, 2023 @ 9:00 AM EST",
          }}
        />

        {/* Delete Modal */}
        <DeleteModal
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Event?"
          disable={disable}
          handleDelete={handleDelete}
          para={
            <>
              Are you sure you want to delete{" "}
              <b>{selectedData?.name || "this event"} ?</b>
              <br />
              Important: This event cannot be recovered.
            </>
          }
          notificationMessage="PDF resource"
        />
      </Col>
      {/* Edit Drawer */}

      <GenericModal
        show={eventAddEditFormVisibility.show}
        onHide={() =>
          dispatch(
            setEventAddEditFormVisibility({
              show: false,
              formType: null,
            }),
          )
        }
        setModalShow={(v) => {
          dispatch(
            dispatch(
              setEventAddEditFormVisibility({
                show: v,
                formType: null,
              }),
            ),
          );
        }}
        modalTitle={
          eventAddEditFormVisibility.formType == "create"
            ? "Create New Event"
            : "Edit Event"
        }
        size={"lg"}
        bodyComponent={<EventModalForms />}
      />
    </Row>
  );
};

export default EventDetailPage;
