import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowTriangleDown,
  CalenderIconDownward,
  CalenderIconDownwardRegular,
  CalenderIconUpward,
  CalenderIconUpwardRegular,
  CheckMarkIcon,
  CreateIcon,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setEventAddEditFormVisibility,
  setIsUpcomingEventsLoading,
} from "../../../store/slices/eventsSlice";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";
import { roles as salonRoles } from "../../../utils/constants";

const EventHeader = (props) => {
  const { upcomingEvents, pastEvents } = useSelector((state) => state.events);

  const { categories, allSalons, currentSalonId } = useSelector(
    (state) => state.global,
  );

  const dispatch = useDispatch();

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };

  const handleChange = (value) => {
    props?.handleResetPage();
    props?.setSelectedCategory(value);
    dispatch(setIsUpcomingEventsLoading(true));
  };

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Events</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                <div className="icon icon-active">
                  <CalenderIconUpward />
                </div>
                <div className="icon">
                  <CalenderIconUpwardRegular />
                </div>
                Upcoming ({upcomingEvents?.data?.length || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                <div className="icon icon-active">
                  <CalenderIconDownwardRegular />
                </div>
                <div className="icon">
                  <CalenderIconDownward />
                </div>
                Past ({pastEvents?.data?.length || 0})
              </div>
            </div>
          </div>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              value={currentSalonId || undefined}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue="All Categories"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={categories}
            onChange={(e) => handleChange(e)}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() =>
              dispatch(
                setEventAddEditFormVisibility({
                  show: true,
                  formType: "create",
                }),
              )
            }
          >
            <span>
              <CreateIcon />
            </span>
            Post Event
          </button>
        </div>
      </div>
    </>
  );
};

export default EventHeader;
