import { Avatar } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Assets } from "../../constants/images";
import { ToolTipTriangle } from "../../constants/svg";
import {
  setAddRemoveMemberInSendChatPopup,
  setSendChatSelectedUsers,
  setShowHideChatModel,
  setStatUserHover,
} from "../../store/slices/globalSlice";
import AvatarImage from "./AvatarImage";

const ProfileHoverChat = ({ show, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);

  // const calculateTooltipPosition = () => {
  //     const windowHeight = window.innerHeight;
  //     const tooltipElement = document.querySelector(".statUserHoverPopup");
  //     if (tooltipElement) {
  //         const tooltipHeight = tooltipElement.clientHeight;
  //         const rect = tooltipElement.getBoundingClientRect();
  //         const topSpace = rect.top;
  //         const bottomSpace = windowHeight - rect.bottom;
  //
  //         if (topSpace < bottomSpace && topSpace < tooltipHeight) {
  //             tooltipElement.classList.add("position-top");
  //         } else {
  //             tooltipElement.classList.remove("position-top");
  //         }
  //     }
  // };
  //
  // useEffect(() => {
  //     calculateTooltipPosition();
  //     window.addEventListener("resize", calculateTooltipPosition);
  //
  //     return () => {
  //         window.removeEventListener("resize", calculateTooltipPosition);
  //     };
  // }, [show]);

  if (!show) return null;

  return (
    <>
      <div className="avatar-overlay-two m-4 statUserHoverPopup">
        <div className="triangleToolTip">
          <ToolTipTriangle />
        </div>
        <div className="avatar-overlay-content">
          <div className="avatar-icon">
            <AvatarImage
              name={user?.name}
              src={user?.image}
              placeholder={Assets.ProfilePlaceholder}
              size={48}
            />
          </div>
          <h4 className="userName">{user?.name || "-"}</h4>
          <p className="userTitle m-0">{user?.position}</p>
          <div className="avatar-buttons">
            <Button
              type="button"
              className={"view-profile border-grey-log-btn"}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setStatUserHover(null));
                navigate(`/team/members/${user.id}`);
              }}
            >
              View Profile
            </Button>
            {user.id != me?.data?.id ? (
              <Button
                type="button"
                className={"messageBtn"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setAddRemoveMemberInSendChatPopup(false));
                  dispatch(
                    setSendChatSelectedUsers([
                      {
                        label: user.name,
                        image: user.image,
                        value: user.id,
                      },
                    ]),
                  );
                  dispatch(setStatUserHover(null));
                  dispatch(setShowHideChatModel(true));
                }}
              >
                Message
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHoverChat;
