import { createSlice } from "@reduxjs/toolkit";

export const requestsSlice = createSlice({
  name: "requests",
  initialState: {
    isEducationRequestLoading: false,
    isTimeOffRequestLoading: false,
    isScheduleRequestLoading: false,
    isSingleRequestLoading: false,
    isRequestHistoryLoading: false,
    filters: {
      location: null,
      status: null,
    },
    educationRequest: {
      meta: null,
      data: [],
    },
    timeOffRequest: {
      meta: null,
      data: [],
    },
    scheduleRequest: {
      meta: null,
      data: [],
    },

    singleRequest: {
      isSingleRequestLoading: true,
      data: null,
    },
    pagination: {
      timeOff: {
        currentPage: 1,
        perPage: 10,
        isPaginate: 1,
      },
      schedule: {
        currentPage: 1,
        perPage: 10,
        isPaginate: 1,
      },
      educatioon: {
        currentPage: 1,
        perPage: 10,
        isPaginate: 1,
      },
    },
    requestHistory: {
      meta: null,
      data: [],
    },
    selectedTab: null,
  },
  reducers: {
    setIsEducationRequestLoading: (state, action) => {
      state.isEducationRequestLoading = action.payload;
    },
    setIsTimeOffRequestLoading: (state, action) => {
      state.isTimeOffRequestLoading = action.payload;
    },
    setIsScheduleRequestLoading: (state, action) => {
      state.isScheduleRequestLoading = action.payload;
    },

    setEducationRequest: (state, action) => {
      state.educationRequest.meta = action.payload.meta;
      state.educationRequest.data = action.payload.data;
    },
    setTimeOffRequest: (state, action) => {
      state.timeOffRequest.meta = action.payload.meta;
      state.timeOffRequest.data = action.payload.data;
    },
    setScheduleRequest: (state, action) => {
      state.scheduleRequest.meta = action.payload.meta;
      state.scheduleRequest.data = action.payload.data;
    },

    setIsSingleRequestLoading: (state, action) => {
      state.isSingleRequestLoading = action.payload;
    },
    setSingleRequest: (state, action) => {
      state.singleRequest.data = action.payload;
    },

    setIsRequestHistoryLoading: (state, action) => {
      state.isRequestHistoryLoading = action.payload;
    },
    setRequestHistory: (state, action) => {
      state.requestHistory.meta = action.payload.meta;
      state.requestHistory.data = action.payload.data;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const {
  setIsEducationRequestLoading,
  setIsTimeOffRequestLoading,
  setIsScheduleRequestLoading,
  setEducationRequest,
  setTimeOffRequest,
  setScheduleRequest,
  setIsSingleRequestLoading,
  setSingleRequest,
  setIsRequestHistoryLoading,
  setRequestHistory,
  setFilters,
  setPagination,
  setSelectedTab,
} = requestsSlice.actions;

export default requestsSlice.reducer;
