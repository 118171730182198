import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetDocuments = async (params) => {
  return await api.get(apiUrl.document, { params });
};

export const GetDocumentDetailByID = async (id) => {
  return await api.get(apiUrl.document + "/" + id);
};

export const DeleteDocument = async (id) => {
  return await api.delete(apiUrl.document + "/" + id);
};

export const CreateDocument = async (payload) => {
  return await api.post(apiUrl.document, payload);
};

export const UpdateDocument = async (id, payload) => {
  return await api.put(apiUrl.document + "/" + id, payload);
};
