import React, { useEffect, useState } from "react";
import { Select, Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Assets } from "../../constants/images";
import { CheckMarkIcon, RoundedStaffIcon } from "../../constants/svg";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { TbCircleCheckFilled } from "react-icons/tb";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Button } from "react-bootstrap";
import { GetMembers } from "../../services/team/member";
import AvatarImage from "./AvatarImage";

const { Option } = Select;

// Define your members here
// const members = [
//   { id: 1, name: "Amber Jones", image: Assets.ProfileAvatarOne },
//   { id: 2, name: "Ashley Smith", image: Assets.ProfileAvatarTwo },
//   { id: 3, name: "Brittany Jensen", image: Assets.ProfileAvatarThree },
//   { id: 4, name: "Chelsea Willson", image: Assets.ProfileAvatarFour },
//   { id: 5, name: "Donna Kerrigan", image: Assets.ProfileAvatar },
//   { id: 6, name: "Elizabeth Porter", image: Assets.ProfileAvatarOne },
//   { id: 7, name: "Gina Moccia", image: Assets.ProfileAvatarTwo },
//   { id: 8, name: "Steven Hansen", image: Assets.ProfileAvatarThree },
//   { id: 9, name: "Amber Jones", image: Assets.ProfileAvatar },
// ];

function CustomMembersSelect(props) {
  const { selectedValue, handleChangeMember, className } = props;

  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (value) => {
    handleChangeMember(value);
    setSelectedMembers(value);
  };

  const getMember = async () => {
    const response = await GetMembers({
      is_paginated: 0,
      "order-column": "id",
      order: "desc",
    });
    const { data, status, message } = response.data;
    setMembers(data.users);
  };

  useEffect(() => {
    getMember();
  }, []);

  useEffect(() => {
    setSelectedMembers(selectedValue);
  }, [selectedValue]);

  useEffect(() => {});

  const menuItemSelectedIcon = <RoundedStaffIcon />;

  return (
    <Select
      {...props}
      mode="multiple"
      allowClear
      placeholder="Add Member(s)"
      value={selectedMembers}
      onChange={handleChange}
      menuItemSelectedIcon={false}
      maxTagCount={0}
      maxTagPlaceholder={(selectedItems) => `${selectedItems.length} Member(s)`}
      showSearch={false}
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
      className={`CustomeMemberSelect noLabelSelect ${className}`}
      dropdownClassName={"customMemberOvlay"}
      suffixIcon={
        selectedMembers.length > 0 ? (
          <Avatar.Group>
            {selectedMembers.slice(0, 3).map((memberId) => {
              const member = members.find((m) => m.id == memberId);
              if (member) {
                return (
                  <AvatarImage
                    name={member.name}
                    src={member.image}
                    placeholder={Assets.ProfilePlaceholder}
                    key={memberId}
                    size={32}
                  />
                );
              }
            })}
            {selectedMembers.length > 3 && (
              <Badge
                count={`+${selectedMembers.length - 3}`}
                style={{
                  backgroundColor: "#fff",
                  color: "#999",
                  boxShadow: "0 0 0 1px #d9d9d9 inset",
                }}
              />
            )}
          </Avatar.Group>
        ) : (
          <RoundedStaffIcon />
        )
      }
      dropdownRender={(menu) => (
        <>
          <div>{menu}</div>
          <div className="customMemberBtn">
            <Button
              disabled={!selectedMembers.length}
              onClick={() => setDropdownOpen(false)}
              className="w-100"
            >
              Done
            </Button>
          </div>
        </>
      )}
    >
      {members.map((member) => (
        <Option key={member.id} value={member.id}>
          <div className="customMemberItem">
            <div className="memberName d-flex align-items-center">
              <AvatarImage
                name={member.name}
                src={member.image}
                placeholder={Assets.ProfilePlaceholder}
                size={30}
              />
              <h4
                className="m-0 ms-2 black truncate"
                style={{ fontSize: "14px" }}
              >
                {member.name}
              </h4>
            </div>
            {selectedMembers.includes(member.id) ? (
              <div
                className="check"
                style={{ fontSize: "20px", marginTop: "-10px" }}
              >
                <IoCheckmarkCircle />
              </div>
            ) : (
              <div
                className="unchecked"
                style={{ fontSize: "20px", marginTop: "-10px" }}
              >
                <MdRadioButtonUnchecked />
              </div>
            )}
          </div>
        </Option>
      ))}
    </Select>
  );
}

export default CustomMembersSelect;
