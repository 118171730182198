import { Avatar, notification, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteDocModal from "../../../components/Modals/DeleteDocModal";
import CreateNewDocModal from "../../../components/Modals/Documents/CreateNewDocModal";
import GenericModal from "../../../components/Modals/GenericModal";
import ShareDocModal from "../../../components/Modals/ShareDocModal";
import { Assets } from "../../../constants/images";
import {
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../../constants/svg";
import {
  DeleteDocument,
  GetDocumentDetailByID,
} from "../../../services/team/document";
import {
  setActionButtonDisable,
  setDocAddEditForm,
  setPageLoading,
  setSelectedData,
} from "../../../store/slices/documentSlice";
import {
  roundStringToNearestInteger,
  toastMessage,
} from "../../../utils/helper";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { setStatUserHover } from "../../../store/slices/globalSlice";
import ProfileHoverChat from "../../../components/Common/ProfileHoverChat";
import { SHARE_URL } from "../../../utils/constants";
import AvatarImage from "../../../components/Common/AvatarImage";

const DocumentDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    selectedData,
    pageLoading,
    docAddEditForm,
    actionButtonDisable,
    reRenderDocDetailPage,
  } = useSelector((state) => state.document);
  const { positions, statUserHover } = useSelector((state) => state.global);
  const { id } = useParams();

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const onDeleteDocument = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const response = await DeleteDocument(id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      setShowModal({ ...showModal, deleteModal: false });
      navigate("/team/docs");
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const onInitGetDocumentDetail = async () => {
    dispatch(setPageLoading(true));
    const response = await GetDocumentDetailByID(id);
    const { data, message, status } = response.data;
    if (!status) {
      notification.error({
        description: message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      return;
    }

    dispatch(setPageLoading(false));
    dispatch(setSelectedData(data));
  };
  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };
  useEffect(() => {
    onInitGetDocumentDetail();
  }, [reRenderDocDetailPage]);

  const getDocType = (link) => {
    if (link) {
      let arr = link.split(".");
      let last = arr[arr.length - 1];
      let type = last.toUpperCase();
      return type;
    }
    return "-";
  };

  if (pageLoading || !selectedData) {
    return <LoadingScreen child={true} />;
  }

  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/team/docs">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Docs</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  <div
                    className="icon-text-wrapper blackLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        shareModal: true,
                      }))
                    }
                  >
                    <ShareIcon />
                    <p className="uLine ">Share</p>
                  </div>
                  <div
                    className="icon-text-wrapper blackLine"
                    onClick={() => {
                      dispatch(
                        setDocAddEditForm({
                          show: true,
                          formType: "update",
                        }),
                      );
                    }}
                  >
                    <EditIcon />
                    <p className="uLine ">Edit</p>
                  </div>
                  <div className="icon-text-wrapper deleteLabel">
                    <RecycleBin />
                    <p
                      className="uLine redLine"
                      onClick={() =>
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }))
                      }
                    >
                      Delete
                    </p>
                  </div>
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <h3 className="sec-heading">{selectedData?.title || "-"}</h3>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Description</h5>
                    <p>
                      {selectedData.description
                        ? selectedData.description
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Type</h5>
                    <p>{getDocType(selectedData.link)}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">File</h5>
                    <a
                      href={selectedData.link}
                      target="_blank"
                      className={"uLine"}
                      rel="noreferrer"
                    >
                      {selectedData.link ?? "-"}
                    </a>
                  </div>

                  <div className="eventDetailItem">
                    <h5 className="head">Size</h5>
                    {/* <p>{selectedData.size.split(/[.\s]/)[0] + " KB" ?? '-'}</p> */}
                    <p>
                      {roundStringToNearestInteger(selectedData.size) + " KB" ??
                        "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created By</h5>
                    <p>{selectedData?.user?.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {selectedData &&
                        moment(selectedData.created_at).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className={"p-0"}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Document Stats</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>Total Views ({selectedData.views.length})</h6>
                </div>
                <div className="response-body">
                  {selectedData.views.map(
                    (elem) =>
                      elem.user && (
                        <NavLink
                          to={`/team/members/${elem?.user?.slug ?? elem?.user?.id}`}
                        >
                          <div
                            style={{ position: "relative" }}
                            className="avatar-item"
                          >
                            <div
                              className="statUserHoverContainer customHover"
                              onMouseEnter={(e) => {
                                dispatch(
                                  setStatUserHover({
                                    id: elem?.user?.id,
                                    name: elem?.user?.name,
                                    image: elem?.user?.image,
                                  }),
                                );
                              }}
                              onMouseLeave={() => {
                                dispatch(setStatUserHover(null));
                              }}
                            >
                              <ProfileHoverChat
                                user={{
                                  id: elem?.user?.id,
                                  name: elem?.user?.name,
                                  image: elem?.user?.image,
                                  position: returnPosition(
                                    elem?.user?.position_id,
                                  ),
                                }}
                                show={
                                  !!(
                                    statUserHover &&
                                    statUserHover.id == elem?.user?.id
                                  )
                                }
                              />
                              <AvatarImage
                                name={elem?.user?.name}
                                src={elem?.user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                            <p>{elem?.user?.name}</p>
                          </div>
                        </NavLink>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Share Modal */}

          <ShareDocModal
            show={showModal.shareModal}
            onHide={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: false }))
            }
            setModalShow={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: true }))
            }
            modalTitle={"Share Document"}
            data={{
              title: selectedData?.title,
              link: SHARE_URL.DOCS + selectedData?.id,
              docType: getDocType(selectedData?.link),
              size: selectedData?.size,
              description: selectedData?.description,
            }}
            modalSubtitle={
              <>
                Click “Copy Link” below, then{" "}
                <NavLink
                  to={""}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={"uLine"}
                >
                  {" "}
                  Open Chat{" "}
                </NavLink>{" "}
                to easily share this event with admins.
              </>
            }
            size={"lg"}
            bodyComponent={{
              img: Assets.eventShareModal,
              title: "Advanced Balayage Master Class",
              para1:
                "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
              para2: "Sep 12, 2023 @ 9:00 AM EST",
            }}
          />

          {/* Delete Modal */}
          <DeleteDocModal
            handleDelete={onDeleteDocument}
            disable={actionButtonDisable}
            show={showModal.deleteModal}
            onHide={() => setShowModal({ ...showModal, deleteModal: false })}
            heading="Delete Doc?"
            para={
              <>
                Are you sure you want to delete
                <b> {selectedData?.title || "this doc"} ?</b>
                <br />
                Important: This document cannot be recovered.
              </>
            }
          />

          <GenericModal
            show={docAddEditForm.show}
            onHide={() => {
              dispatch(
                setDocAddEditForm({
                  show: false,
                  formType: null,
                }),
              );
            }}
            setModalShow={(e) => {
              dispatch(
                setDocAddEditForm({
                  show: false,
                  formType: null,
                }),
              );
            }}
            modalTitle={
              docAddEditForm.formType == "create"
                ? "Upload Document"
                : "Update Doc"
            }
            modalDesc={
              "Every doc you add is automatically sent to your Staff App for easy access."
            }
            size={"lg"}
            bodyComponent={<CreateNewDocModal />}
          />
        </Col>
      </Row>
    </>
  );
};

export default DocumentDetail;
