import { createSlice } from "@reduxjs/toolkit";

export const aletsSlice = createSlice({
  name: "alets",
  initialState: {
    isAlertsLoading: true,
    alerts: {
      meta: null,
      data: [],
    },
    singleAlert: {
      isSingleLoading: true,
      data: null,
    },
    formData: {
      date: null,
      time: null,
      repeat_type: null,
      day: null,
      title: "",
      message: "",
      users_id: [],
      category: null,
      content: null,
      link: "",
      link_type: null,
      checked: false,
      type: "",
      selectedCategory: "",
      recipient_type: "",
    },
  },
  reducers: {
    setIsAlertsLoading: (state, action) => {
      state.isAlertsLoading = action.payload;
    },
    setAlerts: (state, action) => {
      state.alerts.data = action.payload.data;
      state.alerts.meta = action.payload.meta;
    },
    setIsSingleAlertLoading: (state, action) => {
      state.singleAlert.isSingleLoading = action.payload;
    },
    setSingleAlert: (state, action) => {
      state.singleAlert.data = action.payload;
    },

    setAlertsFormData: (state, action) => {
      const newFormData = action.payload;
      Object.assign(state.formData, newFormData);
    },
    setResetAlertsFormData: (state, action) => {
      state.formData.date = null;
      state.formData.time = null;
      state.formData.repeat_type = null;
      state.formData.day = null;
      state.formData.title = "";
      state.formData.message = "";
      state.formData.users_id = [];
      state.formData.category = null;
      state.formData.content = null;
      state.formData.link = "";
      state.formData.checked = false;
      state.formData.link_type = null;
      state.formData.type = "";
      state.formData.selectedCategory = "";
      state.formData.recipient_type = "";
    },
  },
});

export const {
  setIsAlertsLoading,
  setAlerts,
  setIsSingleAlertLoading,
  setSingleAlert,
  setAlertsFormData,
  setResetAlertsFormData,
} = aletsSlice.actions;

export default aletsSlice.reducer;
