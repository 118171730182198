import { createSlice } from "@reduxjs/toolkit";

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    isTasksLoading: true,
    isSingleTaskLoading: true,

    tasks: {
      meta: null,
      data: [],
    },

    singleTask: {
      isSingleTaskLoading: true,
      data: null,
    },

    formData: {
      type: "",
      name: "",
      description: "",
      repeat_cycle: null,
      due_on: null,
      start: null,
      end: null,
      assignees: [],
      selectedCategory: null,
      recipient_type: "",
    },
  },
  reducers: {
    setIsTasksLoading: (state, action) => {
      state.isTasksLoading = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks.data = action.payload.data;
      state.tasks.meta = action.payload.meta;
    },

    setIsSingleTaskLoading: (state, action) => {
      state.singleTask.isSingleTaskLoading = action.payload;
    },
    setSingleTask: (state, action) => {
      state.singleTask.data = action.payload;
    },

    setTasksFormData: (state, action) => {
      const newFormData = action.payload;
      Object.assign(state.formData, newFormData);
    },
    setResetTasksFormData: (state, action) => {
      state.formData.type = "";
      state.formData.name = "";
      state.formData.description = "";
      state.formData.repeat_cycle = null;
      state.formData.due_on = null;
      state.formData.start = null;
      state.formData.end = null;
      state.formData.assignees = [];
      state.formData.selectedCategory = null;
      state.formData.recipient_type = "";
    },
  },
});

export const {
  setIsTasksLoading,
  setTasks,

  setIsSingleTaskLoading,
  setSingleTask,

  setTasksFormData,
  setResetTasksFormData,
} = tasksSlice.actions;

export default tasksSlice.reducer;
