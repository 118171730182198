import React from "react";

const RadioModalCards = ({
  title,
  para,
  icon,
  active,
  handleClick,
  subpara,
}) => {
  return (
    <div
      className={`iconTextCard ${active ? "active" : ""}`}
      onClick={handleClick}
    >
      <div className="icon">{icon}</div>
      <div className="heading">{title}</div>
      <div className="content">
        {para} <span className="font-italic"> {subpara}</span>
      </div>
    </div>
  );
};

export default RadioModalCards;
