import { ConfigProvider, Image, notification, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import getNewHireCheckListTableColumns from "../../TableColumns/newHireCheckList";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import CreateNewHireModal from "../../components/Modals/Teams/CreateNewHIreModal";
import { Assets } from "../../constants/images";
import {
  NewSparkleIcon,
  NoDataIcon,
  ToastCrossIcon,
  UserLogoPlus,
} from "../../constants/svg";
import {
  DeleteNewHireCheckList,
  GetNewHireCheckList,
} from "../../services/team/newHireChecklist";
import {
  setActionButtonDisable,
  setIsPaginate,
  setLoading,
  setNewHireCheckList,
  setNewHireCheckListAddEditForm,
  setPage,
  setPerPage,
  setReRenderTable,
  setSelectedData,
} from "../../store/slices/newHireChecklistSlice";
import { tableLoader, toastMessage } from "../../utils/helper";
import moment from "moment";
import { SHARE_URL, tableHeight } from "../../utils/constants";
import LazyLoadTable from "../../components/Table/LazyLoadTable";

const NewHireChecklist = () => {
  const dispatch = useDispatch();
  // const [selectedData, setSelectedData] = useState(null)
  const {
    loading,
    newHireCheckList,
    perPage,
    page,
    isPaginate,
    actionButtonDisable,
    newHireCheckListAddEditForm,
    reRenderTable,
    sorting,
    selectedData,
  } = useSelector((state) => state.newHireCheckList);
  const { currentSalonId } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [dataLoad, setdataLoad] = useState(false);
  const handleSetSelectedData = (record) => {
    dispatch(
      setSelectedData({
        id: record?.id,
        image: record?.image,
        name: record?.name,
        description: record?.description,
        start_date: record
          ? moment(record.created_at).format("MMM DD, yyyy")
          : "",
        start_time: record ? moment(record.created_at).format("hh:mm a") : "",
      }),
    );
  };
  const [newHiringCheckListIDForDelete, setNewHiringCheckListIDForDelete] =
    useState("");

  const onInitGetNewHireCheckListData = async () => {
    try {
      if (newHireCheckList?.data?.length == 0) {
        dispatch(setLoading(true));
      }
      const response = await GetNewHireCheckList({
        // page,
        // is_paginated: isPaginate,
        // "per-page": perPage,
        "order-column": "created_at",
        order: sorting.sortBy,
        salon_id: currentSalonId,
      });
      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      setdataLoad(data.data.length == 0);

      dispatch(setNewHireCheckList(data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(setIsPaginate(0));
      dispatch(setPage(0));
      dispatch(setPerPage(0));
    } else {
      dispatch(setPage(page));
      dispatch(setPerPage(perPage));
    }
  };

  const onDeleteNewHiringCheckList = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const resp = await DeleteNewHireCheckList(newHiringCheckListIDForDelete);
      const { data, message, status } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setNewHiringCheckListIDForDelete("");
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handleOpenEditForm = (record) => {
    dispatch(setSelectedData(record));
    dispatch(
      setNewHireCheckListAddEditForm({
        show: true,
        formType: "update",
      }),
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await onInitGetNewHireCheckListData();
    };

    fetchData();
  }, [page, perPage, sorting, reRenderTable, currentSalonId]);

  // To handle the fast tab change
  useEffect(() => {
    dispatch(setNewHireCheckList([]));
    dispatch(setLoading(true));
  }, []);

  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">New Hire Checklist</h2>
        <button
          className="btn-gradient"
          onClick={() => {
            dispatch(
              setNewHireCheckListAddEditForm({
                show: true,
                formType: "create",
              }),
            );
          }}
        >
          <span>
            <UserLogoPlus />
          </span>
          Add Task
        </button>
      </div>
      <div className="teamTabSubHead teamHeading">
        <p>
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> Onboarding tasks will automatically
          appear as a checklist for all new hires. &nbsp;
          <a
            href={
              "https://support.mysalonsymphony.com/hc/en-us/articles/18420976141595-New-Hire-Checklist"
            }
            target="_blank"
            className={"uLine globalBlueLinkColor"}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </p>
      </div>
      <div className="teamBody content">
        <ConfigProvider
          renderEmpty={() => (
            <>
              {dataLoad ? (
                <div className="noSearchData">
                  <NoDataIcon />
                  <h5>Onboarding tasks will appear here.</h5>
                  <h6>Click “Add Task” to get started.</h6>
                </div>
              ) : (
                <>
                  <div className="loader-table mt-5 pt-5">
                    <Image
                      width={100}
                      height={100}
                      src={Assets.loadingGif}
                      preview={false}
                    />
                  </div>
                </>
              )}
            </>
          )}
        >
          <Table
            columns={getNewHireCheckListTableColumns(
              setShowModal,
              handleOpenEditForm,
              setNewHiringCheckListIDForDelete,
              handleSetSelectedData,
            )}
            dataSource={newHireCheckList?.data}
            loading={tableLoader(loading)}
            bordered={false}
            pagination={false}
            // pagination={{
            //   onChange: handlePaginationChange,
            //   pageSize: !isPaginate ? newHireCheckList.length : perPage,
            //   showSizeChanger: false,
            //   current: !isPaginate ? 1 : page,
            //   total: !isPaginate
            //     ? newHireCheckList.length
            //     : newHireCheckList?.meta?.total,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            // }}
            store={{ store: "newHireCheckList", param: "newHireCheckList" }}
            scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            rowKey={(record) => record.id}
          />
        </ConfigProvider>
        {/* Share Modal */}

        {showModal.shareModal && (
          <ShareModal
            show={showModal.shareModal}
            onHide={() => {
              setShowModal((prevState) => ({
                ...prevState,
                shareModal: false,
              }));
            }}
            setModalShow={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: true }))
            }
            modalTitle={"Share Onboarding Task"}
            shareLink={SHARE_URL.ONBOARDING + selectedData?.id}
            modalSubtitle={
              <>
                Click “Copy Link” below, then{" "}
                <NavLink
                  to="/inbox"
                  className={"uLine blue-accent-color"}
                  target="_blank"
                >
                  {" "}
                  Open Chat{" "}
                </NavLink>{" "}
                to easily share this goal with members.
              </>
            }
            size={"lg"}
            bodyComponent={{
              img: Assets.eventShareModal,
              title: "Advanced Balayage Master Class",
              para1:
                "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
              para2: "Sep 12, 2023 @ 9:00 AM EST",
            }}
            data={selectedData}
          />
        )}
        {/* Delete Modal */}
        {showModal.deleteModal && (
          <DeleteModal
            handleDelete={onDeleteNewHiringCheckList}
            show={showModal.deleteModal}
            disable={actionButtonDisable}
            onHide={() => {
              setNewHiringCheckListIDForDelete("");
              setShowModal({ ...showModal, deleteModal: false });
            }}
            heading="Delete Onboarding Task?"
            para={
              <>
                Are you sure you want to delete?
                <br />
                Important: This resource cannot be recovered.
              </>
            }
            notificationMessage="Onboarding task"
          />
        )}
        {newHireCheckListAddEditForm?.show && (
          <GenericModal
            show={newHireCheckListAddEditForm.show}
            onHide={() => {
              dispatch(
                setNewHireCheckListAddEditForm({
                  show: false,
                  formType: null,
                }),
              );
            }}
            setModalShow={(e) => {
              dispatch(
                setNewHireCheckListAddEditForm({
                  show: e,
                  formType: null,
                }),
              );
            }}
            modalTitle={
              newHireCheckListAddEditForm.formType == "update"
                ? "Edit Onboarding Task"
                : "Add Onboarding Task"
            }
            customModalHeader={"customOnboardingModalHeader"}
            size={"lg"}
            bodyComponent={
              <CreateNewHireModal
                customModalBody={"customOnboardingModalBody"}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default NewHireChecklist;
