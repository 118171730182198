import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { RedAlertIcon } from "../../constants/svg";
import { useDispatch } from "react-redux";
import { setBlockerModal } from "../../store/slices/globalSlice";

const LeavePageModal = (props) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    props.onHide();

    if (props?.blocker?.proceed) {
      props.blocker.proceed();
      dispatch(setBlockerModal(false));
    }

    if (props?.resetForm) {
      props?.resetForm();
    }
    if (props.navigateNext) {
      props.navigateNext();
    }
    if (props.setModalShow) {
      props.setModalShow(false);
    }
  };
  useEffect(() => {
    return () => {
      props?.setLeavePageModal?.(false);
    };
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-leave-page-modal"
        id="leaveModal"
      >
        <Modal.Body>
          <div className="leaveModalIcon">
            <RedAlertIcon />
          </div>
          <h3>Leave this page?</h3>
          <p>
            Are you sure you want to leave this page now? Any progress will be
            lost if you leave.
          </p>

          <div className="btn-div">
            <Button
              className="solid-grey-sm-btn-leave me-2"
              data-dismiss="modal"
              onClick={() => {
                props.onHide();
              }}
            >
              Keep Working
            </Button>
            <Button
              className="solid-red-sm-btn"
              data-dismiss="modal"
              onClick={handleClick}
            >
              Leave Page
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeavePageModal;
