import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ModalCloseIcon } from "../../constants/svg";
import { GetSalonByID, UpdateSalon } from "../../services/global/global";
import { setReRenderOrganizationDetailPage } from "../../store/slices/organizationSlice";
import { currentBookingList, primaryProductList } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";
import CustomDatePicker from "../FormFields/CustomDatePicker";
import CustomInput from "../FormFields/CustomInput";
import CustomSelect from "../FormFields/CustomSelect";

const EditOrganization = ({ size, show, onHide, setModalShow }) => {
  const primaryProduct = primaryProductList.map((product) => ({
    value: product,
    label: product,
  }));

  const currentBooking = currentBookingList.map((product) => ({
    value: product.replaceAll(" People", ""),
    label: product,
  }));

  const { reRenderOrganizationDetailPage } = useSelector(
    (state) => state.organization,
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  const { singleOrganizationDetail } = useSelector(
    (state) => state.organization,
  );

  const [disableActionBtn, setDisableActionBtn] = useState(false);

  const onInitGetSingleOrganizationDetail = async () => {
    try {
      const response = await GetSalonByID(singleOrganizationDetail.id);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }

      setValue("salon_name", data.name);
      setValue("date_founded", data.date_founded);
      setValue("product_line", data.product_line);
      setValue("booking_software", data.booking_software);
    } catch (e) {
    } finally {
    }
  };

  const onSubmit = async (inputFields) => {
    try {
      const payload = {
        name: inputFields.salon_name,
        date_founded: inputFields.date_founded,
        product_line: inputFields.product_line,
        booking_software: inputFields.booking_software,
      };
      setDisableActionBtn(true);
      const response = await UpdateSalon(singleOrganizationDetail.id, payload);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      setModalShow(false);
      dispatch(
        setReRenderOrganizationDetailPage(reRenderOrganizationDetailPage + 1),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableActionBtn(false);
    }
  };

  const disabledDate = (current) => {
    const currentDate = moment();
    return current && current > currentDate;
  };

  useEffect(() => {
    onInitGetSingleOrganizationDetail();
  }, []);

  return (
    <>
      <Modal
        show={show}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal invite-staff"
      >
        <Modal.Header style={{ paddingBottom: "30px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" text-center w-100 pd-left-40"
          >
            Edit Organization
          </Modal.Title>

          <div
            className="closeBtn"
            data-bs-dismiss="modal"
            onClick={() => {
              setModalShow(true);
              // props.setModalShow(false);
            }}
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <div className="modal-body">
            <Row>
              <Col md={12} className="mb-20">
                <Controller
                  control={control}
                  name="salon_name"
                  rules={{
                    required: "Bussiness name is required",
                  }}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      className={errors.salon_name ? "hasError" : ""}
                      label="Business Name"
                      placeholder="Business Name"
                      value={field?.value}
                      onChange={(e) => field?.onChange(e)}
                    />
                  )}
                />
              </Col>

              <Col md={12} className="mb-20">
                <Controller
                  control={control}
                  name="date_founded"
                  defaultValue={""}
                  rules={{
                    required: "Date founded is required",
                  }}
                  render={({ field }) => (
                    <CustomDatePicker
                      {...field}
                      className={errors.date_founded ? "hasError" : ""}
                      placeholder="Date Founded"
                      onChange={(date, dateString) => {
                        field?.onChange(
                          moment(dateString).format("YYYY-MM-DD"),
                        );
                      }}
                      value={
                        field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""
                      }
                      disabledDate={disabledDate}
                    />
                  )}
                />
              </Col>

              <Col md={12} className="mb-20">
                <Controller
                  control={control}
                  defaultValue=""
                  name="product_line"
                  rules={{
                    required: "Primary product line is required",
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="product_line"
                      label={field?.value == "" ? "Primary Product Line" : ""}
                    >
                      <CustomSelect
                        {...field}
                        className={
                          errors.product_line
                            ? "hasError businessDetailDropdown"
                            : "businessDetailDropdown"
                        }
                        placeholder={"Primary Product Line"}
                        label="Primary Product Line"
                        value={field?.value}
                        options={primaryProduct}
                        onChange={(e) => field?.onChange(e)}
                      />
                    </FloatingLabel>
                  )}
                />
              </Col>

              <Col md={12} className="mb-20">
                <Controller
                  defaultValue=""
                  control={control}
                  name="booking_software"
                  rules={{
                    required: "Current Booking Software is required",
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="booking_software"
                      label={
                        field?.value == ""
                          ? "How many people will use Salon Symphony?"
                          : ""
                      }
                    >
                      <CustomSelect
                        {...field}
                        className={
                          errors.booking_software
                            ? "hasError businessDetailDropdown"
                            : "businessDetailDropdown"
                        }
                        placeholder={"How many people will use Salon Symphony?"}
                        label="Current Booking Software"
                        value={field?.value}
                        options={currentBooking}
                        onChange={(e) => field?.onChange(e)}
                      />
                    </FloatingLabel>
                  )}
                />
              </Col>
              <Col md={12}>
                {disableActionBtn && (
                  <Button
                    type={"submit"}
                    className={"w-100 btn-submit"}
                    disabled={disableActionBtn}
                  >
                    <div className="load black" />
                  </Button>
                )}
                {!disableActionBtn && (
                  <Button
                    type={"submit"}
                    className={"w-100 btn-submit"}
                    disabled={!isValid}
                  >
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditOrganization;
