import { Button, Divider, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Assets } from "../../../constants/images";
import {
  GoToSearchIcon,
  NoDataIcon,
  PurpleGoalSvg,
  SearchDefaultIcon,
} from "../../../constants/svg";
import {
  roles as salonRoles,
  search_module_type,
} from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  getAlertsSearch,
  getDocumentsSearch,
  getEventsSearch,
  getFlashcardsSearch,
  getGoalsSearch,
  getMembersSearch,
  getQuizzesSearch,
  getRequestsSearch,
  getResourcesSearch,
  getSurveysSearch,
  getTasksSearch,
} from "../../../services/search/frontend-search";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  setAlertsCurrentPage,
  setDocumentsCurrentPage,
  setEmptySearches,
  setEventsCurrentPage,
  setFlashcardsCurrentPage,
  setGoalsCurrentPage,
  setIsSearchLoading,
  setMembersCurrentPage,
  setQuizzesCurrentPage,
  setRequestsCurrentPage,
  setResourcesCurrentPage,
  setSurveysCurrentPage,
  setTasksCurrentPage,
} from "../../../store/slices/searchSlice";

const RecentSearch = () => {
  const navigate = useNavigate();
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const {
    isSearchLoading,
    alerts,
    documents,
    events,
    flashcards,
    goals,
    members,
    quizzes,
    requests,
    resources,
    surveys,
    tasks,
  } = useSelector((state) => state?.search);
  const { currentSalonId } = useSelector((state) => state.global);
  const { TabPane } = Tabs;
  const { keyword } = useParams();
  const [tabs, setTabs] = useState([]);

  const returnUrl = (tabIndex, data) => {
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      switch (tabIndex) {
        case 0:
          return `/alerts/details/${data?.id}`;
        case 1:
          return `/events/details/${data?.id}`;
        case 2:
          return `/flashcards/details/${data?.id}`;
        case 3:
          return `/quizzes/details/${data?.id}`;
        case 4:
          return `/resources/details/${data?.id}`;
        case 5:
          return `/surveys/details/${data?.id}`;
      }
    } else {
      switch (tabIndex) {
        case 0:
          return `/alerts/details/${data?.id}`;
        case 1:
          return `/team/docs/details/${data?.id}`;
        case 2:
          return `/events/details/${data?.id}`;
        case 3:
          return `/flashcards/details/${data?.id}`;
        case 4:
          return `/goals/details/${data?.id}`;
        case 5:
          return `/team/members/${data?.slug ?? data?.id}`;
        case 6:
          return `/quizzes/details/${data?.id}`;
        case 7:
          return `/requests/details/${data?.id}`;
        case 8:
          return `/resources/details/${data?.id}`;
        case 9:
          return `/surveys/details/${data?.id}`;
        case 10:
          return `/tasks/details/${data?.id}`;
      }
    }
  };

  const returnData = (tabIndex, data, meta, currentPage, setCurrentPage) => {
    return (
      <>
        {data?.length <= 0 ? (
          <>
            {/* <span>No results in this category for your keyword.</span> */}
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Hmm...no search results in this category.</h5>
              <h6>Try a different category tab or search again.</h6>
            </div>
          </>
        ) : (
          data?.map((item, index) => {
            return (
              <Row key={index}>
                <div
                  style={{ marginBottom: "1rem" }}
                  className="alert-section-row"
                >
                  <div className="alert-section-inner-div align-items-center">
                    <div>
                      {tabIndex == 0 ? (
                        <SearchDefaultIcon />
                      ) : tabIndex == 1 ? (
                        <PurpleGoalSvg />
                      ) : (
                        <img
                          style={{
                            width: "43px",
                            height: "43px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={item?.image || Assets.GeneralPlaceholder}
                          onError={(e) =>
                            (e.target.src = Assets.GeneralPlaceholder)
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      className="alert-section-content"
                      onClick={() => navigate(`${returnUrl(tabIndex, item)}`)}
                    >
                      <p
                        style={{ cursor: "pointer" }}
                        className="alert-section-content-title"
                      >
                        {tabIndex == 0 && "Reminder:"} {item?.name}{" "}
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        className="alert-section-content-desc"
                      >
                        {item.desc}
                      </p>
                    </div>
                  </div>

                  <div className="pb-4">
                    <NavLink to={`${returnUrl(tabIndex, item)}`}>
                      <GoToSearchIcon />
                    </NavLink>
                  </div>
                </div>
                <Divider />
              </Row>
            );
          })
        )}

        <Button
          className={
            currentPage < meta?.last_page ? "mx-auto d-block" : "d-none"
          }
          onClick={() => dispatch(setCurrentPage())}
        >
          Load More
        </Button>
      </>
    );
  };

  function renderContent(tabIndex) {
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      switch (tabIndex) {
        case 0:
          return returnData(
            tabIndex,
            alerts?.data,
            alerts?.meta,
            alerts?.currentPage,
            setAlertsCurrentPage,
          );
        case 1:
          return returnData(
            tabIndex,
            events?.data,
            events?.meta,
            events?.currentPage,
            setEventsCurrentPage,
          );
        case 2:
          return returnData(
            tabIndex,
            flashcards?.data,
            flashcards?.meta,
            flashcards?.currentPage,
            setFlashcardsCurrentPage,
          );
        case 3:
          return returnData(
            tabIndex,
            quizzes?.data,
            quizzes?.meta,
            quizzes?.currentPage,
            setQuizzesCurrentPage,
          );
        case 4:
          return returnData(
            tabIndex,
            resources?.data,
            resources?.meta,
            resources?.currentPage,
            setResourcesCurrentPage,
          );
        case 5:
          return returnData(
            tabIndex,
            surveys?.data,
            surveys?.meta,
            surveys?.currentPage,
            setSurveysCurrentPage,
          );
      }
    } else {
      switch (tabIndex) {
        case 0:
          return returnData(
            tabIndex,
            alerts?.data,
            alerts?.meta,
            alerts?.currentPage,
            setAlertsCurrentPage,
          );
        case 1:
          return returnData(
            tabIndex,
            documents?.data,
            documents?.meta,
            documents?.currentPage,
            setDocumentsCurrentPage,
          );
        case 2:
          return returnData(
            tabIndex,
            events?.data,
            events?.meta,
            events?.currentPage,
            setEventsCurrentPage,
          );
        case 3:
          return returnData(
            tabIndex,
            flashcards?.data,
            flashcards?.meta,
            flashcards?.currentPage,
            setFlashcardsCurrentPage,
          );
        case 4:
          return returnData(
            tabIndex,
            goals?.data,
            goals?.meta,
            goals?.currentPage,
            setGoalsCurrentPage,
          );
        case 5:
          return returnData(
            tabIndex,
            members?.data,
            members?.meta,
            members?.currentPage,
            setMembersCurrentPage,
          );
        case 6:
          return returnData(
            tabIndex,
            quizzes?.data,
            quizzes?.meta,
            quizzes?.currentPage,
            setQuizzesCurrentPage,
          );
        case 7:
          return returnData(
            tabIndex,
            requests?.data,
            requests?.meta,
            requests?.currentPage,
            setRequestsCurrentPage,
          );
        case 8:
          return returnData(
            tabIndex,
            resources?.data,
            resources?.meta,
            resources?.currentPage,
            setResourcesCurrentPage,
          );
        case 9:
          return returnData(
            tabIndex,
            surveys?.data,
            surveys?.meta,
            surveys?.currentPage,
            setSurveysCurrentPage,
          );
        case 10:
          return returnData(
            tabIndex,
            tasks?.data,
            tasks?.meta,
            tasks?.currentPage,
            setTasksCurrentPage,
          );
      }
    }
  }

  const fetchAlertsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.alerts,
      page: alerts?.currentPage,
      "per-page": 10,
    };
    await getAlertsSearch(params, dispatch);
  };

  const fetchDocumentsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.documents,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getDocumentsSearch(params, dispatch);
  };

  const fetchEventsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.events,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getEventsSearch(params, dispatch);
  };

  const fetchFlashcardsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.flashcard,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getFlashcardsSearch(params, dispatch);
  };

  const fetchGoalsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.goals,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getGoalsSearch(params, dispatch);
  };

  const fetchMembersSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.members,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getMembersSearch(params, dispatch);
  };

  const fetchQuizzesSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.quizzes,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getQuizzesSearch(params, dispatch);
  };

  const fetchRequestsSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.requests,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getRequestsSearch(params, dispatch);
  };

  const fetchResourcesSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.resources,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getResourcesSearch(params, dispatch);
  };

  const fetchSurveysSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.surveys,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getSurveysSearch(params, dispatch);
  };

  const fetchTasksSearch = async () => {
    const params = {
      keyword,
      is_paginated: 1,
      salon_id: currentSalonId,
      module: search_module_type?.tasks,
      page: documents?.currentPage,
      "per-page": 10,
    };
    await getTasksSearch(params, dispatch);
    dispatch(setIsSearchLoading(false));
  };

  useEffect(() => {
    dispatch(setIsSearchLoading(true));
    dispatch(setEmptySearches());
  }, [keyword]);

  useEffect(() => {
    fetchAlertsSearch();
  }, [keyword, alerts?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchDocumentsSearch();
  }, [keyword, documents?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchEventsSearch();
  }, [keyword, events?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchFlashcardsSearch();
  }, [keyword, flashcards?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchGoalsSearch();
  }, [keyword, goals?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchMembersSearch();
  }, [keyword, members?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchQuizzesSearch();
  }, [keyword, quizzes?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchRequestsSearch();
  }, [keyword, requests?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchResourcesSearch();
  }, [keyword, resources?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchSurveysSearch();
  }, [keyword, surveys?.currentPage, currentSalonId]);

  useEffect(() => {
    fetchTasksSearch();
  }, [keyword, tasks?.currentPage, currentSalonId]);

  useEffect(() => {
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      setTabs([
        `Alerts (${alerts?.meta?.total || 0})`,
        `Events (${events?.meta?.total || 0})`,
        `Flashcards (${flashcards?.meta?.total || 0})`,
        `Quizzes (${quizzes?.meta?.total || 0})`,
        `Resources (${resources?.meta?.total || 0})`,
        `Surveys (${surveys?.meta?.total || 0})`,
      ]);
    } else {
      setTabs([
        `Alerts (${alerts?.meta?.total || 0})`,
        `Docs (${documents?.meta?.total || 0})`,
        `Events (${events?.meta?.total || 0})`,
        `Flashcards (${flashcards?.meta?.total || 0})`,
        `Goals (${goals?.meta?.total || 0})`,
        `Members (${members?.meta?.total || 0})`,
        `Quizzes (${quizzes?.meta?.total || 0})`,
        `Requests (${requests?.meta?.total || 0})`,
        `Resources (${resources?.meta?.total || 0})`,
        `Surveys (${surveys?.meta?.total || 0})`,
        `Tasks(${tasks?.meta?.total || 0})`,
      ]);
    }
  }, [
    keyword,
    alerts,
    documents,
    events,
    flashcards,
    goals,
    members,
    quizzes,
    requests,
    resources,
    surveys,
    tasks,
  ]);

  useEffect(() => {
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      setTabs([
        `Alerts (${alerts?.meta?.total || 0})`,
        `Events (${events?.meta?.total || 0})`,
        `Flashcards (${flashcards?.meta?.total || 0})`,
        `Quizzes (${quizzes?.meta?.total || 0})`,
        `Requests (${requests?.meta?.total || 0})`,
        `Resources (${resources?.meta?.total || 0})`,
        `Surveys (${surveys?.meta?.total || 0})`,
      ]);
    } else {
      setTabs([
        `Alerts (${alerts?.meta?.total || 0})`,
        `Docs (${documents?.meta?.total || 0})`,
        `Events (${events?.meta?.total || 0})`,
        `Flashcards (${flashcards?.meta?.total || 0})`,
        `Goals (${goals?.meta?.total || 0})`,
        `Members (${members?.meta?.total || 0})`,
        `Quizzes (${quizzes?.meta?.total || 0})`,
        `Requests (${requests?.meta?.total || 0})`,
        `Resources (${resources?.meta?.total || 0})`,
        `Surveys (${surveys?.meta?.total || 0})`,
        `Tasks(${tasks?.meta?.total || 0})`,
      ]);
    }
  }, []);

  return (
    <div>
      <div className="recent-search-title px-5">
        <p>Search</p>
      </div>
      <Row className="tabs-section-search px-5">
        <Tabs>
          {tabs.map((item, tabIndex) => (
            <TabPane tab={item} key={tabIndex}>
              {isSearchLoading ? (
                <div className="load black" />
              ) : (
                renderContent(tabIndex, item)
              )}
            </TabPane>
          ))}
        </Tabs>
      </Row>
    </div>
  );
};

export default RecentSearch;
