import { Select } from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
  HightScoreIcon,
  LowScoreIcon,
} from "../../../constants/svg";
import GenericModal from "../../Modals/GenericModal";
import QuizModal from "./QuizModal/QuizModal";
import {
  setFilters,
  setQuizAddEditForm,
} from "../../../store/slices/quizSlice";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { roles as salonRoles } from "../../../utils/constants";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";

const QuizHeader = (props) => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.survey);
  const { quizAddEditForm } = useSelector((state) => state.quiz);
  const { categories, allSalons, currentSalonId } = useSelector(
    (state) => state.global,
  );

  const handleChangeCategoryFilter = (e) => {
    dispatch(setFilters({ ...filters, category_id: e }));
  };

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Quizzes</h2>
          <div className="customTablist">
            <div className="customScoreItem">
              <div className="score">
                <div className="icon">
                  <HightScoreIcon />
                </div>
                High Score: 80 - 100%
              </div>
            </div>
            <div className="customScoreItem">
              <div className="score">
                <div className="icon">
                  <LowScoreIcon />
                </div>
                Low Score: 0 - 79%
              </div>
            </div>
          </div>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              value={currentSalonId || undefined}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue="All Categories"
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            onChange={handleChangeCategoryFilter}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={categories}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() => {
              dispatch(
                setQuizAddEditForm({
                  show: true,
                  formType: "create",
                }),
              );
            }}
          >
            <span>
              <CreateIcon />
            </span>
            Create Quiz
          </button>
        </div>
        <GenericModal
          show={quizAddEditForm.show}
          onHide={() => {
            dispatch(
              setQuizAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setQuizAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          modalTitle={
            quizAddEditForm.formType == "create"
              ? "Create New Quiz"
              : "Edit Quiz"
          }
          customModalHeader={"customNewQuizHeader"}
          size={"lg"}
          bodyComponent={<QuizModal customModalBody={"customNewQuizBody"} />}
        />
      </div>
    </>
  );
};

export default QuizHeader;
