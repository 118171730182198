import { ConfigProvider, Dropdown, Space, Table, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Assets } from "../../../constants/images";
import {
  BinocularsLight,
  EditIcon,
  MoreIcon,
  NoDataIcon,
  PurpleRepeatIcon,
  RecycleBin,
  ShareIcon,
} from "../../../constants/svg";

import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../../../Contexts/ContextProvider";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { deleteTask, getTasks } from "../../../services/tasks/frontend-tasks";
import { setIsTasksLoading, setTasks } from "../../../store/slices/tasksSlice";
import DeleteModal from "../../Modals/DeleteModal";
import GenericModal from "../../Modals/GenericModal";
import ShareModal from "../../Modals/ShareModal";
import TaskModalForms from "./Modals/TaskModalForms";
import { tableLoader } from "../../../utils/helper";
import { SHARE_URL, tableHeight, task_type } from "../../../utils/constants";
import LazyLoadTable from "../../Table/LazyLoadTable";
import { getShowUpcomingTasks } from "../../../utils/commons";

function TaskBody(props) {
  const { tasks, isTasksLoading } = useSelector((state) => state?.tasks);
  const { currentSalonId } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm();

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const authUser = GetAuthUserLocalStorage();
  const { id: userId } = authUser;

  const url = window.location.href + "/" + selectedData?.key;
  const [disable, setDisable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const [showGenericModal, setShowGenericModal] = useState(false);
  const { taskPopupTitle, setTaskPopupTitle } = useStateContext();

  function renderAttendeesColumn(attendees) {
    if (!attendees) {
      return null;
    }
    const attendeeNames = attendees.attendents || [];
    const attendeeTooltipContent = (
      <div className="attendeeNames">
        {attendeeNames.slice(0, 7).map((name, index) => (
          <React.Fragment key={index}>
            <span>{name}</span>
            {index < attendeeNames.slice(0, 7).length - 1}
          </React.Fragment>
        ))}
        {attendeeNames.length > 7 && (
          <>
            <strong>+{attendeeNames.length - 7} more</strong>
          </>
        )}
      </div>
    );

    return (
      <div className="">
        {attendees.count != 0 ? (
          <Tooltip
            title={attendeeTooltipContent}
            placement="bottom"
            overlayClassName="custom-tooltip"
            color="purple"
            // open={true}
          >
            <div className="seenBy dottedRoundedBox">
              <span>{attendees.count}</span>
            </div>
          </Tooltip>
        ) : (
          <div className="seenBy dottedRoundedBox">
            <span>{attendees.count}</span>
          </div>
        )}
      </div>
    );
  }

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink to={`/tasks/details/${record?.key}`} className={"black"}>
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                setOpen(true);
                setShowGenericModal(true);
                setTaskPopupTitle("Edit Task");
              }}
            >
              <EditIcon />
              Edit
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                handleSetSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              handleSetSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "taskName",
      key: "taskName",
      sorter: (a, b) => a.taskName.localeCompare(b.taskName),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink
          to={`/tasks/details/${record?.key}`}
          className={"black taskAnchor"}
        >
          {record.type > 10 && <PurpleRepeatIcon />}
          <span className={record.type > 10 && "custom-ml-05"}>
            {record.taskName}
          </span>
        </NavLink>
      ),
      width: "35%",
    },
    {
      title: "Due On",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => {
        const dateA = moment(a.dueDate);
        const dateB = moment(b.dueDate);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      // defaultSortOrder: "descend",
      render: (text, record) => <p>{record.dueDate}</p>,
      align: "left",
      width: "10%",
    },
    {
      title: <span className="taskHead bg-purple">TO DO</span>,
      dataIndex: "toDo",
      key: "toDo",
      render: (text, record) => renderAttendeesColumn(record.toDo),
      align: "center",
      width: "15%",
    },
    {
      title: <span className="taskHead bg-red">OVERDUE</span>,
      dataIndex: "overdue",
      key: "overdue",
      render: (text, record) => renderAttendeesColumn(record.overdue),
      align: "center",
      width: "15%",
    },
    {
      title: <span className="taskHead bg-green">DONE</span>,
      dataIndex: "done",
      key: "done",
      render: (text, record) => renderAttendeesColumn(record.done),
      align: "center",
      width: "15%",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleSetSelectedData = (record) => {
    setSelectedData({
      key: record?.key,
      type: record?.type,
      name: record?.taskName,
      description: record?.description,
      image: record?.taskName?.image,
      start_date: moment(record?.start_date).format("MMM DD, yyyy"),
      start_time: moment(record?.start_date).format("hh:mm a"),
    });
  };

  const fetchTasks = async () => {
    const params = {
      is_paginated: 0,
      salon_id: currentSalonId,
      type: props?.selectedCategory,
      // page: props?.page?.currentPage,
      platform: "web",
      // "per-page": props?.page?.perPage,
      all_time: true,
    };

    await getTasks(params, dispatch, setIsTasksLoading, setTasks);
  };

  const handleDelete = async () => {
    await deleteTask(selectedData, setDisable, showModal, setShowModal);
    fetchTasks();
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [
    props?.selectedCategory,
    // props?.page?.currentPage,
    // props?.page?.perPage,
    currentSalonId,
  ]);

  const filteredData = useMemo(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    if (props.activeTab === "tab1") {
      return getShowUpcomingTasks(tasks.data, userId);
    } else {
      return tasks.data.filter((task) =>
        moment(task.dueDate).isBefore(currentDate),
      );
    }
  }, [tasks, props.activeTab]);

  return (
    <>
      <div className="content">
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Tasks you've assigned will appear here.</h5>
              <h6>Click "Assign Task" to get started.</h6>
            </div>
          )}
        >
          <Table
            /* locale={{
                emptyText:(   <Empty
                    image={Assets.EmptyWhiteBg}
                    description={''}
                />),
            }} */
            store={{ store: "tasks", param: "tasks" }}
            columns={columns}
            loading={tableLoader(isTasksLoading)}
            dataSource={filteredData}
            bordered={false}
            pagination={false}
            // pagination={{
            //   onChange: (currPage) =>
            //     props?.setPage({ ...props?.page, currentPage: currPage }),
            //   pageSize: props?.page?.perPage,
            //   current: props?.page?.currentPage,
            //   total: tasks?.meta?.total || 0,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            //   showSizeChanger: false,
            // }}
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="taskTable"
          />
        </ConfigProvider>
      </div>

      {/* Share Modal */}
      <ShareModal
        data={selectedData}
        shareLink={SHARE_URL.TASKS + selectedData?.key}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Task"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then &nbsp;{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            &nbsp; to easily share this task with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />
      {/* Delete Modal */}
      <DeleteModal
        handleDelete={handleDelete}
        disable={disable}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Task?"
        para={
          <>
            Are you sure you want to delete
            <b>
              {" "}
              {selectedData?.type == task_type.one_time ? (
                "this one-time task"
              ) : (
                <b>this recurring task</b>
              )}
              ?
            </b>
            <br />
            Important: This task cannot be recovered.
          </>
        }
        notificationMessage="Task"
      />
      {/* Edit Drawer */}
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={taskPopupTitle}
        size={"lg"}
        bodyComponent={
          <FormProvider {...methods}>
            <TaskModalForms
              selectedData={selectedData}
              isedit={true}
              methods={methods}
              setShowGenericModal={setShowGenericModal}
              show={showGenericModal}
            />
          </FormProvider>
        }
      />
    </>
  );
}

export default TaskBody;
