import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastCrossIcon } from "../../../../constants/svg";
import { UpdateFileUploadStorageSizeAvailability } from "../../../../services/global/global";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import {
  CreateSurvey,
  GetSurveyByID,
  UpdateSurvey,
} from "../../../../services/survey/survey";
import {
  setCategoryListByModule,
  setCreateUpdateTitleModuleID,
} from "../../../../store/slices/globalSlice";
import {
  setActionButtonDisable,
  setReRenderSurveyBuilder,
  setReRenderSurveyDetailPage,
  setReRenderTable,
  setSelectedData,
  setSurveyAddEditForm,
} from "../../../../store/slices/surveySlice";
import {
  all_category_type,
  roles as salonRoles,
  search_module_type,
  segment_type,
  status as recordStatus,
} from "../../../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../../../utils/helper";
import FirstStep from "./StepsForm/FirstStep";
import SecondStep from "./StepsForm/SecondStep";
import ZeroStep from "../../Resource/ResourceModal/StepsForm/ZeroStep";

const SurveyModalForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [segment, setSegment] = useState(null);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const [firstStepDisabled, setFirstStepDisabled] = useState(true);
  const [secondStepDisabled, setSecondStepDisabled] = useState(true);

  const {
    reRenderTable,
    actionButtonDisable,
    surveyAddEditForm,
    selectedData,
    reRenderSurveyDetailPage,
    reRenderSurveyBuilder,
  } = useSelector((state) => state.survey);

  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );

  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  );

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    control,
    getValues,
    clearErrors,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      surveyName: "",
      anonymousResponse: "",
      category: "",
      description: "",
      uploadFromGallery: "",
      uploadFromFolder: "",
      localUpload: "",
      previewImage: "",
    },
  });

  const stepForm1 = watch(["surveyName", "category"]);
  const stepForm2 = watch([
    "localUpload",
    "uploadFromGallery",
    "uploadFromFolder",
    "previewImage",
  ]);

  useEffect(() => {
    const isValidForm1 = stepForm1.every((elem) => elem != "");
    setFirstStepDisabled(!isValidForm1);
  }, [stepForm1]);

  useEffect(() => {
    const isValid = stepForm2.some((elem) => elem != "");
    setSecondStepDisabled(!isValid);
  }, [stepForm2]);

  // Form Submit Function
  const onSubmit = async (inputFields) => {
    try {
      const payload = {
        name: inputFields.surveyName,
        status: recordStatus.active,
        category_id: inputFields.category,
        description: inputFields.description,
        salon_id: currentSalonId,
        user_id: GetAuthUserLocalStorage().id,
        anonymous_response: inputFields.anonymousResponse
          ? recordStatus.active
          : recordStatus.inactive,
      };

      dispatch(setActionButtonDisable(true));

      if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
        let salonData =
          selectedSalons.length > 0
            ? selectedSalons
            : salonSegments.map((item) => {
                return item.id;
              });
        payload.salons = salonData;
        payload.products = selectedProductLines;
        payload.positions = selectedPositions;
        payload.segment = segment;
        payload.softwares = softwares;
        payload.technologies = technologies;
      }
      let salonS3UploadResponse;

      if (inputFields.localUpload != "") {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/survey`;
        salonS3UploadResponse = await salonUploadToS3(
          inputFields.localUpload,
          folder,
        );
        if (salonS3UploadResponse.status) {
          payload.image = salonS3UploadResponse.data.location;
        } else {
          throw new Error(salonS3UploadResponse.message);
        }
      } else if (
        inputFields.uploadFromGallery ||
        inputFields.uploadFromFolder ||
        inputFields.previewImage
      ) {
        payload.image = inputFields.uploadFromGallery
          ? inputFields.uploadFromGallery
          : inputFields.uploadFromFolder
            ? inputFields.uploadFromFolder
            : inputFields.previewImage
              ? inputFields.previewImage
              : "";
      }

      let response;
      if (surveyAddEditForm.formType == "create") {
        response = await CreateSurvey(payload);
      } else if (surveyAddEditForm.formType == "update") {
        response = await UpdateSurvey(selectedData.id, payload);
      }
      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      if (
        salonS3UploadResponse &&
        GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin
      ) {
        await UpdateFileUploadStorageSizeAvailability(
          salonS3UploadResponse.data.size,
        );
      }

      dispatch(setSelectedData(null));

      dispatch(
        setSurveyAddEditForm({
          show: false,
          formType: null,
        }),
      );

      dispatch(setReRenderTable(reRenderTable + 1));
      dispatch(setReRenderSurveyBuilder(reRenderSurveyBuilder + 1));
      dispatch(setReRenderSurveyDetailPage(reRenderSurveyDetailPage + 1));
      // toastMessage('success', message)

      navigate("/surveysbuilder/" + data.id);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const getSurveyByID = async () => {
    try {
      const response = await GetSurveyByID(selectedData.id);
      const { data, message, status } = response.data;
      if (!status) {
        throw new Error(message);
      }
      setSegment(data?.segment || null);
      if (data?.segment == segment_type.CUSTOM) {
        let salons = [];
        let products = [];
        data?.products?.map((item) => {
          products.push(item.product);
        });
        data?.salons?.map((item) => {
          salons.push(item.id);
        });
        setSelectedSalons(salons);
        setSelectedProductLines(products);
      }
      setValue("surveyName", data.name);
      setValue("anonymousResponse", data.anonymous_response == 10);
      setValue("category", data.category_id);
      setValue("description", data.description);
      setValue("description", data.description);
      setValue("previewImage", data.image);
      setTechnologies(data.technologies.map(({ name }) => name) ?? []);
      setSoftwares(data.software.map(({ name }) => name) ?? []);
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
    }
  };

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.survey));

    if (surveyAddEditForm.formType == "update") {
      getSurveyByID();
    }
  }, [surveyAddEditForm]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.surveys));
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`${step == 0 ? "modal-body" : "modal-body tabs-modal"} ${props.customModalBody}`}
        >
          <div className={step == 0 ? "" : "d-none"}>
            <ZeroStep
              setZeroStepDisabled={setZeroStepDisabled}
              customClass="noBorders"
              selectedSalons={selectedSalons}
              setSelectedSalons={setSelectedSalons}
              setSelectedProductLines={setSelectedProductLines}
              selectedProductLines={selectedProductLines}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
              showPositions={false}
              segment={segment}
              setSegment={setSegment}
              softwares={softwares}
              setSoftwares={setSoftwares}
              technologies={technologies}
              setTechnologies={setTechnologies}
            />
          </div>
          <div className={step == 1 ? "" : "d-none"}>
            <FirstStep
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              // handleSelect={handleSelect}
              // firstStepDisabled={firstStepDisabled}
              // setFirstStepDisabled={setFirstStepDisabled}
            />
          </div>
          <div className={step == 2 ? "" : "d-none"}>
            <SecondStep
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              submitted={actionButtonDisable}
              // secondStepDisabled={secondStepDisabled}
              // setSecondStepDisabled={setSecondStepDisabled}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={nextStep}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className={"custom-width-332"}
                    onClick={nextStep}
                    disabled={firstStepDisabled}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={firstStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            ) : null}
            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  {!actionButtonDisable && (
                    <Button
                      type="submit"
                      className={"custom-width-332"}
                      disabled={secondStepDisabled}
                    >
                      {surveyAddEditForm.formType == "update"
                        ? "Continue"
                        : "Continue"}
                    </Button>
                  )}
                  {actionButtonDisable && (
                    <Button
                      disabled={true}
                      type="submit"
                      className={"custom-width-332"}
                    >
                      <div className="load black" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default SurveyModalForm;
