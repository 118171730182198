import { notification } from "antd";
import {
  setAddMember,
  setIsLocationsLoading,
  setIsSingleLocationLoading,
  setLocations,
  setSingleLocation,
} from "../../store/slices/locationsSlice";
import {
  AddUserToLocation,
  DeleteSingleLocation,
  GetLocations,
  GetSingleLocation,
  RemoveUserFromLocation,
  UpdateLocation,
} from "./locations";
import {
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { toastMessage } from "../../utils/helper";

export const getLocationsWithoutPagination = async (params, dispatch) => {
  try {
    const res = await GetLocations(params);
    dispatch(setLocations({ data: res?.data?.data, meta: null }));
    dispatch(setIsLocationsLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsLocationsLoading(false));
  }
};

export const getSingleLocation = async (id, setTempAddedMembers, dispatch) => {
  try {
    const res = await GetSingleLocation(id);
    dispatch(setSingleLocation(res.data.data));
    dispatch(setAddMember(res.data.data?.users));
    setTempAddedMembers(res.data.data?.users);
    dispatch(setIsSingleLocationLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsSingleLocationLoading(false));
  }
};

export const addMemberToLocation = async (
  location_id,
  setDisable,
  tempAddedMembers,
  setShowPopOver,
  onAction,
  setOnAction,
) => {
  try {
    setDisable(true);
    let users = tempAddedMembers?.map((item) => item?.id);
    const res = await AddUserToLocation(location_id, { users });
    setShowPopOver(false);
    setDisable(false);
    setOnAction(!onAction);
    notification.info({
      message: <span>{res.data.message}</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    setDisable(false);
  }
};

export const removeUserFromLocation = async (
  location_id,
  user_id,
  setDisable,
  onAction,
  setOnAction,
) => {
  try {
    setDisable(true);
    const res = await RemoveUserFromLocation(location_id, user_id);
    setDisable(false);
    setOnAction(!onAction);
    notification.info({
      message: <span>Successfully removed the member</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    setDisable(false);
  }
};

export const deleteLocation = async (
  id,
  setDisable,
  showModal,
  setShowModal,
) => {
  try {
    setDisable(true);
    const res = await DeleteSingleLocation(id);
    setShowModal({ ...showModal, deleteModal: false });
    toastMessage("delete", res?.data?.message);
    setDisable(false);
  } catch (e) {
    toastMessage("error", e.message);
    setDisable(false);
  }
};

export const updateLocation = async (id, data) => {
  try {
    const res = await UpdateLocation(id, data);
    toastMessage("update", res?.data?.message);
    return res;
  } catch (e) {
    toastMessage("error", e.message);
  }
};
