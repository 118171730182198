import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClearedActivities,
  getNewActivities,
  updateActivity,
} from "../../../services/activities/frontend-activities";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setClearedActivitiesPage,
  setIsClearedActivitiesPageLoading,
  setIsNewActivitiesPageLoading,
  setNewActivitiesPage,
} from "../../../store/slices/activitiesSlice";
import { activity_type } from "../../../utils/constants";
import Activities from "./Activities";

const ActivityBody = (props) => {
  const { newActivitiesPage, clearedActivitiesPage } = props;
  const authUser = GetAuthUserLocalStorage();
  const [data, setData] = useState([]);
  const [clearData, setClearedItem] = useState([]);
  const [removedItem, setRemovedItem] = useState([]);
  const [restoredItem, setRestoredItem] = useState([]);
  const dispatch = useDispatch();
  const {
    isNewActivitiesLoading,
    isClearedActivitiesLoading,
    newActivities,
    clearedActivities,
  } = useSelector((state) => state?.activities);
  const { currentSalonId } = useSelector((state) => state.global);

  // const pageUpdate = (_page) => {
  //     if (_page.currentPage <= newActivitiesPage?.currentPage) return;
  //     setNewActivitiesPage(_page)
  //     fetchNewOnLoadActivities(_page)
  // }
  const fetchNewActivities = async (params = {}) => {
    params.currentSalonId = currentSalonId;
    params.is_read = activity_type?.unread;
    await getNewActivities(
      params,
      dispatch,
      setIsNewActivitiesPageLoading,
      setNewActivitiesPage,
    );
  };

  const fetchClearedActivities = async (params = {}) => {
    params.is_read = activity_type?.read;
    params.currentSalonId = currentSalonId;
    await getClearedActivities(
      params,
      dispatch,
      setIsClearedActivitiesPageLoading,
      setClearedActivitiesPage,
    );
  };

  // const [newActivitiesPage, setNewActivitiesPage] = useState({ currentPage: 1, perPage: 10 })
  // const [clearedActivitiesPage, setClearedActivitiesPage] = useState({ currentPage: 1, perPage: 10 })
  // const authUser = GetAuthUserLocalStorage()
  // const dispatch = useDispatch()
  // const { isNewActivitiesLoading, isClearedActivitiesLoading, newActivities, clearedActivities } = useSelector(state => state?.activities)
  // const { currentSalonId } = useSelector(state => state.global)

  // const fetchNewActivities = async () => {
  //     const params = {
  //         is_paginated: 1,
  //         page: newActivitiesPage?.currentPage,
  //         "per-page": 10,
  //         salon_id: currentSalonId,
  //         is_read: activity_type?.unread
  //     }

  //     await getNewActivities(params, dispatch, setIsNewActivitiesLoading, setNewActivities)
  // }

  // const fetchClearedActivities = async () => {
  //     const params = {
  //         is_paginated: 1,
  //         page: clearedActivitiesPage?.currentPage,
  //         "per-page": 10,
  //         salon_id: currentSalonId,
  //         is_read: activity_type?.read
  //     }

  //     await getClearedActivities(params, dispatch, setIsClearedActivitiesLoading, setClearedActivities)
  // }

  // const onDelete = () => {
  //     fetchNewActivities()
  //     fetchNewActivities()
  // }

  const handleActivityClick = async (data, readType) => {
    const apiData = {
      name: data?.alertTitle?.name,
      is_read: readType,
      salon_id: currentSalonId,
    };
    const key = data?.key;
    if (key) {
      if (readType == 20) {
        setRemovedItem((prev) => [...prev].filter((id) => id != key));
        setRestoredItem((prev) => [...prev, key]);
      } else {
        setRestoredItem((prev) => [...prev].filter((id) => id != key));
        setRemovedItem((prev) => [...prev, key]);
      }
    }

    await updateActivity(data?.key, apiData);
    await fetchNewActivities({
      readType,
      page: 1,
      "per-page": 10,
      salon_id: currentSalonId,
    });
    await fetchClearedActivities({
      readType,
      page: 1,
      "per-page": 10,
      salon_id: currentSalonId,
    });
  };

  // useEffect(() => {
  //     fetchNewActivities()
  // }, [newActivitiesPage?.currentPage, newActivitiesPage?.perPage, currentSalonId])

  // useEffect(() => {
  //     fetchClearedActivities()
  // }, [clearedActivitiesPage?.currentPage, clearedActivitiesPage?.perPage, currentSalonId])
  return (
    <div className="content">
      <div
        className={
          props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Activities
          getData={fetchNewActivities}
          pages={newActivitiesPage}
          store={{ store: "activities", param: "newActivitiesPage" }}
          isLoading={isNewActivitiesLoading}
          readType={activity_type.read}
          activeTab={props.activeTab}
          handleActivityClick={handleActivityClick}
          filterKeys={removedItem}
        />
      </div>

      <div
        className={
          props.activeTab == "tab2" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Activities
          getData={fetchClearedActivities}
          store={{ store: "activities", param: "clearedActivitiesPage" }}
          pages={clearedActivitiesPage}
          isLoading={isClearedActivitiesLoading}
          readType={activity_type.unread}
          activeTab={props.activeTab}
          handleActivityClick={handleActivityClick}
          filterKeys={restoredItem}
        />
      </div>
    </div>
  );
};

export default ActivityBody;
