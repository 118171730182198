import { createSlice } from "@reduxjs/toolkit";

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    disableActionBtn: false,
    selectedData: null,
    renderTable: 1,
    groups: [],
    createUpdateGroupForm: {
      show: false,
      formType: null,
    },
    singleGroup: {
      data: [],
      isSingleGroupLoading: true,
      meta: null,
    },
    formData: {
      addedMembers: [],
    },
  },
  reducers: {
    setDisableActionBtn: (state, action) => {
      state.disableActionBtn = action.payload;
    },
    setCreateUpdateGroupForm: (state, action) => {
      state.createUpdateGroupForm = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setRenderTable: (state, action) => {
      state.renderTable = action.payload;
    },
    setIsSingleGroupLoading: (state, action) => {
      state.singleGroup.isSingleGroupLoading = action.payload;
    },
    setSingleGroup: (state, action) => {
      state.singleGroup.data = action.payload;
    },

    setAddMember: (state, action) => {
      state.formData.addedMembers = action.payload;
    },

    setRemoveMember: (state, action) => {
      let data = [...state.formData.addedMembers];
      const index = data.findIndex((obj) => obj.id == action.payload);
      data.splice(index, 1);
      state.formData.addedMembers = data;
    },
  },
});

export const {
  setDisableActionBtn,
  setCreateUpdateGroupForm,
  setSelectedData,
  setGroups,
  setRenderTable,
  setSingleGroup,
  setIsSingleGroupLoading,
  setAddMember,
  setRemoveMember,
} = groupSlice.actions;

export default groupSlice.reducer;
