import React from "react";
import { useRouteError } from "react-router";

const CustomErrorBoundary = ({ children }) => {
  let error = useRouteError();
  const handleRedirect = () => {
    // You can replace this URL with your main domain or home page URL
    window.location.href = window.location.origin;
  };
  console.error("error:", error);
  return (
    <div
      className="gotoHomepage"
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <h1>Oops! Something went wrong.</h1>
      <p>
        We apologize for the inconvenience. Our team has been notified of the
        issue.
      </p>
      <p>Please try again later, or contact support if the problem persists.</p>
      <button onClick={handleRedirect} className="btn-gradient">
        Goto Homepage
      </button>
    </div>
  );
};

export default CustomErrorBoundary;
