import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetFlashcards = (params) => api.get(apiUrl.flashcards, { params });
export const GetSingleFlashcard = (id) => api.get(`${apiUrl.flashcards}/${id}`);
export const DeleteSingleFlashcard = (id) =>
  api.delete(`${apiUrl.flashcards}/${id}`);
export const CreateFlashCard = (data) => api.post(`${apiUrl.flashcards}`, data);
export const UpdateFlashCard = (id, data) =>
  api.put(`${apiUrl.flashcards}/${id}`, data);
export const GetFlashcardQuestions = (id) =>
  api.get(`${apiUrl.flashcardBuilder}/${id}`);
export const CreateFlashcardQuestions = (payload) =>
  api.post(`${apiUrl.flashcardBuilder}`, payload);
