import { notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Common/Sidebar";
import TopBar from "../components/Common/TopBar";
import { ToastCrossIcon, ToastRedTrashIcon } from "../constants/svg";
import { GetCategories } from "../services/categories/categories";
import { GetAuthUserLocalStorage } from "../services/localStorage/localStorage";
import {
  GetCurrentSubscription,
  GetPackagesList,
} from "../services/subscription/subscription";
import {
  setAddRemoveMemberInSendChatPopup,
  setAllSalons,
  setCategories,
  setCurrentSalonID,
  setLocations,
  setPositions,
  setSendChatSelectedUsers,
  setShowHideChatModel,
  setSingleSalon,
} from "../store/slices/globalSlice";
import {
  setCanceledSubscriptionID,
  setCheckingSubscription,
  setCurrentSubscriptionDetail,
  setPackages,
  setSubscriptionStatus,
} from "../store/slices/subscriptionSlice";
import {
  gallery_type,
  roles as salonRoles,
  status as salonStatus,
  status,
  subscriptionStatus,
} from "../utils/constants";
import { GetLocations } from "./../services/locations/locations";
import { GetPositions } from "./../services/positions/positions";
import { GetAllSalons, GetSalonByID } from "../services/global/global";
import PackageListingModal from "../components/Modals/PackageListingModal";
import { toastMessage } from "../utils/helper";
import UserProfileModal from "../components/pages/Chat/Modal/UserProfileModal";
import UserListModal from "../components/pages/Chat/Modal/UserListModal";
import { GetMyProfile } from "../services/auth/auth";
import { setMeData } from "../store/slices/authSlice";
import { GetGalleries } from "../services/galleries/galleries";
import { setGalleryData } from "../store/slices/gallerysSlice";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { signInToFirebase } from "../services/chats/frontend-chats";

function MainLayout(props) {
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    checkingSubscription,
    currentSubscriptionDetail,
    packages,
    callCheckSubscriptionAPI,
  } = useSelector((state) => state.subscription);
  const { callGetMyProfileAPI } = useSelector((state) => state?.auth);

  const {
    selectedCategory,
    openChatModal,
    statUserHover,
    createUpdateModuleTitle,
    moduleCreateUpdateID,
    currentSalonId,
  } = useSelector((state) => state.global);
  const { callSalonByIDAPI } = useSelector((state) => state.dashboard);

  const [modalShowPricing, setModalShowPricing] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [activeStep, setActiveStep] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (authUser) {
      getAllPackages();
      dispatch(setCurrentSalonID(authUser?.salon?.id ?? null));
      getGalleries();
      signInToFirebase();
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/dashboard")) {
      if (authUser && !authUser?.show_tab_tour) {
        setActiveStep(0);
        document.body.classList.add("stepFocused");
      }
    } else setActiveStep(null);

    return () => {
      document.body.classList.remove("stepFocused");
    };
  }, []);

  useEffect(() => {
    if (authUser) {
      if (authUser.roles[0].id == salonRoles.super_admin) {
        dispatch(setCheckingSubscription(false));
        dispatch(setSubscriptionStatus(subscriptionStatus.paid));
        dispatch(setCurrentSubscriptionDetail({}));
      } else {
        getCurrentSubscription();
      }
    }
  }, [callCheckSubscriptionAPI]);

  useEffect(() => {
    if (currentSubscriptionDetail && authUser) {
      getAllCategories();
      getAllPositions();
      getAllLocations();
    }
  }, [currentSubscriptionDetail, selectedCategory, currentSalonId]);

  useEffect(() => {
    if (authUser) getMyProfile();
  }, [callGetMyProfileAPI]);

  useEffect(() => {
    if (authUser) {
      if (authUser?.salon?.id) getSalonByID();
      else getAllSalons();
    }
  }, [callSalonByIDAPI]);

  const getGalleries = async () => {
    try {
      const response = await GetGalleries({
        is_paginated: 0,
        status: salonStatus.active,
        salon_id: currentSalonId,
        type: gallery_type.image,
      });
      const { data, status, message } = response.data;
      dispatch(setGalleryData(data));
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllPackages = async () => {
    try {
      const response = await GetPackagesList({
        is_paginated: 0,
        team: authUser?.salon?.booking_software ?? "",
        "order-column": "total_members",
        order: "asc",
      });

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "deletedNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      dispatch(setPackages(data));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await GetCategories({
        is_paginated: 0,
        status: status.active,
        type: selectedCategory,
        salon_id: currentSalonId,
      });
      const { data } = response.data;
      const mappedCategories = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      dispatch(setCategories(mappedCategories));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
    }
  };

  const getAllPositions = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: status.active,
        salon_id: currentSalonId,
        // type: salonJobType.admin
      };
      const res = await GetPositions(params);
      const data = res?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      dispatch(setPositions(data));
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  const getAllLocations = async () => {
    try {
      const params = {
        is_paginated: 0,
        status: status.active,
        salon_id: currentSalonId,
      };
      const res = await GetLocations(params);
      const data = res?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      dispatch(setLocations(data));
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  const getCurrentSubscription = async () => {
    try {
      dispatch(setCheckingSubscription(true));
      const response = await GetCurrentSubscription(authUser?.salon?.id);
      const { data, message, status } = response.data;

      if (!data) {
        dispatch(setSubscriptionStatus(subscriptionStatus.none));
        setModalShowPricing(true);
        return;
      }

      if (data.status == subscriptionStatus.canceled) {
        dispatch(setCanceledSubscriptionID(data.billing_plan_id));
        dispatch(setSubscriptionStatus(subscriptionStatus.canceled));
        setModalShowPricing(true);
        return;
      }

      dispatch(setSubscriptionStatus(subscriptionStatus.paid));
      dispatch(setCurrentSubscriptionDetail(data));
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      dispatch(setCheckingSubscription(false));
    }
  };

  const getSalonByID = async () => {
    try {
      const res = await GetSalonByID(authUser?.salon?.id);
      const { data, status, message } = res.data;
      dispatch(setSingleSalon(data));
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  const getAllSalons = async () => {
    try {
      const res = await GetAllSalons({
        is_paginated: 0,
      });
      const { data, status, message } = res.data;
      dispatch(setAllSalons(data));
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  const getMyProfile = async () => {
    try {
      const res = await GetMyProfile();
      dispatch(setMeData(res?.data?.data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  if (!authUser) return <LoadingScreen />;

  const shouldAddClass = new RegExp("/surveys/|/quizzes/", "gmi").test(
    window.location.pathname,
  );
  const noScroll = new RegExp("/settings|/team/", "gmi").test(location.pathname)
    ? "no-scroll"
    : "";
  const bg = new RegExp("/dashboard", "gmi").test(location.pathname)
    ? "bg-gray4"
    : new RegExp(
          "/flashcard/flash-card-builder|/surveysbuilder/|/quizbuilder/",
          "gmi",
        ).test(location.pathname)
      ? "bg-gray5"
      : "";
  const className = `dashboardMainBody ${noScroll || bg}`;
  return (
    <>
      <div className="dashboardLayoutWrapper">
        <div className="dashboardSideBar">
          <Sidebar
            ref1={ref1}
            ref2={ref2}
            ref3={ref3}
            ref4={ref4}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
        <div className="dashboardMainWrapper">
          <div
            className={`dashboardMainHead ${shouldAddClass ? "custom-pr-zindex" : ""}`}
          >
            <TopBar
              ref5={ref5}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </div>

          <div className={className}>
            <Outlet />
          </div>
        </div>
      </div>
      <PackageListingModal
        handleHide={() => setModalShowPricing(false)}
        show={modalShowPricing}
      />
      <UserProfileModal
        show={openChatModal}
        onHide={() => {
          dispatch(setAddRemoveMemberInSendChatPopup(false));
          dispatch(setSendChatSelectedUsers([]));
          dispatch(setShowHideChatModel(false));
        }}
        modalTitle={"Direct Message"}
        size={"lg"}
        className="userListModal"
        bodyComponent={
          <UserListModal
            onCloseModal={() => {
              dispatch(setAddRemoveMemberInSendChatPopup(false));
              dispatch(setSendChatSelectedUsers([]));
              dispatch(setShowHideChatModel(false));
            }}
          />
        }
      />
    </>
  );
}

export default MainLayout;
