import { Avatar, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteOragnizationModal from "../../../components/Modals/DeleteOragnizationModal";
import { Assets } from "../../../constants/images";
import {
  EditProfileIcon,
  MessageLight,
  PurpleBackIcon,
  RecycleBin,
} from "../../../constants/svg";
import {
  addUserToFirebase,
  getFireBaseUserByID,
  isUserAlreadyChatWithEachOther,
} from "../../../services/chats/frontend-chats";
import { DeleteSalonByID, GetSalonByID } from "../../../services/global/global";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setOrganizationDetailPageLoading,
  setReRenderList,
  setShowHideOrganizationDeleteModal,
  setSingleOraganizationDetail,
} from "../../../store/slices/organizationSlice";
import { online_status } from "../../../utils/constants";
import { getRoleNameByID, toastMessage } from "../../../utils/helper";
import EditOrganization from "../../Modals/EditOrganization";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import {
  setMemberAddEditForm,
  setSelectedOrganization,
} from "../../../store/slices/memberSlice";
import AddSalonModal from "../../Modals/AddSalonModal";

const OrganizationDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    organizationDetailPageLoading,
    singleOrganizationDetail,
    reRenderOrganizationDetailPage,
    showHideOragnizationDeleteModa,
    reRenderList,
  } = useSelector((state) => state.organization);

  const { memberAddEditForm } = useSelector((state) => state.member);
  const { positions, currentSalonId, salon } = useSelector(
    (state) => state.global,
  );

  const [sendMessageToOwnerLoading, setSendMessageToOwnerLoading] =
    useState(false);
  const [showHideEditOrganizationModal, setShowHideEditOrganizationModal] =
    useState(false);
  const [disableActionDeleteBtn, setDisableActionDeleteBtn] = useState(false);

  const handleNavigateToChatWithOrganizationOwner = async (e) => {
    e.preventDefault();
    if (sendMessageToOwnerLoading) return;
    try {
      setSendMessageToOwnerLoading(true);
      const authUser = GetAuthUserLocalStorage();

      let isUserInFireBaseCurr = await getFireBaseUserByID(authUser.id);
      if (!isUserInFireBaseCurr) {
        await addUserToFirebase({
          id: authUser.id.toString(),
          email: authUser.email,
          image: authUser.image,
          name: authUser.name,
          online: online_status.offline,
          position: null,
        });
      }

      let isUserInFireBaseSelected = await getFireBaseUserByID(
        singleOrganizationDetail.user.id,
      );
      if (!isUserInFireBaseSelected) {
        await addUserToFirebase({
          id: singleOrganizationDetail.user.id.toString(),
          email: singleOrganizationDetail.user.email,
          image: singleOrganizationDetail.user.image,
          name: singleOrganizationDetail.user.name,
          online: online_status.offline,
          position: null,
        });
      }

      const thread = await isUserAlreadyChatWithEachOther(
        authUser.id,
        singleOrganizationDetail.user.id,
        dispatch,
      );
      navigate("/inbox");
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setSendMessageToOwnerLoading(false);
    }
  };

  const handleDeleteOraganization = async () => {
    try {
      setDisableActionDeleteBtn(true);
      const response = await DeleteSalonByID(singleOrganizationDetail.id);
      const { status, message, data } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setShowHideOrganizationDeleteModal(false));
      dispatch(setReRenderList(reRenderList + 1));
      toastMessage("success", "Organization Deleted Successfully");
      navigate("/organization");
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableActionDeleteBtn(false);
    }
  };

  const onInitGetSingleOrganizationDetail = async () => {
    try {
      dispatch(setOrganizationDetailPageLoading(true));
      const response = await GetSalonByID(id);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }

      dispatch(setSingleOraganizationDetail(data));
    } catch (e) {
    } finally {
      dispatch(setOrganizationDetailPageLoading(false));
    }
  };

  const getLocations = (locations) => {
    const locationString = locations.map((obj) => obj.name);
    if (locationString.length) return locationString.join(", ");
    return "-";
  };
  function getTimezoneFullname(timezone) {
    const timezoneList = {
      EST: "Eastern Time",
      CST: "Central Time",
      PST: "Pacific Time",
      MST: "Mountain Time",
    };
    return `${timezoneList[timezone]} (${timezone})` || timezone;
  }

  useEffect(() => {
    onInitGetSingleOrganizationDetail();
  }, [reRenderOrganizationDetailPage]);

  if (organizationDetailPageLoading || !singleOrganizationDetail) {
    return <LoadingScreen child={true} />;
  }
  let position_value = positions.filter((item) => {
    return item.value == singleOrganizationDetail?.user?.position_id;
  });

  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/organization">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Organizations</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  <NavLink
                    to="/inbox"
                    onClick={handleNavigateToChatWithOrganizationOwner}
                  >
                    <div className="icon-text-wrapper cursor-pointer blackLine">
                      {sendMessageToOwnerLoading && (
                        <div className="load black" />
                      )}
                      {!sendMessageToOwnerLoading && (
                        <>
                          <MessageLight />
                          <p className="uLine ">Message Owner</p>
                        </>
                      )}
                    </div>
                  </NavLink>

                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      dispatch(
                        setSelectedOrganization(singleOrganizationDetail),
                      );
                      dispatch(
                        setMemberAddEditForm({
                          show: true,
                          formType: "update",
                          isSalonCreate: 1,
                        }),
                      );
                      // setShowHideEditOrganizationModal(true);
                    }}
                  >
                    <EditProfileIcon />
                    <p className="uLine ">Edit Profile</p>
                  </div>
                  <div
                    className="icon-text-wrapper deleteLabel cursor-pointer"
                    onClick={() => {
                      dispatch(setShowHideOrganizationDeleteModal(true));
                    }}
                  >
                    <RecycleBin />
                    <p className="uLine redLine ">Delete Account</p>
                  </div>
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <h3 className="sec-heading">
                  {singleOrganizationDetail?.name}
                </h3>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Account Owner</h5>
                    <p>{singleOrganizationDetail?.user?.name}</p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Account Created</h5>
                    <p>
                      {moment(singleOrganizationDetail.created_at).format(
                        "MMM DD, yyyy",
                      )}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Date Founded</h5>
                    <p>
                      {singleOrganizationDetail?.date_founded != "0000-00-00"
                        ? moment(singleOrganizationDetail.date_founded).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Job Title</h5>
                    <p>
                      {position_value.length > 0
                        ? position_value?.[0]?.label
                        : "-"}
                    </p>
                  </div>

                  <div className="eventDetailItem organHead">
                    <h5 className="head">Product Line</h5>
                    <p>
                      {singleOrganizationDetail?.products
                        .map(({ product }) => product)
                        .join(", ") || "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Booking Software</h5>
                    <p>
                      {singleOrganizationDetail?.softwares
                        .map(({ name }) => name)
                        .join(", ") || "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Other Technology</h5>
                    <p>
                      {singleOrganizationDetail?.technologies
                        .map(({ name }) => name)
                        .join(", ") || "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Time Zone</h5>
                    <p>
                      {getTimezoneFullname(
                        singleOrganizationDetail?.user?.timezone,
                      )}
                    </p>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Location</h5>
                    <div>
                      <p>{getLocations(singleOrganizationDetail?.locations)}</p>
                    </div>
                  </div>
                  <div className="eventDetailItem organHead">
                    <h5 className="head">Email Address</h5>
                    <div>
                      <p>{singleOrganizationDetail?.user?.email || "-"}</p>
                    </div>
                  </div>

                  <div className="eventDetailItem organHead">
                    <h5 className="head">Phone Number</h5>
                    <div>
                      <p>{singleOrganizationDetail?.user?.phone || "-"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className={"p-0"}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Members</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>
                    Members Lists ({singleOrganizationDetail.users.length})
                  </h6>
                </div>
                <div className="response-body">
                  {singleOrganizationDetail.users.map((elem) => (
                    <NavLink to={`/team/members/${elem.id}`}>
                      <div className="avatar-item">
                        <Avatar
                          size={38}
                          icon={
                            <img
                              src={elem.image || Assets.EventImg1}
                              alt={"avatar img"}
                            />
                          }
                        />
                        <p>{elem.name}</p>
                        <p className="purple-color">
                          ({getRoleNameByID(elem?.roles?.[0]?.id)})
                        </p>
                      </div>
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
        {/* Edit Drawer */}
        {/* <Drawer
          title="Edit Organization"
          placement="right"
          className="custom-drawer editDrawer"
          width={471}
          onClose={onClose}
          open={open}
          closable={false}
          extra={
            <Space>
              <div className="cursor-pointer" onClick={onClose}>
                <DrawerCloseIcon />
              </div>
            </Space>
          }
        >
          <div style={{ marginTop: "31px" }}>
            <OrganizationEditForm setOpen={setOpen} />
          </div>
        </Drawer> */}
      </Row>
      {/* Delete Modal */}
      <DeleteOragnizationModal
        disable={disableActionDeleteBtn}
        handleDelete={handleDeleteOraganization}
        show={showHideOragnizationDeleteModa}
        onHide={() => {
          dispatch(setShowHideOrganizationDeleteModal(false));
        }}
        heading="Delete Organization?"
        message="Oraginzation has been deleted"
        notificationMessage="Organization"
        para={
          <>
            Are you sure you want to delete{" "}
            <b>{singleOrganizationDetail?.name}?</b>
          </>
        }
      />

      <EditOrganization
        show={showHideEditOrganizationModal}
        onHide={() => {
          setShowHideEditOrganizationModal(false);
        }}
        setModalShow={(e) => {
          setShowHideEditOrganizationModal(false);
        }}
        size={"lg"}
      />
      <AddSalonModal
        show={memberAddEditForm.show}
        onHide={() => {
          dispatch(
            setMemberAddEditForm({
              show: false,
              formType: null,
              isSalonCreate: 0,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setMemberAddEditForm({
              show: false,
              formType: null,
              isSalonCreate: 0,
            }),
          );
        }}
        size={"lg"}
      />
    </>
  );
};

export default OrganizationDetail;
