import React from "react";
import { NavLink } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";

const MessageCard = (props) => {
  return (
    <>
      <NavLink to={"/inbox"} className={"messageAnchor"}>
        <div
          className={props.clearedData ? "messageCard cleared" : "messageCard"}
        >
          <div className="notificationContent">
            <div className="notificationAvatar">
              <img src={props.notificationImg} alt="notification icon" />
            </div>
            <div className="mainContent">
              <div className="notificationText">{props.cardTitle}</div>
              <div className="notificationSubText">{props.subtitle}</div>
              <div className="notificationTime">
                <span>{props.time}</span>
              </div>
            </div>
          </div>

          <div className={props.count == false ? "hide" : "countDiv"}>
            {props.count}
          </div>
          {props.clearedData && (
            <div className={"clearedIcon"}>
              <IoCheckmarkCircle />
            </div>
          )}
        </div>
      </NavLink>
    </>
  );
};

export default MessageCard;
