import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  convertToUTC,
  getNextDateByDay,
  validURL,
} from "../../../utils/helper";
import {
  alert_type,
  link_types,
  repeat_alert_type,
  roles as salonRoles,
  search_module_type,
} from "../../../utils/constants";
import { ModalCloseIcon } from "../../../constants/svg";
import {
  createAlert,
  getAlerts,
  presetDataForEditAlert,
  updateAlert,
} from "../../../services/alerts/frontend-alerts";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setAlerts,
  setAlertsFormData,
  setIsAlertsLoading,
  setResetAlertsFormData,
} from "../../../store/slices/aletsSlice";
import { setCreateUpdateTitleModuleID } from "../../../store/slices/globalSlice";
import LeavePageModal from "../../Modals/LeavePageModal";
import RecurringAlertForm from "./Steps/RecurringAlertForm";
import ScheduleAlertForm from "./Steps/ScheduleAlertForm";
import Step2 from "./Steps/Step2";
import Step1 from "./Steps/Step1";
import ZeroStep from "../Resource/ResourceModal/StepsForm/ZeroStep";

const CreatePushAlertModal = (props) => {
  const [leavePagemodalShow, setLeavePageModalShow] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useFormContext();
  const [selectedAlertType, setSelectedAlertType] = useState(null);
  const [step, setStep] = useState(
    GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
  );
  const dispatch = useDispatch();
  const [
    date,
    time,
    repeat_type,
    day,
    title,
    message,
    users_id,
    category,
    content,
    link,
    type,
    checked,
    link_type,
    selectedCategory,
    recipient_type,
  ] = watch([
    "date",
    "time",
    "repeat_type",
    "day",
    "title",
    "message",
    "users_id",
    "category",
    "content",
    "link",
    "type",
    "checked",
    "link_type",
    "selectedCategory",
    "recipient_type",
  ]);
  const { formData } = useSelector((state) => state?.alerts);
  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const { currentSalonId, salonSegments } = useSelector(
    (state) => state.global,
  );
  const authUser = GetAuthUserLocalStorage();
  const [disable, setDisable] = useState(false);
  const [segment, setSegment] = useState(null);
  const [selectedSalons, setSelectedSalons] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [zeroStepDisabled, setZeroStepDisabled] = useState(true);
  const [softwares, setSoftwares] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const handleAlertTypeChange = (type) => {
    setSelectedAlertType(type);
    setValue("type", type);
  };

  const moveNext = () => {
    if (!Object.keys(errors).length > 0) {
      saveData();
      setStep(step + 1);
    }
  };
  const moveBack = () => {
    saveData();
    setStep(step - 1);
  };

  const saveData = () => {
    dispatch(
      setAlertsFormData({
        date,
        time,
        repeat_type,
        day,
        title,
        message,
        users_id,
        category,
        content,
        link,
        type,
        checked,
        link_type,
        selectedCategory,
        recipient_type,
      }),
    );
  };

  const resetForm = () => {
    dispatch(setResetAlertsFormData());
    setSelectedAlertType(null);
    setStep(
      GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin ? 0 : 1,
    );
    reset({
      date: null,
      time: null,
      repeat_type: null,
      day: null,
      title: "",
      message: "",
      users_id: [],
      category: null,
      content: null,
      link: "",
      link_type: null,
      checked: false,
      type: "",
      selectedCategory: "",
      recipient_type: "",
      softwares: [],
      technologies: [],
    });
    if (props?.setSelectedData) {
      props?.setSelectedData(null);
    }
  };

  const handleDisableContinue = () => {
    if (!selectedAlertType) {
      return true;
    } else if (selectedAlertType == alert_type?.immediate) {
      return false;
    } else if (selectedAlertType == alert_type?.scheduled) {
      if (!date || !time) {
        return true;
      } else {
        return false;
      }
    } else if (selectedAlertType == alert_type?.recurring) {
      if (repeat_type == repeat_alert_type?.annually) {
        if (!repeat_type || !date || !time) {
          return true;
        } else {
          return false;
        }
      } else {
        if (!repeat_type || !day || !time) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const returnUsers = (category) => {
    if (category == "everyone") {
      return { usersData: users_id };
    } else if (category == "group") {
      let groups = salonTeams?.data?.groups?.filter((item) =>
        users_id?.includes(item?.id?.toString()),
      );
      let users = [];
      let groupsData = [];

      for (let i = 0; i < groups?.length; i++) {
        groupsData.push(groups[i]?.id?.toString());
        for (let j = 0; j < groups[i]?.users?.length; j++) {
          users.push(groups[i].users[j]?.id?.toString());
        }
      }
      return { usersData: users, groupsData };
    } else if (category == "member") {
      return { usersData: users_id };
    }
  };

  const fetchAlerts = async () => {
    const params = {
      page: 1,
      "per-page": 10,
      is_paginated: 1,
      salon_id: authUser?.salon?.id,
    };

    await getAlerts(params, dispatch, setIsAlertsLoading, setAlerts);
  };

  const onCreateAlert = async (data) => {
    let todayDate = moment().format("YYYY-MM-DD");
    let datetime = new Date(`${todayDate} ${data?.time}`);
    let apiData = {
      softwares,
      technologies,
      name: data?.title,
      title: data?.title,
      type: formData?.type?.toString(),
      salon_id: currentSalonId,
      message: data?.message,
      link_type,
      recipient_type,
      salons: data?.salons || [],
      products: data?.products || [],
      positions: data?.positions || [],
      repeat_type: data?.repeat_type,
      date: data?.date,
      time: data?.time,
      is_last_month: data.is_last_month,
      segment: data?.segment,
      ...(link_type == link_types?.internal && {
        link_category: category,
        link_id: content,
      }),
      ...(link_type == link_types?.external && {
        link: data?.link,
      }),

      ...(!props?.isedit && {
        users_id: returnUsers(selectedCategory)?.usersData,
        ...(selectedCategory == "group" && {
          recipients: returnUsers(selectedCategory)?.groupsData,
        }),
      }),

      /*
            ...(type == alert_type?.scheduled && {
              time: data?.time,
              date: moment(data?.date).format("YYYY-MM-DD")
            }),
            ...(type == alert_type?.recurring && {
              time: data?.time,
              date: data?.date,
            }),
            ...(repeat_type == repeat_alert_type?.annually && {
                date: data?.date,
                time : data?.time,
              }),
      */
      ...(type == alert_type?.immediate && {
        time: moment().utc().format("HH:mm:ss"),
        date: moment().utc().format("YYYY-MM-DD"),
      }),
    };
    await createAlert(
      apiData,
      setDisable,
      props?.setModalShow,
      resetForm,
      fetchAlerts,
    );
  };

  const onUpdateAlert = async (data) => {
    let todayDate = moment().format("YYYY-MM-DD");
    let datetime = new Date(`${todayDate} ${data?.time}`);
    let apiData = {
      name: data?.title,
      title: data?.title,
      type: formData?.type?.toString(),
      salon_id: currentSalonId,
      message: data?.message,
      salons: data?.salons || [],
      products: data?.products || [],
      positions: data?.positions || [],
      segment: data?.segment,
      link_type,
      repeat_type: data?.repeat_type,
      date: data?.date,
      time: data?.time,
      is_last_month: data.is_last_month,
      ...(link_type == link_types?.internal && {
        link_category: category,
        link_id: content,
      }),

      ...(link_type == link_types?.external && {
        link: data?.link,
      }),

      /* ...(type == alert_type?.scheduled && {
        time: data?.time,
        date: moment(data?.date).format("YYYY-MM-DD")
      }),

      ...(type == alert_type?.recurring && {
        //time: data?.date ? moment(datetime).format("HH:mm:ss") : moment(datetime).utc().format("HH:mm:ss"),
        ...(repeat_type == repeat_alert_type?.annually ? { date: data?.date ? moment(data?.date).utc().format("YYYY-MM-DD") : undefined, } : { day: day }),
        repeat_type: formData?.repeat_type
      }), */

      ...(type == alert_type?.immediate &&
        {
          // time: moment().utc().format("HH:mm:ss"),
          // date: moment().utc().format("YYYY-MM-DD")
        }),
    };
    await updateAlert(
      apiData,
      props?.selectedData,
      setDisable,
      props?.setModalShow,
      resetForm,
      fetchAlerts,
    );
  };

  const fetchAndPresetSingleAlertData = async () => {
    await presetDataForEditAlert(
      props?.selectedData,
      setSelectedAlertType,
      dispatch,
      setSegment,
      setSelectedSalons,
      setSelectedProductLines,
      setSelectedPositions,
      setSoftwares,
      setTechnologies,
    );
  };
  const handleDisableSubmit = () => {
    let isValid = false;

    if (props.isedit) {
      isValid = watch(["title", "message"]).every((elem) => elem != "");
    } else {
      if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
        isValid = watch(["title", "message", "users_id"]).every(
          (elem) => elem != "",
        );
      } else {
        isValid = watch(["title", "message"]).every((elem) => elem != "");
      }
    }

    if (!isValid) {
      return true;
    }

    if (checked) {
      if (link_type == link_types.internal) {
        const isValid = watch(["category", "content"]).every(
          (elem) => elem != null,
        );
        if (!isValid) {
          return true;
        }
      } else if (link_type == link_types.external) {
        const isValid = watch("link");
        if (isValid == "") {
          return true;
        } else if (!validURL(isValid)) {
          return true;
        }
      } else if (link_type == link_types.no_link) {
        setValue("category", "");
        setValue("content", "");
        setValue("link", "");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }

    // if (props?.isedit) {
    //   if (disable || !isValid || isSubmitting) {
    //     return true
    //   }
    //   else {
    //     return false
    //   }
    // }
    // else {
    //   if (disable || !isValid || isSubmitting || getValues("users_id")?.length <= 0) {
    //     return true
    //   }
    //   else {
    //     return false
    //   }
    // }
  };
  const onSubmit = async (data) => {
    let convertedDateTime = null;
    if (data?.day == 31) {
      data.is_last_month = 1;
    } else {
      data.is_last_month = 0;
    }
    if (
      (props?.isedit || !data?.date) &&
      data?.time &&
      data?.type == alert_type?.recurring
    ) {
      convertedDateTime = getNextDateByDay(
        data.day,
        data.time,
        data?.repeat_type,
        data?.date,
      );
    }
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      let salonData =
        selectedSalons.length > 0
          ? selectedSalons
          : salonSegments.map((item) => {
              return item.id;
            });
      data.salons = salonData;
      data.products = selectedProductLines;
      data.positions = selectedPositions;
      data.segment = segment;
      data.softwares = softwares;
      data.technologies = technologies;
    }
    if (props?.isedit) {
      if ((data?.date && data?.time) || (data?.day && data?.time)) {
        const { utcDate, utcTime } = convertToUTC(
          data.date,
          data.time,
          data?.day,
        );
        data.date = convertedDateTime ? convertedDateTime.date : utcDate;
        data.time = convertedDateTime ? convertedDateTime.time : utcTime;
      }
      await onUpdateAlert(data);
    } else {
      /* if (getValues("users_id")) {

      } */
      if ((data?.date && data?.time) || (data?.day && data?.time)) {
        const { utcDate, utcTime } = convertToUTC(
          data.date,
          data.time,
          data?.day,
        );
        data.date = convertedDateTime ? convertedDateTime.date : utcDate;
        data.time = convertedDateTime ? convertedDateTime.time : utcTime;
      }
      await onCreateAlert(data);
    }
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  /* useEffect(() => {
    if (!props?.isedit){
      reset(formData)
    }
  }, []) */

  useEffect(() => {
    if (props?.isedit && props?.selectedData) {
      fetchAndPresetSingleAlertData();
    }
  }, [props?.isedit, props?.selectedData, props?.show]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.alerts));
  }, []);
  /*  useEffect(()=>{
      if (!props.isedit){
        setValue('link_type',link_types.internal)
      }
    },[props.isedit]) */
  useEffect(() => {
    if (!props?.isedit) {
      setSelectedSalons([]);
      setSelectedPositions([]);
      setSelectedProductLines([]);
    }
  }, [props?.show]);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-create-push-modal"
        style={leavePagemodalShow ? { opacity: "0.75" } : {}}
        onHide={() => {
          setLeavePageModalShow(true);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className={`${props.customModalHeader}`}>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className={`text-center w-100 custom-pl-pb ${props.customModalTitle}`}
            >
              {props?.isedit ? "Edit Push Alert" : "Create Push Alert"}
            </Modal.Title>

            <div
              onClick={() => setLeavePageModalShow(true)}
              className="closeBtn"
            >
              <ModalCloseIcon />
            </div>
          </Modal.Header>

          <Modal.Body
            className={
              step == 0
                ? "alertform tabs-modal noBorders zero-form"
                : "alertform"
            }
          >
            {step == 0 && (
              <ZeroStep
                setZeroStepDisabled={setZeroStepDisabled}
                customClass="noBorders"
                selectedSalons={selectedSalons}
                setSelectedSalons={setSelectedSalons}
                setSelectedProductLines={setSelectedProductLines}
                selectedProductLines={selectedProductLines}
                setSelectedPositions={setSelectedPositions}
                selectedPositions={selectedPositions}
                showPositions={true}
                segment={segment}
                setSegment={setSegment}
                softwares={softwares}
                setSoftwares={setSoftwares}
                technologies={technologies}
                setTechnologies={setTechnologies}
              />
            )}
            {step == 1 && (
              <div style={{ height: "408px" }}>
                <Step1
                  selectedAlertType={selectedAlertType}
                  handleAlertTypeChange={handleAlertTypeChange}
                />
                {selectedAlertType == alert_type?.scheduled ? (
                  <ScheduleAlertForm
                    isedit={props?.isedit}
                    selectedData={props?.selectedData}
                  />
                ) : selectedAlertType == alert_type?.recurring ? (
                  <RecurringAlertForm
                    selectedData={props?.selectedData}
                    isedit={props?.isedit}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}

            {step == 2 && (
              <div style={{ height: "408px" }}>
                <Step2 isedit={props?.isedit} />
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            {step == 0 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  className={"w-100"}
                  // className="custom-width-690"
                  onClick={moveNext}
                  disabled={zeroStepDisabled}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id ==
              salonRoles.super_admin ? (
              <Row className="w-100">
                <Col xs={6}>
                  <Button
                    type="button"
                    onClick={moveBack}
                    className="btn-gray w-100 btn btn-primary"
                  >
                    Back
                  </Button>
                </Col>

                <Col xs={6}>
                  <Button
                    disabled={handleDisableContinue()}
                    type="button"
                    onClick={moveNext}
                    className=" w-100 btn btn-primary"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            ) : null}
            {step == 1 &&
            GetAuthUserLocalStorage()?.roles[0]?.id !=
              salonRoles.super_admin ? (
              <Row className="w-100">
                <Col xs={12}>
                  <Button
                    disabled={handleDisableContinue()}
                    type="button"
                    onClick={moveNext}
                    className="modal-color-btn m-auto custom-width-690 btn btn-primary"
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            ) : null}
            {step == 2 && (
              <Row className="w-100">
                <Col xs={6}>
                  <Button
                    type="button"
                    onClick={moveBack}
                    className="btn-gray w-100 btn btn-primary"
                  >
                    Back
                  </Button>
                </Col>

                <Col xs={6}>
                  <Button
                    disabled={handleDisableSubmit()}
                    type="submit"
                    className=" w-100 btn btn-primary"
                  >
                    {disable ? (
                      <div className="load black" />
                    ) : props?.isedit ? (
                      "Update Alert"
                    ) : selectedAlertType == alert_type?.scheduled ||
                      selectedAlertType == alert_type?.recurring ? (
                      "Schedule Alert"
                    ) : (
                      "Send Alert"
                    )}
                  </Button>
                </Col>
              </Row>
            )}
          </Modal.Footer>
        </Form>
        <LeavePageModal
          show={leavePagemodalShow}
          onHide={() => setLeavePageModalShow(false)}
          setModalShow={props.setModalShow}
          resetForm={resetForm}
        />
      </Modal>
    </>
  );
};
export default CreatePushAlertModal;
