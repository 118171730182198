import React, { useCallback, useEffect, useState } from "react";
import ExpandableSearch from "../../components/Common/ExpandableSearch";
import GenericModal from "../../components/Modals/GenericModal";
import NewGroupChatModal from "../../components/Modals/NewGroupChatModal";
import ChatWindow from "../../components/pages/Chat/ChatWindow";
import UserList from "../../components/pages/Chat/UserList";
import { Assets } from "../../constants/images";
import { RoundedEditIcon, ToastCrossIcon } from "../../constants/svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllUsers,
  setIsAllUsersLoading,
  setSelectedUser,
} from "../../store/slices/chatsSlice";
import {
  getChatUserList,
  signInToFirebase,
  updateFirebaseUser,
} from "../../services/chats/frontend-chats";
import { GetAllUsers, GetMembers } from "../../services/team/member";
import { Image, notification } from "antd";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { roles as salonRoles } from "../../utils/constants";
import NewChat from "../../components/pages/Chat/NewChat";
import AvatarImage from "../../components/Common/AvatarImage";

const Chat = () => {
  const [newChat, setNewChat] = useState(false);
  const [newChatDefault, setNewChatDefault] = useState(true);
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);
  const { userListing, selectedUser } = useSelector((state) => state?.chats);
  const [searchText, setSearchText] = useState("");

  const handleItemClick = (user) => {
    dispatch(setSelectedUser(user));
  };

  // const handleItemClick = (user) => {
  //   setSelectedUser(user);
  //   setNewChat(false);
  //   setSelectedUserGroup(false);
  // };

  const handleNewGroupChatFunc = useCallback(() => {
    setShowGenericModal(false);
    setSelectedUserGroup([
      {
        id: 1,
        avatar: Assets.ProfileAvatarOne,
        username: "John Doe",
        isOnline: true,
        sendingTime: "1h ago",
        chatHistory: [
          {
            sender: "John Doe",
            content: "Hello, how are you?",
            time: "10:00 AM",
            date: "2023-05-19",
            timeSpan: "Yesterday",
          },
          {
            sender: "June Julino",
            content: "I'm good, thank you!",
            time: "10:02 AM",
            date: "2023-05-19",
            timeSpan: "",
          },
        ],
      },
    ]);
  }, []);

  const fetchChatUserList = async () => {
    if (me?.data?.id) {
      getChatUserList(dispatch, me?.data?.id);
    }
  };

  const fetchAllUsers = async () => {
    dispatch(setIsAllUsersLoading(true));
    let response;
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      response = await GetAllUsers({ is_paginated: 0 });
    } else {
      response = await GetMembers();
    }
    const { data, status, message } = response.data;
    if (!status) {
      notification.error({
        description: message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      return;
    }
    dispatch(setIsAllUsersLoading(false));
    const users =
      GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin
        ? data
        : data.users;
    dispatch(setAllUsers(users));
    // await getAllUsers(dispatch,null,me?.data?.salon_id)
  };

  const filteredUsers = userListing?.data?.filter((user) => {
    if (user?.is_group_chat) {
      return user?.name?.toLowerCase().includes(searchText);
    } else {
      return user?.receiver_profile?.[0]?.name
        ?.toLowerCase()
        .includes(searchText);
    }
  });

  useEffect(() => {
    signInToFirebase();
    fetchAllUsers();
    fetchChatUserList();
    updateFirebaseUser(me?.data);
  }, []);
  useEffect(() => {
    fetchChatUserList();
  }, [me]);
  return (
    <>
      {/* <CircularProgressBar selectedValue={1} /> */}
      <div className="chatGrid">
        <div className="userBox">
          <div className="inbox-wrapper h-100">
            <div className="innerContainer pe-0 inboxChatHeader">
              <div className="titleWrapper">
                <h2>Messages</h2>
                <div className="actionBoxWrapper d-flex align-items-center">
                  <ExpandableSearch
                    className="ps-3"
                    setSearchText={setSearchText}
                  />
                  <div
                    className="icon cursor-pointer"
                    onClick={() => {
                      setNewChat(!newChat);
                      if (!selectedUser) {
                        dispatch(setSelectedUser({}));
                      }
                    }}
                  >
                    <RoundedEditIcon />
                  </div>
                </div>
              </div>
            </div>

            {userListing?.isLoading ? (
              <div className="loading-icon-center">
                <Image
                  width={100}
                  height={100}
                  src={Assets.loadingGif}
                  preview={false}
                />
              </div>
            ) : (
              <UserList users={filteredUsers} onItemClick={handleItemClick} />
            )}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatWrapperBox">
            {selectedUser && (
              <ChatWindow newChat={newChat} setNewChat={setNewChat} />
            )}
            {!selectedUser && newChatDefault && (
              <div className="chatWrapperBox__newChat">
                <NewChat
                  key={"newChat"}
                  setNewChat={setNewChat}
                  setNewChatDefault={setNewChatDefault}
                />
              </div>
            )}

            {/* {newChat && <NewChat setNewChat={setNewChat} />} */}
          </div>
        </div>
      </div>
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={"Create New Group"}
        pro={"Pro Tip:"}
        modalDesc={
          <>
            <div style={{ padding: "0 38px", textAlign: "left" }}>
              <b>Pro Tip:</b> Name your group, add your members, then upload a
              custom photo that represents this group.
            </div>
          </>
        }
        size={"lg"}
        bodyComponent={
          <NewGroupChatModal
            setModalShow={setShowGenericModal}
            buttonTrue={true}
            handleNewGroupChatFunc={handleNewGroupChatFunc}
          />
        }
      />
    </>
  );
};

export default Chat;
