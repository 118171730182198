import { ConfigProvider, Image, notification, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import getCandidateReferralColumn from "../../TableColumns/candidateReferral";
import DeleteModal from "../../components/Modals/DeleteModal";
import ShareModal from "../../components/Modals/ShareModal";
import { Assets } from "../../constants/images";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  NewSparkleIcon,
  NoDataIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";
import {
  ApproveCandidateReferral,
  DeleteCandidateReferral,
  GetCandidateReferral,
} from "../../services/team/candidateReferrel";
import {
  setActionButtonDisable,
  setCandidateReferral,
  setFilters,
  setIsPaginate,
  setLoading,
  setPage,
  setPerPage,
  setReRenderTable,
} from "../../store/slices/candidateReferrelSlice";
import { tableLoader, toastMessage } from "../../utils/helper";
import { tableHeight } from "../../utils/constants";
import LazyLoadTable from "../../components/Table/LazyLoadTable";

const menuOption = [
  {
    value: 10,
    label: "All Pending",
  },
  {
    value: 20,
    label: "All Approved",
  },
];

const CandidateRefferals = (props) => {
  const dispatch = useDispatch();
  const {
    loading,
    candidateReferral,
    page,
    perPage,
    isPaginate,
    reRenderTable,
    actionButtonDisable,
    filters,
    sorting,
  } = useSelector((state) => state.candidateReferrel);
  const { currentSalonId } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const [dataLoad, setdataLoad] = useState(false);
  const [deleteIdForCandidateReferral, setDeleteIdForCandidateReferral] =
    useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmApproveModalState, setConfirmApproveModalState] = useState({
    isOpen: false,
    isDisable: false,
  });

  const onApproveCandidateReferral = async () => {
    try {
      setConfirmApproveModalState({
        ...confirmApproveModalState,
        isDisable: true,
      });
      const response = await ApproveCandidateReferral(selectedData.id);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      toastMessage("success", "Candidate Referral Approved Successfully!");
    } catch (e) {
      setConfirmApproveModalState({
        ...confirmApproveModalState,
        isDisable: false,
      });
      toastMessage("error", e.message);
    } finally {
      setSelectedData(null);
      setConfirmApproveModalState({ isDisable: false, isOpen: false });
    }
  };

  const handleSelectedData = (record) => {
    setSelectedData(record);
  };

  const handleSetApproveCandidateData = (record) => {
    setSelectedData(record);
    setConfirmApproveModalState({ ...confirmApproveModalState, isOpen: true });
  };

  const onInitGetCandidateReferrals = async () => {
    try {
      const payload = {
        page,
        is_paginated: isPaginate,
        "per-page": perPage,
        "order-column": "created_at",
        order: sorting.sortBy,
        salon_id: currentSalonId,
      };
      if (filters.is_approved) {
        payload.is_approved = filters.is_approved;
      }

      const response = await GetCandidateReferral(payload);
      const { data, status, message } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      setdataLoad(data.data.length == 0);
      dispatch(setCandidateReferral(data));
    } catch (err) {
      notification.error({
        description: err.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePaginationChange = (page, perPage, sorter) => {
    if (!perPage) {
      dispatch(setIsPaginate(0));
      dispatch(setPage(0));
      dispatch(setPerPage(0));
    } else {
      dispatch(setPage(page));
      dispatch(setPerPage(perPage));
    }
  };

  const onDeleteCandidateReferral = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const response = await DeleteCandidateReferral(
        deleteIdForCandidateReferral,
      );
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setDeleteIdForCandidateReferral(null);
      setShowModal({ ...showModal, deleteModal: false });
      // onInitGetCandidateReferrals();
      notification.success({
        message,
        placement: "bottomRight",
        // duration: 0,
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handleChangeCandidateReferrelFilter = (e) => {
    dispatch(
      setFilters({
        ...filters,
        is_approved: e,
      }),
    );
  };

  useEffect(() => {
    onInitGetCandidateReferrals();
  }, [page, perPage, reRenderTable, sorting, filters, currentSalonId]);

  // To handle the fast tab change
  useEffect(() => {
    dispatch(setCandidateReferral([]));
    dispatch(setLoading(true));
  }, []);

  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">Candidate Referrals</h2>
        <Select
          suffixIcon={<ArrowTriangleDown />}
          menuItemSelectedIcon={<CheckMarkIcon />}
          defaultValue={menuOption[0]}
          // open={true}
          onChange={handleChangeCandidateReferrelFilter}
          popupClassName="main-select pageDropdown header-dropdown"
          className="custom-select-box border"
          options={menuOption}
        ></Select>
      </div>
      <div className="teamTabSubHead teamHeading">
        <p>
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> Candidates referred to your
          organization by staff members will appear here. &nbsp;
          <a
            href={
              "https://support.mysalonsymphony.com/hc/en-us/articles/18420978071579-Candidate-Referrals"
            }
            target="_blank"
            className={"uLine globalBlueLinkColor"}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </p>
      </div>
      <div className="teamBody content">
        <ConfigProvider
          renderEmpty={() => (
            <>
              {dataLoad ? (
                <div className="noSearchData">
                  <NoDataIcon />
                  <h5>Candidates referred by staff will appear here.</h5>
                  <h6>Staff can refer candidates from the mobile app.</h6>
                </div>
              ) : (
                <>
                  <div className="loader-table mt-5 pt-5">
                    <Image
                      width={100}
                      height={100}
                      src={Assets.loadingGif}
                      preview={false}
                    />
                  </div>
                </>
              )}
            </>
          )}
        >
          <LazyLoadTable
            /* locale={{
              emptyText:(   <Empty
                  image={Assets.EmptyWhiteBg}
                  description={''}
              />),
            }} */
            columns={getCandidateReferralColumn(
              setShowModal,
              setDeleteIdForCandidateReferral,
              handleSetApproveCandidateData,
              handleSelectedData,
            )}
            store={{ store: "candidateReferrel", param: "candidateReferral" }}
            dataSource={candidateReferral.data}
            loading={tableLoader(loading)}
            bordered={false}
            pagination={{
              onChange: handlePaginationChange,
              pageSize: !isPaginate ? candidateReferral.length : perPage,
              showSizeChanger: false,
              current: !isPaginate ? 1 : page,
              total: !isPaginate
                ? candidateReferral.length
                : candidateReferral?.meta?.total,
              pageSizeOptions: [10, 20, 50, 100, "All"],
            }}
            scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            rowKey={(record) => record.key}
            // isUpdate={true}
          />
        </ConfigProvider>
        {/* Share Modal */}

        <ShareModal
          show={showModal.shareModal}
          onHide={() => {
            handleSelectedData(null);
            setShowModal((prevState) => ({ ...prevState, shareModal: false }));
          }}
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Referral"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this referral with members.
            </>
          }
          size={"lg"}
          shareLink={window.location.href + "/" + selectedData?.id}
          data={{
            image: selectedData?.image,
            name: selectedData?.name,
            description: selectedData?.description,
            start_date: selectedData
              ? moment(selectedData?.created_at).format("MMM DD, yyyy")
              : "",
            start_time: selectedData
              ? moment(selectedData?.created_at).format("hh:mm a")
              : "",
          }}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Advanced Balayage Master Class",
            para1:
              "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
            para2: "Sep 12, 2023 @ 9:00 AM EST",
          }}
        />

        {/* Delete Modal */}
        <DeleteModal
          handleDelete={onDeleteCandidateReferral}
          show={showModal.deleteModal}
          disable={actionButtonDisable}
          onHide={() => {
            setDeleteIdForCandidateReferral(null);
            setShowModal({ ...showModal, deleteModal: false });
          }}
          heading="Delete Referral?"
          para={
            <>
              Are you sure you want to delete
              <b> {selectedData?.name || " this candidate"} ?</b>
              <br />
              Important: This referral cannot be recovered.
            </>
          }
          notificationMessage="Referral"
        />

        <DeleteModal
          handleDelete={onApproveCandidateReferral}
          show={confirmApproveModalState.isOpen}
          disable={confirmApproveModalState.isDisable}
          onHide={() => {
            setSelectedData(null);
            setConfirmApproveModalState({ isOpen: false, isDisable: false });
          }}
          heading="Approve Candiate Referral?"
          deleteText="Approve"
          para={<>Are you sure you want to Approve this Referral</>}
          notificationMessage="Referral"
        />
      </div>
    </>
  );
};

export default CandidateRefferals;
