import React from "react";

const ChatStatusIcon = ({ user, isOnline }) => {
  const statusColor =
    (!user?.is_group_chat && user?.receiver_profile[0]?.online) || isOnline
      ? "green"
      : "grey";

  return (
    <>
      {!user?.is_group_chat && (
        <span
          style={{ color: statusColor }}
          className={`status ${statusColor}`}
        ></span>
      )}
    </>
  );
};

export default ChatStatusIcon;
