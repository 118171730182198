import { Dropdown, Select, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  EditIcon,
  HorizontalDots,
  ImgSvg,
  ModalCloseIcon,
  PlusICons,
  RecycleBin,
  SingleStar,
  SurveyIconFilled,
} from "../../constants/svg";
import { useDispatch, useSelector } from "react-redux";
import Dissatisfied from "../../assets/images/Emoji/Dissatisfied.png";
import Impressed from "../../assets/images/Emoji/Impressed.png";
import SuperSatisfied from "../../assets/images/Emoji/SuperSatisfied.png";
import Undecided from "../../assets/images/Emoji/Undecided.png";
import Unimpressed from "../../assets/images/Emoji/Unimpressed.png";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ImageUploaderModal from "../../components/Modals/ImageGalleryUpload/ImageUploaderModal";
import { roles as salonRoles, salonQuestionType } from "../../utils/constants";
import {
  CreateSurveyQuestions,
  DeleteSurvey,
  GetSurveyByID,
  GetSurveyQuestions,
} from "../../services/survey/survey";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { UpdateFileUploadStorageSizeAvailability } from "../../services/global/global";
import {
  setReRenderSurveyBuilder,
  setSelectedData,
  setSurveyAddEditForm,
} from "../../store/slices/surveySlice";
import SurveyModalForm from "../../components/pages/SurveysPage/SurveyModal/SurveyModalForm";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { Assets } from "../../constants/images";
import { setBlockerModal } from "../../store/slices/globalSlice";
import { GoPlusCircle } from "react-icons/go";
import { FiMinusCircle } from "react-icons/fi";
import { Image, Shimmer } from "react-shimmer";

const menuOption = [
  {
    value: salonQuestionType.DESCRIPTION,
    label: "Short Answer",
  },
  {
    value: salonQuestionType.MULTIPLE,
    label: "Multiple Choice",
  },
  {
    value: salonQuestionType.STAR,
    label: "Star Rating",
  },
  {
    value: salonQuestionType.EMOJIS,
    label: "Emoji Rating",
  },
  {
    value: salonQuestionType.CHECKBOXES,
    label: "Checkboxes",
  },
  {
    value: salonQuestionType.PICTURE,
    label: "Picture Choice",
  },
];

const props = {
  name: "file",
  multiple: false,
  accept: ".png,.jpg,.jpeg",
  beforeUpload: (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
    }
    return isImage;
  },
  showUploadList: {
    showDownloadIcon: false,
    showRemoveIcon: true,
    removeIcon: <ModalCloseIcon />,
  },
};

const SurveysBuilder = () => {
  const defaultShortQuestion = {
    type: salonQuestionType.DESCRIPTION,
    question: "",
  };

  const defaultMultipleChoiceQueston = {
    type: salonQuestionType.MULTIPLE,
    question: "",
    answers: [
      {
        answer: "",
      },
      {
        answer: "",
      },
      {
        answer: "",
      },
    ],
  };

  const defaultStartQuestion = {
    type: salonQuestionType.STAR,
    question: "",
  };

  const defaultEmojiQuestion = {
    type: salonQuestionType.EMOJIS,
    question: "",
  };

  const defaultCheckboxQuestion = {
    type: salonQuestionType.CHECKBOXES,
    question: "",
    answers: [{ answer: "" }, { answer: "" }, { answer: "" }],
  };

  const defaultImageQuestion = {
    type: salonQuestionType.PICTURE,
    question: "",
    answers: [
      {
        answer: "",
        image: null,
      },
      {
        answer: "",
        image: null,
      },
      {
        answer: "",
        image: null,
      },
    ],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [actionBtnDisable, setActionBtnDisable] = useState(false);
  const [surveyBuilderQuestions, setSurveyBuilderQuestions] = useState([]);
  const [disableSurveyDeleteBtn, setDisableSurveyDeleteBtn] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const { surveyAddEditForm, reRenderSurveyBuilder, selectedData } =
    useSelector((state) => state.survey);

  const handleAddOptionField = (shouldAdd) => {
    const updatedQuestionBuilder = surveyBuilderQuestions?.map(
      (current, index) => {
        if (index == count - 1) {
          if (shouldAdd) {
            return {
              ...current,
              answers: [
                ...current.answers,
                {
                  answer: "",
                },
              ],
            };
          } else {
            return {
              ...current,
              answers: current.answers?.slice(0, -1),
            };
          }
        }
        return current;
      },
    );
    setSurveyBuilderQuestions(updatedQuestionBuilder);
  };

  useEffect(() => {
    dispatch(setBlockerModal(true));

    return () => {
      dispatch(setBlockerModal(false));
    };
  }, []);

  const actionMenu = [
    {
      key: uuidv4(),
      label: (
        <>
          <div
            onClick={() => {
              dispatch(
                setSurveyAddEditForm({
                  show: true,
                  formType: "update",
                }),
              );
            }}
          >
            <EditIcon />
            Edit Details
          </div>
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div
          className={"deleteLabel"}
          onClick={() => {
            setShowModal({ ...showModal, deleteModal: true });
          }}
        >
          <RecycleBin />
          Delete Survey
        </div>
      ),
    },
  ];

  const handleClick = () => {
    if (handleValidate()) {
      setCount(count + 1);
      setSurveyBuilderQuestions([
        ...surveyBuilderQuestions,
        defaultShortQuestion,
      ]);
    }
  };

  const onDeleteSurvey = async () => {
    try {
      setDisableSurveyDeleteBtn(true);
      const resp = await DeleteSurvey(id);
      const { data, message, status } = resp.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("success", "Survey Deleted Successfully");
      navigate("/surveys");
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableSurveyDeleteBtn(false);
    }
  };

  const handleValidate = () => {
    let isValidate = true;

    for (let elem of surveyBuilderQuestions) {
      if (elem.question == "") {
        isValidate = false;
        break;
      }

      if (
        elem.type == salonQuestionType.MULTIPLE ||
        elem.type == salonQuestionType.CHECKBOXES
      ) {
        for (let i = 0; i < elem.answers.length; i++) {
          let row = elem.answers[i];
          if (i == 2 && row.answer?.length > 0) {
            continue;
          }
          if (row.answer == "") {
            isValidate = false;
            break;
          }
        }
      } else if (elem.type == salonQuestionType.PICTURE) {
        for (let row of elem.answers) {
          if (row.answer == "") {
            isValidate = false;
            break;
          }
          if (!row.image) {
            isValidate = false;
            break;
          }
        }
      }
    }

    if (surveyBuilderQuestions?.length == 0) {
      isValidate = false;
    }
    return isValidate;
  };

  const handleSubmit = async () => {
    const payload = {
      survey_id: parseInt(id),
      user_id: GetAuthUserLocalStorage().id,
      questions: surveyBuilderQuestions,
    };

    try {
      setActionBtnDisable(true);
      const response = await CreateSurveyQuestions(payload);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setBlockerModal(false));
      setTimeout(() => {
        navigate("/surveys");
      }, 0);
      dispatch(setReRenderSurveyBuilder(reRenderSurveyBuilder + 1));
      toastMessage("success", message);
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setActionBtnDisable(false);
    }
  };

  const deleteQuestion = (i) => {
    setCount(count - 1);
    setSurveyBuilderQuestions(
      surveyBuilderQuestions.filter((elem, ind) => ind != i),
    );
  };

  const changeQuestionType = (i, v) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        let newElem;
        switch (v) {
          case salonQuestionType.CHECKBOXES:
            newElem = { ...defaultCheckboxQuestion, question: elem.question };
            break;

          case salonQuestionType.DESCRIPTION:
            newElem = { ...defaultShortQuestion, question: elem.question };
            break;

          case salonQuestionType.EMOJIS:
            newElem = { ...defaultEmojiQuestion, question: elem.question };
            break;

          case salonQuestionType.MULTIPLE:
            newElem = {
              ...defaultMultipleChoiceQueston,
              question: elem.question,
            };
            break;

          case salonQuestionType.PICTURE:
            newElem = { ...defaultImageQuestion, question: elem.question };
            break;

          case salonQuestionType.STAR:
            newElem = { ...defaultStartQuestion, question: elem.question };
            break;
        }
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeShortQuestion = (i, v) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        newElem.question = v;
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeMultipleChoiceQuestion = (i, type, data) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        }
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeCheckBoxQuestion = (i, type, data) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        }
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeMultipleImageQuestion = async (i, type, data) => {
    let image = null;
    if (type == "image" && data.value) {
      image = await uploadQuestionImageOnS3(data.value);
    }

    const newChangeQuestionType = [...surveyBuilderQuestions];
    newChangeQuestionType.forEach((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        if (type == "question") {
          newElem.question = data;
        } else if (type == "answer") {
          newElem.answers[data.answerIndex].answer = data.value;
        } else if (type == "image") {
          newElem.answers[data.imgIndex].image = image;
        }
        newChangeQuestionType[index] = newElem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeStarQuestion = (i, v) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        newElem.question = v;
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const onChangeImojiQuestion = (i, v) => {
    const newChangeQuestionType = surveyBuilderQuestions.map((elem, index) => {
      if (index == i) {
        const newElem = { ...elem };
        newElem.question = v;
        return newElem;
      } else {
        return elem;
      }
    });
    setSurveyBuilderQuestions(newChangeQuestionType);
  };

  const uploadQuestionImageOnS3 = async (file) => {
    try {
      let folder = `${
        GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
      }/surveyBuilder`;
      let salonS3UploadResponse = await salonUploadToS3(file, folder);

      if (salonS3UploadResponse.status) {
        if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
          await UpdateFileUploadStorageSizeAvailability(
            salonS3UploadResponse.data.size,
          );
        }
        return salonS3UploadResponse.data.location;
      } else {
        throw new Error(salonS3UploadResponse.message);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
    }
  };

  const onInitGetSurveyQuestion = async () => {
    try {
      setLoading(true);
      const response = await GetSurveyQuestions(id);
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", status);
        return;
      }
      if (data.length) {
        const surveyQuesion = data.map((elem, ind) => {
          if (elem.type == salonQuestionType.CHECKBOXES) {
            return {
              type: salonQuestionType.CHECKBOXES,
              question: elem.question,
              answers: [
                { answer: elem.surveyQuestionOptions[0].answer },
                { answer: elem.surveyQuestionOptions[1].answer },
                { answer: elem.surveyQuestionOptions[2].answer },
              ],
            };
          } else if (elem.type == salonQuestionType.DESCRIPTION) {
            return {
              type: salonQuestionType.DESCRIPTION,
              question: elem.question,
            };
          } else if (elem.type == salonQuestionType.EMOJIS) {
            return {
              type: salonQuestionType.EMOJIS,
              question: elem.question,
            };
          } else if (elem.type == salonQuestionType.MULTIPLE) {
            return {
              type: salonQuestionType.MULTIPLE,
              question: elem.question,
              answers: [
                { answer: elem.surveyQuestionOptions[0].answer },
                { answer: elem.surveyQuestionOptions[1].answer },
                { answer: elem.surveyQuestionOptions[2].answer },
              ],
            };
          } else if (elem.type == salonQuestionType.PICTURE) {
            return {
              type: salonQuestionType.PICTURE,
              question: elem.question,
              answers: [
                {
                  answer: elem.surveyQuestionOptions[0].answer,
                  image: elem.surveyQuestionOptions[0].image,
                },
                {
                  answer: elem.surveyQuestionOptions[1].answer,
                  image: elem.surveyQuestionOptions[1].image,
                },
                {
                  answer: elem.surveyQuestionOptions[2].answer,
                  image: elem.surveyQuestionOptions[2].image,
                },
              ],
            };
          } else if (elem.type == salonQuestionType.STAR) {
            return {
              type: salonQuestionType.STAR,
              question: elem.question,
            };
          }
        });
        setCount(surveyQuesion.length);
        setSurveyBuilderQuestions(surveyQuesion);
      } else {
        setCount(1);
        setSurveyBuilderQuestions([defaultShortQuestion]);
      }
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onInitGetSurveyQuestion();
    getSingleSuveyByID();
  }, [reRenderSurveyBuilder]);

  const getSingleSuveyByID = async () => {
    const response = await GetSurveyByID(id);
    const { data, message, status } = response.data;
    if (!status) {
      toastMessage("error", message);
      return;
    }
    dispatch(setSelectedData(data));
  };

  if (loading) {
    return <LoadingScreen child={true} />;
  }

  return (
    <div className="innerContainer">
      <Row>
        <Col lg={3} style={{ paddingTop: "0px" }}>
          <div className="sidebar_Bg">
            <div className="surveysbuilder_image">
              <Image
                src={selectedData?.image || Assets.GeneralPlaceholder}
                fallback={<Shimmer width={800} height={600} />}
                alt="SurveysBuilder"
                className="img-fluid"
              />
            </div>
            <ul>
              <li>{selectedData?.category?.name || "-"}</li>
              <li>
                <Space size="middle">
                  <Dropdown
                    menu={{
                      items: actionMenu,
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                    overlayClassName={"defaultDropdown"}
                  >
                    <div className="icon cursor-pointer">
                      <HorizontalDots />
                    </div>
                  </Dropdown>
                </Space>
              </li>
            </ul>
            <div className="surveysbuilder_content">
              <h3>{selectedData?.name}</h3>
              <p>{selectedData?.description || "-"}</p>
            </div>

            {!handleValidate() && (
              <Button
                className="publish_survey"
                disabled={!handleValidate()}
                type="button"
              >
                Publish Survey
              </Button>
            )}

            {handleValidate() && (
              <Button
                className="publish_survey"
                disabled={actionBtnDisable}
                type="button"
                onClick={handleSubmit}
              >
                {actionBtnDisable && <div className="load black" />}
                {!actionBtnDisable && "Publish Survey"}
              </Button>
            )}
          </div>
        </Col>
        <Col lg={9}>
          <div className="headingbar_bg">
            <ul className="bar_count">
              <li>&nbsp;</li>
            </ul>
            <h2 className="sec-heading">Survey Builder</h2>
            <p>
              Add questions one by one, then click Publish Survey when finished.
            </p>
            <p className="question_count">
              Total Questions <span>({count})</span>
            </p>
          </div>
          <div className="add_question_box">
            {surveyBuilderQuestions.map((elem, index) => {
              if (elem.type == salonQuestionType.DESCRIPTION) {
                return (
                  <ShortQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeShortQuestion={onChangeShortQuestion}
                  />
                );
              }

              if (elem.type == salonQuestionType.PICTURE) {
                return (
                  <PictureChoiceQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeMultipleImageQuestion={
                      onChangeMultipleImageQuestion
                    }
                  />
                );
              }

              if (elem.type == salonQuestionType.MULTIPLE) {
                return (
                  <MultipleChoiceQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeMultipleChoiceQuestion={
                      onChangeMultipleChoiceQuestion
                    }
                    handleAddOptionField={handleAddOptionField}
                  />
                );
              }

              if (elem.type == salonQuestionType.EMOJIS) {
                return (
                  <ImojiRatingQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeImojiQuestion={onChangeImojiQuestion}
                  />
                );
              }

              if (elem.type == salonQuestionType.STAR) {
                return (
                  <StartRatingQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeStarQuestion={onChangeStarQuestion}
                  />
                );
              }

              if (elem.type == salonQuestionType.CHECKBOXES) {
                return (
                  <CheckBoxQuestion
                    menuOption={menuOption}
                    deleteQuestion={deleteQuestion}
                    index={index}
                    changeQuestionType={changeQuestionType}
                    surveyBuilderQuestions={surveyBuilderQuestions}
                    onChangeCheckBoxQuestion={onChangeCheckBoxQuestion}
                    handleAddOptionField={handleAddOptionField}
                  />
                );
              }
            })}

            <div
              className={`add_question ${!handleValidate() ? "disabled" : ""}`}
              onClick={() => handleClick()}
            >
              <PlusICons />
              Add Question
            </div>
          </div>
        </Col>
      </Row>
      {/* Delete Modal */}
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        disable={disableSurveyDeleteBtn}
        heading="Delete Survey?"
        handleDelete={onDeleteSurvey}
        para={
          <>
            Are you sure you want to delete this Survey?
            <br />
            <b>Important:</b> This question cannot be recovered.
          </>
        }
        notificationMessage="Survey"
      />

      <GenericModal
        show={surveyAddEditForm.show}
        onHide={() => {
          dispatch(
            setSurveyAddEditForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setSurveyAddEditForm({
              show: e,
              formType: null,
            }),
          );
        }}
        modalTitle={
          surveyAddEditForm.formType == "create"
            ? "Create New Survey"
            : "Edit Survey"
        }
        size={"lg"}
        bodyComponent={<SurveyModalForm />}
      />

      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={"Image Uploader"}
        size={"lg"}
        bodyComponent={
          <ImageUploaderModal setShowGenericModal={setShowGenericModal} />
        }
      />
    </div>
  );
};

const ShortQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  surveyBuilderQuestions,
  onChangeShortQuestion,
}) => {
  const { type, question } = surveyBuilderQuestions[index];
  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled /> {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              placeholder="In a few words, what would you say is..."
              minLength={100}
              maxLength={200}
              title={""}
              value={question}
              onChange={(e) => {
                onChangeShortQuestion(index, e.target.value);
              }}
            />
          </div>
        </div>
        <div className="extra_para">
          Let members give written feedback with an open text field.
        </div>
      </div>
    </>
  );
};

const MultipleChoiceQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  surveyBuilderQuestions,
  onChangeMultipleChoiceQuestion,
  handleAddOptionField,
}) => {
  const { question, type, answers } = surveyBuilderQuestions[index];

  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              placeholder="Overall, what do you consider to be..."
              minLength={100}
              maxLength={200}
              title={""}
              value={question}
              onChange={(e) => {
                onChangeMultipleChoiceQuestion(
                  index,
                  "question",
                  e.target.value,
                );
              }}
            />
          </div>
        </div>
        <div className="extra_para">
          Let members choose a single answer from a list of choices.
        </div>
        <div className="question_option">
          <div className="d-block w-100 question_item">
            {/* <span>Correct</span> */}
            <div className="inputWrapper">
              <Form.Control
                type="text"
                placeholder="Option A here..."
                minLength={100}
                maxLength={200}
                title={""}
                value={answers[0].answer}
                onChange={(e) => {
                  onChangeMultipleChoiceQuestion(index, "answer", {
                    value: e.target.value,
                    answerIndex: 0,
                  });
                }}
              />
            </div>
          </div>

          <div className="d-block w-100 question_item">
            {/* <span>Correct</span> */}
            <div className="inputWrapper">
              <Form.Control
                type="text"
                value={answers[1].answer}
                minLength={100}
                maxLength={200}
                title={""}
                placeholder="Option B here..."
                onChange={(e) => {
                  onChangeMultipleChoiceQuestion(index, "answer", {
                    value: e.target.value,
                    answerIndex: 1,
                  });
                }}
              />
              {answers?.length < 3 &&
                index + 1 == surveyBuilderQuestions?.length && (
                  <GoPlusCircle
                    className="add_remove_icon"
                    onClick={() => handleAddOptionField(true)}
                  />
                )}
            </div>
          </div>

          <div className="d-block w-100 question_item">
            {/* <span>Correct</span> */}
            {answers?.length > 2 && (
              <div className="inputWrapper">
                <Form.Control
                  type="text"
                  placeholder="Option C here..."
                  minLength={100}
                  maxLength={200}
                  title={""}
                  value={answers[2].answer}
                  onChange={(e) => {
                    onChangeMultipleChoiceQuestion(index, "answer", {
                      value: e.target.value,
                      answerIndex: 2,
                    });
                  }}
                />
                {index + 1 == surveyBuilderQuestions?.length && (
                  <FiMinusCircle
                    className="add_remove_icon"
                    onClick={() => handleAddOptionField(false)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const StartRatingQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  surveyBuilderQuestions,
  onChangeStarQuestion,
}) => {
  const { question, type } = surveyBuilderQuestions[index];
  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              minLength={100}
              maxLength={200}
              title={""}
              placeholder="5 stars being excellent, how would you rate..."
              onChange={(e) => {
                onChangeStarQuestion(index, e.target.value);
              }}
              value={question}
            />
          </div>
        </div>
        <div className="extra_para">
          Let members evaluate a statement on a visual scale of stars.
        </div>
        <div className="emoji-or-star-wrapper emojis">
          <div className="question_item">
            <label htmlFor="Star1">
              <input type="radio" name="same3" id="Star1" />
              <span>
                <SingleStar />
              </span>
            </label>

            <label htmlFor="Star2">
              <input type="radio" name="same3" id="Star2" />
              <span>
                <SingleStar />
              </span>
            </label>

            <label htmlFor="Star3">
              <input type="radio" name="same3" id="Star3" />
              <span>
                <SingleStar />
              </span>
            </label>

            <label htmlFor="Star4">
              <input type="radio" name="same3" id="Star4" />
              <span>
                <SingleStar />
              </span>
            </label>

            <label htmlFor="Star5">
              <input type="radio" name="same3" id="Star5" />
              <span>
                <SingleStar />
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const ImojiRatingQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  surveyBuilderQuestions,
  onChangeImojiQuestion,
}) => {
  const { question, type } = surveyBuilderQuestions[index];
  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              value={question}
              minLength={100}
              maxLength={200}
              title={""}
              placeholder="Which emoji describes how you feel about..."
              onChange={(e) => {
                onChangeImojiQuestion(index, e.target.value);
              }}
            />
          </div>
        </div>
        <div className="extra_para">
          Let members use emojis to express a level of satisfaction.
        </div>
        <div className="emoji-or-star-wrapper emojis">
          <div className="question_item">
            <label htmlFor="Dissatisfied">
              <input type="radio" name="same2" id="Dissatisfied" />
              <span>
                <img src={Dissatisfied} alt="img" className="img-fluid" />
              </span>
            </label>

            <label htmlFor="Unimpressed">
              <input type="radio" name="same2" id="Unimpressed" />
              <span>
                <img src={Unimpressed} alt="img" className="img-fluid" />
              </span>
            </label>

            <label htmlFor="Undecided">
              <input type="radio" name="same2" id="Undecided" />
              <span>
                <img src={Undecided} alt="img" className="img-fluid" />
              </span>
            </label>

            <label htmlFor="Impressed">
              <input type="radio" name="same2" id="Impressed" />
              <span>
                <img src={Impressed} alt="img" className="img-fluid" />
              </span>
            </label>

            <label htmlFor="SuperSatisfied">
              <input type="radio" name="same2" id="SuperSatisfied" />
              <span>
                <img src={SuperSatisfied} alt="img" className="img-fluid" />
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const CheckBoxQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  surveyBuilderQuestions,
  onChangeCheckBoxQuestion,
  handleAddOptionField,
}) => {
  const { type, question, answers } = surveyBuilderQuestions[index];
  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              minLength={100}
              maxLength={200}
              title={""}
              onChange={(e) => {
                onChangeCheckBoxQuestion(index, "question", e.target.value);
              }}
              value={question}
              placeholder="In what way can we improve our..."
            />
          </div>
        </div>
        <div className="extra_para">
          Let members select multiple answers from a list of options.
        </div>
        <div className="question_option checkbox">
          <div className="d-block w-100 question_item">
            <div className="inputWrapper">
              <Form.Control
                type="text"
                placeholder="Option A here..."
                minLength={100}
                maxLength={200}
                title={""}
                value={answers[0].answer}
                onChange={(e) => {
                  onChangeCheckBoxQuestion(index, "answer", {
                    value: e.target.value,
                    answerIndex: 0,
                  });
                }}
              />
            </div>
          </div>

          <div className="d-block w-100 question_item">
            <div className="inputWrapper">
              <Form.Control
                type="text"
                placeholder="Option B here..."
                value={answers[1].answer}
                minLength={100}
                maxLength={200}
                title={""}
                onChange={(e) => {
                  onChangeCheckBoxQuestion(index, "answer", {
                    value: e.target.value,
                    answerIndex: 1,
                  });
                }}
              />
              {answers?.length < 3 &&
                index + 1 == surveyBuilderQuestions?.length && (
                  <GoPlusCircle
                    className="add_remove_icon"
                    onClick={() => handleAddOptionField(true)}
                  />
                )}
            </div>
          </div>

          <div className="d-block w-100 question_item">
            {answers?.length > 2 && (
              <div className="inputWrapper">
                <Form.Control
                  type="text"
                  placeholder="Option C here..."
                  value={answers[2].answer}
                  minLength={100}
                  maxLength={200}
                  title={""}
                  onChange={(e) => {
                    onChangeCheckBoxQuestion(index, "answer", {
                      value: e.target.value,
                      answerIndex: 2,
                    });
                  }}
                />
                {index + 1 == surveyBuilderQuestions?.length && (
                  <FiMinusCircle
                    className="add_remove_icon"
                    onClick={() => handleAddOptionField(false)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const PictureChoiceQuestion = ({
  menuOption,
  deleteQuestion,
  index,
  changeQuestionType,
  onChangeMultipleImageQuestion,
  surveyBuilderQuestions,
}) => {
  const { type, question, answers } = surveyBuilderQuestions[index];

  return (
    <>
      <div className={`headingbar_bg bg_extra_space active`}>
        <div className="custom-pr">
          <div
            className="three-dots deleteRed"
            onClick={(e) => {
              deleteQuestion(index);
            }}
          >
            Delete
          </div>
        </div>
        <div className="serial_number">
          <SurveyIconFilled />
          {index + 1}
        </div>
        <div className="question_box">
          <div className="surver_builder_select">
            <Select
              value={type}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box"
              options={menuOption}
              onChange={(e) => {
                changeQuestionType(index, e);
              }}
            ></Select>
          </div>
          <div className="surver_builder_input">
            <Form.Control
              type="text"
              value={question}
              minLength={100}
              maxLength={200}
              title={""}
              onChange={(e) => {
                onChangeMultipleImageQuestion(
                  index,
                  "question",
                  e.target.value,
                );
              }}
              placeholder="Which of the following are you most..."
            />
          </div>
        </div>
        <div className="extra_para">
          Let members choose an image answer from a list of picture choices.
        </div>

        <div className="question_option">
          <Row>
            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[0].image == null ? [] : [answers[0].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 0,
                      });
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 0,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[0]?.image ? (
                      <img src={answers[0].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
                <div className="">
                  <Form.Control
                    type="text"
                    placeholder="Picture title A..."
                    value={answers[0].answer}
                    minLength={100}
                    maxLength={200}
                    title={""}
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "answer", {
                        value: e.target.value,
                        answerIndex: 0,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[1].image == null ? [] : [answers[1].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 1,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 1,
                      });
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[1]?.image ? (
                      <img src={answers[1].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
                <div className="">
                  <Form.Control
                    type="text"
                    placeholder="Picture title B..."
                    value={answers[1].answer}
                    minLength={100}
                    maxLength={200}
                    title={""}
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "answer", {
                        value: e.target.value,
                        answerIndex: 1,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col xs={4}>
              <div className="img-box">
                <div className="upper-box surveQuizMultiplImageQuestion">
                  <Upload
                    {...props}
                    showUploadList={false}
                    fileList={
                      answers[2].image == null ? [] : [answers[2].image]
                    }
                    onChange={(e) => {
                      const fileList = e.fileList;
                      const singleFile = !fileList.length
                        ? null
                        : fileList.length > 1
                          ? fileList[1]
                          : fileList[0];
                      onChangeMultipleImageQuestion(index, "image", {
                        value: singleFile,
                        imgIndex: 2,
                      });
                    }}
                    onRemove={() => {
                      onChangeMultipleImageQuestion(index, "image", {
                        value: null,
                        imgIndex: 2,
                      });
                    }}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    className="border-0 bg-0 qb-preview-image"
                    listType="picture"
                  >
                    {answers?.[2]?.image ? (
                      <img src={answers[2].image} />
                    ) : (
                      <ImgSvg />
                    )}
                  </Upload>
                </div>
                <div className="">
                  <Form.Control
                    type="text"
                    minLength={100}
                    maxLength={200}
                    title={""}
                    value={answers[2].answer}
                    placeholder="Picture title C..."
                    onChange={(e) => {
                      onChangeMultipleImageQuestion(index, "answer", {
                        value: e.target.value,
                        answerIndex: 2,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SurveysBuilder;
