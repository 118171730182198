import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckIcon, MagnifyShareIcon } from "../../constants/svg";
import { GetGalleries } from "../../services/galleries/galleries";
import { setSelectPreUploadedFile } from "../../store/slices/globalSlice";
import { gallery_type, status as salonStatus } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";
import { setGalleryData } from "../../store/slices/gallerysSlice";

const { Search } = Input;

function GalleryTab({
  incSearch,
  handleChange = "",
  folderResources = null,
  submitted = false,
}) {
  const dispatch = useDispatch();
  const { selectPreUploadedFile, currentSalonId } = useSelector(
    (state) => state.global,
  );
  const { galleryData } = useSelector((state) => state.gallerys);
  const [searchValue, setSearchValue] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [loadingGalleries, setLoadingGalleries] = useState(false);
  const [folderResourcesST, setFolderResourcesST] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCardClick = (element) => {
    if (submitted) {
      return false;
    }
    const selectFile = selectPreUploadedFile == element?.id ? "" : element?.id;
    if (typeof handleChange == "function") {
      if (element?.link == selectedMedia) {
        handleChange("");
      } else {
        handleChange(element?.link ? element?.link : "");
      }
    }
    setSelectedMedia(element?.link !== selectedMedia ? element?.link : null);
    dispatch(setSelectPreUploadedFile(selectFile));
  };

  const getGalleries = async () => {
    try {
      if (galleryData.length == 0) {
        setLoadingGalleries(true);
      }
      const response = await GetGalleries({
        is_paginated: 0,
        status: salonStatus.active,
        salon_id: currentSalonId,
        type: gallery_type.image,
      });
      const { data, status, message } = response.data;
      dispatch(setGalleryData(data));
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoadingGalleries(false);
    }
  };

  useEffect(() => {
    getGalleries();
  }, [currentSalonId]);

  useEffect(() => {
    setFolderResourcesST(folderResources);
  }, [folderResources]);

  let galleryListing;

  if (folderResourcesST) {
    galleryListing = folderResourcesST;
  } else if (galleryData) {
    galleryListing = galleryData;
  }

  if (searchValue) {
    if (searchValue.length > 0) {
      galleryListing = galleryListing.filter((elem) =>
        elem.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      galleryListing = galleryData;
    }
  }

  return (
    <div className="galleryWrapper">
      {incSearch && (
        <Search
          placeholder="Search high-resolution images by name..."
          onChange={handleSearch}
          enterButton={false}
          prefix={<MagnifyShareIcon />}
          allowClear={true}
          className="removeBtn eventSearchBar border-focus"
        />
      )}
      <div className="card-container">
        {loadingGalleries && (
          <div className="no-search-found">
            <p className="text-center">
              <b>Loading Galleries...</b>
            </p>
          </div>
        )}

        {!loadingGalleries && galleryListing.length > 0 && (
          <div className="galleryCardBox">
            {galleryListing.map((elem) => (
              <div
                key={elem.id}
                className={`galleryBoxImg responses ${
                  selectPreUploadedFile == elem.id ? "active" : ""
                }`}
                onClick={() => handleCardClick(elem)}
              >
                {selectPreUploadedFile == elem.id && (
                  <div className="checkIcon">
                    <CheckIcon />
                  </div>
                )}
                <img alt="example" src={elem.link} />
              </div>
            ))}
          </div>
        )}

        {!loadingGalleries && !galleryListing.length && (
          <div className="no-search-found">
            <p className="text-center">
              <b>No search results found.</b>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default GalleryTab;
