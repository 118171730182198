import { createSlice } from "@reduxjs/toolkit";

export const rewardSlice = createSlice({
  name: "reward",
  initialState: {
    isBadgesLoading: true,
    isShoutoutsLoading: true,
    render: 1,
    achievements: [],
    badges: {
      data: [],
      meta: null,
    },

    shoutouts: {
      data: [],
      meta: null,
    },
  },
  reducers: {
    setIsBadgesLoading: (state, action) => {
      state.isBadgesLoading = action.payload;
    },
    setBadges: (state, action) => {
      state.badges.data = action.payload.data;
      state.badges.meta = action.payload.meta;
    },

    setIsShoutoutsLoading: (state, action) => {
      state.isShoutoutsLoading = action.payload;
    },
    setShoutouts: (state, action) => {
      state.shoutouts.data = action.payload.data;
      state.shoutouts.meta = action.payload.meta;
    },
    updateShoutouts: (state, action) => {
      let data = [...state.shoutouts.data];
      data[action.payload.index].offOn = action.payload.checkedValue;
      state.shoutouts.data = data;
    },
    setAchievements: (state, action) => {
      state.achievements = action.payload;
    },
    setRender: (state, action) => {
      state.render = action.payload;
    },
  },
});

export const {
  setIsBadgesLoading,
  setBadges,
  setIsShoutoutsLoading,
  setShoutouts,
  updateShoutouts,
  setAchievements,
  setRender,
} = rewardSlice.actions;

export default rewardSlice.reducer;
