import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import {
  BlackCrossIcon,
  DecrementIcon,
  IncrementIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import { notification } from "antd";
import { maxBounds, Patterns } from "../../../constants/contants";
import CustomTreeSelect from "../../FormFields/CustomTreeSelect";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { useSelector } from "react-redux";
import { GivePoint } from "../../../services/team/rewards";
import moment from "moment";

const CreateNewPoint = (props) => {
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [assignees, selectedCategory] = watch([
    "assignees",
    "selectedCategory",
  ]);

  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const { currentSalonId } = useSelector((state) => state.global);

  const [points, setPoints] = useState(100);
  const authUser = GetAuthUserLocalStorage();
  const [disable, setDisable] = useState(false);

  const handleIncrement = () => {
    if (points >= 999) {
      return false;
    }
    setPoints(points + 1);
  };

  const handleDecrement = () => {
    // setPoints(points - 1);
    if (points <= 0) {
      return false;
    }
    setPoints(points - 1);
  };

  const handleChange = (event) => {
    setPoints(parseInt(event.target.value));
  };

  const handleTreeSelectValue = (parentNode, value) => {
    setValue("assignees", value);
    setValue("selectedCategory", parentNode);
  };

  const returnUsers = (category) => {
    if (category == "everyone") {
      return assignees;
    } else if (category == "group") {
      let groups = salonTeams?.data?.groups?.filter((item) =>
        assignees?.includes(item?.id?.toString()),
      );
      let users = [];
      for (let i = 0; i < groups?.length; i++) {
        for (let j = 0; j < groups[i]?.users?.length; j++) {
          users.push(groups[i].users[j]?.id?.toString());
        }
      }

      return users;
    } else if (category == "member") {
      return assignees;
    }
  };

  const handleDisableSubmit = () => {
    if (
      disable ||
      !isValid ||
      isSubmitting ||
      !assignees ||
      assignees?.length <= 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetForm = () => {
    reset({
      name: "",
      description: "",
      points: "",
      assignees: [],
    });
  };

  const onSubmit = async (data) => {
    try {
      const apiData = {
        name: data?.name,
        description: data?.description,
        user_id: authUser?.id,
        points,
        date: moment().format("YYYY-MM-DD"),
        salon_id: currentSalonId,
        assignees: returnUsers(selectedCategory),
      };
      setDisable(true);
      const res = await GivePoint(apiData);
      resetForm();
      setDisable(false);
      props.onHide();
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-point-group"
      >
        <Modal.Header>
          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <BlackCrossIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>Give Reward Points</h4>
          <p className="text-point">
            Send rewards points to high-achievers for anything.
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <Row className="mb-20">
                <Col lg={8}>
                  <FloatingLabel
                    controlId="achievement"
                    label="Achievement"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder="Achievement"
                      autoComplete="off"
                      role={"presentation"}
                      className={errors.taskName ? "hasError" : ""}
                      maxLength={maxBounds.POINTSTITLE}
                      {...register("name", {
                        required: {
                          value: true,
                          // message: "This field is required",
                        },
                        pattern: {
                          value: Patterns.Name,
                          // message: "Add the achievement",
                        },
                        maxLength: {
                          value: maxBounds.POINTSTITLE,
                          // message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                      })}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  <CustomTreeSelect
                    isedit={false}
                    label="Recipients"
                    placeholder="Recipients"
                    defaultSelectedData={[]}
                    handleChange={handleTreeSelectValue}
                    selectedCategory={getValues("selectedCategory") || null}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FloatingLabel
                    controlId="description"
                    label="Description"
                    className="mb-20"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Description"
                      style={{ height: "200px" }}
                      maxLength={100}
                      {...register("description", {
                        required: {
                          value: true,
                        },
                      })}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <div className="main-point-div">
                    <div className="increment-div">
                      <span>Points</span>
                      <div className="inc-btns">
                        <div onClick={handleIncrement}>
                          <IncrementIcon />{" "}
                        </div>
                        <div onClick={handleDecrement}>
                          <DecrementIcon />
                        </div>
                      </div>
                    </div>
                    <FloatingLabel
                      controlId="points"
                      label="Points"
                      className=""
                    >
                      <Form.Control
                        type="number"
                        placeholder="Points"
                        autoComplete="off"
                        role="presentation"
                        className={errors.points ? "hasError" : ""}
                        value={points}
                        {...register("points", {
                          required: true,
                          pattern: {
                            // Add your pattern validation here if needed
                          },
                          maxLength: {
                            value: maxBounds.POINTS,
                          },
                        })}
                        onChange={(e) => {
                          // Your custom onChange logic here
                          const newValue = e.target.value;

                          // Truncate the input value to the maximum length if needed
                          if (newValue.length > maxBounds.POINTS) {
                            e.target.value = newValue.slice(
                              0,
                              maxBounds.POINTS,
                            );
                          }

                          // Update the state or perform any other necessary actions
                          handleChange(e);
                        }}
                      />
                    </FloatingLabel>
                  </div>
                </Col>
                <Col lg={4}>
                  <Button
                    className="solid-gotit-grey-btn give-points"
                    type="submit"
                    disabled={handleDisableSubmit()}
                  >
                    {disable ? <div className="load black" /> : "Give Points"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateNewPoint;
