import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Divider } from "antd";
import CopylinkBarTwo from "../../components/Common/CopylinkBarTwo";

const ReferralPage = () => {
  const [refferal, setRefferal] = useState([
    { name: "James Ekstrom", date: "July 3, 2022", status: "Pending" },
    { name: "Kierra Calzoni", date: "July 3, 2022", status: "Approved" },
    { name: "Makenna Vetrovs", date: "July 3, 2022", status: "Pending" },
    { name: "Makenna Vetrovs", date: "July 3, 2022", status: "Approved" },
    { name: "James Ekstrom", date: "July 3, 2022", status: "Pending" },
    { name: "Kierra Calzoni", date: "July 3, 2022", status: "Approved" },
  ]);
  return (
    <div>
      <div className="refferal-header">
        <p className="refferal-title">Referral Program</p>
        <p className="refferal-desc">
          Earn $10 OFF your next month’s subscription for every friend you send
          to Salon Symphony. <a href="#">Learn more »</a>
        </p>
      </div>
      <div className="refferal-row">
        <div className="refferal-container">
          <p className="one">
            <b>01.</b>
          </p>
          <p className="two">Copy your referral link below</p>
          <p className="three">
            First, copy your unique referral link in the field below.
          </p>
        </div>
        <div className="refferal-container">
          <p className="one second ">
            <b>02.</b>
          </p>
          <p className="two">Send your link to colleagues</p>
          <p className="three">
            Next, share your link with people in your network anyway you like.
          </p>
        </div>
        <div className="refferal-container">
          <p className="one third">
            <b>03.</b>
          </p>
          <p className="two">Earn $10 when they join</p>
          <p className="three">
            Voila! Enjoy a $10 credit towards your subscription for every
            referral
          </p>
        </div>
      </div>
      <Row style={{ paddingTop: "21px" }}>
        <CopylinkBarTwo link="https://salonsymphonysoftware.com/referrer/acapello-salons" />
      </Row>

      <Row className="refferal-table">
        <Col className="refferal-table-header">
          <p>Referee</p>
        </Col>
        <Col className="refferal-table-header">
          <p>Date Sent</p>
        </Col>
        <Col className="refferal-table-header">
          <p>Status</p>
        </Col>
        <Divider />
      </Row>
      <div className="refferalTableScroll">
        {refferal.length != 0
          ? refferal.map((item, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <p className="refferal-table-name">{item.name}</p>
                  </Col>
                  <Col className="refferal-table-body">
                    <p>{item.date}</p>
                  </Col>
                  <Col className="refferal-table-body">
                    <p>{item.status}</p>
                  </Col>
                  <Divider />
                </Row>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ReferralPage;
