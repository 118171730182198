import { Dropdown, Image, notification, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import {
  AudioIconFilled,
  BinocularsLight,
  EditIcon,
  LockIcon,
  MoreIcon,
  NewSparkleSmallIcon,
  NoDataIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../../constants/svg";
import {
  DeleteSalonResource,
  GetSalonResources,
} from "../../../services/resources/resources";
import {
  setAudioResourcePagination,
  setAudioResources,
  setFirstStepTabNo,
  setResourceAddEditForm,
  setSelectedData,
} from "../../../store/slices/resourcesSlice";
import { hasAccess, toastMessage } from "../../../utils/helper";
import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import {
  roles as salonRoles,
  salon_resources_type,
  status,
} from "../../../utils/constants";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { FaStar } from "react-icons/fa";

const AudioResources = (props) => {
  const dispatch = useDispatch();

  const {
    resources_audio,
    isAudioResourcesLoading,
    audioResourcePagination,
    selectedData,
  } = useSelector((state) => state?.resources);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  // const [selectedData, setSelectedData] = useState(null)
  const [disable, setDisable] = useState(false);
  const url = window.location.href + "/" + selectedData?.id;
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { currentSalonId } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="adminRoleBox lockBox">
            <h6>
              <span>
                <NewSparkleSmallIcon />
              </span>{" "}
              Posted by Salon Symphony
            </h6>
          </div>
        </>
      ),
    },
  ];

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/resources/details/${record?.id}`}
              className={"black"}
            >
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                dispatch(setSelectedData(record));
                dispatch(setFirstStepTabNo(3));
                dispatch(
                  setResourceAddEditForm({
                    show: true,
                    formType: "update",
                  }),
                );
              }}
            >
              <EditIcon />
              Edit
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                dispatch(setSelectedData(record));
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              setSelectedData(record);
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const handleDelete = async () => {
    try {
      setDisable(true);
      let res = await DeleteSalonResource(selectedData?.id);
      toastMessage("delete", res?.data?.message);
      setShowModal({ ...showModal, deleteModal: false });
      setDisable(false);

      if (props.deleteData == true) {
        props.setDeletedData(false);
      } else {
        props.setDeletedData(true);
      }
    } catch (e) {
      toastMessage("error", e.message);
      setShowModal({ ...showModal, deleteModal: false });
      setDisable(false);
    }
  };

  const handleChangeAudioResourcePagination = (page) => {
    dispatch(
      setAudioResourcePagination({
        ...audioResourcePagination,
        page,
      }),
    );
  };
  useEffect(() => {
    if (resources_audio?.data?.length == resources_audio?.meta?.total) {
      setHasMore(false);
    }
  }, [resources_audio]);

  const fetchMoreData = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        let next_page = resources_audio.meta.current_page + 1;

        let params = {
          salon_id: currentSalonId,
          user_id: authUser?.id,
          status: status?.active,
          is_paginated: 1,
          type: salon_resources_type?.audio,
          category_id: props.seletedCategory,
          page: next_page,
          "per-page": audioResourcePagination.perPage,
        };

        const response = await GetSalonResources(params);
        const newData = response?.data?.data?.data;

        const hasPostsData = response?.data?.data?.data?.length > 0;
        if (hasPostsData) {
          const updatedData = [...resources_audio.data, ...newData];
          if (
            response?.data?.data?.meta?.total &&
            response?.data?.data?.meta?.total <= updatedData?.length
          ) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          dispatch(
            setAudioResources({
              meta: response?.data?.data?.meta,
              data: updatedData,
            }),
          );
          setLoading(false);
        }
      } catch (e) {
        notification.error({
          description: e.message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        setLoading(false);
      }
    }, 1500);
  };
  return (
    <>
      <div className="content custom-pl-15">
        <div className="resources-card-height">
          {isAudioResourcesLoading && <LoadingScreen child={true} />}

          {!isAudioResourcesLoading && !resources_audio?.data.length && (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Resources you've added will appear here.</h5>
              <h6>Click "Add Resource" to get started.</h6>
            </div>
          )}

          {!isAudioResourcesLoading && resources_audio?.data.length > 0 && (
            <InfiniteScroll
              dataLength={
                resources_audio?.data ? resources_audio?.data?.length : 0
              }
              next={fetchMoreData}
              scrollableTarget="infinite-scroll-component "
              hasMore={hasMore} // You can conditionally set this based on your data
              loader={
                loading ? (
                  <div className="loader-chat">
                    <Image
                      width={100}
                      height={100}
                      src={Assets.loadingGif}
                      preview={false}
                    />
                  </div>
                ) : null
              }
              height={"calc(100vh - 192px)"}
            >
              <Row>
                {resources_audio?.data.map((el, idx) => {
                  return (
                    <Col lg={4} xxl={3} key={idx}>
                      <div className="resource_box">
                        <NavLink to={`/resources/details/${el?.id}`}>
                          <div className="resource_img">
                            <img
                              src={el?.image}
                              onError={(e) =>
                                (e.target.src = Assets.GeneralPlaceholder)
                              }
                            />
                          </div>
                          <div className="resource_content ">
                            <div className="mainDivLock tip-top">
                              <h5 className="black">{el?.name}</h5>
                            </div>
                            <div className="resourceSparkleDiv">
                              {el?.user?.roles[0]?.id ==
                                salonRoles.super_admin && (
                                <Dropdown
                                  menu={{
                                    items,
                                  }}
                                  overlayClassName="singleDropdown "
                                  className="singLock"
                                  placement="bottom"
                                  open={false}
                                >
                                  <Space>
                                    {/* <LockIcon /> */}
                                    <FaStar
                                      color="#A8A8A8"
                                      style={{ ":hover": { color: "#c4c4c4" } }}
                                    />
                                  </Space>
                                </Dropdown>
                              )}
                              <p>{el?.category?.name}</p>
                            </div>
                          </div>
                        </NavLink>
                        <div className="sub-div">
                          <ul>
                            <li className="body">
                              <AudioIconFilled />
                              {el?.meta?.user_views}
                            </li>
                            <li>
                              <Space size="middle">
                                <Dropdown
                                  menu={{
                                    items: actionMenu(el),
                                  }}
                                  placement="bottomRight"
                                  trigger={["click"]}
                                  overlayClassName={"defaultDropdown"}
                                >
                                  <div className="icon cursor-pointer">
                                    <MoreIcon />
                                  </div>
                                </Dropdown>
                              </Space>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
          )}
        </div>
        {/* <Row className="mt-2 mb-4"> */}
        {/*    <Col md={12}> */}
        {/*        <Pagination */}
        {/*            defaultCurrent={1} */}
        {/*            current={audioResourcePagination.page} */}
        {/*            total={resources_audio?.meta?.total} */}
        {/*            pageSize={audioResourcePagination.perPage} */}
        {/*            onChange={handleChangeAudioResourcePagination} */}
        {/*            style={{ float: "right" }} */}
        {/*            onShowSizeChange={false} */}
        {/*        /> */}
        {/*    </Col> */}
        {/* </Row> */}

        <ShareModal
          shareLink={url}
          data={{
            image: selectedData?.image,
            name: selectedData?.name,
            description: selectedData?.description,
            start_date: selectedData?.created_at,
            start_time: moment(selectedData?.created_at).format("hh:mm a"),
          }}
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Resource"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/inbox"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this event with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "How to Use Big Blowout Product Guide",
            para1:
              "Big Blowout is a unique jelly serum that melts into hair to provide instant volume with a soft, silky finish. This lightweight formula leaves hair naturally bouncy with no crunch while protecting from heat up to 450°F/230°C.",
            para2: "PDF • Mar 03, 2023",
          }}
        />
        <DeleteModal
          disable={disable}
          handleDelete={handleDelete}
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Resource?"
          para={
            <>
              Are you sure you want to delete{" "}
              <b> {selectedData?.name || "this resource"} ?</b>
              <br />
              Important: This resource cannot be recovered.
            </>
          }
          notificationMessage="PDF resource"
        />
      </div>
    </>
  );
};

export default AudioResources;
