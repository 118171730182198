import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import {
  BlackCrossIcon,
  RedAlertIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
  WhiteModalCrossIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import { notification } from "antd";

const DeleteCustomGroupModal = (props) => {
  const value = props.text;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-delete-group"
      >
        <Modal.Header>
          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <BlackCrossIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="leaveModalIcon">
            {props.icon ? props.icon : <RedAlertIcon />}
          </div>
          <h4>Delete Custom Group?</h4>
          <p>
            Are you sure you want to delete <b>Redken Ambassadors?</b>
            <br />
            Important: This group cannot be recovered.
          </p>
          <div className="btn-div">
            <Button
              className="solid-learn-grey-btn me-2 new"
              onClick={props.onHide}
              data-bs-dismiss="modal"
            >
              Cancel
            </Button>
            <Button className="solid-red-lg-btn" onClick={props?.handleDelete}>
              {props?.disable ? <div className="load black" /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteCustomGroupModal;
