import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import CustomSelect from "../../../../FormFields/CustomSelect";
import { Assets } from "../../../../../constants/images";
import { setCreateUpdateModuleTitle } from "../../../../../store/slices/globalSlice";

const SecondStep = ({ register, errors, control, setValue, getValues }) => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.global);
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);

  function handleEmojiClick(emojiData) {
    setValue("eventName", getValues("eventName") + emojiData.emoji);
  }

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <Row>
      <Col lg={12}>
        <FloatingLabel
          controlId="EventName"
          label="Event Name"
          className="mb-20"
        >
          <Form.Control
            type="text"
            placeholder="Event name"
            autoComplete="off"
            role={"presentation"}
            className={errors.eventName ? "hasError" : ""}
            minLength={1}
            maxLength={100}
            {...register("eventName", {
              onChange: (e) => {
                dispatch(setCreateUpdateModuleTitle(e.target.value));
              },
              required: {
                value: "Event name is required!",
              },
              minLength: {
                value: 1,
              },
              maxLength: {
                value: 100,
              },
            })}
          />
          <div ref={emojiPickerRef}>
            <div
              onClick={() => {
                setShowPicker(!showPicker);
              }}
              className={"smileyBox"}
            >
              <img src={Assets.Smile} className={"smileImage"} />
            </div>
            {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
          </div>
        </FloatingLabel>
      </Col>
      <Col lg={8}>
        <FloatingLabel
          controlId="organizationLabel"
          label="Educator/Organizer"
          className="mb-20"
        >
          <Form.Control
            type="text"
            placeholder="Educator/Organizer"
            autoComplete="off"
            role={"presentation"}
            className={errors.educatorOrganizer ? "hasError" : ""}
            minLength={1}
            maxLength={100}
            {...register("educatorOrganizer", {
              required: {
                value: "Educator/Organizer is required",
              },
              minLength: {
                value: 1,
              },
              maxLength: {
                value: 100,
              },
            })}
          />
        </FloatingLabel>
      </Col>
      <Col lg={4}>
        <Controller
          control={control}
          name="category"
          defaultValue={""}
          rules={{
            required: "Category is required",
          }}
          render={({ field: { onChange, value } }) => (
            <FloatingLabel
              controlId="category"
              label={value == "" ? "Category" : ""}
              className="mb-20"
            >
              <CustomSelect
                value={value}
                label="Category"
                options={categories}
                className={value == "" ? "hasError" : ""}
                onChange={onChange}
                placeholder="Category"
              />
            </FloatingLabel>
          )}
        />
      </Col>
      <Col lg={12}>
        <FloatingLabel
          controlId="description"
          label="Details (Optional)"
          className="custom-textarea mb-20"
        >
          <Form.Control
            as="textarea"
            placeholder="Add details about this event here…"
            style={{ height: "240px" }}
            className={errors.description ? "hasError" : ""}
            minLength={0}
            maxLength={999}
            {...register("description", {
              required: {
                value: false,
              },
              minLength: {
                value: 0,
              },
              maxLength: {
                value: 999,
              },
            })}
          />
        </FloatingLabel>
      </Col>
    </Row>
  );
};

export default SecondStep;
