import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Space, Table } from "antd";
import {
  gallery_type,
  gallery_type_string,
  tableHeight,
} from "../../../utils/constants";
import {
  AudioIconFilled,
  BinocularsLight,
  MoreIcon,
  PdfIconFilled,
  PurpleFolderIcon,
  RecycleBin,
  RenameIcon,
  ShareIcon,
  VideoIconFilled,
} from "../../../constants/svg";
import { formatDate, tableLoader, toastMessage } from "../../../utils/helper";
import { Col } from "react-bootstrap";
import {
  setGalleryAddEditForm,
  setUploadType,
} from "../../../store/slices/gallerysSlice";
import ShareUploadMediaModal from "../../../components/Modals/ShareUploadMediaModal";
import moment from "moment";
import { Assets } from "../../../constants/images";
import DeleteModal from "../../../components/Modals/DeleteModal";
import GenericModal from "../../../components/Modals/GenericModal";
import UploadModal from "../../../components/Modals/Uploads/UploadModal";
import { useDispatch } from "react-redux";
import { DeleteSingleGallery } from "../../../services/galleries/galleries";

function FolderDataTable({ data, refreshData }) {
  const dispatch = useDispatch();
  const [showGenericModalTwo, setShowGenericModalTwo] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [selectedData, setSelectedData] = useState(null);
  const [disable, setDisable] = useState(false);

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink
              to={`/settings/uploads/details/${record?.id}`}
              className={"black"}
            >
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      {
        key: "2",
        className: `${record.type == gallery_type.folder ? "" : "d-none"}`,
        label: (
          <>
            <div
              onClick={() => {
                setSelectedData(record);
                dispatch(setUploadType(gallery_type.folder));
                dispatch(
                  setGalleryAddEditForm({
                    show: true,
                    formType: "update",
                  }),
                );
              }}
            >
              <RenameIcon />
              Rename
            </div>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                setSelectedData(record);
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      {
        key: "4",
        label: (
          <>
            {record?.is_default == 20 ? null : (
              <div
                className={"deleteLabel"}
                onClick={() => {
                  setSelectedData(record);
                  setShowModal((prevState) => ({
                    ...prevState,
                    deleteModal: true,
                  }));
                }}
              >
                <RecycleBin />
                Delete
              </div>
            )}
          </>
        ),
      },
    ];
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "alertTitle",
      key: "alertTitle",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <NavLink
          to={`/settings/uploads/details/${record?.id}`}
          className={"black gallery-link"}
        >
          <Space>
            <div className="squareAvatar">
              {record.type == gallery_type_string.image && (
                <img
                  src={record.link}
                  className="eventDetailImage"
                  style={{
                    backgroundSize: "cover",
                  }}
                />
              )}
            </div>

            {record.type == gallery_type_string.pdf && (
              <span className="customSvgSize">
                <PdfIconFilled />
              </span>
            )}

            {record.type == gallery_type_string.video && (
              <span className="customSvgSize">
                <VideoIconFilled />
              </span>
            )}

            {record.type == gallery_type_string.audio && (
              <span className="customSvgSize">
                <AudioIconFilled />
              </span>
            )}

            {record.type == gallery_type_string.folder && (
              <span className="customSvgSize">
                <PurpleFolderIcon />
              </span>
            )}

            <div className="truncateUploadMediaName">{record?.name}</div>
          </Space>
        </NavLink>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.type}</p>,
      align: "left",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      sorter: (a, b) => a?.size?.localeCompare(b?.size),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.size}</p>,
      align: "left",
      width: 150,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "descend",
      render: (text, record) => {
        return (
          <div className="">
            <div className="seenBy">
              <span>{formatDate(record.created_at)}</span>
            </div>
          </div>
        );
      },

      // align: "center",
      width: 150,
    },
    {
      title: "More",
      align: "center",
      key: "action",
      width: 100,
      render: (record) => (
        <>
          <Space size="middle">
            <Dropdown
              menu={{
                items: actionMenu(record),
              }}
              placement="bottomRight"
              trigger={["click"]}
              overlayClassName={"defaultDropdown"}
            >
              <div className="icon cursor-pointer">
                <MoreIcon />
              </div>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ];
  const url =
    window.location.origin + "/settings/uploads/details/" + selectedData?.id;
  const handleDelete = async () => {
    try {
      setDisable(true);
      const res = await DeleteSingleGallery(selectedData?.id);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);
      refreshData(true);
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered={false}
        // pagination={{
        //     pageSize: 10,
        //     showSizeChanger: false,
        //     total: gallerys?.meta?.total,
        //     pageSizeOptions: [10, 20, 50, 100, 'All']
        // }}
        scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
        responsive={true}
        size={"large"}
        showSorterTooltip={false}
        className="flashCardTable imageTable " // customHeightTable class has been remove to remove scroll
        rowKey={(record) => record.key}
      />
      {/* Share Modal */}
      <ShareUploadMediaModal
        shareLink={url}
        data={{
          type: selectedData?.type,
          key: selectedData?.id,
          name: selectedData?.name,
          description: null,
          image: selectedData?.link,
          start_date: selectedData?.created_at
            ? moment(selectedData?.created_at).format("MMM DD, yyyy")
            : "-",
          start_time: selectedData?.created_at
            ? moment(selectedData?.created_at).format("hh:mm a")
            : "-",
        }}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Media"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this event with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Introduction to L’Oreal Color",
          para1: "Improve your Business knowledge with this flashcard set.",
          para2: "10 Cards • Feb 12, 2023",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        disable={disable}
        handleDelete={handleDelete}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Media"
        para={
          <>
            Are you sure you want to delete
            <b> {selectedData?.name || "this media"} ?</b>
            <br />
            Important: This cannot be undone.
          </>
        }
        notificationMessage="Image"
      />
    </>
  );
}

export default FolderDataTable;
