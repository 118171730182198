import moment from "moment";
import {
  setIsSingleTaskLoading,
  setSingleTask,
  setTasksFormData,
} from "../../store/slices/tasksSlice";
import {
  CreateTask,
  DeleteSingleTask,
  GetSingleTask,
  GetTasks,
  UpdateTask,
} from "./tasks";
import { notification } from "antd";
import { ToastCrossIcon } from "../../constants/svg";
import dayjs from "dayjs";
import { recipient_types, roles as salonRoles } from "../../utils/constants";
import { toastMessage, getRecurringDate } from "../../utils/helper";

export const getTasks = async (params, dispatch, setIsLoading, setData) => {
  try {
    dispatch(setIsLoading(true));
    const res = await GetTasks(params);
    const _data = res?.data?.data?.data || res?.data?.data;
    const data = _data.map((item) => {
      return {
        key: item?.id,
        taskName: item?.name || "-",
        type: item?.type || "-",
        icon: item?.user?.roles?.[0]?.id == salonRoles.super_admin,
        // dueDate: item?.end ? moment(item?.end).format("MMM DD, YYYY") : "-",
        dueDate: item?.end
          ? getRecurringDate(item).format("MMM DD, YYYY")
          : "-",
        toDo: {
          count: item?.todoUsers?.length,
          attendents: item?.todoUsers?.map((item) => item?.assigned?.name),
        },
        overdue: {
          count: item?.overdueUsers?.length,
          attendents: item?.overdueUsers?.map((item) => item?.assigned?.name),
        },
        done: {
          count: item?.doneUsers?.length,
          attendents: item?.doneUsers?.map((item) => item?.assigned?.name),
        },
        description: item?.description,
        repeat: item?.repeat,
        parentId: item?.parent_id,
        doneUserIds: item?.doneUsers?.map((item) => item?.assigned?.id),
      };
    });
    dispatch(setData({ data, meta: res?.data?.meta }));
    dispatch(setIsLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsLoading(false));
  }
};

export const deleteTask = async (
  selectedData,
  setDisable,
  showModal,
  setShowModal,
) => {
  try {
    setDisable(true);
    const res = await DeleteSingleTask(selectedData?.key);
    setShowModal({ ...showModal, deleteModal: false });
    toastMessage("delete", res.data.message);
    setDisable(false);
  } catch (e) {
    toastMessage("error", e.message);
    setDisable(false);
  }
};

export const getSingleTask = async (id, dispatch) => {
  try {
    dispatch(setIsSingleTaskLoading(true));
    const res = await GetSingleTask(id);
    dispatch(setSingleTask(res.data.data));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  } finally {
    dispatch(setIsSingleTaskLoading(false));
  }
};

export const presetDataForEditTask = async (
  selectedData,
  setSelected,
  dispatch,
) => {
  try {
    const res = await GetSingleTask(selectedData?.key);
    const data = res?.data?.data;
    dispatch(
      setTasksFormData({
        type: data?.type,
        name: data?.name,
        description: data?.description,
        start: dayjs(data?.start, "YYYY-MM-DD"),
        end: dayjs(data?.end, "YYYY-MM-DD"),
        repeat_cycle: data?.repeat_cycle,
        due_on: data?.due_on,
        selectedCategory:
          data?.recipient_type == recipient_types?.everyone
            ? "everyone"
            : data?.recipient_type == recipient_types?.group
              ? "group"
              : "member",
      }),
    );

    setSelected(data?.type);
    dispatch(setIsSingleTaskLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setIsSingleTaskLoading(false));
  }
};

export const createTask = async (
  apiData,
  setDisable,
  setModalShow,
  resetForm,
  fetchTasks,
) => {
  try {
    setDisable(true);
    const res = await CreateTask(apiData);
    setDisable(false);
    setModalShow(false);
    resetForm();
    fetchTasks();
    toastMessage("success", res.data.message);
  } catch (e) {
    setDisable(false);
    toastMessage("warning", e.message);
  }
};

export const updateTask = async (
  apiData,
  selectedData,
  setDisable,
  setModalShow,
  resetForm,
  fetchTasks,
) => {
  try {
    setDisable(true);
    const res = await UpdateTask(selectedData?.key, apiData);
    setDisable(false);
    setModalShow(false);
    resetForm();
    fetchTasks();
    toastMessage("success", res.data.message);
  } catch (e) {
    setDisable(false);
    toastMessage("warning", e.message);
  }
};
