import React, { useEffect, useState } from "react";
import RouteConfig from "./RouteConfig";
import { ToastContainer, Zoom } from "react-toastify";
import { MyContextProvider } from "./MyContext";
import { ArrowDown } from "./constants/svg";
import { ConfigProvider } from "antd";
import antdTheme from "./antdTheme.json";
import MobileComponent from "./components/MobileComponent";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobileWarning from "./components/MobileWarning";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { currentSubscriptionDetail } = useSelector(
    (state) => state.subscription,
  );
  /*  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []); */

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const isMobile = useMediaQuery({ maxWidth: 896 });
  // const isIpad = useMediaQuery({
  //   minWidth: 768,
  //   maxWidth: 1024,
  //   minHeight: 768,
  // });
  // const isIpadPro = useMediaQuery({
  //   minWidth: 1366,
  //   maxWidth: 1366,
  //   minHeight: 1024,
  //   maxHeight: 1024,
  //   orientation: "landscape",
  // });

  // const isIpadAir = useMediaQuery({
  //   minWidth: 1180,
  //   maxWidth: 1180,
  //   minHeight: 820,
  //   maxHeight: 820,
  //   orientation: "landscape",
  // });

  // const isPortrait = useMediaQuery({ orientation: "portrait" });
  // const isLandscape = useMediaQuery({ orientation: "landscape" });
  // const isDesktop = useMediaQuery({ minWidth: 1026 });

  // console.log(
  //   isLandscape,
  //   isMobile,
  //   "isLandscape",
  //   isIpad,
  //   isIpadPro,
  //   "ipad",
  //   isDesktop,
  //   "desktop",
  //   isIpadAir,
  //   "Ipad Air"
  // );

  return (
    <>
      {/* <MobileWarning
        title={"For an optimal experience"}
        para={"Please rotate your device to portrait mode"}
        includeImage={false}
        anchorLink={
          "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
        }
        anchorText={"Learn More"}
      />

      {isIpad && !isDesktop && (
        <MobileComponent
          title={"Things look a little tight…"}
          para={
            "Salon Symphony has features so BIG they won’t fit on a small screen. Please try resizing your window or visit mysalonsymphony.com on a desktop device."
          }
          includeImage={true}
          anchorLink={
            "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
          }
          anchorText={"Learn More"}
        />
      )}

      {isIpadPro && !isDesktop && (
        <MobileComponent
          title={"Things look a little tight…"}
          para={
            "Salon Symphony has features so BIG they won’t fit on a small screen. Please try resizing your window or visit mysalonsymphony.com on a desktop device."
          }
          includeImage={true}
          anchorLink={
            "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
          }
          anchorText={"Learn More"}
        />
      )}

      {isIpadPro && (
        <MobileComponent
          title={"Things look a little tight…"}
          para={
            "Salon Symphony has features so BIG they won’t fit on a small screen. Please try resizing your window or visit mysalonsymphony.com on a desktop device."
          }
          includeImage={true}
          anchorLink={
            "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
          }
          anchorText={"Learn More"}
        />
      )}
      {isIpadAir && (
        <MobileComponent
          title={"Things look a little tight…"}
          para={
            "Salon Symphony has features so BIG they won’t fit on a small screen. Please try resizing your window or visit mysalonsymphony.com on a desktop device."
          }
          includeImage={true}
          anchorLink={
            "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
          }
          anchorText={"Learn More"}
        />
      )}

      {isMobile && currentSubscriptionDetail?.statusName == "Paid" && (
        <MobileComponent
          title={"Things look a little tight…"}
          para={
            "Salon Symphony has features so BIG they won’t fit on a small screen. Please try resizing your window or visit mysalonsymphony.com on a desktop device."
          }
          includeImage={true}
          anchorLink={
            "https://support.mysalonsymphony.com/hc/en-us/articles/18910550599451-Why-can-t-I-access-the-Admin-Dashboard-on-a-mobile-phone-"
          }
          anchorText={"Learn More"}
        />
      )}

      {isMobile && !isLandscape && (
        <MyContextProvider>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
            limit={1}
            theme="light"
            successIcon={<ArrowDown />}
          />
          <ConfigProvider theme={antdTheme}>
            <RouteConfig />
          </ConfigProvider>
        </MyContextProvider>
      )}
      {isDesktop && ( */}
      <MyContextProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
          limit={1}
          theme="light"
          successIcon={<ArrowDown />}
        />
        <ConfigProvider theme={antdTheme}>
          <RouteConfig />
        </ConfigProvider>
      </MyContextProvider>
      {/* )} */}
    </>
  );
};

export default App;
