import { Select } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useStateContext } from "../../../Contexts/ContextProvider";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
  CalenderIconUpward,
  CalenderIconUpwardRegular,
  CalenderIconDownward,
  CalenderIconDownwardRegular,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { setIsTasksLoading } from "../../../store/slices/tasksSlice";
import { roles as salonRoles, task_type_array } from "../../../utils/constants";
import GenericModal from "../../Modals/GenericModal";
import TaskModalForms from "./Modals/TaskModalForms";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";
import moment from "moment";
import { getShowUpcomingTasks } from "../../../utils/commons";
import { size } from "lodash";

const TaskHeader = (props) => {
  const { currentSalonId, allSalons } = useSelector((state) => state.global);
  const { tasks } = useSelector((state) => state?.tasks);
  const authUser = GetAuthUserLocalStorage();
  const { id: userId } = authUser;

  const { taskPopupTitle, setTaskPopupTitle } = useStateContext();
  const [showGenericModal, setShowGenericModal] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm();

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };

  const handleChange = (value) => {
    props?.handleResetPage();
    props?.setSelectedCategory(value);
    dispatch(setIsTasksLoading(true));
  };

  const taskCount = useMemo(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    let pastTasks = 0;
    tasks.data.map((task) => {
      if (moment(task.dueDate).isSameOrAfter(currentDate)) {
      } else {
        pastTasks++;
      }
    });
    const finalUpcommingTasksData = getShowUpcomingTasks(tasks.data, userId);
    const upcomingTasks = size(finalUpcommingTasksData);
    return { upcomingTasks, pastTasks };
  }, [tasks, userId]);

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <div>
            <h2 className="sec-heading">Tasks</h2>
          </div>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                <div className="icon icon-active">
                  <CalenderIconUpward />
                </div>
                <div className="icon">
                  <CalenderIconUpwardRegular />
                </div>
                Upcoming ({taskCount.upcomingTasks || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                <div className="icon icon-active">
                  <CalenderIconDownwardRegular />
                </div>
                <div className="icon">
                  <CalenderIconDownward />
                </div>
                Past ({taskCount.pastTasks || 0})
              </div>
            </div>
          </div>
        </div>

        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              value={currentSalonId || undefined}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue={"All Tasks"}
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={task_type_array}
            onChange={handleChange}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() => {
              setShowGenericModal(true);
              setTaskPopupTitle("Task Name");
            }}
          >
            <span>
              <CreateIcon />
            </span>
            Assign Task
          </button>
        </div>
      </div>

      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={taskPopupTitle}
        customModalHeader={"customNewTaskModalHeader"}
        size={"lg"}
        bodyComponent={
          <FormProvider {...methods}>
            <TaskModalForms
              isEdit={false}
              methods={methods}
              showGenericModal={showGenericModal}
              setShowGenericModal={setShowGenericModal}
              customModalBody={"customNewTaskModalBody"}
            />
          </FormProvider>
        }
      />
    </>
  );
};

export default TaskHeader;
