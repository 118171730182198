import { Assets } from "../../../constants/images";

export const staffData = [
  {
    title: (
      <>
        Everyone <span className="roundedIcon"></span>
      </>
    ),
    value: "everyone",
    image: Assets.everyOneImage,
  },
  {
    title: "Groups",
    value: "groups",
    selectable: false,
    children: [
      {
        title: (
          <>
            Stylists <span className="roundedIcon check"></span>
          </>
        ),
        value: "stylists",
        image: Assets.GalleryImg1,
      },
      {
        title: (
          <>
            Managers <span className="roundedIcon check"></span>
          </>
        ),
        value: "managers",
        image: Assets.GalleryImg1,
      },
      {
        title: (
          <>
            Barbers <span className="roundedIcon check"></span>
          </>
        ),
        value: "babers ",
        image: Assets.GalleryImg1,
      },
      {
        title: (
          <>
            Barbers <span className="roundedIcon check"></span>
          </>
        ),
        value: "babers",
        image: Assets.GalleryImg1,
      },
    ],
  },
  {
    title: "Members",
    value: "members",
    selectable: false,
    children: [
      {
        title: (
          <>
            Member 1 <span className="roundedIcon check"></span>
          </>
        ),
        value: "member1",
        image: Assets.GalleryImg2,
      },
      // ...
    ],
  },
];

export const Location = [
  {
    value: "0",
    label: "Manhattan",
  },
  {
    value: "1",
    label: "London",
  },
  {
    value: "2",
    label: "Geneva",
  },
  {
    value: "3",
    label: "USA",
  },
  {
    value: "4",
    label: "United Kingdom",
  },
  {
    value: "5",
    label: "Open House",
  },
  {
    value: "6",
    label: "Celebration",
  },
];
