import React, { useState } from "react";
import ActivityHeader from "../../components/pages/Activity/ActivityHeader";
import ActivityBody from "../../components/pages/Activity/ActivityBody";

const Activity = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <>
      <div className="innerContainer">
        <ActivityHeader activeTab={activeTab} setActiveTab={setActiveTab} />

        <ActivityBody activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </>
  );
};

export default Activity;
