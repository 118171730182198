import {
  Checkbox,
  ConfigProvider,
  List,
  notification,
  Popover,
  Space,
} from "antd";
import VirtualList from "rc-virtual-list";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "../../assets/images/AddICon.svg";
import DeleteGroupModal from "../../components/Modals/DeleteGroupModal";
import ShareModal from "../../components/Modals/ShareModal";
import { Assets } from "../../constants/images";
import {
  LockKeyHoleFaded,
  MessageIcon,
  PenBoxIcon,
  PurpleBackIcon,
  RecycleBin,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../constants/svg";
import { useDispatch, useSelector } from "react-redux";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { getUsersWithoutPagination } from "../../services/users/frontend-users";
import {
  setAddMember,
  setCreateUpdateGroupForm,
  setIsSingleGroupLoading,
  setSelectedData,
  setSingleGroup,
} from "../../store/slices/groupSlice";
import {
  AddUserToGroup,
  DeleteGroupByID,
  GetGroupByID,
  RemoveUserFromGroup,
} from "../../services/team/groups";
import moment from "moment";
import {
  deleteGroupChat,
  redirectToGroupChat,
  syncFirebaseGroup,
} from "../../services/chats/frontend-chats";
import { toastMessage } from "../../utils/helper";
import GenericModal from "../../components/Modals/GenericModal";
import CreateNewGroup from "../../components/Modals/CreateNewGroup";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import DeleteModal from "../../components/Modals/DeleteModal";
import { groupTypes, message_type } from "../../utils/constants";
import { serverTimestamp } from "firebase/firestore";
import AvatarImage from "../../components/Common/AvatarImage";
import { checkIsDefaultGroupAvatar } from "../../utils/commons";
import EveryOneImage from "../../assets/images/everyone.svg";

const GroupDetailPage = () => {
  const navigate = useNavigate();
  const [selectedfile, setSelectedfile] = useState(null);
  const fileUpload = useRef(null);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [showPopOver, setShowPopOver] = useState(false);
  const dispatch = useDispatch();
  const [tempAddedMembers, setTempAddedMembers] = useState([]);
  const [removingGroupMemeberId, setRemovingGroupMemberId] = useState(null);
  const [onAction, setOnAction] = useState(false);
  const authUser = GetAuthUserLocalStorage();
  const { id } = useParams();
  const { singleGroup, formData, createUpdateGroupForm } = useSelector(
    (state) => state?.group,
  );
  const { isUsersLoading, users } = useSelector((state) => state?.users);
  const { currentSalonId } = useSelector((state) => state.global);
  const [disable, setDisable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const isEqualObject = (userId) => {
    return tempAddedMembers.some((obj) => obj?.id == userId);
  };

  const handleChange = (e, item) => {
    let data = [...tempAddedMembers];
    let isAlreadyAdded = tempAddedMembers?.some(
      (member) => member?.id == item?.id,
    );

    if (isAlreadyAdded) {
      const index = data.findIndex((obj) => obj.id == item?.id);
      data.splice(index, 1);
      setTempAddedMembers(data);
    } else {
      data.push(item);
      setTempAddedMembers(data);
    }
  };

  const addMember = async () => {
    let authUser = GetAuthUserLocalStorage();
    try {
      setDisable(true);
      let users = tempAddedMembers?.map((item) => item?.id);
      const res = await AddUserToGroup(id, { users });
      let newUsersCount = users.length - singleGroup?.data?.users?.length;
      const newMessageWithTxt = {
        created_at: serverTimestamp(),
        id: uuidv4(),
        is_read: false,
        sender_id: authUser?.id,
        image_url: "",
        video_url: "",
        media_url: "",
        text: `${authUser?.salon?.name} has added ${newUsersCount} member${newUsersCount > 1 ? "s" : ""}`,
        message_type: message_type?.default,
      };
      await syncFirebaseGroup(
        singleGroup?.data?.id,
        res?.data?.data,
        newMessageWithTxt,
      );
      setShowPopOver(false);
      setDisable(false);
      setOnAction(!onAction);
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };

  const removeMember = async (user_id) => {
    try {
      setDisable(true);
      setRemovingGroupMemberId(user_id);
      const res = await RemoveUserFromGroup(singleGroup?.data?.id, user_id);
      await syncFirebaseGroup(singleGroup?.data?.id, res?.data?.data);
      setDisable(false);
      setOnAction(!onAction);
      setRemovingGroupMemberId(null);
      notification.info({
        message: <span>Successfully removed the member</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    } finally {
    }
  };

  const content = (
    <div className="add_new_popup_list member-add">
      <List>
        <VirtualList data={users?.data} height={265} itemKey="id">
          {(item) => (
            <List.Item
              key={item.id}
              actions={[
                <Checkbox
                  onChange={(e) => handleChange(e, item)}
                  checked={isEqualObject(item?.id)}
                ></Checkbox>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <AvatarImage
                    name={item?.name}
                    src={item?.image}
                    placeholder={Assets.ProfilePlaceholder}
                    size={36}
                  />
                }
                title={<p>{item?.name || "-"}</p>}
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
      <div className=" list__bottom__tooltip">
        <Button
          disabled={disable}
          onClick={addMember}
          className="add__member__button"
        >
          {disable ? <div className="load black" /> : "Done"}
        </Button>
      </div>
    </div>
  );

  const fetchtSingleGroup = async () => {
    try {
      dispatch(setIsSingleGroupLoading(true));
      const res = await GetGroupByID(id);
      const { data, message, status } = res.data;
      dispatch(setSingleGroup(data));
      setTempAddedMembers(data?.users);
      dispatch(setAddMember(data?.users));
      dispatch(setIsSingleGroupLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const fetchUsers = async () => {
    const params = {
      is_paginated: 0,
      salon_id: currentSalonId,
    };
    await getUsersWithoutPagination(params, dispatch);
  };

  const handleGroupChat = async () => {
    try {
      let usersMembers = [];
      singleGroup?.data?.users.map((item) => {
        usersMembers.push(item.id);
      });
      await redirectToGroupChat(
        singleGroup?.data?.id,
        dispatch,
        usersMembers,
        singleGroup?.data,
      );
      navigate("/inbox");
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchtSingleGroup();
  }, [onAction, currentSalonId]);

  useEffect(() => {
    fetchUsers();
    fetchtSingleGroup();
  }, [createUpdateGroupForm]);

  const handleDelete = async () => {
    try {
      setDisable(true);
      const response = await DeleteGroupByID(singleGroup?.data?.id);
      await deleteGroupChat(singleGroup?.data?.id);

      const { data, status, message } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      toastMessage("delete", message);
      setDeleteModal(false);
      navigate(`/team/groups`);
      dispatch(setSelectedData(null));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisable(false);
    }
  };

  return (
    // todo: for raja bhai
    <>
      {singleGroup?.isSingleGroupLoading ? (
        <LoadingScreen child={true} />
      ) : (
        <Row>
          <Col lg={12} className={""}>
            <div
              className="leftEventDetailWrapper"
              style={{ marginRight: "0px" }}
            >
              <div className="group__details__header">
                <div className="d-flex justify-content-between">
                  <div className="backToEvent">
                    <NavLink to="/team/groups" style={{ fontSize: "14px" }}>
                      <PurpleBackIcon />
                      <span className="uLineHover">Back to Groups</span>
                    </NavLink>
                  </div>
                  <div className="shareButton">
                    <Space size={"large"}>
                      {singleGroup?.data?.type == groupTypes.CUSTOM && (
                        <div
                          className="icon-text-wrapper"
                          onClick={() => {
                            dispatch(setSelectedData(singleGroup.data));
                            dispatch(
                              setCreateUpdateGroupForm({
                                show: true,
                                formType: "update",
                              }),
                            );
                          }}
                        >
                          <PenBoxIcon />
                          <p className="uLine text__capitalize">Edit group</p>
                        </div>
                      )}
                      <div className="icon-text-wrapper">
                        <MessageIcon />
                        <p
                          className="uLine text__capitalize"
                          onClick={handleGroupChat}
                        >
                          Chat group
                        </p>
                      </div>
                      <div
                        className="icon-text-wrapper"
                        onClick={() => {
                          if (singleGroup?.data?.type == groupTypes.CUSTOM) {
                            setDeleteModal(true);
                          } else {
                            setShowModal((prevState) => ({
                              ...prevState,
                              deleteModal: true,
                            }));
                          }
                          dispatch(setSelectedData({ id }));
                        }}
                      >
                        {singleGroup?.data?.type == groupTypes.CUSTOM ? (
                          <RecycleBin />
                        ) : (
                          <LockKeyHoleFaded />
                        )}

                        <p className=" uLine faded text__capitalize">
                          delete group
                        </p>
                      </div>
                    </Space>
                  </div>
                </div>
                <div className="upload__image__section">
                  <div className="upload__photo__main">
                    <AvatarImage
                      name={singleGroup?.data?.name}
                      src={
                        checkIsDefaultGroupAvatar(singleGroup?.data?.image)
                          ? EveryOneImage
                          : singleGroup?.data?.image
                      }
                      size={58}
                      placeholder={EveryOneImage}
                    />
                    {/* {!singleGroup?.data?.image && (
                      <img
                        src={Assets.ProfilePlaceholder}
                        className="camera__alternate"
                      />
                    )}
                    {singleGroup?.data?.image && (
                      <img
                        src={singleGroup?.data?.image}
                        alt="uploaded image"
                        style={{ width: "100%", borderRadius: "50%" }}
                        className="img-div"
                      />
                    )} */}
                  </div>
                  {/* <input ref={fileUpload} type="file" style={{ display: 'none' }} onChange={(event) => setSelectedfile(event.target.files[0])} /> */}
                  <div className="group-detail-header">
                    <h1 className="sec-heading">
                      {singleGroup?.data?.name || "-"}
                    </h1>
                    <p className="light-gray">
                      Created On{" "}
                      {moment(singleGroup?.data?.created_at)?.format(
                        "MMM DD, YYYY",
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="detail-body">
                    <h4>
                      Assigned Members (
                      {singleGroup?.data?.users?.length
                        ? singleGroup?.data?.users?.length
                        : 0}
                      )
                    </h4>
                    <div className="add__member__action__header">
                      <Popover
                        className="custom-class"
                        content={content}
                        overlayStyle={{
                          width: "346px",
                          position: "relative",
                        }}
                        trigger="click"
                        placement="bottomLeft"
                        open={showPopOver}
                        onOpenChange={() => setShowPopOver(!showPopOver)}
                      >
                        <img src={AddIcon} style={{ cursor: "pointer" }} />
                        <span
                          className="add__members__title"
                          style={{ marginLeft: "8px" }}
                        >
                          Add members
                        </span>
                      </Popover>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <div>
                          <ConfigProvider renderEmpty={() => <p></p>}>
                            <List
                              itemLayout="horizontal"
                              className="custom-class addLocationList"
                              dataSource={formData?.addedMembers}
                              renderItem={(member) => (
                                <List.Item
                                  style={{
                                    borderBottom: "none",
                                    padding: "10px 0px",
                                  }}
                                  key={uuidv4()}
                                >
                                  <div className="member__render__list__inner">
                                    <AvatarImage
                                      name={member?.name}
                                      src={member?.image}
                                      placeholder={Assets.ProfilePlaceholder}
                                      size={36}
                                    />
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: "5px" }}
                                    >
                                      <h3 className="mb-0">
                                        {member?.name || "-"}
                                      </h3>
                                      <span
                                        className="members__remove__from__list"
                                        onClick={() => removeMember(member?.id)}
                                      >
                                        (Remove)
                                      </span>
                                    </div>
                                  </div>
                                </List.Item>
                              )}
                            />
                          </ConfigProvider>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Onboarding Task"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this goal with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />
      {/* Delete Modal */}
      {/* Delete Modal */}
      <DeleteGroupModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
      />
      <DeleteModal
        handleDelete={handleDelete}
        disable={disable}
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
          setShowModal({ ...showModal, deleteModal: false });
        }}
        heading="Delete Group?"
        para={
          <>
            Are you sure you want to delete
            <b> {singleGroup?.data?.name || "this group"} ?</b>
            <br />
            Important: This group cannot be recovered.
          </>
        }
        notificationMessage="Group"
      />
      <GenericModal
        // resetForm={resetForm}
        show={createUpdateGroupForm.show}
        onHide={() => {
          dispatch(
            setCreateUpdateGroupForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setCreateUpdateGroupForm({
              show: false,
              formType: null,
            }),
          );
        }}
        modalTitle={
          createUpdateGroupForm.formType == "update"
            ? "Edit Group"
            : "Create New Group"
        }
        pro={"Pro Tip:"}
        modalDesc={
          <>
            <div style={{ padding: "0 38px", textAlign: "left" }}>
              <b>Pro Tip:</b> You can easily refer to custom groups when sending
              alerts, assigning tasks, and more!
            </div>
          </>
        }
        size={"lg"}
        bodyComponent={<CreateNewGroup />}
      />
    </>
  );
};
export default GroupDetailPage;
