import React from "react";
import {
  BinocularsLight,
  ShareIcon,
  RecycleBin,
  ApproveCheckMarkIcon,
  MoreIcon,
} from "../constants/svg";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Space, Dropdown } from "antd";

const getCandidateReferralColumn = (
  setShowModal,
  setDeleteIdForCandidateReferral,
  handleSetApproveCandidateData,
  handleSelectedData,
) => {
  return [
    {
      title: "Candidate",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <div>
          <p>
            <strong>{record.name}</strong>
          </p>
          <p className="subline">
            <span>From:</span> &nbsp;
            <NavLink
              to={`/team/members/${record?.user?.slug ?? record?.user?.id}`}
              className={"black"}
            >
              <span className="blue-accent">{record?.user?.name || "-"}</span>
            </NavLink>
          </p>
        </div>
      ),
      width: "200px",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter: (a, b) => a?.position?.name.localeCompare(b?.position?.name),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record?.position?.name || "-"}</p>,
      align: "left",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      sorter: (a, b) =>
        parseInt(a.experience.charAt(0)) - parseInt(b.experience.charAt(0)),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.experience || "-"}</p>,
      align: "left",
    },
    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
      sorter: (a, b) => a.relation.localeCompare(b.relation),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.relation || "-"}</p>,
      align: "left",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => <p>{record.phone || "-"}</p>,
      align: "left",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: uuidv4(),
                  className: `${record.statusName == "Pending" ? "" : "d-none"}`,
                  label: (
                    <>
                      <div
                        className={"black"}
                        onClick={() => {
                          handleSetApproveCandidateData(record);
                        }}
                      >
                        <ApproveCheckMarkIcon />
                        Approve
                      </div>
                    </>
                  ),
                },

                {
                  key: uuidv4(),
                  label: (
                    <>
                      <div
                        onClick={() => {
                          handleSelectedData(record);
                          setShowModal((prevState) => ({
                            ...prevState,
                            shareModal: true,
                          }));
                        }}
                      >
                        <ShareIcon />
                        Share
                      </div>
                    </>
                  ),
                },
                {
                  key: uuidv4(),
                  label: (
                    <div
                      className={"deleteLabel"}
                      onClick={() => {
                        handleSelectedData(record);
                        setDeleteIdForCandidateReferral(record.id);
                        setShowModal((prevState) => ({
                          ...prevState,
                          deleteModal: true,
                        }));
                      }}
                    >
                      <RecycleBin />
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ];
};

export default getCandidateReferralColumn;
