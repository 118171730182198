import React from "react";
import { Button, Modal } from "react-bootstrap";
import { RedAlertIcon, BlackCrossIcon } from "../../constants/svg";

const DeleteModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-modal"
      >
        {props?.headerCloseBtn && (
          <Modal.Header>
            <div
              className="closeBtn"
              onClick={props.onHide}
              data-bs-dismiss="modal"
            >
              <BlackCrossIcon />
            </div>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="leaveModalIcon">
            {props.icon ? props.icon : <RedAlertIcon />}
          </div>
          <h3>{props.heading}</h3>
          <p>{props.para}</p>

          <div className="btn-div">
            <Button
              className="solid-grey-sm-btn me-2"
              data-dismiss="modal"
              onClick={props.onHide}
            >
              {props.cancelText ? props.cancelText : "Cancel"}
            </Button>
            <Button
              disabled={props?.disable}
              className="solid-red-sm-btn"
              data-dismiss="modal"
              onClick={props?.handleDelete}
            >
              {props?.disable ? (
                <div className="load black" />
              ) : props.deleteText ? (
                props.deleteText
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
