import { Button } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";
import { LimitCrossIcon } from "../../constants/svg";
import { Assets } from "../../constants/images";
import { useNavigate } from "react-router-dom";

const StorageLimitModal = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="mainUserLimit"
        dialogClassName="subMainUserList userListModal"
      >
        <Modal.Body>
          <div
            className="headerCrossIcon cursor-pointer"
            onClick={props.onHide}
          >
            <LimitCrossIcon />
          </div>
          <div className="subModalContent">
            <img
              src={Assets.UploadClouldImg}
              alt="rocket"
              className="img-fluid uploadCloud"
            />
            <h3>Upgrade Storage</h3>
            <p>
              It looks like you've reached you free storage limit. To continue
              using Salon Symphony, you can remove old files or buy some more
              storage.
            </p>
            <div className="buttonDiv">
              {!props.suggestions ? (
                <Button
                  className="greyTransBg"
                  onClick={() => {
                    props.onHide();
                    navigate("/settings/uploads");
                  }}
                >
                  Manage Files
                </Button>
              ) : null}
              <Button
                className={"btn-gradient btn-gradient--hover"}
                onClick={() => {
                  props.onHide();
                  navigate("/settings/storage");
                }}
              >
                Get Storage
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StorageLimitModal;
