import { createSlice } from "@reduxjs/toolkit";

export const surveySlice = createSlice({
  name: "survey",
  initialState: {
    loading: false,
    survey: null,
    surveyTableData: [],
    selectedData: null,
    page: 1,
    perPage: 10,
    isPaginate: 1,
    reRenderTable: 1,
    reRenderSurveyBuilder: 1,
    actionButtonDisable: false,
    singleSurveyDetails: null,
    reRenderSurveyDetailPage: 1,
    pageLoading: false,
    uploadFileTabNo: 1,
    surveyAddEditForm: {
      show: false,
      formType: null,
    },
    filters: {
      category_id: 0,
    },
    sorting: {
      column: "created_at",
      sortBy: "desc",
    },
  },
  reducers: {
    setReRenderSurveyDetailPage: (state, action) => {
      state.reRenderSurveyDetailPage = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSurvey: (state, action) => {
      state.survey = action.payload;
    },
    setSurveyTableData: (state, action) => {
      state.surveyTableData = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setIsPaginate: (state, action) => {
      state.isPaginate = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setSingleSurveyDetails: (state, action) => {
      state.singleSurveyDetails = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setUploadFileTabNo: (state, action) => {
      state.uploadFileTabNo = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setSurveyAddEditForm: (state, action) => {
      state.surveyAddEditForm = action.payload;
    },
    setReRenderSurveyBuilder: (state, action) => {
      state.reRenderSurveyBuilder = action.payload;
    },
  },
});

export const {
  setLoading,
  setSurvey,
  setPage,
  setPerPage,
  setIsPaginate,
  setSurveyTableData,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setSingleSurveyDetails,
  setPageLoading,
  setUploadFileTabNo,
  setFilters,
  setSelectedData,
  setSurveyAddEditForm,
  setReRenderSurveyDetailPage,
  setReRenderSurveyBuilder,
} = surveySlice.actions;

export default surveySlice.reducer;
