import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  BlackCrossIcon,
  DecrementIcon,
  IncrementIcon,
} from "../../../constants/svg";
import { UpdateSalonAchievement } from "../../../services/team/rewards";
import { toastMessage } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setRender } from "../../../store/slices/rewardSlice";

const UpdatePoint = ({ show, onHide, selectedPoint }) => {
  const { render } = useSelector((state) => state.reward);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);
  const [selectedPointCurr, setSelectedPointCurr] = useState(null);
  const [points, setPoints] = useState();
  const [showIncButton, setShowIncButton] = useState(1);
  const [showButtonArrow, setShowButtonArrow] = useState(true);
  const [showDecButton, setShowDecButton] = useState(true);
  const [btnValue, setButtonValue] = useState();

  const onSubmit = async (inputFields) => {
    try {
      const payload = { points: inputFields.points };
      setDisable(true);
      const response = await UpdateSalonAchievement(
        selectedPointCurr.id,
        payload,
      );
      const { data, message, status } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      onHide();
      dispatch(setRender(render + 1));
      toastMessage(
        "success",
        <span>
          <strong>Points</strong> updated!
        </span>,
      );
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setDisable(false);
    }
  };

  const handleIncrement = () => {
    let pointsValue = getValues("points");
    if (pointsValue >= 999) {
      return false;
    }
    setButtonValue(parseInt(getValues("points")) + 1);
    setValue("points", parseInt(getValues("points")) + 1);
  };

  const handleDecrement = () => {
    let pointsValue = getValues("points");
    if (pointsValue <= 0) {
      return false;
    }
    setButtonValue(parseInt(getValues("points")) - 1);
    setValue("points", parseInt(getValues("points")) - 1);
  };

  useEffect(() => {
    setShowButtonArrow(false);
    setShowDecButton(false);

    if (btnValue >= 999) {
      setShowButtonArrow(true);
    }
    if (btnValue <= 0) {
      setShowDecButton(true);
    }
    if (btnValue == 0) {
      setShowDecButton(true);
    }
  }, [btnValue]);

  useEffect(() => {
    if (selectedPoint) {
      setValue("points", selectedPoint.points ? selectedPoint.points : 0);
    }
    setSelectedPointCurr(selectedPoint);
  }, [selectedPoint]);

  // const handleInputChange = (e) => {
  //     let inputValue = e.target.value;

  //     // Allow only digits (whole numbers) and prevent exponential notation
  //     inputValue = inputValue.replace(/[^\d]/g, '');
  //     setValue('points', inputValue);
  // };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    // Allow only digits (whole numbers) and prevent exponential notation
    inputValue = inputValue.replace(/[^\d]/g, "");
    // If user types '0', show decimal button
    if (inputValue == "0") {
      setShowDecButton(true);
    } else {
      setShowDecButton(false);
    }

    if (inputValue == "999") {
      setShowButtonArrow(true);
    } else {
      setShowButtonArrow(false);
    }

    // Limit the input to a maximum of 3 characters
    if (inputValue.length <= 3) {
      setValue("points", inputValue);

      // Set the state to control the visibility of buttons
      setPoints(inputValue.length);
    } else {
      // Set the state to hide buttons when the input length exceeds 3
      setPoints(3);
    }
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-point-group"
      >
        <Modal.Header>
          <div className="closeBtn" onClick={onHide} data-bs-dismiss="modal">
            <BlackCrossIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>Update Reward Points</h4>
          <p className="text-point">
            Change the total points issued for this achievement.
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <Row>
                <Col lg={8}>
                  <div className="main-point-div">
                    <div className="increment-div">
                      <span>Points</span>
                      <div className={`${"inc-btns"}`}>
                        <div
                          onClick={handleIncrement}
                          className={`${showButtonArrow ? "disabled" : null}`}
                        >
                          <IncrementIcon />
                        </div>
                        <div
                          onClick={handleDecrement}
                          className={`${showDecButton ? "disabled" : null}`}
                        >
                          <DecrementIcon />
                        </div>
                      </div>
                    </div>
                    <FloatingLabel
                      controlId="points"
                      label="Points"
                      className=""
                    >
                      <Controller
                        name="points"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            type="text"
                            placeholder="Points"
                            autoComplete="off"
                            role="presentation"
                            className={errors.points ? "hasError" : ""}
                            {...field}
                            onChange={handleInputChange}
                          />
                        )}
                      />
                    </FloatingLabel>
                  </div>
                </Col>
                <Col lg={4}>
                  <Button
                    className="solid-gotit-grey-btn give-points"
                    type="submit"
                    disabled={disable}
                  >
                    {disable && <div className="load black" />}
                    {!disable && "Update Points"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatePoint;
