import { notification, Select, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import StatusModal from "../../components/Modals/StatusModal";
import { Assets } from "../../constants/images";
import {
  CheckMarkIcon,
  DownloadIcon,
  PurpleBackIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  UpDpwnArrow,
} from "../../constants/svg";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  GetRequests,
  GetSingleRequest,
  UpdateRequest,
} from "../../services/requests/requests";
import {
  setIsRequestHistoryLoading,
  setIsSingleRequestLoading,
  setRequestHistory,
  setSingleRequest,
} from "../../store/slices/requestsSlice";
import {
  PAYMENT_METHOD,
  tableHeight,
  user_requests,
  user_requests_status,
} from "../../utils/constants";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import AvatarImage from "../../components/Common/AvatarImage";

const menuOption = [
  {
    name: "Pending",
    value: "Pending",
  },
  {
    name: "Approve",
    value: "Approved",
  },
  {
    name: "Decline",
    value: "Declined",
  },
];

const RequestDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    singleRequest,
    requestHistory,
    isSingleRequestLoading,
    isRequestHistoryLoading,
  } = useSelector((state) => state?.requests);
  const { currentSalonId } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();
  const [disable, setDisable] = useState(false);
  const [onUpdate, setOnUpdate] = useState(1);
  const [selectedValue, setSelectedValue] = useState(menuOption[0].name);
  const [statusModal, setStatusModal] = useState({
    showModal: false,
    option: "",
  });
  const value = statusModal.option;
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    if (value == "Approve" || value == "Decline") {
      setStatusModal({ showModal: true, option: value });
    } else {
      setSelectedValue(value);
    }
  };

  const onApprove = async (msg) => {
    try {
      const data = {
        status: user_requests_status?.accepted,
        salon_id: currentSalonId,
        approved_by: authUser?.id,
        type: singleRequest?.data?.type,
        sub_type: singleRequest?.data?.sub_type,
        note: msg,
      };
      setDisable(true);
      const res = await UpdateRequest(id, data);
      setDisable(false);
      setStatusModal({ ...statusModal, showModal: false });
      setOnUpdate(onUpdate + 1);
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      setDisable(false);
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const onDecline = async (msg) => {
    try {
      const data = {
        status: user_requests_status?.declined,
        salon_id: currentSalonId,
        approved_by: authUser?.id,
        type: singleRequest?.data?.type,
        sub_type: singleRequest?.data?.sub_type,
        note: msg,
      };
      setDisable(true);
      const res = await UpdateRequest(id, data);
      setDisable(false);
      setStatusModal({ ...statusModal, showModal: false });
      setOnUpdate(onUpdate + 1);
      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      setDisable(false);
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const handleStatusUpdate = async (msg = null) => {
    if (statusModal.option == "Approve") {
      await onApprove(msg);
    } else if (statusModal.option == "Decline") {
      await onDecline(msg);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Reason",
      dataIndex: "alertTitle",
      key: "alertTitle",

      render: (text, record) => <span>{record.alertTitle}</span>,
    },
    {
      title: "Decision",
      dataIndex: "decision",
      key: "decision",

      render: (text, record) => <p>{record.decision}</p>,
      align: "left",
      width: 150,
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",

      render: (text, record) => <p>{record.admin}</p>,
      align: "left",
      width: 150,
    },
    {
      title: "Updated",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return (
          <div className="">
            <span>{record.date}</span>
          </div>
        );
      },
      align: "center",
      width: 150,
    },
  ];
  const getSingleRequest = async () => {
    dispatch(setIsSingleRequestLoading(true));
    try {
      const res = await GetSingleRequest(id);
      dispatch(setSingleRequest(res.data.data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setIsSingleRequestLoading(false));
    }
  };
  useEffect(() => {
    getSingleRequest();
  }, [id, onUpdate]);

  const getRequestHistory = async () => {
    dispatch(setIsRequestHistoryLoading(true));
    try {
      const params = {
        is_paginated: 1,
        type: singleRequest?.data?.type,
        salon_id: singleRequest?.data?.user?.salon?.id,
        user_id: singleRequest?.data?.user?.id,
      };
      const res = await GetRequests(params);
      let data = res.data.data.data?.map((item) => {
        return {
          key: item?.id,
          alertTitle:
            item?.type == user_requests.reimbursement
              ? item?.typeName
              : item?.subTypeName || "-",
          decision:
            item?.status == 10
              ? "Pending"
              : item?.status == 20
                ? "Approved"
                : "Declined",
          admin: item?.approved?.name || "-",
          date: item?.updated_at
            ? moment(item?.updated_at).format("MMM DD, yyyy")
            : "-",
        };
      });
      dispatch(setRequestHistory({ meta: res.data.data?.meta, data }));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setIsRequestHistoryLoading(false));
    }
  };

  useEffect(() => {
    if (singleRequest?.data) {
      getRequestHistory();
    }
  }, [singleRequest?.data, onUpdate]);

  const loc = useLocation();
  const requestTab = loc.search;
  const handleDownloadClick = (url, name, type) => {
    // Get the image URL from the Assets object
    const imageUrl = Assets.AccountSetupImage;

    // Create a virtual anchor element
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${name}.${type}`; // Set a desired download filename

    // Trigger a click event on the anchor element
    anchor.click();

    // Clean up the anchor element
    URL.revokeObjectURL(anchor.href);
  };

  if (isSingleRequestLoading || isSingleRequestLoading) {
    return <LoadingScreen child={true} />;
  }
  return (
    <>
      <div className="main-request-detail h-100">
        <Row className="h-100">
          <Col lg={4} className="pe-0">
            <div className="main-left-div h-100">
              <div className="backToEvent">
                <NavLink to="/requests">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Requests</span>
                </NavLink>
              </div>
              <div className="request-user">
                <div className="avatar-div">
                  <AvatarImage
                    name={singleRequest?.data?.user?.name}
                    src={singleRequest?.data?.user?.image}
                    placeholder={Assets.ProfilePlaceholder}
                  />
                  {/* <img
                    src={
                      singleRequest?.data?.user?.image ||
                      Assets.ProfilePlaceholder
                    }
                    onError={(e) => (e.target.src = Assets.ProfilePlaceholder)}
                    alt="avatar"
                  /> */}
                </div>
                <h2>{singleRequest?.data?.user?.name || "-"}</h2>
              </div>
              <div className="user-details">
                {(singleRequest?.data?.type == user_requests.timeoff ||
                  singleRequest?.data?.type == user_requests.schedule ||
                  singleRequest?.data?.type == user_requests.reimbursement) && (
                  <div className="sub-div">
                    <h6>Sent</h6>
                    <p>
                      {singleRequest?.data?.created_at
                        ? moment(singleRequest?.data?.created_at).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.hours && (
                  <div className="sub-div">
                    <h6>Sent On</h6>
                    <p>
                      {singleRequest?.data?.created_at
                        ? moment(singleRequest?.data?.created_at).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {(singleRequest?.data?.type == user_requests.schedule ||
                  singleRequest?.data?.type == user_requests.hours) && (
                  <div className="sub-div">
                    <h6>Request</h6>
                    <p>{singleRequest?.data?.subTypeName}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="sub-div">
                    <h6>Request</h6>
                    <p>{singleRequest?.data?.typeName}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="sub-div">
                    <h6>Total Spent</h6>
                    <p>${singleRequest?.data?.value ?? "-"}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="sub-div">
                    <h6>Date Spent</h6>
                    <p>
                      {singleRequest?.data?.date
                        ? moment(singleRequest?.data?.date).format(
                            "MMM DD, YYYY",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.hours && (
                  <div className="sub-div">
                    <h6>Total Hours</h6>
                    <p>{singleRequest?.data?.hour}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.hours && (
                  <div className="sub-div">
                    <h6>Class Date</h6>
                    <p>
                      {singleRequest?.data?.date
                        ? moment(singleRequest?.data?.date).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.schedule && (
                  <div className="sub-div">
                    <h6>Current</h6>
                    <p>
                      {singleRequest?.data?.dayName &&
                      singleRequest?.data?.preAvailable
                        ? singleRequest?.data?.dayName +
                          ", " +
                          singleRequest?.data?.preAvailable
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.schedule && (
                  <div className="sub-div">
                    <h6>Desired</h6>
                    <p>
                      {singleRequest?.data?.dayName &&
                      singleRequest?.data?.available
                        ? singleRequest?.data?.dayName +
                          ", " +
                          singleRequest?.data?.available
                        : "-"}
                    </p>
                    {/* <p>
                      {singleRequest?.data?.dayName}, &nbsp;
                      {(singleRequest?.data?.available)}
                    </p> */}
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.timeoff && (
                  <div className="sub-div">
                    <h6>Reason</h6>
                    <p>{singleRequest?.data?.subTypeName}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.timeoff && (
                  <div className="sub-div">
                    <h6>Leave</h6>
                    <p>
                      {singleRequest?.data?.date
                        ? moment(singleRequest?.data?.date).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.timeoff && (
                  <div className="sub-div">
                    <h6>Return</h6>
                    <p>
                      {singleRequest?.data?.return_date
                        ? moment(singleRequest?.data?.return_date).format(
                            "MMM DD, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.timeoff && (
                  <div className="sub-div">
                    <h6>Coverage</h6>
                    <p>
                      {singleRequest?.data?.coverage
                        ? singleRequest?.data?.coverage?.name
                        : "-"}
                    </p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="sub-div">
                    <h6>Purpose</h6>
                    <p>{singleRequest?.data?.subTypeName ?? "-"}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.hours && (
                  <div className="sub-div">
                    <h6>Educator</h6>
                    <p>{singleRequest?.data?.educator ?? "-"}</p>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="sub-div">
                    <h6>Payment</h6>
                    <p>
                      {PAYMENT_METHOD[singleRequest?.data?.payment_method] ??
                        "-"}
                    </p>
                  </div>
                )}

                <div className="sub-div">
                  <h6>Status</h6>
                  <Select
                    value={
                      singleRequest?.data?.status ==
                      user_requests_status?.accepted
                        ? "Approve"
                        : singleRequest?.data?.status ==
                            user_requests_status?.pending
                          ? "Pending"
                          : "Decline"
                    }
                    suffixIcon={<UpDpwnArrow />}
                    menuItemSelectedIcon={<CheckMarkIcon />}
                    onChange={handleChange}
                    // open={true}
                    popupClassName="statusDropdown main-select me-3"
                    className="custom-select-box statusDropdown req-detail"
                    options={menuOption.map((item) => ({
                      label: (
                        <>
                          <div className="d-flex align-items-center">
                            <span className={`statusBall ${item.name}`}></span>{" "}
                            {item.value}
                          </div>
                        </>
                      ),
                      value: item.name,
                      disabled: !!(
                        (singleRequest?.data?.status ==
                          user_requests_status?.accepted &&
                          item.value != "Approved") ||
                        (singleRequest?.data?.status ==
                          user_requests_status?.declined &&
                          item.value != "Declined")
                      ),
                    }))}
                  ></Select>
                </div>
              </div>

              {singleRequest?.data?.type == user_requests.hours && (
                <div className="main-note">
                  <h6>Class Name</h6>
                  <p>{singleRequest?.data?.class ?? "-"}</p>
                </div>
              )}

              {singleRequest?.data?.type == user_requests.hours && (
                <div className="main-note">
                  <h6>Comments</h6>
                  <p>{singleRequest?.data?.comments ?? "-"}</p>
                </div>
              )}

              {(singleRequest?.data?.type == user_requests.timeoff ||
                singleRequest?.data?.type == user_requests.schedule ||
                singleRequest?.data?.type == user_requests.reimbursement) && (
                <div className="main-note">
                  <h6>Staff Note</h6>
                  <p>{singleRequest?.data?.comments ?? "-"}</p>
                </div>
              )}
              {(singleRequest?.data?.type == user_requests.timeoff ||
                singleRequest?.data?.type == user_requests.schedule ||
                singleRequest?.data?.type == user_requests.reimbursement) && (
                <div className="main-note">
                  <h6>Admin Note</h6>
                  <p>{singleRequest?.data?.note ?? "-"}</p>
                </div>
              )}

              <div>
                {singleRequest?.data?.type == user_requests.reimbursement && (
                  <div className="main-note">
                    <h6>Receipt</h6>
                    <div
                      className="downloaddiv cursor-pointer"
                      onClick={handleDownloadClick}
                    >
                      <span>-</span>
                      {/* <DownloadIcon /> */}
                    </div>
                  </div>
                )}

                {singleRequest?.data?.type == user_requests.hours && (
                  <div className="main-note">
                    <h6>Certificate</h6>
                    {singleRequest?.data?.attachment ? (
                      <div
                        className="downloaddiv cursor-pointer"
                        onClick={() =>
                          handleDownloadClick(
                            singleRequest?.data?.attachment,
                            "certificate",
                            "pdf",
                          )
                        }
                      >
                        {/* <span>-</span> */}
                        <DownloadIcon />
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col lg={8} className="ps-0">
            <div className="main-border-div h-100">
              <div className="main-right-div h-100">
                <div className="request-detail-header">
                  <h2>Request History</h2>
                  {/* <div className="print-div">
                    <PrinterIcon />
                    <span>Print</span>
                  </div> */}
                </div>
                <Table
                  columns={columns}
                  dataSource={requestHistory?.data}
                  bordered={false}
                  pagination={false}
                  scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
                  responsive={true}
                  size={"large"}
                  showSorterTooltip={false}
                  className="req-detail-table"
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* Status Modal */}
        <StatusModal
          show={statusModal.showModal}
          size={"lg"}
          onHide={() => setStatusModal({ ...statusModal, showModal: false })}
          modalTitle={
            statusModal.option == "Approve"
              ? "Approve Request?"
              : "Decline Request?"
          }
          modalSubtitle={
            <>
              {statusModal.option} the{" "}
              <b>
                {singleRequest?.data?.subTypeName == "Change Days"
                  ? "Schedule Change"
                  : singleRequest?.data?.subTypeName || "-"}
              </b>{" "}
              time requested by <b>{singleRequest?.data?.user?.name || "-"}</b>?
            </>
          }
          modalButtonClass={
            statusModal.option == "Approve"
              ? "solid-approve-green-btn"
              : "solid-red-sm-btn"
          }
          modalButtonText={
            statusModal.option == "Approve" ? "Approve" : "Decline"
          }
          onStatusUpdate={handleStatusUpdate}
          disable={disable}
        />
      </div>
    </>
  );
};

export default RequestDetail;
