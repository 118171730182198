import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
  GoalAciveBlack,
  GoalActivePurple,
  GoalTropgyFilled,
  GoalTrophyLight,
  GoalTrophyMissed,
  GoalTrophyMissedFilled,
} from "../../../constants/svg";
import {
  setEmptyAllGoals,
  setIsAchievedGoalsLoading,
  setIsActiveGoalsLoading,
  setIsMissedGoalsLoading,
} from "../../../store/slices/goalsSlice";
import { goal_type_array, roles as salonRoles } from "../../../utils/constants";
import GenericModal from "../../Modals/GenericModal";
import GoalModalForms from "./Modals/GoalModalForms";
import { FormProvider, useForm } from "react-hook-form";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";

const GoalsHeader = (props) => {
  const [selectedCard, setSelectedCard] = useState("Create New Goal");
  const [stepStatus, setStepStatus] = useState();
  const [showGenericModal, setShowGenericModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { activeGoals, achievedGoals, missedGoals } = useSelector(
    (state) => state.goals,
  );
  const { allSalons, currentSalonId } = useSelector((state) => state.global);

  const methods = useForm();

  const handleTabClick = (tabName) => {
    props.setActiveTab(tabName);
  };

  const handleChange = (value = "") => {
    props?.handleResetPage();
    props?.setSelectedGoalType(value);
    dispatch(setEmptyAllGoals());
    dispatch(setIsActiveGoalsLoading(true));
    dispatch(setIsAchievedGoalsLoading(true));
    dispatch(setIsMissedGoalsLoading(true));
  };

  useEffect(() => {
    handleChange();
  }, [currentSalonId]);

  useEffect(() => {
    if (location.state) {
      handleTabClick(location.state.activeTab);
    }
  }, [location]);

  return (
    <>
      <div className="globalHeader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Goals</h2>
          <div className="customTablist">
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab1" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab1")}
              >
                <div className="icon icon-active">
                  <GoalActivePurple />
                </div>
                <div className="icon">
                  <GoalAciveBlack />
                </div>
                Active ({activeGoals?.meta?.total || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab2" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab2")}
              >
                <div className="icon icon-active">
                  <GoalTropgyFilled />
                </div>
                <div className="icon">
                  <GoalTrophyLight />
                </div>
                Achieved ({achievedGoals?.meta?.total || 0})
              </div>
            </div>
            <div className="customTabItem">
              <div
                className={props.activeTab == "tab3" ? "tab active" : "tab"}
                onClick={() => handleTabClick("tab3")}
              >
                <div className="icon icon-active">
                  <GoalTrophyMissedFilled />
                </div>
                <div className="icon">
                  <GoalTrophyMissed />
                </div>
                Missed ({missedGoals?.meta?.total || 0})
              </div>
            </div>
          </div>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              value={currentSalonId || undefined}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue={"All Targets"}
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            onChange={(e) => handleChange(e)}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={goal_type_array}
            // popupMatchSelectWidth={true}
          />
          <button
            className="btn-gradient"
            onClick={() => setShowGenericModal(true)}
          >
            <span>
              <CreateIcon />
            </span>
            Create Goal
          </button>
        </div>
      </div>
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={selectedCard}
        mt10="custom-mt-10"
        size={"lg"}
        goal={true}
        customModalHeader={"customGoalModalHeader"}
        bodyComponent={
          <FormProvider {...methods}>
            <GoalModalForms
              show={showGenericModal}
              setModalShow={setShowGenericModal}
              setStepStatus={setStepStatus}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              methods={methods}
              isedit={false}
            />
          </FormProvider>
        }
      />
    </>
  );
};

export default GoalsHeader;
