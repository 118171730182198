import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetCurrentSubscription = (salonId) =>
  api.get(apiUrl.current_subscription + "/" + salonId);
export const GetPackagesList = (params) =>
  api.get(apiUrl.billing_plans, { params });
export const CreatePaymentLink = (packageId) =>
  api.get(apiUrl.payment_link + "/" + packageId);
export const CancelSubscription = (subscriptionId) =>
  api.delete(apiUrl.cancel_subscription + "/" + subscriptionId);
