import { notification, Space } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import FolderResourceUploadModal from "../../components/Modals/Uploads/FolderResourceUploadModal";
import { Assets } from "../../constants/images";
import ShareUploadMediaModal from "../../components/Modals/ShareUploadMediaModal";
import {
  BlackSolidPDFIcon,
  NewAudioIcon,
  NewImageIcon,
  NewVideoIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
  UploadNewIcon,
} from "../../constants/svg";
import {
  DeleteSingleGallery,
  GetSingleGallery,
} from "../../services/galleries/galleries";
import {
  setFolderId,
  setIsSingleGalleryLoading,
  setSingleGallery,
  setUploadType,
} from "../../store/slices/gallerysSlice";
import { gallery_type, SHARE_URL } from "../../utils/constants";
import {
  getUloadTypeStringLiteral,
  hasAccess,
  toastMessage,
} from "../../utils/helper";
import FolderDataTable from "./ProfileForms/FolderDataTable";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

const GalleryDetailPage = () => {
  const { uploadType, galleryAddEditForm } = useSelector(
    (state) => state?.gallerys,
  );

  const [selectedData, setSelectedData] = useState(null);
  const [showGenericModalTwo, setShowGenericModalTwo] = useState(false);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [disable, setDisable] = useState(false);
  const { id } = useParams();
  const link = SHARE_URL.GALLERY + id;
  const { singleGallery, renderList } = useSelector((state) => state?.gallerys);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const dropdownButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSetSelectedData = () => {
    setSelectedData({
      key: id,
      name: singleGallery?.data?.name,
      description: null,
      image: singleGallery?.data?.link,
      start_date: singleGallery?.data?.created_at
        ? moment(singleGallery?.data?.created_at).format("MMM DD, yyyy")
        : "-",
      start_time: singleGallery?.data?.created_at
        ? moment(singleGallery?.data?.created_at).format("hh:mm a")
        : "-",
    });
  };

  const handleDelete = async () => {
    try {
      setDisable(true);
      const res = await DeleteSingleGallery(id);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      // Remove Due to Content Not Error on Deleting Resource
      // await getSingleGallery()
      setDisable(false);
      navigate("/settings/uploads/");
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  const getSingleGallery = async () => {
    try {
      dispatch(setIsSingleGalleryLoading(true));
      const res = await GetSingleGallery(id);
      let data = res?.data?.data;
      data.resources?.forEach((resource) => {
        resource.size = resource.size ? resource.size : "-";
        if (resource.type == gallery_type.pdf) {
          resource.type = "PDF";
        } else if (resource.type == gallery_type.video) {
          resource.type = "Video";
        } else if (resource.type == gallery_type.audio) {
          resource.type = "Audio";
        } else if (resource.type == gallery_type.folder) {
          resource.type = "Folder";
        } else if (resource.type == gallery_type.image) {
          resource.type = "Image";
        }
      });

      dispatch(setSingleGallery(data));
      dispatch(setIsSingleGalleryLoading(false));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsSingleGalleryLoading(true));
    }
  };

  const toggleDropdown = () => {
    dispatch(setFolderId(singleGallery?.data?.id));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getSingleGallery();
  }, [id]);

  const refreshDataFunc = (data) => {
    if (data == true) {
      getSingleGallery();
    }
  };

  const handleItemClick = (uploadType) => {
    setIsOpen(false);
    setShowGenericModalTwo(true);
    dispatch(setUploadType(uploadType));
  };

  const getUploadModalTitle = (isAddEdit) => {
    let uploadModalTitle;

    switch (uploadType) {
      case gallery_type.image:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Image" : "Update Image";
        break;

      case gallery_type.video:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Video" : "Update Video";
        break;

      case gallery_type.audio:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New Audio" : "Update Audio";
        break;

      case gallery_type.folder:
        uploadModalTitle =
          isAddEdit == "create" ? "Create New Folder" : "Rename Folder";
        break;

      case gallery_type.pdf:
        uploadModalTitle =
          isAddEdit == "create" ? "Upload New PDF" : "Update PDF";
        break;
    }
    return uploadModalTitle;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click occurred outside of the picker, so close it
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  return (
    <>
      {singleGallery.isSingleGalleryLoading ? (
        <LoadingScreen child={true} />
      ) : (
        <Row>
          <Col lg={12} className={"pe-0"}>
            <div className="leftEventDetailWrapper">
              <div className="eventDetailHeader innerContainer pLRLess d-flex justify-content-between">
                <div className="backToEvent">
                  <NavLink to="/settings/uploads">
                    <PurpleBackIcon />
                    <span className="uLineHover">Back to Upload</span>
                  </NavLink>
                </div>
                <div className="shareButton">
                  <Space size={"large"}>
                    {singleGallery?.data?.type == gallery_type.folder &&
                    hasAccess(singleGallery?.data?.user) ? (
                      // <div
                      //     className="icon-text-wrapper cursor-pointer blackLine"
                      //     onClick={() => {
                      //         dispatch(setFolderId(singleGallery?.data?.id))
                      //         setShowGenericModalTwo(true)
                      //     }}
                      // >
                      //     <UploadIcons />
                      //     <p className="uLine ">Add Media</p>
                      // </div>
                      <div className="globalHeader--right position-realtive">
                        <button
                          className="btn-gradient"
                          onClick={() => toggleDropdown()}
                          ref={dropdownButtonRef}
                        >
                          <span>
                            <UploadNewIcon />
                          </span>
                          Add Media
                        </button>

                        {isOpen && (
                          <div
                            className="custom-dropdown folder-media-upload"
                            ref={dropdownRef}
                          >
                            <div
                              className="sub-dropdown cursor-pointer"
                              onClick={() =>
                                handleItemClick(gallery_type?.image)
                              }
                            >
                              <NewImageIcon />
                              <span>New Image</span>
                            </div>
                            <div
                              className="sub-dropdown cursor-pointer"
                              onClick={() =>
                                handleItemClick(gallery_type?.video)
                              }
                            >
                              <NewVideoIcon />
                              <span>New Video</span>
                            </div>
                            <div
                              className="sub-dropdown cursor-pointer"
                              onClick={() =>
                                handleItemClick(gallery_type?.audio)
                              }
                            >
                              <NewAudioIcon />
                              <span>New Audio</span>
                            </div>
                            <div
                              className="sub-dropdown cursor-pointer"
                              onClick={() => handleItemClick(gallery_type?.pdf)}
                            >
                              <BlackSolidPDFIcon />
                              <span>New PDF</span>
                            </div>
                            {/* <div className="sub-dropdown cursor-pointer" onClick={() => handleItemClick(gallery_type?.folder)}> */}
                            {/* <NewFolderIcon /> */}
                            {/* <span>New Folder</span> */}
                            {/* </div> */}
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={() => {
                        handleSetSelectedData();
                        setShowModal((prevState) => ({
                          ...prevState,
                          shareModal: true,
                        }));
                      }}
                    >
                      <ShareIcon />
                      <p className="uLine ">Share</p>
                    </div>
                    {hasAccess(singleGallery?.data?.user) && (
                      <div
                        className="icon-text-wrapper deleteLabel cursor-pointer"
                        onClick={() =>
                          setShowModal((prevState) => ({
                            ...prevState,
                            deleteModal: true,
                          }))
                        }
                      >
                        <RecycleBin />
                        <p className="uLine redLine">Delete</p>
                      </div>
                    )}
                  </Space>
                </div>
              </div>
              <div className="eventDetailBody custom-table galleryDetailPage">
                <div className="innerContainer pLessContainer">
                  <h3 className="sec-heading">
                    {singleGallery?.data?.name || "-"}
                  </h3>
                  <div className="eventDetailInformation">
                    {singleGallery?.data?.type != gallery_type.folder && (
                      <div className="eventDetailItem">
                        <h5 className="head">Link</h5>
                        <a
                          href={singleGallery?.data?.link || "#"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {singleGallery?.data?.link || "-"}
                        </a>
                      </div>
                    )}

                    {singleGallery?.data?.type != gallery_type.folder && (
                      <div className="eventDetailItem">
                        <h5 className="head">Size</h5>
                        <p>{singleGallery?.data?.size || "-"}</p>
                      </div>
                    )}

                    <div className="eventDetailItem">
                      <h5 className="head">Type</h5>
                      <p>
                        {getUloadTypeStringLiteral(singleGallery?.data?.type)}
                      </p>
                    </div>

                    <div className="eventDetailItem">
                      <h5 className="head">Created By</h5>
                      <p>{singleGallery?.data?.user?.name || "-"}</p>
                    </div>
                    <div className="eventDetailItem">
                      <h5 className="head">Created On</h5>
                      <p>
                        {moment(singleGallery?.data?.created_at).format(
                          "MMM DD, YYYY",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {singleGallery?.data?.type == gallery_type.folder && (
                  <Row>
                    <Col lg={12}>
                      <FolderDataTable
                        data={singleGallery?.data?.resources}
                        refreshData={refreshDataFunc}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}

      {/* Share Modal */}
      <ShareUploadMediaModal
        shareLink={link}
        data={{
          type: singleGallery?.data?.type,
          image: singleGallery?.data?.link,
          name: singleGallery?.data?.name,
          description: singleGallery?.data?.description,
          start_date: singleGallery?.data?.created_at,
          start_time: moment(singleGallery?.data?.created_at).format("hh:mm a"),
        }}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Media"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this event with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "How to Use Big Blowout Product Guide",
          para1:
            "Big Blowout is a unique jelly serum that melts into hair to provide instant volume with a soft, silky finish. This lightweight formula leaves hair naturally bouncy with no crunch while protecting from heat up to 450°F/230°C.",
          para2: "PDF • Mar 03, 2023",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Media?"
        handleDelete={handleDelete}
        disable={disable}
        para={
          <>
            Are you sure you want to delete
            <b> {singleGallery?.data?.name || "this media"} ?</b>
            <br />
            Important: This Media cannot be recovered.
          </>
        }
      />

      <GenericModal
        show={showGenericModalTwo}
        onHide={() => {
          dispatch(setFolderId(null));
          setShowGenericModalTwo(false);
        }}
        setModalShow={(v) => {
          setShowGenericModalTwo(v);
        }}
        modalTitle={getUploadModalTitle("create")}
        desc={true}
        modalDesc={
          "Use unique file names to help you find this resource in the future."
        }
        pro="Pro Tip:"
        size={"lg"}
        bodyComponent={
          <FolderResourceUploadModal
            getSingleGallery={getSingleGallery}
            setModalShow={setShowGenericModalTwo}
          />
        }
      />
    </>
  );
};

export default GalleryDetailPage;
