import React, { useEffect, useState } from "react";
import { notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Assets } from "../../../constants/images";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setAllUsers,
  setIsAllUsersLoading,
} from "../../../store/slices/chatsSlice";
import { GetAllUsers, GetMembers } from "../../../services/team/member";
import { ToastCrossIcon } from "../../../constants/svg";
import Image from "antd/es/image";
import { isUserAlreadyChatWithEachOther } from "../../../services/chats/frontend-chats";
import { roles as salonRoles } from "../../../utils/constants";
import AvatarImage from "../../Common/AvatarImage";
import { sortBy } from "lodash";

const { Option } = Select;

const NewChat = ({ setNewChat, setNewChatDefault }) => {
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [enterPressed, setEnterPressed] = useState(false);
  const { allUsers } = useSelector((state) => state?.chats);
  const { me } = useSelector((state) => state.auth);
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const handleMemberSelect = async (user_id) => {
    if (!enterPressed) {
      setNewChat(false);
      setNewChatDefault && setNewChatDefault(false);
      await isUserAlreadyChatWithEachOther(
        parseInt(authUser?.id),
        parseInt(user_id),
        dispatch,
      );
    } else {
      setSearchTerm("");
    }
  };
  const fetchAllUsers = async () => {
    dispatch(setIsAllUsersLoading(true));
    let response;
    if (GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin) {
      response = await GetAllUsers({
        is_paginated: 0,
        keyword: searchTerm || "",
      });
    } else {
      response = await GetMembers({ keyword: searchTerm });
    }
    const { data, status, message } = response.data;
    if (!status) {
      notification.error({
        description: message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      return;
    }
    dispatch(setIsAllUsersLoading(false));
    let users =
      GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin
        ? data
        : data.users;
    users =
      users.length > 0
        ? users?.filter((item) => {
            return item.id != authUser?.id;
          })
        : users;
    dispatch(setAllUsers(users));
  };

  useEffect(() => {
    if (searchTerm?.length > 2 || searchTerm?.length == 0) {
      fetchAllUsers();
    }
  }, [searchTerm]);

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      setEnterPressed(true);
    } else {
      setEnterPressed(false);
    }
  };
  useEffect(() => {
    const selectElement = document.getElementById("searchSelect");

    if (selectElement) {
      selectElement.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, []);
  const sortedUsers = sortBy(allUsers.data, "name");
  return (
    <div className="chat-window newChat w-100">
      <div className="chat-header">
        <div className="selectMemeber">
          <h4 className="m-0">Send To:</h4>
          <Select
            showSearch
            placeholder="Choose a recipient..."
            optionFilterProp="children"
            value={searchTerm}
            filterOption={false}
            onChange={handleMemberSelect}
            onSearch={(value) => {
              setSearchTerm(value);
            }}
            className="selectMemberDropdown chat-member-select-dropdown"
            popupClassName="newChatDropdown"
            suffixIcon={false}
            id={"searchSelect"}
          >
            {allUsers?.isLoading ? (
              <div className="loader-chat">
                <Image
                  width={100}
                  height={100}
                  src={Assets.loadingGif}
                  preview={false}
                />
              </div>
            ) : (
              sortedUsers.map((member) => {
                return (
                  <Option key={member?.id} value={member.id}>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="title d-flex align-items-center">
                        <AvatarImage
                          name={member?.name}
                          src={member?.image}
                          placeholder={Assets.ProfilePlaceholder}
                        />
                        <span className="mb-0 ms-3">{member?.name}</span>
                      </div>
                      <span className="roundedIcon "></span>
                    </div>
                  </Option>
                );
              })
            )}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default NewChat;
