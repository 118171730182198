import React, { useState } from "react";
import { Input, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Form } from "react-bootstrap";
import { ToastCrossIcon, ToastGreenCheckIcon } from "../../constants/svg";

const CopylinkBar = ({ link, closeAction }) => {
  const [isToastVisible, setToastVisible] = useState(false);

  const copyLinkToClipboard = () => {
    if (!isToastVisible) {
      // Only show the toast if it's not already visible
      navigator.clipboard.writeText(link).then(() => {
        setToastVisible(true); // Set the state to indicate that the toast is now visible

        notification.info({
          message: (
            <span>
              <strong>Link </strong> copied to clipboard!
            </span>
          ),
          placement: "bottomRight",
          className: "createdNotification",
          icon: <ToastGreenCheckIcon />,
          closeIcon: <ToastCrossIcon />,
          onClose: () => setToastVisible(false), // Reset the state when the toast is closed
        });
      });
    }
  };

  return (
    <div className="copy-link-container">
      <Form.Control value={link} readOnly />
      <Button
        className="mb-0"
        size={"lg"}
        type={"submit"}
        onClick={copyLinkToClipboard}
      >
        Copy Link
      </Button>
    </div>
  );
};

export default CopylinkBar;
