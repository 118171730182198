import React, { useState, useEffect } from "react";
import TaskBody from "../../components/pages/Tasks/TaskBody";
import TaskHeader from "../../components/pages/Tasks/TaskHeader";
import { useSelector } from "react-redux";

const TasksPage = () => {
  const [page, setPage] = useState({ currentPage: 1, perPage: 10 });
  const selectedTab = useSelector((state) => state.events.activeTab);
  const [activeTab, setActiveTab] = useState(selectedTab || "tab1");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleResetPage = () => {
    setPage({ currentPage: 1, perPage: 10 });
  };
  useEffect(() => {
    setSelectedCategory(null);
  }, [activeTab]);

  return (
    <>
      <div className="innerContainer">
        <TaskHeader
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleResetPage={handleResetPage}
        />

        <TaskBody
          selectedCategory={selectedCategory}
          page={page}
          setPage={setPage}
          activeTab={activeTab}
        />
      </div>
    </>
  );
};

export default TasksPage;
