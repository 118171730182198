import { ConfigProvider, Table } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import { NoDataIcon } from "../../../constants/svg";
import { setInActiveOragnizationPagination } from "../../../store/slices/organizationSlice";
import DeleteDocModal from "../../Modals/DeleteDocModal";
import ShareModal from "../../Modals/ShareModal";
import { tableLoader } from "../../../utils/helper";
import { tableHeight } from "../../../utils/constants";
import LasyLoadTable from "../../Table/LazyLoadTable";
const Lost = ({ tableColumn }) => {
  const dispatch = useDispatch();
  const {
    inActiveOragnizations,
    inActiveOragnizationPagination,
    tableLoading,
  } = useSelector((state) => state.organization);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  return (
    <>
      <div
        className={
          inActiveOragnizations.data.length == 0 ? "task__container_empty" : ""
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Organizaton you've created will appear here.</h5>
            </div>
          )}
        >
          <LasyLoadTable
            columns={tableColumn}
            loading={tableLoader(tableLoading)}
            dataSource={inActiveOragnizations?.data}
            bordered={false}
            store={{ store: "organization", param: "inActiveOragnizations" }}
            pagination={{
              onChange: (currPage) =>
                dispatch(
                  setInActiveOragnizationPagination({
                    ...inActiveOragnizationPagination,
                    page: currPage,
                  }),
                ),
              pageSize: inActiveOragnizationPagination.perPage,
              current: inActiveOragnizationPagination.page,
              total: inActiveOragnizations?.meta?.total || 0,
              pageSizeOptions: [10, 20, 50, 100, "All"],
              showSizeChanger: false,
            }}
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className={"flashCardTable imageTable"}
            rowKey={(record) => record.uniid}
          />
        </ConfigProvider>
        {/* Share Modal */}
        <ShareModal
          show={showModal.shareModal}
          onHide={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: false }))
          }
          setModalShow={() =>
            setShowModal((prevState) => ({ ...prevState, shareModal: true }))
          }
          modalTitle={"Share Flashcard Set"}
          modalSubtitle={
            <>
              Click “Copy Link” below, then{" "}
              <NavLink
                to="/chat"
                className={"uLine blue-accent-color"}
                target="_blank"
              >
                {" "}
                Open Chat{" "}
              </NavLink>{" "}
              to easily share this organization with members.
            </>
          }
          size={"lg"}
          bodyComponent={{
            img: Assets.eventShareModal,
            title: "Introduction to L’Oreal Color",
            para1: "Improve your Business knowledge with this flashcard set.",
            para2: "10 Cards • Feb 12, 2023",
          }}
        />
        {/* Delete Modal */}
        <DeleteDocModal
          show={showModal.deleteModal}
          onHide={() => setShowModal({ ...showModal, deleteModal: false })}
          heading="Delete Organization?"
          message="Oraginzation deleted"
          para={
            <>
              Are you sure you want to delete <b>Acapello Salons?</b>
            </>
          }
        />
      </div>
    </>
  );
};

export default Lost;
