import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetGalleries = (params) => api.get(apiUrl.galleries, { params });
export const GetSingleGallery = (id) => api.get(`${apiUrl.galleries}/${id}`);
export const DeleteSingleGallery = (id) =>
  api.delete(`${apiUrl.galleries}/${id}`);
export const UploadGallery = (data) => api.post(apiUrl.galleries, data);
export const UpdateGallery = (id, data) =>
  api.put(`${apiUrl.galleries}/${id}`, data);
