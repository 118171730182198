import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [taskPopupTitle, setTaskPopupTitle] = useState("Create New Task");
  const [alertCalander, setAlertCalander] = useState(null);
  const [alertScheduledTime, setAlertScheduledTime] = useState(null);

  return (
    <StateContext.Provider
      value={{
        taskPopupTitle,
        setTaskPopupTitle,
        alertCalander,
        setAlertCalander,
        alertScheduledTime,
        setAlertScheduledTime,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
