import React from "react";
import dayjs from "dayjs";
import moment from "moment";
import CustomSelect from "../components/FormFields/CustomSelect";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../components/FormFields/CustomDatePicker";
import CustomInput from "../components/FormFields/CustomInput";
import CustomTreeSelect from "../components/FormFields/CustomTreeSelect";

const FormTestPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {};

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="select"
        render={({ field }) => (
          <CustomSelect
            {...field}
            placeholder={"Test Placeholder"}
            label={"Test Placeholder"}
            value={field?.value}
            options={[{ label: "Karachi", value: "1" }]}
            onChange={(e) => field?.onChange(e)}
          />
        )}
      />

      <Controller
        control={control}
        name="input"
        render={({ field }) => (
          <CustomInput
            {...field}
            label="Enter name"
            placeholder="Enter name"
            value={field?.value}
            onChange={(e) => field?.onChange(e)}
          />
        )}
      />

      <Controller
        control={control}
        name="date"
        render={({ field }) => (
          <CustomDatePicker
            {...field}
            placeholder="Start Date"
            onChange={(date, dateString) => {
              field?.onChange(moment(dateString).format("YYYY-MM-DD"));
            }}
            value={field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
          />
        )}
      />

      <CustomTreeSelect />

      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default FormTestPage;
