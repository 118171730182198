import React, { useEffect } from "react";
import SurveyHeader from "../../components/pages/SurveysPage/SurveyHeader";
import SurveyBody from "../../components/pages/SurveysPage/SurveyBody";
import { all_category_type } from "../../utils/constants";
import { setCategoryListByModule } from "../../store/slices/globalSlice";
import { useDispatch } from "react-redux";

const SurveysPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.survey));
  }, []);

  return (
    <>
      <div className="innerContainer">
        <SurveyHeader />
        <SurveyBody />
      </div>
    </>
  );
};

export default SurveysPage;
