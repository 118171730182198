import { notification } from "antd";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Assets } from "../../constants/images";
import {
  BlackCrossIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { deleteGroupChat } from "../../services/chats/frontend-chats";
import { DeleteGroupByID } from "../../services/team/groups";
import {
  setDisableActionBtn,
  setRenderTable,
  setSelectedData,
} from "../../store/slices/groupSlice";
import { toastMessage } from "../../utils/helper";

const DeleteGroupModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedData, renderTable, disableActionBtn } = useSelector(
    (state) => state.group,
  );

  const handleDelete = async () => {
    // try {
    //     dispatch(setDisableActionBtn(true));
    //     const response = await DeleteGroupByID(selectedData.id)
    //     await deleteGroupChat(selectedData.id)
    //
    //     const { data, status, message } = response.data;
    //     if (!status) {
    //         notification.error({
    //             description: message,
    //             placement: 'bottomRight',
    //             className: 'createdNotification',
    //             closeIcon: <ToastCrossIcon />,
    //         });
    //         return;
    //     }
    //
    //
    //     toastMessage('delete', message)
    //     dispatch(setSelectedData(null))
    //     dispatch(setRenderTable(renderTable + 1))
    //     props.onHide();
    //     navigate("/team/groups")
    //
    // } catch (e) {
    //     toastMessage('error', e.message)
    // } finally {
    //     dispatch(setDisableActionBtn(false));
    // }

    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-delete-group"
      >
        <Modal.Header>
          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <BlackCrossIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <img src={Assets.DeleteModalGroup} alt="" />
          <h4>Cannot Delete Group</h4>
          <p>
            Groups based on position titles are automatically created when one
            or more members belong to that position. However, you can create and
            delete custom groups.
          </p>
          <div className="btn-div">
            <Button
              className="solid-learn-grey-btn me-2"
              data-dismiss="modal"
              onClick={props.onHide}
            >
              Learn More
            </Button>
            {!disableActionBtn && (
              <Button
                className="solid-gotit-grey-btn "
                data-dismiss="modal"
                onClick={handleDelete}
              >
                Got It!
              </Button>
            )}
            {disableActionBtn && (
              <Button className="solid-gotit-grey-btn" disabled={true}>
                <div className="load black" />
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteGroupModal;
