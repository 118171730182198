import { notification } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assets } from "../../../constants/images";
import { ToastCrossIcon, ToastRedTrashIcon } from "../../../constants/svg";
import { GetEvents } from "../../../services/events/events";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  setIsPastEventsLoading,
  setIsUpcomingEventsLoading,
  setPastEvents,
  setUpcomingEvents,
} from "../../../store/slices/eventsSlice";
import {
  event_type,
  roles as salonRoles,
  salooon_event_type,
} from "../../../utils/constants";
import Events from "./Events";

const EventBody = (props) => {
  const {
    isUpcomingEventsLoading,
    upcomingEvents,
    isPastEventsLoading,
    pastEvents,
    renderTable,
  } = useSelector((state) => state.events);
  const { currentSalonId, salon } = useSelector((state) => state.global);
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(
    ({ auth }) => !!auth?.me?.data?.roles?.find(({ id }) => id === 1),
  );

  const getEventText = (eventType) => {
    let r = "-";
    switch (eventType) {
      case salooon_event_type.online:
        r = "Online";
        break;

      case salooon_event_type.onsite:
        r = "In Person";
        break;
    }
    return r;
  };

  const getUpcomingEvents = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    const products = salon?.products?.map(({ product }) => product) ?? [];
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
        category_id: props?.selectedCategory,
        event_type: event_type.upcoming,
        // "order-column": props?.orderColumn,
        // order: props?.order,
        // page: props?.upcomingEventPage?.currentPage,
        // "per-page": props?.upcomingEventPage?.perPage,
        softwares,
        technologies,
        products,
      };
      dispatch(setIsUpcomingEventsLoading(true));
      const res = await GetEvents(params);
      const data = res?.data?.data?.map((item) => {
        return {
          key: item?.id,
          id: item?.id,
          alertTitle: {
            title: item?.name || "-",
            image: item?.image || Assets.GeneralPlaceholder,
            lock: item?.user?.roles[0]?.id == salonRoles.super_admin,
          },
          sendType: {
            main: item?.category?.name || "-",
            sub: getEventText(item.type),
          },
          lastSent: {
            main: item?.start_date
              ? moment(item?.start_date).format("MMM DD, yyyy")
              : "-",
            sub: item?.start_time || "-",
          },
          seenBy: item?.eventReservations?.length,
          attendeeNames: item?.goingUsers?.map((item) => item?.user?.name),
          link: item?.link,
          start_date: item?.start_date,
          start_time: item?.start_time,
          image: item?.image,
          name: item?.name,
          description: item?.description,
          goingUsers: item?.goingUsers,
          user: item?.user,
        };
      });
      dispatch(setUpcomingEvents({ data, meta: res?.data?.data?.meta }));
      dispatch(setIsUpcomingEventsLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsUpcomingEventsLoading(false));
    }
  };

  const getPastEvents = async () => {
    if (!salon?.id && !isSuperAdmin) return;
    const technologies = salon?.technologies?.map(({ name }) => name) ?? [];
    const softwares = salon?.softwares?.map(({ name }) => name) ?? [];
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
        category_id: props?.selectedCategory,
        event_type: event_type.past,
        // "order-column": props?.orderColumn,
        // order: props?.order,
        page: props?.pastEventPage?.currentPage,
        // "per-page": props?.pastEventPage?.perPage,
        softwares,
        technologies,
      };
      dispatch(setIsPastEventsLoading(true));
      const res = await GetEvents(params);
      const data = res?.data?.data?.map((item) => {
        return {
          key: item?.id,
          id: item?.id,
          alertTitle: {
            title: item?.name || "-",
            image: item?.image || Assets.GeneralPlaceholder,
            lock: item?.user?.roles[0]?.id == salonRoles.super_admin,
          },
          sendType: {
            main: item?.category?.name || "-",
            sub: getEventText(item.type),
          },
          lastSent: {
            main: item?.start_date
              ? moment(item?.start_date).format("MMM DD, yyyy")
              : "-",
            sub: item?.start_time || "-",
          },
          seenBy: item?.eventReservations?.length,
          attendeeNames: item?.goingUsers?.map((item) => item?.user?.name),
          link: item?.link,
          start_date: item?.start_date,
          start_time: item?.start_time,
          image: item?.image,
          name: item?.name,
          description: item?.description,
          goingUsers: item?.goingUsers,
          user: item?.user,
        };
      });
      dispatch(setPastEvents({ data, meta: res?.data?.data?.meta }));
      dispatch(setIsPastEventsLoading(false));
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      dispatch(setIsPastEventsLoading(false));
    }
  };

  const onDelete = () => {
    getUpcomingEvents();
    getPastEvents();
  };
  useEffect(() => {
    getUpcomingEvents();
  }, [
    props?.selectedCategory,
    props?.upcomingEventPage?.currentPage,
    props?.upcomingEventPage?.perPage,
    renderTable,
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  useEffect(() => {
    getPastEvents();
  }, [
    props?.selectedCategory,
    props?.pastEventPage?.currentPage,
    props?.pastEventPage?.perPage,
    renderTable,
    currentSalonId,
    salon,
    isSuperAdmin,
  ]);

  return (
    <div className="content">
      <div
        className={
          props.activeTab == "tab1" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Events
          activeTab={props.activeTab}
          selectedCategory={props?.selectedCategory}
          onDelete={onDelete}
          isLoading={isUpcomingEventsLoading}
          data={upcomingEvents?.data}
          storeState={"upcomingEvents"}
          // page={props?.upcomingEventPage}
          // setPage={props?.setUpcomingEventPage}
          // totalPage={upcomingEvents?.meta?.total}
        />
      </div>
      <div
        className={
          props.activeTab == "tab2" ? "tableWrapper active" : "tableWrapper"
        }
      >
        <Events
          activeTab={props.activeTab}
          selectedCategory={props?.selectedCategory}
          onDelete={onDelete}
          isLoading={isPastEventsLoading}
          storeState={"pastEvents"}
          data={pastEvents?.data}
          // page={props?.upcomingEventPage}
          // setPage={props?.setUpcomingEventPage}
          // totalPage={pastEvents?.meta?.total}
        />
      </div>
    </div>
  );
};

export default EventBody;
