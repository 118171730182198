import { notification } from "antd";
import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { maxBounds, Patterns } from "../../constants/contants";
import {
  SquareLeftArrow,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { ForgetPassword } from "../../services/auth/auth";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = async (data) => {
    try {
      setDisable(true);
      const res = await ForgetPassword(data);
      setDisable(false);
      notification.info({
        message: res.data.message,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });

      setTimeout(() => {
        navigate("/reset-code", { state: { email: data?.email } });
      }, 1200);
    } catch (e) {
      notification.error({
        message: e.message,
        placement: "bottomRight",
        // duration: 0,
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };

  const goBackFunc = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="authBox forgotPassword">
        <div className="goBackBtn" onClick={goBackFunc}>
          <SquareLeftArrow />
        </div>
        <div className="authHeaderText">
          <h1>Reset Password</h1>
          <p>Please enter your email address for a reset code.</p>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          {/* Email Label */}
          <FloatingLabel
            controlId="floatingInput"
            label="Email Address"
            className="mb-20"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className={errors.email ? "hasError" : ""}
              {...register("email", {
                required: {
                  value: true,
                  // message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                },
                pattern: {
                  value: Patterns.Email,
                  // message: VALIDATIONS_TEXT.EMAIL_PATTERN
                },
                maxLength: {
                  value: maxBounds.EMAIL,
                  // message: VALIDATIONS_TEXT.EMAIL_MAX
                },
              })}
            />
          </FloatingLabel>

          <Button
            disabled={disable}
            className="mb-0 w-100"
            size={"lg"}
            type={"submit"}
          >
            {disable ? <div className="load black" /> : "Send Code"}
          </Button>
        </Form>
        <div className="authBoxText">
          <a
            href={
              "https://support.mysalonsymphony.com/hc/en-us/articles/18418248349083-Forgot-Password"
            }
            target={"_blank"}
            className={"blue-accent uLine"}
            rel="noreferrer"
          >
            Need Help?
          </a>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
