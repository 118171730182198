import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { maxBounds } from "../../../constants/contants";
import {
  DropAudio,
  DropPDF,
  DropVideo,
  ModalCloseIcon,
} from "../../../constants/svg";
import {
  UpdateGallery,
  UploadGallery,
} from "../../../services/galleries/galleries";
import { UpdateFileUploadStorageSizeAvailability } from "../../../services/global/global";
import { setRenderList } from "../../../store/slices/gallerysSlice";
import {
  gallery_type,
  roles as salonRoles,
  segment_type,
} from "../../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../../utils/helper";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import FileUploadField from "../../Common/FileUploadField";
import CustomUploader from "../../Common/CustomUploader";

const UploadModal = ({ setModalShow, selectedData }) => {
  const { uploadType, renderList, galleryAddEditForm } = useSelector(
    (state) => state?.gallerys,
  );
  const { currentSalonId } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      file: "",
      name: "",
    },
  });

  const [disable, setDisable] = useState(false);

  const getUploadAccept = () => {
    let acceptType;

    switch (uploadType) {
      case gallery_type.image:
        acceptType = ".png,.jpg,.jpeg";
        break;

      case gallery_type.video:
        acceptType = "video/*";
        break;

      case gallery_type.audio:
        acceptType = "audio/*";
        break;

      case gallery_type.pdf:
        acceptType = ".pdf";
        break;
    }
    return acceptType;
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: getUploadAccept(),
    beforeUpload: (file) => {
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  const onSubmit = async (data) => {
    try {
      const apiData = {
        name: data?.name,
        type: uploadType,
      };
      if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
        apiData.salon_id = currentSalonId;
      } else {
        apiData.segment = segment_type.ALL;
        apiData.is_default = 20; // NO: 10, YES: 20
      }
      setDisable(true);

      let salonS3UploadResponse;

      if (uploadType != gallery_type.folder) {
        salonS3UploadResponse = await salonUploadToS3(
          data.file,
          "uploads/gallery",
          false,
          false,
        );
        if (salonS3UploadResponse.status) {
          apiData.link = salonS3UploadResponse?.data?.location;
          apiData.size = salonS3UploadResponse?.data?.sizeD;
        }
      }

      let res;
      if (galleryAddEditForm.formType == "create") {
        res = await UploadGallery(apiData);
      } else if (galleryAddEditForm.formType == "update") {
        res = await UpdateGallery(selectedData.id, apiData);
      }
      if (GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin) {
        if (salonS3UploadResponse) {
          await UpdateFileUploadStorageSizeAvailability(
            salonS3UploadResponse.data.size,
          );
        }
      }

      dispatch(setRenderList(renderList + 1));
      setDisable(false);
      setModalShow(false);
      toastMessage("success", res.data.message);
    } catch (e) {
      toastMessage("warning", e.message);
      setDisable(false);
    }
  };

  useEffect(() => {
    if (galleryAddEditForm.formType == "update" && selectedData) {
      setValue("name", selectedData.name);
    }
  }, [galleryAddEditForm, selectedData]);

  const getActionBtnText = () => {
    let txt;
    if (galleryAddEditForm.formType == "create") {
      txt =
        uploadType == gallery_type.folder ? "Create Folder" : "Upload Media";
    } else if (galleryAddEditForm.formType == "update") {
      txt = uploadType == gallery_type.folder ? "Rename" : "Update Media";
    }
    return txt;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="DocName"
                label="File Name"
                className="mb-20"
              >
                <Form.Control
                  type="text"
                  placeholder="File Name"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.name ? "hasError" : ""}
                  maxLength={maxBounds.NAME}
                  {...register("name", {
                    required: {
                      value: true,
                    },
                    maxLength: {
                      value: maxBounds.NAME,
                    },
                  })}
                />
              </FloatingLabel>
            </Col>

            {uploadType != gallery_type.folder && (
              <Col lg={12}>
                <p className="small-font mb-4">Upload File (Max: 10MB)</p>
                <div className="addLinkBox addLinkBox-upload-modal ">
                  <Controller
                    control={control}
                    name="file"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomUploader
                        {...uploadProps}
                        fileList={value == "" ? [] : [value]}
                        onChange={(e) => {
                          const fileList = e.fileList;
                          onChange(
                            !fileList.length
                              ? ""
                              : fileList.length > 1
                                ? fileList[1]
                                : fileList[0],
                          );
                        }}
                        className="border-0 bg-0 three-type-upload"
                        // className={`border-0 bg-0 ${
                        //   value == "" ? "hasError" : ""
                        // }`}
                        listType="picture"
                      >
                        {uploadType == gallery_type.video ? (
                          <DropVideo />
                        ) : uploadType == gallery_type.audio ? (
                          <DropAudio />
                        ) : uploadType == gallery_type.pdf ? (
                          <DropPDF />
                        ) : (
                          <FileUploadField limitMegabyteSize={10} />
                        )}
                      </CustomUploader>
                    )}
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="modal-footer">
          {!disable && (
            <Button type="submit" className={" w-100"} disabled={!isValid}>
              {getActionBtnText()}
            </Button>
          )}

          {disable && (
            <Button type="submit" className={" w-100"} disabled={true}>
              <div className="load black" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default UploadModal;
