import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isStatisticsLoading: false,
    isEducationRequestLoading: false,
    isTimeOffRequestLoading: false,
    isScheduleRequestLoading: false,
    isActivitiesLoading: false,
    callSalonByIDAPI: 1,
    statistics: {
      totalEventsCount: 0,
      totalMembersCount: 0,
      totalGoalsCount: 0,
      totalTasksCount: 0,
      accountCount: 0,
      invitesCount: 0,
      openTickets: 0,
    },
    educationRequest: {
      meta: null,
      data: [],
    },
    timeOffRequest: {
      meta: null,
      data: [],
    },
    scheduleRequest: {
      meta: null,
      data: [],
    },

    activities: {
      meta: null,
      data: [],
    },
  },
  reducers: {
    setIsStatisticsLoading: (state, action) => {
      state.isStatisticsLoading = action.payload;
    },
    setStatistics: (state, action) => {
      state.statistics.totalEventsCount = action.payload.totalEventsCount;
      state.statistics.totalMembersCount = action.payload.totalMembersCount;
      state.statistics.totalGoalsCount = action.payload.totalGoalsCount;
      state.statistics.totalTasksCount = action.payload.totalTasksCount;
      state.statistics.accountCount = action.payload.accountCount;
      state.statistics.openTickets = action.payload.openTickets;
      state.statistics.requestsCount = action.payload.requestsCount;
      state.statistics.invitesCount = action.payload.invitesCount;
    },

    setIsEducationRequestLoading: (state, action) => {
      state.isEducationRequestLoading = action.payload;
    },
    setIsTimeOffRequestLoading: (state, action) => {
      state.isTimeOffRequestLoading = action.payload;
    },
    setIsScheduleRequestLoading: (state, action) => {
      state.isScheduleRequestLoading = action.payload;
    },
    setIsActivitiesLoading: (state, action) => {
      state.isActivitiesLoading = action.payload;
    },

    setEducationRequest: (state, action) => {
      state.educationRequest.meta = action.payload.meta;
      state.educationRequest.data = action.payload.data;
    },
    setTimeOffRequest: (state, action) => {
      state.timeOffRequest.meta = action.payload.meta;
      state.timeOffRequest.data = action.payload.data;
    },
    setScheduleRequest: (state, action) => {
      state.scheduleRequest.meta = action.payload.meta;
      state.scheduleRequest.data = action.payload.data;
    },
    setActivities: (state, action) => {
      state.activities.meta = action.payload.meta;
      state.activities.data = action.payload.data;
    },
    setCallSalonByIDAPI: (state, action) => {
      state.callSalonByIDAPI = action.payload;
    },
  },
});

export const {
  setIsStatisticsLoading,
  setStatistics,
  setCallSalonByIDAPI,
  setIsEducationRequestLoading,
  setIsTimeOffRequestLoading,
  setIsScheduleRequestLoading,
  setIsActivitiesLoading,
  setEducationRequest,
  setTimeOffRequest,
  setScheduleRequest,
  setActivities,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
