import React, { useEffect, useState } from "react";
import { Avatar, Popover, Space } from "antd";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Assets } from "../../constants/images";
import {
  EditIcon,
  NewEveryOneIcon,
  NewGroupIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
} from "../../constants/svg";
import ShareModal from "../../components/Modals/ShareModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { deleteTask, getSingleTask } from "../../services/tasks/frontend-tasks";
import { FormProvider, useForm } from "react-hook-form";
import TaskModalForms from "../../components/pages/Tasks/Modals/TaskModalForms";
import GenericModal from "../../components/Modals/GenericModal";
import { useStateContext } from "../../Contexts/ContextProvider";
import { recipient_types, SHARE_URL, task_type } from "../../utils/constants";
import {
  getNumberTitle,
  renderContentWithLinks,
  getRecurringDate,
} from "../../utils/helper";
import ProfileHover from "../../components/Common/ProfileHover";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import AvatarImage from "../../components/Common/AvatarImage";

const TaskDetailPage = () => {
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [statusModal, setStatusModal] = useState({
    showModal: false,
    option: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { singleTask } = useSelector((state) => state?.tasks);
  const { statUserHover } = useSelector((state) => state?.global);
  const { positions } = useSelector((state) => state.global);
  const [disable, setDisable] = useState(false);
  const url = SHARE_URL.TASKS + id;
  const methods = useForm();
  const [showGenericModal, setShowGenericModal] = useState(false);
  const { taskPopupTitle, setTaskPopupTitle } = useStateContext();
  const [show, setShow] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item.assigned?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const selectedData = {
      key: id,
    };
    await deleteTask(selectedData, setDisable, showModal, setShowModal);

    setTimeout(() => {
      navigate("/tasks");
    }, 1200);
  };

  const fetchSingleTask = async () => {
    await getSingleTask(id, dispatch);
  };

  useEffect(() => {
    fetchSingleTask();
  }, [id]);

  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };
  let dueTitle = null;
  if (singleTask?.isSingleTaskLoading || !singleTask?.data) {
    return <LoadingScreen child={true} />;
  } else {
    switch (singleTask?.data?.type) {
      case task_type.weekly:
        dueTitle = `${singleTask?.data?.dueOnDay}`;
        break;
      case task_type.bi_weekly:
        dueTitle = `Other ${singleTask?.data?.dueOnDay}`;
        break;
      case task_type.monthly:
        dueTitle = `${singleTask?.data?.due_on <= 28 ? getNumberTitle(singleTask?.data?.due_on) : "Last"} of every month`;
        break;
    }
  }

  return (
    <>
      <Row>
        <Col lg={8} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/tasks">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Tasks</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        shareModal: true,
                      }))
                    }
                  >
                    <ShareIcon />
                    <p className="uLine ">Share</p>
                  </div>
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      setOpen(true);
                      setShowGenericModal(true);
                    }}
                  >
                    <EditIcon />
                    <p className="uLine ">Edit</p>
                  </div>
                  <div
                    className="icon-text-wrapper deleteLabel cursor-pointer"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        deleteModal: true,
                      }))
                    }
                  >
                    <RecycleBin />
                    <p className="uLine redLine">Delete</p>
                  </div>
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="sec-heading">
                    {singleTask?.data?.name || "-"}
                  </h3>
                </div>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Assignee</h5>
                    <div className="recipt-div">
                      {singleTask?.data?.recipient_type ==
                      recipient_types?.everyone ? (
                        <NewEveryOneIcon />
                      ) : singleTask?.data?.recipient_type ==
                        recipient_types?.group ? (
                        <NewGroupIcon />
                      ) : (
                        ""
                      )}
                      <p>
                        {singleTask?.data?.recipient_type ==
                        recipient_types?.everyone ? (
                          "Everyone"
                        ) : singleTask?.data?.recipient_type ==
                          recipient_types?.group ? (
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#000000",
                              lineHeight: "20px",
                            }}
                          >
                            {singleTask?.data?.groupRecipients
                              ?.map((item) => item?.name)
                              .join(", ")}
                          </div>
                        ) : singleTask?.data?.userRecipients?.length > 0 ? (
                          <Avatar.Group>
                            {singleTask?.data?.userRecipients?.length == 1 ? (
                              <div className={"single-assignee"}>
                                <AvatarImage
                                  name={
                                    singleTask?.data?.userRecipients[0]?.name
                                  }
                                  src={
                                    singleTask?.data?.userRecipients[0]?.image
                                  }
                                  placeholder={Assets.ProfilePlaceholder}
                                  size={26}
                                />
                                <div
                                  style={{
                                    fontSize: "13px",
                                    color: "#000000",
                                    lineHeight: "20px",
                                  }}
                                >
                                  {singleTask?.data?.userRecipients[0]?.name}
                                </div>
                              </div>
                            ) : (
                              singleTask?.data?.userRecipients
                                ?.slice(0, 5)
                                ?.map((item, index) => (
                                  <AvatarImage
                                    src={item?.image}
                                    placeholder={Assets.ProfilePlaceholder}
                                    size={30}
                                    key={index}
                                    className="avatar-border--white"
                                  />
                                ))
                            )}
                            {singleTask?.data?.userRecipients?.length > 5 && (
                              <Avatar
                                size={25}
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  color: "#000",
                                  // fontWeight: 'bold',
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                              >
                                +{singleTask?.data?.userRecipients?.length - 5}
                              </Avatar>
                            )}
                          </Avatar.Group>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Description</h5>
                    <p>
                      {singleTask?.data?.description
                        ? renderContentWithLinks(singleTask?.data?.description)
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Task Type</h5>
                    <p>{singleTask?.data?.typeName || "-"}</p>
                  </div>
                  {dueTitle && (
                    <div className="eventDetailItem">
                      <h5 className="head">Due Every</h5>
                      <p>{dueTitle || "-"}</p>
                    </div>
                  )}
                  <div className="eventDetailItem">
                    <h5 className="head">Due Date</h5>
                    {/* <p>{moment(singleTask?.data?.end_date).format("MMM DD, yyyy")}</p> */}
                    <p>
                      {singleTask?.data?.end
                        ? getRecurringDate(singleTask?.data).format(
                            "MMM DD, YYYY",
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Repeats</h5>
                    <p>{singleTask?.data?.repeat || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Assigned By</h5>
                    <p>{singleTask?.data?.assignee?.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {moment(singleTask?.data?.created_at).format(
                        "MMM DD, yyyy",
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className={"p-0"} onMouseLeave={handleMouseLeave}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Task Status</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>To Do ({singleTask?.data?.todoUsers?.length})</h6>
                </div>
                <div className="response-body">
                  {singleTask?.data?.todoUsers?.map((item, index) => {
                    if (item?.assigned?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: item?.assigned?.id,
                                  name: item?.assigned?.name,
                                  image: item?.assigned?.image,
                                  position: returnPosition(
                                    item?.assigned?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={item?.assigned?.name}
                                src={item?.assigned?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            key={index}
                            to={`/team/members/${item?.assigned?.slug ?? item?.assigned?.id}`}
                          >
                            <p>{item?.assigned?.name} </p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>Overdue ({singleTask?.data?.overdueUsers?.length})</h6>
                </div>
                <div className="response-body">
                  {singleTask?.data?.overdueUsers?.map((item, index) => {
                    if (item?.assigned?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: item?.assigned?.id,
                                  name: item?.assigned?.name,
                                  image: item?.assigned?.image,
                                  position: returnPosition(
                                    item?.assigned?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={item?.assigned?.name}
                                src={item?.assigned?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            to={`/team/members/${item?.assigned?.slug ?? item?.assigned?.id}`}
                          >
                            <p>{item?.assigned?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>Done ({singleTask?.data?.doneUsers?.length})</h6>
                </div>
                <div className="response-body">
                  {singleTask?.data?.doneUsers?.map((item, index) => {
                    if (item?.assigned?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: item?.assigned?.id,
                                  name: item?.assigned?.name,
                                  image: item?.assigned?.image,
                                  position: returnPosition(
                                    item?.assigned?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={item?.assigned?.name}
                                src={item?.assigned?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            to={`/team/members/${item?.assigned?.slug ?? item?.assigned?.id}`}
                          >
                            <p>{item?.assigned?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* All modals goes here */}

      {/* Share Modal */}
      <ShareModal
        data={{
          image: null,
          name: singleTask?.data?.name,
          description: singleTask?.data?.description,
          start_date: moment(singleTask?.data?.created_at).format(
            "MMM, DD yyyy",
          ),
          start_time: moment(singleTask?.data?.created_at).format("hh:mm a"),
        }}
        shareLink={url}
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Task"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            &nbsp; to easily share this task with members.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 @ 9:00 AM EST",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        handleDelete={handleDelete}
        disable={disable}
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Task?"
        para={
          <>
            Are you sure you want to delete
            <b>
              {" "}
              {singleTask?.data?.type == task_type.one_time ? (
                "this one-time task"
              ) : (
                <b>this recurring task</b>
              )}
              ?
            </b>
            <br />
            Important: This task cannot be recovered.
          </>
        }
        notificationMessage="Task"
      />
      {/* Edit Drawer */}
      <GenericModal
        show={showGenericModal}
        onHide={() => setShowGenericModal(false)}
        setModalShow={setShowGenericModal}
        modalTitle={taskPopupTitle}
        size={"lg"}
        bodyComponent={
          <FormProvider {...methods}>
            <TaskModalForms
              isedit={true}
              methods={methods}
              setShowGenericModal={setShowGenericModal}
              show={showGenericModal}
              id={id}
            />
          </FormProvider>
        }
      />
    </>
  );
};

export default TaskDetailPage;
