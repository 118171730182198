import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetNewHireCheckList = async (params) => {
  return await api.get(apiUrl.newHireCheckList, { params });
};

export const DeleteNewHireCheckList = async (id) => {
  return await api.delete(apiUrl.newHireCheckList + "/" + id);
};

export const GetSingleNewHireCheckList = async (id) => {
  return await api.get(apiUrl.newHireCheckList + "/" + id);
};

export const CreateNewHireCheckList = async (payload) => {
  return await api.post(apiUrl.newHireCheckList, payload);
};

export const UpdateNewHireCheckList = async (id, payload) => {
  return await api.put(apiUrl.newHireCheckList + "/" + id, payload);
};
