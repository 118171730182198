import { notification } from "antd";
import { Assets } from "../../constants/images";
import {
  ClearAllActivities,
  DeleteAllActivities,
  GetActivities,
  UpdateActivity,
} from "./activities";
import moment from "moment";
import { ToastCrossIcon, ToastGreenCheckIcon } from "../../constants/svg";

export const getNewActivities = async (
  params,
  dispatch,
  setLoading,
  setData,
) => {
  try {
    dispatch(setLoading(true));
    const res = await GetActivities(params);
    const data = res.data.data?.data?.map((item, index) => ({
      key: item?.id,
      alertTitle: {
        title: item?.user?.name || "-",
        image: item?.user?.image || Assets.ProfilePlaceholder,
        name: item?.name || "-",
      },
      sendType: {
        main: item?.description || "-",
      },
      lastSent: {
        main: `${moment.utc(item?.created_at).local().fromNow()} at ${moment.utc(item?.created_at).local().format("hh:mm a")}`,
        time: `${moment.utc(item?.created_at).toISOString()}`,
      },
      checkMark: false,
      model: item?.model,
      model_id: item?.model_id,
    }));

    dispatch(setData({ meta: res.data.data?.meta, data }));
    dispatch(setLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setLoading(false));
  }
};

export const getNewActivitiesMore = async (params) => {
  try {
    const res = await GetActivities(params);
    const data = res.data.data?.data?.map((item, index) => ({
      key: item?.id,
      alertTitle: {
        title: item?.user?.name || "-",
        image: item?.user?.image || Assets.ProfilePlaceholder,
        name: item?.name || "-",
      },
      sendType: {
        main: item?.description || "-",
      },
      lastSent: {
        main: `${moment.utc(item?.created_at).local().fromNow()} at ${moment.utc(item?.created_at).local().format("hh:mm a")}`,
        time: `${moment.utc(item?.created_at).toISOString()}`,
      },
      checkMark: false,
      model: item?.model,
      model_id: item?.model_id,
    }));

    return { meta: res.data.data?.meta, data };
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const getClearedActivities = async (
  params,
  dispatch,
  setLoading,
  setData,
) => {
  try {
    dispatch(setLoading(true));
    const res = await GetActivities(params);
    const data = res.data.data?.data?.map((item, index) => ({
      key: item?.id,
      alertTitle: {
        title: item?.user?.name || "-",
        image: item?.user?.image || Assets.ProfilePlaceholder,
        name: item?.name || "-",
      },
      sendType: {
        main: item?.description || "-",
      },
      lastSent: {
        main: `${moment.utc(item?.created_at).local().fromNow()} at ${moment.utc(item?.created_at).local().format("hh:mm a")}`,
        time: `${moment.utc(item?.created_at).toISOString()}`,
      },
      checkMark: true,
      model: item?.model,
      model_id: item?.model_id,
    }));

    dispatch(setData({ meta: res.data.data?.meta, data }));
    dispatch(setLoading(false));
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
    dispatch(setLoading(false));
  }
};

export const getClearedActivitiesMore = async (params) => {
  try {
    const res = await GetActivities(params);
    const data = res.data.data?.data?.map((item, index) => ({
      key: item?.id,
      alertTitle: {
        title: item?.user?.name || "-",
        image: item?.user?.image || Assets.ProfilePlaceholder,
        name: item?.name || "-",
      },
      sendType: {
        main: item?.description || "-",
      },
      lastSent: {
        main: `${moment.utc(item?.created_at).local().fromNow()} at ${moment.utc(item?.created_at).local().format("hh:mm a")}`,
        time: `${moment.utc(item?.created_at).toISOString()}`,
      },
      checkMark: true,
      model: item?.model,
      model_id: item?.model_id,
    }));

    return { meta: res.data.data?.meta, data };
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const updateActivity = async (id, data) => {
  try {
    const res = await UpdateActivity(id, data);
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const deleteAllActivities = async (salon_id) => {
  try {
    const res = await DeleteAllActivities(salon_id);
    notification.info({
      message: <span>{res.data.message}</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};

export const clearAllActivities = async (salon_id) => {
  try {
    const res = await ClearAllActivities(salon_id);
    notification.info({
      message: <span>{res.data.message}</span>,
      placement: "bottomRight",
      className: "createdNotification",
      icon: <ToastGreenCheckIcon />,
      closeIcon: <ToastCrossIcon />,
    });
  } catch (e) {
    notification.error({
      description: e.message,
      placement: "bottomRight",
      className: "createdNotification",
      closeIcon: <ToastCrossIcon />,
    });
  }
};
