import { DatePicker } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the English locale for dayjs
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../Contexts/ContextProvider";
import { RoundedCalenderIcon } from "../../constants/svg";

const DatepickerComponent = (props) => {
  const { alertCalander, setAlertCalander } = useStateContext();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateValue, setDateValue] = useState();
  // props?.defaultDate ? dayjs(props?.defaultDate, "MM DD,YYYY") : undefined

  useEffect(() => {
    dayjs.locale("en"); // Set the locale to English
  }, []);
  useEffect(() => {
    if (props.value) setDateValue(props.value);
  }, [props.value]);
  const onChange = (date, dateString) => {
    if (props?.handleSelectAndDatePickerValues) {
      props?.handleSelectAndDatePickerValues(
        moment(dateString).format("YYYY-MM-DD"),
      );
    }
    setAlertCalander(date);
    setDateValue(date);
    if (props?.setData) {
      props?.setData(props?.name, dateString);
    }
  };

  return (
    <div
      className={`customLabelGroup datePickerWrapper form-floating mb-20 ${dateValue ? "hasValue" : ""}`}
    >
      <div className="form-control">
        {dateValue ? (
          <div
            className={"customLabel"}
            style={{
              display: "block",
              color: isCalendarOpen ? "#7B68EE" : "#A8A8A8",
            }}
          >
            {props?.pickerName}
          </div>
        ) : null}
        <DatePicker
          onChange={onChange}
          // suffixIcon={<RoundedCalenderIcon />}
          suffixIcon={true}
          placeholder={props?.pickerName}
          disabledDate={props?.disabledDate}
          className="customDatePicker"
          closeButton={false}
          value={
            props.value && props.value != "0000-00-00"
              ? dayjs(props.value, "YYYY-MM-DD")
              : ""
          }
          // value={(props.value && props.value != '0000-00-00') ? moment(props.value).format("MM DD,YYYY") : null}
          format="MMM DD, YYYY" // Set the desired date format
          onFocus={() => setIsCalendarOpen(true)}
          onBlur={() => setIsCalendarOpen(false)}
          showNow={false}
        />
      </div>
      <div className="icon">
        <RoundedCalenderIcon />
      </div>
    </div>
  );
};

export default DatepickerComponent;
