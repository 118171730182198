import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ResendOtp,
  VerifyOtp as VerifyOtpService,
} from "../../services/auth/auth";
import { formatTime, toastMessage } from "../../utils/helper";
import { useLocation } from "react-router";
import { constant, online_status, roles } from "../../utils/constants";
import { updateOnlineStatusOfLoginUser } from "../../services/chats/frontend-chats";
import {
  SetAuthUserLocalStorage,
  SetTokenLocalStorage,
  SetUserRoleLocalStorage,
} from "../../services/localStorage/localStorage";
import { notification } from "antd";
import { ToastCrossIcon, ToastGreenCheckIcon } from "../../constants/svg";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [otpCode, setOtpCode] = useState("");
  const [sendingOtpCode, setSendingOtpCode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const { formData } = useSelector((state) => state.auth);
  const [counter, setCounter] = useState(300);

  useEffect(() => {
    let timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const onSubmit = async (data) => {
    const email = queryParams.get("email");
    try {
      setDisable(true);
      if (otpCode.length == 0) {
        setError(true);
        setErrorMessage("Please Enter Code");
      } else if (otpCode.length > 0 && otpCode.length < 4) {
        setError(true);
        setErrorMessage("Code is incomplete");
      } else {
        const response = await VerifyOtpService({
          email: formData?.email || email,
          otp_code: otpCode,
          role_id: roles?.admin?.toString(),
          device_type: constant?.DEVICE_TYPE,
          device_token: constant?.DEVICE_TOKEN,
          password: formData?.password,
        });

        let { data, message, status } = response.data;
        if (!status) {
          toastMessage("error", message);
          return;
        }
        await updateOnlineStatusOfLoginUser(
          response?.data?.data?.user?.id,
          online_status?.online,
        );
        const resData = response?.data?.data;
        SetAuthUserLocalStorage(resData?.user);
        SetTokenLocalStorage(resData?.access_token?.token);
        SetUserRoleLocalStorage(resData?.user?.roles[0]);
        setDisable(false);

        notification.info({
          message: response.data.message,
          placement: "bottomRight",
          // duration: 0,
          className: "createdNotification",
          icon: <ToastGreenCheckIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        navigate("/dashboard");

        // navigate("/");
      }
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisable(false);
    }
  };

  const handleOtpChange = (otp) => {
    setOtpCode(otp);
  };

  const resendCode = async () => {
    if (sendingOtpCode) return;
    try {
      if (!formData.email) throw new Error("Email is required!");

      setSendingOtpCode(true);
      const response = await ResendOtp({
        email: formData.email,
      });

      const { data, message, status } = response.data;

      if (!status) {
        toastMessage("error", message);
        return;
      }

      setCounter(30);
      toastMessage("success", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setSendingOtpCode(false);
    }
  };

  useEffect(() => {
    const email = queryParams.get("email");
    if (formData?.email == "" && !email) navigate("/setup");
  }, []);

  return (
    <div className="authBox SignUp">
      <div className="authHeaderText">
        <h1>Verify Account</h1>
        <p className="subcribePlan">
          Enter the passcode sent to your email or
          <NavLink
            to={
              "https://support.mysalonsymphony.com/hc/en-us/requests/new?ticket_form_id=18339993546523"
            }
            className={"uLine blue-accent d-inline ms-1"}
            target="_blank"
          >
            request support.
          </NavLink>
        </p>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <Form.Group className="OtpInput-container" controlId="signInEmail">
          <OtpInput
            value={otpCode}
            onChange={(otp) => handleOtpChange(otp)}
            numInputs={4}
            isInputNum={true}
            placeholder={"----"}
            className={"OtpInput"}
            hasErrored={true}
            focusStyle="focus"
            autoFocus={true}
          />
          {/* {error && <Form.Text>{errorMessage}</Form.Text>} */}
        </Form.Group>
        <Button
          type={"submit"}
          className={"w-100 btn-submit m-t-50"}
          disabled={disable}
        >
          Verify
        </Button>

        <div className="mainCounterDiv">
          {counter > 0 ? (
            <span className="d-inline-block mt-30">
              Didn't get a code? Request a new one in{" "}
              <b className="timer blue-accent">{formatTime(counter)}</b>
            </span>
          ) : (
            <p
              onClick={() => resendCode()}
              className={"text-center blue-accent cursor-pointer "}
            >
              Request a new
              <span className={"uLine"}>&nbsp;passcode now.</span>
            </p>
          )}
        </div>
      </Form>
    </div>
  );
};

export default VerifyOtp;
