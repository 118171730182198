import React from "react";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import moment from "moment";
import { Controller, useFormContext, useForm } from "react-hook-form";
import CustomDatePicker from "../../../FormFields/CustomDatePicker";
import CustomSelect from "../../../FormFields/CustomSelect";
import {
  schedule_time_array,
  event_timezone,
} from "../../../../utils/constants";
import { set } from "immutable";

const ScheduleAlertForm = (props) => {
  const { control } = useFormContext();

  const [time, setime] = React.useState(props?.selectedData?.ls);
  const disabledPastDates = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current <= today;
  };
  return (
    <Row>
      <Col xs={4}>
        <Controller
          rules={{
            required: {
              value: "Required",
            },
          }}
          defaultValue={props?.selectedData?.date}
          control={control}
          name="date"
          render={({ field }) => (
            <CustomDatePicker
              {...field}
              label="Send Date"
              placeholder="Send Date"
              onChange={(date, dateString) => {
                field?.onChange(moment(dateString).format("YYYY-MM-DD"));
              }}
              value={field?.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
              // disabledDate={disabledPastDates}
            />
          )}
        />
      </Col>

      <Col xs={4}>
        <Controller
          rules={{
            required: {
              value: "Required",
            },
          }}
          defaultValue={props?.selectedData?.ls}
          control={control}
          name="time"
          render={({ field }) => (
            <CustomSelect
              {...field}
              placeholder={"Send Time"}
              label={"Send Time"}
              value={time}
              options={schedule_time_array}
              onChange={(e) => {
                field?.onChange(e);
                setime(e);
              }}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default ScheduleAlertForm;
