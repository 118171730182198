import { Avatar, Dropdown, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CreateNewGroup from "../../components/Modals/CreateNewGroup";
import DeleteGroupModal from "../../components/Modals/DeleteGroupModal";
import GenericModal from "../../components/Modals/GenericModal";
import { toastMessage } from "../../utils/helper";
import {
  deleteGroupChat,
  redirectToGroupChat,
} from "../../services/chats/frontend-chats";
import { Assets } from "../../constants/images";
import {
  AddIcon,
  ArrowTriangleDown,
  CheckMarkIcon,
  LockKeyhole,
  MessageLight,
  MoreIconGrey,
  NewSparkleIcon,
  NoDataIcon,
  PenBoxIcon,
  RecycleBin,
  ToastCrossIcon,
} from "../../constants/svg";
import { DeleteGroupByID, GetList } from "../../services/team/groups";
import {
  setCreateUpdateGroupForm,
  setGroups,
  setSelectedData,
} from "../../store/slices/groupSlice";
import { groupTypes, roles as salonRoles } from "../../utils/constants";
import DeleteModal from "../../components/Modals/DeleteModal";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import AvatarImage from "../../components/Common/AvatarImage";
import { checkIsDefaultGroupAvatar } from "../../utils/commons";
import EveryOneImage from "../../assets/images/everyone.svg";
import { includes } from "lodash";

const menuOption = [
  {
    label: "All Groups",
    value: null,
  },
  {
    label: "Positions ",
    value: 30,
  },
  {
    label: "Locations ",
    value: 20,
  },
  {
    label: "Custom",
    value: 10,
  },
];

const GroupsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const { createUpdateGroupForm, groups, selectedData, renderTable } =
    useSelector((state) => state.group);
  const { currentSalonId } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const resetForm = () => {};

  const getAllGroups = async () => {
    if (groups?.length == 0) {
      setGroupLoading(true);
    }

    const response = await GetList({
      is_paginated: 0,
      "order-column": "id",
      order: "desc",
      salon_id: currentSalonId,
      type: filter,
    });
    const { data, status, message } = response.data;
    dispatch(setGroups(data));
    setGroupLoading(false);
  };

  const handleGroupChat = async (group) => {
    let usersMembers = [];
    group?.users.map((item) => {
      usersMembers.push(item.id);
    });
    try {
      await redirectToGroupChat(group?.id, dispatch, usersMembers, group);
      navigate("/inbox");
    } catch (e) {
      toastMessage("error", e.message);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, [renderTable, filter, currentSalonId]);

  const handleDelete = async () => {
    try {
      setDisable(true);
      const response = await DeleteGroupByID(selectedData.id);
      await deleteGroupChat(selectedData.id);

      const { data, status, message } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      toastMessage("delete", message);
      dispatch(setSelectedData(null));
      setDeleteModal(false);
      getAllGroups();
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisable(false);
    }
  };
  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">Groups</h2>
        {/*
        GetAuthUserLocalStorage()?.roles[0]?.id != salonRoles.super_admin && (
          <div className="d-flex gap-1">
            <Select
              defaultValue="All Groups"
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              // open={true}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={menuOption}
              onChange={(val) => setFilter(val)}
            ></Select>
            <button
              className="btn-gradient"
              onClick={() => {
                dispatch(
                  setCreateUpdateGroupForm({
                    show: true,
                    formType: "create",
                  }),
                );
              }}
            >
              <span>
                <AddIcon />
              </span>
              Create Group
            </button>
          </div>
        )
        */}
      </div>
      <div className="teamTabSubHead teamHeading">
        <p className="border-line">
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> Members are automatically grouped by
          position and location as they join. &nbsp;
          <a
            href={
              "https://support.mysalonsymphony.com/hc/en-us/articles/18501449795611-User-Groups"
            }
            target="_blank"
            className={"uLine globalBlueLinkColor"}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </p>
      </div>
      <div className="teamBody teamGroupCardOnly">
        {groupLoading && <LoadingScreen child={true} />}
        {!groupLoading && !groups?.length ? (
          <Row>
            <Col md={12}>
              <div className="noSearchData">
                <NoDataIcon />
                <h5>Groups you've added will appear here.</h5>
                <h6>Click "Create Group" to get started.</h6>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            {groups.map((elem, id) => {
              const image = elem.image;
              const groupAvatar = checkIsDefaultGroupAvatar(image);
              return (
                <Col key={uuidv4()} lg={3} md={4} sm={4}>
                  <div key={elem.id} className="main-group-card">
                    <div className="groupCardDropdown">
                      <Dropdown
                        menu={{
                          items: [
                            elem.type == 10 && {
                              key: uuidv4(),
                              label: (
                                <>
                                  <NavLink
                                    to={"/"}
                                    className={"black item"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(setSelectedData(elem));
                                      dispatch(
                                        setCreateUpdateGroupForm({
                                          show: true,
                                          formType: "update",
                                        }),
                                      );
                                    }}
                                  >
                                    <PenBoxIcon />
                                    Edit Group
                                  </NavLink>
                                </>
                              ),
                            },
                            {
                              key: uuidv4(),
                              label: (
                                <>
                                  <div
                                    className="item"
                                    onClick={() => {
                                      handleGroupChat(elem);
                                    }}
                                  >
                                    <MessageLight />
                                    Chat Group
                                  </div>
                                </>
                              ),
                            },
                            {
                              key: uuidv4(),
                              label: (
                                <>
                                  <div
                                    className="item"
                                    onClick={() => {
                                      dispatch(setSelectedData(elem));
                                      if (elem.type == groupTypes.CUSTOM) {
                                        setDeleteModal(true);
                                      } else {
                                        setShowModal((prevState) => ({
                                          ...prevState,
                                          deleteModal: true,
                                        }));
                                      }
                                    }}
                                  >
                                    {elem.type == groupTypes.CUSTOM ? (
                                      <RecycleBin />
                                    ) : (
                                      <LockKeyhole />
                                    )}
                                    Delete Group
                                  </div>
                                </>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        overlayClassName={"defaultDropdown balckedDropdown"}
                      >
                        <div className="icon cursor-pointer">
                          <MoreIconGrey />
                        </div>
                      </Dropdown>
                    </div>
                    <NavLink to={`/team/groups/${elem.slug ?? elem.id}`}>
                      <div className="groupCard">
                        <div className="roundIcon groups-icons">
                          {/* <UserSettingIcon />
                           */}
                          <AvatarImage
                            name={elem.name}
                            src={groupAvatar ? EveryOneImage : elem.image}
                            placeholder={EveryOneImage}
                          />
                        </div>
                        <h6 className="title black">{elem.name}</h6>
                        <p className="subTitle">
                          {elem?.users?.length > 0
                            ? elem?.users?.length == 1
                              ? "1 Member"
                              : elem?.users?.length + " Members"
                            : "0 Member"}
                        </p>
                        {/* <Avatar.Group>
                        {Array.from({ length: 6 }).map((_, index) => (
                          <Avatar key={index} size={25} src={item.src} />
                        ))}
                      </Avatar.Group> */}
                        {elem.users.length > 0 && (
                          <Avatar.Group>
                            {elem.users.slice(0, 5).map((user, index) => (
                              <AvatarImage
                                src={user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={25}
                                key={index}
                                className="group-members"
                              />
                            ))}
                            {elem.users.length > 5 && (
                              <Avatar
                                size={25}
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  color: "#000",
                                  // fontWeight: 'bold',
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                              >
                                +{elem.users.length - 5}
                              </Avatar>
                            )}
                          </Avatar.Group>
                        )}
                      </div>
                    </NavLink>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
      {/* Delete Modal */}
      <DeleteGroupModal
        show={showModal.deleteModal}
        onHide={() => {
          dispatch(setSelectedData(null));
          setShowModal({ ...showModal, deleteModal: false });
        }}
      />
      <DeleteModal
        handleDelete={handleDelete}
        disable={disable}
        show={deleteModal}
        onHide={() => {
          dispatch(setSelectedData(null));
          setDeleteModal(false);
          setShowModal({ ...showModal, deleteModal: false });
        }}
        heading="Delete Group?"
        para={
          <>
            Are you sure you want to delete
            <b> {selectedData?.name || "this group"} ?</b>
            <br />
            Important: This group cannot be recovered.
          </>
        }
        notificationMessage="Group"
      />
      <GenericModal
        resetForm={resetForm}
        show={createUpdateGroupForm.show}
        onHide={() => {
          dispatch(
            setCreateUpdateGroupForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setCreateUpdateGroupForm({
              show: false,
              formType: null,
            }),
          );
        }}
        modalTitle={
          createUpdateGroupForm.formType == "update"
            ? "Edit Group"
            : "Create New Group"
        }
        pro={"Pro Tip:"}
        modalDesc={
          <>
            <div style={{ padding: "0 38px", textAlign: "left" }}>
              <b>Pro Tip:</b> You can easily refer to custom groups when sending
              alerts, assigning tasks, and more!
            </div>
          </>
        }
        size={"lg"}
        bodyComponent={<CreateNewGroup />}
      />
    </>
  );
};

export default GroupsPage;
