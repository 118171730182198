import api from "../index";
import { apiUrl } from "../../utils/constants";

export const CreateGroup = (payload) => api.post(apiUrl.groups, payload);
export const GetList = (params) => api.get(apiUrl.groups, { params });
export const GetGroupByID = (id) => api.get(apiUrl.groups + "/" + id);
export const UpdateGroup = (id, payload) =>
  api.put(apiUrl.groups + "/" + id, payload);
export const DeleteGroupByID = (id) => api.delete(apiUrl.groups + "/" + id);
export const AddUserToGroup = (group_id, data) =>
  api.post(`${apiUrl.group_add_users}/${group_id}`, data);
export const RemoveUserFromGroup = (group_id, user_id) =>
  api.delete(`${apiUrl.group_remove_user}/${group_id}/${user_id}`);
