import React, { useEffect, useState } from "react";
import EventBody from "../../components/pages/Events/EventBody";
import EventHeader from "../../components/pages/Events/EventHeader";
import { all_category_type } from "../../utils/constants";
import { setCategoryListByModule } from "../../store/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveEventTab,
  setEventAddEditFormVisibility,
} from "../../store/slices/eventsSlice";
import EventModalForms from "../../components/pages/Events/Modals/EventModalForms";
import GenericModal from "../../components/Modals/GenericModal";

function EventsPage() {
  const dispatch = useDispatch();
  const { eventAddEditFormVisibility, selectedData } = useSelector(
    (state) => state.events,
  );
  const selectedTab = useSelector((state) => state.events.activeTab);

  const [activeTab, setActiveTab] = useState(selectedTab || "tab1");
  const [selectedCategory, setSelectedCategory] = useState(null);

  dispatch(setActiveEventTab(activeTab));
  const [upcomingEventPage, setUpcomingEventPage] = useState({
    currentPage: 1,
    perPage: 10,
  });
  const [pastEventPage, setPastEventPage] = useState({
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderColumn: "id",
    order: "desc",
  });

  const handleResetPage = () => {
    setUpcomingEventPage({ currentPage: 1, perPage: 10 });
    setPastEventPage({ currentPage: 1, perPage: 10 });
  };

  useEffect(() => {
    setSelectedCategory(null);
  }, [activeTab]);

  useEffect(() => {
    dispatch(setCategoryListByModule(all_category_type?.event));
  }, []);

  return (
    <>
      <div className="innerContainer">
        <EventHeader
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleResetPage={handleResetPage}
        />

        <EventBody
          selectedCategory={selectedCategory}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          orderColumn={order.orderColumn}
          order={order.order}
          upcomingEventPage={upcomingEventPage}
          setUpcomingEventPage={setUpcomingEventPage}
          pastEventPage={pastEventPage}
          setPastEventPage={setPastEventPage}
        />
      </div>
      <GenericModal
        show={eventAddEditFormVisibility.show}
        onHide={() =>
          dispatch(
            setEventAddEditFormVisibility({
              show: false,
              formType: null,
            }),
          )
        }
        setModalShow={(v) => {
          dispatch(
            dispatch(
              setEventAddEditFormVisibility({
                show: v,
                formType: null,
              }),
            ),
          );
        }}
        modalTitle={
          eventAddEditFormVisibility.formType == "create"
            ? "Create New Event"
            : "Edit Event"
        }
        customModalHeader={"customEventModalHeader"}
        customClassName="customEventModal"
        size={"lg"}
        bodyComponent={<EventModalForms />}
      />
    </>
  );
}

export default EventsPage;
