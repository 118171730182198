import { useEffect, useRef, useState } from "react";
import { IoCloseOutline, IoSearch } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { NoDataSearchFolder } from "../../constants/svg";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSearchHistoryLoading,
  setSearchHistory,
} from "../../store/slices/searchSlice";
import {
  addSearchHistory,
  clearAllSearchHistory,
  deleteSingleSearchHistory,
  getSearchHistory,
} from "../../services/search/frontend-search";

const SearchBar = () => {
  const searchBarRef = useRef(null);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const authUser = GetAuthUserLocalStorage();
  const dispatch = useDispatch();
  const { isSearchHistoryLoading, searchHistory } = useSelector(
    (state) => state?.search,
  );

  const handleInputFocus = () => {
    document.body.classList.add("searchBarFocused");
    setIsMenuOpen(true);
  };

  const handleCloseAll = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("searchBarFocused");
  };

  const handleKeyDown = async (event) => {
    if (event.key == "Enter") {
      if (searchTerm.length > 0) {
        const data = {
          keyword: searchTerm,
          user_id: authUser?.id,
        };
        await addSearchHistory(data);
        navigate(`/search/${searchTerm}`);
        setIsMenuOpen(false);
        document.body.classList.remove("searchBarFocused");
      }
    }
  };

  const handleDeleteSearchHistory = async (data, index) => {
    await deleteSingleSearchHistory(data?.id, index, dispatch);
  };

  const handleClearAllSearchHistory = async () => {
    if (searchHistory?.data?.length > 0) {
      await clearAllSearchHistory(authUser?.id, dispatch);
    }
  };

  const fetchSearchHistory = async () => {
    const params = {
      is_paginated: 0,
      user_id: authUser?.id,
    };

    await getSearchHistory(
      params,
      dispatch,
      setIsSearchHistoryLoading,
      setSearchHistory,
    );
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!searchBarRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        document.body.classList.remove("searchBarFocused");
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      fetchSearchHistory();
    }
  }, [isMenuOpen]);

  return (
    <div
      ref={searchBarRef}
      className={isMenuOpen ? "generalSearchBar active" : "generalSearchBar"}
    >
      <div className="searchBarWrapper">
        <div className="searchIcon">
          <IoSearch />
        </div>
        <input
          type="text"
          className="searchInput"
          placeholder="Search resources, events, and more"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={handleInputFocus}
          onKeyDown={handleKeyDown}
        />
        <div
          className="closeCrossBtn cursor-pointer"
          onClick={() => {
            setSearchTerm("");
            handleCloseAll();
          }}
        >
          {isMenuOpen && <IoCloseOutline />}
        </div>
      </div>
      {isMenuOpen && (
        <div className="menuWrapper">
          <div className="menuheader">
            <h4>History</h4>
            <span
              className="uLine redLine cursor-pointer"
              onClick={handleClearAllSearchHistory}
            >
              Clear All
            </span>
          </div>
          <div className="menuBody">
            {isSearchHistoryLoading ? (
              <div className="load black" />
            ) : searchHistory?.data?.length <= 0 ? (
              <div className={"noDataImg"}>
                <NoDataSearchFolder />
                <p className={"mt-4"}>No recent searches...</p>
              </div>
            ) : (
              <ul className="searchbarMenuList">
                {searchHistory?.data?.map((item, index) => (
                  <li key={index} className="searchbarMenuItem">
                    <NavLink
                      to={`/search/${item?.keyword}`}
                      onClick={handleCloseAll}
                    >
                      {item.keyword}
                    </NavLink>
                    <IoCloseOutline
                      onClick={(e) => handleDeleteSearchHistory(item, index)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
