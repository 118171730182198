import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrganizationHeader from "../../components/pages/Organization/OrganizationHeader";
import OrginzationBody from "../../components/pages/Organization/OrganizationBody";
import { DeleteSalonByID, GetAllSalons } from "../../services/global/global";
import { toastMessage } from "../../utils/helper";
import {
  setActiveOragnizations,
  setInActiveOragnizations,
  setReRenderList,
  setShowHideOrganizationDeleteModal,
  setTableLoading,
} from "../../store/slices/organizationSlice";
import DeleteOragnizationModal from "../../components/Modals/DeleteOragnizationModal";
import { organization_status } from "../../utils/constants";

const OrganizationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("tab1");
  const [showModal, setShowModal] = useState({
    shareModal: false,
    editModal: false,
    deleteModal: false,
  });
  const { allSalons, currentSalonId } = useSelector((state) => state.global);
  const {
    activeOraganizationPagination,
    inActiveOragnizationPagination,
    filters,
    showHideOragnizationDeleteModa,
    singleOrganizationDetail,
    reRenderList,
  } = useSelector((state) => state.organization);

  const [disableActionDeleteBtn, setDisableActionDeleteBtn] = useState(false);

  const getActiveOrganizations = async (params) => {
    try {
      dispatch(setTableLoading(true));
      const response = await GetAllSalons(params);
      const { data, message, status } = response.data;
      const { data: tableData, meta } = data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      const modified = tableData.map((elem) => {
        return {
          ...elem,
          product_line: elem.product_line ? elem.product_line : "",
          total_members: elem.meta.members,
        };
      });
      dispatch(
        setActiveOragnizations({
          meta,
          data: modified,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const getInactiveOrganizations = async (params) => {
    try {
      dispatch(setTableLoading(true));
      const response = await GetAllSalons(params);
      const { data, message, status } = response.data;
      const { data: tableData, meta } = data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      const modified = tableData.map((elem) => {
        return {
          ...elem,
          product_line: elem.product_line ? elem.product_line : "",
          total_members: elem.meta.members,
        };
      });
      dispatch(
        setInActiveOragnizations({
          meta,
          data: modified,
        }),
      );
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const handleDeleteOraganization = async () => {
    try {
      setDisableActionDeleteBtn(true);
      const response = await DeleteSalonByID(singleOrganizationDetail.id);
      const { status, message, data } = response.data;
      if (!status) {
        toastMessage("error", message);
        return;
      }
      dispatch(setShowHideOrganizationDeleteModal(false));
      dispatch(setReRenderList(reRenderList + 1));
      toastMessage("success", "Organization Deleted Successfully");
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      setDisableActionDeleteBtn(false);
    }
  };

  useEffect(() => {
    const payload = {
      is_paginated: 1,
      status: organization_status.active,
      page: activeOraganizationPagination.page,
      "per-page": activeOraganizationPagination.perPage,
      salon_id: currentSalonId,
    };
    if (filters.billing_plan_id) {
      payload.billing_plan_id = filters.billing_plan_id;
    }
    getActiveOrganizations(payload);
  }, [
    currentSalonId,
    JSON.stringify(activeOraganizationPagination),
    JSON.stringify(filters),
    reRenderList,
  ]);

  useEffect(() => {
    const payload = {
      is_paginated: 1,
      status: organization_status.inactive,
      page: inActiveOragnizationPagination.page,
      "per-page": inActiveOragnizationPagination.perPage,
      salon_id: currentSalonId,
    };
    if (filters.billing_plan_id) {
      payload.billing_plan_id = filters.billing_plan_id;
    }
    // TODO this request should return inactive organizations but backend responding with all organizations
    getInactiveOrganizations(payload);
  }, [
    currentSalonId,
    JSON.stringify(inActiveOragnizationPagination),
    JSON.stringify(filters),
    reRenderList,
  ]);

  return (
    <>
      <div className="innerContainer">
        <OrganizationHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        <OrginzationBody activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      {/* Delete Modal */}
      <DeleteOragnizationModal
        handleDelete={handleDeleteOraganization}
        disable={disableActionDeleteBtn}
        show={showHideOragnizationDeleteModa}
        onHide={() => {
          dispatch(setShowHideOrganizationDeleteModal(false));
        }}
        heading="Delete Organization?"
        message="Oraginzation has been deleted"
        notificationMessage="Organization"
        para={
          <>
            Are you sure you want to delete{" "}
            <b>{singleOrganizationDetail?.name}?</b>
          </>
        }
      />
    </>
  );
};

export default OrganizationPage;
