import { createSlice } from "@reduxjs/toolkit";

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: {
    renderTable: 1,
    uploadFileTabNo: 1,
    isResourcesLoading: true,
    isResourceLoading: true,
    isPdfResourcesLoading: true,
    isVideoResourcesLoading: true,
    isAudioResourcesLoading: true,
    firstStepTabNo: 0,
    disableActionBtn: false,
    selectedData: null,
    pageLoading: false,
    reRenderResourceDetailPage: 1,
    allResourcePagination: {
      page: 1,
      perPage: 12,
    },
    videoResourcePagination: {
      page: 1,
      perPage: 12,
    },
    audioResourcePagination: {
      page: 1,
      perPage: 12,
    },
    pdfResourcePagination: {
      page: 1,
      perPage: 12,
    },
    resources: {
      meta: null,
      data: [],
    },
    resource: {
      data: {},
    },
    resources_pdf: {
      meta: null,
      data: [],
    },
    resources_audio: {
      meta: null,
      data: [],
    },
    resources_video: {
      meta: null,
      data: [],
    },
    resourceAddEditForm: {
      show: false,
      formType: null,
    },
    activeTab: "",
  },
  reducers: {
    setUploadFileTabNo: (state, action) => {
      state.uploadFileTabNo = action.payload;
    },
    setIsResourcesLoading: (state, action) => {
      state.isResourcesLoading = action.payload;
    },
    setIsResourceLoading: (state, action) => {
      state.isResourcesLoading = action.payload;
    },
    setIsPdfResourcesLoading: (state, action) => {
      state.isPdfResourcesLoading = action.payload;
    },
    setIsVideoResourcesLoading: (state, action) => {
      state.isVideoResourcesLoading = action.payload;
    },
    setIsAudioResourcesLoading: (state, action) => {
      state.isAudioResourcesLoading = action.payload;
    },
    setResources: (state, action) => {
      state.resources = action.payload;
    },
    setPdfResources: (state, action) => {
      state.resources_pdf = action.payload;
    },
    setAudioResources: (state, action) => {
      state.resources_audio = action.payload;
    },
    setVideoResources: (state, action) => {
      state.resources_video = action.payload;
    },
    setResource: (state, action) => {
      state.resource.data = action.payload.data;
    },
    setFirstStepTabNo: (state, action) => {
      state.firstStepTabNo = action.payload;
    },
    setDisableActionBtn: (state, action) => {
      state.disableActionBtn = action.payload;
    },
    setResourceAddEditForm: (state, action) => {
      state.resourceAddEditForm = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setRenderTable: (state, action) => {
      state.renderTable = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setReRenderResourceDetailPage: (state, action) => {
      state.reRenderResourceDetailPage = action.payload;
    },
    setAllResourcePagination: (state, action) => {
      state.allResourcePagination = action.payload;
    },
    setVideoResourcePagination: (state, action) => {
      state.videoResourcePagination = action.payload;
    },
    setAudioResourcePagination: (state, action) => {
      state.audioResourcePagination = action.payload;
    },
    setPdfResourcePagination: (state, action) => {
      state.pdfResourcePagination = action.payload;
    },
    setActiveResourcesTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setIsResourcesLoading,
  setIsPdfResourcesLoading,
  setIsVideoResourcesLoading,
  setIsAudioResourcesLoading,
  setIsResourceLoading,
  setResources,
  setPdfResources,
  setAudioResources,
  setVideoResources,
  setResource,
  setFirstStepTabNo,
  setUploadFileTabNo,
  setDisableActionBtn,
  setResourceAddEditForm,
  setSelectedData,
  setRenderTable,
  setPageLoading,
  setReRenderResourceDetailPage,
  setAllResourcePagination,
  setVideoResourcePagination,
  setAudioResourcePagination,
  setPdfResourcePagination,
  setActiveResourcesTab,
} = resourcesSlice.actions;

export default resourcesSlice.reducer;
