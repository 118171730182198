import React, { useRef, useState } from "react";
import {
  Eye,
  EyeHide,
  ToastCrossIcon,
  ToastGreenCheckIcon,
} from "../../../constants/svg";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { maxBounds, minBounds, Patterns } from "../../../constants/contants";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import { ChangePassword } from "../../../services/auth/auth";

function UpdatePassword(props) {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const passwordWatch = useRef({});
  passwordWatch.current = watch("password", "");

  const cPasswordWatch = useRef({}); // Change this line
  cPasswordWatch.current = watch("password_confirmation", ""); // Change this line

  const handleDisableSubmit = () => {
    if (disable || !isValid || isSubmitting) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      const apiData = {
        current_password: data?.current_password,
        password: data?.password,
        password_confirmation: data?.password_confirmation,
      };
      setDisable(true);
      const res = await ChangePassword(apiData);
      reset();
      setDisable(false);

      notification.info({
        message: <span>{res.data.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      setDisable(false);
    }
  };

  return (
    <>
      <div className="updatePasswordBox">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="current_password"
              label="Current Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={passwordShow ? "text" : "password"}
                placeholder="xxxxxx"
                aria-describedby="passToggle"
                className={errors.current_password ? "hasError" : ""}
                minLength={6}
                maxLength={16}
                autoComplete="off"
                role={"presentation"}
                {...register("current_password", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                {passwordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="password"
              label="New Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={newPassword ? "text" : "password"}
                placeholder="xxxxxx"
                aria-describedby="passToggle"
                className={errors.password ? "hasError" : ""}
                minLength={6}
                maxLength={16}
                autoComplete="off"
                role={"presentation"}
                {...register("password", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setNewPassword(!newPassword)}
              >
                {newPassword ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="themeFormGroup">
            <FloatingLabel
              controlId="password_confirmation"
              label="Confirm New Password"
              className="mb-20 position-relative"
            >
              <Form.Control
                type={cPasswordShow ? "text" : "password"}
                placeholder="••••••••"
                aria-describedby="passToggle"
                minLength={6}
                maxLength={16}
                className={errors.password_confirmation ? "hasError" : ""}
                autoComplete="off"
                role={"presentation"}
                {...register("password_confirmation", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Password,
                    // message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                  },
                  validate: (value) =>
                    value == passwordWatch.current ||
                    "The passwords do not match",
                  minLength: {
                    value: 6,
                    // message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: 16,
                    // message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                })}
              />
              <div
                className="password-toggle-icon"
                onClick={() => setCPasswordShow(!cPasswordShow)}
              >
                {cPasswordShow ? <Eye /> : <EyeHide />}
              </div>
            </FloatingLabel>
          </Form.Group>

          <Button
            className="mb-0 w-100"
            size={"lg"}
            type={"submit"}
            disabled={handleDisableSubmit()}
          >
            {disable ? <div className="load black" /> : "Save Password"}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default UpdatePassword;
