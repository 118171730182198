import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetActivities = (params) => api.get(apiUrl.activities, { params });
export const UpdateActivity = (id, data) =>
  api.put(`${apiUrl.activities}/${id}`, data);
export const DeleteAllActivities = (salon_id) =>
  salon_id
    ? api.delete(`${apiUrl.delete_activities}/${salon_id}`)
    : api.delete(`${apiUrl.delete_activities}`);
export const ClearAllActivities = (salon_id) =>
  salon_id
    ? api.get(`${apiUrl.cleared_activities}/${salon_id}`)
    : api.get(`${apiUrl.cleared_activities}`);
