import api from "../index";
import { apiUrl } from "../../utils/constants";
import { GetAuthUserLocalStorage } from "../localStorage/localStorage";

export const GetMembers = async (params = {}) => {
  return await api.get(
    apiUrl.salons.get_team_by_id + GetAuthUserLocalStorage()?.salon?.id,
    { params },
  );
};
export const GetAllUsers = async (params = {}) => {
  return await api.get(apiUrl.users, { params });
};

export const DeleteMember = async (id) => {
  return await api.delete(apiUrl.users + "/" + id);
};

export const DeleteInvitedMember = async (id) => {
  return await api.delete(apiUrl.users + "/" + id);
};

export const GetMemberDetailByID = async (id) => {
  return await api.get(apiUrl.users + "/" + id);
};

export const CreateMember = async (payload) => {
  return await api.post(apiUrl.users, payload);
};

export const UpdateMember = async (id, payload) => {
  return await api.put(apiUrl.users + "/" + id, payload);
};

export const UpdateAllSchedule = async (userId, payload) => {
  return await api.put(apiUrl.userSchedule + "/" + userId, payload);
};

export const UpdateMemberRole = async (userId, roleId) => {
  return await api.post(apiUrl.userRole + "/" + userId + "/" + roleId);
};

export const GetUserSchedules = async (params) => {
  return await api.get(apiUrl.userSchedules, { params });
};
