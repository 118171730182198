import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { ModalCloseIcon } from "../../constants/svg";

const StatusModal = (props) => {
  const [msg, setMsg] = useState("");
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal statusModal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="custom-pl-20 text-center w-100"
          >
            {props.modalTitle}
            <p>{props.modalSubtitle}</p>
          </Modal.Title>

          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        <div className="modal-body">
          <Form.Control
            as="textarea"
            rows={8}
            resize={false}
            placeholder="Add Note (Optional)"
            onChange={(e) => {
              setMsg(e.target.value);
            }}
          />
        </div>
        <div className="modal-footer">
          <div className="btn-div d-flex w-100">
            <Button
              className={`solid-grey-sm-btn me-2 w-100`}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button
              disabled={props?.disable}
              className={`${props.modalButtonClass} w-100`}
              onClick={() => {
                props.onStatusUpdate(msg);
              }}
            >
              {props?.disable ? (
                <div className="load black" />
              ) : (
                props.modalButtonText
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StatusModal;
