import api from "../index";
import { apiUrl } from "../../utils/constants";

export const GetCandidateReferral = async (params) => {
  return await api.get(apiUrl.candidateReferral, { params });
};

export const GetCandidateReferralDetail = async (id) => {
  return await api.get(apiUrl.candidateReferral + "/" + id);
};

export const DeleteCandidateReferral = async (id) => {
  return await api.delete(apiUrl.candidateReferral + "/" + id);
};

export const ApproveCandidateReferral = async (id) => {
  return await api.post(apiUrl.candidateReferralApprove + "/" + id);
};
