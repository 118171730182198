import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    isSearchLoading: true,
    isSearchHistoryLoading: true,

    searchHistory: {
      meta: {
        total: 0,
      },
      data: [],
    },

    alerts: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    documents: {
      currentPage: 1,
      meta: null,
      data: [],
    },

    events: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    flashcards: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    goals: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    members: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    quizzes: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    requests: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    resources: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    surveys: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },

    tasks: {
      currentPage: 1,
      meta: {
        total: 0,
      },
      data: [],
    },
  },
  reducers: {
    setIsSearchLoading: (state, action) => {
      state.isSearchLoading = action.payload;
    },
    setIsSearchHistoryLoading: (state, action) => {
      state.isSearchHistoryLoading = action.payload;
    },

    setSearchHistory: (state, action) => {
      state.searchHistory.data = action.payload.data;
      state.searchHistory.meta = action.payload.meta;
    },
    setRemoveSearchHistory: (state, action) => {
      let data = [...state.searchHistory.data];
      data.splice(action.payload, 1);
      state.searchHistory.data = data;
    },
    setClearAllSearchHistory: (state, action) => {
      state.searchHistory.data = [];
    },

    setAlerts: (state, action) => {
      state.alerts.data = [...state.alerts.data, ...action.payload.data];
      state.alerts.meta = action.payload.meta;
    },
    setAlertsCurrentPage: (state, action) => {
      state.alerts.currentPage = state.alerts.currentPage + 1;
    },

    setDocuments: (state, action) => {
      state.documents.data = [...state.documents.data, ...action.payload.data];
      state.documents.meta = action.payload.meta;
    },
    setDocumentsCurrentPage: (state, action) => {
      state.documents.currentPage = state.documents.currentPage + 1;
    },

    setEvents: (state, action) => {
      state.events.data = [...state.events.data, ...action.payload.data];
      state.events.meta = action.payload.meta;
    },
    setEventsCurrentPage: (state, action) => {
      state.events.currentPage = state.events.currentPage + 1;
    },

    setFlashcards: (state, action) => {
      state.flashcards.data = [
        ...state.flashcards.data,
        ...action.payload.data,
      ];
      state.flashcards.meta = action.payload.meta;
    },
    setFlashcardsCurrentPage: (state, action) => {
      state.flashcards.currentPage = state.flashcards.currentPage + 1;
    },

    setGoals: (state, action) => {
      state.goals.data = [...state.goals.data, ...action.payload.data];
      state.goals.meta = action.payload.meta;
    },
    setGoalsCurrentPage: (state, action) => {
      state.goals.currentPage = state.goals.currentPage + 1;
    },

    setMembers: (state, action) => {
      state.members.data = [...state.members.data, ...action.payload.data];
      state.members.meta = action.payload.meta;
    },
    setMembersCurrentPage: (state, action) => {
      state.members.currentPage = state.members.currentPage + 1;
    },

    setQuizzes: (state, action) => {
      state.quizzes.data = [...state.quizzes.data, ...action.payload.data];
      state.quizzes.meta = action.payload.meta;
    },
    setQuizzesCurrentPage: (state, action) => {
      state.quizzes.currentPage = state.quizzes.currentPage + 1;
    },

    setRequests: (state, action) => {
      state.requests.data = [...state.requests.data, ...action.payload.data];
      state.requests.meta = action.payload.meta;
    },
    setRequestsCurrentPage: (state, action) => {
      state.requests.currentPage = state.requests.currentPage + 1;
    },

    setResources: (state, action) => {
      state.resources.data = [...state.resources.data, ...action.payload.data];
      state.resources.meta = action.payload.meta;
    },
    setResourcesCurrentPage: (state, action) => {
      state.resources.currentPage = state.resources.currentPage + 1;
    },

    setSurveys: (state, action) => {
      state.surveys.data = [...state.surveys.data, ...action.payload.data];
      state.surveys.meta = action.payload.meta;
    },
    setSurveysCurrentPage: (state, action) => {
      state.surveys.currentPage = state.surveys.currentPage + 1;
    },

    setTasks: (state, action) => {
      state.tasks.data = [...state.tasks.data, ...action.payload.data];
      state.tasks.meta = action.payload.meta;
    },
    setTasksCurrentPage: (state, action) => {
      state.tasks.currentPage = state.tasks.currentPage + 1;
    },

    setEmptySearches: (state, action) => {
      state.alerts.data = [];
      state.documents.data = [];
      state.events.data = [];
      state.flashcards.data = [];
      state.goals.data = [];
      state.members.data = [];
      state.quizzes.data = [];
      state.requests.data = [];
      state.resources.data = [];
      state.surveys.data = [];
      state.tasks.data = [];
    },
  },
});

export const {
  setIsSearchLoading,
  setAlerts,
  setAlertsCurrentPage,
  setDocuments,
  setDocumentsCurrentPage,
  setEvents,
  setEventsCurrentPage,
  setFlashcards,
  setFlashcardsCurrentPage,
  setGoals,
  setGoalsCurrentPage,
  setMembers,
  setMembersCurrentPage,
  setQuizzes,
  setQuizzesCurrentPage,
  setRequests,
  setRequestsCurrentPage,
  setResources,
  setResourcesCurrentPage,
  setSurveys,
  setSurveysCurrentPage,
  setTasks,
  setTasksCurrentPage,
  setEmptySearches,
  setIsSearchHistoryLoading,
  setSearchHistory,
  setRemoveSearchHistory,
  setClearAllSearchHistory,
} = searchSlice.actions;

export default searchSlice.reducer;
