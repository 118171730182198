import { ConfigProvider, Dropdown, Empty, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Assets } from "../../../constants/images";
import {
  BinocularsLight,
  EditIcon,
  LockIcon,
  LockKeyhole,
  MoreIcon,
  NoDataIcon,
  RecycleBin,
  ShareIcon,
} from "../../../constants/svg";
import { DeleteSingleEvent } from "../../../services/events/events";
import DeleteModal from "../../Modals/DeleteModal";
import ShareModal from "../../Modals/ShareModal";
import {
  setEventAddEditFormVisibility,
  setSelectedData,
} from "../../../store/slices/eventsSlice";
import { hasAccess, tableLoader, toastMessage } from "../../../utils/helper";
import { SHARE_URL, tableHeight } from "../../../utils/constants";
import { FaStar } from "react-icons/fa";
import LazyLoadTable from "../../Table/LazyLoadTable";

function Events(props) {
  const dispatch = useDispatch();

  const { eventAddEditFormVisibility, selectedData } = useSelector(
    (state) => state.events,
  );

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const url = SHARE_URL.EVENTS + selectedData?.key;

  const onClose = () => {
    setOpen(false);
  };

  const actionMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <>
            <NavLink to={`/events/details/${record?.key}`} className={"black"}>
              <BinocularsLight />
              View
            </NavLink>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "2",
        label: (
          <>
            <div
              onClick={() => {
                dispatch(setSelectedData(record));
                dispatch(
                  setEventAddEditFormVisibility({
                    show: true,
                    formType: "update",
                  }),
                );
              }}
            >
              <EditIcon />
              Edit
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            <div
              onClick={() => {
                dispatch(setSelectedData(record));
                setShowModal((prevState) => ({
                  ...prevState,
                  shareModal: true,
                }));
              }}
            >
              <ShareIcon />
              Share
            </div>
          </>
        ),
      },
      hasAccess(record?.user) && {
        key: "4",
        label: (
          <div
            className={"deleteLabel"}
            onClick={() => {
              dispatch(setSelectedData(record));
              setShowModal((prevState) => ({
                ...prevState,
                deleteModal: true,
              }));
            }}
          >
            <RecycleBin />
            Delete
          </div>
        ),
      },
    ];
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="adminRoleBox">
            <h6>
              Posted by Salon Symphony{" "}
              <span>
                <LockKeyhole />
              </span>{" "}
            </h6>
          </div>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "Event Name",
      dataIndex: "alertTitle",
      key: "alertTitle",
      sorter: (a, b) => a.alertTitle.title.localeCompare(b.alertTitle.title),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <>
          <NavLink
            to={`/events/details/${record?.key}`}
            className={"black imageTableWrapper"}
          >
            <Space size={10}>
              <img
                src={record.alertTitle.image}
                alt={record.alertTitle.title}
                className="eventDetailImage"
                onError={(e) => (e.target.src = Assets.GeneralPlaceholder)}
              />
              {record.alertTitle.lock && (
                <Dropdown
                  menu={{
                    items: [],
                  }}
                  overlayClassName="singleDropdown"
                  className="singLock imageMargin"
                  placement="bottom"
                  open={false}
                >
                  <Space>
                    {/* <LockIcon /> */}
                    <FaStar
                      color="#A8A8A8"
                      style={{ ":hover": { color: "#c4c4c4" } }}
                    />
                  </Space>
                </Dropdown>
              )}
              <span>{record.alertTitle.title}</span>
            </Space>
          </NavLink>
        </>
      ),
      width: "45%",
    },
    {
      title: "Category",
      dataIndex: "sendType",
      key: "sendType",
      sorter: (a, b) => a.sendType.main.localeCompare(b.sendType.main),
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p>
          {record.sendType.main}
          <span className="d-block">{record.sendType.sub}</span>
        </p>
      ),
      align: "left",
      width: "15%",
    },
    {
      title: "Start Date",
      dataIndex: "lastSent",
      key: "lastSent",
      sorter: (a, b) => {
        if (a.lastSent.main == "--") {
          return -1;
        } else if (b.lastSent.main == "--") {
          return 1;
        } else {
          const dateA = moment(a.lastSent.main, "MMM DD, YYYY");
          const dateB = moment(b.lastSent.main, "MMM DD, YYYY");
          return dateA?.isBefore(dateB) ? -1 : dateA?.isAfter(dateB) ? 1 : 0;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => (
        <p>
          {record.lastSent.main}
          <span className="d-block">{record.lastSent.sub}</span>
        </p>
      ),
      align: "left",
      width: "15%",
    },
    {
      title: "Going",
      dataIndex: "seenBy",
      key: "seenBy",
      className: "center-column",
      sorter: (a, b) => a.seenBy > b.seenBy,
      sortDirections: ["descend", "ascend", "descend"],
      render: (text, record) => {
        const attendeeNames = record.attendeeNames || [];
        const attendeeTooltipContent = (
          <div className="attendeeNames">
            {attendeeNames.slice(0, 7).map((name, index) => (
              <React.Fragment key={index}>
                <span>{name}</span>
                {index < attendeeNames.slice(0, 7).length - 1}
              </React.Fragment>
            ))}
            {attendeeNames.length > 7 && (
              <>
                <strong>+{attendeeNames.length - 7} more</strong>
              </>
            )}
          </div>
        );
        return (
          <div className="">
            {record?.goingUsers.length != 0 ? (
              <Tooltip
                title={attendeeTooltipContent}
                placement="bottom"
                overlayClassName="custom-tooltip"
                color="purple"
              >
                <div className="seenBy dottedRoundedBox">
                  <span>{record?.goingUsers.length}</span>
                </div>
              </Tooltip>
            ) : (
              <div className="seenBy dottedRoundedBox">
                <span>{record?.goingUsers.length}</span>
              </div>
            )}
          </div>
        );
      },

      align: "center",
      width: "15%",
    },
    {
      title: "More",
      key: "action",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionMenu(record),
            }}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"defaultDropdown"}
          >
            <div className="icon cursor-pointer">
              <MoreIcon />
            </div>
          </Dropdown>
        </Space>
      ),
      width: "10%",
    },
  ];

  const handleDelete = async () => {
    try {
      setDisable(true);

      const res = await DeleteSingleEvent(selectedData?.key);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", res?.data?.message);
      setDisable(false);
      props?.onDelete();
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    }
  };

  return (
    <>
      <div className={props?.data?.length == 0 ? "task__container_empty" : ""}>
        <ConfigProvider
          renderEmpty={() => (
            <div className="noSearchData">
              <NoDataIcon />
              <h5>Events you've posted will appear here.</h5>
              <h6>Click "Post Event" to get started.</h6>
            </div>
          )}
        >
          <Table
            // locale={{
            //   emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
            // }}
            columns={columns}
            store={{ store: "events", param: props.storeState }}
            loading={tableLoader(props?.isLoading)}
            dataSource={props?.data}
            bordered={false}
            scroll={{ x: "max-content", y: tableHeight?.height }}
            responsive={true}
            size={"large"}
            showSorterTooltip={false}
            className="alertTable imageTable"
            pagination={false}
            // pagination={{
            //   onChange: (currPage) =>
            //     props?.setPage({ ...props?.page, currentPage: currPage }),
            //   pageSize: props?.page?.perPage,
            //   current: props?.page?.currentPage,
            //   total: props?.totalPage || 0,
            //   pageSizeOptions: [10, 20, 50, 100, "All"],
            //   showSizeChanger: false,
            // }}
          />
        </ConfigProvider>
      </div>
      {/* Share Modal */}
      <ShareModal
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Event"}
        modalSubtitle={
          <>
            Click “Copy Link” below, then{" "}
            <NavLink
              to="/inbox"
              className={"uLine blue-accent-color"}
              target="_blank"
            >
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this event with members.
          </>
        }
        data={selectedData}
        shareLink={url}
        size={"lg"}
        bodyComponent={{
          img: Assets.eventShareModal,
          title: "Advanced Balayage Master Class",
          para1:
            "Lenny Strand will be at our Scarborough location teaching a FREE Advanced Balayage Master Class on September 12. This is a free event and recommended for all stylists.",
          para2: "Sep 12, 2023 • 9:00 AM EST",
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        show={showModal.deleteModal}
        onHide={() => setShowModal({ ...showModal, deleteModal: false })}
        heading="Delete Event?"
        handleDelete={handleDelete}
        disable={disable}
        para={
          <>
            Are you sure you want to delete{" "}
            <b>{selectedData?.alertTitle?.title || "this event"}?</b>
            <br />
            Important: This event cannot be recovered.
          </>
        }
        notificationMessage="Event"
      />
    </>
  );
}

export default Events;
