import React, { useState } from "react";

import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Select, message, notification, Upload } from "antd";
import {
  DropPhotoHere,
  ModalCloseIcon,
  RoundedStaffIcon,
} from "../../constants/svg";
import { useForm } from "react-hook-form";
import {
  maxBounds,
  Patterns,
  VALIDATIONS_TEXT,
} from "../../constants/contants";
import Dragger from "antd/es/upload/Dragger";
import CustomMembersSelect from "../Common/CustomMembersSelect";

const NewGroupChatModal = (props) => {
  // Dragger image options
  const DraggerProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5185415ba171ea3a00704eed",
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    onChange(info) {
      const { status, response } = info.file;
      if (status != "uploading") {
      }
      if (status == "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status == "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  return (
    <>
      <div className="modal-body">
        <Row>
          <Col lg={12}>
            <FloatingLabel
              controlId="DocName"
              label="Group Name"
              className="mb-20"
            >
              <Form.Control
                type="text"
                placeholder="Group Name"
                autoComplete="off"
                role={"presentation"}
                className={errors.eventName ? "hasError" : ""}
                maxLength={maxBounds.NAME}
                {...register("eventName", {
                  required: {
                    value: true,
                    // message: VALIDATIONS_TEXT.NAME_REQUIRED,
                  },
                  pattern: {
                    value: Patterns.Name,
                    // message: VALIDATIONS_TEXT.FIELD_REQUIRE,
                  },
                  maxLength: {
                    value: maxBounds.NAME,
                    // message: VALIDATIONS_TEXT.NAME_MAX,
                  },
                })}
              />
            </FloatingLabel>
          </Col>

          <div className="mb-20">
            <CustomMembersSelect />
          </div>
          <Col lg={12}>
            <p className="small-font">Upload Attachment</p>
            <Dragger
              {...DraggerProps}
              className="border-0 bg-0 height-dragger"
              listType="picture"
            >
              <DropPhotoHere />
            </Dragger>
          </Col>
        </Row>
      </div>

      <div className="modal-footer">
        <Button
          type="button"
          className={" w-100"}
          onClick={props.handleNewGroupChatFunc}
        >
          {props.buttonTrue == true ? "Start Group Chat" : "Save Group"}
        </Button>
      </div>
    </>
  );
};

export default NewGroupChatModal;
