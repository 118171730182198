import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Image, Modal, Row } from "react-bootstrap";
import { Assets } from "../../constants/images";
import { ModalCloseIcon } from "../../constants/svg";
import CopylinkBar from "../Common/CopylinkBar";

const ShareModal = (props) => {
  const maxLength = 30;

  const [paragraph, setParagraph] = useState("");

  const handleParagraphClass = (paragraph = "") => {
    const words = paragraph?.split(" ");
    const wordCount = words?.length;

    if (wordCount > maxLength) {
      return words.slice(0, maxLength).join(" ") + " ..."; // Trim and add ellipsis
    }

    return paragraph;
  };

  useEffect(() => {
    setParagraph(props.data?.description);
  }, [props.data]);
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal shareModal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" text-left w-100"
          >
            {props.modalTitle}
            <p>{props.modalSubtitle}</p>
          </Modal.Title>

          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        {/* All body Compponents goes here... */}
        <div
          className={
            props?.data?.image
              ? "modal-body"
              : "modal-body share-modal-without-image"
          }
        >
          {props?.data?.image ? (
            <Row>
              <Col lg={5}>
                <div
                  className="img-wrapper"
                  style={{
                    width: "214px !important",
                    height: "140px",
                    borderRadius: "4px",
                  }}
                >
                  <Image
                    className="w-100"
                    src={props?.data?.image || Assets.GeneralPlaceholder}
                    onError={(e) => (e.target.src = Assets.GeneralPlaceholder)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Col>
              <Col lg={7} className="p-0">
                <div className="shareContainer">
                  <h6>
                    <b>{props?.data?.name || "-"}</b>
                  </h6>
                  <p
                    className={
                      paragraph?.split(" ").length > maxLength ? "ellipsis" : ""
                    }
                  >
                    {handleParagraphClass(paragraph)}
                  </p>
                  <p>
                    {props?.data?.start_date
                      ? moment(props?.data?.start_date).format("MMM DD, yyyy") +
                        " • " +
                        props?.data?.start_time
                      : "-"}
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12}>
                <div className="shareContainer">
                  <h6>
                    <b>{props?.data?.name || "-"}</b>
                  </h6>
                  <p
                    className={
                      paragraph?.split(" ").length > maxLength ? "ellipsis" : ""
                    }
                  >
                    {handleParagraphClass(paragraph)}
                  </p>
                  <p>
                    {props?.data?.start_date
                      ? moment(props?.data?.start_date).format("MMM DD, yyyy") +
                        " • " +
                        props?.data?.start_time
                      : "-"}
                  </p>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <CopylinkBar
            closeAction={props.onHide}
            link={props?.shareLink || ""}
          />
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;
