import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  RedAlertIcon,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import { notification } from "antd";

const DeleteOragnizationModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-doc-modal"
      >
        <Modal.Body>
          <div className="leaveModalIcon">
            {props.icon ? props.icon : <RedAlertIcon />}
          </div>
          <h3>{props.heading}</h3>
          <p>{props.para}</p>

          <div className="btn-div">
            <Button className="border-grey-sm-btn me-2" onClick={props.onHide}>
              {props.cancelText ? props.cancelText : "Cancel"}
            </Button>
            <Button
              disabled={props?.disable}
              className="solid-red-sm-btn"
              onClick={() => {
                props.handleDelete();
              }}
            >
              {props?.disable ? <div className="load black" /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteOragnizationModal;
