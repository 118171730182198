import { Image, Tabs, message } from "antd";
import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import FolderTab from "../../Common/FolderTab";
import GalleryTab from "../../Common/GalleryTab";
import { useDispatch } from "react-redux";
import { ModalCloseIcon, DeleteIcon } from "./../../../constants/svg";
import { setSelectPreUploadedFile } from "../../../store/slices/globalSlice";
import FileUploader from "../../Common/FileUploader";

const props = {
  name: "file",
  multiple: false,
  beforeUpload: (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  },
  showUploadList: {
    showDownloadIcon: false,
    showRemoveIcon: true,
    removeIcon: <ModalCloseIcon />,
  },
};

const ImageUploaderModal = ({
  handleChangeLocalUpload,
  handleChangeGalleryUpload,
  handleChangeFolderUpload,
  handleClickBtnSubmit,
  showUploadImage = null,
  selectedFrontBackImageUploadIndex,
  handleOpenFrontBackImageDelete,
  imageUploaderDoneBtn,
  handelImageUploaderDoneBtn,
}) => {
  const dispatch = useDispatch();
  const [localUpload, setLocalUpload] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const TabsContent = [
    {
      key: 1,
      label: `Gallery`,
      children: (
        <>
          <GalleryTab
            incSearch={true}
            handleChange={handleChangeGalleryUpload}
          />
        </>
      ),
    },
    {
      key: 2,
      label: `Folders`,
      children: (
        <>
          <FolderTab handleChange={handleChangeFolderUpload} />
        </>
      ),
    },
    {
      key: 3,
      label: `Upload`,
      children: (
        <div style={{ marginTop: "16px" }}>
          <FileUploader
            {...props}
            fileList={localUpload ? [localUpload] : null}
            onRemove={(e) => {
              handleChangeLocalUpload(null);
            }}
            onChange={(e) => {
              const fileList = e.fileList;
              const singleImage = fileList.length
                ? fileList.length > 1
                  ? fileList[1]
                  : fileList[0]
                : "";
              setLocalUpload(singleImage);
              handleChangeLocalUpload(singleImage);
            }}
            beforeUpload={() => {
              return false;
            }}
            className="border-0 bg-0"
            listType="picture"
            limitMegabyteSize={4}
          />
        </div>
      ),
    },
  ];

  const handleTabsChange = (tabNo) => {
    setLocalUpload("");
    handleChangeLocalUpload(null);
    handleChangeGalleryUpload(null);
    handleChangeFolderUpload(null);
    dispatch(setSelectPreUploadedFile(""));
    setActiveTab(tabNo);
    handelImageUploaderDoneBtn(true);
  };
  const handleDelete = () => {
    handleOpenFrontBackImageDelete(
      selectedFrontBackImageUploadIndex.index,
      selectedFrontBackImageUploadIndex.type,
    );
  };

  return (
    <>
      <div className="modal-body tabs-modal">
        <p className="proTipText mb-20">
          <span>Pro Tip:</span> Upload a cover photo or choose from the gallery.
          For best results, use an image with little or no text.
        </p>

        <Tabs
          defaultActiveKey={1}
          activeKey={activeTab}
          items={TabsContent}
          onChange={handleTabsChange}
        />

        {showUploadImage && (
          <Row>
            <Col md={12}>
              <div className="img-upload-preview">
                {/* <strong>Preview</strong> */}
                {/* <br /> */}
                <Image
                  className="ant-img-custom-css upload-img"
                  preview={false}
                  src={showUploadImage}
                />
                <Button
                  type="button"
                  className={"delete-img"}
                  onClick={() => handleDelete()}
                >
                  <ModalCloseIcon />
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className="modal-footer">
        <div className="custom-footer-btns w-100">
          <Row>
            <Col lg={12} className="p-0">
              <Button
                type="button"
                className="custom-width-690"
                onClick={handleClickBtnSubmit}
                disabled={imageUploaderDoneBtn}
              >
                Done
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ImageUploaderModal;
