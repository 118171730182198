import React from "react";
import { Button } from "react-bootstrap";

function TourBox(props) {
  return (
    <div className={`tourBox step${props.stepNumber}`}>
      <div className="triangle"></div>
      <h3>{props.heading}</h3>
      <p>{props.para}</p>
      <div className="tourbuttons">
        {props.backFunc && (
          <Button
            className="mb-0 btn-gray me-2 border-radius-2rem"
            size={"sm"}
            onClick={props.backFunc}
          >
            Back
          </Button>
        )}
        {props.nextFunc && (
          <Button
            className="mb-0  me-2 border-radius-2rem"
            size={"sm"}
            onClick={props.nextFunc}
          >
            {props?.nextBtnText ? props?.nextBtnText : "Next"}
          </Button>
        )}
        {props.skipFunc && (
          <Button
            className="mb-0 no-bg smallbtn br-2rem-hover"
            size={"sm"}
            onClick={() => {
              props.skipFunc();
            }}
          >
            {props.skipText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default TourBox;
