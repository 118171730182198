import { createSlice } from "@reduxjs/toolkit";

export const storageSlice = createSlice({
  name: "storage",
  initialState: {
    isStoragesLoading: false,
    storages: {
      data: [],
    },
    singleStorage: {
      isSingleLoading: false,
      data: null,
    },
    singleStoragePaymentLink: {
      isSinglePMLinkLoading: false,
      data: null,
    },
  },
  reducers: {
    setIsStoragesLoading: (state, action) => {
      state.isStoragesLoading = action.payload;
    },
    setStorages: (state, action) => {
      state.storages.data = action.payload.data;
    },
    setIsSingleStorageLoading: (state, action) => {
      state.singleStorage.isSingleLoading = action.payload;
    },
    setSingleStorage: (state, action) => {
      state.singleStorage.data = action.payload;
    },
    setSingleStoragePaymentLinkLoading: (state, action) => {
      state.singleStoragePaymentLink.isSinglePMLinkLoading = action.payload;
    },
    setSingleStoragePaymentLink: (state, action) => {
      state.singleStoragePaymentLink.data = action.payload;
    },
  },
});

export const {
  setIsSingleStorageLoading,
  setIsStoragesLoading,
  setSingleStorage,
  setSingleStoragePaymentLink,
  setSingleStoragePaymentLinkLoading,
  setStorages,
} = storageSlice.actions;

export default storageSlice.reducer;
