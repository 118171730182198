import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  maxBounds,
  Patterns,
  VALIDATIONS_TEXT,
} from "../../../constants/contants";
import {
  setCreateUpdateModuleTitle,
  setCreateUpdateTitleModuleID,
} from "../../../store/slices/globalSlice";
import {
  ModalCloseIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
  DropPDF,
  AcrobatIcon,
} from "../../../constants/svg";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import {
  status as recordStatus,
  search_module_type,
} from "../../../utils/constants";
import {
  setActionButtonDisable,
  setSelectedData,
  setDocAddEditForm,
  setReRenderTable,
  setReRenderDocDetailPage,
} from "../../../store/slices/documentSlice";
import {
  checkPDFBySrc,
  salonUploadToS3,
  toastMessage,
} from "../../../utils/helper";
import {
  CreateDocument,
  UpdateDocument,
  GetDocumentDetailByID,
} from "../../../services/team/document";
import { UpdateFileUploadStorageSizeAvailability } from "../../../services/global/global";
import CustomUploader from "../../Common/CustomUploader";

const CreateNewDocModal = (props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      docName: "",
      description: "",
      file: "",
      preview: "",
      size: "",
    },
  });

  const { currentSalonId } = useSelector((state) => state.global);
  const watchPreview = watch("preview");

  const dispatch = useDispatch();
  const {
    selectedData,
    reRenderTable,
    docAddEditForm,
    actionButtonDisable,
    reRenderDocDetailPage,
  } = useSelector((state) => state.document);

  const onSubmit = async (inputFields) => {
    try {
      const payload = {
        title: inputFields.docName,
        description: inputFields.description,
        salon_id: currentSalonId,
        status: recordStatus.active,
      };

      let salonS3UploadResponse;

      dispatch(setActionButtonDisable(true));

      if (inputFields.file != "") {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/doc`;
        salonS3UploadResponse = await salonUploadToS3(inputFields.file, folder);
        payload.link = salonS3UploadResponse.data.location;
        payload.size = salonS3UploadResponse.data.size;
      } else if (getValues("preview")) {
        payload.link = getValues("preview");
        payload.size = getValues("size");
      }

      let response;
      if (docAddEditForm.formType == "create") {
        response = await CreateDocument(payload);
      } else if (docAddEditForm.formType == "update") {
        response = await UpdateDocument(selectedData.id, payload);
      }
      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      if (salonS3UploadResponse) {
        await UpdateFileUploadStorageSizeAvailability(
          salonS3UploadResponse.data.size,
        );
      }

      dispatch(setSelectedData(null));

      dispatch(
        setDocAddEditForm({
          show: false,
          formType: null,
        }),
      );

      dispatch(setReRenderDocDetailPage(reRenderDocDetailPage + 1));
      dispatch(setReRenderTable(reRenderTable + 1));

      toastMessage("success", message);
    } catch (e) {
      toastMessage("success", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const handleDisableSubmit = () => {
    if (actionButtonDisable || !isValid || isSubmitting) {
      return true;
    } else {
      return false;
    }
  };

  const getDocByID = async () => {
    try {
      const response = await GetDocumentDetailByID(selectedData.id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      setValue("docName", data.title);
      setValue("description", data.description);
      setValue("preview", data.link);
      setValue("size", data.size);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  useEffect(() => {
    if (docAddEditForm.formType == "update") {
      getDocByID();
    }
  }, [docAddEditForm]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.documents));
  }, []);
  const Draggerprops = {
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <ModalCloseIcon />,
    },
  };

  const isPDF = checkPDFBySrc(watchPreview);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={`modal-body ${props.customModalBody}`}>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="DocName"
                label="Doc Name"
                className="mb-20 custom-textarea"
              >
                <Form.Control
                  type="text"
                  placeholder="ex. Employee Contract"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.docName ? "hasError" : ""}
                  maxLength={maxBounds.NAME}
                  {...register("docName", {
                    required: "Doc Name is required",
                    onChange: (e) => {
                      dispatch(setCreateUpdateModuleTitle(e.target.value));
                    },
                  })}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="description"
                label="Description (Optional)"
                className="mb-20 custom-textarea"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Description (Optional)"
                  style={{ height: "58px" }}
                  className={errors.description ? "hasError" : ""}
                  {...register("description", {
                    required: false,
                  })}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <p className="small-font mb-3" style={{}}>
                Upload Attachment
              </p>
              <div className="addLink-Box resource-pdf">
                <Controller
                  control={control}
                  name="file"
                  rules={{
                    required: watchPreview == "" ? "File is required" : false,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <CustomUploader
                      {...props}
                      showUploadList={{
                        showDownloadIcon: false,
                        showPreviewIcon: true,
                      }}
                      className={`border-0 bg-0 three-type-upload ${errors.file ? "hasError" : ""}`}
                      accept={"pdf,.png,.jpg,.jpeg,.doc,.docx,.pdf"}
                      fileList={value == "" ? [] : [value]}
                      listType="picture"
                      onChange={(e) => {
                        const fileList = e.fileList;
                        setValue("preview", "");
                        setValue("size", "");
                        onChange(
                          !fileList
                            ? ""
                            : fileList.length > 1
                              ? fileList[1]
                              : fileList[0],
                        );
                      }}
                      iconRender={(file, listType) => {
                        if (file.type === "application/pdf")
                          return (
                            <span
                              role="img"
                              aria-label="pdf-icon"
                              className="anticon"
                            >
                              <AcrobatIcon />
                            </span>
                          );
                      }}
                      beforeUpload={() => {
                        /* update state here */
                        return false;
                      }}
                    >
                      <DropPDF />
                    </CustomUploader>
                  )}
                />
              </div>
            </Col>
          </Row>
          {watchPreview != "" && (
            <Row>
              <Col md={12} className="mt-2">
                <div className="ant-upload-list-item ant-upload-list-item-undefined upload-doc">
                  <div className="ant-upload-icon">
                    {isPDF ? (
                      <span className="uploadIconPdfView">
                        <AcrobatIcon />
                      </span>
                    ) : (
                      <span
                        role="img"
                        aria-label="paper-clip"
                        className="anticon anticon-paper-clip"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="paper-clip"
                          width="1em"
                          height="1em"
                          fill="#7b68ee"
                          aria-hidden="true"
                        >
                          <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                        </svg>
                      </span>
                    )}
                  </div>
                  <span
                    className="ant-upload-list-item-name"
                    title={watchPreview}
                  >
                    {watchPreview}
                  </span>
                  <span className="ant-upload-list-item-actions">
                    <button
                      title="Remove file"
                      type="button"
                      onClick={() => {
                        setValue("preview", "");
                      }}
                      className="ant-btn css-dev-only-do-not-override-1owjn6r ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"
                    >
                      <span className="ant-btn-icon">
                        <span
                          role="img"
                          aria-label="delete"
                          tabIndex="-1"
                          className="anticon anticon-delete"
                        >
                          <ModalCloseIcon />
                        </span>
                      </span>
                    </button>
                  </span>
                </div>
                {/* <strong>Link</strong> */}
                {/* <p>{watchPreview}</p> */}
              </Col>
            </Row>
          )}
        </div>

        <div className="modal-footer">
          <Button
            type="submit"
            className={" w-100"}
            disabled={handleDisableSubmit()}
          >
            {actionButtonDisable && <div className="load black" />}
            {!actionButtonDisable && docAddEditForm.formType == "create"
              ? "Save Doc"
              : ""}
            {!actionButtonDisable && docAddEditForm.formType == "update"
              ? "Update Doc"
              : ""}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateNewDocModal;
