import { createSlice } from "@reduxjs/toolkit";

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: {
    isActivitiesLoading: true,
    isNewActivitiesLoading: true,
    isNewActivitiesPageLoading: true,
    isClearedActivitiesPageLoading: true,
    isClearedActivitiesLoading: true,

    activities: {
      meta: null,
      data: [],
    },

    newActivities: {
      meta: null,
      data: [],
    },
    newActivitiesPage: {
      meta: null,
      data: [],
    },

    clearedActivities: {
      meta: null,
      data: [],
    },

    clearedActivitiesPage: {
      meta: null,
      data: [],
    },
  },
  reducers: {
    setIsActivitiesLoading: (state, action) => {
      state.isActivitiesLoading = action.payload;
    },
    setActivities: (state, action) => {
      state.activities.meta = action.payload.meta;
      state.activities.data = action.payload.data;
    },

    setIsNewActivitiesLoading: (state, action) => {
      state.isNewActivitiesLoading = action.payload;
    },
    setIsNewActivitiesPageLoading: (state, action) => {
      state.isNewActivitiesPageLoading = action.payload;
    },
    setIsClearedActivitiesLoading: (state, action) => {
      state.isClearedActivitiesLoading = action.payload;
    },

    setIsClearedActivitiesPageLoading: (state, action) => {
      state.isClearedActivitiesPageLoading = action.payload;
    },

    setNewActivities: (state, action) => {
      state.newActivities.data = action.payload.data;
      state.newActivities.meta = action.payload.meta;
    },
    setNewActivitiesPage: (state, action) => {
      state.newActivitiesPage.data = action.payload.data;
      state.newActivitiesPage.meta = action.payload.meta;
    },

    setClearedActivities: (state, action) => {
      state.clearedActivities.data = action.payload.data;
      state.clearedActivities.meta = action.payload.meta;
    },
    setClearedActivitiesPage: (state, action) => {
      state.clearedActivitiesPage.data = action.payload.data;

      state.clearedActivitiesPage.meta = action.payload.meta;
    },
  },
});

export const {
  setIsActivitiesLoading,
  setActivities,

  setIsNewActivitiesLoading,
  setIsClearedActivitiesLoading,

  setIsNewActivitiesPageLoading,
  setIsClearedActivitiesPageLoading,

  setNewActivities,
  setClearedActivities,
  setNewActivitiesPage,
  setClearedActivitiesPage,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
