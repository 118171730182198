import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { notification } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PlansBullets,
  ToastCrossIcon,
  ToastRedTrashIcon,
} from "../../constants/svg";
import {
  setIsBillingPageLoading,
  setIsCancellingSubscription,
  setIsCreatingPaymentLink,
  setPackages,
} from "../../store/slices/billingAndPlanSlice";
import { setStatistics } from "../../store/slices/dashboardSlice";
import {
  setCallCheckSubscriptionAPI,
  setCanceledSubscriptionID,
  setCurrentSubscriptionDetail,
  setSubscriptionStatus,
} from "../../store/slices/subscriptionSlice";
import {
  CancelSubscription,
  CreatePaymentLink,
  GetCurrentSubscription,
  GetPackagesList,
} from "../../services/subscription/subscription";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import DeleteModal from "../../components/Modals/DeleteModal";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { GetStatistics } from "../../services/dashboard/dashboard";
import PackageListingModal from "../../components/Modals/PackageListingModal";
import {
  roles as salonRoles,
  status,
  subscriptionStatus,
} from "../../utils/constants";

const BillingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isBillingPageLoading,
    packages,
    isCreatingPaymentLink,
    isCancellingSubscription,
  } = useSelector((state) => state.billingAndPlan);

  const { salon } = useSelector((state) => state.global);

  const { currentSubscriptionDetail, callCheckSubscriptionAPI } = useSelector(
    (state) => state.subscription,
  );
  const { statistics } = useSelector((state) => state?.dashboard);

  const [currentClickPlan, setCurrentClickPlan] = useState(null);
  const [downgradeWarningPopup, setDowngradeWarningPopup] = useState(false);
  const [cancelSubscriptionPopup, setCancelSubscriptionPopup] = useState(false);
  const [memberNeedToRemove, setMemberNeedToRemove] = useState(0);
  const [modalShowPricing, setModalShowPricing] = useState(false);

  const handleSubscribePackage = async (packageId = 0) => {
    const willingToSelectPackage = packages.find(
      (elem) => elem?.id == packageId,
    );
    if (
      willingToSelectPackage.total_members <=
      currentSubscriptionDetail.total_members
    ) {
      const currentAddedMemberDiff =
        parseInt(salon.meta.members) -
        parseInt(willingToSelectPackage.total_members);
      if (currentAddedMemberDiff > 0) {
        setMemberNeedToRemove(currentAddedMemberDiff);
        setDowngradeWarningPopup(true);
        return;
      }
    }

    if (isCreatingPaymentLink || isCancellingSubscription) return;
    try {
      dispatch(setIsCreatingPaymentLink(true));
      setCurrentClickPlan(packageId);
      const response = await CreatePaymentLink(packageId);
      const { data, message, status } = response.data;
      if (!status || !data) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "deletedNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      window.location.href = data?.payment_link;
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottomRight",
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      setCurrentClickPlan(null);
      dispatch(setIsCreatingPaymentLink(false));
    }
  };

  const getAllPackages = async () => {
    try {
      if (packages.length == 0) {
        dispatch(setIsBillingPageLoading(true));
      }

      const response = await GetPackagesList({
        is_paginated: 0,
        team: GetAuthUserLocalStorage()?.salon?.booking_software ?? "",
        "order-column": "total_members",
        order: "asc",
      });

      const { data, message, status } = response.data;

      if (!status || !data) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "deletedNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      const selectedPackage = data.find(
        (elem) => elem?.id == currentSubscriptionDetail?.billing_plan?.id,
      );
      const unSelectedPackages = data.filter(
        (elem) => elem?.id != currentSubscriptionDetail?.billing_plan?.id,
      );
      unSelectedPackages.unshift(selectedPackage);
      dispatch(setPackages(unSelectedPackages));
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottomRight",
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setIsBillingPageLoading(false));
    }
  };

  const getCurrentSubscription = async () => {
    try {
      const response = await GetCurrentSubscription(
        GetAuthUserLocalStorage()?.salon?.id,
      );
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "deletedNotification",
          icon: <ToastRedTrashIcon />,
          closeIcon: <ToastCrossIcon />,
        });
      }

      if (!data) {
        dispatch(setSubscriptionStatus(subscriptionStatus.none));
        setModalShowPricing(true);
        return;
      }

      if (data.status == subscriptionStatus.canceled) {
        dispatch(setCanceledSubscriptionID(data.billing_plan_id));
        dispatch(setSubscriptionStatus(subscriptionStatus.canceled));
        setModalShowPricing(true);
        return;
      }

      dispatch(setSubscriptionStatus(subscriptionStatus.paid));
      dispatch(setCurrentSubscriptionDetail(data));
    } catch (err) {
    } finally {
    }
  };

  const handleCancelPlan = async () => {
    try {
      dispatch(setIsCancellingSubscription(true));
      const response = await CancelSubscription(currentSubscriptionDetail?.id);
      const { data, message, status } = response.data;
      if (data) {
        dispatch(setCallCheckSubscriptionAPI(callCheckSubscriptionAPI + 1));
        navigate("/dashboard");
        return;
      }
      notification.error({
        message,
        placement: "bottomRight",
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (err) {
      notification.error({
        message: err.message,
        placement: "bottomRight",
        className: "deletedNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setIsCancellingSubscription(false));
    }
  };

  const getStatistics = async () => {
    let auth = GetAuthUserLocalStorage();
    const params = {
      salon_id: auth?.salon_id,
    };
    const res = await GetStatistics(params);
    const data = res.data.data;
    dispatch(
      setStatistics({
        totalEventsCount: data?.eventCount,
        totalMembersCount: data?.membersCount,
        totalGoalsCount: data?.goalCount,
        totalTasksCount: data?.taskCount,
        accountCount: data?.accountCount,
        openTickets: data?.openTickets,
        requestsCount: data?.requestsCount,
        invitesCount: data?.invitesCount,
      }),
    );
  };
  useEffect(() => {
    getStatistics();
    getCurrentSubscription();
  }, []);

  useEffect(() => {
    if (currentSubscriptionDetail) {
      getAllPackages();
    }
  }, [currentSubscriptionDetail]);

  if (isBillingPageLoading) {
    return (
      // <Space size="middle" className="before-page-data-load-spinner">
      //   <Spin size="large" />
      // </Space>
      <LoadingScreen child={true} />
    );
  }

  return (
    <>
      <section className={"billingpagewrapper"}>
        <div className={"billingheader border-line"}>
          <Row className="mb-0">
            <Col lg={8} md={8} className={"pe-0"}>
              <h2 className="sec-heading">Subscription</h2>
            </Col>

            {/* <Col lg={4} md={4} className={" text-end "}>
              <NavLink
                to="/"
                className={"newbtn-currentplan"}
                onClick={(e) => {
                  e.preventDefault();
                  setCancelSubscriptionPopup(true);
                }}
              >
                Cancel Plan
              </NavLink>
            </Col> */}
          </Row>

          <Row className="mb-0">
            <Col lg={8} md={8} className={"pe-0"}>
              <p className="billing-paragraph">
                Click <b>Manage</b> to view and update your subscription or{" "}
                <a
                  href={
                    "https://support.mysalonsymphony.com/hc/en-us/requests/new?ticket_form_id=16817626638235"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  contact support
                </a>
                .
              </p>
            </Col>
          </Row>
        </div>

        <div className={"planbilling-area "}>
          <div className="billingPlanTitle">
            <div className={"billingPlanTitleText"}>
              <h2 className="sec-heading">Business Plan</h2>
            </div>
            <div className="customButton">
              <NavLink
                to="https://billing.stripe.com/p/login/9AQ6oLcEvcen4TubII"
                className="customManageBtn"
                target="_blank"
              >
                <span className="manageText">Manage</span>
              </NavLink>
            </div>
          </div>
          <div className="addBottomMargin addTopMargin">
            <p className={"text-start billing-paragraph"}>
              <b>
                {statistics.totalMembersCount + statistics.invitesCount || 0} of{" "}
                {salon?.meta?.total_members >= 999
                  ? "Unlimited"
                  : salon?.meta?.total_members || 0}
              </b>{" "}
              users,{" "}
              <b>
                {salon?.storage_available_gbs
                  ? salon?.storage_available_gbs.toFixed(2)
                  : 0}{" "}
                GB of{" "}
                {salon?.meta?.total_storage >= 999
                  ? "Unlimited"
                  : `${salon?.meta?.total_storage} GB` || "0 GB"}{" "}
              </b>{" "}
              storage
            </p>
          </div>
          <div>
            <table className="customTable">
              <tr className="tableRowUnderline tableRowTitle">
                <td>Subscription</td>
                <td>Users</td>
                <td>Storage</td>
                <td>Cost</td>
              </tr>
              {packages
                ?.filter((elem) => elem?.status == 10)
                .map((elem) => (
                  <tr className="tableRowUnderline childrenTableRow">
                    <td>
                      <img
                        src={require("../../../src/assets/images/ss-logo.png")}
                      />
                      <span className="marginLogo">{elem?.name}</span>
                    </td>
                    <td>
                      {elem?.total_members < 999
                        ? elem?.total_members
                        : "Unlimited"}
                    </td>
                    <td>{elem?.storage?.name}</td>
                    <td>
                      {elem?.storage?.currencyUnit}
                      {elem?.price}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </section>
      <PackageListingModal
        handleHide={() => setModalShowPricing(false)}
        show={modalShowPricing}
      />
      <DeleteModal
        show={cancelSubscriptionPopup}
        deleteText="Confirm"
        onHide={() => setCancelSubscriptionPopup(false)}
        heading="Cancel Subscription?"
        handleDelete={handleCancelPlan}
        disable={isCancellingSubscription}
        para={
          <>
            Are you sure you want to cancel your Salon Symphony plan? Important:
            You may lose your account data if you cancel.{" "}
          </>
        }
        notificationMessage="Billing"
      />

      <DeleteModal
        show={downgradeWarningPopup}
        deleteText="Manage Members"
        headerCloseBtn={true}
        cancelText="Keep Current Plan"
        onHide={() => {
          setMemberNeedToRemove(0);
          setDowngradeWarningPopup(false);
        }}
        heading="Downgrade Plan?"
        handleDelete={(e) => {
          navigate("/team/members");
        }}
        para={
          <>
            Looks like you've exceeded the maximum number of users for this
            plan. To downgrade, you must{" "}
            <b>remove {memberNeedToRemove} members</b>.
          </>
        }
        notificationMessage="Billing"
      />
    </>
  );
};

const CurrentPlanButton = ({ currentSubscriptionDetail, packageId }) => {
  if (
    currentSubscriptionDetail &&
    currentSubscriptionDetail?.billing_plan_id == packageId
  ) {
    return (
      <NavLink
        onClick={(e) => {
          e.preventDefault();
        }}
        className={"newbtn-currentplan"}
        style={{
          display: "block",
          width: "150px",
          padding: "8px 0px",
        }}
      >
        Current Plan
      </NavLink>
    );
  }
  return null;
};

const RenderSelectPackage = ({
  handleSubscribePackage,
  packageId,
  currentSubscriptionDetail,
  currentClickPlan,
}) => {
  const { isCreatingPaymentLink } = useSelector(
    (state) => state.billingAndPlan,
  );

  const isLoadingOnClickedSelectBtn = () => {
    if (isCreatingPaymentLink && currentClickPlan == packageId) {
      return <div className="load black" />;
    } else {
      return "Select";
    }
  };

  if (currentSubscriptionDetail?.billing_plan_id != packageId) {
    return (
      <NavLink
        onClick={(e) => {
          e.preventDefault();
          handleSubscribePackage(packageId);
        }}
        className={`${"btnplan"} ${isCreatingPaymentLink && currentClickPlan == packageId ? null : "btn-gradient"}`}
        disabled={isCreatingPaymentLink}
      >
        {isLoadingOnClickedSelectBtn()}
      </NavLink>
    );
  }
  return null;
};

const RenderCancelPackage = ({
  packageId,
  currentSubscriptionDetail,
  handleCancelSubscriptionPopup,
}) => {
  const { isCancellingSubscription } = useSelector(
    (state) => state.billingAndPlan,
  );

  if (currentSubscriptionDetail.billing_plan_id == packageId) {
    return (
      <NavLink
        to="#"
        className={"newbtn-currentplan"}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        Current Plan
      </NavLink>
    );
  }
  return null;
};

export default BillingPage;
