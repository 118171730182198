import { notification, Popover, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modals/DeleteModal";
import GenericModal from "../../components/Modals/GenericModal";
import ShareModal from "../../components/Modals/ShareModal";
import SurveyModalForm from "../../components/pages/SurveysPage/SurveyModal/SurveyModalForm";
import { Assets } from "../../constants/images";
import {
  EditIcon,
  PurpleBackIcon,
  RecycleBin,
  ShareIcon,
  ToastCrossIcon,
} from "../../constants/svg";
import { DeleteSurvey, GetSurveyByID } from "../../services/survey/survey";
import {
  setActionButtonDisable,
  setPageLoading,
  setSelectedData,
  setSurveyAddEditForm,
} from "../../store/slices/surveySlice";
import ProfileHoverChat from "../../components/Common/ProfileHoverChat";
import { setStatUserHover } from "../../store/slices/globalSlice";
import {
  hasAccess,
  renderContentWithLinks,
  toastMessage,
} from "../../utils/helper";
import { salonQuestionType, SHARE_URL } from "../../utils/constants";
import PictureQuestionView from "../../components/Common/PictureQuestionView";
import MultipleChoiceQuestionView from "../../components/Common/MultipleChoiceQuestionView";
import ShortQuestionView from "../../components/Common/ShortQuestionView";
import EmojiQuestionView from "../../components/Common/EmojiQuestionView";
import StarRatingQuestionView from "../../components/Common/StartRatingQuestionView";
import CheckBoxQuestionView from "../../components/Common/CheckboxQuestionView";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ProfileHover from "../../components/Common/ProfileHover";
import AvatarImage from "../../components/Common/AvatarImage";

const SurveyDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    selectedData,
    pageLoading,
    actionButtonDisable,
    reRenderSurveyDetailPage,
    surveyAddEditForm,
  } = useSelector((state) => state.survey);
  const { statUserHover } = useSelector((state) => state.global);
  const { positions } = useSelector((state) => state.global);
  const { id } = useParams();

  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });
  const [open, setOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [show, setShow] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item.user?.id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setShow(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onDeleteSurvey = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const resp = await DeleteSurvey(id);
      const { data, message, status } = resp.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      setShowModal({ ...showModal, deleteModal: false });
      navigate("/surveys");
      toastMessage("delete", message);
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const getSingleSuveyByID = async () => {
    dispatch(setPageLoading(true));
    const response = await GetSurveyByID(id);
    const { data, message, status } = response.data;
    if (!status) {
      notification.error({
        description: message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
      return;
    }

    dispatch(setPageLoading(false));
    dispatch(setSelectedData(data));
  };
  useEffect(() => {
    getSingleSuveyByID();
  }, [reRenderSurveyDetailPage]);

  if (pageLoading || !selectedData) {
    return <LoadingScreen child={true} />;
  }
  const returnPosition = (position_id) => {
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].value == position_id) {
        return positions[i]?.label || "-";
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={9} className={"pe-0"}>
          <div className="leftEventDetailWrapper">
            <div className="eventDetailHeader innerContainer d-flex justify-content-between">
              <div className="backToEvent">
                <NavLink to="/surveys">
                  <PurpleBackIcon />
                  <span className="uLineHover">Back to Surveys</span>
                </NavLink>
              </div>
              <div className="shareButton">
                <Space size={"large"}>
                  {/* <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() => {
                      navigate('/surveysbuilder/' + id)
                    }}
                  >
                    <p className="uLine ">Survey Builder</p>
                  </div> */}
                  <div
                    className="icon-text-wrapper cursor-pointer blackLine"
                    onClick={() =>
                      setShowModal((prevState) => ({
                        ...prevState,
                        shareModal: true,
                      }))
                    }
                  >
                    <ShareIcon />
                    <p className="uLine ">Share</p>
                  </div>
                  {hasAccess(selectedData?.user) && (
                    <div
                      className="icon-text-wrapper cursor-pointer blackLine"
                      onClick={() => {
                        dispatch(
                          setSurveyAddEditForm({
                            show: true,
                            formType: "update",
                          }),
                        );
                      }}
                    >
                      <EditIcon />
                      <p className="uLine ">Edit</p>
                    </div>
                  )}
                  {hasAccess(selectedData?.user) && (
                    <div className="icon-text-wrapper deleteLabel cursor-pointer">
                      <RecycleBin />
                      <p
                        className="uLine redLine"
                        onClick={() =>
                          setShowModal((prevState) => ({
                            ...prevState,
                            deleteModal: true,
                          }))
                        }
                      >
                        Delete
                      </p>
                    </div>
                  )}
                </Space>
              </div>
            </div>
            <div className="eventDetailBody">
              <div className="innerContainer">
                <h3 className="sec-heading ">{selectedData?.name || "-"}</h3>
                <div className="eventDetailInformation">
                  <div className="eventDetailItem">
                    <h5 className="head">Category</h5>
                    <p>{selectedData?.category.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Questions</h5>
                    <p>{selectedData?.surveyQuestions?.length}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Description</h5>
                    <p>
                      {selectedData?.description
                        ? renderContentWithLinks(selectedData?.description)
                        : "-"}
                    </p>
                  </div>

                  <div className="eventDetailItem">
                    <h5 className="head">Submitted</h5>
                    <p>{selectedData?.submittedUsers?.length}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created By</h5>
                    <p>{selectedData?.user?.name || "-"}</p>
                  </div>
                  <div className="eventDetailItem">
                    <h5 className="head">Created On</h5>
                    <p>
                      {selectedData &&
                        moment(selectedData?.created_at).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="outerContainer">
                <div className="survey-container">
                  <div className="survey-border-wrapper">
                    {selectedData?.surveyQuestions?.map((elem, index) => {
                      if (elem.type == salonQuestionType.DESCRIPTION) {
                        return (
                          <ShortQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }

                      if (elem.type == salonQuestionType.STAR) {
                        return (
                          <StarRatingQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData?.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }

                      if (elem.type == salonQuestionType.EMOJIS) {
                        return (
                          <EmojiQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData?.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }

                      if (elem.type == salonQuestionType.MULTIPLE) {
                        return (
                          <MultipleChoiceQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData?.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }

                      if (elem.type == salonQuestionType.PICTURE) {
                        return (
                          <PictureQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData?.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }

                      if (elem.type == salonQuestionType.CHECKBOXES) {
                        return (
                          <CheckBoxQuestionView
                            data={elem}
                            index={index}
                            assignedUsers={selectedData?.surveyUsers}
                            detailPageType="survey"
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={3} className={"p-0"} onMouseLeave={handleMouseLeave}>
          <div className="rightEventDetailWrapper">
            <div className="eventDetailHeader d-flex justify-content-between">
              <h4 className="sec-heading m-0 p-0">Survey Responses</h4>
            </div>
            <div className="eventDetailBody">
              <div className="response-card">
                <div className="response-header">
                  <h6>Submitted ({selectedData?.submittedUsers?.length})</h6>
                </div>
                <div className="response-body">
                  {selectedData?.submittedUsers?.map((elem, index) => {
                    if (elem?.user?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: elem?.user?.id,
                                  name: elem?.user?.name,
                                  image: elem?.user?.image,
                                  position: returnPosition(
                                    elem?.user?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={elem?.user?.name}
                                src={elem?.user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            to={`/team/members/${elem?.user?.slug ?? elem?.user?.id}`}
                          >
                            <p>{elem?.user?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="response-card">
                <div className="response-header">
                  <h6>Not Started ({selectedData?.notStartedUsers?.length})</h6>
                </div>
                <div className="response-body">
                  {selectedData?.notStartedUsers?.map((elem, index) => {
                    if (elem?.user?.name) {
                      return (
                        <div
                          style={{ position: "relative" }}
                          className="avatar-item"
                        >
                          <Popover
                            placement="rightTop"
                            content={
                              <ProfileHover
                                user={{
                                  id: elem?.user?.id,
                                  name: elem?.user?.name,
                                  image: elem?.user?.image,
                                  position: returnPosition(
                                    elem?.user?.position_id,
                                  ),
                                }}
                              />
                            }
                            arrow={true}
                            key={index}
                          >
                            <div className="statUserHoverContainer customHover">
                              <AvatarImage
                                name={elem?.user?.name}
                                src={elem?.user?.image}
                                placeholder={Assets.ProfilePlaceholder}
                                size={38}
                              />
                            </div>
                          </Popover>
                          <NavLink
                            to={`/team/members/${elem?.user?.slug ?? elem?.user?.id}`}
                          >
                            <p>{elem?.user?.name}</p>
                          </NavLink>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* Share Modal */}
          <ShareModal
            show={showModal.shareModal}
            onHide={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: false }))
            }
            setModalShow={() =>
              setShowModal((prevState) => ({ ...prevState, shareModal: true }))
            }
            modalTitle={"Share Survey"}
            shareLink={SHARE_URL.SURVEYS + selectedData?.id}
            data={{
              image: selectedData?.image,
              name: selectedData?.name,
              description: selectedData?.description,
              start_date: selectedData
                ? moment(selectedData.created_at).format("MMM DD, yyyy")
                : "",
              start_time: selectedData
                ? moment(selectedData.created_at).format("hh:mm a")
                : "",
            }}
            modalSubtitle={
              <>
                Click “Copy Link” below, then{" "}
                <NavLink
                  to="/inbox"
                  className={"uLine blue-accent-color"}
                  target="_blank"
                >
                  {" "}
                  Open Chat{" "}
                </NavLink>{" "}
                to easily share this survey with members.
              </>
            }
            size={"lg"}
            bodyComponent={{
              img: Assets.eventShareModal,
              title: "Class Followup Survey",
              para1:
                "This is event dummy message will replace with the. This is event dummy message will replace with the. This is event dummy message will replace with the...",
              para2: "8 Questions • Oct 04, 2023",
            }}
          />

          {/* Delete Modal */}
          <DeleteModal
            disable={actionButtonDisable}
            handleDelete={onDeleteSurvey}
            show={showModal.deleteModal}
            onHide={() => setShowModal({ ...showModal, deleteModal: false })}
            heading="Delete Survey?"
            para={
              <>
                Are you sure you want to delete{" "}
                <b> {selectedData?.name || "this survey"} ?</b>
                <br />
                Important: This survey cannot be recovered.
              </>
            }
            notificationMessage="Survey"
          />
        </Col>
      </Row>
      <GenericModal
        show={surveyAddEditForm.show}
        onHide={() => {
          dispatch(
            setSurveyAddEditForm({
              show: false,
              formType: null,
            }),
          );
        }}
        setModalShow={(e) => {
          dispatch(
            setSurveyAddEditForm({
              show: e,
              formType: null,
            }),
          );
        }}
        modalTitle={
          surveyAddEditForm.formType == "create"
            ? "Create New Survey"
            : "Edit Survey"
        }
        size={"lg"}
        bodyComponent={<SurveyModalForm />}
      />
    </>
  );
};

export default SurveyDetailPage;
