import { Select } from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowTriangleDown,
  CheckMarkIcon,
  CreateIcon,
} from "../../../constants/svg";
import GenericModal from "../../Modals/GenericModal";
import SurveyModalForm from "./SurveyModal/SurveyModalForm";
import {
  setFilters,
  setSurveyAddEditForm,
} from "../../../store/slices/surveySlice";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { roles as salonRoles } from "../../../utils/constants";
import { setCurrentSalonID } from "../../../store/slices/globalSlice";

const SurveyHeader = () => {
  const dispatch = useDispatch();
  const { filters, surveyAddEditForm } = useSelector((state) => state.survey);
  const { categories, allSalons, currentSalonId } = useSelector(
    (state) => state.global,
  );

  const handleFilterSurveyCategory = (e) => {
    dispatch(setFilters({ ...filters, category_id: e }));
  };

  return (
    <>
      <div className="globalHeader flashcardheader">
        <div className="globalHeader--left">
          <h2 className="sec-heading">Surveys</h2>
        </div>
        <div className="globalHeader--right">
          {GetAuthUserLocalStorage().roles[0].id == salonRoles.super_admin && (
            <Select
              defaultValue="All Organizations"
              value={currentSalonId || undefined}
              suffixIcon={<ArrowTriangleDown />}
              menuItemSelectedIcon={<CheckMarkIcon />}
              onChange={(e) => {
                dispatch(setCurrentSalonID(e));
              }}
              popupClassName="main-select pageDropdown header-dropdown me-3"
              className="custom-select-box border"
              options={allSalons.map((elem) => {
                return { value: elem.id, label: elem.name };
              })}
              getPopupContainer={() => document.getElementById("area")}
            />
          )}
          <Select
            defaultValue="All Categories"
            onChange={handleFilterSurveyCategory}
            suffixIcon={<ArrowTriangleDown />}
            menuItemSelectedIcon={<CheckMarkIcon />}
            // open={true}
            popupClassName="main-select pageDropdown header-dropdown me-3"
            className="custom-select-box border"
            options={categories}
          ></Select>
          <button
            className="btn-gradient"
            onClick={() => {
              dispatch(
                setSurveyAddEditForm({
                  show: true,
                  formType: "create",
                }),
              );
            }}
          >
            <span>
              <CreateIcon />
            </span>
            Create Survey
          </button>
        </div>
        <GenericModal
          show={surveyAddEditForm.show}
          onHide={() => {
            dispatch(
              setSurveyAddEditForm({
                show: false,
                formType: null,
              }),
            );
          }}
          setModalShow={(e) => {
            dispatch(
              setSurveyAddEditForm({
                show: e,
                formType: null,
              }),
            );
          }}
          modalTitle={
            surveyAddEditForm.formType == "create"
              ? "Create New Survey"
              : "Edit Survey"
          }
          customModalHeader={"customNewSurveyHeader"}
          size={"lg"}
          bodyComponent={
            <SurveyModalForm customModalBody={"customNewSurveyModalBody"} />
          }
        />
      </div>
    </>
  );
};
export default SurveyHeader;
