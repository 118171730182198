import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Assets } from "../../constants/images";
import { PurpleFolderIcon } from "../../constants/svg";
import GalleryTab from "./GalleryTab";
import { GetGalleries } from "../../services/galleries/galleries";
import { gallery_type, status as salonStatus } from "../../utils/constants";
import { toastMessage } from "../../utils/helper";

const FolderTab = ({ handleChange = "", submitted = false }) => {
  const { currentSalonId } = useSelector((state) => state.global);

  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showComponent, setShowComponent] = useState(false);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [folders, setFolders] = useState([]);

  const getFolders = async () => {
    try {
      setLoadingFolders(true);
      const response = await GetGalleries({
        is_paginated: 0,
        status: salonStatus.active,
        salon_id: currentSalonId,
        type: gallery_type.folder,
        resource_type: gallery_type.image,
      });
      const { data, status, message } = response.data;
      const dataModified = data.map(({ name, id, resources }) => ({
        name,
        id,
        resources,
      }));
      setFolders(dataModified);
    } catch (err) {
      toastMessage("error", err.message);
    } finally {
      setLoadingFolders(false);
    }
  };

  useEffect(() => {
    getFolders();
  }, [currentSalonId]);

  const handleFolderClick = (id) => {
    setSelectedFolder(id);
    setShowComponent(true);
  };

  const handleShowFolder = () => {
    setShowComponent(false);
  };

  return (
    <>
      <div className="folderWrapperBox">
        <div className="folderHeader">
          <h4 className="black uLine" onClick={handleShowFolder}>
            All Folders
          </h4>
          {showComponent && (
            <span style={{ width: "30px", heigt: "21px" }}>
              <img src={Assets.SideArrows} className="img-fluid" />
            </span>
          )}
          {showComponent && (
            <h4 className="uLine truncateUploadFolderName2">
              {folders.find((elem) => elem.id == selectedFolder).name}
            </h4>
          )}
        </div>
        <div className="foderContainer">
          {!showComponent && (
            <div className="folderHolder">
              {!loadingFolders &&
                folders?.map((folder, index) => {
                  return (
                    <div
                      className="folderBox"
                      key={index}
                      onClick={() => handleFolderClick(folder.id)}
                    >
                      <PurpleFolderIcon />
                      <p className="black truncateUploadFolderName">
                        {folder.name}
                      </p>
                    </div>
                  );
                })}
              {!loadingFolders && !folders.length && (
                <div className="no-search-found">
                  <p className="text-center">
                    <b>No Folder Available</b>
                  </p>
                </div>
              )}
              {loadingFolders && (
                <div className="no-search-found">
                  <p className="text-center">
                    <b>Loading Folders...</b>
                  </p>
                </div>
              )}
            </div>
          )}
          {showComponent && (
            <GalleryTab
              folderResources={
                folders.find((elem) => elem.id == selectedFolder).resources
              }
              incSearch={false}
              handleChange={handleChange}
              submitted={submitted}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FolderTab;
