import { createSlice } from "@reduxjs/toolkit";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    tableLoading: false,
    organizationDetailPageLoading: false,
    reRenderOrganizationDetailPage: 1,
    reRenderList: 1,
    singleOrganizationDetail: null,
    showHideOragnizationDeleteModa: false,
    filters: {
      billing_plan_id: null,
    },
    activeOrganizations: {
      data: [],
      meta: [],
    },
    inActiveOragnizations: {
      data: [],
      meta: [],
    },
    activeOraganizationPagination: {
      page: 1,
      perPage: 200,
    },
    inActiveOragnizationPagination: {
      page: 1,
      perPage: 200,
    },
  },
  reducers: {
    setActiveOragnizations: (state, action) => {
      state.activeOrganizations = action.payload;
    },
    setInActiveOragnizations: (state, action) => {
      state.inActiveOragnizations = action.payload;
    },
    setTableLoading: (state, action) => {
      state.tableLoading = action.payload;
    },
    setActiveOragnizationPagination: (state, action) => {
      state.activeOraganizationPagination = action.payload;
    },
    setInActiveOragnizationPagination: (state, action) => {
      state.inActiveOragnizationPagination = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setReRenderOrganizationDetailPage: (state, action) => {
      state.reRenderOrganizationDetailPage = action.payload;
    },
    setSingleOraganizationDetail: (state, action) => {
      state.singleOrganizationDetail = action.payload;
    },
    setOrganizationDetailPageLoading: (state, action) => {
      state.organizationDetailPageLoading = action.payload;
    },
    setShowHideOrganizationDeleteModal: (state, action) => {
      state.showHideOragnizationDeleteModa = action.payload;
    },
    setReRenderList: (state, action) => {
      state.reRenderList = action.payload;
    },
  },
});

export const {
  setActiveOragnizations,
  setInActiveOragnizations,
  setTableLoading,
  setActiveOragnizationPagination,
  setInActiveOragnizationPagination,
  setFilters,
  setReRenderOrganizationDetailPage,
  setSingleOraganizationDetail,
  setOrganizationDetailPageLoading,
  setShowHideOrganizationDeleteModal,
  setReRenderList,
} = organizationSlice.actions;

export default organizationSlice.reducer;
