import { Empty, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DeleteMemberModal from "../../components/Modals/DeleteMemberModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import ShareMemberModa from "../../components/Modals/ShareMemberModa";
import { Assets } from "../../constants/images";
import {
  roles,
  SHARE_URL,
  tableHeight,
  roles as salonRoles,
} from "../../utils/constants";
import {
  NewSparkleIcon,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
  UserLogoPlus,
} from "../../constants/svg";

import { getMemeberTableColumns } from "../../TableColumns/member";
import {
  DeleteMember,
  GetMembers,
  UpdateMemberRole,
} from "../../services/team/member";
import { setCallSalonByIDAPI } from "../../store/slices/dashboardSlice";
import {
  setActionButtonDisable,
  setLoading,
  setMember,
  setMemberAddEditForm,
  setMemberTableData,
  setReRenderTable,
  setSelectedData,
} from "../../store/slices/memberSlice";
import { tableLoader, toastMessage } from "../../utils/helper";
import { CancelInvitation, ResendInvitation } from "../../services/users/users";
import { deleteFirebaseUser } from "../../services/chats/frontend-chats";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import UserLimitModal from "../../components/Modals/UserLimitModal";

const MembersPage = () => {
  const dispatch = useDispatch();
  const {
    reRenderTable,
    sorting,
    loading,
    memberTableData,
    actionButtonDisable,
    memberAddEditForm,
  } = useSelector((state) => state.member);
  const { salon } = useSelector((state) => state.global);

  const { callSalonByIDAPI } = useSelector((state) => state.dashboard);
  const { me } = useSelector((state) => state?.auth);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState({
    shareModal: null,
    editModal: null,
    deleteModal: null,
  });

  const [showLimitModal, setShowLimitModal] = useState(false);

  const [openConfirmUpdateMemberModal, setOpenConfirmUpdateMemberModal] =
    useState(false);
  const [
    confirmMemberRoleUpdateMemberDisable,
    setConfirmMemberRoleUpdateMemberDisable,
  ] = useState(false);
  const [updateMemberRoleData, setUpdateMemberRoleData] = useState(null);

  const [memberForDelete, setMemberForDelete] = useState(null);
  const [memberForShare, setMemberForShare] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
  });

  const handleConfirmMemberUpdateRole = (userId, roleId) => {
    setUpdateMemberRoleData({ userId, roleId });
    setOpenConfirmUpdateMemberModal(true);
  };

  const handleUpdateUserRole = async () => {
    try {
      setConfirmMemberRoleUpdateMemberDisable(true);
      const response = await UpdateMemberRole(
        updateMemberRoleData.userId,
        updateMemberRoleData.roleId,
      );
      const { message, data, status } = response.data;
      if (!status) {
        notification.error({
          message,
          placement: "bottomRight",
          className: "deletedNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      dispatch(setReRenderTable(reRenderTable + 1));
      setUpdateMemberRoleData(null);
      setOpenConfirmUpdateMemberModal(false);
      notification.success({
        message: "Role Updated Successfully!",
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        message: e.message,
        placement: "bottomRight",
        className: "deletedNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      setConfirmMemberRoleUpdateMemberDisable(false);
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(setActionButtonDisable(true));
      const response = await DeleteMember(memberForDelete.id);
      const { data, message, status } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      await deleteFirebaseUser(memberForDelete.id);
      dispatch(setCallSalonByIDAPI(callSalonByIDAPI + 1));
      setMemberForDelete(null);
      setShowModal({ ...showModal, deleteModal: false });
      toastMessage("delete", message);
      dispatch(setReRenderTable(reRenderTable + 1));
    } catch (e) {
      toastMessage("error", e.message);
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  // Action Menu for dropdown
  const onInitFetctMemeberData = async () => {
    try {
      if (memberTableData.length == 0) {
        dispatch(setLoading(true));
      }
      const response = await GetMembers();
      const { data, status, message } = response.data;
      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }

      const users = data.users;
      const invited = data.invited;
      let userDataModified = users.map((elem) => {
        return {
          key: uuidv4(),
          id: elem.id,
          slug: elem.slug,
          name: elem.name,
          employeeName: elem.name,
          email: elem.email ? elem.email : "-",
          avatar: elem.image,
          position: elem.position ? elem.position.name : "-",
          location: elem.location ? elem.location.name : "-",
          role: elem?.roles[0]?.id ?? roles.member,
          isOwner: elem.isOwner,
          isUserOwner: user?.isOwner ? user?.isOwner : false,
          isInvited: false,
        };
      });
      invited.map((elem) => {
        let invitedUser = {
          key: uuidv4(),
          id: elem?.id,
          slug: "-",
          name: elem?.name || "-",
          employeeName: elem?.name || "-",
          email: "-",
          avatar: null,
          position: "-",
          location: elem?.location ? elem?.location?.name : "-",
          role: "Invited",
          isOwner: false,
          isUserOwner: false,
          isInvited: true,
        };
        userDataModified.push(invitedUser);
      });
      dispatch(setMemberTableData(userDataModified));
      dispatch(setMember(data));
    } catch (e) {
      notification.error({
        description: e.message,
        placement: "bottomRight",
        className: "createdNotification",
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleEditOpenMemberForm = (record) => {
    dispatch(setSelectedData(record));
    dispatch(
      setMemberAddEditForm({
        show: true,
        formType: "update",
      }),
    );
  };

  useEffect(() => {
    onInitFetctMemeberData();
  }, [reRenderTable, sorting, user]);

  useEffect(() => {
    if (me?.data) {
      setUser(me?.data);
    }
  }, [me]);

  // ======== Resend Invitation function API =================
  const resendIvite = async (id) => {
    await ResendInvitation(id)
      .then((resp) => {
        toastMessage("success", resp?.data?.message);
      })
      .catch((e) => {
        toastMessage("error", e?.data?.message);
      });
  };

  const cancelIvite = async (id) => {
    await CancelInvitation(id)
      .then((resp) => {
        toastMessage("error", resp?.data?.message);
        onInitFetctMemeberData();
      })
      .catch((e) => {
        toastMessage("error", e?.data?.message);
      });
  };

  return (
    <>
      <div className="teamTabMainHead">
        <h2 className="sec-heading">Members</h2>
        <button
          className="btn-gradient"
          onClick={() => {
            if (GetAuthUserLocalStorage().roles[0].id == salonRoles.admin) {
              if (salon.meta.members >= salon.meta.total_members) {
                setShowLimitModal(true);
                return;
              }
              dispatch(
                setMemberAddEditForm({
                  show: true,
                  formType: "create",
                  isSalonCreate: 0,
                }),
              );
            } else {
              dispatch(
                setMemberAddEditForm({
                  show: true,
                  formType: "create",
                  isSalonCreate: 1,
                }),
              );
            }
          }}
        >
          <span>
            <UserLogoPlus />
          </span>
          Invite Staff
        </button>
      </div>
      <div className="teamTabSubHead memberHeader">
        <p>
          <NewSparkleIcon />
          <b className="gap-6">Pro Tip:</b> You can modify a member’s
          permissions from the role dropdown. &nbsp;
          <a
            href={
              "https://support.mysalonsymphony.com/hc/en-us/articles/19418823578267-User-Roles"
            }
            target="_blank"
            className={"uLine globalBlueLinkColor"}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </p>
      </div>

      <div className="teamBody content">
        <Table
          locale={{
            emptyText: <Empty image={Assets.EmptyWhiteBg} description={""} />,
          }}
          columns={getMemeberTableColumns(
            setShowModal,
            handleEditOpenMemberForm,
            setMemberForDelete,
            handleConfirmMemberUpdateRole,
            me?.data?.isOwner,
            setMemberForShare,
            resendIvite,
            cancelIvite,
          )}
          dataSource={memberTableData}
          loading={tableLoader(loading)}
          bordered={false}
          pagination={false}
          scroll={{ x: "max-content", y: tableHeight?.teamHeightTable }}
          responsive={true}
          size={"large"}
          className="flashCardTable"
          showSorterTooltip={false}
          rowKey={(record) => record.key}
        />
      </div>

      <UserLimitModal
        show={showLimitModal}
        onHide={() => setShowLimitModal(false)}
      />

      <ShareMemberModa
        show={showModal.shareModal}
        onHide={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: false }))
        }
        setModalShow={() =>
          setShowModal((prevState) => ({ ...prevState, shareModal: true }))
        }
        modalTitle={"Share Member Profile"}
        modalSubtitle={
          <>
            Click "Copy Link" below, then{" "}
            <NavLink to={"/inbox"} className={"uLine"} target="_blank">
              {" "}
              Open Chat{" "}
            </NavLink>{" "}
            to easily share this member with admins.
          </>
        }
        size={"lg"}
        bodyComponent={{
          img: memberForShare?.avatar || Assets.ProfilePlaceholder,
          title: memberForShare?.employeeName,
          subTitle: memberForShare?.position,
          link: SHARE_URL.MEMBERS + memberForShare?.slug,
        }}
      />

      <DeleteModal
        deleteText="Update"
        show={openConfirmUpdateMemberModal}
        onHide={() => setOpenConfirmUpdateMemberModal(false)}
        heading="Update Role?"
        handleDelete={handleUpdateUserRole}
        disable={confirmMemberRoleUpdateMemberDisable}
        para={
          <>
            Changing the role of this user will change their permissions.
            <br />
            Are you sure you want to update their role?
          </>
        }
        notificationMessage="Member"
      />

      <DeleteMemberModal
        pageType="list"
        show={showModal.deleteModal}
        handleDelete={handleDelete}
        disable={actionButtonDisable}
        onHide={() => {
          setMemberForDelete(null);
          setShowModal({ ...showModal, deleteModal: false });
        }}
        text="Member"
        member={memberForDelete}
      />
    </>
  );
};

export default MembersPage;
