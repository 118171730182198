import { createSlice } from "@reduxjs/toolkit";

export const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    loading: false,
    quiz: null,
    quizTableData: [],
    page: 1,
    perPage: 10,
    isPaginate: 1,
    reRenderTable: 1,
    reRenderQuizDetailPage: 1,
    reRenderQuizBuilder: 1,
    actionButtonDisable: false,
    singleQuizDetails: null,
    pageLoading: false,
    uploadFileTabNo: 1,
    selectedData: null,
    quizAddEditForm: {
      show: false,
      formType: null,
    },
    filters: {
      category_id: 0,
    },
    sorting: {
      column: "id",
      sortBy: "desc",
    },
  },
  reducers: {
    setReRenderQuizDetailPage: (state, action) => {
      state.reRenderQuizDetailPage = action.payload;
    },
    setQuizAddEditForm: (state, action) => {
      state.quizAddEditForm = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQuiz: (state, action) => {
      state.quiz = action.payload;
    },
    setQuizTableData: (state, action) => {
      state.quizTableData = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setIsPaginate: (state, action) => {
      state.isPaginate = action.payload;
    },
    setReRenderTable: (state, action) => {
      state.reRenderTable = action.payload;
    },
    setActionButtonDisable: (state, action) => {
      state.actionButtonDisable = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setSingleQuizDetails: (state, action) => {
      state.singleQuizDetails = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setUploadFileTabNo: (state, action) => {
      state.uploadFileTabNo = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setReRenderQuizBuilder: (state, action) => {
      state.reRenderQuizBuilder = action.payload;
    },
  },
});

export const {
  setLoading,
  setQuiz,
  setQuizTableData,
  setPage,
  setPerPage,
  setIsPaginate,
  setReRenderTable,
  setActionButtonDisable,
  setSorting,
  setSingleQuizDetails,
  setPageLoading,
  setUploadFileTabNo,
  setFilters,
  setQuizAddEditForm,
  setSelectedData,
  setReRenderQuizDetailPage,
  setReRenderQuizBuilder,
} = quizSlice.actions;

export default quizSlice.reducer;
