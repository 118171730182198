import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { roles as salonRoles } from "../utils/constants";
import {
  BullhornLightIcon,
  BullHornSolidIcon,
  MembersIcon,
  MembersIconFilled,
} from "../constants/sidebarIcons";
import {
  CandidateIcon,
  CandidateIconFilled,
  DocIcon,
  DocIconFilled,
  GroupsIcon,
  GroupsIconFilled,
  NewHireIcon,
  NewHireIconFilled,
  RewardIcon,
  RewardIconFilled,
} from "../constants/svg";
import { GetAuthUserLocalStorage } from "../services/localStorage/localStorage";

const TeamLayout = () => {
  return (
    <div className="teamLayoutWrapper h-100 d-flex">
      <div className="innerSideBarWrapper h-100">
        <div
          className={
            GetAuthUserLocalStorage()?.roles[0]?.id == salonRoles.super_admin
              ? "innerSideBar h-100 d-none"
              : "innerSideBar h-100"
          }
        >
          <ul className={"sidebarList innerSideBarList h-100"}>
            <li className={"sidebarItem"}>
              <NavLink to="/team/members">
                <div className="icon activeIcon">
                  <MembersIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <MembersIcon />
                </div>
                <p className="sidebarText blue-accent">Members</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/docs">
                <div className="icon activeIcon">
                  <DocIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <DocIcon />
                </div>
                <p className="sidebarText blue-accent">Documents</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/onboarding">
                <div className="icon activeIcon">
                  <NewHireIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <NewHireIcon />
                </div>
                <p className="sidebarText blue-accent">Onboarding</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/candidates">
                <div className="icon activeIcon">
                  <CandidateIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <CandidateIcon />
                </div>
                <p className="sidebarText blue-accent">Candidates</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/rewards">
                <div className="icon activeIcon">
                  <RewardIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <RewardIcon />
                </div>
                <p className="sidebarText blue-accent">Rewards</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/shoutouts">
                <div className="icon activeIcon">
                  <BullHornSolidIcon />
                </div>
                <div className="icon nonActiveIcon">
                  <BullhornLightIcon />
                </div>
                <p className="sidebarText blue-accent">Shoutouts</p>
              </NavLink>
            </li>
            <li className={"sidebarItem"}>
              <NavLink to="/team/groups">
                <div className="icon activeIcon">
                  <GroupsIconFilled />
                </div>
                <div className="icon nonActiveIcon">
                  <GroupsIcon />
                </div>
                <p className="sidebarText blue-accent">Groups</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="innerBody">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default TeamLayout;
