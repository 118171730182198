import { createSlice } from "@reduxjs/toolkit";

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    isActiveGoalsLoading: true,
    isAchievedGoalsLoading: true,
    isMissedGoalsLoading: true,

    activeGoals: {
      meta: null,
      data: [],
    },
    achievedGoals: {
      meta: null,
      data: [],
    },
    missedGoals: {
      meta: null,
      data: [],
    },

    singleGoal: {
      isSingleGoalLoading: true,
      data: null,
    },
    renderGoalDetailPage: 1,
    formData: {
      type: "",
      name: "",
      description: "",
      target_type: null,
      target_date: null,
      target_value: "",
      duration: null,
      start_date: null,
      end_date: null,
      users: [],
      selectedCategory: "",
    },
    activeTab: "",
  },
  reducers: {
    setIsActiveGoalsLoading: (state, action) => {
      state.isActiveGoalsLoading = action.payload;
    },
    setIsAchievedGoalsLoading: (state, action) => {
      state.isAchievedGoalsLoading = action.payload;
    },
    setIsMissedGoalsLoading: (state, action) => {
      state.isMissedGoalsLoading = action.payload;
    },

    setActiveGoals: (state, action) => {
      // state.activeGoals.data = [...state.activeGoals.data, ...action.payload.data]
      state.activeGoals.data = Array.from(
        new Map(
          [...state.activeGoals.data, ...action.payload.data].map((item) => [
            item.id,
            item,
          ]),
        ).values(),
      );
      state.activeGoals.meta = action.payload.meta;
    },
    setAchievedGoals: (state, action) => {
      // state.achievedGoals.data = [...state.achievedGoals.data, ...action.payload.data]
      state.achievedGoals.data = Array.from(
        new Map(
          [...state.achievedGoals.data, ...action.payload.data].map((item) => [
            item.id,
            item,
          ]),
        ).values(),
      );
      state.achievedGoals.meta = action.payload.meta;
    },
    setMissedGoals: (state, action) => {
      // state.missedGoals.data = [...state.missedGoals.data, ...action.payload.data]
      state.missedGoals.data = Array.from(
        new Map(
          [...state.missedGoals.data, ...action.payload.data].map((item) => [
            item.id,
            item,
          ]),
        ).values(),
      );
      state.missedGoals.meta = action.payload.meta;
    },

    setIsSingleGoalLoading: (state, action) => {
      state.singleGoal.isSingleGoalLoading = action.payload;
    },
    setSingleGoal: (state, action) => {
      state.singleGoal.data = action.payload;
    },

    setRemoveActiveGoalFromArray: (state, action) => {
      let data = [...state.activeGoals.data];
      data.splice(action.payload, 1);
      state.activeGoals.data = data;
    },

    setRemoveAchievedGoalFromArray: (state, action) => {
      let data = [...state.achievedGoals.data];
      data.splice(action.payload, 1);
      state.achievedGoals.data = data;
    },

    setRemoveMissedGoalFromArray: (state, action) => {
      let data = [...state.missedGoals.data];
      data.splice(action.payload, 1);
      state.missedGoals.data = data;
    },

    setGoalsFormData: (state, action) => {
      const newFormData = action.payload;
      Object.assign(state.formData, newFormData);
    },
    setResetGoalsFormData: (state, action) => {
      state.formData.type = "";
      state.formData.name = "";
      state.formData.description = "";
      state.formData.target_type = null;
      state.formData.target_date = null;
      state.formData.target_value = "";
      state.formData.duration = null;
      state.formData.start_date = null;
      state.formData.end_date = null;
      state.formData.users = [];
      state.formData.selectedCategory = "";
    },

    setEmptyAllGoals: (state, action) => {
      state.activeGoals.data = [];
      state.achievedGoals.data = [];
      state.missedGoals.data = [];
    },

    setAllGoalsLoading: (state, action) => {
      state.isActiveGoalsLoading = true;
      state.isAchievedGoalsLoading = true;
      state.isMissedGoalsLoading = true;
    },

    setStopAllGoalsLoading: (state, action) => {
      state.isActiveGoalsLoading = false;
      state.isAchievedGoalsLoading = false;
      state.isMissedGoalsLoading = false;
    },
    setRenderGoalDetailPage: (state, action) => {
      state.renderGoalDetailPage = action.payload;
    },
    setSelectedGoalTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setIsActiveGoalsLoading,
  setIsAchievedGoalsLoading,
  setIsMissedGoalsLoading,

  setActiveGoals,
  setAchievedGoals,
  setMissedGoals,

  setIsSingleGoalLoading,
  setSingleGoal,
  setRenderGoalDetailPage,
  setRemoveActiveGoalFromArray,
  setRemoveAchievedGoalFromArray,
  setRemoveMissedGoalFromArray,

  setGoalsFormData,
  setResetGoalsFormData,

  setEmptyAllGoals,
  setAllGoalsLoading,
  setStopAllGoalsLoading,
  setSelectedGoalTab,
} = eventsSlice.actions;

export default eventsSlice.reducer;
