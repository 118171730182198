import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../../Contexts/ContextProvider";
import { GetAuthUserLocalStorage } from "../../../../services/localStorage/localStorage";
import { setCreateUpdateTitleModuleID } from "../../../../store/slices/globalSlice";
import { search_module_type, task_type } from "../../../../utils/constants";
import {
  createTask,
  getTasks,
  presetDataForEditTask,
  updateTask,
} from "../../../../services/tasks/frontend-tasks";
import {
  setIsTasksLoading,
  setResetTasksFormData,
  setTasks,
  setTasksFormData,
} from "../../../../store/slices/tasksSlice";
import FirstStep from "./StepsForm/FirstStep";
import SecondStep from "./StepsForm/SecondStep";

const TaskModalForms = (props) => {
  const { taskPopupTitle, setTaskPopupTitle } = useStateContext();
  const [step, setStep] = useState(1); // initial the step where you want to start
  const [disable, setDisable] = useState(false);
  const [selected, setSelected] = useState(null);
  const [secondStepDisabled, setSecondStepDisabled] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useFormContext();
  const dispatch = useDispatch();
  const [
    type,
    name,
    description,
    start,
    end,
    repeat_cycle,
    due_on,
    assignees,
    selectedCategory,
    recipient_type,
  ] = watch([
    "type",
    "name",
    "description",
    "start",
    "end",
    "repeat_cycle",
    "due_on",
    "assignees",
    "selectedCategory",
    "recipient_type",
  ]);
  const authUser = GetAuthUserLocalStorage();
  const { salonTeams } = useSelector((state) => state?.salon_teams);
  const { formData } = useSelector((state) => state?.tasks);
  const { currentSalonId } = useSelector((state) => state.global);

  const navigate = useNavigate();

  const handleSelect = (value) => {
    setSelected(value);
    setValue("type", value);
  };

  const nextStep = () => {
    setStep(step + 1);
    saveData();
    if (selected == task_type?.one_time) {
      setTaskPopupTitle(`${props?.isedit ? "Update" : "Create"} New Task`);
    } else if (selected == task_type?.weekly) {
      setTaskPopupTitle(`${props?.isedit ? "Update" : "Create"} Weekly Task`);
    } else if (selected == task_type?.bi_weekly) {
      setTaskPopupTitle(
        `${props?.isedit ? "Update" : "Create"} Bi-Weekly Task`,
      );
    } else if (selected == task_type?.monthly) {
      setTaskPopupTitle(`${props?.isedit ? "Update" : "Create"} Monthly Task`);
    }
  };

  const prevStep = () => {
    saveData();
    setStep(step - 1);
  };

  const saveData = () => {
    dispatch(
      setTasksFormData({
        type,
        name,
        description,
        start,
        end,
        repeat_cycle,
        due_on,
        assignees,
        selectedCategory,
        recipient_type,
      }),
    );
  };

  const returnUsers = (category) => {
    if (category == "everyone") {
      return { usersData: assignees };
    } else if (category == "group") {
      let groups = salonTeams?.data?.groups?.filter((item) =>
        assignees?.includes(item?.id?.toString()),
      );
      let users = [];
      let groupsData = [];

      for (let i = 0; i < groups?.length; i++) {
        groupsData.push(groups[i]?.id?.toString());

        for (let j = 0; j < groups[i]?.users?.length; j++) {
          users.push(groups[i].users[j]?.id?.toString());
        }
      }
      return { usersData: users, groupsData };
    } else if (category == "member") {
      return { usersData: assignees };
    }
  };

  const resetForm = () => {
    dispatch(setResetTasksFormData());
    setStep(1);
    setSelected(null);
  };

  const fetchTasks = async () => {
    const params = {
      is_paginated: 1,
      salon_id: currentSalonId,
      // page: 1,
      // "per-page": 10,
      platform: "web",
      all_time: true,
    };

    await getTasks(params, dispatch, setIsTasksLoading, setTasks);
  };

  const onCreateTask = async () => {
    let apiData = {
      name,
      ...(start && { start: moment(start).format("YYYY-MM-DD") }),
      ...(end && { end: moment(end).format("YYYY-MM-DD") }),
      type: type?.toString(),
      // status: status?.active,
      description,
      assignees: returnUsers(selectedCategory)?.usersData,
      ...(selectedCategory == "group" && {
        recipients: returnUsers(selectedCategory)?.groupsData,
      }),
      recipient_type,
      assign_by: authUser?.id,
      salon_id: currentSalonId,
      repeat_cycle: repeat_cycle?.toString(),
      due_on,
    };
    await createTask(
      apiData,
      setDisable,
      props?.setShowGenericModal,
      resetForm,
      fetchTasks,
    );
  };

  const onUpdateTask = async () => {
    let apiData = {
      name,
      ...(start && { start: moment(start).format("YYYY-MM-DD") }),
      ...(end && { end: moment(end).format("YYYY-MM-DD") }),
      type: type?.toString(),
      // status: status?.active,
      description,
      assign_by: authUser?.id,
      salon_id: currentSalonId,
      repeat_cycle: repeat_cycle?.toString(),
      due_on,
    };

    const selectedData = {
      key: props?.selectedData?.key || props?.id,
    };

    await updateTask(
      apiData,
      selectedData,
      setDisable,
      props?.setShowGenericModal,
      resetForm,
      fetchTasks,
    );
    navigate("/tasks");
  };

  const fetchAndPresetSingleTaskData = async () => {
    const selectedData = {
      key: props?.selectedData?.key || props?.id,
    };
    await presetDataForEditTask(selectedData, setSelected, dispatch);
  };

  const handleDisableSubmit = () => {
    if (props?.isedit) {
      if (disable || !isValid || isSubmitting) {
        return true;
      } else {
        return false;
      }
    } else {
      if (disable || !isValid || isSubmitting || assignees?.length <= 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onSubmit = (data) => {
    if (props?.isedit) {
      onUpdateTask();
    } else {
      if (getValues("assignees")) {
        onCreateTask();
      }
    }
  };

  useEffect(() => {
    if (step == 1) {
      setTaskPopupTitle(props?.isedit ? "Edit Task" : "Create New Task");
    }
  }, [step]);

  useEffect(() => {
    if (props?.isedit) {
      fetchAndPresetSingleTaskData();
    }
  }, [props?.isedit, props?.selectedData, props?.show]);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  useEffect(() => {
    if (!props?.show) {
      reset(formData);
      resetForm();
    }
  }, [props?.show]);

  useEffect(() => {
    dispatch(setCreateUpdateTitleModuleID(search_module_type.tasks));
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`modal-body ${step == 2 ? "" : "tabs-modal"} ${props.customModalBody}`}
        >
          {step == 1 && (
            <FirstStep handleSelect={handleSelect} selected={selected} />
          )}
          {step == 2 && (
            <SecondStep
              handleSelect={handleSelect}
              secondStepDisabled={secondStepDisabled}
              setSecondStepDisabled={setSecondStepDisabled}
              selected={selected}
              showGenericModal={props.showGenericModal}
              isedit={props?.isedit}
            />
          )}
        </div>
        <div className="modal-footer">
          <div className="custom-footer-btns w-100">
            {step == 1 && (
              <Col lg={12} className="p-0">
                <Button
                  type="button"
                  // className={"w-100"}
                  className="custom-width-690"
                  onClick={nextStep}
                  disabled={!selected}
                >
                  Continue
                </Button>
              </Col>
            )}
            {step == 2 && (
              <>
                <div>
                  <Button
                    type="button"
                    className={"btn-gray custom-width-332 margin-right-26"}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={handleDisableSubmit()}
                    type="submit"
                    className={"custom-width-332"}
                  >
                    {disable ? (
                      <div className="load black" />
                    ) : props?.isedit ? (
                      "Edit Task"
                    ) : (
                      "Assign Task"
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default TaskModalForms;
