import { Image } from "antd";
import React from "react";
import { Assets } from "../../constants/images";

const LoadingScreen = ({ child = false }) => {
  return (
    <div className={`loading-screen ${child && "child-loader"} `}>
      <div className="gif">
        <Image src={Assets.loadingGif} preview={false} />
      </div>
    </div>
  );
};

export default LoadingScreen;
