import React, { useEffect } from "react";
import { Upload } from "antd";

const CustomUploader = ({ children = "", ...restProps }) => {
  const uploaderCN = "customUploader";
  const activeCN = "dragIn";

  const checkViewable = (element) =>
    element?.offsetHeight && element?.offsetWidth;

  const getViewableElement = (nodeList) => {
    const viewableElements = Array.from(nodeList)?.filter((element) => {
      return checkViewable(element);
    });
    return viewableElements?.[0];
  };

  const onDragInArea = (e) => {
    e.stopPropagation();
    const uploaderElements = document.querySelectorAll(`.${uploaderCN}`);
    const uploaderElement = getViewableElement(uploaderElements);
    if (!uploaderElement?.classList?.contains(activeCN)) {
      uploaderElement.classList.add(activeCN);
    }
  };

  const onDragOutArea = (e) => {
    const uploaderElements = document.querySelectorAll(`.${uploaderCN}`);
    const uploaderElement = getViewableElement(uploaderElements);
    if (uploaderElement?.classList?.contains(activeCN)) {
      uploaderElement.classList.remove(activeCN);
    }
  };

  useEffect(() => {
    if (!document) return;
    const modals = document.querySelectorAll(".modal");
    const modal = getViewableElement(modals);
    const modalContent = modal.querySelector(".modal-content");

    modalContent?.addEventListener("dragover", onDragInArea);
    modalContent?.addEventListener("drop", onDragOutArea);
    modal?.addEventListener("dragover", onDragOutArea);

    return () => {
      modalContent?.addEventListener("dragover", onDragInArea);
      modalContent?.addEventListener("drop", onDragOutArea);
      modal?.addEventListener("dragover", onDragOutArea);
    };
  }, []);

  return (
    <div className={uploaderCN}>
      <div className="customUploadOverlay">
        <div className="overlayLogo"></div>
        <div className="overlayText">Upload to Salon Symphony</div>
      </div>
      <Upload {...restProps}>{children}</Upload>
    </div>
  );
};

export default CustomUploader;
