import { Drawer, Dropdown, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  BinocularsLight,
  DoubleTick,
  HorizontalDots,
  MessageEditIcon,
  ModalCloseIcon,
  RecycleBin,
} from "../../constants/svg";
import MessageCard from "./MessageCard";
import { Assets } from "../../constants/images";
import { NavLink, useLocation } from "react-router-dom";
import NewMessageModal from "../Modals/NewMessageModal";

const MessageDrawer = (props) => {
  // const [openMessage, setOpenMessage] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const closeMessageDrawer = () => {
    props.setOpenMessage(false);
  };
  const location = useLocation();

  useEffect(() => {
    props.setOpenMessage(false);
  }, [location.pathname]);

  const messageAllData = [
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: "12",
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
  ];
  const messageUnreadData = [
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: "12",
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
    {
      key: uuidv4(),
      img: Assets.notificationIcon,
      title: <h6>Hannah Roger</h6>,
      subtitle: <p>Hi! Please change my pending status...</p>,
      count: false,
      time: "Today, 4:15 PM",
      link: "/inbox",
    },
  ];

  const items = [
    {
      key: uuidv4(),
      label: (
        <>
          <NavLink to={"/inbox"}>
            <BinocularsLight />
            Open Chat
          </NavLink>
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <>
          <DoubleTick />
          Mark all read
        </>
      ),
    },
    {
      key: uuidv4(),
      label: (
        <div className={"deleteLabel"}>
          <RecycleBin />
          Delete all
        </div>
      ),
    },
  ];

  // Tabs Content
  const messageContent = [
    {
      key: "1",
      label: `All`,
      children: (
        <>
          {messageAllData.map((data) => {
            return (
              <MessageCard
                key={data.key}
                notificationImg={data.img}
                time={data.time}
                cardTitle={data.title}
                link={data.link}
                subtitle={data.subtitle}
                count={data.count}
              />
            );
          })}
        </>
      ),
    },
    {
      key: "2",
      label: `Unread (3)`,
      children: (
        <>
          {/* {messageUnreadData.map((data) => {
            return (
              <MessageCard
                notificationImg={data.img}
                time={data.time}
                cardTitle={data.title}
                link={data.link}
                clearedData={true}
              />
            );
          })} */}
          {messageUnreadData.map((data) => {
            return (
              <MessageCard
                key={data.key}
                notificationImg={data.img}
                time={data.time}
                cardTitle={data.title}
                link={data.link}
                subtitle={data.subtitle}
                count={data.count}
              />
            );
          })}
        </>
      ),
    },
  ];

  return (
    <>
      <Drawer
        open={props.openMessage}
        onClose={closeMessageDrawer}
        size={"small"}
        placement={"right"}
        closable={false}
        title={"Messages"}
        scroll
        extra={
          <ul className={"drawerExtras"}>
            <li className="cursor-pointer" key={uuidv4()}>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
                trigger={["click"]}
                overlayClassName={"defaultDropdown activity-drop-down"}
              >
                <div className="icon">
                  <HorizontalDots />
                </div>
              </Dropdown>
            </li>
            <li
              className="cursor-pointer"
              onClick={setModalShow}
              key={uuidv4()}
            >
              <MessageEditIcon />
            </li>
            <li
              className="cursor-pointer"
              onClick={closeMessageDrawer}
              key={uuidv4()}
            >
              <ModalCloseIcon />
            </li>
          </ul>
        }
      >
        <div className="drawerTabs">
          <Tabs defaultActiveKey="1" items={messageContent} />
        </div>
      </Drawer>
      <NewMessageModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default MessageDrawer;
