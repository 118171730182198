import { Tabs } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AcrobatIcon,
  ModalAudioIcon,
  ModalImageIcon,
  ModalVideoIcon,
} from "../../../../../constants/svg";
import { setFirstStepTabNo } from "../../../../../store/slices/resourcesSlice";
import AudioUpload from "./StepsDetail/AudioUpload";
import PdfUpload from "./StepsDetail/PdfUpload";
import VideoUpload from "./StepsDetail/VideoUpload";
import { AiOutlineDelete, AiOutlinePaperClip } from "react-icons/ai";
import { checkPDFBySrc } from "../../../../../utils/helper";

const FirstStep = ({
  register,
  watch,
  control,
  setValue,
  errors,
  getValues,
  resetField,
}) => {
  const dispatch = useDispatch();
  const { firstStepTabNo } = useSelector((state) => state.resources);

  const hanldeOnChange = (tabNo) => {
    setValue("uploadPDF", "");
    setValue("uploadVideos", "");
    setValue("uploadAudio", "");
    dispatch(setFirstStepTabNo(tabNo));
  };

  // Tabs Data Array
  const ResuoreFirstStep = [
    {
      key: 1,
      label: (
        <div className="main-alert-box">
          <div className="icon-div">
            <ModalImageIcon />
          </div>
          <h3>PDF</h3>
          <p>Upload a PDF or link to a resource online.</p>
        </div>
      ),
      children: (
        <>
          <Controller
            control={control}
            rules={{
              required:
                firstStepTabNo == 1 &&
                getValues("previewFirstStepAttachment") == ""
                  ? "Upload a PDF or link to a resource online is required."
                  : false,
            }}
            name="uploadPDF"
            render={({ field: { onChange, onBlur, value } }) => (
              <PdfUpload
                resetField={resetField}
                handleChange={(e) => {
                  setValue("previewFirstStepAttachment", "");
                  onChange(e);
                }}
                handleSwitch={(e) => {
                  setValue("switchValue", e);
                }}
                switchDefaultValue={getValues("switchValue")}
                linkDefaultValue={
                  getValues("previewFirstStepAttachment") != ""
                    ? getValues("previewFirstStepAttachment")
                    : null
                }
                fileList={value}
              />
            )}
          />
          {/* {errors.uploadPDF && <Form.Text>{errors.uploadPDF.message}</Form.Text>} */}
        </>
      ),
    },
    {
      key: 2,
      label: (
        <div className="main-alert-box">
          <div className="icon-div">
            <ModalVideoIcon />
          </div>
          <h3>Video</h3>
          <p>Upload an MP4 or link to a YouTube video.</p>
        </div>
      ),
      children: (
        <>
          <Controller
            control={control}
            rules={{
              required:
                firstStepTabNo == 2 &&
                getValues("previewFirstStepAttachment") == ""
                  ? "Upload an MP4 or link to a YouTube video is required."
                  : false,
            }}
            name="uploadVideos"
            render={({ field: { onChange, onBlur, value } }) => (
              <VideoUpload
                resetField={resetField}
                handleChange={(e) => {
                  setValue("previewFirstStepAttachment", "");
                  onChange(e);
                }}
                handleSwitch={(e) => {
                  setValue("switchValue", e);
                }}
                switchDefaultValue={getValues("switchValue")}
                linkDefaultValue={
                  getValues("previewFirstStepAttachment") != ""
                    ? getValues("previewFirstStepAttachment")
                    : null
                }
                fileList={value}
              />
            )}
          />
          {/* {errors.uploadVideos && <Form.Text>{errors.uploadVideos.message}</Form.Text>} */}
        </>
      ),
    },
    {
      key: 3,
      label: (
        <div className="main-alert-box">
          <div className="icon-div">
            <ModalAudioIcon />
          </div>
          <h3>Audio</h3>
          <p>Upload an MP3 or link to an audio source.</p>
        </div>
      ),
      children: (
        <>
          <Controller
            control={control}
            rules={{
              required:
                firstStepTabNo == 3 &&
                getValues("previewFirstStepAttachment") == ""
                  ? "Upload a WAV file or link to an audio source is required."
                  : false,
            }}
            name="uploadAudio"
            render={({ field: { onChange, onBlur, value } }) => (
              <AudioUpload
                resetField={resetField}
                handleChange={(e) => {
                  setValue("previewFirstStepAttachment", "");
                  onChange(e);
                }}
                handleSwitch={(e) => {
                  setValue("switchValue", e);
                }}
                switchDefaultValue={getValues("switchValue")}
                linkDefaultValue={
                  getValues("previewFirstStepAttachment") != ""
                    ? getValues("previewFirstStepAttachment")
                    : null
                }
                fileList={value}
              />
            )}
          />
          {/* {errors.uploadAudio && <Form.Text>{errors.uploadAudio.message}</Form.Text>} */}
        </>
      ),
    },
  ];

  const isPDF = checkPDFBySrc(getValues("previewFirstStepAttachment"));

  return (
    <>
      <Row>
        <Col md={12}>
          <Tabs
            activeKey={firstStepTabNo}
            items={ResuoreFirstStep}
            onChange={hanldeOnChange}
            className="alerts-tab main-push-alert resource-first-step-attachment-uploader"
          />
        </Col>
      </Row>
      {getValues("previewFirstStepAttachment") != "" && (
        <Row className="mb-4 mt-4">
          <Col md={12}>
            {/* <strong>Preview</strong> */}
            <p className="mt-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="overflow-ellipses">
                  {isPDF ? (
                    <span className="uploadIconPdfView">
                      <AcrobatIcon />
                    </span>
                  ) : (
                    <AiOutlinePaperClip
                      style={{
                        color: "#00000073",
                        fontSize: "16px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                  {getValues("previewFirstStepAttachment")}
                </div>
                <AiOutlineDelete
                  onClick={() => setValue("previewFirstStepAttachment", "")}
                  style={{ color: "#00000073", fontSize: "16px" }}
                />
              </div>
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FirstStep;
