import dayjs from "dayjs";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Assets } from "../../../../../constants/images";
import {
  bi_weekly_due_every_array,
  monthly_and_bi_weekly_repeat_task_array,
  monthly_due_every_array,
  recipient_types,
  task_type,
  weekly_due_every_array,
  weekly_repeat_task_array,
} from "../../../../../utils/constants";
import { toastMessage } from "../../../../../utils/helper";
import CustomDatePicker from "../../../../FormFields/CustomDatePicker";
import CustomSelect from "../../../../FormFields/CustomSelect";
import CustomTextArea from "../../../../FormFields/CustomTextArea";
import CustomTreeSelect from "../../../../FormFields/CustomTreeSelect";
import { setCreateUpdateModuleTitle } from "../../../../../store/slices/globalSlice";

const SecondStep = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { formData } = useSelector((state) => state?.tasks);
  const showPickerShowHide = useRef(false);
  const emojiPickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);

  function handleEmojiClick(emojiData) {
    setValue("name", getValues("name") + emojiData.emoji);
  }

  const handleTreeSelectValue = (parentNode, value) => {
    setValue("assignees", value);
    setValue("selectedCategory", parentNode);
    setValue(
      "recipient_type",
      parentNode == "everyone"
        ? recipient_types?.everyone
        : parentNode == "group"
          ? recipient_types?.group
          : recipient_types?.member,
    );
  };

  const returnFormComponent = () => {
    if (props?.selected == task_type?.one_time) {
      return (
        <>
          <Col xs={4} className="">
            <Controller
              rules={{
                required: {
                  value: "Required",
                },
              }}
              control={control}
              name="start"
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  label="Start Date"
                  placeholder="Start Date"
                  onChange={(date, dateString) => {
                    const startDate = new Date(dateString);
                    const endDate = new Date(getValues("end"));
                    if (
                      getValues("end") &&
                      startDate.getTime() > endDate.getTime()
                    ) {
                      toastMessage(
                        "error",
                        "The start date should not precede the end date.",
                      );
                      return;
                    }
                    if (!getValues("end")) {
                      setValue("end", moment(dateString).format("YYYY-MM-DD"));
                    }
                    field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  value={field.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
                />
              )}
            />
          </Col>

          <Col xs={4} className="">
            <Controller
              control={control}
              name="end"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  label="Due Date"
                  placeholder="Due Date"
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  onChange={(date, dateString) => {
                    const endDate = new Date(dateString);
                    const startDate = new Date(getValues("start"));
                    if (
                      getValues("start") &&
                      endDate.getTime() < startDate.getTime()
                    ) {
                      toastMessage(
                        "error",
                        "The due date should not precede the start date",
                      );
                      return;
                    }
                    if (!getValues("start")) {
                      setValue(
                        "start",
                        moment(dateString).format("YYYY-MM-DD"),
                      );
                    }
                    field?.onChange(moment(dateString).format("YYYY-MM-DD"));
                  }}
                  value={field.value ? dayjs(field?.value, "YYYY-MM-DD") : ""}
                />
              )}
            />
          </Col>
        </>
      );
    } else if (props?.selected == task_type?.weekly) {
      return (
        <>
          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="due_on"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Due Every"}
                  label={"Due Every"}
                  value={field?.value}
                  options={weekly_due_every_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>

          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="repeat_cycle"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Repeat Task"}
                  label={"Repeat Task"}
                  value={field?.value}
                  options={weekly_repeat_task_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>
        </>
      );
    } else if (props?.selected == task_type?.bi_weekly) {
      return (
        <>
          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="due_on"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Due Every"}
                  label={"Due Every"}
                  value={field?.value}
                  options={bi_weekly_due_every_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>

          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="repeat_cycle"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Repeat Task"}
                  label={"Repeat Task"}
                  value={field?.value}
                  options={monthly_and_bi_weekly_repeat_task_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>
        </>
      );
    } else if (props?.selected == task_type?.monthly) {
      return (
        <>
          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="due_on"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Due Every"}
                  label={"Due Every"}
                  value={field?.value}
                  options={monthly_due_every_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>

          <Col xs={4} className="mb-20">
            <Controller
              control={control}
              name="repeat_cycle"
              rules={{
                required: {
                  value: "Required",
                },
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={"Repeat Task"}
                  label={"Repeat Task"}
                  value={field?.value}
                  options={monthly_and_bi_weekly_repeat_task_array}
                  onChange={(e) => field?.onChange(e)}
                />
              )}
            />
          </Col>
        </>
      );
    }
  };

  useEffect(() => {
    reset(formData);
  }, []);

  const handleCheckEmojiClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      showPicker
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCheckEmojiClick);
    return () => {
      window.removeEventListener("click", handleCheckEmojiClick);
    };
  }, [showPicker]);

  return (
    <Row>
      <Col xs={12}>
        <FloatingLabel label="Task Name" className="mb-20 custom-textarea">
          <Form.Control
            className={props?.className}
            placeholder="ex. Post your work on Instagram"
            onChange={props?.onChange}
            minLength={1}
            maxLength={100}
            {...register("name", {
              onChange: (e) => {
                dispatch(setCreateUpdateModuleTitle(e.target.value));
              },
              required: {
                value: "Task name is required",
              },
              minLength: {
                value: 1,
              },
              maxLength: {
                value: 100,
              },
            })}
          />

          <div ref={emojiPickerRef}>
            <div
              onClick={() => {
                setShowPicker(!showPicker);
              }}
              className={"smileyBox"}
            >
              <img src={Assets.Smile} className={"smileImage"} />
            </div>
            {showPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
          </div>
        </FloatingLabel>
      </Col>

      <Col xs={4}>
        <CustomTreeSelect
          isedit={props?.isedit}
          label="Assign Staff"
          placeholder="Assign Staff"
          defaultSelectedData={formData?.assignees || []}
          handleChange={handleTreeSelectValue}
          selectedCategory={getValues("selectedCategory") || null}
        />
      </Col>

      {returnFormComponent()}

      <Col xs={12}>
        <Controller
          control={control}
          name="description"
          rules={{
            required: {
              value: false,
            },
            minLength: {
              value: 0,
            },
            maxLength: {
              value: 999,
            },
          }}
          render={({ field }) => (
            <CustomTextArea
              {...field}
              label="Description (Optional)"
              placeholder="Add instructions or additional details here…"
              value={field?.value}
              onChange={(e) => field?.onChange(e)}
              minLength={0}
              maxLength={999}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default SecondStep;
