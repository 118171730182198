import { Assets } from "./images";
import { NewEveryOneIcon, NewGroupIcon, NewMemberIcon } from "./svg";

export const recipentsData = [
  {
    value: "Everyone",
    childern: [],
    isSelected: false,
    image: <NewEveryOneIcon />,
    visible: false,
  },
  {
    value: "Groups",
    isSelected: false,
    image: <NewGroupIcon />,
    visible: true,
    childern: [
      {
        value: "Samantha Williamson",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Jon Doe",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
    ],
  },
  {
    value: "Members",
    isSelected: false,
    image: <NewMemberIcon />,
    visible: true,
    childern: [
      {
        value: "Stylists",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Barbers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
    ],
  },
];

// Assign Staff

export const AssignData = [
  {
    value: "Everyone",
    childern: [],
    isSelected: false,
    image: <NewEveryOneIcon />,
    visible: false,
  },
  {
    value: "Groups",
    isSelected: false,
    image: <NewGroupIcon />,
    visible: true,
    childern: [
      {
        value: "Stylists",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Barbers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Groups",
        isSelected: false,
        image: <NewGroupIcon />,
        visible: true,
      },
    ],
  },
  {
    value: "Members",
    isSelected: false,
    image: <NewMemberIcon />,
    visible: true,
    childern: [
      {
        value: "Stylists",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Barbers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
      {
        value: "Managers",
        parent: "Members",
        isSelected: false,
        image: <NewMemberIcon />,
        visible: true,
      },
    ],
  },
];
