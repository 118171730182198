import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const CustomInput = (props) => {
  return (
    <FloatingLabel label={props?.label} className="custom-textarea">
      <Form.Control
        {...props}
        style={props?.style}
        className={props?.className}
        placeholder={props?.placeholder}
        type={props?.type}
        value={props?.value}
        onChange={props?.onChange}
      />
    </FloatingLabel>
  );
};

export default CustomInput;
