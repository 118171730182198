import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalCloseIcon } from "../../../../constants/svg";

const UserProfileModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="generic-modal userProfileModal chatSendModal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" text-center w-100"
          >
            {props.modalTitle}
          </Modal.Title>

          <div
            className="closeBtn"
            onClick={props.onHide}
            data-bs-dismiss="modal"
          >
            <ModalCloseIcon />
          </div>
        </Modal.Header>
        {props.desc == true ? (
          <div className="text-left location-desc-padding custom-height-20">
            <b>{props.pro}</b> {props.modalDesc}
          </div>
        ) : (
          <div
            className="text-center pb-4"
            style={{ display: props.modalDesc ? "block" : "none" }}
          >
            {props.modalDesc}
          </div>
        )}

        {/* All body Compponents goes here... */}
        {props.bodyComponent}
      </Modal>
    </>
  );
};

export default UserProfileModal;
